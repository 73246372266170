import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';

export type OvertimeDialogProps = {
	open: boolean;
	initialOvertimeThreshold: number | null;
	initialOvertimePayOption: IntegrationElement | null;
	integrationPayOptions: Record<string, IntegrationElement>;
	handleSave: (
		payOption: IntegrationElement,
		overtimeThreshold: number,
	) => Promise<void>;
	handleClose: () => void;
};

export const OvertimeDialog = ({
	open,
	initialOvertimeThreshold,
	initialOvertimePayOption,
	integrationPayOptions,
	handleSave,
	handleClose,
}: OvertimeDialogProps): JSX.Element => {
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedPayOption, setSelectedPayOption] =
		useState<IntegrationElement | null>(initialOvertimePayOption);
	const [overtimeThreshold, setOvertimethreshold] = useState(
		initialOvertimeThreshold ?? 0,
	);
	const [errors, setErrors] = useState({ payOption: false, overtime: false });

	const validateInput = (): void => {
		if (overtimeThreshold === 0) {
			setErrors((prev) => ({ ...prev, overtime: true }));
		}
		if (selectedPayOption === null) {
			setErrors((prev) => ({ ...prev, payOption: true }));
		}
	};

	const onSave = async (): Promise<void> => {
		validateInput();
		if (
			Object.values(errors).every((error) => !error) &&
			selectedPayOption !== null
		) {
			setSaving(true);
			await handleSave(selectedPayOption, overtimeThreshold);
			setSaving(false);
			setSelectedPayOption(null);
			setOvertimethreshold(0);
			handleClose();
		}
	};

	const onClose = (): void => {
		setSelectedPayOption(null);
		setOvertimethreshold(0);
		handleClose();
	};

	const requiredLabel = (label: string): JSX.Element => (
		<Stack direction="row">
			<Typography>{label}</Typography>
			<Typography color="error.main">*</Typography>
		</Stack>
	);

	return (
		<Dialog
			onClose={!saving ? onClose : undefined}
			open={open}
			fullWidth={true}
			maxWidth="xs">
			<DialogTitle>Configure Overtime</DialogTitle>

			<DialogContent>
				<Stack spacing={2} paddingY={1}>
					<DialogContentText fontSize="small">
						Set the hours threshold to use before overtime is
						applied.
					</DialogContentText>
					<TextField
						select
						size="small"
						label={requiredLabel('Pay Option')}
						value={selectedPayOption?.id ?? 'tl-default'}
						error={errors.payOption}
						helperText={
							errors.payOption && 'Please select a Pay Option'
						}
						disabled={saving}
						fullWidth>
						<MenuItem
							key="pay-option-default"
							value="tl-default"
							disabled>
							Select Pay Option
						</MenuItem>
						{Object.values(integrationPayOptions).map(
							(integrationPayOption) => (
								<MenuItem
									key={integrationPayOption.id}
									value={integrationPayOption.id}
									onClick={(): void => {
										setSelectedPayOption(
											integrationPayOption,
										);
										setErrors({
											...errors,
											payOption: false,
										});
									}}>
									{integrationPayOption.name} -{' '}
									{integrationPayOption.description}
								</MenuItem>
							),
						)}
					</TextField>
					<TextField
						type="tel"
						size="small"
						label={requiredLabel('Overtime Threshold')}
						value={overtimeThreshold}
						error={errors.overtime}
						helperText={
							errors.overtime &&
							'Please provide an Overtime Threshold'
						}
						disabled={saving}
						onChange={(value): void => {
							let newHours = +Math.abs(
								Number(value.target.value),
							).toFixed(2);
							const MAX_HOURS = 168; // hours in a week, no real constraints are needed below this
							if (newHours > MAX_HOURS) newHours = MAX_HOURS;
							setOvertimethreshold(newHours);
							setErrors((prev) => ({ ...prev, overtime: false }));
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Typography>hrs</Typography>
								</InputAdornment>
							),
						}}
						inputProps={{
							min: 0,
							sx: {
								textAlign: 'right',
							},
						}}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose} disabled={saving}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					disabled={
						Object.values(errors).some((error) => error) || saving
					}
					loading={saving}
					onClick={onSave}
					sx={{ paddingX: 3 }}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
