import { Timestamp } from '../firebase/firebase';
import {
	SafetyCourse,
	SafetyCourseStatus,
	safetyCourseStatus,
	SafetyCourseType,
	SafetyCourseValidationResponse,
} from './SafetyCourse';
import { TimestampToString } from './TypescriptUtilities';

export const getSafetyCourseStatus = (
	safetyCourse: SafetyCourseValidationResponse | null,
): SafetyCourseStatus => {
	switch (safetyCourse?.type) {
		case SafetyCourseType.ConstructSafe: {
			return safetyCourseStatus.Valid;
		}
		case SafetyCourseType.SiteSafe: {
			if (safetyCourse?.expiryDate) {
				return safetyCourse.expiryDate > new Date()
					? safetyCourseStatus.Valid
					: safetyCourseStatus.Expired;
			} else {
				throw Error('Site Safe expiry date is null or undefined');
			}
		}
		default: {
			return safetyCourseStatus.NotFound;
		}
	}
};

export const getSafetyCourseStatusWithPending = (
	safetyCourse?: SafetyCourseValidationResponse | null,
): SafetyCourseStatus | 'Pending' => {
	if (safetyCourse === undefined) {
		return 'Pending';
	}
	return getSafetyCourseStatus(safetyCourse);
};

export const createCourse = (
	id: string,
	type: SafetyCourseType,
	name: string,
	expiryDate: Date | null,
): SafetyCourse['course'] => {
	switch (type) {
		case SafetyCourseType.ConstructSafe:
			return {
				id: id,
				name: name,
				type: type,
				expiryDate: null,
			};
		case SafetyCourseType.SiteSafe:
			if (expiryDate !== null) {
				return {
					id: id,
					name: name,
					type: type,
					expiryDate: Timestamp.fromDate(expiryDate),
				};
			} else {
				throw Error('Site Safe expiry date is null');
			}
	}
};

export const safetyCourseToResponse = (
	safetyCourse: SafetyCourse['course'],
): SafetyCourseValidationResponse => {
	switch (safetyCourse.type) {
		case SafetyCourseType.ConstructSafe: {
			return {
				id: safetyCourse.id,
				expiryDate: safetyCourse.expiryDate,
				name: safetyCourse.name,
				type: safetyCourse.type,
			};
		}
		case SafetyCourseType.SiteSafe: {
			return {
				id: safetyCourse.id,
				expiryDate: safetyCourse.expiryDate.toDate(),
				name: safetyCourse.name,
				type: safetyCourse.type,
			};
		}
	}
};

export const responseToSafetyCourse = (
	safetyCourse: SafetyCourseValidationResponse,
): SafetyCourse['course'] => {
	switch (safetyCourse.type) {
		case SafetyCourseType.ConstructSafe: {
			return {
				id: safetyCourse.id,
				expiryDate: null,
				name: safetyCourse.name,
				type: safetyCourse.type,
			};
		}
		case SafetyCourseType.SiteSafe: {
			return {
				id: safetyCourse.id,
				expiryDate: Timestamp.fromDate(safetyCourse.expiryDate),
				name: safetyCourse.name,
				type: safetyCourse.type,
			};
		}
	}
};

export const convertSafetyCourseCFResponse = (
	response: TimestampToString<SafetyCourse>,
): SafetyCourse => {
	const course: SafetyCourse['course'] =
		response.course.type === SafetyCourseType.SiteSafe
			? {
					...response.course,
					expiryDate: Timestamp.fromDate(
						new Date(response.course.expiryDate),
					),
			  }
			: {
					...response.course,
					expiryDate: null,
			  };

	return {
		...response,
		dateCreated: Timestamp.fromDate(new Date(response.dateCreated)),
		course,
	};
};
