import firebase from 'firebase';
import { Activity, UserDetails } from '../../constants/Common';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { AllowDateOrTimestamp } from '../helpers/dateUtilities';

export type TimesheetAction = (
	timesheet: Timesheet,
	activities: Record<string, Activity>,
) => Promise<void>;

type GetTimesheetAction = (
	timesheetStatus: TimesheetStatus.Active | TimesheetStatus.Submitted,
	isCurrent?: boolean,
) => {
	onClick: TimesheetAction;
	btnText: string;
};

type FirebaseUpdateTimesheetActivities = Pick<
	FirebaseApi,
	'updateTimesheetActivities'
>;

const approve =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetAction =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			| 'id'
			| 'timesheetStatus'
			| 'lastEditedBy'
			| 'reviewedAt'
			| 'reviewer'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Approved,
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			reviewer: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			reviewedAt: firebase.firestore.Timestamp.now(),
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status' | 'finalReviewBy' | 'finalReviewAt'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Approved,
			finalReviewBy: userDetails.displayName,
			finalReviewAt: new Date(),
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

const submit =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetAction =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			'id' | 'timesheetStatus' | 'dateSubmitted' | 'lastEditedBy'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Submitted,
			dateSubmitted: firebase.firestore.Timestamp.now(),
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Submitted,
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

const unsubmit =
	(
		userDetails: UserDetails,
		firebaseApi: FirebaseUpdateTimesheetActivities,
	): TimesheetAction =>
	async (timesheet: Timesheet, activities: Record<string, Activity>) => {
		const updatedTimesheet: Pick<
			Timesheet,
			'id' | 'timesheetStatus' | 'dateSubmitted' | 'lastEditedBy'
		> = {
			id: timesheet.id,
			timesheetStatus: TimesheetStatus.Active,
			dateSubmitted: firebase.firestore.Timestamp.now(),
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
		};
		const updatedActivities: Pick<
			AllowDateOrTimestamp<Activity>,
			'id' | 'status'
		>[] = Object.values(activities).map((activity: Activity) => ({
			id: activity.id,
			status: TimesheetStatus.Active,
		}));

		await firebaseApi.updateTimesheetActivities(
			updatedTimesheet,
			updatedActivities,
		);
	};

export const getTimesheetActions = (
	userDetails: UserDetails,
	firebaseApi: FirebaseUpdateTimesheetActivities,
): GetTimesheetAction => {
	const approveAction = approve(userDetails, firebaseApi);
	const submitAction = submit(userDetails, firebaseApi);
	const unsubmitAction = unsubmit(userDetails, firebaseApi);

	return (
		timesheetStatus: TimesheetStatus.Active | TimesheetStatus.Submitted,
		isCurrent = false,
	): {
		onClick: TimesheetAction;
		btnText: string;
	} => {
		const actions: Record<
			TimesheetStatus.Active | TimesheetStatus.Submitted,
			{ onClick: TimesheetAction; btnText: string }
		> = {
			Submitted: {
				onClick: isCurrent ? unsubmitAction : approveAction,
				btnText: isCurrent ? 'Unsubmit' : 'Approve',
			},
			Active: {
				onClick: isCurrent ? submitAction : unsubmitAction,
				btnText: isCurrent ? 'Submit' : 'Unsubmit',
			},
		};
		return actions[timesheetStatus];
	};
};
