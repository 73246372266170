import { createContext, useContext } from 'react';
import { ExplicitAny } from '../../constants/AnyTypes';
import { UserDetails } from '../../constants/Common';

export type AnalyticsService = {
	logEvent: (
		eventName: string,
		eventParams?: Record<string, ExplicitAny>,
	) => void;
	identify: (userDetails: UserDetails | null) => void;
	setup: () => void;
};

const defaultAnalyticsContext: AnalyticsService = {
	logEvent: () => {
		throw new Error('Uninitialised');
	},
	identify: () => {
		throw new Error('Uninitialised');
	},
	setup: () => {
		throw new Error('Uninitialised');
	},
};

export const AnalyticsContext = createContext<AnalyticsService>(
	defaultAnalyticsContext,
);

export const useAnalyticsContext = (): AnalyticsService =>
	useContext(AnalyticsContext);
