import {
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	DialogActions,
	Button,
	MenuItem,
	TextField,
	Switch,
	Stack,
	Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import {
	AccountsAccountType,
	accountApprovalsMap,
} from '../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	CompanyTypes,
	Site,
	UserDetails,
	accountTypes,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { CompanyAutoComplete } from '../Autocomplete/CompanyAutocomplete';
import { SitesAutocomplete } from '../Autocomplete/SitesAutocomplete';

export type UsersOptionsModalProps = {
	modalOpen: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
	accountType: AccountsAccountType;
	existingUser: UserDetails;
	sites: Record<string, Site>;
	firebaseApi: Pick<
		FirebaseApi,
		'companiesSubscriptionByType' | 'updateUserAccountDetails'
	>;
};

export const UsersOptionsModal = ({
	modalOpen,
	setModalOpen,
	accountType,
	existingUser,
	sites,
	firebaseApi,
}: UsersOptionsModalProps): JSX.Element => {
	const [siteID, setSiteID] = useState<string>(existingUser.siteID);
	const [newUsersAccountType, setNewUsersAccountType] = useState<AccountType>(
		existingUser.accountType,
	);
	const [contractedToCompany, setContractedToCompany] = useState<
		UserDetails['contractedTo']
	>(existingUser.contractedTo);
	const [toggleState, setToggleState] = useState<
		UserDetails['disabledLeave']
	>(!!existingUser.disabledLeave);

	const editContractedTo = accountType === accountTypes.handler;
	const selectableAccountTypes = accountApprovalsMap[accountType];
	const isRecruitmentSite =
		sites[siteID] && sites[siteID].companyType === CompanyTypes.recruitment;
	const selectedContractedToID = isRecruitmentSite
		? sites[siteID].companyID
		: contractedToCompany?.id ?? null;
	const selectedSiteID = siteID === '' ? null : siteID;

	const handleSave = async (): Promise<void> => {
		if (!existingUser) return;
		const updatedUserAccountDetails: Partial<
			Pick<
				UserDetails,
				| 'site'
				| 'siteID'
				| 'siteCompany'
				| 'siteCompanyID'
				| 'contractedTo'
				| 'accountType'
			>
		> = {};

		if (sites[siteID] && sites[siteID].id !== existingUser.siteID) {
			const site: Site = sites[siteID];
			updatedUserAccountDetails.site = site.name;
			updatedUserAccountDetails.siteID = site.id;
			updatedUserAccountDetails.siteCompany = site.company;
			updatedUserAccountDetails.siteCompanyID = site.companyID;
		} else if (siteID === '' && existingUser.siteID !== '') {
			updatedUserAccountDetails.site = '';
			updatedUserAccountDetails.siteID = '';
			updatedUserAccountDetails.siteCompany = '';
			updatedUserAccountDetails.siteCompanyID = '';
		}
		if (newUsersAccountType !== existingUser.accountType) {
			updatedUserAccountDetails.accountType = newUsersAccountType;
		}
		if (contractedToCompany !== existingUser.contractedTo) {
			updatedUserAccountDetails.contractedTo = contractedToCompany;
		}

		const hasSiteContractedToDataChanged =
			Object.keys(updatedUserAccountDetails).length > 0;
		const updateToggleState = toggleState !== !!existingUser.disabledLeave;
		const toggleStateToUpdate = updateToggleState ? toggleState : undefined;

		// Only call the update function if there are changes
		if (hasSiteContractedToDataChanged || updateToggleState) {
			await firebaseApi.updateUserAccountDetails(
				existingUser.userID,
				updatedUserAccountDetails,
				toggleStateToUpdate,
			);
		}

		setModalOpen(false);
	};

	const handleClose = (): void => {
		setModalOpen(false);
		setContractedToCompany(existingUser.contractedTo);
		setSiteID(existingUser.siteID);
		setNewUsersAccountType(existingUser.accountType);
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} fullWidth>
			<DialogTitle>Edit {existingUser.displayName} Details</DialogTitle>
			<DialogContent>
				<List>
					{editContractedTo && (
						<ListItem>
							<CompanyAutoComplete
								value={selectedContractedToID}
								label="Contracted To"
								recentClientIDs={
									existingUser.recentClients?.map(
										(item) => item.id,
									) ?? []
								}
								onChange={(contractedToCompany): void =>
									setContractedToCompany(contractedToCompany)
								}
								error={false}
								helperText=""
								disabled={isRecruitmentSite}
								includeUserCompany
								firebaseApi={firebaseApi}
							/>
						</ListItem>
					)}
					<ListItem>
						<SitesAutocomplete
							value={selectedSiteID}
							onChange={setSiteID}
							label="Site"
							sites={sites}
						/>
					</ListItem>
					<ListItem>
						<TextField
							value={newUsersAccountType}
							fullWidth
							label="Account Type"
							onChange={(event): void => {
								setNewUsersAccountType(
									event.target.value as AccountType,
								);
							}}
							select>
							{selectableAccountTypes.map((option) => (
								<MenuItem key={option} value={option}>
									{AccountTypeHumanName[option]}
								</MenuItem>
							))}
						</TextField>
					</ListItem>
					<ListItem>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							flex={1}>
							<Typography>Disable Leave</Typography>
							<Switch
								checked={toggleState}
								onClick={(): void =>
									setToggleState(!toggleState)
								}
							/>
						</Stack>
					</ListItem>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="outlined">
					Cancel
				</Button>
				<Button onClick={handleSave} variant="contained">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
