import { UserDetails } from '../../../../constants/Common';
import {
	Email,
	fetchRecepients,
	sendEmail,
	SendEmailCallback,
} from '../../../../constants/Email';

type RequestTemplateProps = {
	userDetails: UserDetails;
	requestNotes: string;
};

const RequestTemplate = ({
	userDetails,
	requestNotes,
}: RequestTemplateProps): JSX.Element => (
	<body>
		<h1>New Kiosk request</h1>A new kiosk has been requested, with the
		following details:
		<table>
			<tbody>
				<tr>
					<th>Date</th>
					<td>{new Date().toLocaleString()}</td>
				</tr>
				<tr>
					<th>Company</th>
					<td>
						{userDetails.company} - {userDetails.companyID}
					</td>
				</tr>
				<tr>
					<th>Site</th>
					<td>
						{userDetails.site} - {userDetails.siteID}
					</td>
				</tr>
				<tr>
					<th>Requester</th>
					<td>
						{userDetails.displayName} - {userDetails.email}
					</td>
				</tr>

				<tr>
					<th>Notes from Requester</th>
					<td>{requestNotes}</td>
				</tr>
			</tbody>
		</table>
	</body>
);

type UnassignTemplateProps = {
	userDetails: UserDetails;
	requestNotes: string;
	kiosk: Pick<UserDetails, 'userID' | 'displayName'>;
};

const UnassignTemplate = ({
	userDetails,
	requestNotes,
	kiosk,
}: UnassignTemplateProps): JSX.Element => (
	<body>
		<h1>Kiosk Unassigned</h1>A kiosk has been unassigned, with the following
		details:
		<table>
			<tbody>
				<tr>
					<th>Date</th>
					<td>{new Date().toLocaleString()}</td>
				</tr>
				<tr>
					<th>Old Company</th>
					<td>
						{userDetails.company} - {userDetails.companyID}
					</td>
				</tr>
				<tr>
					<th>Old Site</th>
					<td>
						{userDetails.site} - {userDetails.siteID}
					</td>
				</tr>
				<tr>
					<th>Requester</th>
					<td>
						{userDetails.displayName} - {userDetails.email}
					</td>
				</tr>
				<tr>
					<th>Kiosk</th>
					<td>
						{kiosk.displayName} - {kiosk.userID}
					</td>
				</tr>
				<tr>
					<th>Notes from Requester</th>
					<td>{requestNotes}</td>
				</tr>
			</tbody>
		</table>
	</body>
);

const toRequestEmail = async (html: string): Promise<Email> => ({
	html,
	to: (await fetchRecepients()).management,
	subject: 'New Kiosk Request',
	template: 'NewKioskRequest',
});
export const sendKioskEmail = (
	props: RequestTemplateProps,
	onSend?: SendEmailCallback,
): void => sendEmail(RequestTemplate(props), toRequestEmail, onSend);

const toUnassignEmail = async (html: string): Promise<Email> => ({
	html,
	to: (await fetchRecepients()).management,
	subject: 'Kiosk Unassigned',
	template: 'UnassignedKiosk',
});
export const sendUnassignEmail = (
	props: UnassignTemplateProps,
	onSend?: SendEmailCallback,
): void => sendEmail(UnassignTemplate(props), toUnassignEmail, onSend);
