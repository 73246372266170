import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import {
	Company,
	CompanyEmployeeRates,
	CompanyTypes,
	Minimal,
	MinimalUserDetails,
	Site,
} from '../../constants/Common';
import { Contract, UpdatableContract } from '../../constants/Contract';
import { Entries, PickOne } from '../../constants/TypescriptUtilities';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../providers/UserProvider';
import { sortByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { UploadFile } from '../UploadFile/UploadFile';
import { RatesTextFields } from './RatesTextFields';

export type ContractDialogFirebaseApi = Pick<
	FirebaseApi,
	| 'companyEmployeeRatesByID'
	| 'companiesSubscriptionByType'
	| 'subscribeWorkerUsersByCompany'
	| 'activeSitesSubscription'
	| 'findDuplicateContract'
>;
type ContractErrorMap = Partial<Record<keyof UpdatableContract, string>> & {
	unique?: string;
};
export type ContractDialogProps =
	| {
			action: 'add';
			modalOpen: boolean;
			closeModal: () => void;
			onSave: (contract: UpdatableContract) => Promise<void>;
			contract?: never;
			firebaseApi: ContractDialogFirebaseApi;
	  }
	| {
			action: 'edit';
			modalOpen: boolean;
			closeModal: () => void;
			onSave: (contract: UpdatableContract) => Promise<void>;
			contract: Contract;
			firebaseApi: ContractDialogFirebaseApi;
	  };
const MAX_FILE_SIZE = 5242880; // bytes

const emptyContract: UpdatableContract = {
	employee: { id: '', name: '' },
	payRate: 0,
	chargeOutRate: 0,
	onCost: 0,
	notes: '',
	site: null,
	accepterCompany: { id: '', name: '' },
	margin: 0,
	document: null,
};

const toUpdateableContract = (contract: Contract): UpdatableContract => ({
	employee: contract.employee,
	payRate: contract.payRate,
	chargeOutRate: contract.chargeOutRate,
	onCost: contract.onCost,
	notes: contract.notes,
	site: contract.site,
	accepterCompany: contract.accepterCompany,
	margin: contract.margin,
	document: contract.documentURL,
});

const calculateMargin = (
	chargeOutRate: number,
	payRate: number,
	onCost: number,
): number => {
	return chargeOutRate - payRate * (1 + onCost);
};

const reducer = (
	state: UpdatableContract,
	action: { value?: Partial<UpdatableContract> },
): UpdatableContract => {
	if (!action.value) {
		return emptyContract;
	}

	const newState = { ...state, ...action.value };

	if (
		'payRate' in action.value ||
		'chargeOutRate' in action.value ||
		'onCost' in action.value
	) {
		const chargeOutRate = !isNaN(newState.chargeOutRate)
			? newState.chargeOutRate
			: 0;
		const payRate = !isNaN(newState.payRate) ? newState.payRate : 0;
		const onCost = !isNaN(newState.onCost) ? newState.onCost : 0;
		newState.margin = calculateMargin(chargeOutRate, payRate, onCost);
	}

	return newState;
};

export const ContractDialog = (
	contractDialogProps: ContractDialogProps,
): JSX.Element => {
	const { modalOpen, closeModal, onSave, firebaseApi, ...props } =
		contractDialogProps;

	const userDetails = useUserDetailsContext();

	const [userList, setUserList] = useState<MinimalUserDetails[]>([]);
	const [siteList, setSiteList] = useState<Minimal<Site>[]>([]);
	const [companyList, setCompanyList] = useState<Minimal<Company>[]>([]);
	const [companyEmployeeRates, setCompanyEmployeeRates] =
		useState<CompanyEmployeeRates>({});
	const defaultContract =
		props.action === 'edit'
			? toUpdateableContract(props.contract)
			: emptyContract;
	const [state, dispatch] = useReducer(reducer, defaultContract);
	const [errors, setErrors] = useState<ContractErrorMap>({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (modalOpen && userDetails?.companyID) {
			const usersSub = firebaseApi.subscribeWorkerUsersByCompany(
				userDetails.companyID,
				(users) => {
					const minimalUsers = Object.values(users).map((user) => ({
						id: user.userID,
						name: user.displayName,
					}));
					setUserList(sortByField(minimalUsers, 'name'));
				},
			);
			const companiesSub = firebaseApi.companiesSubscriptionByType(
				CompanyTypes.construction,
				(companies) => {
					const companyList = Object.values(companies).map(
						(company) => ({
							id: company.id,
							name: company.name,
						}),
					);
					setCompanyList(sortByField(companyList, 'name'));
				},
			);
			const sitesSub = firebaseApi.activeSitesSubscription((sites) => {
				const siteList = Object.values(sites).map((site) => ({
					id: site.id,
					name: site.name,
				}));
				setSiteList(sortByField(siteList, 'name'));
				dispatch({ value: { site: { id: '', name: 'All Sites' } } });
			});

			const employeeRatesSub = firebaseApi.companyEmployeeRatesByID(
				userDetails?.companyID,
				(employeeRates) => {
					setCompanyEmployeeRates(employeeRates);
				},
			);
			return () => {
				usersSub();
				companiesSub();
				sitesSub();
				employeeRatesSub();
			};
		}
	}, [firebaseApi, modalOpen, userDetails?.companyID]);

	const onCancel = (): void => {
		closeModal();
		setErrors({});
		dispatch({});
	};

	const saveContract = async (): Promise<void> => {
		if (!(await validate())) {
			return;
		}

		setIsLoading(true);
		if (!state.site || state.site.id === '') {
			await onSave({ ...state, site: null });
		} else {
			await onSave(state);
		}
		closeModal();
		dispatch({});
		setIsLoading(false);
	};

	const validate = async (): Promise<boolean> => {
		let success = true;

		const contractEntries = Object.entries(
			state,
		) as Entries<UpdatableContract>;

		const errorMap: ContractErrorMap = contractEntries.reduce(
			(prev, [id, value]) => {
				if (
					(id === 'chargeOutRate' || id === 'payRate') &&
					(value <= 0 || isNaN(value))
				) {
					success = false;
					return { ...prev, [id]: 'Must be greater than 0' };
				} else if (
					(id === 'employee' || id === 'accepterCompany') &&
					(!value.id || !value.name)
				) {
					success = false;
					return { ...prev, [id]: 'Must select an option' };
				} else if (
					id === 'document' &&
					value &&
					typeof value !== 'string' &&
					value.size > MAX_FILE_SIZE
				) {
					success = false;
					return { ...prev, [id]: 'File size must be less than 5mb' };
				}
				return prev;
			},
			{} as ContractErrorMap,
		);

		// Check for a unique contract
		if (
			userDetails?.companyID &&
			state.employee.id &&
			state.accepterCompany.id
		) {
			const result = await firebaseApi.findDuplicateContract(
				state.employee.id,
				userDetails.companyID,
				state.accepterCompany.id,
				state.site?.id,
			);

			const isEditing = props.action === 'edit';
			const isAdding = props.action === 'add';
			const foundDuplicates = result && result?.length > 0;
			const moreThanOneDuplicateWhileEditing =
				isEditing && result && result?.length > 1;

			// When adding, no duplicates are allowed.
			if (isAdding && foundDuplicates) {
				success = false;
				const sitePortion = state.site?.id
					? `on the ${state.site.name} site`
					: 'without a site specified';

				errorMap.unique = `A contract between ${state.employee.name} and ${state.accepterCompany.name}, ${sitePortion}, already exists.`;

				// When editing, a single duplicate is allowed (itself), but not more.
			} else if (isEditing && moreThanOneDuplicateWhileEditing) {
				success = false;
				const sitePortion = state.site?.id
					? `on the ${state.site.name} site`
					: 'without a site specified';

				errorMap.unique = `Multiple contracts between ${state.employee.name} and ${state.accepterCompany.name}, ${sitePortion}, already exists.`;
			}
		}
		setErrors(errorMap);

		return success;
	};

	const employeeAutoComplete = (
		<Autocomplete
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			value={state.employee.id && userList ? state.employee : null}
			onChange={(_, newValue): void => {
				if (newValue !== null) {
					const employeeRates = companyEmployeeRates[newValue.id]
						? {
								chargeOutRate:
									companyEmployeeRates[newValue.id]
										.chargeOutRate,
								payRate:
									companyEmployeeRates[newValue.id].payRate,
						  }
						: {
								chargeOutRate: 0,
								payRate: 0,
						  };
					dispatch({
						value: { employee: newValue, ...employeeRates },
					});
					setErrors({ ...errors, employee: undefined });
				}
			}}
			options={Object.values(userList)}
			getOptionLabel={(option): string => option.name ?? ''}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label="Employee"
					variant="outlined"
					error={errors['employee'] !== undefined}
					helperText={errors['employee']}
				/>
			)}
		/>
	);

	const companyAutoComplete = (
		<Autocomplete
			value={state.accepterCompany.id ? state.accepterCompany : null}
			onChange={(_, newValue): void => {
				if (newValue !== null) {
					dispatch({ value: { accepterCompany: newValue } });
					setErrors({
						...errors,
						accepterCompany: undefined,
					});
				}
			}}
			options={Object.values(companyList)}
			getOptionLabel={(option: Minimal<Company>): string => option.name}
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label="Client"
					variant="outlined"
					error={errors['accepterCompany'] !== undefined}
					helperText={errors['accepterCompany']}
				/>
			)}
		/>
	);

	const siteAutoComplete = (
		<Autocomplete
			value={state.site || null}
			onChange={(_, newValue): void => {
				dispatch({ value: { site: newValue } });
				setErrors({ ...errors, site: undefined });
			}}
			options={[{ id: '', name: 'All Sites' }, ...siteList]}
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			getOptionLabel={(option): string => option.name ?? ''}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label="Site (Optional)"
					variant="outlined"
					error={errors['site'] !== undefined}
					helperText={errors['site']}
				/>
			)}
		/>
	);

	const handleRateChange =
		(field: 'payRate' | 'chargeOutRate' | 'onCost') =>
		(rate: number): void => {
			// https://github.com/microsoft/TypeScript/issues/10530 -> Must cast as TS can't narrow in square brackets for performance reasons
			const value = {
				[field]: rate,
			} as PickOne<UpdatableContract>;
			dispatch({
				value,
			});
			setErrors({
				...errors,
				[field]: undefined,
				margin: undefined,
			});
		};

	const modalContent = (
		<Grid container spacing={2} pt={1}>
			<Grid item xs={12}>
				{employeeAutoComplete}
			</Grid>
			<Grid item xs={12}>
				{companyAutoComplete}
			</Grid>
			<Grid item xs={12}>
				{siteAutoComplete}
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					maxRows={4}
					minRows={4}
					multiline
					label="Notes"
					placeholder="Notes"
					value={state.notes}
					onChange={(event): void =>
						dispatch({ value: { notes: event.target.value } })
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<RatesTextFields
					newChargeRate={state.chargeOutRate}
					chargeRateError={!!errors.chargeOutRate}
					newPayRate={state.payRate}
					payRateError={!!errors.payRate}
					newOnCost={state.onCost}
					onCostError={!!errors.onCost}
					newMargin={state.margin}
					handleChargeRateChange={handleRateChange('chargeOutRate')}
					handlePayRateChange={handleRateChange('payRate')}
					handleOnCostChange={handleRateChange('onCost')}
				/>
			</Grid>
			<Grid item xs={12} textAlign="center" minHeight="2rem">
				{errors.unique && (
					<>
						<Typography
							variant="body1Bold"
							color="error"
							display="block">
							{errors.unique}
						</Typography>
						<Typography color="error" display="block" mb={1}>
							You can have, at most, one non-archived contract
							with this combination at all times.
						</Typography>
					</>
				)}
			</Grid>
			<Grid container item xs={12} justifyContent="center">
				<UploadFile
					onUpload={(blob: Blob | null): void =>
						dispatch({ value: { document: blob } })
					}
					storageUrl={
						typeof state.document === 'string'
							? state.document
							: null
					}
				/>
			</Grid>
		</Grid>
	);

	return (
		<Dialog
			open={modalOpen}
			fullWidth
			onClose={(): void => {
				onCancel();
				closeModal();
			}}>
			<DialogTitle>
				{props.action === 'add' ? 'New' : 'Edit'} Rate
			</DialogTitle>
			<DialogContent>
				{isLoading ? <LoadingDots /> : modalContent}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					disabled={isLoading}
					onClick={onCancel}>
					Cancel
				</Button>
				<Button
					variant="contained"
					disabled={isLoading}
					onClick={saveContract}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
