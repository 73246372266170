import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const deletePayrollIntegration = (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<boolean | undefined> => {
	return makeCloudFunctionRequestReturnSuccessStatus({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.PayrollDelete,
		method: 'DELETE',
		services: defaultCloudFunctionServices,
	});
};
