import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type WorkHistoryTriggerBody = {
	timesheetID: string;
};

type WorkHistoryTriggerReturn = boolean;

export const workHistoryTrigger = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	timesheetID: string,
): Promise<WorkHistoryTriggerReturn> =>
	await makeCloudFunctionRequestReturnSuccessStatus<WorkHistoryTriggerBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.WorkHistoryTrigger,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: { timesheetID },
	});
