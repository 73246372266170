/* eslint-disable id-length */
// it's a copout to disable this rule, but for generic sorting helpers, I can only think of less helpful names than `a` and `b`
import { MinimalInterface, Minimal } from '../../constants/Common';

export const sortMinimal = <T extends MinimalInterface>(
	order: 'asc' | 'desc',
): ((
	minimalA: {
		data: Minimal<T> | null;
	},
	minimalB: {
		data: Minimal<T> | null;
	},
) => number) => {
	const multiplier = order === 'asc' ? -1 : 1;
	return (
		minimalA: { data: Minimal<T> | null },
		minimalB: { data: Minimal<T> | null },
	): number => {
		const a = minimalA.data;
		const b = minimalB.data;

		if (a === null) {
			return -1 * multiplier;
		} else if (b === null) {
			return multiplier;
		}
		return a.name.localeCompare(b.name) * multiplier;
	};
};

export const sortObjectByKeys = <T>(
	object: Record<string, T>,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(object).sort(([a], [b]) =>
			a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
		),
	);

export const sortObjectByValues = (
	object: Record<string, string>,
): Record<string, string> =>
	Object.fromEntries(
		Object.entries(object).sort(([, a], [, b]) =>
			a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
		),
	);

export const sortObjectByField = <
	Field extends string,
	T extends Record<Field, string>,
>(
	unsortedObject: Record<string, T>,
	field: Field,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(unsortedObject).sort(([, a], [, b]) =>
			a[field]
				.toLocaleLowerCase()
				.localeCompare(b[field].toLocaleLowerCase()),
		),
	);

export const sortObjectBySubField = <
	Field extends string,
	SubField extends string,
	T extends Record<Field, Record<SubField, string>>,
>(
	unsortedObject: Record<string, T>,
	field: Field,
	subfield: SubField,
): Record<string, T> =>
	Object.fromEntries(
		Object.entries(unsortedObject).sort(([, a], [, b]) =>
			a[field][subfield]
				.toLocaleLowerCase()
				.localeCompare(b[field][subfield].toLocaleLowerCase()),
		),
	);

/**
 * Case insensitive sorting of string arrays.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortStringArray = <T extends string>(list: T[]): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
	);
	return sortedList;
};

/**
 * Case insensitive sorting of lists.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortByField = <
	Field extends string,
	T extends Record<Field, string>,
>(
	list: T[],
	field: Field,
): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a[field]
			.toLocaleLowerCase()
			.localeCompare(b[field].toLocaleLowerCase()),
	);
	return sortedList;
};

/**
 * Case insensitive sorting of lists on subfields.
 * Returns a sorted copy of the list leaving the original intact.
 */
export const sortBySubField = <
	Field extends string,
	SubField extends string,
	T extends Record<Field, Record<SubField, string>>,
>(
	list: T[],
	field: Field,
	subfield: SubField,
): T[] => {
	const sortedList = [...list];
	sortedList.sort((a, b) =>
		a[field][subfield].localeCompare(b[field][subfield]),
	);
	return sortedList;
};
