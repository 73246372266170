import {
	ActivityIntegrationTableRow,
	hasTaskCode,
	hasCostCode,
} from '../Models/activityTab';

export const activityIntegrationTableRowSearch = (
	searchQuery: string,
	currentRow: (
		| string
		| ActivityIntegrationTableRow['costCode']
		| ActivityIntegrationTableRow['taskCode']
	)[],
): boolean => {
	const lowerCaseQuery = searchQuery.toLowerCase();
	return currentRow.some((cell): boolean => {
		if (typeof cell === 'string') {
			return cell.toLowerCase().includes(lowerCaseQuery);
		} else if (hasTaskCode(cell)) {
			return (
				cell.taskCode.integrationName
					.toLowerCase()
					.includes(lowerCaseQuery) ||
				cell.taskCode.integrationID
					.toLowerCase()
					.includes(lowerCaseQuery)
			);
		} else if (hasCostCode(cell)) {
			return (
				cell.costCode.integrationName
					.toLowerCase()
					.includes(lowerCaseQuery) ||
				cell.costCode.integrationID
					.toLowerCase()
					.includes(lowerCaseQuery)
			);
		}
		return false;
	});
};
