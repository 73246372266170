import {
	Stack,
	SxProps,
	TextField,
	TextFieldProps,
	Theme,
} from '@mui/material';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import {
	DatePicker,
	PickersDayProps,
} from '../../providers/LocalizationProvider';
import {
	SLASH_DATE_FORMAT,
	formatSlashedDate,
} from '../helpers/dateFormatters';
import { WeekPickerDay } from './WeekPickerDay';

export type DateRangeSelectorProps = {
	startDateUseState: [Date, Dispatch<SetStateAction<Date>>];
	endDateUseState: [Date, Dispatch<SetStateAction<Date>>];
	textFieldProps?: Partial<TextFieldProps>;
	wrapperStyles?: SxProps<Theme>;
	maxDateRangeDays?: number;
};

const DateRangeSelector = ({
	startDateUseState,
	endDateUseState,
	textFieldProps,
	wrapperStyles,
	maxDateRangeDays,
}: DateRangeSelectorProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectingStartDate, setSelectingStartDate] = useState(true);
	const [startDate, setStartDate] = startDateUseState;
	const [endDate, setEndDate] = endDateUseState;
	const [minDate, setMinDate] = useState<Date | null>(null);
	const [maxDate, setMaxDate] = useState<Date | null>(null);
	const formattedStartDate = formatSlashedDate(startDate);
	const formattedEndDate = formatSlashedDate(endDate);

	const renderWeekPickerDay = (
		date: Date,
		_: Array<Date>,
		pickersDayProps: PickersDayProps<Date>,
	): JSX.Element => {
		const dateTime = date.getTime();
		const startDateTime = startDate.getTime();
		// Set the end date time to the end of the day so it can match the day picker time
		const endDateTime = startOfDay(endDate).getTime();
		const dayIsBetween =
			dateTime > startDateTime && dateTime <= endDateTime;
		const isFirstDay = dateTime === startDateTime;
		const isLastDay = dateTime === endDateTime;
		const disableDay =
			minDate &&
			maxDate &&
			(dateTime < minDate?.getTime() || dateTime > maxDate?.getTime());
		return (
			<WeekPickerDay
				key={date.getTime()}
				disableDay={disableDay ?? false}
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				{...pickersDayProps}
			/>
		);
	};

	const onChange = (date: Date | null): void => {
		if (!date) return;
		if (selectingStartDate) {
			setStartDate(startOfDay(date));
			setEndDate(endOfDay(date));
			setSelectingStartDate(false);
			if (maxDateRangeDays) {
				setMinDate(subDays(startOfDay(date), maxDateRangeDays));
				setMaxDate(addDays(endOfDay(date), maxDateRangeDays));
			}
		} else if (
			startOfDay(endDate).getTime() === date.getTime() &&
			startDate.getTime() === date.getTime()
		) {
			setIsOpen(false);
			setSelectingStartDate(true);
		} else {
			setSelectingStartDate(true);
			if (date < startDate) {
				setStartDate(date);
			} else {
				setEndDate(endOfDay(date));
			}
			setIsOpen(false);
		}
	};

	const handleOnOpen = (): void => {
		setMaxDate(null);
		setMinDate(null);
		setIsOpen(true);
	};

	return (
		<Stack direction="row" sx={wrapperStyles}>
			<DatePicker
				label="Date Range"
				// This is set to null to trigger onChange everytime
				// We also custom render the days anyway
				value={null}
				onChange={onChange}
				onClose={(): void => {
					setSelectingStartDate(true);
					setIsOpen(false);
				}}
				views={['day']}
				onOpen={handleOnOpen}
				open={isOpen}
				defaultCalendarMonth={startDate}
				inputFormat={SLASH_DATE_FORMAT}
				renderDay={renderWeekPickerDay}
				closeOnSelect={false}
				renderInput={(props: TextFieldProps): JSX.Element => (
					<TextField
						{...props}
						fullWidth
						inputProps={{
							...props.inputProps,
							value: `${formattedStartDate} - ${formattedEndDate}`,
							readOnly: true,
						}}
						{...textFieldProps}
						focused={false}
						sx={{
							'& .MuiInputBase-input': {
								caretColor: 'transparent',
								cursor: 'default',
							},
						}}
					/>
				)}
			/>
		</Stack>
	);
};
export default DateRangeSelector;
