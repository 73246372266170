import { Button, Grid } from '@mui/material';
import firebase from 'firebase';
import { useState } from 'react';
import { AccountsAccountType } from '../../constants/Accounts';
import {
	Site,
	UserDetails,
	UserProfile,
	WorkerType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { UsersOptionsModal } from './UsersOptionsModal';
import { UsersRemoveModal } from './UsersRemoveModal';

export type UserOptionsProps = {
	accountType: AccountsAccountType;
	existingUser?: UserDetails;
	canRemoveUser: boolean;
	sites: Record<string, Site>;
	firebaseApi: Pick<
		FirebaseApi,
		| 'companiesSubscriptionByType'
		| 'updateUserAccountDetails'
		| 'updateUserProfileCompany'
		| 'updateUserDetailsCompanyInfo'
	>;
};

export const UsersOptions = ({
	accountType,
	existingUser,
	canRemoveUser,
	firebaseApi,
	sites,
}: UserOptionsProps): JSX.Element => {
	const [modalOpen, setModalOpen] = useState(false);
	const [removeModalOpen, setRemoveModalOpen] = useState(false);

	// Remove user from company & UserProfile
	const removeUserFromCompany = async (): Promise<void> => {
		if (!existingUser) {
			return;
		}
		const updatedUser: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'company'
			| 'companyID'
			| 'contractedTo'
		> = {
			accountType: '',
			workerType:
				firebase.firestore.FieldValue.delete() as unknown as WorkerType, // delete field
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
			company: '',
			companyID: '',
			contractedTo: null,
		};

		const updatedUserProfile: Pick<UserProfile, 'company'> = {
			company: {
				id: '',
				name: '',
			},
		};

		try {
			await firebaseApi.updateUserProfileCompany(
				existingUser.userID,
				updatedUserProfile,
			);
		} catch (error) {
			// Continue if unable to find UserProfile
			console.error(error);
		}

		await firebaseApi.updateUserDetailsCompanyInfo(
			existingUser.userID,
			updatedUser,
		);
	};

	return (
		<Grid container spacing={1}>
			{existingUser && (
				<UsersOptionsModal
					firebaseApi={firebaseApi}
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					accountType={accountType}
					existingUser={existingUser}
					sites={sites}
				/>
			)}
			{existingUser && (
				<UsersRemoveModal
					modalOpen={removeModalOpen}
					setModalOpen={setRemoveModalOpen}
					userName={existingUser.displayName}
					removeUserFromCompany={removeUserFromCompany}
				/>
			)}
			<Grid item xs={12} sm={canRemoveUser ? 6 : 12}>
				<Button
					fullWidth
					onClick={(): void => setModalOpen(true)}
					variant="outlined">
					Edit
				</Button>
			</Grid>
			{canRemoveUser && (
				<Grid item xs={12} sm={6}>
					<Button
						fullWidth
						onClick={(): void => setRemoveModalOpen(true)}
						variant="contained"
						color="error">
						Remove
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
