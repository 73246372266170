import { startOfWeek } from 'date-fns';
import type { UserProps } from '../../../../constants/Common';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../../../providers/featureFlags/Provider';
import { useTimesheetSearchParams } from '../../hooks/TimesheetNavHooks';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { Create } from './Create';
import {
	CreateDeprecated,
	CreateDeprecatedFirebaseCalls,
} from './CreateDeprecated';

type AvailableFirebaseCalls = Extract<
	keyof FirebaseApi,
	CreateDeprecatedFirebaseCalls
>;

export const CreateWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const {
		context: { endDate, firebaseApi, loading, users },
		dispatch,
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const featureFlags = useFeatureFlagContext();
	const createTimesheetsV3Enabled = featureFlags.get(
		'enableCreateTimesheetsV3',
	);

	const setTimesheetSearchParams = useTimesheetSearchParams();

	const setSelected = (selectedID: string): void => {
		dispatch({ key: 'timesheetID', value: selectedID });
	};

	return (
		<>
			{createTimesheetsV3Enabled ? (
				<Create />
			) : (
				<CreateDeprecated
					firebaseApi={firebaseApi}
					weekEnding={endDate}
					setWeekEnding={(value): void =>
						setTimesheetSearchParams({
							endDate: value,
							startDate: startOfWeek(value),
						})
					}
					userDetails={userDetails}
					users={users}
					setSelected={(value): void => setSelected(value)}
					loading={loading}
					canActionTimesheets={[
						'management',
						'seniorManagement',
					].includes(userDetails.accountType)}
					navigateWithoutRouter={(href): void => {
						// As we cannot change both timesheetID and weekEnding at the same time
						// navigate to page directly via the window.location interface
						window.location.href = href;
					}}
				/>
			)}
		</>
	);
};
