import { Email } from '../../constants/Email';
import { Firestore } from '../firebase';

const EMAILS_COLLECTION = 'emails';

const createEmail = async (email: Email): Promise<string> => {
	const newEmail = await Firestore.collection(EMAILS_COLLECTION).add(email);
	return newEmail.id;
};

const emailsFirebaseApi = {
	createEmail,
};

export default emailsFirebaseApi;
