// Functions for different number input formats that are useful and shared across the app.
// These functions provide the inputComponent to the InputProps in textFields and input components.
import React from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { FixMeLater } from '../../constants/AnyTypes';

/*
	This number format is used to format price values to their appropriate display value
*/
export const priceNumberFormat = (value: number): string => {
	return value.toLocaleString('en-NZ', {
		style: 'currency',
		currency: 'NZD',
	});
};

/*
	This number format is used to fomat calculated percentages from its decimal value into 
	their appropriate display value
*/
export const percentNumberFormatString = (value: number): string => {
	return (value * 100).toFixed(2) + '%';
};

/*
	This number format is used to format calculated percentages from its decimal value into 
	their percent number value
*/
export const formatDecimalToPercent = (value: number): number => {
	return Number((value * 100).toFixed(2));
};

/*
    This function takes a percentage number and returns its decimal value
*/
export const formatPercentToDecimal = (value: number): number => {
	return value / 100;
};

/**
 * Leave hours text field number input
 */
export const leaveHoursNumberFormat = React.forwardRef(
	function leaveHoursNumberFormat(props: FixMeLater, inputRef) {
		const { onChange, ...other } = props;
		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				allowNegative={false}
				onValueChange={(values): void => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				isAllowed={(values): boolean => {
					return (
						(parseFloat(values.value) > 0 &&
							parseFloat(values.value) <= 24) ||
						values.value === ''
					);
				}}
				isNumericString
				style={{ textAlign: 'center' }}
			/>
		);
	},
);

export type BasicNumberFormatProps = {
	inputRef: (instance: NumberFormat<FixMeLater> | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
};

/** Various fields (e.g. payRate) with Contracts */
export const basicNumberFormatter = React.forwardRef<
	NumberFormat<InputAttributes>,
	BasicNumberFormatProps
>(function basicNumberFormatter(props, ref) {
	const { onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onFocus={(value: React.FocusEvent<HTMLInputElement>): void => {
				if (
					value.target.value === '0' ||
					Number(value.target.value) === 0
				) {
					onChange({
						target: {
							name: props.name,
							value: '',
						},
					});
				}
			}}
			onValueChange={(values): void =>
				onChange({
					target: {
						name: props.name ?? '',
						value: values.value,
					},
				})
			}
			thousandSeparator
			isNumericString
			decimalScale={2}
		/>
	);
});

export const percentageNumberFormatter = React.forwardRef<
	NumberFormat<InputAttributes>,
	BasicNumberFormatProps
>(function PercentageNumberFormatter(props, ref) {
	const { onChange, name, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onFocus={(value: React.FocusEvent<HTMLInputElement>): void => {
				if (
					value.target.value === '0' ||
					Number(value.target.value) === 0
				) {
					onChange({
						target: {
							name: name,
							value: '',
						},
					});
				}
			}}
			onValueChange={(values): void =>
				onChange({
					target: {
						name: name ?? '',
						value: values.value,
					},
				})
			}
			isNumericString
			decimalScale={2}
			suffix="%"
			allowNegative={false}
			isAllowed={({ floatValue }): boolean =>
				floatValue === undefined ||
				(floatValue >= 0 && floatValue <= 100)
			}
		/>
	);
});

export type SafetyCourseFormatProps = {
	inputRef: (instance: NumberFormat<FixMeLater> | null) => void;
	format: string;
};

export const safeCourseIDNumberFormat = React.forwardRef<
	NumberFormat<InputAttributes>,
	SafetyCourseFormatProps
>(function SafeCourseIDNumberFormat(props, ref) {
	const { format, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			format={format}
			allowNegative={false}
		/>
	);
});
