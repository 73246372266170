import cloudFunctionApi from '../../../cloudfunctions';
import { UserProps } from '../../../constants/Common';
import { Document } from '../../../constants/Document';
import firebaseApi from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../providers/UserProvider';
import { NewDocument } from './NewDocument';

export const NewDocumentWrapper = ({
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const abortSignal = useAbortController();
	const userAuth = useUserAuthContext();

	const createDocumentFunction = async (
		name: Document['name'],
		employee: Document['employee']['id'],
		fields: Document['documentFields'],
		template: Document['template']['id'],
	): Promise<void> => {
		if (userAuth) {
			await cloudFunctionApi.createDocument(
				abortSignal,
				userAuth,
				name,
				employee,
				fields,
				template,
			);
		}
	};
	return (
		<NewDocument
			userDetails={userDetails}
			createDocument={createDocumentFunction}
			firebaseApi={firebaseApi}
		/>
	);
};
