import CircleIcon from '@mui/icons-material/Circle';
import {
	Grid,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import PageWrapperDisplay from './PageWrapper';

type PrivacyPolicyPage = {
	renderHeader: (showDrawer: boolean) => JSX.Element;
};

const PrivacyPolicyScreen = (props: PrivacyPolicyPage): JSX.Element => {
	return (
		<PageWrapperDisplay renderHeader={props.renderHeader}>
			<Typography variant="h4" mt="5vh" textAlign="center">
				Trade Legion Privacy Policy
			</Typography>
			<Grid
				id="content"
				container
				spacing={2}
				textAlign="justify"
				p="7vw"
				sx={{ flexDirection: 'column' }}>
				<Grid item>
					<Typography variant="subtitle1" paragraph={true}>
						Trade Legion Limited built the Trade Legion app as a
						Commercial app. This SERVICE is provided by Trade Legion
						Limited and is intended for use as is.
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						This page is used to inform visitors regarding our
						policies with the collection, use, and disclosure of
						Personal Information if anyone decided to use our
						Service.
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						If you choose to use our Service, then you agree to the
						collection and use of information in relation to this
						policy. The Personal Information that we collect is used
						for providing and improving the Service. We will not use
						or share your information with anyone except as
						described in this Privacy Policy.
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						The terms used in this Privacy Policy have the same
						meanings as in our Terms and Conditions, which is
						accessible at Trade Legion unless otherwise defined in
						this Privacy Policy.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Information Collection and Use
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						For a better experience, while using our Service, we may
						require you to provide us with certain personally
						identifiable information, including but not limited to
						name, age, location, email address, phone number,
						employer, work history. The information that we request
						will be retained by us and used as described in this
						privacy policy. The app does use third party services
						that may collect information used to identify you.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="subtitle1">
						Link to privacy policy of third party service providers
						used by the app:
					</Typography>
					<List>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								<Link href="https://www.google.com/policies/privacy/">
									Google Play Services
								</Link>
							</ListItemText>
						</ListItem>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								<Link href="https://firebase.google.com/policies/analytics">
									Google Analytics for Firebase
								</Link>
							</ListItemText>
						</ListItem>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								<Link href="https://firebase.google.com/support/privacy/">
									Firebase Crashlytics
								</Link>
							</ListItemText>
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Log Data
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						We want to inform you that whenever you use our Service,
						in a case of an error in the app we collect data and
						information (through third party products) on your phone
						called Log Data. This Log Data may include information
						such as your device Internet Protocol (“IP”) address,
						device name, operating system version, the configuration
						of the app when utilizing our Service, the time and date
						of your use of the Service, and other statistics.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Cookies
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						Cookies are files with a small amount of data that are
						commonly used as anonymous unique identifiers. These are
						sent to your browser from the websites that you visit
						and are stored on your device&apos;s internal memory.
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						This Service does not use these “cookies” explicitly.
						However, the app may use third party code and libraries
						that use “cookies” to collect information and improve
						their services. You have the option to either accept or
						refuse these cookies and know when a cookie is being
						sent to your device. If you choose to refuse our
						cookies, you may not be able to use some portions of
						this Service.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Service Providers
					</Typography>
					<Typography variant="subtitle1">
						We may employ third-party companies and individuals due
						to the following reasons:
					</Typography>
					<List>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								To facilitate our Service
							</ListItemText>
						</ListItem>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								To provide the Service on our behalf
							</ListItemText>
						</ListItem>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								To perform Service-related services
							</ListItemText>
						</ListItem>
						<ListItem alignItems="center">
							<ListItemIcon>
								<CircleIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>
								To assist us in analyzing how our Service is
								used
							</ListItemText>
						</ListItem>
					</List>
					<Typography variant="subtitle1" paragraph={true}>
						We want to inform users of this Service that these third
						parties have access to your Personal Information. The
						reason is to perform the tasks assigned to them on our
						behalf. However, they are obligated not to disclose or
						use the information for any other purpose.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Security
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						We value your trust in providing us your Personal
						Information, thus we are striving to use commercially
						acceptable means of protecting it. But remember that no
						method of transmission over the internet, or method of
						electronic storage is 100% secure and reliable, and we
						cannot guarantee its absolute security.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Links to Other Sites
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						This Service may contain links to other sites. If you
						click on a third-party link, you will be directed to
						that site. Note that these external sites are not
						operated by us. Therefore, we strongly advise you to
						review the Privacy Policy of these websites. We have no
						control over and assume no responsibility for the
						content, privacy policies, or practices of any
						third-party sites or services.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Children’s Privacy
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						These Services do not address anyone under the age of
						13. We do not knowingly collect personally identifiable
						information from children under 13 years of age. In the
						case we discover that a child under 13 has provided us
						with personal information, we immediately delete this
						from our servers. If you are a parent or guardian and
						you are aware that your child has provided us with
						personal information, please contact us so that we will
						be able to do necessary actions.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Changes to This Privacy Policy
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						We may update our Privacy Policy from time to time.
						Thus, you are advised to review this page periodically
						for any changes. We will notify you of any changes by
						posting the new Privacy Policy on this page.
					</Typography>

					<Typography variant="subtitle1">
						This policy is effective as of 2021-07-05
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" mb="3vh">
						Contact Us
					</Typography>
					<Typography variant="subtitle1" paragraph={true}>
						If you have any questions or suggestions about our
						Privacy Policy, do not hesitate to contact us at
						mwebster@trade-legion.co.nz.
					</Typography>
					<Typography variant="subtitle1">
						This privacy policy page was created at{' '}
						<Link href="https://privacypolicytemplate.net">
							privacypolicytemplate.net
						</Link>{' '}
						and modified/generated by{' '}
						<Link href="https://app-privacy-policy-generator.nisrulz.com/">
							{' '}
							App Privacy Policy Generator
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</PageWrapperDisplay>
	);
};

export default PrivacyPolicyScreen;
