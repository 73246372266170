import HelpIcon from '@mui/icons-material/Help';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { DefaultTheme } from '../../Theme';

export type MenuPreferenceToggleProps = {
	name: string;
	icon: JSX.Element | undefined;
	toggled: boolean;
	toggle: () => void;
};

export const MenuPreferenceToggle = ({
	name,
	icon,
	toggled,
	toggle,
}: MenuPreferenceToggleProps): JSX.Element => {
	return (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			justifyContent="space-between">
			<Box display="flex" alignItems="center">
				<Box display="flex" color={DefaultTheme.palette.neutral.main}>
					{icon ?? <HelpIcon />}
				</Box>
				<Typography paddingLeft={1}>{name}</Typography>
			</Box>
			<Switch checked={toggled} onClick={toggle} />
		</Stack>
	);
};
