import { Persona } from '../../constants/Personas';
import { Firestore } from '../firebase';

const COLLECTION = 'users';
const SUBCOLLECTION = 'personas';

const personasByUserID = (
	userID: string,
	callback: (personas: Persona[]) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(userID)
		.collection(SUBCOLLECTION)
		.onSnapshot((snapshot) => {
			const personas: Persona[] = [];
			snapshot.forEach((doc) => {
				personas.push(doc.data() as Persona);
			});
			callback(personas);
		});

const createPersona = async (
	userID: string,
	persona: Omit<Persona, 'id'>,
): Promise<void> => {
	const docRef = Firestore.collection(COLLECTION)
		.doc(userID)
		.collection(SUBCOLLECTION)
		.doc();
	await docRef.set({ ...persona, id: docRef.id });
};

const deletePersona = async (
	userID: string,
	personaID: string,
): Promise<void> => {
	await Firestore.collection(COLLECTION)
		.doc(userID)
		.collection(SUBCOLLECTION)
		.doc(personaID)
		.delete();
};

const personasFirebaseApi = {
	personasByUserID,
	createPersona,
	deletePersona,
};

export default personasFirebaseApi;
