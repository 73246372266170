import { Box, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { IntegrationCard } from '../IntegrationCard/IntegrationCard';

export type IntegrationItem = {
	id: string;
	name: string;
	link: string;
};

export type IntegrationSetupProps = {
	integrationTypes: Record<string, IntegrationItem>;
	description: string | JSX.Element;
	firebaseApi: Pick<FirebaseApi, 'getDownloadUrl'>;
};

export const IntegrationSetup = ({
	integrationTypes,
	description,
	firebaseApi,
}: IntegrationSetupProps): JSX.Element => {
	const navigate = useNavigate();

	const [integrationLogos, setIntegrationLogos] = useState<
		Record<string, string>
	>({});

	const handleFetchLogos = useCallback(async () => {
		const logos: Record<string, string> = Object.fromEntries(
			await Promise.all(
				Object.values(integrationTypes).map(async (integration) => {
					return [
						integration.id,
						await firebaseApi.getDownloadUrl(
							'/integrationLogos/' + integration.id + '.png',
						),
					];
				}),
			),
		);

		return logos;
	}, [firebaseApi, integrationTypes]);

	useEffect(() => {
		let isMounted = true;

		handleFetchLogos().then((logos) => {
			if (isMounted) {
				setIntegrationLogos(logos);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [handleFetchLogos]);

	const onStart = (integrationSelected: string): void => {
		if (integrationSelected !== '') {
			const selectedIntegration = integrationTypes[integrationSelected];
			if (selectedIntegration !== undefined) {
				const link = selectedIntegration.link;
				navigate(link, { replace: false });
			}
		}
	};

	return (
		<Stack spacing={2}>
			<Card>
				<CardHeader title="Initial Setup" />
				<CardContent>{description}</CardContent>
			</Card>
			<Box>
				<Grid container spacing={2}>
					{Object.entries(integrationTypes).map(([key, value]) => (
						<Grid item key={key} xs={12} sm={4} md={3}>
							<IntegrationCard
								id={key}
								onStart={onStart}
								label={value.name}
								link={integrationLogos[key]}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
		</Stack>
	);
};
