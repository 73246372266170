import HelpIcon from '@mui/icons-material/Help';
import { Grid, Typography, Tooltip, IconButton, Divider } from '@mui/material';
import { Fragment } from 'react';
import {
	HoursComponent,
	HoursRecord,
	UserProfile,
} from '../../constants/Common';
import { hoursToMostSignificantUnit } from '../helpers/dateUtilities';

/** Turns an HoursRecord object into a consistently list of entries, with Total always at the bottom
 *
 * Sorted first by hoursWorked, then alphabetically */
export const hoursRecordToList = (
	hoursRecord: HoursRecord,
): [string, HoursComponent][] => {
	return Object.entries(hoursRecord).sort(
		([keyA, valueA], [keyB, valueB]) => {
			// Total should always be at the bottom
			if (keyA === 'Total') {
				return 1;
			} else if (keyB === 'Total') {
				return -1;
			} else if (valueA.hoursWorked === valueB.hoursWorked) {
				// If the hours worked are the same, then sort alphabetically
				return valueA.activityName.localeCompare(valueB.activityName);
			} else {
				return valueB.hoursWorked - valueA.hoursWorked;
			}
		},
	);
};
type HandlerEmployeeProfileActivityTimesProps = {
	employee: UserProfile;
	isEditing: boolean;
};

export const HandlerEmployeeProfileActivityTimes = ({
	employee,
	isEditing,
}: HandlerEmployeeProfileActivityTimesProps): JSX.Element => {
	const hoursList = hoursRecordToList(employee.totalHours)
		.slice(0, -1)
		.slice(0, 3);
	const toolTipText =
		'These times are rounded down given 8 hour days, 40 hour weeks, 4 weeks a month, 50 weeks a year.';
	const textColor = isEditing ? 'neutral.main' : 'default';
	return (
		<Grid container item xs={12} spacing={1}>
			<Grid item xs={6}>
				<Typography color={textColor}>Total Experience</Typography>
			</Grid>
			<Grid
				item
				xs={6}
				display="flex"
				alignItems="center"
				position="relative"
				justifyContent="space-between">
				<Typography color={textColor}>
					{hoursToMostSignificantUnit(
						employee.totalHours.Total.hoursWorked,
					)}
				</Typography>
				<Tooltip
					title={toolTipText}
					componentsProps={{
						tooltip: {
							sx: {
								fontSize: '1rem',
							},
						},
					}}>
					<IconButton
						size="small"
						sx={{
							position: 'absolute',
							top: 0,
							right: 0,
						}}>
						<HelpIcon />
					</IconButton>
				</Tooltip>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{hoursList.length > 0 && (
				<Grid item xs={12}>
					<Typography color={textColor} fontWeight="bolder">
						Top Activities
					</Typography>
				</Grid>
			)}
			{hoursList.map(([id, item]) => (
				<Fragment key={id}>
					<Grid item xs={6}>
						<Typography color={textColor}>
							{item.activityName}
						</Typography>
					</Grid>
					<Grid
						item
						xs={6}
						display="flex"
						alignItems="center"
						position="relative"
						justifyContent="space-between">
						<Typography color={textColor}>
							{hoursToMostSignificantUnit(item.hoursWorked)}
						</Typography>
					</Grid>
				</Fragment>
			))}
		</Grid>
	);
};
