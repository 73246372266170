import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import Image, { ImageProps } from './Image';

type UploadImageButtonProps = {
	imageProps: ImageProps;
	setBlob: (value: React.SetStateAction<Blob | null>) => void;
};

export const UploadImageButton = ({
	imageProps,
	setBlob,
}: UploadImageButtonProps): JSX.Element => {
	const [error, setError] = useState('');

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files) {
			if (event.target.files[0].size > 5242880) {
				setError(
					'Your profile picture is too large - cannot be more than 5MB',
				);
				return;
			} else {
				setError('');
			}

			setBlob(event.target.files[0]);
		}
	};

	return (
		<>
			<Image {...imageProps} />
			<Button
				role="button"
				fullWidth
				variant="contained"
				component="label"
				sx={{ marginTop: 3 }}>
				Select picture
				<input
					data-testid="input"
					type="file"
					hidden
					accept="image/*"
					onChange={onChange}
				/>
			</Button>
			{error && (
				<Typography
					role="contentinfo"
					color="error.main"
					textAlign="center">
					{error}
				</Typography>
			)}
		</>
	);
};
