import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import cloudFunctionApi from '../../../../cloudfunctions';
import { isRequiredLeaveMapped } from '../../../../constants/Leave';
import firebaseApi from '../../../../firebase/firebaseApi';
import { InvoicingType } from '../../../../models/Integrations/InvoicingIntegration';
import {
	DEFAULT_ACTIVITY_KEY,
	PayrollType,
} from '../../../../models/Integrations/PayrollIntegration';
import { ActivityMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/ActivityMappingTab';
import { LeaveMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/LeaveMappingTab';
import { BasePayrollPage } from '../../IntegrationPages/BaseIntegrationPage';
import { IntegrationTab } from '../../IntegrationPages/IntegrationTab';
import { NavigateToIntegrationDialog } from '../../IntegrationUIComponents/NavigateToIntegrationDialog';
import { PayrollEmployeesTab } from '../PayrollEmployeesTab';

const REDIRECT_URL = process.env.REACT_APP_URL;
const XERO_LOGIN_BASE_URL = process.env.REACT_APP_XERO_LOGIN_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
const XERO_LOGIN_REDIRECT = `${REDIRECT_URL}/connect/xero`;
const XERO_LOGIN_API = `${XERO_LOGIN_BASE_URL}/identity/connect/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${XERO_LOGIN_REDIRECT}&scope=openid offline_access payroll.employees payroll.settings.read payroll.timesheets`;

export const XeroPayrollPage = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [token, setToken] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);
	const [openNavigateToInvoicingDialog, setOpenNavigateToInvoicingDialog] =
		useState(false);

	useEffect(() => {
		const code = searchParams.get('code');
		setToken(code);
	}, [searchParams]);

	const tabs: IntegrationTab<PayrollType>[] = [
		{
			index: 0,
			name: 'Employees',
			content: (props) => (
				<PayrollEmployeesTab
					integrationName="Xero"
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
					{...props}
				/>
			),
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchPayrollEmployeeIDs,
		},
		{
			index: 1,
			name: 'Activities',
			content: (props) => (
				<ActivityMappingTab firebaseApi={firebaseApi} {...props} />
			),
			optional: false,
			canMoveNext: (payrollIntegration) =>
				DEFAULT_ACTIVITY_KEY in payrollIntegration.activityTypes,
			fetchData: cloudFunctionApi.fetchPayrollActivityIDs,
		},
		{
			index: 2,
			name: 'Leave',
			content: (props) => <LeaveMappingTab {...props} />,
			optional: false,
			canMoveNext: (payrollIntegration) =>
				isRequiredLeaveMapped(
					Object.keys(payrollIntegration.leaveTypes),
				),
			fetchData: cloudFunctionApi.fetchPayrollLeaveIDs,
		},
	];

	const handleAuthenticate = (): void => {
		window.open(XERO_LOGIN_API, '_self');
	};

	const renderXeroPayrollAuthentication = (): JSX.Element => (
		<>
			<Typography>
				Click &quot;Authenticate&quot; below to connect your Xero
				Payroll with Trade Legion.
			</Typography>
			{showError && (
				<>
					<Typography
						color="error"
						variant="subtitle2"
						paddingTop="1rem">
						Something went wrong and we failed to connect to Xero.
					</Typography>
					<Typography color="error" variant="subtitle2">
						Please contact Trade Legion for assistance.
					</Typography>
				</>
			)}
		</>
	);

	const handleSubmit = useCallback(() => {
		searchParams.delete('code');
		searchParams.delete('scope');
		searchParams.delete('session_state');
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const clearAll = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/connect', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	const handleNavigateToXeroInvoicing = (): void => {
		navigate('/connect-invoicing/xero');
	};

	const handleEndIntegration = (
		companyIntegrations: Partial<{
			invoicingIntegration: InvoicingType;
			payrollIntegrated: PayrollType;
		}>,
	): void => {
		if (companyIntegrations.invoicingIntegration === 'Xero') {
			setOpenNavigateToInvoicingDialog(true);
		}
	};

	const renderNavigateToInvoicingDialog = (): JSX.Element => (
		<NavigateToIntegrationDialog
			openNavigateToIntegrationDialogState={[
				openNavigateToInvoicingDialog,
				setOpenNavigateToInvoicingDialog,
			]}
			handleNavigateToIntegration={handleNavigateToXeroInvoicing}
			integrations={{
				integrationName: 'Xero',
				integrationClass: 'Payroll',
				otherIntegrationName: {
					invoicingIntegration: 'Xero',
				},
			}}
		/>
	);

	if (!XERO_LOGIN_BASE_URL || !CLIENT_ID) {
		return (
			<Stack spacing={1} alignContent="center">
				<Typography textAlign="center">
					{`Xero Payroll isn't correctly setup. ${(
						<Link to="/contact-us">Contact Trade Legion</Link>
					)}.`}
				</Typography>
				<Button variant="outlined" fullWidth onClick={handleCancel}>
					Back
				</Button>
			</Stack>
		);
	}

	return (
		<>
			{renderNavigateToInvoicingDialog()}
			<BasePayrollPage
				handleAuthenticate={handleAuthenticate}
				integrationType="Xero"
				tabs={tabs}
				renderAuthenticationContent={renderXeroPayrollAuthentication}
				token={token}
				handleSubmitError={clearAll}
				handleSubmitSuccess={handleSubmit}
				handleCancelAuthenticate={handleCancel}
				fetchToken={cloudFunctionApi.fetchPayrollTokens}
				postEndIntegrationStep={handleEndIntegration}
				endIntegrationHelperText="(This will require you reauth your Xero Invoicing integration, if you have one)"
				firebaseApi={firebaseApi}
				deleteIntegrationFunction={
					cloudFunctionApi.deletePayrollIntegration
				}
			/>
		</>
	);
};
