import TuneIcon from '@mui/icons-material/Tune';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NoSiteWarning = (): JSX.Element => {
	const navigate = useNavigate();

	const navigateToNewSite = (): void => {
		navigate('/options/new-site');
	};

	return (
		<Card variant="outlined">
			<CardContent>
				<Stack
					height="calc(100vh - 224px)"
					alignItems="center"
					justifyContent="center"
					spacing={1}>
					<TuneIcon fontSize="large" />
					<Typography variant="h5">No Site Selected</Typography>

					<Typography variant="body2">
						Please select a site to edit, or create one
					</Typography>

					<Button onClick={navigateToNewSite} variant="contained">
						Create a Site
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
};
