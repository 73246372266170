import { EmployeeLink } from '../../../../../models/Integrations/ProjectTrackingIntegration';
import { IntegrationLinkStatus } from '../../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';

export type TempEmployeeLink = EmployeeLink | { id: string };

export type EmployeeIntegrationTableRow = {
	id: string;
	name: string;
	status: IntegrationLinkStatus;
	account: TempEmployeeLink;
};

export const isEmployeeLink = (
	value: TempEmployeeLink,
): value is EmployeeLink => (value as EmployeeLink).name !== undefined;
