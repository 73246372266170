import { PDFExportProps, savePDF } from '@progress/kendo-react-pdf';
import type { MutableRefObject } from 'react';

type ExportPDFProps<T extends HTMLElement> = {
	ref: MutableRefObject<T | null>;
	title: string;
	styleProps?: PDFExportProps;
	callback?: () => void;
};

export const exportPDF = <T extends HTMLElement>({
	ref,
	title,
	styleProps,
	callback,
}: ExportPDFProps<T>): void => {
	const refCurrent = ref?.current;
	if (refCurrent != null) {
		savePDF(
			refCurrent,
			{
				paperSize: 'A4',
				scale: 0.6,
				margin: {
					right: '2cm',
					left: '2cm',
					top: '1cm',
					bottom: '1cm',
				},
				forcePageBreak: 'PDFReportStylesControlPanelninety',
				fileName: title,
				...styleProps,
			},
			callback,
		);
	}
};
