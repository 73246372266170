import { createTheme, Theme, ThemeProvider } from '@mui/material';

export const ReportsTableTheme = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => (
	<ThemeProvider
		theme={(baseTheme: Theme): Theme =>
			createTheme({
				...baseTheme,
				components: {
					...baseTheme.components,
					MUIDataTableHeadCell: {
						styleOverrides: {
							root: {
								paddingBottom: 8,
							},
						},
					},
					MuiPopover: {
						styleOverrides: {
							paper: {
								width: '50%',
								maxWidth: '800px !important',
							},
						},
					},
					MuiTable: {
						styleOverrides: {
							root: {
								'@page': {
									orientation: 'landscape',
								},
							},
						},
					},
					// MUIDataTable styles are not recognised by the MUI Theme
					// This is a hacky workaround, if their types were good we would not need this
					...{
						MUIDataTable: {
							styleOverrides: {
								paper: {
									borderTopLeftRadius: 0,
									borderTopRightRadius: 0,
									display: 'flex',
									flexDirection: 'column',
									flexGrow: 1,
								},
							},
						},
					},
				},
			})
		}>
		{children}
	</ThemeProvider>
);
