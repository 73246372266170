import { Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { UserDetails } from '../../../../../../constants/Common';
import { Note } from '../../../../../../constants/Note';
import { BasicNote } from './BasicNote';
import { InputNote } from './InputNote';

export type NotesSectionProps = {
	notes: Pick<Note, 'id' | 'user' | 'note'>[];
	editNotes?: {
		user: Pick<UserDetails, 'userID' | 'displayName'>;
		onNoteChange: (id: string, note: string) => void;
		disabled: boolean;
	};
	isGeneralNotes?: boolean;
};

const NotesSectionImpl = ({
	notes,
	editNotes,
	isGeneralNotes = false,
}: NotesSectionProps): JSX.Element => {
	const heading = isGeneralNotes ? 'General Notes' : 'Notes';
	const basicNotes = notes.filter(
		(note) => note.user.id !== editNotes?.user.userID,
	);
	const userNote = notes.find(
		(note) => note.user.id === editNotes?.user.userID,
	);

	return (
		<Stack paddingY={isGeneralNotes ? 1 : 0} spacing={0.5}>
			<Typography fontWeight="bold">{heading}</Typography>
			{basicNotes.map((note) => (
				<BasicNote key={`note-${note.id}`} note={note} />
			))}
			{editNotes && userNote && (
				<InputNote
					note={{ note: userNote.note, user: userNote.user }}
					onNoteChange={(note): void =>
						editNotes.onNoteChange(userNote.id, note)
					}
					disabled={editNotes.disabled ?? false}
				/>
			)}
		</Stack>
	);
};

const propsAreEqual = (
	prev: Readonly<NotesSectionProps>,
	next: Readonly<NotesSectionProps>,
): boolean => {
	const notesEqual = prev.notes.every((note, index) => {
		const nextNote = next.notes[index];
		return note.id === nextNote?.id && note.note === nextNote?.note;
	});

	return (
		notesEqual &&
		prev.isGeneralNotes === next.isGeneralNotes &&
		prev.editNotes?.disabled === next.editNotes?.disabled &&
		prev.editNotes?.user.userID === next.editNotes?.user.userID &&
		prev.editNotes?.user.displayName === next.editNotes?.user.displayName
	);
};

export const NotesSection = memo(NotesSectionImpl, propsAreEqual);
