import React, { useEffect } from 'react';
import { LoggingContext, LoggingService } from './context';

type LoggingProviderProps = {
	children: React.ReactNode;
	loggingService: LoggingService;
};

export const LoggingProvider = ({
	children,
	loggingService,
}: LoggingProviderProps): JSX.Element => {
	useEffect(() => {
		loggingService.setup();
	}, [loggingService]);

	return (
		<LoggingContext.Provider value={loggingService}>
			{children}
		</LoggingContext.Provider>
	);
};
