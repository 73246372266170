import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Link, List, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import {
	ErrorType,
	ExportErrors,
} from '../../../../cloudfunctions/projectTracking';
import type { UserDetails } from '../../../../constants/Common';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../../../providers/UserProvider';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import { CustomActionDialog } from '../../../Dialogs/CustomActionDialog';
import { formatSearchParamsDate } from '../../../helpers/dateFormatters';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';

const renderTimesheetWeekDate = (timesheet: Timesheet): string => {
	const utcDate = timesheet.weekEnding.toDate();
	const nzTime = utcToZonedTime(utcDate, 'Pacific/Auckland');
	return formatSearchParamsDate(nzTime);
};

const formatError = (
	timesheet: Timesheet,
	error: ExportErrors,
	user: UserDetails,
): JSX.Element => {
	const canEditEmployeeLinks =
		user.accountType !== 'seniorManagement' &&
		error.type === ErrorType.EmployeeLink;

	return (
		<CustomAlert
			key={`${timesheet.id}-${error.name}-${error.type}`}
			sx={{ mb: 1 }}
			title={`${timesheet.employer.name} - ${timesheet.site.name} - ${
				timesheet.employee.name
			} - ${renderTimesheetWeekDate(timesheet)}`}
			alertText={`Missing ${dueTo[error.type].name} link for ${
				error.name
			}`}
			actionOnClick={(): void => {
				window?.open(
					`/project-tracking/catprojects/${
						dueTo[error.type].link
					}?name=${error.name}`,
					'_blank',
					'noreferrer',
				);
			}}
			severity="warning"
			actionText={
				canEditEmployeeLinks // only senior management can actually edit employee links
					? undefined
					: 'Configure Link'
			}
		/>
	);
};

type TimesheetData = {
	timesheet: Timesheet;
	errors: ExportErrors[];
}[];

export type ErrorDialogProps = {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	errors: ExportErrors[];
	firebaseApi: Pick<FirebaseApi, 'getTimesheetById'>;
};

const dueTo: Record<ErrorType, { link: string; name: string }> = {
	[ErrorType.EmployeeLink]: {
		link: 'employees',
		name: 'Employee',
	},
	[ErrorType.ActivityLink]: {
		link: 'activities',
		name: 'Activity',
	},
	[ErrorType.ProjectLink]: {
		link: 'sites',
		name: 'Site',
	},
};

export const ErrorDialog = ({
	isOpen,
	setIsOpen,
	errors,
	firebaseApi,
}: ErrorDialogProps): JSX.Element => {
	const [timesheets, setTimesheets] = useState<TimesheetData>([]);
	const user = useUserDetailsContext();

	useEffect(() => {
		const fetchTimesheets = async (): Promise<void> => {
			const errorsByTimesheetId = errors.reduce<
				Record<string, ExportErrors[]>
			>((acc, error) => {
				if (!(error.id in acc)) {
					acc[error.id] = [];
				}
				acc[error.id].push(error);
				return acc;
			}, {});

			const errorsAndTimesheets = Object.entries(errorsByTimesheetId).map(
				async ([timesheetId, errors]) => ({
					timesheet: await firebaseApi.getTimesheetById(timesheetId),
					errors,
				}),
			);

			setTimesheets(await Promise.all(errorsAndTimesheets));
		};

		fetchTimesheets();
	}, [errors, firebaseApi]);

	const content =
		// presume that an empty timesheets mean loading, as this dialog shouldn't even be open if there are no timesheets with errors to display
		user === null || timesheets.length === 0 ? (
			<LoadingDots />
		) : (
			<>
				<Typography>
					Failed to export all timesheets. The following timesheets
					were skipped and require additional integration
					configuration to export:
				</Typography>
				<List>
					{timesheets.map(({ timesheet, errors }) =>
						errors.map((error) =>
							formatError(timesheet, error, user),
						),
					)}
				</List>
			</>
		);

	return (
		<CustomActionDialog
			title="CATProjects Export Warnings"
			content={content}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			sx={{ minWidth: 'lg' }}
			actionButton={
				<Button
					variant="contained"
					LinkComponent={Link}
					disabled={timesheets.length === 0}
					href={
						timesheets.length > 0
							? `../../timesheets/overview?endDate=${renderTimesheetWeekDate(
									timesheets[0].timesheet, // we only do this for a single week, so all timesheets should be the same
							  )}`
							: ''
					}
					target="_blank"
					endIcon={<OpenInNewIcon />}>
					View Timesheets
				</Button>
			}
		/>
	);
};
