import { TradeLegionLink, IntegrationLink } from './IntegrationElements';

export type BaseProjectTrackingIntegration = {
	type: ProjectTrackingType;
	status: IntegrationStatus;
	companyID: string;
};

export const projectTrackingType = {
	Workbench: 'Workbench',
	CATProjects: 'CATProjects',
} as const;
export type ProjectTrackingType =
	(typeof projectTrackingType)[keyof typeof projectTrackingType];

export const manualProjectTrackingIntegration = [
	projectTrackingType.CATProjects,
];
export type ManualProjectTrackingIntegration =
	(typeof manualProjectTrackingIntegration)[number];

export const isManualProjectTrackingIntegration = (
	integrationType: ProjectTrackingType,
): integrationType is ManualProjectTrackingIntegration =>
	manualProjectTrackingIntegration.includes(
		integrationType as ManualProjectTrackingIntegration,
	);

export enum IntegrationStatus {
	Active = 'Active',
	Incomplete = 'Incomplete',
	Unauthorised = 'Unauthorised',
}

export type ProjectTrackingIntegration =
	| ({
			type: Exclude<
				ProjectTrackingType,
				typeof projectTrackingType.Workbench
			>;
	  } & BaseProjectTrackingIntegration)
	| ({
			type: Extract<
				ProjectTrackingType,
				typeof projectTrackingType.Workbench
			>;
			baseURL: string;
	  } & BaseProjectTrackingIntegration);

export enum ProjectTrackingLinks {
	Employee = 'Employee',
	Site = 'Site',
	Activity = 'Activity',
}

type LinkType<T extends ProjectTrackingLinks> = { type: T };
export type Link = LinkType<
	ProjectTrackingLinks.Employee | ProjectTrackingLinks.Site
> &
	TradeLegionLink &
	IntegrationLink;

// Sub-Collection under 'Workbench' projectTrackingIntegrations -> projects -> tasks
export type ActivityLink = LinkType<ProjectTrackingLinks.Activity> &
	TradeLegionLink & {
		taskCode: IntegrationLink;
		costCode: IntegrationLink;
		timeCode?: IntegrationLink;
	};
// Sub-Collection under 'Workbench' projectTrackingIntegrations -> projects
export type SiteLink = Link & LinkType<ProjectTrackingLinks.Site>;
// Sub-Collection under 'Workbench' projectTrackingIntegrations -> employees
export type EmployeeLink = Link & LinkType<ProjectTrackingLinks.Employee>;

export type WorkbenchProjectTrackingIntegrationLinks =
	| ActivityLink
	| SiteLink
	| EmployeeLink;

export type SiteActivityLink = { siteID: string; activityLink: ActivityLink };
