import firebaseApi from '../../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../../providers/UserProvider';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { ComplianceHistory } from './ComplianceHistory';

export const ComplianceHistoryWrapper = (): JSX.Element => {
	const userDetails = useUserDetailsContext();

	return !userDetails ? (
		<LoadingDots />
	) : (
		<ComplianceHistory
			userDetails={userDetails}
			firebaseApi={firebaseApi}
		/>
	);
};
