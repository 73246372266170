import { PayrollDetails } from '../../constants/Payroll';
import { Firestore } from '../firebase';

const payrollDetailsByUserIDSubscription = (
	userID: string,
	callback: (payrollDetails: PayrollDetails | null) => void,
): (() => void) => {
	return Firestore.collection('users')
		.doc(userID)
		.collection('payrollDetails')
		.doc(userID)
		.onSnapshot((docSnapshot) => {
			if (docSnapshot.exists)
				callback(docSnapshot.data() as PayrollDetails);
			else callback(null);
		});
};

const payrollFirebaseApi = { payrollDetailsByUserIDSubscription };

export default payrollFirebaseApi;
