import { BaseIntegrationLink } from '../../models/Integrations/IntegrationElements';
import { IntegrationStatus } from '../../models/Integrations/IntegrationStatus';
import {
	InvoicingIntegrationAtRest,
	InvoicingLink,
} from '../../models/Integrations/InvoicingIntegration';
import { Firestore } from '../firebase';

const INVOICING_INTEGRATIONS_COLLECTION = 'invoicingIntegrations';
const PLACEMENTS_SUB_COLLECTION = 'placements';

const invoicingIntegrationClientSubscription = (
	companyID: string,
	onNext: (snapshot: InvoicingLink[]) => void,
): (() => void) =>
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.collection('contacts')
		.onSnapshot((snapshot) =>
			onNext(snapshot.docs.map((doc) => doc.data() as InvoicingLink)),
		);

const setInvoicingIntegrationClient = async (
	companyID: string,
	link: InvoicingLink,
): Promise<void> =>
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.collection('contacts')
		.doc(link.id)
		.set(link);

const updateInvoicingIntegrationStatus = async (
	companyID: string,
	status: IntegrationStatus,
): Promise<void> => {
	const updatedIntegration: Pick<InvoicingIntegrationAtRest, 'status'> = {
		status,
	};
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.update(updatedIntegration);
};

const removeInvoicingIntegrationContactLink = async (
	companyID: string,
	link: InvoicingLink,
): Promise<void> => {
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.collection('contacts')
		.doc(link.id)
		.delete();
};

const invoicingIntegrationSubscription = (
	companyID: string,
	onNext: (integration: InvoicingIntegrationAtRest | null) => void,
): (() => void) =>
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.onSnapshot((doc) =>
			onNext(
				doc.exists ? (doc.data() as InvoicingIntegrationAtRest) : null,
			),
		);

const createInvoicingIntegrationPlacement = async (
	companyID: string,
	placement: BaseIntegrationLink,
): Promise<void> =>
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.collection(PLACEMENTS_SUB_COLLECTION)
		.doc(placement.id)
		.set(placement);

const deleteInvoicingIntegrationPlacement = async (
	companyID: string,
	placementID: string,
): Promise<void> =>
	Firestore.collection(INVOICING_INTEGRATIONS_COLLECTION)
		.doc(companyID)
		.collection(PLACEMENTS_SUB_COLLECTION)
		.doc(placementID)
		.delete();

const invoicingIntegrationsFirebaseApi = {
	createInvoicingIntegrationPlacement,
	deleteInvoicingIntegrationPlacement,
	invoicingIntegrationClientSubscription,
	invoicingIntegrationSubscription,
	removeInvoicingIntegrationContactLink,
	setInvoicingIntegrationClient,
	updateInvoicingIntegrationStatus,
};

export default invoicingIntegrationsFirebaseApi;
