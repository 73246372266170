import firebaseApi from '../../../firebase/firebaseApi';
import { useDocumentsContext } from '../DocumentsWrapper';
import { useDocumentSearchParams } from '../hooks/DocumentNavHooks';
import { AllDocuments } from './AllDocuments';

export const AllDocumentsWrapper = (): JSX.Element => {
	const {
		context: { loading, documentsData, documentID },
	} = useDocumentsContext();

	const setDocumentSearchParams = useDocumentSearchParams();

	return (
		<AllDocuments
			selected={documentID}
			setSelected={(value): void =>
				setDocumentSearchParams({ documentID: value })
			}
			loading={loading}
			documentsData={documentsData}
			storageApi={firebaseApi}
		/>
	);
};
