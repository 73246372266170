import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Stack,
	MenuItem,
	Box,
	Typography,
	Grid,
	Button,
} from '@mui/material';
import { useState } from 'react';
import { InductionStep } from '../../../constants/InductionStep';
import { PartialWithID } from '../../../constants/TypescriptUtilities';
import { CustomSnackBar } from '../../SnackBar/SnackBar';
import { InductionStepImageButton } from './InductionStepImageButton';

export type InductionStepDialogProps = {
	open: boolean;
	onClose: () => void;
	onCreate: (
		step: Omit<InductionStep, 'id' | 'imageLocation'>,
		imageBlob: string,
		position: number,
	) => Promise<boolean | undefined>;
	onUpdate: (
		step: PartialWithID<InductionStep>,
		imageBlob: string,
		position: number,
	) => Promise<boolean | undefined>;
	onDelete: (step: InductionStep) => Promise<boolean | undefined>;
	steps: InductionStep[];
	currentStep: InductionStep | null;
	stepPosition?: number;
	imgUrl?: string;
};

export const InductionStepDialog = ({
	open,
	onClose,
	onCreate,
	onUpdate,
	onDelete,
	steps,
	currentStep,
	imgUrl,
	stepPosition,
}: InductionStepDialogProps): JSX.Element => {
	const [title, setTitle] = useState(currentStep?.title ?? '');
	const [body, setBody] = useState(currentStep?.body ?? '');
	const [imgBlob, setImgBlob] = useState(imgUrl ?? '');
	const [position, setPosition] = useState<number>(
		stepPosition ?? steps.length + 1,
	);
	const [loadingSave, setLoadingSave] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [errors, setErrors] = useState<{
		title?: string;
		body?: string;
		img?: string;
	}>({});
	const [showSnackError, setShowSnackError] = useState(false);
	const stepOptions = Array.from(
		{ length: currentStep ? steps.length : steps.length + 1 },
		(_, i) => i + 1,
	);

	const validate = (): boolean => {
		const newErrors: { title?: string; body?: string; img?: string } = {};
		if (title === '') newErrors.title = 'Title is required';
		if (body === '') newErrors.body = 'Body is required';
		if (imgBlob === '') newErrors.img = 'Image is required';

		setErrors(newErrors);

		return Object.keys(newErrors).length === 0;
	};

	const handleSave = async (): Promise<void> => {
		if (!validate()) return;
		// no need to save if nothing has changed
		if (
			body === currentStep?.body &&
			title === currentStep.title &&
			imgBlob === imgUrl &&
			position === stepPosition
		) {
			handleClose();
			return;
		}

		let success: boolean | undefined;
		setLoadingSave(true);
		if (currentStep) {
			const newStep: PartialWithID<InductionStep> = {
				id: currentStep.id ?? '',
			};
			if (currentStep.title !== title) newStep.title = title;
			if (currentStep.body !== body) newStep.body = body;
			success = await onUpdate(newStep, imgBlob, position - 1);
		} else {
			const newStep: Omit<InductionStep, 'id' | 'imageLocation'> = {
				title,
				body,
			};
			success = await onCreate(newStep, imgBlob, position - 1);
		}
		setLoadingSave(false);
		if (success) {
			handleClose();
		} else {
			setShowSnackError(true);
		}
	};

	const handleClose = (): void => {
		if (loadingSave || loadingDelete) return;
		onClose();
	};

	const handleDelete = async (): Promise<void> => {
		if (!currentStep) {
			return;
		}

		setLoadingDelete(true);
		const success = await onDelete(currentStep);
		setLoadingDelete(false);
		if (success) {
			handleClose();
		} else {
			setShowSnackError(true);
		}
	};

	const handleImageUpload = (file: File): void => {
		const reader = new FileReader();
		reader.onload = (event): void => {
			if (event.target && typeof event.target.result === 'string') {
				setImgBlob(event.target.result);
				setErrors((prev) => ({ ...prev, img: undefined }));
			}
		};
		reader.readAsDataURL(file);
	};

	const handleTitleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setTitle(event.target.value);
		setErrors((prev) => ({ ...prev, title: undefined }));
	};

	const handleBodyChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setBody(event.target.value);
		setErrors((prev) => ({ ...prev, body: undefined }));
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
			<CustomSnackBar
				open={showSnackError}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				onClose={(): void => setShowSnackError(false)}
				snackBarText="Error Saving Step"
				severity="error"
			/>
			<DialogTitle>
				{!currentStep ? 'Add' : 'Edit'} Induction Step
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} pt={1}>
					<Grid item xs={12} sm={6}>
						<Stack spacing={2}>
							<TextField
								label="Title"
								value={title}
								onChange={handleTitleChange}
								size="small"
								fullWidth
								error={!!errors.title}
								helperText={errors.title}
								disabled={loadingSave}
								required
							/>
							<TextField
								label="Body"
								value={body}
								onChange={handleBodyChange}
								fullWidth
								size="small"
								multiline
								rows={8}
								error={!!errors.body}
								helperText={errors.body}
								disabled={loadingSave}
								required
							/>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center">
								<Typography>Step Position</Typography>
								<TextField
									select
									value={position}
									onChange={(event): void =>
										setPosition(
											parseInt(event.target.value),
										)
									}
									size="small"
									variant="outlined"
									sx={{ width: '100px' }}
									disabled={loadingSave}
									required>
									{stepOptions.map((pos) => (
										<MenuItem key={pos} value={pos}>
											{pos}
										</MenuItem>
									))}
								</TextField>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6} pb={1}>
						<InductionStepImageButton
							img={imgBlob}
							onImageUpload={handleImageUpload}
							disable={loadingSave}
						/>
						{errors.img && (
							<Typography color="error" variant="caption">
								{errors.img}
							</Typography>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item>
						{currentStep && (
							<LoadingButton
								variant="outlined"
								onClick={handleDelete}
								disabled={loadingSave}
								loading={loadingDelete}>
								Delete Step
							</LoadingButton>
						)}
					</Grid>
					<Grid item>
						<Box display="flex" gap={1}>
							<Button
								onClick={handleClose}
								variant="outlined"
								disabled={loadingSave || loadingDelete}>
								Cancel
							</Button>
							<LoadingButton
								onClick={handleSave}
								variant="contained"
								disabled={loadingDelete}
								loading={loadingSave}>
								Save
							</LoadingButton>
						</Box>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
