import { TextFieldProps } from '@mui/material';
import { startOfWeek } from 'date-fns';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import DateWeekSelector from '../DateWeekSelector/DateWeekSelector';
import { AddNew } from './AddNew';
import { DataTable } from './DataTable';

export type DateDataTableProps = {
	tableData: string[][] | object[];
	columns: MUIDataTableColumnDef[];
	title: React.ReactNode;
	customTableOptions?: MUIDataTableOptions;
	date: [Date, (date: Date) => void];
	selection?: [number, (selected: number) => void];
	onClick?: () => void;
	onClickDisabled?: boolean;
	type?: string;
	allowFuture?: boolean;
	weekEnding?: boolean;
	showWeekRange?: boolean;
};

export const DateDataTable = ({
	showWeekRange = true,
	...props
}: DateDataTableProps): JSX.Element => {
	const [startDate, setStartDate] = props.date;
	const onChange = (date: Date | null): void => {
		setStartDate(date ?? startOfWeek(new Date()));
	};
	const textFieldProps: Partial<TextFieldProps> = {
		size: 'small',
	};

	return (
		<DataTable
			{...props}
			customTableOptions={{
				...props.customTableOptions,
			}}
			title={
				props.onClick ? (
					<AddNew
						onClick={props.onClick}
						type={props.type}
						title="Add Timesheet"
						disabled={props.onClickDisabled}
					/>
				) : (
					props.title
				)
			}
			customToolbar={(): JSX.Element => (
				<DateWeekSelector
					date={startDate}
					onChange={onChange}
					textFieldProps={textFieldProps}
					allowFuture={props.allowFuture}
					showWeekChangeArrows
					wrapperStyles={{
						// Needed to properly align with the MUI Data Table actions
						display: 'inline-flex',
						verticalAlign: 'middle',
					}}
					weekEnding={props.weekEnding}
					showWeekRange={showWeekRange}
				/>
			)}
		/>
	);
};
