import AddIcon from '@mui/icons-material/Add';
import {
	Card,
	CardActionArea,
	CardContent,
	Typography,
	CardMedia,
	Stack,
	Tooltip,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import Image from '../../Image/Image';
import { CustomSnackBar } from '../../SnackBar/SnackBar';

export type ImageButtonProps = {
	img: string;
	onImageUpload: (file: File) => void;
	disable: boolean;
};

export const InductionStepImageButton = ({
	img,
	onImageUpload,
	disable,
}: ImageButtonProps): JSX.Element => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const [error, setError] = useState('');

	const handleImageClick = (): void => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			if (file.size > 5242880) {
				setError(
					'Your step picture is too large - cannot be more than 5MB',
				);
			} else {
				onImageUpload(file);
				setError('');
			}
		}
	};

	return (
		<>
			<CustomSnackBar
				open={error !== ''}
				onClose={(): void => setError('')}
				snackBarText={error}
				severity="error"
			/>
			<Tooltip title="Add Image" arrow>
				<Card
					elevation={0}
					sx={{
						display: 'flex',
						height: '100%',
						backgroundColor: img ? 'transparent' : 'neutral.light',
					}}>
					<CardActionArea
						onClick={handleImageClick}
						disabled={disable}>
						<CardContent
							sx={{
								padding: 0,
							}}>
							<CardMedia>
								{img ? (
									<Image
										src={img}
										alt="Induction Step Image"
										imageStyle={{
											height: '30vh',
											objectFit: 'cover',
										}}
									/>
								) : (
									<Stack alignItems="center">
										<AddIcon
											sx={{ color: 'primary.main' }}
										/>
										<Typography
											sx={{ color: 'primary.main' }}>
											Add Image
										</Typography>
									</Stack>
								)}
							</CardMedia>
						</CardContent>
					</CardActionArea>
					<input
						type="file"
						accept="image/*"
						ref={fileInputRef}
						style={{ display: 'none' }}
						onChange={handleFileChange}
						data-testid="step-image-input"
					/>
				</Card>
			</Tooltip>
		</>
	);
};
