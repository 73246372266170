import { Box, useTheme } from '@mui/material';
import TriangleImg from '../../images/TriangleImg.png';
import Timg from '../../images/TriangleImgB.png';

type PageWrapper = {
	renderHeader?: (showDrawer: boolean) => JSX.Element;
	children: React.ReactNode;
	height?: string;
};

const PageWrapperDisplay = (props: PageWrapper): JSX.Element => {
	const theme = useTheme();
	return (
		<Box
			id="pageContent"
			sx={{
				height: '100%',
			}}>
			{props.renderHeader?.(false)}
			<Box
				id="contentWrapper"
				width="100%"
				height={props.height ?? 'auto'}
				minHeight={`calc(100% - ${props.renderHeader ? 64 : 0}px)`}
				padding="5vw"
				sx={{
					backgroundColor: theme.palette.backgroundHeader.default,
					backgroundImage: `url(${TriangleImg}),url(${Timg})`,
					backgroundSize: '50.3vw',
					backgroundRepeat: 'no-repeat no-repeat',
					backgroundPosition: 'top left, bottom right',
				}}>
				{props.children}
			</Box>
		</Box>
	);
};

export default PageWrapperDisplay;
