import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import { useState } from 'react';
import PinInput from 'react-pin-input';
import { Site, UserDetails } from '../../../../constants/Common';

export type UpdateKioskDialogProps = {
	open: boolean;
	currentPin: UserDetails['pin'];
	currentSite: Pick<Site, 'id' | 'name'>;
	sites: Record<string, Pick<Site, 'id' | 'name'>>;
	onClose: () => void;
	updateKiosk: (
		site: Pick<Site, 'id' | 'name'>,
		pin: NonNullable<UserDetails['pin']>,
	) => Promise<void>;
};

export const UpdateKioskDialog = ({
	open,
	currentPin,
	currentSite,
	sites,
	onClose,
	updateKiosk,
}: UpdateKioskDialogProps): JSX.Element => {
	const theme = useTheme();
	const [selectedSite, setSelectedSite] = useState(currentSite);
	const [pinCode, setPinCode] = useState(currentPin?.join('') || '');
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(false);

	const validateInput = (): boolean => {
		const pinArray = pinCode.split('');
		const hasPinNums = pinArray.every((digit) => !isNaN(Number(digit)));

		const inputValid =
			selectedSite.id !== '' && pinArray.length === 4 && hasPinNums;
		setError(!inputValid);
		return inputValid;
	};

	const saveKioskDetails = async (): Promise<void> => {
		setSaving(true);
		if (validateInput()) {
			await updateKiosk(selectedSite, [
				Number(pinCode[0]),
				Number(pinCode[1]),
				Number(pinCode[2]),
				Number(pinCode[3]),
			]);
			onClose();
		} else {
			setSaving(false);
		}
	};

	return (
		<Dialog open={open} fullWidth onClose={onClose} maxWidth="xs">
			<DialogTitle>Edit Kiosk</DialogTitle>
			<DialogContent>
				<Grid
					container
					justifyContent="center"
					spacing={2}
					paddingTop={1}>
					<Grid item xs={12}>
						<TextField
							label="Select Site"
							value={selectedSite.id}
							fullWidth
							disabled={saving}
							error={error && selectedSite.id === ''}
							onChange={(event): void => {
								setSelectedSite(sites[event.target.value]);
								setError(false);
							}}
							select>
							{Object.values(sites).map((option) => {
								return (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<Typography
							fontSize="small"
							color={error ? 'error.main' : 'neutral.main'}>
							Pin
						</Typography>
						<PinInput
							autoSelect
							length={4}
							initialValue={pinCode}
							onChange={(value): void => {
								setPinCode(value);
								setError(false);
							}}
							type="numeric"
							inputMode="number"
							disabled={saving}
							inputStyle={{
								borderColor: error
									? theme.palette.error.main
									: theme.palette.neutral.main,
								borderRadius: 5,
							}}
							inputFocusStyle={{
								borderColor: theme.palette.primary.main,
							}}
						/>
						{error && (
							<Grid item xs={12}>
								<Typography fontSize="small" color="error">
									Please enter a valid pin
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" disabled={saving} onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					disabled={saving}
					loading={saving}
					onClick={saveKioskDetails}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
