import CustomAlert from '../../../CustomAlert/CustomAlert';

export type SafetyCourseDuplicateAlertProps = {
	alertMessage: string;
};

export const SafetyCourseDuplicateAlert = ({
	alertMessage,
}: SafetyCourseDuplicateAlertProps): JSX.Element => (
	<CustomAlert severity="info" variant="filled" alertText={alertMessage} />
);
