import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { UserDetails } from '../../../../constants/Common';
import {
	SafetyCourse,
	SafetyCourseType,
	SafetyCourseValidationResponse,
} from '../../../../constants/SafetyCourse';
import { SafetyCourseDialog } from '../SafetyCourseDialog/SafetyCourseDialog';
import { SafetyCourseValidationCard } from '../SafetyCourseValidationCard/SafetyCourseValidationCard';

export type SafetyCourseAddDialogProps = {
	open: boolean;
	onValidate: (
		courseType: SafetyCourseType,
		courseID: string,
	) => Promise<{
		safetyCourseResponse: SafetyCourseValidationResponse | undefined;
		duplicateSafetyCourse: SafetyCourse | undefined;
	}>;
	onClose: () => void;
	onSave: (
		userID: string,
		safetyCourse: SafetyCourseValidationResponse,
	) => Promise<boolean>;
	employees: Record<string, UserDetails>;
	existingSafetyCourseEmployeeIDs: string[];
};

export const SafetyCourseAddDialog = ({
	open,
	onValidate,
	onClose,
	onSave,
	employees,
	existingSafetyCourseEmployeeIDs,
}: SafetyCourseAddDialogProps): JSX.Element => {
	const [safetyCourseResponse, setSafetyCourseResponse] = useState<
		SafetyCourseValidationResponse | undefined | null
	>();
	const [user, setUser] = useState<UserDetails | null>(null);
	const [loading, setLoading] = useState(false);
	const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

	const [duplicateSafetyCourse, setDuplicateSafetyCourse] = useState<
		SafetyCourse | undefined
	>();

	const errorSnackbarMessage = 'Failed to create the Safety Course.';

	const duplicateAlert =
		duplicateSafetyCourse && user?.displayName
			? `Another employee (${duplicateSafetyCourse.worker.name}) is already using this Safety Course ID. Please try again with ${user.displayName}'s ID.`
			: undefined;

	const disabled =
		safetyCourseResponse === undefined ||
		safetyCourseResponse === null ||
		user === null ||
		duplicateSafetyCourse !== undefined;

	const handleValidate = useCallback(
		async (
			courseType: SafetyCourseType,
			courseID: string,
		): Promise<void> => {
			setDuplicateSafetyCourse(undefined);
			const { safetyCourseResponse, duplicateSafetyCourse } =
				await onValidate(courseType, courseID);

			setSafetyCourseResponse(
				safetyCourseResponse !== undefined
					? safetyCourseResponse
					: null,
			);
			setDuplicateSafetyCourse(duplicateSafetyCourse);
		},
		[onValidate],
	);

	const handleConfirm = async (): Promise<void> => {
		if (safetyCourseResponse && user !== null) {
			setLoading(true);

			const success = await onSave(user.userID, safetyCourseResponse);
			if (success) {
				handleClose();
			} else {
				setErrorSnackbarOpen(true);
			}
			setLoading(false);
		}
	};

	const handleUserNameChange = (
		_: React.SyntheticEvent,
		value: UserDetails | null,
	): void => {
		if (value !== null) {
			setUser(value);
		}
	};

	const handleClose = (): void => {
		setSafetyCourseResponse(undefined);
		setUser(null);
		setDuplicateSafetyCourse(undefined);
		onClose();
	};

	const handleCloseSnackBar = (): void => setErrorSnackbarOpen(false);

	return (
		<SafetyCourseDialog
			open={open}
			loading={loading}
			disabled={disabled}
			errorSnackbarOpen={errorSnackbarOpen}
			errorSnackbarMessage={errorSnackbarMessage}
			handleCloseSnackBar={handleCloseSnackBar}
			handleClose={handleClose}
			handleConfirm={handleConfirm}>
			<Stack spacing={2}>
				<Typography>Trade Legion User</Typography>
				<Autocomplete
					fullWidth
					options={Object.values(employees)}
					getOptionLabel={(option: UserDetails): string =>
						option.displayName
					}
					value={user}
					onChange={handleUserNameChange}
					isOptionEqualToValue={(
						option: UserDetails,
						value,
					): boolean => option.userID === value.userID}
					renderInput={(params): JSX.Element => (
						<TextField
							{...params}
							label="User Name"
							placeholder="Select Users Name"
						/>
					)}
					getOptionDisabled={(option): boolean =>
						existingSafetyCourseEmployeeIDs.includes(option.userID)
					}
					size="small"
				/>
			</Stack>
			<SafetyCourseValidationCard
				validateSafetyCourse={handleValidate}
				safetyCourse={safetyCourseResponse}
				duplicateAlert={duplicateAlert}
			/>
		</SafetyCourseDialog>
	);
};
