import { Link } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { UserDetails } from '../../constants/Common';
import {
	PayrollDetails,
	kiwiSaverPercentageString,
} from '../../constants/Payroll';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import {
	alphabeticalCustomTableSort,
	numericalCustomTableSort,
} from '../helpers/muiDataTableCustomSorts';
import { sortObjectByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type PayrollTableDetails = Omit<
	PayrollDetails,
	'taxCode' | 'kiwiSaverPercentage' | 'id'
> & {
	name: string;
	taxCode: PayrollDetails['taxCode'] | '-';
	kiwiSaverPercentage: PayrollDetails['kiwiSaverPercentage'] | '-';
};

export type EmployeesPayrollDetailsProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		'payrollDetailsByUserIDSubscription' | 'subscribeWorkerUsersByCompany'
	>;
};

export const EmployeesPayrollDetails = ({
	userDetails,
	firebaseApi,
}: EmployeesPayrollDetailsProps): JSX.Element => {
	const [loading, setLoading] = useState(true);
	const [employees, setEmployees] = useState<Record<string, UserDetails>>({});
	const [payrollData, setPayrollData] = useState<
		Record<string, PayrollTableDetails>
	>({});
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no employees found'
	);
	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				sortCompare: alphabeticalCustomTableSort,
			},
		},
		{
			name: 'irdNumber',
			label: 'IRD Number',
			options: {
				sortCompare: alphabeticalCustomTableSort,
			},
		},
		{
			name: 'taxCode',
			label: 'Tax Code',
			options: {
				sortCompare: alphabeticalCustomTableSort,
			},
		},
		{
			name: 'bankAccount',
			label: 'Bank Account',
			options: {
				sortCompare: alphabeticalCustomTableSort,
			},
		},
		{
			name: 'kiwiSaverPercentage',
			label: 'KiwiSaver',
			options: {
				customBodyRender: (
					kiwiSaverPercentage: PayrollTableDetails['kiwiSaverPercentage'],
				) =>
					kiwiSaverPercentage === '-'
						? '-'
						: kiwiSaverPercentageString(kiwiSaverPercentage),
				sortCompare: numericalCustomTableSort,
			},
		},
		{
			name: 'payslipEmail',
			label: 'Email',
			options: {
				customBodyRender: (email: string) =>
					email === '-' ? (
						email
					) : (
						<Link href={`mailto:${email}`}>{email}</Link>
					),
				sortCompare: alphabeticalCustomTableSort,
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 310px)',
		viewColumns: false,
		selectableRowsOnClick: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	useEffect(() => {
		return firebaseApi.subscribeWorkerUsersByCompany(
			userDetails.companyID,
			(employees) => {
				if (Object.keys(employees).length === 0) {
					setLoading(false);
				} else {
					setEmployees(sortObjectByField(employees, 'displayName'));
				}
			},
		);
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		if (Object.keys(employees).length === 0) return;
		let isMounted = true;
		const unsubs: (() => void)[] = [];
		const promises = Object.values(employees).map(
			(employee) =>
				new Promise<void>((resolve) => {
					const unsubscribe =
						firebaseApi.payrollDetailsByUserIDSubscription(
							employee.userID,
							(payrollDetails) => {
								if (payrollDetails) {
									setPayrollData((prevState) => ({
										...prevState,
										[employee.userID]: {
											...payrollDetails,
											name: employee.displayName,
										},
									}));
								} else {
									setPayrollData((prevState) => ({
										...prevState,
										[employee.userID]: {
											id: employee.userID,
											payslipEmail: '-',
											irdNumber: '-',
											kiwiSaverPercentage: '-',
											bankAccount: '-',
											taxCode: '-',
											name: employee.displayName,
										},
									}));
								}
								resolve();
							},
						);
					unsubs.push(unsubscribe);
				}),
		);

		Promise.allSettled(promises).then(() => {
			if (isMounted) {
				setLoading(false);
			}
		});

		return () => {
			isMounted = false;
			unsubs.forEach((unsub) => unsub());
		};
	}, [employees, firebaseApi]);

	return (
		<DataTable
			tableData={Object.values(payrollData)}
			columns={columns}
			title="Employee Payroll Details"
			customTableOptions={tableOptions}
		/>
	);
};
