export const signUpFlowPaths = [
	'/sign-in',
	'/sign-up',
	'/verify-email',
	'/account-information',
	'/company-information',
	'/awaiting-approval',
	'/subscription-setup',
] as const;
export type SignUpFlowPaths = (typeof signUpFlowPaths)[number];

export const isSignUpFlowPath = (path: string): path is SignUpFlowPaths =>
	signUpFlowPaths.includes(path as SignUpFlowPaths);

export const defaultPagePaths = [
	'/contact-us',
	'/sign-in',
	'/sign-up',
	'/privacy-policy',
	'/terms-and-conditions',
	'/help',
	'/404',
] as const;
export type DefaultPagePaths = (typeof defaultPagePaths)[number];

export const isDefaultPagePath = (path: string): path is DefaultPagePaths =>
	defaultPagePaths.includes(path as DefaultPagePaths);
