import ReactDOM from 'react-dom';
import { Timestamp } from '../firebase/firebase';
import firebaseApi from '../firebase/firebaseApi';
import { EmailTemplateRecipients } from './EmailTemplateRecipients';

enum EmailAccounts {
	NoReply,
}

// please extend this type instead of using it directly
export type Email = {
	template: string;
	to: string;
	subject: string;
	html: string;
	from?: EmailAccounts;
	// This will be filled by the email function upon successful sending of an email
	readonly sent?: Timestamp;
};
export type SendEmailCallback = (
	value: Email & { id: string },
) => void | Promise<void>;

const templateRecepients: EmailTemplateRecipients | null = null;

/**
 * Fetches a list of common email accounts, stored in the database, so should be always up to date.
 * Memoized, so don't worry about multiple calls
 */
export const fetchRecepients = async (): Promise<EmailTemplateRecipients> => {
	if (templateRecepients) {
		return templateRecepients;
	} else {
		const recepients = await firebaseApi.getEmails();
		if (recepients) {
			return recepients;
		} else {
			throw new Error('Could not fetch template recepients');
		}
	}
};

/**
 * Constructs an email, using a JSX element as a template, then sends it
 *
 * @param messageTemplate - A JSX element that will be rendered into the email body - the top level tag should be <body></body>
 * @param toEmail - An async function that should construct an object of type Email that is ready to be sent, taking the html body as input
 * @param onSend - Optional, will be run after the email has been sent
 */
export const sendEmail = (
	messageTemplate: JSX.Element,
	toEmail: (html: string) => Promise<Email>,
	onSend?: SendEmailCallback,
): void => {
	const temp = document.createElement('html');
	ReactDOM.render(messageTemplate, temp, async () => {
		const email = await toEmail(temp.innerHTML);
		const newEmailID = await firebaseApi.createEmail(email);

		if (onSend) {
			await onSend({ ...email, id: newEmailID });
		}
	});
};
