import { AccountType } from './Common';

export const employeesAccountType = ['handler', 'seniorManagement'] as const;

export type EmployeesAccountType = AccountType &
	(typeof employeesAccountType)[number];

export type EmployeesConfig = {
	titles: string[];
};

export const EmployeesConfig: Record<EmployeesAccountType, EmployeesConfig> = {
	handler: {
		titles: ['details', 'profiles', 'payroll'],
	},
	seniorManagement: {
		titles: ['details', 'payroll'],
	},
};
