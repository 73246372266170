import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';

export type IntegrationSetupDialogProps = {
	open: boolean;
	integrationType: string;
	renderContent: () => JSX.Element;
	handleCancel: () => void;
	handleAuthenticate: () => void;
};

export const IntegrationSetupDialog = ({
	open,
	integrationType,
	renderContent,
	handleCancel,
	handleAuthenticate,
}: IntegrationSetupDialogProps): JSX.Element => (
	<Dialog open={open}>
		<DialogTitle>{`Authenticate with ${integrationType}`}</DialogTitle>
		<DialogContent>{renderContent()}</DialogContent>
		<DialogActions>
			<Button variant="outlined" onClick={handleCancel}>
				Cancel
			</Button>
			<Button variant="contained" onClick={handleAuthenticate}>
				Authenticate
			</Button>
		</DialogActions>
	</Dialog>
);
