import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { LoggingService } from './context';

const isLocal = (): boolean => {
	return (
		window.location.hostname === 'localhost' ||
		window.location.hostname === '127.0.0.1'
	);
};

const setupLogRocket: LoggingService['setup'] = () => {
	const id = process.env.REACT_APP_LOGROCKET_ID;
	if (id && !isLocal()) {
		LogRocket.init(id, {
			release: process.env.REACT_APP_VERSION,
		});
		setupLogRocketReact(LogRocket);
	}
};

const identifyLogRocket: LoggingService['identify'] = (userDetails) => {
	if (userDetails) {
		LogRocket.identify(userDetails.userID, {
			name: userDetails.displayName,
			email: userDetails.email,
		});
	} else {
		LogRocket.identify('');
	}
};

const logger: LoggingService['logger'] = {
	log: (message, ...props) => LogRocket.log(message, ...props),
	debug: (message, ...props) => LogRocket.debug(message, ...props),
	error: (message, ...props) => LogRocket.error(message, ...props),
	info: (message, ...props) => LogRocket.info(message, ...props),
};

export const logRocketLoggingService: LoggingService = {
	setup: setupLogRocket,
	identify: identifyLogRocket,
	logger,
};
