import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import MUIDataTable, {
	MUIDataTableColumn,
	MUIDataTableOptions,
} from 'mui-datatables';
import { useEffect, useState } from 'react';
import {
	CompanyEmployeeRates,
	EmployeeRates,
	UserDetails,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { formatDecimalToPercent } from '../helpers/numberFormats';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { EditEmployeeRatesDialog } from './EditEmployeeRatesDialog';

type EmployeeRatesDisplay = EmployeeRates & {
	displayName: string;
};
export type DefaultEmployeeRatesProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'companyEmployeeRatesByID'
		| 'subscribeWorkerUsersByCompany'
		| 'setEmployeeRates'
	>;
};

export const DefaultEmployeeRates = ({
	userDetails,
	firebaseApi,
}: DefaultEmployeeRatesProps): JSX.Element => {
	const [companyEmployeeRates, setCompanyEmployeeRates] =
		useState<CompanyEmployeeRates>({});
	const [usersEmployeeRates, setUsersEmployeeRates] = useState<
		Record<string, EmployeeRatesDisplay>
	>({});
	const [selectedWorker, setSelectedWorker] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [newPayRate, setNewPayRate] = useState<number>(0);
	const [newChargeOut, setNewChargeOut] = useState<number>(0);
	const [newOnCost, setNewOnCost] = useState<number>(0);
	const [newMargin, setNewMargin] = useState<number>(0);

	useEffect(() => {
		setLoading(true);
		return firebaseApi.companyEmployeeRatesByID(
			userDetails.companyID,
			(employeeRates) => {
				setCompanyEmployeeRates(employeeRates);
				setLoading(false);
			},
		);
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		return firebaseApi.subscribeWorkerUsersByCompany(
			userDetails.companyID,
			(users) => {
				const employeeRatesData: Record<string, EmployeeRatesDisplay> =
					{};
				Object.values(users).forEach((user) => {
					employeeRatesData[user.userID] = {
						id: user.userID,
						margin: companyEmployeeRates[user.userID]?.margin ?? 0,
						payRate:
							companyEmployeeRates[user.userID]?.payRate ?? 0,
						chargeOutRate:
							companyEmployeeRates[user.userID]?.chargeOutRate ??
							0,
						onCost: companyEmployeeRates[user.userID]?.onCost ?? 0,
						displayName: user.displayName,
					};
				});
				setUsersEmployeeRates(employeeRatesData);
				setLoading(false);
			},
		);
	}, [userDetails.companyID, companyEmployeeRates, firebaseApi]);

	const options: MUIDataTableOptions = {
		selectableRows: 'none',
		elevation: 1,
		fixedHeader: true,
		// 320px is the magic number, but is NOT screen size dependant
		// it represents top of viewport to top of the top row of data without scrolling
		tableBodyHeight: 'calc(100vh - 320px)',
		filter: false,
		viewColumns: false,
		expandableRowsHeader: false,
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching records found'
				),
			},
		},
	};

	const numCells = 5;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;

	const columns: MUIDataTableColumn[] = [
		{
			label: 'Name',
			name: 'displayName',
			options: {
				customBodyRender: (displayName: UserDetails['displayName']) =>
					displayName ?? 'INVALID EMPLOYEE NAME',
			},
		},
		{
			label: 'Charge Out Rate',
			name: 'chargeOutRate',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (
					chargeOutRate: EmployeeRates['chargeOutRate'],
				) => `$${chargeOutRate.toFixed(2)}`,
			},
		},
		{
			label: 'Pay Rate',
			name: 'payRate',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (payRate: EmployeeRates['payRate']) =>
					`$${payRate.toFixed(2)}`,
			},
		},
		{
			label: 'On Cost',
			name: 'onCost',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (onCost: EmployeeRates['onCost']) =>
					`${!isNaN(onCost) ? formatDecimalToPercent(onCost) : 0}%`,
			},
		},
		{
			label: 'Margin',
			name: 'margin',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(1) },
				}),
				customBodyRender: (margin: EmployeeRates['margin']) =>
					`$${margin.toFixed(2)}`,
			},
		},
		{
			name: '',
			label: '',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidthCalc(0.1) },
				}),
				customBodyRenderLite: (dataIndex: number) => (
					<Tooltip title="Edit">
						<IconButton
							color="primary"
							onClick={(): void =>
								handleEditDetails(
									Object.keys(usersEmployeeRates)[dataIndex],
								)
							}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				),
			},
		},
	];

	const handleSaveDetails = async (): Promise<void> => {
		// Save button is disabled if contract values are less than 0
		if (!userDetails?.companyID) return;
		const newEmployeeRates: EmployeeRates = {
			id: selectedWorker,
			chargeOutRate: newChargeOut,
			payRate: newPayRate,
			onCost: newOnCost,
			margin: newMargin,
		};
		await firebaseApi.setEmployeeRates(
			userDetails.companyID,
			selectedWorker,
			newEmployeeRates,
		);
		setModalOpen(!modalOpen);
	};

	const handleEditDetails = (workerID: string): void => {
		setSelectedWorker(workerID);
		setNewChargeOut(usersEmployeeRates[workerID].chargeOutRate);
		setNewPayRate(usersEmployeeRates[workerID].payRate);
		setNewOnCost(usersEmployeeRates[workerID].onCost);
		setNewMargin(usersEmployeeRates[workerID].margin);
		setModalOpen(!modalOpen);
	};

	const handleRateChange =
		(
			valueChange: (value: number) => number,
			stateChange: (value: React.SetStateAction<number>) => void,
		) =>
		(rate: number): void => {
			stateChange(rate);
			setNewMargin(Number(valueChange(rate).toFixed(2)));
		};

	return (
		<>
			<EditEmployeeRatesDialog
				employeeDisplayName={
					usersEmployeeRates[selectedWorker]?.displayName
				}
				modalOpen={modalOpen}
				closeModal={(): void => setModalOpen(!modalOpen)}
				newChargeOut={newChargeOut}
				newPayRate={newPayRate}
				newOnCost={newOnCost}
				newMargin={newMargin}
				handleChargeOutChange={handleRateChange(
					(value: number) => value - newPayRate * newOnCost,
					setNewChargeOut,
				)}
				handlePayRateChange={handleRateChange(
					(value: number) => newChargeOut - value * newOnCost,
					setNewPayRate,
				)}
				handleOnCostChange={handleRateChange(
					(value: number) => newChargeOut - newPayRate * (1 + value),
					setNewOnCost,
				)}
				handleSaveDetails={handleSaveDetails}
			/>
			<MUIDataTable
				title="Default Rates"
				data={Object.values(usersEmployeeRates)}
				columns={columns}
				options={options}
			/>
		</>
	);
};
