import firebase from 'firebase';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
	Competency,
	CompetencyTemplate,
	CompetencyUpdate,
	CompetencyChange,
	CompetencyDelete,
} from '../../../constants/Competencies';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../../providers/UserProvider';
import { SearchTemplates } from '../Common/SearchTemplates';
import { SectionHeader } from '../Common/SectionHeader';
import { CompetencyList } from './CompetencyList';

export type CompetenciesFirebaseCalls =
	| 'competencyTemplatesSubscription'
	| 'saveCompetencies'
	| 'saveNewCompetencyTemplate'
	| 'userProfileCompetenciesSubscription';

type CompetenciesProps = {
	userID: string;
	isPrinting: boolean;
	firebaseApi: Pick<FirebaseApi, CompetenciesFirebaseCalls>;
};

export const Competencies = ({
	userID,
	isPrinting,
	firebaseApi,
}: CompetenciesProps): JSX.Element => {
	const userDetails = useUserDetailsContext();

	const [templateCompetencies, setTemplateCompetencies] = useState<
		CompetencyTemplate[]
	>([]);
	const [competencies, setCompetencies] = useState<
		Record<string, Competency>
	>({});
	const [updatedCompetencies, setUpdatedcompetencies] = useState<
		Record<string, CompetencyUpdate>
	>({});
	const [competenciesAfterDiffs, setCompetenciesAfterDiffs] = useState<
		Competency[]
	>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [currentSelection, setCurrentSelection] =
		useState<CompetencyTemplate | null>(null);

	const onCurrentSelectionChange = useCallback(async () => {
		if (!currentSelection || !userDetails) {
			// Field reset, or userDetails not present
			return;
		} else if (currentSelection.id === '') {
			// new template
			const template: Omit<CompetencyTemplate, 'id'> = {
				tool: currentSelection.tool,
				addedBy: {
					userID: userDetails.userID,
					companyID: userDetails.companyID,
					date: firebase.firestore.Timestamp.now(),
				},
			};
			const newTemplate = await firebaseApi.saveNewCompetencyTemplate(
				template,
			);

			setUpdatedcompetencies((prev) => ({
				...prev,
				[newTemplate.id]: {
					type: 'new',
					competencyUpdate: { ...newTemplate, owned: false },
				},
			}));

			setCurrentSelection(null);
		} else {
			const diff: Competency = {
				id: currentSelection.id,
				tool: currentSelection.tool,
				owned: false,
				addedBy: {
					userID: userDetails.userID,
					companyID: userDetails.companyID,
					date: firebase.firestore.Timestamp.now(),
				},
			};
			setUpdatedcompetencies((prev) => ({
				...prev,
				[currentSelection.id]: {
					type: 'new',
					competencyUpdate: diff,
				},
			}));
			setCurrentSelection(null);
		}
	}, [currentSelection, firebaseApi, userDetails]);

	useEffect(() => {
		onCurrentSelectionChange();
	}, [currentSelection, onCurrentSelectionChange, userDetails]);

	useEffect(() => {
		if (isPrinting) {
			setIsEditing(false);
		}
	}, [isPrinting]);

	// reset local changes after save or cancel
	useEffect(() => setUpdatedcompetencies({}), [isEditing]);

	useEffect(
		() =>
			firebaseApi.userProfileCompetenciesSubscription(
				userID,
				(competencyDocs) => {
					setCompetencies(
						Object.fromEntries(
							competencyDocs.map((competency) => [
								competency.id,
								competency,
							]),
						),
					);
				},
			),
		[firebaseApi, userID],
	);

	useEffect(() => {
		// apply all diffs to our initial list of competencies
		// there's a lot of shuffling from arrays to object and back here, which is annoying,
		// buuuut I want to ensure that certain things are unique per object, and this is the easy way of doing that

		const updated = Object.entries(updatedCompetencies).reduce(
			(prev, [id, diff]) => {
				if (diff.type === 'delete') {
					delete prev[id];
				} else if (diff.type === 'new') {
					prev[id] = diff.competencyUpdate;
				} else {
					prev[id].owned = diff.competencyUpdate.owned;
				}
				return prev;
			},
			cloneDeep(competencies),
		);

		setCompetenciesAfterDiffs(
			Object.values(updated).sort((competencyA, competencyB) =>
				competencyA.tool.localeCompare(competencyB.tool),
			),
		);
	}, [competencies, updatedCompetencies]);

	useEffect(
		() =>
			firebaseApi.competencyTemplatesSubscription((templates) =>
				// Exclude base competencies this user already has
				setTemplateCompetencies(
					templates
						.filter((template) => !(template.id in competencies))
						.sort((templateA, templateB) =>
							templateA.tool.localeCompare(templateB.tool),
						),
				),
			),
		[competencies, firebaseApi],
	);

	const save = async (): Promise<void> => {
		await firebaseApi.saveCompetencies(userID, updatedCompetencies);
		setIsEditing(false);
	};

	const onUpdate = (id: string, owned: boolean): void => {
		if (id in updatedCompetencies) {
			setUpdatedcompetencies((prev) => {
				const update = prev[id];
				update.competencyUpdate.owned = owned;
				return {
					...prev,
					[id]: update,
				};
			});
		} else {
			const update: CompetencyChange = {
				type: 'update',
				competencyUpdate: { owned },
			};
			setUpdatedcompetencies((prev) => ({ ...prev, [id]: update }));
		}
	};

	const onDelete = (id: string): void => {
		const del: CompetencyDelete = {
			type: 'delete',
			competencyUpdate: {},
		};
		setUpdatedcompetencies((prev) => ({ ...prev, [id]: del }));
	};

	return (
		<SectionHeader
			title="Competencies"
			setEditState={setIsEditing}
			editing={isEditing}
			printing={isPrinting}
			save={save}>
			<>
				<CompetencyList
					competencies={competenciesAfterDiffs}
					editing={isEditing}
					onUpdate={onUpdate}
					onDelete={onDelete}
				/>
				{isEditing && (
					<SearchTemplates
						currentSelection={currentSelection}
						setCurrentSelection={setCurrentSelection}
						templates={templateCompetencies}
						label="tool"
						templateName="Competency"
					/>
				)}
			</>
		</SectionHeader>
	);
};
