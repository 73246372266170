import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { emptyFunction } from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { ProjectTrackingType } from '../../models/Integrations/ProjectTrackingIntegration';
import { useFeatureFlagContext } from '../../providers/featureFlags/Provider';
import { useUserDetailsContext } from '../../providers/UserProvider';
import {
	IntegrationItem,
	IntegrationSetup,
} from '../Integrations/Components/IntegrationSetup/IntegrationSetup';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../SnackBar/SnackBar';

export type ProjectTrackingPageProps = {
	firebaseApi: Pick<FirebaseApi, 'companySubscription' | 'getDownloadUrl'>;
};

export const ProjectTrackingPage = ({
	firebaseApi,
}: ProjectTrackingPageProps): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const navigate = useNavigate();
	const featureFlags = useFeatureFlagContext();
	const { state } = useLocation();

	const catProjectsEnabled = featureFlags.get('enableCATProjectsIntegration');

	const [loading, setLoading] = useState(true);

	const redirects: Record<ProjectTrackingType, string | undefined> = useMemo(
		() => ({
			Workbench: '/project-tracking/workbench',
			CATProjects: catProjectsEnabled
				? '/project-tracking/catprojects'
				: undefined,
		}),
		[catProjectsEnabled],
	);

	const projectTrackingTypes: Partial<
		Record<ProjectTrackingType, IntegrationItem>
	> = useMemo(() => {
		const types: Partial<Record<ProjectTrackingType, IntegrationItem>> = {
			Workbench: {
				name: 'Workbench',
				id: 'Workbench',
				link: 'workbench',
			},
		};

		if (catProjectsEnabled) {
			types['CATProjects'] = {
				name: 'CATProjects',
				id: 'CATProjects',
				link: 'catprojects',
			};
		}

		return types;
	}, [catProjectsEnabled]);

	useEffect(() => {
		let mounted = true;
		let sub = emptyFunction;

		if (userDetails !== null) {
			setLoading(true);
			sub = firebaseApi.companySubscription(
				userDetails.companyID,
				(company) => {
					const integration = company.projectTrackingIntegrated;

					if (integration) {
						const navigationLink = redirects[integration];
						if (navigationLink) {
							navigate(navigationLink);
						}
					}

					if (mounted) {
						setLoading(false);
					}
				},
			);
		}

		return () => {
			mounted = false;
			sub();
		};
	}, [firebaseApi, navigate, redirects, userDetails]);

	const renderDescription = (): JSX.Element => (
		<>
			<Typography>
				Looks like you don&apos;t have a project tracking system setup
				with us! We can handle your timesheets all the way through to
				project tracking, freeing up your schedule for other things.
			</Typography>
			<Typography>
				Select your project tracking provider below to integrate with
				Trade Legion
			</Typography>
		</>
	);

	return (
		<>
			<CustomSnackBar
				open={state?.snackbar}
				onClose={(): void => {
					delete state.snackbar;
				}}
				snackBarText={state?.snackbar?.message}
				severity={state?.snackbar?.severity}
			/>
			{loading ? (
				<LoadingDots />
			) : (
				<IntegrationSetup
					integrationTypes={projectTrackingTypes}
					description={renderDescription()}
					firebaseApi={firebaseApi}
				/>
			)}
		</>
	);
};
