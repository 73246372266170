import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Qualification } from '../../../constants/Qualifications';
import { humanReadableSpan } from '../../helpers/dateUtilities';
import { IndentedCardList } from '../Common/IndentedCardList';

type QualificationsListProps = {
	qualifications: Qualification[];
	editing: boolean;

	onDelete: (qualification: Qualification) => void;
};

export const QualificationsList = ({
	qualifications,
	editing,
	onDelete,
}: QualificationsListProps): JSX.Element => {
	const row = (qualification: Qualification): JSX.Element => (
		<Grid container columns={12} alignItems="center">
			<Grid item xs={5}>
				<Typography variant="body1Bold">
					{qualification.title}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				{qualification.organisation ? (
					<Typography variant="body1">
						{qualification.organisation}
					</Typography>
				) : (
					<Typography sx={{ fontStyle: 'italic' }}>
						No Organisation
					</Typography>
				)}
			</Grid>
			<Grid item xs={3}>
				<Typography variant="body1">
					{humanReadableSpan(
						qualification.obtainedDate,
						qualification.expiryDate,
					)}
				</Typography>
			</Grid>

			<Grid item xs={1} textAlign="right">
				{qualification.evidenceUrl && (
					<Tooltip title="Attached Evidence">
						<AttachFileIcon />
					</Tooltip>
				)}
			</Grid>
		</Grid>
	);

	return (
		<IndentedCardList
			list={qualifications}
			editing={editing}
			card={{
				content: row,
				onDelete,
				onClick: (qualification) =>
					qualification.evidenceUrl &&
					window.open(qualification.evidenceUrl),
				isClickableField: 'evidenceUrl',
			}}
		/>
	);
};
