import {
	Company,
	CompanyEmployeeRates,
	CompanyType,
	EmployeeRates,
} from '../../constants/Common';
import {
	Firestore,
	FirestoreDataConverter,
	QuerySnapshot,
	Storage,
} from '../firebase';

const COMPANIES_COLLECTION = 'companies';
const companyConverter: FirestoreDataConverter<Company> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => ({ ...snapshot.data() } as Company),
};

const getCompany = async (companyID: string): Promise<Company | undefined> => {
	const doc = await Firestore.collection(COMPANIES_COLLECTION)
		.doc(companyID)
		.withConverter(companyConverter)
		.get();
	return doc.exists ? doc.data() : undefined;
};

const getCompanies = async (): Promise<Record<string, Company>> => {
	const companiesSnapshot = await Firestore.collection(COMPANIES_COLLECTION)
		.withConverter(companyConverter)
		.get();
	const companies = companiesSnapshot.docs.reduce<Record<string, Company>>(
		(allCompanies, companyDoc) => {
			allCompanies[companyDoc.id] = companyDoc.data();
			return allCompanies;
		},
		{},
	);
	return companies;
};

const customerSupportQueryCompanies = async (
	filterByCompanyName: string,
	filterByType: string,
): Promise<Company[]> => {
	const collectionRef = Firestore.collection(COMPANIES_COLLECTION);

	let companiesQuery = collectionRef.where('companyType', '==', filterByType);
	if (filterByType !== '' && filterByCompanyName !== '') {
		companiesQuery = collectionRef
			.where('name', '>=', filterByCompanyName)
			.where('name', '<=', filterByCompanyName + '\uf8ff')
			.where('companyType', '==', filterByType);
	} else if (filterByCompanyName !== '') {
		companiesQuery = collectionRef
			.where('name', '>=', filterByCompanyName)
			.where('name', '<=', filterByCompanyName + '\uf8ff');
	}

	const companyDocs = await companiesQuery
		.withConverter(companyConverter)
		.get();
	const companies = companyDocs.docs.map((doc) => doc.data());
	return companies;
};

const createCompany = async (company: Omit<Company, 'id'>): Promise<string> => {
	const docRef = Firestore.collection(COMPANIES_COLLECTION).doc();
	await docRef.set({
		...company,
		id: docRef.id,
	});
	return docRef.id;
};

const updateCompany = async (
	companyID: string,
	company: Omit<Company, 'id'>,
): Promise<void> =>
	await Firestore.collection(COMPANIES_COLLECTION)
		.doc(companyID)
		.update(company);

const updateCompanyLogo = async (
	company: Pick<Company, 'id' | 'name' | 'logoLocation'>,
	newLogo: File,
): Promise<void> => {
	// Create a ref if a company doesn't already have a logo set
	if (company.logoLocation === undefined) {
		const logoLocation = `companyLogos/${company.id}`;
		const ref = Storage.ref().child(logoLocation);
		await ref.put(newLogo);
		await ref.updateMetadata({
			customMetadata: { companyName: company.name },
		});
		await Firestore.collection(COMPANIES_COLLECTION)
			.doc(company.id)
			.update({ logoLocation });
	} else {
		await Storage.ref().child(company.logoLocation).put(newLogo);
	}
};

const companiesSubscription = (
	onNext: (snapshot: QuerySnapshot) => void,
): (() => void) =>
	Firestore.collection(COMPANIES_COLLECTION)
		.withConverter(companyConverter)
		.onSnapshot(onNext);

const companiesSubscriptionByType = (
	companyType: CompanyType,
	companiesCallback: (companies: Company[]) => void,
): (() => void) =>
	Firestore.collection(COMPANIES_COLLECTION)
		.where('companyType', '==', companyType)
		.withConverter(companyConverter)
		.onSnapshot((snapshot) => {
			const companies = snapshot.docs.map((docSnapshot) =>
				docSnapshot.data(),
			);
			companiesCallback(companies);
		});

const companySubscription = (
	companyID: string,
	onNext: (company: Company) => void,
): (() => void) =>
	Firestore.collection(COMPANIES_COLLECTION)
		.doc(companyID)
		.withConverter(companyConverter)
		.onSnapshot((doc) => onNext(doc.data() as Company));

const companyEmployeeRatesByID = (
	companyID: string,
	employeeRatesCallback: (companyEmployeeRates: CompanyEmployeeRates) => void,
): (() => void) =>
	Firestore.collection(COMPANIES_COLLECTION)
		.doc(companyID)
		.collection('employeeRates')
		.onSnapshot((querySnapshot) => {
			const employeeRates: CompanyEmployeeRates = {};
			querySnapshot.forEach((doc) => {
				employeeRates[doc.id] = doc.data() as EmployeeRates;
			});
			employeeRatesCallback(employeeRates);
		});

const setEmployeeRates = async (
	companyID: string,
	employeeID: string,
	rates: EmployeeRates,
): Promise<void> => {
	await Firestore.collection(COMPANIES_COLLECTION)
		.doc(companyID)
		.collection('employeeRates')
		.doc(employeeID)
		.set(rates);
};

const companiesFirebaseApi = {
	createCompany,
	companiesSubscription,
	companiesSubscriptionByType,
	companyEmployeeRatesByID,
	companySubscription,
	customerSupportQueryCompanies,
	getCompanies,
	getCompany,
	setEmployeeRates,
	updateCompany,
	updateCompanyLogo,
};

export default companiesFirebaseApi;
