import { Template } from '../../constants/Template';
import { Firestore } from '../firebase';

const COLLECTION = 'templates';

const templatesByCompanyIDSubscription = (
	companyID: string,
	callback: (documents: Template[]) => void,
): (() => void) => {
	return Firestore.collection(COLLECTION)
		.where('company.id', '==', companyID)
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data() as Template)),
		);
};

const templatesFirebaseApi = {
	templatesByCompanyIDSubscription,
};

export default templatesFirebaseApi;
