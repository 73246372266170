import { CompetencyTemplate } from '../../constants/Competencies';
import { Firestore } from '../firebase';

const saveNewCompetencyTemplate = async (
	newCompetency: Omit<CompetencyTemplate, 'id'>,
): Promise<CompetencyTemplate> => {
	const doc = Firestore.collection('competencyTemplates').doc();
	const template: CompetencyTemplate = {
		id: doc.id,
		tool: newCompetency.tool,
		addedBy: newCompetency.addedBy,
	};
	await doc.set(template);
	return template;
};

const competencyTemplatesSubscription = (
	callback: (competencies: CompetencyTemplate[]) => void,
): (() => void) =>
	Firestore.collection('competencyTemplates').onSnapshot((snapshot) =>
		callback(
			snapshot.docs.map(
				(doc) => ({ ...doc.data(), id: doc.id } as CompetencyTemplate),
			),
		),
	);

const competencyTemplatesFirebaseApi = {
	competencyTemplatesSubscription,
	saveNewCompetencyTemplate,
};

export default competencyTemplatesFirebaseApi;
