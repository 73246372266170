import { SafetyCourse, SafetyCourseType } from '../../constants/SafetyCourse';
import { Firestore, FirestoreDataConverter } from '../firebase';

const COLLECTION = 'safetyCourses';

const safetyCourseConverter: FirestoreDataConverter<SafetyCourse> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as SafetyCourse,
};

const safetyCoursesByCompanySubscription = (
	companyID: string,
	callback: (safetyCourses: SafetyCourse[]) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.withConverter(safetyCourseConverter)
		.where('worker.companyID', '==', companyID)
		.onSnapshot((snapshot) => {
			const safetyCourses = snapshot.docs.map((doc) => doc.data());
			callback(safetyCourses);
		});

const getSafetyCoursesByWorker = async (
	workerID: string,
): Promise<SafetyCourse[]> => {
	const querySnapshot = await Firestore.collection(COLLECTION)
		.withConverter(safetyCourseConverter)
		.where('worker.id', '==', workerID)
		.get();

	const safetyCourses = querySnapshot.docs.map(
		(doc) => doc.data() as SafetyCourse,
	);
	return safetyCourses;
};

const getSafetyCoursesByWorkerCourseType = async (
	workerID: string,
	courseType: SafetyCourseType,
): Promise<SafetyCourse[]> => {
	const querySnapshot = await Firestore.collection(COLLECTION)
		.withConverter(safetyCourseConverter)
		.where('worker.id', '==', workerID)
		.where('course.type', '==', courseType)
		.get();

	const safetyCourses = querySnapshot.docs.map(
		(doc) => doc.data() as SafetyCourse,
	);
	return safetyCourses;
};

const findDuplicateSafetyCourse = async (
	safetyCourseID: string,
	safetyCourseType: SafetyCourseType,
): Promise<SafetyCourse | undefined> => {
	const docs = await Firestore.collection(COLLECTION)
		.where('course.id', '==', safetyCourseID)
		.where('course.type', '==', safetyCourseType)
		.limit(1)
		.get();

	if (!docs.empty) {
		const userDoc = docs.docs[0];
		return userDoc.data() as SafetyCourse;
	}
};

const safetyCoursesFirebaseApi = {
	safetyCoursesByCompanySubscription,
	getSafetyCoursesByWorker,
	getSafetyCoursesByWorkerCourseType,
	findDuplicateSafetyCourse,
};

export default safetyCoursesFirebaseApi;
