import { Card, CardContent } from '@mui/material';
import {
	Chart,
	ChartTitle,
	ChartLegend,
	ChartSeries,
	ChartSeriesItem,
	ChartValueAxis,
	ChartValueAxisItem,
	ChartCategoryAxis,
	ChartCategoryAxisItem,
	SeriesType,
} from '@progress/kendo-react-charts';

type TimeSeriesChartProps = {
	series: {
		type: SeriesType;
		data:
			| {
					Date: Date;
					staffNum: number;
			  }[]
			| {
					Date: Date;
					hours: number;
			  }[]
			| {
					Date: Date;
					profit: number;
			  }[]
			| {
					Date: Date;
					revenue: number;
			  }[];
		name: string;
		field: string;
	}[];
	axisCrossingValue: number[];
	axisValue: { name: string; color: string }[];
	title: string;
};

export const TimeSeriesChart = ({
	series,
	axisValue,
	axisCrossingValue,
	title,
}: TimeSeriesChartProps): JSX.Element => {
	return (
		<Card>
			<CardContent>
				<Chart>
					<ChartTitle text={title} />
					<ChartLegend position="bottom" orientation="horizontal" />
					<ChartCategoryAxis>
						<ChartCategoryAxisItem
							startAngle={0}
							axisCrossingValue={axisCrossingValue}
							labels={{ format: 'dd/MM', rotation: 'auto' }}
						/>
					</ChartCategoryAxis>
					<ChartSeries>
						{series.map((item, idx) => (
							<ChartSeriesItem
								key={idx}
								name={item.name}
								type={item.type}
								field={item.field}
								axis={item.field}
								categoryField="Date"
								data={item.data}
								tooltip={{ visible: true }}
							/>
						))}
					</ChartSeries>
					<ChartValueAxis>
						{axisValue.map((item, idx) => (
							<ChartValueAxisItem key={idx} name={item.name} />
						))}
					</ChartValueAxis>
				</Chart>
			</CardContent>
		</Card>
	);
};
