import { Autocomplete, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import { SimplifiedSite, SiteList } from '../../constants/Common';
import { sortObjectByField } from '../helpers/sortHelpers';

type SiteWithGroup = SimplifiedSite & {
	group: string;
};

type SiteSelectDropdownProps = {
	onChange: (
		event: React.SyntheticEvent,
		value: SimplifiedSite | null,
	) => void;
	value: SimplifiedSite | null;
	siteGroups: Record<string, SiteList>;
	error: boolean;
	disabled?: boolean;
	fullWidth?: boolean;
	size?: 'small' | 'medium';
	disableClearable?: boolean;
	inputLabel?: string;
	placeHolder?: string;
	required?: boolean;
	helperText?: string;
};

export const SiteSelectDropdown = ({
	onChange,
	value,
	siteGroups,
	error,
	disabled = false,
	fullWidth = true,
	disableClearable = false,
	size = 'medium',
	inputLabel = 'Select Site',
	required = false,
	placeHolder,
	helperText = 'You must select a site',
}: SiteSelectDropdownProps): JSX.Element => {
	// Construct sorted site object list with their provided site grouping
	const allSitesObject = Object.entries(siteGroups).reduce(
		(acc: Record<string, SiteWithGroup>, [siteGroupName, siteGroup]) => {
			const sortedGroup = sortObjectByField(siteGroup, 'name');
			Object.values(sortedGroup).forEach((site) => {
				acc[site.id] = { group: siteGroupName, ...site };
			});
			return acc;
		},
		{},
	);
	return (
		<Autocomplete
			options={Object.values(allSitesObject)}
			getOptionLabel={(option): string => option.name}
			groupBy={(option: SiteWithGroup): string => option.group}
			fullWidth={fullWidth}
			disabled={disabled}
			disableClearable={disableClearable}
			onChange={onChange}
			value={allSitesObject[value?.id ?? ''] ?? null}
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			renderOption={(
				props: FixMeLater,
				option: SiteWithGroup,
			): JSX.Element => (
				<MenuItem
					{...props}
					label={option.name}
					variant="standard"
					key={option.id + option.group}>
					{option.name || 'No Site'}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					label={inputLabel}
					size={size}
					required={required}
					fullWidth={fullWidth}
					inputProps={{
						...params.inputProps,
					}}
					error={error}
					helperText={error ? helperText : null}
					placeholder={placeHolder}
				/>
			)}
		/>
	);
};
