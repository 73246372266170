import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type ImportEmployeeCSVData = {
	fileName: string;
};

export const payrollEmployeeImport = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	fileName: string,
): Promise<boolean | undefined> => {
	return await makeCloudFunctionRequestReturnSuccessStatus<ImportEmployeeCSVData>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.PayrollEmployeeImport,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: { fileName: fileName },
		},
	);
};
