import { useEffect } from 'react';
import { RerenderingError } from './useAbortController';

/** Prevents re-render errors that occur when changing an in-progress fetch is cancelled because the page that calls it has its state changed and therefore re-renders.*/
export const useRerenderHandler = (): void => {
	useEffect(() => {
		window.addEventListener('unhandledrejection', handler);
		return () => {
			window.removeEventListener('unhandledrejection', handler);
		};
	});

	const handler = (event: PromiseRejectionEvent): void => {
		if (event.reason instanceof RerenderingError) {
			// we don't care
			event.preventDefault();
		} else {
			throw event.reason;
		}
	};
};
