import CloseIcon from '@mui/icons-material/Close';
import {
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
	Typography,
} from '@mui/material';
import { ExplicitAny } from '../../../../constants/AnyTypes';
import { MinimalInterface } from '../../../../constants/Common';
import { IntegrationLink } from '../../../../models/Integrations/IntegrationElements';

export type OptionWithDisabled<T = ExplicitAny> = MinimalInterface &
	(T extends undefined
		? {
				disabled: boolean;
				metadata?: T;
		  }
		: { disabled: boolean; metadata: T });

export type IntegrationLinkCellProps<T> = {
	link?: IntegrationLink;
	linkIntegrationID?: string;
	options: Record<string, OptionWithDisabled<T>>;
	onChange: (integrationID?: string) => void;
	disabled?: boolean;
	missingLinkMessage?: string;
	missingLinkLabel?: string;
	selectOverride?: string;
};

const sortOptionNumericString = <T,>(
	optionA: OptionWithDisabled<T>,
	optionB: OptionWithDisabled<T>,
): number =>
	optionA.name
		.toLocaleLowerCase()
		.localeCompare(optionB.name.toLocaleLowerCase(), 'en');

export const IntegrationLinkCell = <T,>({
	link,
	linkIntegrationID,
	options,
	onChange,
	missingLinkMessage,
	missingLinkLabel,
	disabled,
	selectOverride,
}: IntegrationLinkCellProps<T>): JSX.Element => {
	const handleOnChange = (event: SelectChangeEvent): void => {
		onChange(event.target.value);
	};

	const handleOnClear = (): void => {
		onChange();
	};

	if (link !== undefined) {
		if (options[link.integrationID] === undefined) {
			return (
				<Tooltip title={missingLinkMessage}>
					<Typography
						color="error"
						sx={{ textDecoration: 'underline dotted' }}>
						{missingLinkLabel}
					</Typography>
				</Tooltip>
			);
		} else {
			return <Typography>{`${link.integrationName}`}</Typography>;
		}
	} else {
		return (
			<Select
				value={linkIntegrationID ?? ''}
				onChange={handleOnChange}
				size="small"
				fullWidth
				displayEmpty
				disabled={disabled}
				sx={{
					'& .MuiInputBase-inputAdornedEnd': {
						paddingRight: '0px !important', // if anyone finds a way to overrided another style I'm all ears
					},
				}}
				endAdornment={
					!!linkIntegrationID && (
						<InputAdornment position="end" sx={{ mr: 2 }}>
							<IconButton
								size="small"
								onClick={handleOnClear}
								disabled={disabled}>
								<Tooltip title="Clear Selection">
									<CloseIcon fontSize="small" />
								</Tooltip>
							</IconButton>
						</InputAdornment>
					)
				}>
				<MenuItem value="">
					<Typography
						color="neutral.main"
						fontStyle="italic"
						overflow="hidden"
						textOverflow="ellipsis"
						noWrap>
						{selectOverride ? selectOverride : 'Select'}
					</Typography>
				</MenuItem>
				{Object.values(options)
					.sort(sortOptionNumericString)
					.map((option) => (
						<MenuItem
							disabled={option.disabled}
							key={`${option.id}`}
							id={option.id}
							value={option.id}>
							<Typography
								overflow="hidden"
								textOverflow="ellipsis"
								noWrap>{`${option.name}`}</Typography>
						</MenuItem>
					))}
			</Select>
		);
	}
};
