import {
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	Typography,
} from '@mui/material';
import { WorkerAttendance } from '../../constants/Attendance';
import { AttendanceCard } from './AttendanceCard';

export type WorkerCheckboxListProps = {
	title: string;
	workers: WorkerAttendance[];
	checked: string[];
	setChecked: (checked: string[]) => void;
};

export const WorkerCheckboxList = ({
	title,
	workers,
	checked,
	setChecked,
}: WorkerCheckboxListProps): JSX.Element => {
	const handleToggle = (id: string) => () => {
		const currentIndex = checked.indexOf(id);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(id);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	return (
		<List dense>
			<ListItem
				key="select-all-workers"
				secondaryAction={
					workers.length !== 0 && (
						<Checkbox
							edge="end"
							onChange={(): void =>
								checked.length !== workers.length
									? setChecked(
											workers.map(
												(worker) => worker.userID,
											),
									  )
									: setChecked([])
							}
							checked={checked.length === workers.length}
							inputProps={{
								'aria-label':
									'checkbox-list-secondary-label-select-all-workers',
							}}
						/>
					)
				}
				disablePadding
				sx={{ marginBottom: '8px' }}>
				<Typography fontSize="large">{title}</Typography>
			</ListItem>
			{workers.map((worker) => {
				const labelId = `checkbox-list-secondary-label-${worker.userID}`;
				return (
					<ListItem
						key={worker.userID}
						secondaryAction={
							<Checkbox
								edge="end"
								onChange={handleToggle(worker.userID)}
								checked={checked.indexOf(worker.userID) !== -1}
								inputProps={{ 'aria-label': labelId }}
							/>
						}
						disablePadding>
						<ListItemButton onClick={handleToggle(worker.userID)}>
							<AttendanceCard employee={worker} />
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
};
