import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

enum DocumentSearchParamKey {
	documentID = 'documentID',
	templateID = 'templateID',
}

type DocumentSearchParams = {
	documentID?: string;
	templateID?: string;
};

export const useDocumentSearchParams = (): ((
	params: DocumentSearchParams,
) => void) => {
	const [, setSearchParams] = useSearchParams();

	const setSearchParamsCallback = useCallback(
		(params: DocumentSearchParams): void => {
			setSearchParams((prev) => {
				if (params.documentID) {
					prev.set(
						DocumentSearchParamKey.documentID,
						params.documentID,
					);
				}
				if (params.templateID) {
					prev.set(
						DocumentSearchParamKey.templateID,
						params.templateID,
					);
				}
				return prev;
			});
		},
		[setSearchParams],
	);

	return setSearchParamsCallback;
};
