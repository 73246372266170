import { Evacuation, Evacuee } from '../../constants/Common';
import { Firestore, FirestoreDataConverter } from '../firebase';

// Limit batch to 500 operations
const MAX_OPERATIONS_PER_BATCH = 499;
const EVACUATIONS_COLLECTION = 'evacuations';
const EVACUEES_COLLECTION = 'evacuees';

const evacuationsConverter: FirestoreDataConverter<Evacuation> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => ({ ...snapshot.data() } as Evacuation),
};

/**
 * Create a new evacuation with the provided evacuees
 * @param evacuation New evacuation to create
 * @param evacuees Evacuees to add to the evacuation (optional)
 */
const createEvacuation = async (
	evacuation: Evacuation,
	evacuees: Record<string, Evacuee> = {},
): Promise<void[]> => {
	const allBatches = [Firestore.batch()];
	let opCounter = 0;
	let batchIndex = 0;

	// Save Evacuation
	const evacuationRef = Firestore.collection(EVACUATIONS_COLLECTION).doc();
	allBatches[batchIndex].set(evacuationRef, evacuation);
	opCounter++;

	// Save Evacuees
	Object.entries(evacuees).forEach(([evacueeID, evacuee]) => {
		const evacueeRef = evacuationRef
			.collection(EVACUEES_COLLECTION)
			.doc(evacueeID);
		allBatches[batchIndex].set(evacueeRef, evacuee);
		opCounter++;

		if (opCounter === MAX_OPERATIONS_PER_BATCH) {
			allBatches.push(Firestore.batch());
			batchIndex++;
			opCounter = 0;
		}
	});

	return await Promise.all(allBatches.map((batch) => batch.commit()));
};

const evacuationsBySiteSubscription = (
	siteID: Evacuation['siteID'],
	evacuationsCallback: (evacuations: Evacuation[]) => void,
): (() => void) => {
	return Firestore.collection(EVACUATIONS_COLLECTION)
		.where('siteID', '==', siteID)
		.withConverter(evacuationsConverter)
		.onSnapshot((snapshot) => {
			const siteEvacuations = snapshot.docs.map((doc) => doc.data());
			evacuationsCallback(siteEvacuations);
		});
};

const evacuationsFirebaseApi = {
	createEvacuation,
	evacuationsBySiteSubscription,
};

export default evacuationsFirebaseApi;
