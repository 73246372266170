import { Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import {
	accountApprovalsMap,
	AccountsAccountType,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	CompanyTypes,
	Site,
	UserDetails,
	accountTypes,
	assertAccountType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { contractedToCustomTableSort } from '../helpers/muiDataTableCustomSorts';
import { sortObjectByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { UsersOptions } from './UsersOptions';

export type UsersProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'subscribeNonKioskUsersByCompany'
		| 'companiesSubscriptionByType'
		| 'activeSitesByCompanySubscription'
		| 'activeSitesByCompanyTypeSubscription'
		| 'updateUserAccountDetails'
		| 'updateUserProfileCompany'
		| 'updateUserDetailsCompanyInfo'
	>;
};

export const Users = ({
	userDetails,
	firebaseApi,
}: UsersProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);

	const [selected, setSelected] = useState<null | string>(null);
	const [users, setUsers] = useState<UserDetails[]>([]);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [loading, setLoading] = useState(true);

	const canManageAllUsersInCompany =
		accountType === accountTypes.handler ||
		accountType === accountTypes.seniorManagement;
	const showContractedTo = accountType === accountTypes.handler;
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		<Typography>
			{`There are no users for your ${
				canManageAllUsersInCompany ? 'company' : 'site'
			}`}
		</Typography>
	);
	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const columns = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: showContractedTo ? 'contractedTo' : 'company',
			label: showContractedTo ? 'Client' : 'Company',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					company:
						| UserDetails['contractedTo']
						| UserDetails['company'],
				) => (typeof company === 'string' ? company : company?.name),
				sortCompare: contractedToCustomTableSort,
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				searchable: false,
				setCellHeaderProps,
				customBodyRender: (userID: string): JSX.Element => (
					<UsersOptions
						accountType={accountType}
						existingUser={users.find(
							(user) => user.userID === userID,
						)}
						canRemoveUser={
							canManageAllUsersInCompany &&
							userID !== userDetails.userID &&
							users.find((user) => user.userID === userID)
								?.companyID === userDetails.companyID
						}
						sites={sites}
						firebaseApi={firebaseApi}
					/>
				),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const setApprovedUsersCallback = useCallback(
		(users: UserDetails[]): void => {
			const newUsers = users
				.map((user) => ({ ...user, id: user.userID }))
				// filter out users who's details cannot be edited by the current users account type
				.filter((user) =>
					accountApprovalsMap[accountType].includes(user.accountType),
				)
				.sort((userA, userB) =>
					userA.displayName.localeCompare(userB.displayName),
				);
			setUsers(newUsers);
			setLoading(false);
		},
		[accountType],
	);

	useEffect(() => {
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			setApprovedUsersCallback,
		);
	}, [firebaseApi, setApprovedUsersCallback, userDetails]);

	useEffect(() => {
		if (!showContractedTo) {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(siteMap) => {
					setSites(sortObjectByField(siteMap, 'name'));
				},
			);
		} else {
			const companySitesSubscription =
				firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					(siteMap) => {
						setSites((prevState) => ({
							...prevState,
							...sortObjectByField(siteMap, 'name'),
						}));
					},
				);
			const constructionSites =
				firebaseApi.activeSitesByCompanyTypeSubscription(
					CompanyTypes.construction,
					(siteMap) => {
						setSites((prevState) => ({
							...prevState,
							...sortObjectByField(siteMap, 'name'),
						}));
					},
				);
			return (): void => {
				constructionSites();
				companySitesSubscription();
			};
		}
	}, [
		firebaseApi,
		showContractedTo,
		userDetails.accountType,
		userDetails.companyID,
	]);

	const selection = ():
		| [number | null, ((selected: number) => void) | null]
		| undefined => {
		const userSelected = users.findIndex(
			(user) => user.userID === selected,
		);
		const selectedUser = (index: number): string | null =>
			users.find((_, i) => i === index)?.userID ?? null;

		return [
			userSelected >= 0 ? userSelected : null,
			(index): void => setSelected(selectedUser(index)),
		];
	};

	return (
		<AccountsTableTheme centerColumnHeader={4}>
			<DataTable
				tableData={users}
				columns={columns}
				title={`${userDetails.company} Users`}
				selection={selection()}
				customTableOptions={tableOptions}
			/>
		</AccountsTableTheme>
	);
};
