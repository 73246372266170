import { styled } from '@mui/material';
import { ComponentType } from 'react';
import {
	PickersDay,
	PickersDayProps,
} from '../../providers/LocalizationProvider';

export type CustomPickerDayProps = PickersDayProps<Date> & {
	dayIsBetween: boolean;
	isFirstDay: boolean;
	isLastDay: boolean;
	disableDay?: boolean;
};

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== 'dayIsBetween' &&
		prop !== 'isFirstDay' &&
		prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
	...((isFirstDay || dayIsBetween) && {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.primary.dark,
		},
	}),
	...(dayIsBetween && {
		borderRadius: 0,
	}),
	...(isFirstDay && {
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
		borderTopRightRadius: '0%',
		borderBottomRightRadius: '0%',
	}),
	...(isLastDay && {
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
	}),
})) as ComponentType<CustomPickerDayProps>;

export const WeekPickerDay = ({
	dayIsBetween,
	isFirstDay,
	isLastDay,
	disableDay,
	...props
}: CustomPickerDayProps): JSX.Element => (
	<CustomPickersDay
		{...props}
		sx={{ padding: 2.5 }}
		disableMargin
		disabled={disableDay}
		dayIsBetween={dayIsBetween}
		isFirstDay={isFirstDay}
		isLastDay={isLastDay}
	/>
);
