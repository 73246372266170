import LockIcon from '@mui/icons-material/Lock';
import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	Company,
	ManageSubscriptionAccountTypes,
	SubscriptionStatuses,
} from '../../constants/Common';
import { Auth } from '../../firebase/firebase';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../providers/UserProvider';
import { configureManageSubscriptionButton } from './ManageSubscriptionButton';

type SubscriptionControlProps = {
	returnUrl: string;
	company: Company | null;
	initialSetup?: boolean;
};

const auth = Auth();

export const SubscriptionControl = ({
	returnUrl,
	company,
	initialSetup = false,
}: SubscriptionControlProps): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const user = useUserAuthContext();
	const navigate = useNavigate();

	const showManageBtn =
		!!userDetails &&
		ManageSubscriptionAccountTypes.includes(userDetails.accountType);
	const hasSubscription = company?.subscriptionStatus;

	const displayMessage = (): JSX.Element => {
		const mainText = showManageBtn
			? hasSubscription &&
			  company?.subscriptionStatus !== SubscriptionStatuses.Incomplete
				? 'Expired Subscription'
				: 'Missing Subscription'
			: 'Account Locked';

		return (
			<>
				<Typography variant="h3" textAlign="center">
					{initialSetup ? 'Subscription Setup' : mainText}
				</Typography>
				{!showManageBtn && (
					<Typography variant="h6" textAlign="center">
						Contact Your Supervisor
					</Typography>
				)}
			</>
		);
	};

	return (
		<Stack height="100%" alignItems="center">
			{!initialSetup && (
				<LockIcon color="primary" sx={{ fontSize: 248 }} />
			)}
			<Stack spacing={2} alignItems="center">
				{displayMessage()}
				{showManageBtn &&
					user &&
					company &&
					configureManageSubscriptionButton(company, user, returnUrl)}
				<Button
					fullWidth
					variant="outlined"
					onClick={(): void => {
						auth.signOut().then(() => {
							navigate('/');
						});
					}}>
					Log Out
				</Button>
			</Stack>
		</Stack>
	);
};
