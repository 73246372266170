import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RefreshIcon from '@mui/icons-material/Refresh';
import StoreIcon from '@mui/icons-material/Store';
import TimelineIcon from '@mui/icons-material/Timeline';
import TuneIcon from '@mui/icons-material/Tune';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import * as React from 'react';
import { AccountSettings } from '../components/AccountSettings/AccountSettings';
import { ComplianceHistoryWrapper } from '../components/Compliance/ComplianceHistory/ComplianceHistoryWrapper';
import { ComplianceWrapper } from '../components/Compliance/ComplianceWrapper';
import { ConfigureInductionsWrapper } from '../components/Compliance/ConfigureInductions/ConfigureInductionsWrapper';
import { InductionsWrapper } from '../components/Compliance/Inductions/InductionsWrapper';
import { SafetyCoursesWrapper } from '../components/Compliance/SafetyCourses/SafetyCoursesWrapper';
import { FailedAutomations } from '../components/CustomerSupport/FailedAutomations/FailedAutomations';
import { ManageCompanies } from '../components/CustomerSupport/ManageCompanies';
import { ManageInvoicingIntegrationsWrapper } from '../components/CustomerSupport/ManageInvoices/ManageInvoicingIntegrationsWrapper';
import { ManageLeaveIntegrationsWrapper } from '../components/CustomerSupport/ManageLeave/ManageLeaveIntegrationsWrapper';
import { ManageSites } from '../components/CustomerSupport/ManageSites';
import { ManageTimesheetPayrollIntegrationsWrapper } from '../components/CustomerSupport/ManageTimesheetPayroll/ManageTimesheetPayrollIntegrationsWrapper';
import { ManageUsers } from '../components/CustomerSupport/ManageUsers';
import { WorkHistoryAutomationsWrapper } from '../components/CustomerSupport/ManageWorkHistory/ManageWorkHistoryAutomationsWrapper';
import DashboardDisplay from '../components/Dashboard/DashboardDisplay';
import ContactUsDisplay from '../components/DefaultPages/ContactUsDisplay';
import { PageNotFound } from '../components/DefaultPages/PageNotFound';
import PrivacyPolicyScreen from '../components/DefaultPages/PrivacyPolicyScreen';
import TermsAndConditions from '../components/DefaultPages/TermsAndConditions';
import { AllDocumentsWrapper } from '../components/Documents/Documents/AllDocumentsWrapper';
import { NewDocumentWrapper } from '../components/Documents/Documents/NewDocumentWrapper';
import { DocumentsWrapper } from '../components/Documents/DocumentsWrapper';
import { NewTemplateWrapper } from '../components/Documents/Templates/NewTemplateWrapper';
import { TemplatesWrapper } from '../components/Documents/Templates/TemplatesWrapper';
import { EmployeeFinancialOverview } from '../components/EmployeeFinancialOverview/EmployeeFinancialOverview';
import { EmployeeDetailsWrapper } from '../components/Employees/EmployeeDetailsWrapper';
import { Employees } from '../components/Employees/Employees';
import { EmployeesPayrollDetailsWrapper } from '../components/Employees/EmployeesPayrollDetailsWrapper';
import { HandlerEmployees } from '../components/Employees/HandlerEmployees';
import { Evacuations } from '../components/Evacuations/Evacuations';
import { InvoxyPage } from '../components/Integrations/IntegrationPages/InvoxyPage';
import { XeroInvoicingPage } from '../components/Integrations/IntegrationPages/XeroInvoicingPage';
import { InvoicingIntegrationsPage } from '../components/Integrations/InvoicingIntegrationsPage';
import { IPayrollPage } from '../components/Integrations/Payroll/IPayroll/IPayrollPage';
import { PayHeroPage } from '../components/Integrations/Payroll/PayHero/PayHeroPage';
import { SmartlyPage } from '../components/Integrations/Payroll/Smartly/SmartlyPage';
import { XeroPayrollPage } from '../components/Integrations/Payroll/Xero/XeroPayrollPage';
import { PayrollIntegrationsPage } from '../components/Integrations/PayrollIntegrationsPage';
import { Invoicing } from '../components/Invoicing/Invoicing';
import LeaveReview from '../components/LeaveReview/LeaveReview';
import { CompanyInfo } from '../components/Management/Options/CompanyInfo';
import { EditSiteDetailsWrapper } from '../components/Management/Options/EditSite/EditSiteDetailsWrapper';
import { Kiosks } from '../components/Management/Options/Kiosk/Kiosks';
import { NewSiteWrapper } from '../components/Management/Options/NewSite/NewSiteWrapper';
import { Options } from '../components/Management/Options/Options';
import { Payroll } from '../components/Management/Payroll/Payroll';
import { AccountsWrapper } from '../components/ManageSignUps/AccountsWrapper';
import { NewAccountsWrapper } from '../components/ManageSignUps/NewAccountsWrapper';
import { UsersWrapper } from '../components/ManageSignUps/UsersWrapper';
import { Metrics } from '../components/Metrics/Metrics';
import { PPEStore } from '../components/PPEStore/PPEStore';
import catprojectPages from '../components/ProjectTracking/CATProjects';
import { catprojectsAccountTypes } from '../components/ProjectTracking/CATProjects/CATProjectsConstants';
import { ProjectTrackingPageWrapper } from '../components/ProjectTracking/ProjectTrackingPageWrapper';
import workbenchPages from '../components/ProjectTracking/Workbench';
import { ContractRatesWrapper } from '../components/Rates/ContractRatesWrapper';
import { DefaultEmployeeRatesWrapper } from '../components/Rates/DefaultEmployeeRatesWrapper';
import { Rates } from '../components/Rates/Rates';
import { ActivitiesReportWrapper } from '../components/Reports/Activities/ActivitiesReportWrapper';
import { ProjectTrackingReportWrapper } from '../components/Reports/ProjectTracking/ProjectTrackingReportWrapper';
import { ReportsPageWrapper } from '../components/Reports/ReportsPageWrapper';
import { SiteLogReportWrapper } from '../components/Reports/SiteLog/SiteLogReportWrapper';
import { TimesheetsReportWrapper } from '../components/Reports/Timesheets/TimesheetsReportsWrapper';
import { AttendanceWrapper } from '../components/SignInBook/AttendanceWrapper';
import { GuestsWrapper } from '../components/SignInBook/GuestsWrapper';
import { SignInBook } from '../components/SignInBook/SignInBook';
import { SignInOutLog } from '../components/SignInBook/SignInOutLog';
import { MyTimesheets } from '../components/Timesheets/MyTimesheets/MyTimesheets';
import { CreateWrapper } from '../components/Timesheets/Timesheets/Create/CreateWrapper';
import { DetailsWrapper } from '../components/Timesheets/Timesheets/Details/DetailsWrapper';
import { ExportWrapper } from '../components/Timesheets/Timesheets/Export/ExportWrapper';
import { OverviewWrapper } from '../components/Timesheets/Timesheets/Overview/OverviewWrapper';
import { SiteActivitiesWrapper } from '../components/Timesheets/Timesheets/SiteActivities/SiteActivitiesWrapper';
import { TimesheetsPageWrapper } from '../components/Timesheets/Timesheets/TimesheetsPageWrapper';
import { FixMeLater } from './AnyTypes';
import {
	AccountType,
	accountTypes,
	Company,
	CompanyTypes,
	UserDetails,
} from './Common';
import { DefaultPagePaths } from './PagePaths';
import { workbenchAccountTypes } from './Workbench';

export type Page = {
	name: string;
	element: React.ElementType<FixMeLater>;
	path: string;
	accountRequirements: AccountType[];
	icon?: JSX.Element;
	parent?: string;
	showInDrawer: boolean;
	alwaysShowTitle?: boolean;
	featureFlag?: string[];
	renderCondition?: (
		featureFlagCheck: (flag: string) => boolean,
		user: UserDetails,
		company: Company | null,
	) => boolean;
	// turns out this is valid - be careful as this will be dealt with recursivley
	nestedPages?: Pick<
		Page,
		| 'name'
		| 'element'
		| 'nestedPages'
		| 'path'
		| 'accountRequirements'
		| 'renderCondition'
	>[];
};

export type DefaultPage = Pick<
	Page,
	'name' | 'element' | 'icon' | 'parent' | 'showInDrawer'
> & {
	path: DefaultPagePaths | '/*';
	renderRoute: boolean;
	alwaysShowTitle?: boolean;
};

export type Header = {
	name: string;
	type: string;
	parent: string;
	accountRequirements: string[];
	showInDrawer: true;
};

export const defaultPageConfig: DefaultPage[] = [
	{
		name: 'Contact Us',
		element: ContactUsDisplay,
		path: '/contact-us',
		icon: <ContactPageIcon />,
		parent: 'home',
		showInDrawer: true,
		renderRoute: false,
		alwaysShowTitle: true,
	},
	{
		name: 'Privacy Policy',
		element: PrivacyPolicyScreen,
		path: '/privacy-policy',
		icon: <AssignmentIcon />,
		parent: 'home',
		showInDrawer: true,
		renderRoute: false,
		alwaysShowTitle: true,
	},
	{
		name: 'Terms & Conditions',
		element: TermsAndConditions,
		path: '/terms-and-conditions',
		icon: <AssignmentIcon />,
		parent: 'home',
		showInDrawer: true,
		renderRoute: false,
		alwaysShowTitle: true,
	},
	{
		name: 'Page Not Found',
		element: PageNotFound,
		path: '/404',
		icon: <QuestionMarkIcon />,
		parent: 'home',
		showInDrawer: false,
		renderRoute: true,
		alwaysShowTitle: false,
	},
	// this page should ALWAYS be last
	{
		name: 'Page Not Found',
		element: PageNotFound,
		path: '/*',
		icon: <QuestionMarkIcon />,
		parent: 'home',
		showInDrawer: false,
		renderRoute: true,
		alwaysShowTitle: false,
	},
	// don't put anything below here in this list
];

export const pageConfig: Page[] = [
	{
		name: 'Dashboard',
		element: DashboardDisplay,
		path: 'dashboard',

		parent: 'dashboard',
		icon: <DashboardIcon />,
		accountRequirements: [
			'handler',
			'management',
			'seniorManagement',
			'cx',
			'thirdPartyWorker',
			'worker',
		],
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Timesheets',
		element: TimesheetsPageWrapper,
		path: 'timesheets',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <FormatListBulletedIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Overview',
				element: OverviewWrapper,
				path: 'overview',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Details',
				element: DetailsWrapper,
				path: 'details',
				accountRequirements: ['handler'],
			},
			{
				name: 'Review',
				element: DetailsWrapper,
				path: 'review',
				accountRequirements: ['management', 'seniorManagement'],
			},
			{
				name: 'Create',
				element: CreateWrapper,
				path: 'create',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Export',
				element: ExportWrapper,
				path: 'export',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Site Activities',
				element: SiteActivitiesWrapper,
				path: 'site-activities',
				accountRequirements: ['management', 'seniorManagement'],
			},
		],
	},
	{
		name: 'My Timesheets',
		element: MyTimesheets,
		path: 'mytimesheets',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <FormatListBulletedIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Sign In Book',
		element: SignInBook,
		path: 'sign-in-book',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <MenuBookIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Attendance',
				element: AttendanceWrapper,
				path: 'attendance',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Sign In Book',
				element: SignInOutLog,
				path: 'sign-in-book',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Guests',
				element: GuestsWrapper,
				path: 'guests',
				accountRequirements: ['management', 'seniorManagement'],
			},
		],
	},
	{
		name: 'Account Settings',
		element: AccountSettings,
		path: 'account-settings',
		icon: <QuestionMarkIcon />,
		parent: 'home',
		accountRequirements: [
			'handler',
			'management',
			'seniorManagement',
			'cx',
			'thirdPartyWorker',
			'worker',
		],
		showInDrawer: false,
		alwaysShowTitle: true,
	},
	{
		name: 'Accounts',
		element: AccountsWrapper,
		path: 'accounts',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <PeopleOutlineIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'New Accounts',
				element: NewAccountsWrapper,
				path: 'new-accounts',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Users',
				element: UsersWrapper,
				path: 'users',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
		],
	},
	{
		name: 'Compliance',
		element: ComplianceWrapper,
		path: 'compliance',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <VerifiedRoundedIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Inductions',
				element: InductionsWrapper,
				path: 'inductions',
				accountRequirements: ['management', 'seniorManagement'],
			},
			{
				name: 'configure inductions',
				element: ConfigureInductionsWrapper,
				path: 'configure-inductions',
				accountRequirements: ['management', 'seniorManagement'],
			},
			{
				name: 'Safety Courses',
				element: SafetyCoursesWrapper,
				path: 'safety-courses',
				accountRequirements: ['seniorManagement', 'handler'],
			},
			{
				name: 'History',
				element: ComplianceHistoryWrapper,
				path: 'history',
				accountRequirements: ['seniorManagement', 'management'],
			},
		],
	},
	{
		name: 'Documents',
		element: DocumentsWrapper,
		path: 'documents',
		accountRequirements: ['seniorManagement', 'handler'],
		icon: <DescriptionOutlinedIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Documents',
				element: AllDocumentsWrapper,
				path: 'documents',
				accountRequirements: ['seniorManagement', 'handler'],
			},
			{
				name: 'Templates',
				element: TemplatesWrapper,
				path: 'templates',
				accountRequirements: ['seniorManagement', 'handler'],
			},
			{
				name: 'New Document',
				element: NewDocumentWrapper,
				path: 'new-document',
				accountRequirements: ['seniorManagement', 'handler'],
			},
			{
				name: 'New Template',
				element: NewTemplateWrapper,
				path: 'new-template',
				accountRequirements: ['seniorManagement', 'handler'],
			},
		],
	},
	{
		name: 'Manage Companies',
		element: ManageCompanies,
		path: 'companies',
		accountRequirements: ['cx'],
		icon: <BusinessIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Manage Sites',
		element: ManageSites,
		path: 'sites',
		accountRequirements: ['cx'],
		icon: <PeopleOutlineIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Manage Users',
		element: ManageUsers,
		path: 'users',
		accountRequirements: ['cx'],
		icon: <PeopleOutlineIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Failed Automations',
		element: FailedAutomations,
		path: 'failed-automations',
		accountRequirements: ['cx'],
		icon: <RefreshIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Leave',
				element: ManageLeaveIntegrationsWrapper,
				path: 'leave',
				accountRequirements: ['cx'],
			},
			{
				name: 'Payroll Timesheet',
				element: ManageTimesheetPayrollIntegrationsWrapper,
				path: 'payroll-timesheets',
				accountRequirements: ['cx'],
			},
			{
				name: 'Work History Timesheets',
				element: WorkHistoryAutomationsWrapper,
				path: 'work-history',
				accountRequirements: ['cx'],
			},
			{
				name: 'Invoices',
				element: ManageInvoicingIntegrationsWrapper,
				path: 'invoices',
				accountRequirements: ['cx'],
			},
		],
	},
	{
		name: 'Evacuations',
		element: Evacuations,
		path: 'evacuations',
		accountRequirements: ['management'],
		icon: <DoorFrontIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Reports',
		element: ReportsPageWrapper,
		path: 'reports',
		accountRequirements: ['management', 'seniorManagement', 'handler'],
		icon: <AssessmentIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Timesheets',
				element: TimesheetsReportWrapper,
				path: 'timesheets',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Site Log',
				element: SiteLogReportWrapper,
				path: 'site-log',
				accountRequirements: [
					'management',
					'seniorManagement',
					'handler',
				],
			},
			{
				name: 'Project Tracking',
				element: ProjectTrackingReportWrapper,
				path: 'project-tracking',
				accountRequirements: ['management', 'seniorManagement'],
			},
			{
				name: 'Activites',
				element: ActivitiesReportWrapper,
				path: 'activities',
				accountRequirements: ['management', 'seniorManagement'],
			},
		],
	},
	{
		name: 'Options',
		element: Options,
		path: 'options',
		accountRequirements: ['handler', 'management', 'seniorManagement'],
		icon: <TuneIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Site Details',
				element: EditSiteDetailsWrapper,
				path: 'site-details',
				renderCondition: (_, __, company) =>
					!!company?.canCreateSites ||
					company?.companyType === CompanyTypes.construction,
				accountRequirements: [
					'handler',
					'management',
					'seniorManagement',
				],
			},
			{
				name: 'New Site',
				element: NewSiteWrapper,
				path: 'new-site',
				renderCondition: (_, __, company) =>
					!!company?.canCreateSites ||
					company?.companyType === CompanyTypes.construction,
				accountRequirements: [
					'handler',
					'management',
					'seniorManagement',
				],
			},
			{
				name: 'Company Details',
				element: CompanyInfo,
				path: 'company-details',
				accountRequirements: ['handler', 'seniorManagement'],
			},
			{
				name: 'Kiosks',
				element: Kiosks,
				path: 'kiosks',
				accountRequirements: ['management', 'seniorManagement'],
			},
		],
	},
	{
		name: 'Rates',
		element: Rates,
		path: 'rates',
		accountRequirements: ['handler'],
		icon: <LibraryBooksIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Rates',
				element: ContractRatesWrapper,
				path: 'rates',
				accountRequirements: ['handler'],
			},
			{
				name: 'Default Rates',
				element: DefaultEmployeeRatesWrapper,
				path: 'default-rates',
				accountRequirements: ['handler'],
			},
		],
	},
	{
		name: 'Leave Review',
		element: LeaveReview,
		path: 'leave-review',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <ReceiptIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
	},
	{
		name: 'Employees',
		element: Employees,
		path: 'employees',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <PeopleIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Details',
				element: EmployeeDetailsWrapper,
				path: 'details',
				accountRequirements: ['handler', 'seniorManagement'],
			},
			{
				name: 'Profiles',
				element: HandlerEmployees,
				path: 'profiles',
				accountRequirements: ['handler'],
			},
			{
				name: 'Payroll',
				element: EmployeesPayrollDetailsWrapper,
				path: 'payroll',
				accountRequirements: ['handler', 'seniorManagement'],
			},
		],
	},
	{
		name: 'Project Tracking Integrations',
		element: ProjectTrackingPageWrapper,
		path: 'project-tracking',
		accountRequirements: [...workbenchAccountTypes],
		icon: <PendingActionsOutlinedIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		renderCondition: (_, userDetails, company) =>
			!!company?.projectTrackingIntegrated ||
			userDetails.accountType === accountTypes.seniorManagement,
	},
	{
		name: 'Workbench',
		element: workbenchPages.PageWrapper,
		path: 'project-tracking/workbench',
		accountRequirements: [...workbenchAccountTypes],
		icon: <PendingActionsOutlinedIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Employees',
				element: workbenchPages.EmployeesWrapper,
				path: 'employees',
				accountRequirements: ['seniorManagement'],
			},
			{
				name: 'Sites',
				element: workbenchPages.SitesWrapper,
				path: 'sites',
				accountRequirements: [...workbenchAccountTypes],
			},
			{
				name: 'Activities',
				element: workbenchPages.ActivitiesWrapper,
				path: 'activities',
				accountRequirements: [...workbenchAccountTypes],
			},
		],
	},
	{
		name: 'CATProjects',
		element: catprojectPages.PageWrapper,
		path: 'project-tracking/catprojects',
		accountRequirements: [...catprojectsAccountTypes],
		icon: <PendingActionsOutlinedIcon />,
		parent: 'dashboard',
		featureFlag: ['enableCATProjectsIntegration'],
		showInDrawer: false,
		alwaysShowTitle: true,
		nestedPages: [
			{
				name: 'Employees',
				element: catprojectPages.EmployeesWrapper,
				path: 'employees',
				accountRequirements: [...catprojectsAccountTypes],
			},
			{
				name: 'Activities',
				element: catprojectPages.ActivitiesWrapper,
				path: 'activities',
				accountRequirements: [...catprojectsAccountTypes],
			},
			{
				name: 'Sites',
				element: catprojectPages.SitesWrapper,
				path: 'sites',
				accountRequirements: [...catprojectsAccountTypes],
			},
			{
				name: 'Export',
				element: catprojectPages.ExportWrapper,
				path: 'export',
				accountRequirements: [...catprojectsAccountTypes],
			},
		],
	},
	{
		name: 'Payroll Integrations',
		element: PayrollIntegrationsPage,
		path: 'connect',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Invoicing Integrations',
		element: InvoicingIntegrationsPage,
		path: 'connect-invoicing',
		accountRequirements: ['handler'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'IPayroll',
		element: IPayrollPage,
		path: 'connect/ipayroll',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Invoxy',
		element: InvoxyPage,
		path: 'connect-invoicing/invoxy',
		accountRequirements: ['handler'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Xero',
		element: XeroInvoicingPage,
		path: 'connect-invoicing/xero',
		accountRequirements: ['handler'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'PayHero',
		element: PayHeroPage,
		path: 'connect/payhero',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Xero',
		element: XeroPayrollPage,
		path: 'connect/xero',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Smartly',
		element: SmartlyPage,
		path: 'connect/smartly',
		accountRequirements: ['handler', 'seniorManagement'],
		icon: <IntegrationInstructionsIcon />,
		parent: 'dashboard',
		showInDrawer: false,
		alwaysShowTitle: true,
		featureFlag: [],
	},
	{
		name: 'Invoices',
		element: Invoicing,
		path: 'invoices',
		accountRequirements: ['handler'],
		icon: <ReceiptIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: ['enableInvoicesPage'],
	},
	{
		name: 'Metrics',
		element: Metrics,
		path: 'metrics',
		accountRequirements: ['handler'],
		icon: <TimelineIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: ['enableMetricsPage'],
	},
	{
		name: 'Payroll',
		element: Payroll,
		path: 'payroll',
		accountRequirements: ['handler'],
		icon: <PodcastsIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: ['enablePayrollPage'],
	},
	{
		name: 'PPE Store',
		element: PPEStore,
		path: 'ppe-store',
		accountRequirements: ['handler'],
		icon: <StoreIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: ['enablePPEStorePage'],
	},
	{
		name: 'Financial Overview',
		element: EmployeeFinancialOverview,
		path: 'financial-overview',
		accountRequirements: ['handler'],
		icon: <PodcastsIcon />,
		parent: 'dashboard',
		showInDrawer: true,
		alwaysShowTitle: true,
		featureFlag: ['enableFinancialOverviewPage'],
	},
	// End Demo Features
];
