import {
	IntegrationAtRest,
	IntegrationCollection,
} from '../../models/Integrations/Integration';
import {
	ActivityTypesMapping,
	BaseIntegrationLink,
} from '../../models/Integrations/IntegrationElements';
import { PayrollType } from '../../models/Integrations/PayrollIntegration';
import { Firestore, FirestoreDataConverter } from '../firebase';

const EMPLOYEE_LINKS_COLLECTION = 'employees';
const PLACEMENT_LINKS_COLLECTION = 'placements';

const baseLinkConverter: FirestoreDataConverter<BaseIntegrationLink> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as BaseIntegrationLink,
};

const createIntegrationEmployeeLink = async (
	integrationCollection: IntegrationCollection,
	integrationCompanyID: string,
	employeeLink: BaseIntegrationLink,
): Promise<void> =>
	await Firestore.collection(integrationCollection)
		.doc(integrationCompanyID)
		.collection(EMPLOYEE_LINKS_COLLECTION)
		.doc(employeeLink.id)
		.set(employeeLink);

const updateIntegrationEmployeeLink = async (
	integrationCollection: IntegrationCollection,
	integrationCompanyID: string,
	employeeLink: BaseIntegrationLink,
): Promise<void> =>
	await Firestore.collection(integrationCollection)
		.doc(integrationCompanyID)
		.collection(EMPLOYEE_LINKS_COLLECTION)
		.doc(employeeLink.id)
		.update(employeeLink);

const deleteIntegrationEmployeeLink = async (
	integrationCollection: IntegrationCollection,
	integrationCompanyID: string,
	employeeLinkID: string,
): Promise<void> =>
	await Firestore.collection(integrationCollection)
		.doc(integrationCompanyID)
		.collection(EMPLOYEE_LINKS_COLLECTION)
		.doc(employeeLinkID)
		.delete();

const integrationEmployeeLinksSubscription = (
	integrationCollection: IntegrationCollection,
	integrationCompanyID: string,
	callback: (employeeLinks: Record<string, BaseIntegrationLink>) => void,
): (() => void) =>
	Firestore.collection(integrationCollection)
		.doc(integrationCompanyID)
		.collection(EMPLOYEE_LINKS_COLLECTION)
		.withConverter(baseLinkConverter)
		.onSnapshot((querySnapshot) => {
			const employeeLinks: Record<string, BaseIntegrationLink> = {};
			querySnapshot.forEach((doc) => {
				const employeeLink = doc.data();
				employeeLinks[doc.id] = employeeLink;
			});
			callback(employeeLinks);
		});

const integrationPlacementLinksSubscription = (
	integrationCollection: IntegrationCollection,
	integrationCompanyID: string,
	callback: (employeeLinks: Record<string, BaseIntegrationLink>) => void,
): (() => void) =>
	Firestore.collection(integrationCollection)
		.doc(integrationCompanyID)
		.collection(PLACEMENT_LINKS_COLLECTION)
		.withConverter(baseLinkConverter)
		.onSnapshot((querySnapshot) => {
			const employeeLinks: Record<string, BaseIntegrationLink> = {};
			querySnapshot.forEach((doc) => {
				const employeeLink = doc.data();
				employeeLinks[doc.id] = employeeLink;
			});
			callback(employeeLinks);
		});

const updateIntegrationActivityMappings = async (
	integrationCollection: IntegrationCollection,
	companyID: string,
	updatedActivityLinks: ActivityTypesMapping,
): Promise<void> =>
	await Firestore.collection(integrationCollection)
		.doc(companyID)
		.update({ activityTypes: updatedActivityLinks });

const updateIntegrationLeaveMappings = async (
	integrationCollection: Extract<
		IntegrationCollection,
		'payrollIntegrations'
	>,
	companyID: string,
	updatedLeaveTypes: Pick<IntegrationAtRest<PayrollType>, 'leaveTypes'>,
): Promise<void> =>
	await Firestore.collection(integrationCollection)
		.doc(companyID)
		.update(updatedLeaveTypes);

const integrationFirebaseApi = {
	createIntegrationEmployeeLink,
	deleteIntegrationEmployeeLink,
	integrationEmployeeLinksSubscription,
	integrationPlacementLinksSubscription,
	updateIntegrationActivityMappings,
	updateIntegrationEmployeeLink,
	updateIntegrationLeaveMappings,
};

export default integrationFirebaseApi;
