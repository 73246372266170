import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	Box,
	CardActions,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { ExplicitAny } from '../../../constants/AnyTypes';
import { UserDetails } from '../../../constants/Common';
import { Document } from '../../../constants/Document';
import { Template } from '../../../constants/Template';

export type NewDocumentFormProps = {
	templates: Template[];
	selectedTemplateState: [
		Template | null,
		Dispatch<SetStateAction<Template | null>>,
	];
	employees: UserDetails[];
	selectedEmployeeState: [
		UserDetails | null,
		Dispatch<SetStateAction<UserDetails | null>>,
	];
	customFieldsState: [
		Document['documentFields'],
		Dispatch<SetStateAction<Document['documentFields']>>,
	];
	documentNameState: [string | null, Dispatch<SetStateAction<string | null>>];
	sendDocument: (
		templateID: string,
		templateName: string,
		employeeID: string,
	) => Promise<void>;
};

export const NewDocumentForm = ({
	templates,
	selectedTemplateState,
	employees,
	selectedEmployeeState,
	customFieldsState,
	documentNameState,
	sendDocument,
}: NewDocumentFormProps): JSX.Element => {
	const [sendingDocument, setSendingDocument] = useState<boolean>(false);
	const [selectedTemplate, setSelectedTemplate] = selectedTemplateState;
	const [selectedEmployee, setSelectedEmployee] = selectedEmployeeState;
	const [customFields, setCustomFields] = customFieldsState;
	const [documentName, setDocumentName] = documentNameState;

	const handleSendDocument = async (): Promise<void> => {
		if (
			selectedTemplate !== null &&
			selectedEmployee !== null &&
			Object.values(customFields).every((field) => field.trim() !== '')
		) {
			setSendingDocument(true);
			await sendDocument(
				selectedTemplate.id,
				selectedTemplate.name,
				selectedEmployee.userID,
			);
			setSendingDocument(false);
		}
	};

	const setInitialDocumentFields = (
		templateFields: Template['fields'],
	): Document['documentFields'] => {
		const initialFields: Document['documentFields'] = {};
		return Object.entries(templateFields)
			.sort(([, indexA], [, indexB]) => indexA - indexB)
			.reduce(
				(fields, [field]) => ({
					...fields,
					[field]: '',
				}),
				initialFields,
			);
	};

	return (
		<Stack height="100%" justifyContent="space-between" spacing={2}>
			<Typography variant="h5">New Document</Typography>
			<Stack spacing={2} paddingY={1} height="100%" overflow="auto">
				<Autocomplete
					data-testid="template-autocomplete"
					value={selectedTemplate}
					options={templates}
					getOptionLabel={(option): string => option.name ?? ''}
					fullWidth
					onChange={(
						_: React.SyntheticEvent,
						value: Template | null,
					): void => {
						setSelectedTemplate(value);
						setCustomFields(
							value ? setInitialDocumentFields(value.fields) : {},
						);
					}}
					isOptionEqualToValue={(option, value): boolean =>
						option === value
					}
					renderOption={(
						props: ExplicitAny,
						option: Template,
					): JSX.Element => (
						<MenuItem
							{...props}
							label={option.name}
							variant="standard"
							key={option.id}>
							{option.name}
						</MenuItem>
					)}
					disabled={sendingDocument}
					renderInput={(params): JSX.Element => (
						<TextField
							{...params}
							label="Template (required)"
							inputProps={{
								...params.inputProps,
							}}
							InputLabelProps={{ shrink: true }}
							required
							size="small"
							placeholder="Please select a template"
							error={false}
						/>
					)}
				/>
				<Autocomplete
					data-testid="employee-autocomplete"
					value={selectedEmployee}
					options={employees}
					getOptionLabel={(option): string =>
						option.displayName ?? ''
					}
					fullWidth
					onChange={(
						_: React.SyntheticEvent,
						value: UserDetails | null,
					): void => {
						setSelectedEmployee(value);
					}}
					isOptionEqualToValue={(option, value): boolean =>
						option === value
					}
					renderOption={(
						props: ExplicitAny,
						option: UserDetails,
					): JSX.Element => (
						<MenuItem
							{...props}
							label={option.displayName}
							variant="standard"
							key={option.userID}>
							{option ? option.displayName : 'No Employees'}
						</MenuItem>
					)}
					disabled={sendingDocument}
					renderInput={(params): JSX.Element => (
						<TextField
							{...params}
							label="Recipient (required)"
							inputProps={{
								...params.inputProps,
							}}
							InputLabelProps={{ shrink: true }}
							required
							size="small"
							placeholder="Please select a employee"
						/>
					)}
				/>
				<TextField
					fullWidth
					size="small"
					label="Document Name"
					placeholder={
						selectedTemplate
							? selectedEmployee
								? `${selectedTemplate.name} - ${selectedEmployee.displayName}`
								: `${selectedTemplate.name}`
							: ''
					}
					InputLabelProps={{ shrink: true }}
					disabled={sendingDocument}
					value={documentName ?? ''}
					onChange={(event): void =>
						event.target.value.trim() === ''
							? setDocumentName(null)
							: setDocumentName(event.target.value)
					}
				/>
				{Object.keys(customFields).length > 0 && (
					<Typography variant="body1">Custom Fields</Typography>
				)}
				{Object.entries(customFields).map(([field, value], index) => (
					<TextField
						key={`${index}-${field}`}
						fullWidth
						size="small"
						label={`${field} (required)`}
						required
						InputLabelProps={{ shrink: true }}
						disabled={sendingDocument}
						value={value}
						onChange={(event): void =>
							setCustomFields((prev) => ({
								...prev,
								[field]: event.target.value,
							}))
						}
					/>
				))}
			</Stack>
			<CardActions sx={{ justifyContent: 'flex-end', padding: 0 }}>
				<Tooltip title="Send document for employee to sign">
					<Box>
						<LoadingButton
							variant="contained"
							loading={sendingDocument}
							disabled={
								sendingDocument ||
								selectedTemplate === null ||
								selectedEmployee === null ||
								Object.values(customFields).some(
									(field) => field.trim() === '',
								)
							}
							onClick={handleSendDocument}>
							Send Document
						</LoadingButton>
					</Box>
				</Tooltip>
			</CardActions>
		</Stack>
	);
};
