import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
} from '@mui/material';
import { add, endOfWeek, startOfWeek, sub } from 'date-fns';
import firebase from 'firebase';
import { Dispatch, useEffect, useState } from 'react';
import { SiteList, UserDetails } from '../../../constants/Common';
import {
	InvoiceStatuses,
	ProjectTrackingStatuses,
	Timesheet,
	TimesheetPayrollStatuses,
	WorkHistoryStatus,
} from '../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../constants/Timesheet/TimesheetStatus';
import { getRates } from '../../../constants/Timesheet/TimesheetUtilities';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import DateWeekSelector from '../../DateWeekSelector/DateWeekSelector';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { SiteSelectDropdown } from '../../SiteSelectDropdown/SiteSelectDropdown';
import { DuplicateTimesheetAlert } from '../DuplicateTimesheetAlert';

export enum DuplicateType {
	None = 'None',
	Current = 'Current',
	History = 'History',
}

type DuplicateTimesheetIndex =
	| { type: DuplicateType.None }
	| { type: Exclude<DuplicateType, DuplicateType.None>; index: number };

export type AddTimesheetDialogProps = {
	open: boolean;
	loading: boolean;
	handleClose: () => void;
	goToTimesheet: (duplicate: {
		type: Exclude<DuplicateType, DuplicateType.None>;
		index: number;
	}) => void;
	timesheets: Record<string, Timesheet[]>;
	setWeek: Dispatch<Date>;
	sites: SiteList;
	recentSites: SiteList;
	userDetails: UserDetails;
	weekEnding: Date;
	setSelected: Dispatch<number>;
	firebaseApi: Pick<
		FirebaseApi,
		| 'createTimesheet'
		| 'getLimitedContractsByEmployeeSiteSupplierAccepterStatus'
		| 'getLimitedContractByEmployeeSupplierAccepterStatus'
		| 'getUser'
	>;
};

export const AddTimesheetDialog = ({
	userDetails,
	open,
	loading,
	handleClose,
	timesheets,
	goToTimesheet,
	sites,
	recentSites,
	firebaseApi,
	weekEnding,
	setWeek,
}: AddTimesheetDialogProps): JSX.Element => {
	const [duplicateTimesheetIndex, setDuplicateTimesheetIndex] =
		useState<DuplicateTimesheetIndex>({ type: DuplicateType.None });
	const [site, setSite] = useState<string>(
		userDetails ? userDetails.siteID : '',
	);
	const [siteError, setSiteError] = useState<boolean>(false);

	useEffect(() => {
		const currentDuplicateIndex = timesheets.current.findIndex(
			(timesheet) => timesheet.site.id === site,
		);
		const historyDuplicateIndex = timesheets.history.findIndex(
			(timesheet) => timesheet.site.id === site,
		);

		if (currentDuplicateIndex !== -1) {
			setDuplicateTimesheetIndex({
				type: DuplicateType.Current,
				index: currentDuplicateIndex,
			});
		} else if (historyDuplicateIndex !== -1) {
			setDuplicateTimesheetIndex({
				type: DuplicateType.History,
				index: historyDuplicateIndex,
			});
		} else {
			setDuplicateTimesheetIndex({ type: DuplicateType.None });
		}
	}, [timesheets, site]);

	const handleGoToTimesheet = (): void => {
		if (duplicateTimesheetIndex.type !== DuplicateType.None) {
			goToTimesheet(duplicateTimesheetIndex);
		}
		handleClose();
	};

	const createNewTimesheet = async (): Promise<void> => {
		const selectedSite = sites[site];
		if (!selectedSite) {
			setSiteError(true);
			return;
		}
		const rates = await getRates(
			userDetails.userID,
			selectedSite.id,
			userDetails.companyID,
			userDetails.contractedTo?.id,
			firebaseApi,
		);
		const newTimesheet: Omit<Timesheet, 'id'> = {
			employer: {
				id: userDetails.companyID,
				name: userDetails.company,
			},
			invoiceStatus: InvoiceStatuses.Unsent,
			site: {
				company: selectedSite.company,
				companyID: selectedSite.companyID,
				id: selectedSite.id,
				name: selectedSite.name,
			},
			contractedTo: userDetails.contractedTo,
			timesheetStatus: TimesheetStatus.Active,
			cost: {
				billable: 0,
			},
			employee: {
				id: userDetails.userID,
				name: userDetails.displayName,
				paid: false,
				type: userDetails.workerType,
			},
			week: firebase.firestore.Timestamp.fromDate(
				startOfWeek(weekEnding),
			),
			weekEnding: firebase.firestore.Timestamp.fromDate(weekEnding),
			contract: rates,
			hours: {
				monday: {
					billable: 0,
					break: 0,
				},
				tuesday: {
					billable: 0,
					break: 0,
				},
				wednesday: {
					billable: 0,
					break: 0,
				},
				thursday: {
					billable: 0,
					break: 0,
				},
				friday: {
					billable: 0,
					break: 0,
				},
				saturday: {
					billable: 0,
					break: 0,
				},
				sunday: {
					billable: 0,
					break: 0,
				},
				total: {
					billable: 0,
					break: 0,
				},
			},
			dateSubmitted: null,
			reviewedAt: null,
			reviewer: null,
			lastEditedBy: {
				name: userDetails.displayName,
				id: userDetails.userID,
			},
			payrollStatus: TimesheetPayrollStatuses.Unsent,
			projectTrackingStatus: ProjectTrackingStatuses.Unsent,
			workHistoryStatus: WorkHistoryStatus.Unsent,
		};
		await firebaseApi.createTimesheet(newTimesheet, []);
		handleClose();
	};

	return (
		<Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
			<DialogTitle>Select Your Site and Week Beginning</DialogTitle>
			<DialogContent>
				{loading ? (
					<LoadingDots />
				) : (
					<List>
						{duplicateTimesheetIndex.type !==
							DuplicateType.None && (
							<ListItem sx={{ display: 'block' }}>
								<DuplicateTimesheetAlert
									handleGoToTimesheet={handleGoToTimesheet}
								/>
							</ListItem>
						)}
						<ListItem>
							<SiteSelectDropdown
								onChange={(_, value): void => {
									if (value) {
										setSiteError(false);
										setSite(value.id);
									}
								}}
								value={sites[site] ?? null}
								siteGroups={{
									'Recent Sites': recentSites,
									Sites: sites,
								}}
								error={siteError}
							/>
						</ListItem>
						<ListItem>
							<DateWeekSelector
								onChange={(newWeek: Date | null): void => {
									setWeek(
										(newWeek && endOfWeek(newWeek)) ??
											endOfWeek(new Date()),
									);
								}}
								date={weekEnding}
								dateLimits={{
									minDate: sub(endOfWeek(new Date()), {
										weeks: 3,
									}),
									maxDate: add(endOfWeek(new Date()), {
										weeks: 1,
									}),
								}}
								showWeekLabel
								weekEnding
								allowFuture
							/>
						</ListItem>
					</List>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={createNewTimesheet}
					disabled={
						(duplicateTimesheetIndex.type !== DuplicateType.None &&
							site !== '') ||
						loading
					}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
