import { ActivitiesWrapper } from './Activities/ActivitiesWrapper';
import { EmployeesWrapper } from './Employees/EmployeesWrapper';
import { PageWrapper } from './PageWrapper';
import { SitesWrapper } from './Sites/SitesWrapper';

const workbenchPages = {
	ActivitiesWrapper,
	EmployeesWrapper,
	SitesWrapper,
	PageWrapper,
};
export type WorkbenchPages = typeof workbenchPages;
export default workbenchPages;
