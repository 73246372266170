// Horrific hackery to allow us to name the zip folder, instead of getting a pseudo-random memory address
export const downloadBlob = (blobURL: string, fileName: string): void => {
	const fileLink = document.createElement('a');
	fileLink.href = blobURL;
	fileLink.download = fileName;
	fileLink.click();
	fileLink.parentNode?.removeChild(fileLink);
};

export type ExportStatuses =
	| 'none'
	| 'zipEmpty'
	| 'loading'
	| 'done'
	| 'error'
	| 'warning';
