import { cloneDeep } from 'lodash';
import { Page, pageConfig } from '../../constants/PageConfig';
import { useCompanySubscriptionContext } from '../CompanySubscriptionProvider';
import { useFeatureFlagContext } from '../featureFlags/Provider';
import { useUserDetailsContext } from '../UserProvider';
import { PageConfigContext } from './context';

type PageConfigProviderProps = {
	children: React.ReactNode;
};

const isPage = (
	page: Page | NonNullable<Page['nestedPages']>[number],
): page is Page => (page as Page).showInDrawer !== undefined;

export const PageConfigProvider = ({
	children,
}: PageConfigProviderProps): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const companySubscription = useCompanySubscriptionContext();
	const featureFlags = useFeatureFlagContext();

	const recurseShouldRenderPage = (
		page: Page | NonNullable<Page['nestedPages']>[number],
	): boolean => {
		if (
			isPage(page) &&
			page.featureFlag &&
			page.featureFlag.some((flag) => !featureFlags.get(flag))
		) {
			return false;
		}

		if (!userDetails?.accountType) return false;

		const shouldRender =
			page.accountRequirements.includes(userDetails.accountType) &&
			(page.renderCondition === undefined ||
				page.renderCondition(
					featureFlags.get,
					userDetails,
					companySubscription.company,
				));

		if (!shouldRender) {
			return false;
		}

		if (page.nestedPages) {
			page.nestedPages = page.nestedPages.filter(recurseShouldRenderPage);
		}

		return true;
	};

	const pages = cloneDeep(pageConfig).filter((page) =>
		recurseShouldRenderPage(page),
	);

	return (
		<PageConfigContext.Provider value={pages}>
			{children}
		</PageConfigContext.Provider>
	);
};
