import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { MappingTypes } from './MappingTypes';

type ActionSuccessfulSnackBarProps = {
	type: MappingTypes;
	integrationConnectionDeleted: boolean;
	actionSuccessfulSnackBarOpen: boolean;
	setActionSuccessfulSnackBarOpen: React.Dispatch<
		React.SetStateAction<boolean>
	>;
};

export const ActionSuccessfulSnackBar = ({
	type,
	integrationConnectionDeleted,
	actionSuccessfulSnackBarOpen,
	setActionSuccessfulSnackBarOpen,
}: ActionSuccessfulSnackBarProps): JSX.Element => (
	<CustomSnackBar
		open={actionSuccessfulSnackBarOpen}
		onClose={(
			event?: React.SyntheticEvent | Event,
			reason?: string,
		): void => {
			if (reason === 'clickaway') {
				return;
			}
			setActionSuccessfulSnackBarOpen(false);
		}}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		snackBarText={`Integration ${type} Connection ${
			integrationConnectionDeleted ? 'Removed' : 'Updated'
		}`}
	/>
);
