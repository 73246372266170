import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { FixMeLater } from '../../../../constants/AnyTypes';

export const OverviewTableTheme = ({
	children,
	centeredColumns,
}: {
	children: JSX.Element;
	centeredColumns?: number[];
}): JSX.Element => {
	const columns = centeredColumns
		?.map((col) => `&:nth-of-type(${col})`)
		.join(', ');

	return (
		<ThemeProvider
			theme={(baseTheme: Theme): Theme =>
				createTheme({
					...baseTheme,
					components: {
						...baseTheme.components,
						// MUIDataTable styles are not recognised by the MUI Theme
						// This is a hacky workaround, if their types were good we would not need this
						MUIDataTableBodyCell: {
							styleOverrides: {
								root: {
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
						},
						MUIDataTableHeadCell: {
							// This is fragile styling as if columns change too much this breaks.
							// Currently, columns are okay.
							// We have to apply some custom styling to the table column headers here as
							// MUIDataTable doesn't let us do a custom render without losing the default styling
							styleOverrides: {
								root:
									columns !== undefined
										? {
												[columns]: {
													'& span': {
														display: 'flex',
														justifyContent:
															'center',
														margin: 0,
													},
													'& button': {
														margin: 0,
													},
													'& div': {
														display: 'flex',
														justifyContent:
															'center',
														margin: 0,
													},
												},
										  }
										: {},
							},
						},
					},
					// MUIDataTables does not export types https://github.com/gregnb/mui-datatables/pull/1882#issuecomment-1140911360
				} as FixMeLater)
			}>
			{children}
		</ThemeProvider>
	);
};
