import SyncIcon from '@mui/icons-material/Sync';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { ExplicitAny } from '../../../../constants/AnyTypes';
import { DataTable } from '../../../DataTable/DataTable';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { FilterChip } from '../../../Timesheets/Timesheets/Overview/FilterChip';
import { IntegrationLinkStatus } from '../IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTableTheme } from './IntegrationTableTheme';

export type LinkFilter = 'All' | 'Linked' | 'Unlinked' | 'Missing';

const linkDisplayFilters: Record<LinkFilter, IntegrationLinkStatus[]> = {
	All: [
		IntegrationLinkStatus.Linked,
		IntegrationLinkStatus.Unlinked,
		IntegrationLinkStatus.Missing,
	],
	Linked: [IntegrationLinkStatus.Linked],
	Unlinked: [IntegrationLinkStatus.Unlinked],
	Missing: [IntegrationLinkStatus.Missing],
};

export type IntegrationTableProps<T extends { status: IntegrationLinkStatus }> =
	{
		title: string;
		data: T[];
		columns: MUIDataTableColumnDef[];
		loading: boolean;
		triggerRefresh?: () => Promise<void>;
		missingDataLabel: string;
		customSearch?: (
			searchQuery: string,
			currentRow: ExplicitAny[],
		) => boolean;
		centeredColumns?: number[];
		selection?: [number | null, ((selected: number) => void) | null];
		customToolbarItems?: JSX.Element;
	};

export const IntegrationTable = <T extends { status: IntegrationLinkStatus }>({
	title,
	data,
	columns,
	loading,
	triggerRefresh,
	missingDataLabel,
	centeredColumns,
	customSearch,
	selection,
	customToolbarItems,
}: IntegrationTableProps<T>): JSX.Element => {
	const [filteredTableData, setFilteredTableData] = useState<T[]>([]);
	const [currentFilter, setCurrentFilter] = useState<LinkFilter>('All');
	const [linkStatusCount, setLinkStatusCount] = useState<
		Record<LinkFilter, number>
	>({
		All: 0,
		Linked: 0,
		Unlinked: 0,
		Missing: 0,
	});
	const [disableRefresh, setDisableRefresh] = useState(false);

	const tableOptions: MUIDataTableOptions = {
		tableBodyHeight: 'calc(100vh - 272px)',
		download: false,
		filter: false,
		print: false,
		pagination: false,
		elevation: 0,
		customSearch: customSearch,
		isRowSelectable: () => false,
		customToolbar: () => renderCustomToolbar(),
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots style={{ minHeight: '170px' }} />
				) : (
					missingDataLabel
				),
			},
		},
	};

	useEffect(() => {
		const filteredData = data.filter((item) => {
			if (currentFilter === 'Linked') {
				return item.status === IntegrationLinkStatus.Linked;
			} else if (currentFilter === 'Unlinked') {
				return item.status === IntegrationLinkStatus.Unlinked;
			} else if (currentFilter === 'Missing') {
				return item.status === IntegrationLinkStatus.Missing;
			} else {
				return true;
			}
		});

		setFilteredTableData(filteredData);
	}, [currentFilter, data]);

	useEffect(() => {
		if (loading || data.length === 0) {
			setLinkStatusCount({
				All: 0,
				Linked: 0,
				Unlinked: 0,
				Missing: 0,
			});
		} else {
			const linked = data.filter(
				(link) => link.status === IntegrationLinkStatus.Linked,
			).length;
			const unlinked = data.filter(
				(link) => link.status === IntegrationLinkStatus.Unlinked,
			).length;
			const missing = data.filter(
				(link) => link.status === IntegrationLinkStatus.Missing,
			).length;
			setLinkStatusCount({
				All: linked + unlinked + missing,
				Linked: linked,
				Unlinked: unlinked,
				Missing: missing,
			});
		}
	}, [data, loading]);

	const handleRefresh = async (): Promise<void> => {
		if (!triggerRefresh) return;
		setDisableRefresh(true);
		await triggerRefresh();
		setDisableRefresh(false);
	};

	const renderCustomToolbar = (): JSX.Element => {
		return (
			<>
				{renderRefreshButton()}
				{customToolbarItems}
			</>
		);
	};

	const renderRefreshButton = (): JSX.Element =>
		triggerRefresh ? (
			<LoadingButton
				data-testid="refresh-button"
				onClick={handleRefresh}
				disabled={disableRefresh}
				loading={disableRefresh}
				sx={{ display: 'inline-flex', minWidth: '0px' }}>
				<Tooltip title="Refresh Column">
					<SyncIcon />
				</Tooltip>
			</LoadingButton>
		) : (
			<></>
		);

	const renderTableTitle = (): JSX.Element => (
		<Grid container spacing={1} pt={1}>
			<Grid xs={12} item>
				<Typography variant="h4">{title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Stack spacing={1} direction="row">
					{Object.keys(linkDisplayFilters).map((titleKey) => {
						const title = titleKey as LinkFilter;
						return (
							<FilterChip
								title={title}
								key={title}
								currentFilter={currentFilter}
								onClick={(): void => setCurrentFilter(title)}
								count={linkStatusCount[title]}
							/>
						);
					})}
				</Stack>
			</Grid>
		</Grid>
	);

	return (
		<IntegrationTableTheme centeredColumns={centeredColumns}>
			<DataTable
				title={renderTableTitle()}
				tableData={loading ? [] : filteredTableData}
				columns={columns}
				customTableOptions={tableOptions}
				selection={selection}
			/>
		</IntegrationTableTheme>
	);
};
