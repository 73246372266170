import { Box, Tabs, Tab } from '@mui/material';
import { useMatch, Outlet } from 'react-router';
import { useSearchParams, Link } from 'react-router-dom';
import { UserProps, assertAccountType } from '../../constants/Common';
import {
	SignInBookAccountType,
	signInBookAccountType,
	signInBookConfig,
} from './signInBookConfig';

export const SignInBook = ({ userDetails }: UserProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<SignInBookAccountType>(
		accountType,
		signInBookAccountType,
	);
	const titles = signInBookConfig(accountType, userDetails).titles;
	const pathMatch = useMatch('/sign-in-book/:tab');
	const [searchParams] = useSearchParams();

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
