import activitiesFirebaseApi from './activities';
import companyFirebaseApi from './companies';
import competencyTemplatesFirebaseApi from './competencyTemplates';
import complianceReportsFirebaseApi from './compliance';
import contractsFirebaseApi from './contracts';
import documentsFirebaseApi from './documents';
import emailsFirebaseApi from './emails';
import evacuationsFirebaseApi from './evacuations';
import featureFlagsFirebaseApi from './featureFlags';
import guestFirebaseApi from './guests';
import inductionsFirebaseApi from './Inductions';
import integrationFirebaseApi from './integrations';
import invoicingIntegrationsFirebaseApi from './invoicingIntegrations';
import leaveFirebaseApi from './leave';
import payrollFirebaseApi from './payroll';
import payrollIntegrationsFirebaseApi from './payrollIntegrations';
import personasFirebaseApi from './personas';
import projectTrackingApi from './projectTrackingIntegrations';
import qualificationTemplatesFirebaseApi from './qualificationTemplates';
import safetyCoursesApi from './safetyCourses';
import siteLogsFirebaseApi from './sitelogs';
import sitesFirebaseApi from './sites';
import storageFirebaseApi from './storage';
import templatesFirebaseApi from './templates';
import timesheetsFirebaseApi from './timesheets';
import userProfilesFirebaseApi from './userProfiles';
import usersFirebaseApi from './users';
import websiteDataFirebaseApi from './websiteData';

const firebaseApi = {
	...activitiesFirebaseApi,
	...companyFirebaseApi,
	...competencyTemplatesFirebaseApi,
	...complianceReportsFirebaseApi,
	...contractsFirebaseApi,
	...documentsFirebaseApi,
	...emailsFirebaseApi,
	...evacuationsFirebaseApi,
	...featureFlagsFirebaseApi,
	...guestFirebaseApi,
	...inductionsFirebaseApi,
	...integrationFirebaseApi,
	...invoicingIntegrationsFirebaseApi,
	...leaveFirebaseApi,
	...payrollFirebaseApi,
	...payrollIntegrationsFirebaseApi,
	...personasFirebaseApi,
	...projectTrackingApi,
	...qualificationTemplatesFirebaseApi,
	...safetyCoursesApi,
	...siteLogsFirebaseApi,
	...sitesFirebaseApi,
	...templatesFirebaseApi,
	...timesheetsFirebaseApi,
	...userProfilesFirebaseApi,
	...usersFirebaseApi,
	...websiteDataFirebaseApi,
	...storageFirebaseApi,
};

export type FirebaseApi = typeof firebaseApi;

export default firebaseApi;
