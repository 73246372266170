import { UserDetails } from '../../constants/Common';
import { RecaptchaVerifier, Auth } from '../../firebase/firebase';
import { useUserAuthContext } from '../../providers/UserProvider';
import { MultiFactorDialog, MultiFactorState } from './MultiFactorDialog';
import { enrolMultiFactor, acceptVerificationCode } from './multiFactorUtils';

type MultiFactorDialogWrapperProps = {
	multiFactorState: MultiFactorState;
	setMultiFactorState: (state: MultiFactorState) => void;
	recaptchaVerifier: RecaptchaVerifier;
	accountDetails: UserDetails;
};

const auth = Auth();

export const MultiFactorDialogWrapper = ({
	multiFactorState,
	setMultiFactorState,
	recaptchaVerifier,
	accountDetails,
}: MultiFactorDialogWrapperProps): JSX.Element => {
	const user = useUserAuthContext();

	return (
		<MultiFactorDialog
			multiFactorState={multiFactorState}
			setMultiFactorState={setMultiFactorState}
			enrolMultiFactor={enrolMultiFactor}
			acceptVerificationCode={acceptVerificationCode}
			recaptchaVerifier={recaptchaVerifier}
			accountDetails={accountDetails}
			user={user}
			auth={auth}
		/>
	);
};
