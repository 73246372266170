import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { RatesTextFields } from './RatesTextFields';

export type EditEmployeeRatesDialogProps = {
	employeeDisplayName: string;
	modalOpen: boolean;
	closeModal: () => void;
	newChargeOut: number;
	newPayRate: number;
	newOnCost: number;
	newMargin: number;
	handleChargeOutChange: (rate: number) => void;
	handlePayRateChange: (rate: number) => void;
	handleOnCostChange: (rate: number) => void;
	handleSaveDetails: () => void;
};

export const EditEmployeeRatesDialog = ({
	employeeDisplayName,
	modalOpen,
	closeModal,
	newChargeOut,
	newPayRate,
	newOnCost,
	newMargin,
	handleChargeOutChange,
	handlePayRateChange,
	handleOnCostChange,
	handleSaveDetails,
}: EditEmployeeRatesDialogProps): JSX.Element => (
	<Dialog
		color="default"
		fullWidth
		maxWidth="xs"
		open={modalOpen}
		onClose={closeModal}
		disableScrollLock>
		<DialogTitle>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center">
				{`${employeeDisplayName} Employee Rates`}
				<IconButton onClick={closeModal}>
					<Close />
				</IconButton>
			</Box>
		</DialogTitle>
		<DialogContent>
			<RatesTextFields
				newChargeRate={newChargeOut}
				// always false as we handle by disabling the save button here instead
				chargeRateError={false}
				payRateError={false}
				newPayRate={newPayRate}
				newOnCost={newOnCost}
				onCostError={false}
				newMargin={newMargin}
				handleChargeRateChange={handleChargeOutChange}
				handlePayRateChange={handlePayRateChange}
				handleOnCostChange={handleOnCostChange}
			/>
		</DialogContent>
		<DialogActions>
			<Button variant="outlined" onClick={closeModal}>
				Cancel
			</Button>
			<Button
				variant="contained"
				onClick={(): void => handleSaveDetails()}
				disabled={
					newChargeOut <= 0 ||
					newPayRate <= 0 ||
					newOnCost < 0 ||
					newOnCost > 1
				}>
				Save
			</Button>
		</DialogActions>
	</Dialog>
);
