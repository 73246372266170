import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Box, Chip, Tooltip } from '@mui/material';
import { SafetyCourseValidationStatus } from '../../../../constants/SafetyCourse';
import {
	ExpiredTooltip,
	NotFoundTooltip,
	ValidTooltip,
} from '../SafetyCourseStatusChip/SafetyCourseStatusChip';

export type SafetyCourseStatusIconProps = {
	status: SafetyCourseValidationStatus;
};

export const SafetyCourseStatusIcon = ({
	status,
}: SafetyCourseStatusIconProps): JSX.Element => {
	switch (status) {
		case SafetyCourseValidationStatus.Expired:
			return (
				<ExpiredTooltip>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center">
						<ErrorOutlineOutlinedIcon
							sx={{ fontSize: 64 }}
							color="error"
						/>
						<Chip label="Expired" size="small" color="error" />
					</Box>
				</ExpiredTooltip>
			);
		case SafetyCourseValidationStatus.Valid:
			return (
				<ValidTooltip>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center">
						<CheckCircleOutlineOutlinedIcon
							color="success"
							sx={{ fontSize: 64 }}
						/>
						<Chip
							label="Valid"
							size="small"
							color="success"
							sx={{ width: '100%' }}
						/>
					</Box>
				</ValidTooltip>
			);
		case SafetyCourseValidationStatus.NotFound:
			return (
				<NotFoundTooltip>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center">
						<HighlightOffOutlinedIcon
							sx={{ fontSize: 64 }}
							color="error"
						/>
						<Chip label="Not Found" size="small" color="error" />
					</Box>
				</NotFoundTooltip>
			);
		case SafetyCourseValidationStatus.Pending:
			return (
				<Tooltip title="Safety Course Status ">
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center">
						<HelpOutlineOutlinedIcon
							color="neutral"
							sx={{ fontSize: 64 }}
						/>
						<Chip label="Pending" size="small" color="neutral" />
					</Box>
				</Tooltip>
			);
	}
};
