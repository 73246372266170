import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type DeleteTemplateBody = {
	templateID: string;
};

export const deleteTemplate = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	templateID: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<DeleteTemplateBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.DeleteTemplate,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			templateID,
		},
	});
};
