import { useEffect, useState } from 'react';

export const useAbortController = (): AbortSignal => {
	const [abortController, setAbortController] = useState(
		new AbortController(),
	);

	useEffect(() => {
		setAbortController(new AbortController());
	}, []);

	useEffect(() => {
		return () => abortController.abort(new RerenderingError());
	}, [abortController]);
	return abortController.signal;
};

export class RerenderingError extends Error {
	constructor() {
		super('Re-Rendering');
	}
}
