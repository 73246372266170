import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useState } from 'react';

export type UsersRemoveModalProps = {
	userName: string;
	modalOpen: boolean;
	setModalOpen: (value: boolean) => void;
	removeUserFromCompany: () => Promise<void>;
};

export const UsersRemoveModal = ({
	userName,
	modalOpen,
	setModalOpen,
	removeUserFromCompany,
}: UsersRemoveModalProps): JSX.Element => {
	const [loading, setLoading] = useState(false);

	const onRemoveClick = async (): Promise<void> => {
		setLoading(true);
		await removeUserFromCompany();
		setModalOpen(false);
		setLoading(false);
	};
	return (
		<Dialog fullWidth open={modalOpen}>
			<DialogTitle>Are You Sure?</DialogTitle>
			<DialogContent>
				{`This will remove ${userName} from your company.`}
			</DialogContent>
			<DialogActions>
				<Button
					disabled={loading}
					variant="outlined"
					onClick={(): void => setModalOpen(false)}
					color="primary">
					Cancel
				</Button>
				<LoadingButton
					disabled={loading}
					loading={loading}
					variant="contained"
					onClick={onRemoveClick}
					color="error">
					Remove
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
