import { TextFieldProps } from '@mui/material';
import {
	matchIsValidTel,
	MuiTelInput,
	MuiTelInputCountry,
} from 'mui-tel-input';

type PhoneNumberInputProps = {
	value: string;
	onChange: (
		event:
			| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
			| string,
	) => void;
	helperText?: string;
	disabled?: boolean;
	label?: string;
	required?: boolean;
	onlyCountries?: MuiTelInputCountry[];
	preferredCountries?: MuiTelInputCountry[];
	fullWidth?: boolean;
	InputProps?: TextFieldProps['InputProps'];
	size?: 'small' | 'medium';
	placeHolder?: string;
	checkError?: boolean;
};

export const PhoneNumberInput = ({
	value,
	onChange,
	helperText,
	disabled,
	label,
	required,
	onlyCountries,
	preferredCountries,
	fullWidth,
	InputProps,
	size,
	placeHolder,
	checkError = true,
}: PhoneNumberInputProps): JSX.Element => {
	const error = !validatePhoneNumber(value);
	return (
		<MuiTelInput
			value={value}
			required={required}
			onChange={onChange}
			error={checkError && error && helperText !== ''}
			helperText={helperText}
			disabled={disabled ?? false}
			label={label ?? 'Mobile Number (required)'}
			onlyCountries={onlyCountries ?? ['NZ', 'AU']}
			preferredCountries={preferredCountries ?? ['NZ']}
			fullWidth={fullWidth ?? true}
			InputProps={InputProps}
			size={size}
			placeholder={placeHolder}
		/>
	);
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
	return matchIsValidTel(phoneNumber);
};
