import { Activity } from '../../constants/Common';

type ActivityHoursTotals = {
	totals: Record<
		string,
		{
			name: string;
			total: number;
		}
	>;
	allHours: number;
};

export const getActivityHourTotals = (
	activities: Pick<Activity, 'id' | 'activity' | 'hours'>[],
): ActivityHoursTotals => {
	let allHours = 0;
	const totals = activities.reduce<ActivityHoursTotals['totals']>(
		(totals, activity) => {
			if (totals[activity.activity.id]) {
				totals[activity.activity.id].total += activity.hours;
			} else {
				totals[activity.activity.id] = {
					name: activity.activity.name,
					total: activity.hours,
				};
			}
			allHours += activity.hours;
			return totals;
		},
		{},
	);
	return { totals, allHours };
};
