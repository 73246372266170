import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Grid,
	Box,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Activity } from '../../../constants/Common';
import { getActivityHourTotals } from '../../helpers/activityHelpers';

type TotalActivityHours = { name: string; total: number };

export type ActivitiesReportSummaryProps = {
	activities: Activity[];
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ActivitiesReportSummary = ({
	activities,
	open,
	setOpen,
}: ActivitiesReportSummaryProps): JSX.Element => {
	const [activityHours, setActivityHours] = useState<TotalActivityHours[]>(
		[],
	);
	const [totalActivityHours, setTotalActivityHours] = useState(0);

	useEffect(() => {
		const { totals, allHours } = getActivityHourTotals(activities);
		const sortedActivities = Object.values(totals).sort(
			(activityA, activityB) =>
				activityA.name.localeCompare(activityB.name) ||
				activityB.total - activityA.total,
		);
		setActivityHours(sortedActivities);
		setTotalActivityHours(allHours);
	}, [activities]);

	return (
		<Accordion
			elevation={0}
			onChange={(): void => setOpen(!open)}
			disableGutters
			disabled={activities.length === 0}
			expanded={open && activities.length !== 0}
			sx={{
				border: '1px solid',
				borderColor: 'neutral.light',
			}}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				sx={{
					backgroundColor: 'neutral.light',
					flexDirection: 'row-reverse',
				}}>
				<Grid container>
					<Grid item sm={12} md={6} lg={8} xl={9}>
						<Typography variant="subtitle1Bold">Summary</Typography>
					</Grid>
					<Grid
						item
						sm={12}
						md={6}
						lg={4}
						xl={3}
						display="flex"
						paddingRight={2}
						paddingLeft={{ lg: 1, md: 0.5, sm: 0 }}
						justifyContent="space-between">
						<Typography variant="subtitle1Bold">Total</Typography>
						<Typography variant="subtitle1Bold">
							{totalActivityHours}
						</Typography>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container>
					{activityHours.map((activity) => (
						<Grid
							item
							sm={12}
							md={6}
							lg={4}
							xl={3}
							key={activity.name}>
							<Box
								display="flex"
								justifyContent="space-between"
								paddingX={2}>
								<Typography>{activity.name}</Typography>
								<Typography>{activity.total}</Typography>
							</Box>
						</Grid>
					))}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};
