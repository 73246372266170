import { Stack } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import { useState, useEffect } from 'react';
import { accountTypes, UserDetails, Guest } from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { RemoveUserButton } from '../ManageSignUps/RemoveUserButton';

export type GuestsProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'updateUserDetailsSiteInfo'
		| 'updateUserDetailsCompanyInfo'
		| 'updateUserProfileCompany'
		| 'deleteGuest'
		| 'subscribeGuestsBySite'
		| 'subscribeToAllGuests'
	>;
};

export const Guests = ({
	userDetails,
	firebaseApi,
}: GuestsProps): JSX.Element => {
	const [selected, setSelected] = useState(0);
	const [guests, setGuests] = useState<Guest[]>([]);
	const [loading, setLoading] = useState(true);

	const noMatchTableText =
		userDetails?.siteID === '' ? (
			'Please select a site'
		) : loading ? (
			<LoadingDots />
		) : (
			'There are no guests for your site'
		);
	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const columns = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'company',
			label: 'Company',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				searchable: false,
				setCellHeaderProps,
				customBodyRender: (id: string): JSX.Element => (
					<Stack direction="row" spacing={1} width="100%" pr={1}>
						<RemoveUserButton
							accountType={userDetails.accountType}
							existingUserID={id}
							existingUserName={
								guests.find((guest) => guest.id === id)
									?.displayName ?? ''
							}
							isGuest={true}
							firebaseApi={firebaseApi}
						/>
					</Stack>
				),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	useEffect(() => {
		if (userDetails.siteID === '') {
			setLoading(false);
			return;
		}
		const subscriptionCallback: (guests: Record<string, Guest>) => void = (
			guests,
		) => {
			const guestList = Object.values(guests);
			setGuests(guestList);
			setLoading(false);
		};
		let unsub;
		if (userDetails.accountType === accountTypes.cx) {
			unsub = firebaseApi.subscribeToAllGuests(subscriptionCallback);
		} else {
			unsub = firebaseApi.subscribeGuestsBySite(
				userDetails.siteID,
				subscriptionCallback,
			);
		}
		return unsub;
	}, [userDetails.siteID, userDetails.accountType, firebaseApi]);

	return (
		<DataTable
			tableData={guests}
			columns={columns}
			title={
				userDetails.accountType === accountTypes.cx
					? 'Guests in the system'
					: 'Guests'
			}
			selection={[selected, setSelected]}
			customTableOptions={tableOptions}
		/>
	);
};
