import { Box, Tab, Tabs } from '@mui/material';
import { Link, Outlet, useMatch, useSearchParams } from 'react-router-dom';
import { UserProps, assertAccountType } from '../../constants/Common';
import {
	ContractsAccountType,
	contractsAccountType,
	ratesConfig,
} from '../../constants/Contract';

export const Rates = ({ userDetails }: Required<UserProps>): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<ContractsAccountType>(accountType, contractsAccountType);
	const titles = ratesConfig[accountType].titles;
	const pathMatch = useMatch('/rates/:tab');
	const [searchParams] = useSearchParams();

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
