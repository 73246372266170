import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../providers/UserProvider';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { ConfigureInductions } from './ConfigureInductions';

export const ConfigureInductionsWrapper = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const user = useUserAuthContext();
	const abortSignal = useAbortController();

	return !userDetails || !user ? (
		<LoadingDots />
	) : (
		<ConfigureInductions
			userDetails={userDetails}
			firebaseApi={firebaseApi}
			cloudFunctionApi={cloudFunctionApi}
			user={user}
			abortSignal={abortSignal}
		/>
	);
};
