import { Autocomplete, MenuItem, TextField, Box } from '@mui/material';
import {
	SafetyCourse,
	SafetyCourseValidationResponse,
	SafetyCourseValidationStatus,
} from '../../../../../constants/SafetyCourse';
import { safetyCourseToResponse } from '../../../../../constants/SafetyCourseUtilities';
import { SafetyCourseValidationInfo } from '../../../SafetyCourses/SafetyCourseStatusInfo/SafetyCourseStatusInfo';

export type NewInductionExistingSafetyCourseSelectProps = {
	existingSafetyCourses: Record<string, SafetyCourse>;
	selectedSafetyCourseID: string | null;
	handleSafetyCourseResponseChange: (
		option: SafetyCourseValidationResponse | undefined | null,
	) => void;
	safetyCourseResponse: SafetyCourseValidationResponse | undefined | null;
};

export const NewInductionExistingSafetyCourseSelect = ({
	existingSafetyCourses,
	selectedSafetyCourseID,
	handleSafetyCourseResponseChange,
	safetyCourseResponse,
}: NewInductionExistingSafetyCourseSelectProps): JSX.Element => {
	return (
		<>
			<Autocomplete
				fullWidth
				renderOption={(props, option: string): JSX.Element => (
					<MenuItem {...props} key={option}>
						{existingSafetyCourses[option]?.course.name}
					</MenuItem>
				)}
				value={selectedSafetyCourseID}
				onChange={(_, newValue): void => {
					if (newValue) {
						handleSafetyCourseResponseChange(
							safetyCourseToResponse(
								existingSafetyCourses[newValue].course,
							),
						);
					} else {
						handleSafetyCourseResponseChange(undefined);
					}
				}}
				size="small"
				renderInput={(params): JSX.Element => (
					<TextField
						{...params}
						label="Select from Inductee's Safety Course"
						inputProps={{ ...params.inputProps }}
					/>
				)}
				getOptionLabel={(option: string): string =>
					existingSafetyCourses[option]?.course.name ?? ''
				}
				options={Object.keys(existingSafetyCourses)}
			/>
			<Box display="flex" alignItems="center">
				<SafetyCourseValidationInfo
					status={
						safetyCourseResponse
							? SafetyCourseValidationStatus.Valid
							: SafetyCourseValidationStatus.Pending
					}
					safetyCourseID={safetyCourseResponse?.id ?? ''}
					courseName={safetyCourseResponse?.name ?? ''}
					courseExpiry={safetyCourseResponse?.expiryDate ?? null}
				/>
			</Box>
		</>
	);
};
