import { Grid, Link, Paper, Typography } from '@mui/material';

export const ErrorFooter = ({ error }: { error: string }): JSX.Element => {
	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-around"
			textAlign="center"
			spacing={1}>
			<Grid item xs={12}>
				<Paper>
					<Typography textAlign="center" color="error">
						{error}
					</Typography>
					<Link href="/contact-us">Contact Trade Legion</Link> if the
					problem persists.
				</Paper>
			</Grid>
		</Grid>
	);
};
