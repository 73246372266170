import { MinimalInterface, Site, UserDetails } from '../../constants/Common';

type SetErrorFunction = (
	errorField: 'workerTypeError' | 'siteError' | 'contractedToError',
	errorMessage: string,
) => void;

type ApproveUserFunction = (
	updatedUserDetails: Pick<
		UserDetails,
		| 'accountType'
		| 'workerType'
		| 'site'
		| 'siteID'
		| 'siteCompany'
		| 'siteCompanyID'
		| 'contractedTo'
		| 'company'
		| 'companyID'
	>,
) => void;

type ValidationChain = {
	validateSite: (siteID: string) => ValidationChain;
	validateContractedTo: (
		contractedTo: MinimalInterface,
		site: MinimalInterface,
	) => ValidationChain;
	finalize: () => void;
};

export const accountApproveValidation = (
	accountType: UserDetails['accountType'],
	workerType: UserDetails['workerType'],
	selectedSite: Pick<Site, 'name' | 'id' | 'company' | 'companyID'>,
	company: MinimalInterface,
	contractedToCompany: MinimalInterface | null,
	setError: SetErrorFunction,
	approveUser: ApproveUserFunction,
): ValidationChain => {
	let hasError = false;

	const chain: ValidationChain = {
		validateSite: (siteID: string) => {
			if (!siteID) {
				setError('siteError', 'Please select a site');
				hasError = true;
			}
			return chain;
		},

		validateContractedTo: (
			contractedTo: MinimalInterface,
			site: MinimalInterface,
		) => {
			if (!contractedTo && site) {
				setError(
					'contractedToError',
					'Must select contracted company for employees with a site',
				);
				hasError = true;
			} else if (contractedTo && !site) {
				setError(
					'siteError',
					'Must select a site for contracted employees',
				);
				hasError = true;
			}
			return chain;
		},

		finalize: () => {
			if (!hasError) {
				const site = selectedSite
					? selectedSite
					: {
							name: '',
							id: '',
							company: '',
							companyID: '',
					  };
				approveUser({
					accountType: accountType,
					workerType: workerType,
					contractedTo: contractedToCompany,
					company: company.name,
					companyID: company.id,
					site: site.name,
					siteID: site.id,
					siteCompany: site.company,
					siteCompanyID: site.companyID,
				});
			}
		},
	};

	return chain;
};
