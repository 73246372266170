import { Box, Menu } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';

type MenuButtonProps = {
	buttonText: string;
	options: {
		name: string;
		onClick: () => Promise<void>;
	}[];
	buttonProps?: ButtonProps;
};

export const MenuButton = ({
	buttonText,
	options,
	buttonProps,
}: MenuButtonProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = !!anchorEl;
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Box
			sx={{
				height: '100%',
			}}>
			<Button onClick={handleClick} {...buttonProps}>
				{buttonText}
			</Button>
			<Menu
				open={open}
				onClose={(): void => setAnchorEl(null)}
				anchorEl={anchorEl}>
				{options.map((option) => (
					<MenuItem
						sx={{ width: '100%' }}
						onClick={option.onClick}
						key={option.name}>
						{option.name}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};
