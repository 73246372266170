import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
	Portal,
	Dialog,
	DialogTitle,
	Stack,
	Typography,
	IconButton,
	DialogContent,
	DialogActions,
} from '@mui/material';
import { PropsWithChildren } from 'react';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';

export type SafetyCourseDialogProps = {
	open: boolean;
	loading: boolean;
	disabled: boolean;
	errorSnackbarOpen: boolean;
	errorSnackbarMessage: string;
	handleCloseSnackBar: () => void;
	handleClose: () => void;
	handleConfirm: () => void;
};
export const SafetyCourseDialog = ({
	children,
	open,
	disabled,
	loading,
	errorSnackbarOpen,
	errorSnackbarMessage,
	handleCloseSnackBar,
	handleClose,
	handleConfirm,
}: PropsWithChildren<SafetyCourseDialogProps>): JSX.Element => {
	const headingText = "Verify Employee's Safety Course";
	const confirmText = 'Confirm';

	return (
		<>
			<Portal>
				<CustomSnackBar
					open={errorSnackbarOpen}
					autoHideDuration={6000}
					onClose={handleCloseSnackBar}
					snackBarText={errorSnackbarMessage}
					severity="error"
				/>
			</Portal>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Typography variant="inherit">{headingText}</Typography>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>{children}</Stack>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						variant="contained"
						disabled={disabled}
						loading={loading}
						onClick={handleConfirm}>
						{confirmText}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
