import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { emptyFunction } from '../../constants/Common';
import { useUserAuthContext } from '../../providers/UserProvider';
import { CustomSnackBar } from '../SnackBar/SnackBar';
import { SignInUpWrapper } from './SignInUpWrapper';

const AUTH_STATE = 'verify';

type VerifyEmailProps = {
	onSignOut: () => void;
};

export const VerifyEmail = ({ onSignOut }: VerifyEmailProps): JSX.Element => {
	const navigate = useNavigate();
	const userAuth = useUserAuthContext();

	const [emailVerifyError, setEmailVerifyError] = useState('');
	const [snackBarOpen, setSnackBarOpen] = useState(false);

	const sendVerificationEmail = (): void => {
		if (userAuth !== null && !userAuth.emailVerified) {
			userAuth.sendEmailVerification().then(
				() => {
					setSnackBarOpen(true);
					setEmailVerifyError('');
				},
				(reason) => {
					// handle failure
					if (reason.code === 'auth/too-many-requests') {
						setEmailVerifyError(
							'Too many requests, try again later',
						);
					}
				},
			);
		} else if (userAuth?.emailVerified) {
			setEmailVerifyError('Email already verified');
		}
	};

	const verificationSnackBar = (): JSX.Element => (
		<CustomSnackBar
			open={snackBarOpen}
			onClose={(): void => setSnackBarOpen(false)}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			snackBarText="Email Verification Sent"
		/>
	);

	const handleNext = (): (() => void) | undefined => {
		if (userAuth !== null) {
			userAuth.reload().then(() => {
				if (userAuth.emailVerified) {
					return navigate('/account-information');
				} else {
					setEmailVerifyError('Email not verified');
				}
			});
		} else {
			return emptyFunction;
		}
	};

	return (
		<>
			{verificationSnackBar()}
			<SignInUpWrapper
				title="Verify Email To Continue"
				authState={AUTH_STATE}
				back={{ label: 'Back', handle: () => navigate('/sign-up') }}
				next={{
					label: userAuth?.emailVerified
						? 'Next'
						: 'Verified? Continue',
					handle: handleNext,
				}}
				onSignOut={onSignOut}>
				<Grid container spacing={1}>
					<Grid item xs={12} display="flex" justifyContent="center">
						<Typography variant="h6">
							Verification Email Has Been sent
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center">
						{emailVerifyError !== '' && (
							<Typography sx={{ paddingTop: 1 }}>
								{emailVerifyError}
							</Typography>
						)}
						<Button
							onClick={sendVerificationEmail}
							fullWidth
							color="secondary">
							resend Verification Email
						</Button>
					</Grid>
				</Grid>
			</SignInUpWrapper>
		</>
	);
};
