import firebase from 'firebase';
import { PayrollType } from '../models/Integrations/PayrollIntegration';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type PayrollTokensTequestBody = {
	payrollType: PayrollType;
	token: string;
};

export const fetchPayrollTokens = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	code: string,
	payrollType: PayrollType,
): Promise<boolean | undefined> => {
	return await makeCloudFunctionRequestReturnSuccessStatus<PayrollTokensTequestBody>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.PayrollTokens,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: { token: code, payrollType: payrollType },
		},
	);
};
