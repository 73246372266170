type Options = {
	locale?: Locale;
	weekStartsOn?: 1 | 2 | 3 | 4 | 5 | 6;
};

const options: Options = {
	// Index of the first day of the week.
	// Sunday is 0, Monday is 1, Saturday is 6.
	weekStartsOn: 1,
};

export default options;
