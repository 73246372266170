import {
	Alert,
	AlertColor,
	AlertTitle,
	CircularProgress,
	Snackbar,
	SnackbarCloseReason,
	SnackbarOrigin,
} from '@mui/material';
import { ReactNode } from 'react';

type CustomSnackBarProps = {
	open: boolean;
	onClose: (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason,
	) => void;
	anchorOrigin?: SnackbarOrigin;
	snackBarTitle?: string;
	snackBarText: string;
	severity?: AlertColor;
	loading?: boolean;
	action?: ReactNode;
	autoHideDuration?: number;
};

export const CustomSnackBar = ({
	open,
	onClose,
	anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
	snackBarTitle,
	snackBarText,
	severity = 'success',
	loading = false,
	action,
	autoHideDuration = 6000,
}: CustomSnackBarProps): JSX.Element => (
	<Snackbar
		open={open}
		autoHideDuration={autoHideDuration}
		onClose={onClose}
		anchorOrigin={anchorOrigin}>
		<Alert
			onClose={onClose}
			severity={severity}
			sx={{ width: '100%', color: 'neutral.dark' }}
			action={action}
			icon={
				loading ? (
					<CircularProgress
						sx={{ alignSelf: 'center' }}
						size="1.5rem"
						color="info"
					/>
				) : undefined
			}>
			{snackBarTitle && <AlertTitle>{snackBarTitle}</AlertTitle>}
			{snackBarText}
		</Alert>
	</Snackbar>
);
