import { Box, Grid, Stack, Typography } from '@mui/material';
import { Document } from '../../../constants/Document';

type ViewDocumentProps = {
	document: Pick<Document, 'documentFields' | 'template'>;
};

export const ViewDocumentBody = ({
	document,
}: ViewDocumentProps): JSX.Element => {
	const orderedFields = Object.entries(document.documentFields).sort(
		([nameA], [nameB]) =>
			document.template.fields[nameA] - document.template.fields[nameB],
	);
	return (
		<>
			<Stack spacing={2} sx={{ overflowWrap: 'break-word' }}>
				<Box>
					<Grid container spacing={1}>
						{orderedFields.map(([key, value]) => (
							<Grid item xs={12} key={key}>
								<Typography variant="body1Bold">
									{key}
								</Typography>
								<Typography>{value}</Typography>
							</Grid>
						))}
					</Grid>
				</Box>
				<Box>
					<Typography whiteSpace="break-spaces">
						{document.template.body}
					</Typography>
				</Box>
			</Stack>
		</>
	);
};
