export const BASE_URL = process.env.REACT_APP_FIREBASE_HTTP_FUNCTION_URL ?? '';
type CloudFunctions =
	| 'multiTimesheet'
	| 'singleTimesheet'
	| 'payrollActivityIDs'
	| 'payrollLeaveIDs'
	| 'payrollEmployeeIDs'
	| 'payrollCompanyIDs'
	| 'payrollTokens'
	| 'payrollTimesheetExport'
	| 'payrollEmployeeImport'
	| 'payrollDelete'
	| 'payrollTriggerLeaveIntegration'
	| 'payrollTriggerTimesheetIntegration'
	| 'stripeCreateCustomer'
	| 'stripeCreateCustomerPortalSession'
	| 'stripeCreateCheckoutSession'
	| 'stripeGetCustomerOutstandingPayments'
	| 'stripeGetSubscriptionTrialDates'
	| 'invoicingActivityIDs'
	| 'invoicingContractIDs'
	| 'invoicingCompanyIDs'
	| 'invoicingEmployeeIDs'
	| 'invoicingTokens'
	| 'invoicingIntegrationsTrigger'
	| 'sendTimesheetReminderNotification'
	| 'createLeaveNote'
	| 'editLeaveNote'
	| 'deleteLeaveNote'
	| 'createTimesheetNote'
	| 'editTimesheetNote'
	| 'deleteTimesheetNote'
	| 'deleteAllTimesheetNotes'
	| 'createTemplate'
	| 'deleteTemplate'
	| 'createDocument'
	| 'workHistoryTrigger'
	| 'projectTrackingTokens'
	| 'projectTrackingEmployeeIDs'
	| 'projectTrackingProjectIDs'
	| 'projectTrackingCostingIDs'
	| 'projectTrackingExport'
	| 'createProjectTrackingEmployeeLink'
	| 'deleteProjectTrackingEmployeeLink'
	| 'createProjectTrackingSiteLink'
	| 'deleteProjectTrackingSiteLink'
	| 'createProjectTrackingActivityLink'
	| 'deleteProjectTrackingActivityLink'
	| 'updateProjectTrackingIntegrationStatus'
	| 'validateSafetyCourse'
	| 'createSafetyCourse'
	| 'updateSafetyCourse'
	| 'createInduction'
	| 'updateInduction'
	| 'createSite'
	| 'updateSite'
	| 'archiveSite'
	| 'createSite'
	| 'updateSite'
	| 'createInductionStep'
	| 'updateInductionStep'
	| 'deleteInductionStep';

const addBaseURL = <FunctionName extends CloudFunctions>(
	cloudFunctionName: FunctionName,
): `${string}/${FunctionName}` => `${BASE_URL}/${cloudFunctionName}`;

export const HTTPSCloudFunctionURLs: {
	readonly [FunctionName in Capitalize<CloudFunctions>]: `${string}/${Uncapitalize<FunctionName>}`;
} = {
	CreateLeaveNote: addBaseURL('createLeaveNote'),
	CreateTimesheetNote: addBaseURL('createTimesheetNote'),
	DeleteLeaveNote: addBaseURL('deleteLeaveNote'),
	DeleteTimesheetNote: addBaseURL('deleteTimesheetNote'),
	DeleteAllTimesheetNotes: addBaseURL('deleteAllTimesheetNotes'),
	EditLeaveNote: addBaseURL('editLeaveNote'),
	EditTimesheetNote: addBaseURL('editTimesheetNote'),
	InvoicingActivityIDs: addBaseURL('invoicingActivityIDs'),
	InvoicingCompanyIDs: addBaseURL('invoicingCompanyIDs'),
	InvoicingContractIDs: addBaseURL('invoicingContractIDs'),
	InvoicingEmployeeIDs: addBaseURL('invoicingEmployeeIDs'),
	InvoicingTokens: addBaseURL('invoicingTokens'),
	MultiTimesheet: addBaseURL('multiTimesheet'),
	PayrollActivityIDs: addBaseURL('payrollActivityIDs'),
	PayrollCompanyIDs: addBaseURL('payrollCompanyIDs'),
	PayrollDelete: addBaseURL('payrollDelete'),
	PayrollEmployeeIDs: addBaseURL('payrollEmployeeIDs'),
	PayrollEmployeeImport: addBaseURL('payrollEmployeeImport'),
	PayrollLeaveIDs: addBaseURL('payrollLeaveIDs'),
	PayrollTimesheetExport: addBaseURL('payrollTimesheetExport'),
	PayrollTokens: addBaseURL('payrollTokens'),
	SendTimesheetReminderNotification: addBaseURL(
		'sendTimesheetReminderNotification',
	),
	SingleTimesheet: addBaseURL('singleTimesheet'),
	StripeCreateCheckoutSession: addBaseURL('stripeCreateCheckoutSession'),
	StripeCreateCustomer: addBaseURL('stripeCreateCustomer'),
	StripeCreateCustomerPortalSession: addBaseURL(
		'stripeCreateCustomerPortalSession',
	),
	StripeGetCustomerOutstandingPayments: addBaseURL(
		'stripeGetCustomerOutstandingPayments',
	),
	StripeGetSubscriptionTrialDates: addBaseURL(
		'stripeGetSubscriptionTrialDates',
	),
	CreateTemplate: addBaseURL('createTemplate'),
	DeleteTemplate: addBaseURL('deleteTemplate'),
	CreateDocument: addBaseURL('createDocument'),
	PayrollTriggerLeaveIntegration: addBaseURL(
		'payrollTriggerLeaveIntegration',
	),
	PayrollTriggerTimesheetIntegration: addBaseURL(
		'payrollTriggerTimesheetIntegration',
	),
	WorkHistoryTrigger: addBaseURL('workHistoryTrigger'),
	InvoicingIntegrationsTrigger: addBaseURL('invoicingIntegrationsTrigger'),
	ProjectTrackingTokens: addBaseURL('projectTrackingTokens'),
	ProjectTrackingEmployeeIDs: addBaseURL('projectTrackingEmployeeIDs'),
	ProjectTrackingProjectIDs: addBaseURL('projectTrackingProjectIDs'),
	ProjectTrackingCostingIDs: addBaseURL('projectTrackingCostingIDs'),
	ProjectTrackingExport: addBaseURL('projectTrackingExport'),
	CreateProjectTrackingEmployeeLink: addBaseURL(
		'createProjectTrackingEmployeeLink',
	),
	DeleteProjectTrackingEmployeeLink: addBaseURL(
		'deleteProjectTrackingEmployeeLink',
	),
	CreateProjectTrackingSiteLink: addBaseURL('createProjectTrackingSiteLink'),
	DeleteProjectTrackingSiteLink: addBaseURL('deleteProjectTrackingSiteLink'),
	CreateProjectTrackingActivityLink: addBaseURL(
		'createProjectTrackingActivityLink',
	),
	DeleteProjectTrackingActivityLink: addBaseURL(
		'deleteProjectTrackingActivityLink',
	),
	UpdateProjectTrackingIntegrationStatus: addBaseURL(
		'updateProjectTrackingIntegrationStatus',
	),
	ValidateSafetyCourse: addBaseURL('validateSafetyCourse'),
	CreateSafetyCourse: addBaseURL('createSafetyCourse'),
	UpdateSafetyCourse: addBaseURL('updateSafetyCourse'),
	CreateInduction: addBaseURL('createInduction'),
	UpdateInduction: addBaseURL('updateInduction'),
	CreateSite: addBaseURL('createSite'),
	UpdateSite: addBaseURL('updateSite'),
	ArchiveSite: addBaseURL('archiveSite'),
	CreateInductionStep: addBaseURL('createInductionStep'),
	UpdateInductionStep: addBaseURL('updateInductionStep'),
	DeleteInductionStep: addBaseURL('deleteInductionStep'),
} as const;
