import AddIcon from '@mui/icons-material/Add';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Site, UserDetails, UserProps } from '../../../../constants/Common';
import firebaseApi from '../../../../firebase/firebaseApi';
import { DataTable } from '../../../DataTable/DataTable';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { LoadingDots } from '../../subcomponents/LoadingDots';
import { sendKioskEmail, sendUnassignEmail } from './KioskEmails';
import { UpdateKioskDialog } from './UpdateKioskDialog';

enum DialogOptions {
	None = 'none',
	Edit = 'edit',
	Request = 'request',
	Snackbar = 'snackbar',
	Unassign = 'unassign',
}

export const Kiosks = ({ userDetails }: UserProps): JSX.Element => {
	const canManageKioskRequests =
		userDetails.accountType === 'seniorManagement';

	const [loading, setLoading] = useState(true);
	const [dialogOpen, setDialogOpen] = useState<DialogOptions>(
		DialogOptions.None,
	);
	const [selected, setSelected] = useState(0);
	const [kioskList, setKioskList] = useState<
		Pick<
			UserDetails,
			| 'userID'
			| 'batteryLevel'
			| 'displayName'
			| 'site'
			| 'siteID'
			| 'company'
			| 'pin'
		>[]
	>([]);
	const [requestNotes, setRequestNotes] = useState('');
	const [sites, setSites] = useState<Record<string, Site>>({});

	useEffect(() => {
		if (canManageKioskRequests) {
			return firebaseApi.subscribeKiosksByCompany(
				userDetails.companyID,
				(kiosks) => {
					setKioskList(kiosks);
					setLoading(false);
				},
			);
		} else {
			return firebaseApi.subscribeKiosksBySite(
				userDetails.siteID,
				(kiosks) => {
					setKioskList(kiosks);
					setLoading(false);
				},
			);
		}
	}, [canManageKioskRequests, userDetails.companyID, userDetails.siteID]);

	useEffect(() => {
		return firebaseApi.activeSitesByCompanySubscription(
			userDetails.companyID,
			(newSites) => {
				setSites(newSites);
			},
		);
	}, [userDetails.companyID]);

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '50%',
					},
				}),
			},
		},
		{
			name: 'batteryLevel',
			label: 'Battery Level',
			options: {
				filter: false,
				customBodyRender: (batteryLevel: number) =>
					formatBattery(batteryLevel),
				setCellHeaderProps: () => ({
					style: {
						width: '50%',
					},
				}),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'single',
		tableBodyHeight: 'calc(100vh - 320px)',
		elevation: 2,
		viewColumns: false,
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		download: false,
		print: false,
		textLabels: {
			body: {
				noMatch: loading ? <LoadingDots /> : 'No Kiosks Found.',
			},
		},
	};

	const handleRequest = (): void =>
		sendKioskEmail({ userDetails, requestNotes }, () => {
			setDialogOpen(DialogOptions.Snackbar);
			setRequestNotes('');
		});

	const handleUnassign = (): void =>
		sendUnassignEmail(
			{ userDetails, requestNotes, kiosk: kioskList[selected] },
			() => {
				setDialogOpen(DialogOptions.Snackbar);
				setRequestNotes('');
			},
		);

	const formatBattery = (batteryLevel: number | undefined): string =>
		batteryLevel ? `${Math.floor(batteryLevel * 100)}%` : '';

	const requestDialog = (): JSX.Element => (
		<Dialog
			open={dialogOpen === DialogOptions.Request}
			fullWidth
			onClose={(): void => setDialogOpen(DialogOptions.None)}
			maxWidth="xs">
			<DialogTitle>Request a new Kiosk</DialogTitle>
			<DialogContent
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				}}>
				<Typography>
					This will send a request to Trade Legion to install a new
					Kiosk at your current site ({userDetails.site}.)
				</Typography>
				<TextField
					placeholder="Optional: Details of your request"
					value={requestNotes}
					onChange={(event): void =>
						setRequestNotes(event.target.value)
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void => setDialogOpen(DialogOptions.None)}>
					Close
				</Button>
				<Button variant="contained" onClick={handleRequest}>
					Make Request
				</Button>
			</DialogActions>
		</Dialog>
	);

	const unassignDialog = (): JSX.Element => (
		<Dialog
			open={dialogOpen === DialogOptions.Unassign}
			fullWidth
			onClose={(): void => setDialogOpen(DialogOptions.None)}
			maxWidth="xs">
			<DialogTitle>Request Removal</DialogTitle>
			<DialogContent
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				}}>
				<Typography>
					This will send a request to Trade Legion to remove this
					kiosk ({kioskList[selected]?.displayName}) from your site (
					{userDetails.site})
				</Typography>
				<TextField
					placeholder="Optional: Details of your request"
					value={requestNotes}
					onChange={(event): void =>
						setRequestNotes(event.target.value)
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void => setDialogOpen(DialogOptions.None)}>
					Close
				</Button>
				<Button variant="contained" onClick={handleUnassign}>
					Unassign Kiosk
				</Button>
			</DialogActions>
		</Dialog>
	);

	const requestSnackBar = (): JSX.Element => (
		<CustomSnackBar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			open={dialogOpen === DialogOptions.Snackbar}
			onClose={(): void =>
				setDialogOpen(
					dialogOpen === DialogOptions.Snackbar
						? DialogOptions.None
						: dialogOpen,
				)
			}
			snackBarText="Request sent!"
		/>
	);

	const updateKioskDetails = async (
		site: Pick<Site, 'id' | 'name'>,
		pin: [number, number, number, number],
	): Promise<void> => {
		const update = {
			pin: pin,
			site: site.name,
			siteID: site.id,
		};
		await firebaseApi.updateKioskDetails(
			kioskList[selected].userID,
			update,
		);
	};

	const updateKioskDialog = (): JSX.Element => (
		<UpdateKioskDialog
			open={dialogOpen === DialogOptions.Edit}
			currentPin={kioskList[selected].pin}
			currentSite={{
				id: kioskList[selected].siteID,
				name: kioskList[selected].site,
			}}
			sites={sites}
			onClose={(): void => {
				setDialogOpen(DialogOptions.None);
			}}
			updateKiosk={updateKioskDetails}
		/>
	);

	return (
		<>
			<Stack
				spacing={2}
				direction={{ xs: 'column', sm: 'column', md: 'row' }}
				divider={<Divider orientation="vertical" flexItem />}>
				<Box flex={1}>
					<DataTable
						title={<Typography variant="h5">Kiosks</Typography>}
						tableData={kioskList}
						selection={[selected, setSelected]}
						columns={columns}
						customTableOptions={tableOptions}
						customToolbar={(): JSX.Element =>
							canManageKioskRequests ? (
								<Tooltip title="Request New Kiosk">
									<IconButton
										onClick={(): void =>
											setDialogOpen(DialogOptions.Request)
										}>
										<AddIcon color="primary" />
									</IconButton>
								</Tooltip>
							) : (
								<></>
							)
						}
					/>
				</Box>
				<Paper sx={{ flex: 1 }}>
					<Stack
						height="100%"
						justifyContent="space-between"
						padding={2}>
						{loading && <LoadingDots />}
						{kioskList[selected] && (
							<>
								<Box>
									<Typography variant="h5" pl={1}>
										{kioskList[selected].displayName}
									</Typography>
									<Stack
										paddingTop={5}
										gap={1}
										divider={
											<Divider
												orientation="horizontal"
												flexItem
											/>
										}>
										<Box
											display="flex"
											justifyContent="space-between">
											<Typography>
												Current Site
											</Typography>
											<Typography>
												{kioskList[selected].site}
											</Typography>
										</Box>
										<Box
											display="flex"
											justifyContent="space-between">
											<Typography>Company</Typography>
											<Typography>
												{kioskList[selected].company}
											</Typography>
										</Box>
										<Box
											display="flex"
											justifyContent="space-between">
											<Typography>
												Battery Level
											</Typography>
											<Box sx={{ width: '50%' }}>
												<Typography align="center">
													{formatBattery(
														kioskList[selected]
															?.batteryLevel,
													)}
												</Typography>

												<LinearProgress
													variant="determinate"
													value={
														(kioskList[selected]
															?.batteryLevel ||
															0) * 100
													}
												/>
											</Box>
										</Box>
										<Box
											display="flex"
											justifyContent="space-between">
											<Typography flex={1}>
												Pin
											</Typography>
											<Box
												display="flex"
												justifyContent="flex-end"
												alignItems="center"
												flexDirection="column">
												<Typography flex={1}>
													{kioskList[selected].pin
														? kioskList[selected]
																.pin
														: 'No Pin'}
												</Typography>
											</Box>
										</Box>
										<Box />
									</Stack>
								</Box>
								<Grid container spacing={1} marginTop={1}>
									{canManageKioskRequests && (
										<Grid item sm={12} lg={9} xl={8}>
											<Button
												variant="outlined"
												fullWidth
												onClick={(): void =>
													setDialogOpen(
														DialogOptions.Unassign,
													)
												}>
												Request removal of this Kiosk
											</Button>
										</Grid>
									)}
									<Grid item sm={12} lg={3} xl={4}>
										<Button
											variant="contained"
											fullWidth
											onClick={(): void =>
												setDialogOpen(
													DialogOptions.Edit,
												)
											}
											sx={{ height: '100%' }}>
											Edit
										</Button>
									</Grid>
								</Grid>
								{dialogOpen === DialogOptions.Edit &&
									updateKioskDialog()}
							</>
						)}
					</Stack>
				</Paper>
			</Stack>
			{requestDialog()}
			{unassignDialog()}
			{requestSnackBar()}
		</>
	);
};
