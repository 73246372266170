import { differenceInDays, parse } from 'date-fns';
import { MUISortOptions } from 'mui-datatables';
import { Leave } from '../../constants/Leave';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import {
	Link,
	SiteActivityLink,
} from '../../models/Integrations/ProjectTrackingIntegration';
import { SLASH_DATE_FORMAT } from './dateFormatters';

type CustomTableSortCallback<T> = (
	order: MUISortOptions['direction'],
) => (obj1: { data: T }, obj2: { data: T }) => number;

export const weekCustomTableSort: CustomTableSortCallback<string> = (order) => {
	return (entry1, entry2) => {
		const date1 = parse(entry1.data, SLASH_DATE_FORMAT, new Date());
		const date2 = parse(entry2.data, SLASH_DATE_FORMAT, new Date());
		return (date2.getTime() - date1.getTime()) * (order === 'asc' ? 1 : -1);
	};
};

export const nameCustomTableSort: CustomTableSortCallback<{ name: string }> =
	(order) => (obj1, obj2) =>
		obj1.data.name.localeCompare(obj2.data.name) *
		(order === 'asc' ? 1 : -1);

export const contractedToCustomTableSort: CustomTableSortCallback<{
	name: string;
}> = (order) => (obj1, obj2) => {
	const name1 = obj1.data?.name || 'Not Contracted';
	const name2 = obj2.data?.name || 'Not Contracted';
	return name1.localeCompare(name2) * (order === 'asc' ? 1 : -1);
};

export const timesheetHoursCustomTableSort: CustomTableSortCallback<
	Timesheet['hours']
> = (order) => (obj1, obj2) =>
	(obj1.data.total.billable - obj2.data.total.billable) *
	(order === 'asc' ? 1 : -1);

export const stringHoursCustomTableSort: CustomTableSortCallback<string> =
	(order) => (obj1, obj2) =>
		(parseFloat(obj1.data) - parseFloat(obj2.data)) *
		(order === 'asc' ? 1 : -1);

// Position is not in the types published by MUI, but is in the object we get, and necessary to correctly sort this column
type NumberWithPosition = { data: number | null; position: number };
const getHours = (
	numWithPosition: NumberWithPosition,
	leaveEntries: Leave[],
): number => {
	const leave = leaveEntries[numWithPosition.position];
	if (leave === undefined) {
		return 0;
	} else if (leave.endDate) {
		const days =
			differenceInDays(leave.endDate.toDate(), leave.startDate.toDate()) +
			1;
		return days * 8; // working hours
	} else if (leave.hours) {
		return leave.hours;
	} else {
		throw new Error("Can't have leave without either date and hours");
	}
};
export const leaveDurationCustomTableSort: (
	leaveEntries: Leave[],
) => CustomTableSortCallback<Leave['hours']> =
	(leaveEntries) => (order) => (aLeave, bLeave) => {
		const aDuration = getHours(aLeave as NumberWithPosition, leaveEntries);
		const bDuration = getHours(bLeave as NumberWithPosition, leaveEntries);
		const result = (aDuration - bDuration) * (order === 'asc' ? 1 : -1);
		return result;
	};

export const linkTableSort: CustomTableSortCallback<Link> =
	(order) => (obj1, obj2) => {
		const name1 = obj1.data?.integrationName || '';
		const name2 = obj2.data?.integrationName || '';
		return name1.localeCompare(name2) * (order === 'asc' ? 1 : -1);
	};

export const activityLinkTableSort: (
	key: keyof Pick<
		SiteActivityLink['activityLink'],
		'timeCode' | 'taskCode' | 'costCode'
	>,
) => CustomTableSortCallback<SiteActivityLink> =
	(key) => (order) => (obj1, obj2) => {
		const name1 = obj1.data?.activityLink?.[key]?.integrationName || '';
		const name2 = obj2.data?.activityLink?.[key]?.integrationName || '';

		if (name1 === '') {
			return 1;
		} else if (name2 === '') {
			return -1;
		} else {
			return name1.localeCompare(name2) * (order === 'asc' ? 1 : -1);
		}
	};

export const alphabeticalCustomTableSort: CustomTableSortCallback<string> = (
	order,
) => {
	return (obj1, obj2) => {
		const value1 = obj1.data;
		const value2 = obj2.data;

		// Both values are '-'
		if (value1 === '-' && value2 === '-') return 0;

		// One of the values is '-'
		if (value1 === '-') return 1; // Push value1 to the bottom
		if (value2 === '-') return -1; // Push value2 to the bottom

		// Alphabetical sorting
		if (order === 'asc') {
			return value1.localeCompare(value2);
		} else {
			return value2.localeCompare(value1);
		}
	};
};

export const numericalCustomTableSort: CustomTableSortCallback<number | '-'> = (
	order,
) => {
	return (obj1, obj2) => {
		const value1 = obj1.data;
		const value2 = obj2.data;

		// Both values are '-'
		if (value1 === '-' && value2 === '-') return 0;

		// One of the values is '-'
		if (value1 === '-') return 1; // Push value1 to the bottom
		if (value2 === '-') return -1; // Push value2 to the bottom

		// Alphabetical sorting
		if (order === 'asc') {
			return value1 - value2;
		} else {
			return value2 - value1;
		}
	};
};
