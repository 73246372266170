import ClearIcon from '@mui/icons-material/Clear';
import {
	IconButton,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { isRequiredLeave } from '../../../../constants/Leave';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';

type IntegrationSelectDropdownProps = {
	id: string;
	integrationID: string;
	integrationPayElementTypes: IntegrationElement[];
	dropdownLabel: string;
	dropdownHelperText: string;
	onChange: (id: string, integrationID: string) => void;
	onClear: (id: string, integrationID: string) => void;
	selected: string[];
	disableSelected?: boolean;
};

export const IntegrationSelectDropdown = ({
	id,
	integrationID,
	integrationPayElementTypes,
	dropdownLabel,
	dropdownHelperText,
	onChange,
	onClear,
	selected,
	disableSelected = true,
}: IntegrationSelectDropdownProps): JSX.Element => {
	const isRequired =
		isRequiredLeave(id) || id === 'Default' || id === 'Overtime';
	const missingMapping =
		(isRequired && integrationID === '') ||
		(integrationID !== '' &&
			!integrationPayElementTypes.some(
				(payElement) => payElement.id === integrationID,
			));

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			textAlign="left">
			<TextField
				id={id}
				data-testid={`integration-dropdown-${id}`}
				value={integrationID}
				label={`${dropdownLabel}${isRequired ? ' (required)' : ''}`}
				onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
					onChange(id, event.target.value)
				}
				error={missingMapping}
				required={isRequired}
				helperText={missingMapping ? dropdownHelperText : ''}
				fullWidth
				select>
				{integrationPayElementTypes.map((payElement) => (
					<MenuItem
						disabled={
							disableSelected && selected.includes(payElement.id)
						}
						key={`${id}-${payElement.id}`}
						id={payElement.id}
						value={payElement.id}>
						<Typography noWrap>
							{payElement.name} - {payElement.description}
						</Typography>
					</MenuItem>
				))}
			</TextField>
			<IconButton
				data-testid={`clear-button-${id}`}
				size="small"
				color="primary"
				disabled={integrationID === ''}
				onClick={(): void => onClear(id, integrationID)}>
				<Tooltip title="Remove">
					<ClearIcon fontSize="medium" />
				</Tooltip>
			</IconButton>
		</Stack>
	);
};
