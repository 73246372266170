import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Stack,
	Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Company } from '../../../constants/Common';
import firebaseApi from '../../../firebase/firebaseApi';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../providers/UserProvider';
import { configureManageSubscriptionButton } from '../../SubscriptionControl/ManageSubscriptionButton';

export const CompanyInfo = (): JSX.Element => {
	const user = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const [logoUrl, setLogoUrl] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [updatePhoto, setUpdatePhoto] = useState(true);
	const [company, setCompany] = useState<Company>();

	const mountedRef = useRef(true);

	useEffect(
		// ensures the async storage calls don't update components when unmounted
		() => {
			mountedRef.current = true;
			return () => {
				mountedRef.current = false;
			};
		},
		[],
	);

	useEffect(() => {
		if (!userDetails?.companyID) return;
		const companySub = firebaseApi.companySubscription(
			userDetails.companyID,
			(userCompany) => {
				setCompany(userCompany);
				if (userCompany.logoLocation === undefined) {
					setLogoUrl(null);
					setLoading(false);
				}
			},
		);

		return companySub;
	}, [userDetails?.companyID]);

	useEffect(() => {
		if (company?.logoLocation && updatePhoto) {
			setLogoUrl(null); // ensure old photo isn't cached

			const fetchCompanyLogo = async (
				logoLocation: string,
			): Promise<void> => {
				const logoUrl = await firebaseApi.getDownloadUrl(logoLocation);

				if (mountedRef.current) {
					setLogoUrl(logoUrl);
					setLoading(false);
					setUpdatePhoto(false);
				}
			};
			fetchCompanyLogo(company.logoLocation);
		}
	}, [company?.logoLocation, updatePhoto]);

	const handleUpload = async (
		event: ChangeEvent<HTMLInputElement>,
	): Promise<void> => {
		// If there are no files or no company, then what are we even doing here?
		if (
			!event.target.files ||
			event.target.files.length === 0 ||
			!company ||
			!userDetails
		) {
			return;
		}

		setLoading(true);
		const file = event.target.files[0];
		await firebaseApi.updateCompanyLogo(company, file);
		setUpdatePhoto(true);
	};

	return (
		<Card variant="outlined">
			<Box
				minHeight="500px"
				display="flex"
				flexDirection="column"
				justifyContent="space-between">
				<CardHeader
					title={userDetails?.company}
					subheader="Current Logo"
					action={
						user &&
						company &&
						configureManageSubscriptionButton(company, user)
					}
				/>
				<CardContent>
					<Box
						justifyContent="center"
						alignItems="center"
						display="flex">
						{loading ? (
							<CircularProgress />
						) : logoUrl ? (
							<Box
								component="img"
								alt="Company Logo"
								src={logoUrl}
								maxWidth="310px"
								maxHeight="310px"
							/>
						) : (
							<Typography variant="subtitle1">
								No logo found
							</Typography>
						)}
					</Box>
				</CardContent>
				<CardActions sx={{ padding: 2 }}>
					<Box flexGrow={1}>
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center">
							<input
								accept="image/png, image/jpg, image/jpeg"
								id="button-file"
								type="file"
								hidden
								disabled={loading}
								onChange={handleUpload}
							/>
							<label htmlFor="button-file">
								<Button
									component="span"
									variant="contained"
									disabled={loading}>
									Upload New Logo
								</Button>
							</label>
						</Stack>
					</Box>
				</CardActions>
			</Box>
		</Card>
	);
};
