import { Box, Tabs, Tab } from '@mui/material';
import { Link, useMatch, Outlet } from 'react-router-dom';
import { UserDetails, assertAccountType } from '../../constants/Common';
import {
	ComplianceAccountType,
	complianceAccountType,
	complianceConfig,
} from '../../constants/Compliance';

type ComplianceProps = {
	userDetails: UserDetails;
};

export const Compliance = ({ userDetails }: ComplianceProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<ComplianceAccountType>(
		accountType,
		complianceAccountType,
	);
	const titles = complianceConfig[accountType].titles;
	const pathMatch = useMatch('/compliance/:tab');

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}`}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
