import { MUIDataTableColumn } from 'mui-datatables';
import { FixMeLater } from './AnyTypes';
import { Company, Minimal, MinimalUserDetails, Site } from './Common';
import { ContractStatus } from './Contract';

/** Simple escaping of quotes in strings and join it into a single column */
const escapeAndJoinColumn = (data: (string | number)[]): string =>
	data
		.map((i) => (typeof i === 'string' ? `"${i.replace('"', '""')}"` : i))
		.join(',') + '\n';

const buildHead = (head: MUIDataTableColumn[]): string =>
	escapeAndJoinColumn(head.map((i) => i.label ?? i.name));
// must be in the same order as in the column defintions
type ColumnDefs = [
	MinimalUserDetails,
	Minimal<Company>,
	Minimal<Site> | null,
	number,
	number,
	number,
	ContractStatus,
];

type Body = {
	index: number;
	data: ColumnDefs;
}[];

const buildBody = (body: Body): string =>
	body
		.map(
			({
				data: [
					employee,
					accepterCompany,
					site,
					chargeOutRate,
					payRate,
					margin,
					status,
				],
			}) =>
				escapeAndJoinColumn([
					employee.name,
					accepterCompany.name,
					site?.name ?? '',
					chargeOutRate,
					payRate,
					margin,
					ContractStatus[status],
				]),
		)
		.join('');

export const onDownload: (
	buildHead: (head: FixMeLater) => string,
	buildBody: (data: FixMeLater) => string,
	columns: FixMeLater,
	data: FixMeLater,
) => string | boolean = (_1, _2, columns, data) => {
	return '\uFEFF' + buildHead(columns) + buildBody(data);
};
