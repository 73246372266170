import {
	Alert,
	AlertColor,
	AlertTitle,
	Button,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';

type CustomAlertProps = {
	title?: string;
	alertText: string;
	actionText?: string;
	actionOnClick?: () => void;
	severity?: AlertColor;
	variant?: 'filled' | 'standard' | 'outlined';
	sx?: SxProps<Theme>;
};

const CustomAlert = ({
	title,
	actionOnClick,
	alertText,
	actionText,
	variant,
	severity,
	sx,
}: CustomAlertProps): JSX.Element => (
	<Alert
		severity={severity}
		variant={variant}
		// align items fix, else multiline/small screen layout breaks
		sx={{ alignItems: 'center', ...sx }}
		action={
			actionText && (
				<Button
					color="inherit"
					variant="outlined"
					size="small"
					onClick={actionOnClick}>
					{actionText}
				</Button>
			)
		}>
		{title && (
			<AlertTitle style={{ fontWeight: 'bold' }}>{title}</AlertTitle>
		)}
		<Typography>{alertText}</Typography>
	</Alert>
);

export default CustomAlert;
