import {
	ActivityTypesMapping,
	BaseIntegrationLink,
} from './IntegrationElements';
import { IntegrationStatus } from './IntegrationStatus';

// Shape in DB
export type InvoicingIntegrationAtRest = {
	type: InvoicingType;
	status: IntegrationStatus;
	companyID: string;
	activityTypes: ActivityTypesMapping;
};

export const InvoicingTypes = ['Invoxy', 'Xero'] as const;
export type InvoicingType = (typeof InvoicingTypes)[number];

export const DEFAULT_ACTIVITY_KEY: keyof InvoicingIntegrationAtRest['activityTypes'] =
	'Default';

export enum GroupBy {
	Site = 'Site',
	All = 'All',
}
export const groupByLabels: Record<GroupBy, string> = {
	[GroupBy.Site]: 'One Invoice Per Site',
	[GroupBy.All]: 'One Invoice For Everyone',
};
export enum SendInvoice {
	Thursday = 'Thursday',
	WriteDontSent = 'WriteDontSend',
}
export const sendInvoiceLabels: Record<SendInvoice, string> = {
	[SendInvoice.Thursday]: 'Every Thursday 5pm',
	[SendInvoice.WriteDontSent]: "Write But Don't Send",
};
export enum DueDate {
	SevenDays = 'SevenDays',
	FourteenDays = 'FourteenDays',
	TwentiethNextMonth = 'TwentiethNextMonth',
}
export const dueDateLabels: Record<DueDate, string> = {
	[DueDate.SevenDays]: '7 days after sending',
	[DueDate.FourteenDays]: '14 days after sending',
	[DueDate.TwentiethNextMonth]: '20th of the following month',
};
export type InvoicingLink = BaseIntegrationLink & {
	groupBy: GroupBy;
	sendInvoice: SendInvoice;
	dueDate: DueDate;
};
