import { Box, Typography, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { NewInductionValidationErrors } from '../../../../../constants/InductionEntry';
import {
	PhoneNumberInput,
	validatePhoneNumber,
} from '../../../../PhoneNumberInput/PhoneNumberInput';

export type NewGuestInductionProps = {
	inducteeName: string;
	setInducteeName: Dispatch<SetStateAction<string>>;
	inducteeNameError: boolean;
	guestMobileNumber: string;
	setGuestMobileNumber: Dispatch<SetStateAction<string>>;
	guestMobileNumberError: boolean;
	guestCompany: string;
	setGuestCompany: Dispatch<SetStateAction<string>>;
	guestCompanyError: boolean;
	setValidationErrors: Dispatch<SetStateAction<NewInductionValidationErrors>>;
};

export const NewGuestInduction = ({
	inducteeName,
	setInducteeName,
	inducteeNameError,
	guestMobileNumber,
	setGuestMobileNumber,
	guestMobileNumberError,
	guestCompany,
	setGuestCompany,
	guestCompanyError,
	setValidationErrors,
}: NewGuestInductionProps): JSX.Element => (
	<>
		<Box>
			<Typography variant="subtitle1" pb={0.5}>
				{`Guest's Full Name`}
			</Typography>
			<TextField
				fullWidth
				placeholder="Enter first and last name"
				variant="outlined"
				value={inducteeName ?? null}
				onChange={(event): void => {
					setInducteeName(event.target.value);
					setValidationErrors((prevState) => ({
						...prevState,
						inducteeName: false,
					}));
				}}
				size="small"
				error={inducteeNameError}
				helperText={inducteeNameError ? 'Name is required' : ''}
			/>
		</Box>
		<Box>
			<Typography variant="subtitle1" pb={0.5}>
				{`Guest's Mobile Number`}
			</Typography>
			<PhoneNumberInput
				value={guestMobileNumber}
				onChange={(event): void => {
					setGuestMobileNumber(event.toString());
					setValidationErrors((prevState) => ({
						...prevState,
						guestMobileNumber: !validatePhoneNumber(
							event.toString(),
						),
					}));
				}}
				label=""
				helperText={
					!guestMobileNumberError ? '' : 'Invalid mobile number'
				}
				placeHolder="Enter mobile number"
				size="small"
			/>
		</Box>
		<Box>
			<Typography variant="subtitle1" pb={0.5}>
				Company
			</Typography>
			<TextField
				fullWidth
				placeholder="Select Company"
				variant="outlined"
				value={guestCompany}
				onChange={(event): void => {
					setGuestCompany(event.target.value);
					setValidationErrors((prevState) => ({
						...prevState,
						guestCompany: false,
					}));
				}}
				size="small"
				error={guestCompanyError}
				helperText={guestCompanyError ? 'Company is required' : ''}
			/>
		</Box>
	</>
);
