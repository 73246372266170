import firebase from 'firebase';
import { NoteDay } from '../constants/Note';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type CreateNoteBody = {
	timesheetID: string;
	note: string;
	day: NoteDay;
};

export const createTimesheetNote = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	timesheetID: string,
	day: NoteDay,
	note: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<CreateNoteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateTimesheetNote,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			timesheetID,
			day,
			note,
		},
	});
};
