import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import firebase from 'firebase';
import { useState } from 'react';
import { UserDetails } from '../../../constants/Common';
import {
	QualificationTemplate,
	QualificationWithEvidence,
} from '../../../constants/Qualifications';
import firebaseApi from '../../../firebase/firebaseApi';
import { DatePicker } from '../../../providers/LocalizationProvider';
import { UploadFile } from '../../UploadFile/UploadFile';

type QualificationDialogProps = {
	open: boolean;
	close: () => void;
	userDetails: UserDetails;
	template: QualificationTemplate;
	afterSave: (qualification: QualificationWithEvidence) => void;
};

export const QualificationDialog = ({
	open,
	close,
	template,
	userDetails,
	afterSave,
}: QualificationDialogProps): JSX.Element => {
	// qualification.id === "" means that this will be a new template, not currently present in qualificationsTemplate
	const [qualification, setQualification] =
		useState<QualificationWithEvidence>({
			id: template.id,
			title: template.title,
			organisation: template.organisation ?? null,
			evidence: null,
			addedBy: {
				userID: userDetails.userID,
				companyID: userDetails.companyID,
				date: firebase.firestore.Timestamp.now(),
			},
		});

	const [qualificationError, setQualificationError] = useState('');

	const addQualification = async (): Promise<void> => {
		if (!qualification.title) {
			setQualificationError('Title cannot be empty');
			return;
		} else {
			setQualificationError('');
		}
		if (qualification.id === '') {
			const newQualification: Omit<QualificationTemplate, 'id'> = {
				title: qualification.title,
				organisation: qualification.organisation,
				addedBy: qualification.addedBy,
			};
			const createdQualificationTemplateID =
				await firebaseApi.createQualificationTemplate(newQualification);

			afterSave({
				...qualification,
				id: createdQualificationTemplateID,
			});
		} else {
			afterSave(qualification);
		}

		close();
	};

	const isExistingTemplate = qualification.id !== '';

	return (
		<Dialog fullWidth open={open} onClose={close}>
			<DialogTitle>Create New Qualification</DialogTitle>
			<DialogContent>
				<Grid container columns={8} spacing={2} alignItems="center">
					<Grid item xs={4}>
						<Typography>Title</Typography>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							required
							error={!!qualificationError}
							helperText={qualificationError}
							value={qualification.title}
							disabled={isExistingTemplate}
							onChange={(event): void =>
								setQualification((prev) => ({
									...prev,
									title: event.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography>Organisation</Typography>
					</Grid>
					<Grid item xs={4}>
						<TextField
							fullWidth
							value={qualification.organisation ?? ''}
							disabled={isExistingTemplate}
							onChange={(event): void =>
								setQualification((prev) => ({
									...prev,
									organisation: event.target.value,
								}))
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography>Date Obtained</Typography>
					</Grid>
					<Grid item xs={4}>
						<DatePicker
							inputFormat="dd/MM/yyyy"
							maxDate={new Date()}
							value={qualification.obtainedDate?.toDate() ?? null}
							onChange={(date): void => {
								if (!date) return;
								setQualification({
									...qualification,
									obtainedDate:
										firebase.firestore.Timestamp.fromDate(
											date,
										),
								});
							}}
							views={['year', 'month', 'day']}
							renderInput={(
								params: TextFieldProps,
							): JSX.Element => (
								<TextField {...params} fullWidth />
							)}
						/>
					</Grid>

					<Grid item xs={4}>
						<Typography>Expiry Date</Typography>
					</Grid>
					<Grid item xs={4}>
						<DatePicker
							inputFormat="dd/MM/yyyy"
							minDate={new Date()}
							value={qualification.expiryDate?.toDate() ?? null}
							onChange={(date): void => {
								if (!date) return;
								setQualification({
									...qualification,
									expiryDate:
										firebase.firestore.Timestamp.fromDate(
											date,
										),
								});
							}}
							views={['year', 'month', 'day']}
							renderInput={(
								params: TextFieldProps,
							): JSX.Element => (
								<TextField {...params} fullWidth />
							)}
						/>
					</Grid>

					<Grid item xs={4}>
						<Typography>Evidence</Typography>
					</Grid>
					<Grid item xs={4} textAlign="center">
						<UploadFile
							label="Evidence"
							onUpload={(evidence): void =>
								setQualification((prev) => ({
									...prev,
									evidence,
								}))
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={close}>
					cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={addQualification}>
					Create Qualification
				</Button>
			</DialogActions>
		</Dialog>
	);
};
