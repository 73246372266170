import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	Company,
	CompanyType,
	CompanyTypeName,
	companyTypes,
} from '../../constants/Common';
import { FieldValue } from '../../firebase/firebase';
import firebaseApi from '../../firebase/firebaseApi';

type EditableCompanyFields = {
	name: string;
	companyType: CompanyType;
	canCreateSites?: boolean;
};

type ErrorMapType = Record<
	keyof EditableCompanyFields,
	{ message: string; error: boolean }
>;

type EditCompanyDetailsProps = {
	company: Company;

	saveCompanyCallback?: (name: string) => void;
};

const companyEditableFields: Record<keyof EditableCompanyFields, string> = {
	name: 'Company Name',
	companyType: 'Company Type',
	canCreateSites: 'Can Create Sites?',
};

const createError = (companyDetails: EditableCompanyFields): ErrorMapType => {
	return Object.fromEntries(
		Object.entries(companyDetails).map(([key, value]) => {
			const option = key as keyof EditableCompanyFields;
			return [
				option,
				{
					message: `Invalid ${companyEditableFields[option]}`,
					error: value === '',
				},
			];
		}),
	) as ErrorMapType;
};

const toEditableCompany = (company: Company): EditableCompanyFields => ({
	name: company.name,
	companyType: company.companyType,
	canCreateSites: company.canCreateSites,
});

export const EditCompanyDetails = ({
	company,
	saveCompanyCallback,
}: EditCompanyDetailsProps): JSX.Element => {
	const [companyDetails, setCompanyDetails] = useState<EditableCompanyFields>(
		toEditableCompany(company),
	);
	const [errorMap, setErrorMap] = useState<ErrorMapType>(
		createError(companyDetails),
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [logoURL, setLogoURL] = useState('');

	useEffect(() => {
		setCompanyDetails(toEditableCompany(company));
	}, [company]);

	useEffect(() => {
		if (company.logoLocation !== undefined && company.logoLocation !== '') {
			const fetchCompanyLogo = async (
				logoLocation: string,
			): Promise<void> => {
				const url = await firebaseApi.getDownloadUrl(logoLocation);
				setLogoURL(url);
			};
			fetchCompanyLogo(company.logoLocation);
		} else {
			setLogoURL('');
		}
	}, [company.logoLocation]);

	const saveCompanyDetails = async (): Promise<void> => {
		const isValid = Object.values(errorMap).every((value) => !value.error);
		if (isValid) {
			const editCompany: Omit<Company, 'id'> = {
				name: companyDetails.name,
				companyType: companyDetails.companyType,
				canCreateSites: companyDetails.canCreateSites
					? true
					: (FieldValue.delete() as unknown as undefined),
			};
			await firebaseApi.updateCompany(company.id, editCompany);

			setIsEditing(false);
			saveCompanyCallback?.(companyDetails.name);
		} else {
			console.error('error', errorMap);
		}
	};

	const handleDetailChange = (
		value: string,
		key: keyof EditableCompanyFields,
	): void => {
		setCompanyDetails({
			...companyDetails,
			[key]: value,
		});
		setErrorMap((prev) => ({
			...prev,
			[key]: {
				message: prev[key].message,
				error: value === '',
			},
		}));
	};

	const handleCanCreateSiteChange = (value: boolean): void => {
		setCompanyDetails({
			...companyDetails,
			canCreateSites: value,
		});
	};

	return (
		<Card variant="outlined">
			{company ? (
				<>
					<CardHeader
						disableTypography
						avatar={
							logoURL !== '' && (
								<Box
									component="img"
									src={logoURL}
									alt="Company Logo"
									sx={{
										height: 50,
									}}
								/>
							)
						}
						title={
							<Typography variant="h6">
								{company?.name} Details
							</Typography>
						}
						action={
							!isEditing ? (
								<IconButton
									color="primary"
									onClick={(): void => {
										setIsEditing(true);
									}}>
									<EditIcon />
								</IconButton>
							) : (
								<>
									<IconButton
										color="primary"
										onClick={async (): Promise<void> =>
											await saveCompanyDetails()
										}>
										<SaveIcon />
									</IconButton>
									<IconButton
										color="primary"
										onClick={(): void => {
											setIsEditing(false);
											setCompanyDetails(company);
											setErrorMap(createError(company));
										}}>
										<CancelIcon />
									</IconButton>
								</>
							)
						}
					/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									label="Company Name"
									fullWidth
									disabled={!isEditing}
									value={companyDetails.name}
									onChange={(event): void =>
										handleDetailChange(
											event.target.value,
											'name',
										)
									}
									error={errorMap.name.error}
									helperText={
										errorMap.name.error
											? errorMap.name.message
											: ''
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									value={companyDetails.companyType}
									fullWidth
									label="Company Type"
									onChange={(event): void => {
										handleDetailChange(
											event.target.value,
											'companyType',
										);
									}}
									select
									disabled={!isEditing}
									error={errorMap.companyType.error}
									helperText={
										errorMap.companyType.error
											? errorMap.companyType.message
											: ''
									}>
									{companyTypes.map((type) => {
										return (
											<MenuItem key={type} value={type}>
												{CompanyTypeName[type]}
											</MenuItem>
										);
									})}
								</TextField>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Payroll Integration"
									fullWidth
									value={
										company.payrollIntegrated
											? company.payrollIntegrated
											: ''
									}
									disabled
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Invoicing Integration"
									fullWidth
									value={
										company.invoicingIntegrated
											? company.invoicingIntegrated
											: ''
									}
									disabled
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Logo"
									fullWidth
									value={
										company.logoLocation
											? company.logoLocation
											: ''
									}
									disabled
								/>
							</Grid>
							<Grid
								sx={{
									'&:empty': {
										paddingTop: '0px !important',
									},
								}}
								item
								xs={12}
								container
								direction="row">
								{company.companyType === 'recruitment' && (
									<Grid item xs={6} container>
										<Grid
											display="flex"
											alignItems="center">
											<Typography variant="body1" m={1}>
												{
													companyEditableFields.canCreateSites
												}
											</Typography>
										</Grid>
										<Grid
											display="flex"
											alignItems="center">
											<Switch
												disabled={!isEditing}
												checked={
													companyDetails.canCreateSites
												}
												value={undefined}
												onChange={(event): void => {
													handleCanCreateSiteChange(
														event.target.checked,
													);
												}}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</>
			) : (
				<></>
			)}
		</Card>
	);
};
