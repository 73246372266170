import { CustomSnackBar } from '../../SnackBar/SnackBar';
import { TemplateStatuses } from './Templates';

export const TemplateSnackbar = ({
	event,
	open,
	onClose,
}: {
	event: TemplateStatuses;
	open: boolean;
	onClose: () => void;
}): JSX.Element => {
	const handleSnackBarClose = (_?: unknown, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		onClose();
	};

	switch (event) {
		case TemplateStatuses.None:
			return (
				<CustomSnackBar
					open={open}
					onClose={handleSnackBarClose}
					snackBarText=""
					severity="info"
				/>
			);
		case TemplateStatuses.Loading:
			return (
				<CustomSnackBar
					open={open}
					onClose={handleSnackBarClose}
					snackBarText="Deleting Template"
					severity="info"
					loading
				/>
			);
		case TemplateStatuses.Done:
			return (
				<CustomSnackBar
					open={open}
					onClose={handleSnackBarClose}
					snackBarText="Template Deleted"
					severity="success"
				/>
			);
		case TemplateStatuses.Error:
			return (
				<CustomSnackBar
					open={open}
					onClose={handleSnackBarClose}
					snackBarText="Could not delete requested template"
					severity="error"
				/>
			);
	}
};
