import { QualificationTemplate } from '../../constants/Qualifications';
import { Firestore, FirestoreDataConverter } from '../firebase';

const QUALIFICATION_TEMPLATES_COLLECTION = 'qualificationTemplates';
const qualificationTemplatesConverter: FirestoreDataConverter<QualificationTemplate> =
	{
		toFirestore: (model) => model,
		fromFirestore: (snapshot, _) =>
			({ ...snapshot.data() } as QualificationTemplate),
	};

const createQualificationTemplate = async (
	qualificationTemplate: Omit<QualificationTemplate, 'id'>,
): Promise<QualificationTemplate['id']> => {
	const doc = Firestore.collection(QUALIFICATION_TEMPLATES_COLLECTION).doc();
	const newQualificationTemplate = {
		...qualificationTemplate,
		id: doc.id,
	};
	await doc.set(newQualificationTemplate);
	return newQualificationTemplate.id;
};

const qualificationTemplatesSubscription = (
	callback: (qualificationTemplates: QualificationTemplate[]) => void,
): (() => void) =>
	Firestore.collection(QUALIFICATION_TEMPLATES_COLLECTION)
		.orderBy('title')
		.withConverter(qualificationTemplatesConverter)
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data())),
		);

const qualificationTemplatesFirebaseApi = {
	createQualificationTemplate,
	qualificationTemplatesSubscription,
};

export default qualificationTemplatesFirebaseApi;
