import { Analytics } from '../../firebase/firebase';
import { AnalyticsService } from './context';

let firebaseAnalytics: Analytics | null = null;

const setup: AnalyticsService['setup'] = () => {
	firebaseAnalytics = Analytics();
};

const logEvent: AnalyticsService['logEvent'] = (event, eventParams) => {
	firebaseAnalytics?.logEvent(event, eventParams);
};

const identify: AnalyticsService['identify'] = (userDetails) => {
	if (userDetails) {
		firebaseAnalytics?.setUserId(userDetails.userID);
	} else {
		firebaseAnalytics?.setUserId('');
	}
};

export const firebaseAnalyticsService: AnalyticsService = {
	logEvent,
	identify,
	setup,
};
