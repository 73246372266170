import { ActivitiesWrapper } from './Activities/ActivitiesWrapper';
import { EmployeesWrapper } from './Employees/EmployeesWrapper';
import { ExportWrapper } from './Export/ExportWrapper';
import { PageWrapper } from './PageWrapper';
import { SitesWrapper } from './Sites/SitesWrapper';

const catprojectPages = {
	ActivitiesWrapper,
	SitesWrapper,
	ExportWrapper,
	EmployeesWrapper,
	PageWrapper,
};
export type CATProjectsPages = typeof catprojectPages;
export default catprojectPages;
