import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	useTheme,
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router';
import { UserDetails, emptyFunction } from '../../constants/Common';
import type {
	Auth,
	FirebaseAuthError,
	RecaptchaVerifier,
	User,
} from '../../firebase/firebase';

export type MultiFactorState =
	| 'closed'
	| 'info'
	| 'enterCode'
	| 'signOut'
	| 'finish'
	| 'error';

export type MultiFactorDialogProps = {
	multiFactorState: MultiFactorState;
	enrolMultiFactor: (
		mobileNumber: string,
		recaptchaVerifier: RecaptchaVerifier,
		setVerificationId: (value: SetStateAction<string>) => void,
		user: User | null,
	) => Promise<void>;
	acceptVerificationCode: (
		verificationId: string,
		verificationCode: string,
		user: User | null,
	) => Promise<void>;
	setMultiFactorState: (state: MultiFactorState) => void;
	recaptchaVerifier: RecaptchaVerifier;
	accountDetails: UserDetails;
	user: User | null;
	auth: Auth;
};

export const MultiFactorDialog = ({
	multiFactorState,
	enrolMultiFactor,
	acceptVerificationCode,
	setMultiFactorState,
	recaptchaVerifier,
	accountDetails,
	user,
	auth,
}: MultiFactorDialogProps): JSX.Element => {
	const [verificationCode, setVerificationCode] = useState<string>('');
	const [verificationId, setVerificationId] = useState<string>('');
	const [multiFactorError, setMultiFactorError] = useState<string>();

	const navigate = useNavigate();
	const theme = useTheme();

	const multiFactorDialogContent: Record<
		MultiFactorState,
		{ content: JSX.Element; next: () => void; nextName: string }
	> = {
		closed: {
			content: <></>,
			next: emptyFunction,
			nextName: '',
		},
		info: {
			content: (
				<>
					<Typography gutterBottom>
						Multi-Factor Authentication provides increased security
						for your TradeLegion account by requiring you to better
						prove your identity when you sign in.
					</Typography>
					<Typography gutterBottom>
						We will do this by texting a verification code to your
						provided mobile phone number (
						{accountDetails.mobileNumber}
						).
					</Typography>
					<Typography gutterBottom>
						When you sign up, we will need to send an inital code to
						your phone and you may need to sign in again. Standard
						SMS rates may apply.
					</Typography>
				</>
			),
			next: async () => {
				try {
					await enrolMultiFactor(
						accountDetails.mobileNumber,
						recaptchaVerifier,
						setVerificationId,
						user,
					);
					setMultiFactorState('enterCode');
				} catch (err) {
					const error = err as FirebaseAuthError;
					if (error.code === 'auth/requires-recent-login') {
						setMultiFactorState('signOut');
					} else {
						setMultiFactorError(error.message as string);
					}
				}
			},
			nextName: 'Send Verification Code',
		},
		enterCode: {
			content: (
				<>
					<Typography gutterBottom>
						{`We've sent a verification code to the mobile phone number you've provided (${accountDetails.mobileNumber}). Please enter it below`}
					</Typography>
					<PinInput
						focus
						autoSelect
						length={6}
						onChange={(value): void => setVerificationCode(value)}
						type="numeric"
						inputMode="number"
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							paddingTop: '10px',
						}}
						inputStyle={{
							borderColor: theme.palette.neutral.main,
						}}
						inputFocusStyle={{
							borderColor: theme.palette.primary.main,
						}}
					/>
				</>
			),
			next: async () => {
				try {
					await acceptVerificationCode(
						verificationId,
						verificationCode,
						user,
					);
					setMultiFactorState('finish');
				} catch (err) {
					const error = err as FirebaseAuthError;
					setMultiFactorError(error.message as string);
					setMultiFactorState('error');
				}
			},
			nextName: 'Verify code',
		},
		signOut: {
			content: (
				<>
					<Typography gutterBottom>
						Looks like your last sign-in was some time ago. We will
						need to you to sign-in again to refresh your details.
					</Typography>
					<Typography gutterBottom>
						Once you&apos;ve signed in again, you will be redirected
						back here, so you can try create an MFA token again.
					</Typography>
				</>
			),
			next: async () => {
				await auth.signOut();
				navigate('/sign-in');
				const unsubscribe = auth.onAuthStateChanged((user) => {
					if (user) {
						// user is signed in again
						navigate('/account-settings');
						setMultiFactorState('info');
						unsubscribe();
					}
				});
			},
			nextName: 'Sign out',
		},
		finish: {
			content: <>You&apos;ve successfully added a multifactor token!</>,
			next: () => {
				setMultiFactorState('closed');
			},
			nextName: 'Finish',
		},
		error: {
			content: (
				<>
					<Typography gutterBottom>
						An error occured while trying to verify your token
					</Typography>
					<Typography gutterBottom>{multiFactorError}</Typography>
				</>
			),
			next: () => {
				setMultiFactorState('closed');
				navigate(0); // refresh
			},
			nextName: 'Finish',
		},
	};
	return (
		<Dialog
			open={multiFactorState !== 'closed'}
			onClose={(): void => setMultiFactorState('closed')}>
			<DialogTitle>Add Multi-Factor Authentication</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					component="div" // override as defaults to <p>, which has problems with nesting <PinInput>
				>
					{multiFactorDialogContent[multiFactorState].content}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{multiFactorState !== 'finish' && (
					<Button
						variant="outlined"
						onClick={(): void => setMultiFactorState('closed')}>
						Cancel
					</Button>
				)}
				<Button
					variant="contained"
					onClick={multiFactorDialogContent[multiFactorState].next}
					autoFocus>
					{multiFactorDialogContent[multiFactorState].nextName}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
