import { UserProps, assertAccountType } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { Attendance } from './Attendance';
import {
	SignInBookAccountType,
	signInBookAccountType,
	signInBookConfig,
} from './signInBookConfig';

export const AttendanceWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<SignInBookAccountType>(
		accountType,
		signInBookAccountType,
	);

	return (
		<Attendance
			{...signInBookConfig(accountType, userDetails)}
			userDetails={userDetails}
			firebaseApi={firebaseApi}
		/>
	);
};
