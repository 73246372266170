import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import { UserDetails } from '../../../../constants/Common';
import {
	BaseIntegrationLink,
	IntegrationEmployee,
} from '../../../../models/Integrations/IntegrationElements';

type ConfirmationDialogProps = {
	setShowConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
	showConfirmationDialog: boolean;
	connectionsToSubmit?: Record<string, BaseIntegrationLink>;
	usersList: Record<string, UserDetails>;
	integrationAccounts: IntegrationEmployee[];
	handleChanges: () => void;
};

export const ConfirmationDialog = ({
	setShowConfirmationDialog,
	showConfirmationDialog,
	connectionsToSubmit,
	usersList,
	integrationAccounts,
	handleChanges,
}: ConfirmationDialogProps): JSX.Element => {
	return (
		<Dialog
			onClose={(): void => {
				setShowConfirmationDialog(!showConfirmationDialog);
			}}
			open={showConfirmationDialog}>
			<DialogTitle>Add Account Connections:</DialogTitle>
			<DialogContent>
				{connectionsToSubmit &&
					Object.values(connectionsToSubmit).map((connection) => (
						<Grid
							key={connection.id}
							container
							id="list"
							alignItems="center"
							flexDirection="row"
							width="100%">
							<Grid item xs={5}>
								<Typography>
									{
										Object.values(usersList).find(
											(user) =>
												user.userID === connection.id,
										)?.displayName
									}
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<CompareArrowsIcon fontSize="large" />
							</Grid>
							<Grid item xs={5}>
								<Typography>
									{
										integrationAccounts.find(
											(employee) =>
												employee.id ===
												connection.integrationID,
										)?.name
									}
								</Typography>
							</Grid>
						</Grid>
					))}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void => {
						setShowConfirmationDialog(!showConfirmationDialog);
					}}>
					Cancel
				</Button>
				<Button variant="contained" onClick={handleChanges}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
