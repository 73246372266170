import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { UserDetails } from '../../constants/Common';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { sortObjectByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type EmployeesTableDetails = Pick<
	UserDetails,
	'displayName' | 'mobileNumber' | 'email'
>;

export type EmployeeDetailsProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<FirebaseApi, 'subscribeUsersByCompany'>;
};

export const EmployeeDetails = ({
	userDetails,
	firebaseApi,
}: EmployeeDetailsProps): JSX.Element => {
	const [tableEmployeesData, setTableEmployeesData] = useState<
		Record<string, EmployeesTableDetails>
	>({});
	const [loading, setLoading] = useState(false);
	const [employees, setEmployees] = useState<Record<string, UserDetails>>({});

	useEffect(() => {
		if (!userDetails?.companyID) return;
		setLoading(true);
		const employeeSubscription = firebaseApi.subscribeUsersByCompany(
			userDetails.companyID,
			(employees) => {
				const sortedEmployees = sortObjectByField(
					employees,
					'displayName',
				);
				setEmployees(sortedEmployees);
				setLoading(false);
			},
		);
		return employeeSubscription;
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		const tableData = Object.fromEntries(
			Object.values(employees).map((employee: UserDetails) => [
				employee.userID,
				{
					displayName: employee.displayName,
					mobileNumber: employee.mobileNumber,
					email: employee.email,
				},
			]),
		);
		setTableEmployeesData(tableData);
	}, [employees]);

	const columns: MUIDataTableColumn[] = [
		{
			name: 'displayName',
			label: 'Name',
		},
		{
			name: 'mobileNumber',
			label: 'Phone Number',
		},
		{
			name: 'email',
			label: 'Email',
		},
	];

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no employees found'
	);

	const tableOptions: MUIDataTableOptions = {
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 310px)',
		viewColumns: false,
		selectableRowsOnClick: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	return (
		<DataTable
			tableData={Object.values(tableEmployeesData)}
			columns={columns}
			title="Employee Details"
			customTableOptions={tableOptions}
		/>
	);
};
