import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	IntegrationAtRest,
	IntegrationType,
	isPayrollIntegrationType,
} from '../../../../models/Integrations/Integration';
import {
	ActivityTypesMapping,
	BaseIntegrationLink,
	IntegrationElement,
} from '../../../../models/Integrations/IntegrationElements';
import { useFeatureFlagContext } from '../../../../providers/featureFlags/Provider';
import { ActivityIntegrationTabProps } from '../IntegrationTab';
import { ErrorFooter } from './ErrorFooter';
import { MappingTable } from './MappingTable';
import { OvertimeFooter } from './OvertimeFooter';

export const ActivityMappingTab = <T extends IntegrationType>({
	user,
	integration,
	integrationCollection,
	fetchData,
	firebaseApi,
}: ActivityIntegrationTabProps<T>): JSX.Element => {
	const abortSignal = useAbortController();
	const featureFlags = useFeatureFlagContext();
	const overtimeEnabled =
		integration.type === 'IPayroll' &&
		featureFlags.get('enablePayrollOvertime');
	// contractorsEnabled will be opened to other integrations after TL-2350
	const contractorsEnabled =
		integration.type === 'Xero' &&
		featureFlags.get('enablePayrollContractors');

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const [activityMapping, setActivityMapping] =
		useState<ActivityTypesMapping | null>(null);
	const [integrationActivityTypes, setIntegrationActivityTypes] = useState<
		Record<string, IntegrationElement>
	>({});

	const getIntegrationActivityIDs = useCallback(
		async (abortSignal: AbortSignal) => {
			if (user === null) return;
			const activityList = await fetchData(abortSignal, user);

			if (activityList) {
				const activityMap = activityList.activityTypes.reduce(
					(
						acc: Record<string, IntegrationElement>,
						integrationElement,
					) => {
						acc[integrationElement.id] = integrationElement;
						return acc;
					},
					{},
				);

				setIntegrationActivityTypes(activityMap);
				if (activityList.activityTypes.length === 0) {
					setError(
						`No Pay Options available from ${integration.type}. Please ensure you have your integrated system set up.`,
					);
				} else {
					setError(null);
				}
			} else {
				if (!abortSignal.aborted) {
					setError(
						`Unable to get Pay Options from ${integration.type}. Please try again.`,
					);
					setIntegrationActivityTypes({});
				}
			}
		},
		[fetchData, integration.type, user],
	);

	useEffect(() => {
		const emptyDefault: BaseIntegrationLink = {
			id: 'Default',
			name: 'Default',
			integrationID: '',
			integrationName: '',
		};
		const updatedActivityMapping: ActivityTypesMapping = {
			Default: emptyDefault,
		};

		if (isPayrollIntegrationType(integration)) {
			const emptyOvertime: BaseIntegrationLink = {
				id: 'Overtime',
				name: 'Overtime',
				integrationID: '',
				integrationName: '',
			};

			const emptyDefaultContractor: BaseIntegrationLink = {
				id: 'Contractor',
				name: 'Contractor',
				integrationID: '',
				integrationName: '',
			};

			if (contractorsEnabled) {
				updatedActivityMapping['Contractor'] = emptyDefaultContractor;
			}

			if (integration.overtimeThreshold !== undefined) {
				updatedActivityMapping['Overtime'] = emptyOvertime;
			}
		}

		setActivityMapping({
			...updatedActivityMapping,
			...integration.activityTypes,
		});

		getIntegrationActivityIDs(abortSignal);
	}, [
		getIntegrationActivityIDs,
		abortSignal,
		integration,
		contractorsEnabled,
	]);

	useEffect(() => {
		if (
			integrationActivityTypes === null ||
			Object.keys(integrationActivityTypes).length === 0 ||
			activityMapping === null
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [integrationActivityTypes, activityMapping]);

	const handleUpdateMapping = async (
		updatedMapping: ActivityTypesMapping,
	): Promise<void> => {
		// Remove blank entires
		const updatedOrAddedMappings = Object.values(updatedMapping).filter(
			(item) => item.integrationID !== '',
		);

		const updatedIntegration: Pick<
			IntegrationAtRest<T>,
			'activityTypes'
		> = {
			// Couldn't get the MappingTable component to play nice with a ternary Type to guarantee the mapping used.
			// However, this should be relatively safe
			activityTypes: Object.fromEntries(
				updatedOrAddedMappings.map((item) => [item.id, item]),
			) as ActivityTypesMapping,
		};

		await firebaseApi.updateIntegrationActivityMappings(
			integrationCollection,
			integration.companyID,
			updatedIntegration.activityTypes,
		);
	};

	if (activityMapping === null) return <></>;
	if (integrationActivityTypes === null) return <></>;

	return (
		<Box py={2}>
			<Paper id="boxList" elevation={1}>
				<MappingTable
					headerTitle={{
						left: 'Activity',
						right: 'Pay Option',
					}}
					dropdownLabel="Pay Option"
					dropdownHelperText="Missing mapping for Activity. Select a new Pay Option above"
					integrationMapping={activityMapping}
					integrationElements={integrationActivityTypes}
					loading={loading}
					fetchIntegrationIDs={getIntegrationActivityIDs}
					handleCommitMapping={handleUpdateMapping}
					mappingType="Activity"
				/>
				{overtimeEnabled && isPayrollIntegrationType(integration) && (
					<OvertimeFooter
						loading={loading}
						integration={integration}
						integrationPayOptions={integrationActivityTypes}
						firebaseApi={firebaseApi}
					/>
				)}
				{error !== null && <ErrorFooter error={error} />}
			</Paper>
		</Box>
	);
};
