import { useEffect, useState } from 'react';
import {
	QualificationTemplate,
	Qualification,
	QualificationUpdate,
	QualificationWithEvidence,
	QualificationNew,
	QualificationDelete,
	QualificationDeleteWithEvidence,
} from '../../../constants/Qualifications';
import type { FirebaseApi } from '../../../firebase/firebaseApi';
import { useUserDetailsContext } from '../../../providers/UserProvider';
import { SearchTemplates } from '../Common/SearchTemplates';
import { SectionHeader } from '../Common/SectionHeader';
import { QualificationDialog } from './QualificationDialog';
import { QualificationsList } from './QualificationsList';

export type QualificationsFirebaseCalls =
	| 'qualificationTemplatesSubscription'
	| 'saveQualificationUpdates'
	| 'userProfileQualificationsSubscription';

type QualificationsProps = {
	userID: string;
	isPrinting: boolean;
	firebaseApi: Pick<FirebaseApi, QualificationsFirebaseCalls>;
};

export const Qualifications = ({
	userID,
	isPrinting,
	firebaseApi,
}: QualificationsProps): JSX.Element => {
	const userDetails = useUserDetailsContext();

	const [templateQualifications, setTemplateQualifications] = useState<
		QualificationTemplate[]
	>([]);
	const [qualifications, setQualifications] = useState<Qualification[]>([]);
	const [qualificationUpdates, setQualificationUpdates] = useState<
		Record<string, QualificationUpdate>
	>({});
	const [qualificationsAfterDiffs, setQualificationsAfterDiffs] = useState<
		Qualification[]
	>([]);
	const [isEditing, setIsEditing] = useState(false);
	const [currentSelection, setCurrentSelection] =
		useState<QualificationTemplate | null>(null);

	useEffect(() => {
		if (isPrinting) {
			setIsEditing(false);
		}
	}, [isPrinting]);

	// reset local changes after save or cancel
	useEffect(() => setQualificationUpdates({}), [isEditing]);

	useEffect(
		() =>
			firebaseApi.userProfileQualificationsSubscription(
				userID,
				(qualifications) => {
					setQualifications(
						qualifications
							// Don't show expired quals - we can't do this at query stage as we need to do an OR
							// If an expired qual is obtained again, it will simply be overwritten
							.filter(
								(qualification) =>
									!qualification.expiryDate ||
									qualification.expiryDate.toDate() >=
										new Date(),
							),
					);
				},
			),
		[firebaseApi, userID],
	);

	useEffect(() => {
		// apply all diffs to our initial list of qualifications
		// there's a lot of shuffling from arrays to object and back here, which is annoying,
		// buuuut I want to ensure that certain things are unique per object, and this is the easy way of doing that

		const qualificationsMap = Object.fromEntries(
			qualifications.map((qualification) => [
				qualification.id,
				qualification,
			]),
		);

		const updatedMap = Object.entries(qualificationUpdates).reduce(
			(prev, [id, qualificationUpdate]) => {
				if (qualificationUpdate.type === 'delete') {
					delete prev[id];
				} else if (qualificationUpdate.type === 'new') {
					prev[id] = qualificationUpdate.newQualification;
				}
				return prev;
			},
			qualificationsMap,
		);

		setQualificationsAfterDiffs(
			Object.values(updatedMap).sort((qualificationA, qualificationB) =>
				qualificationA.title.localeCompare(qualificationB.title),
			),
		);
	}, [qualifications, qualificationUpdates]);

	useEffect(
		() =>
			firebaseApi.qualificationTemplatesSubscription(
				(qualificationTemplates) => {
					const qualificationIDs = qualifications.map((i) => i.id);
					setTemplateQualifications(
						qualificationTemplates
							.filter(
								(template) =>
									!qualificationIDs.includes(template.id),
							)
							.sort((templateA, templateB) =>
								templateA.title.localeCompare(templateB.title),
							),
					);
				},
			),
		[firebaseApi, qualifications],
	);

	const dialogToNewQualifications = (
		qualification: QualificationWithEvidence,
	): void => {
		const newDiff: QualificationNew = {
			type: 'new',
			newQualification: qualification,
		};
		setQualificationUpdates((prev) => ({
			...prev,
			[qualification.id]: newDiff,
		}));
	};

	const onDelete = (qualification: Qualification): void => {
		const del: QualificationDelete | QualificationDeleteWithEvidence =
			qualification.evidenceUrl
				? {
						type: 'delete',
						hasEvidence: true,
						id: qualification.id,
				  }
				: { type: 'delete', hasEvidence: false };
		setQualificationUpdates((prev) => ({
			...prev,
			[qualification.id]: del,
		}));
	};

	const save = async (): Promise<void> => {
		await firebaseApi.saveQualificationUpdates(
			userID,
			qualificationUpdates,
		);
		setIsEditing(false);
	};

	return (
		<SectionHeader
			title="Qualifications"
			setEditState={setIsEditing}
			editing={isEditing}
			printing={isPrinting}
			save={save}>
			<>
				<QualificationsList
					qualifications={qualificationsAfterDiffs}
					editing={isEditing}
					onDelete={onDelete}
				/>
				{isEditing && (
					<SearchTemplates
						currentSelection={currentSelection}
						setCurrentSelection={setCurrentSelection}
						templates={templateQualifications}
						label="title"
						templateName="Qualification"
					/>
				)}
				{currentSelection && userDetails && (
					<QualificationDialog
						open={!!currentSelection}
						close={(): void => setCurrentSelection(null)}
						userDetails={userDetails}
						template={currentSelection}
						afterSave={dialogToNewQualifications}
					/>
				)}
			</>
		</SectionHeader>
	);
};
