import { Box, Card } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import {
	ProjectTrackingCostCode,
	ProjectTrackingCosting,
	ProjectTrackingTaskCode,
} from '../../../../cloudfunctions/projectTracking';
import {
	Site,
	TimesheetActivity,
	UserDetails,
} from '../../../../constants/Common';
import { createSiteActivityID } from '../../../../constants/ProjectTrackingIntegrations';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';
import {
	ActivityLink,
	ProjectTrackingLinks,
	SiteActivityLink,
	SiteLink,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { activityLinkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { IntegrationLinkButton } from '../../../Integrations/Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkCell,
	OptionWithDisabled,
} from '../../../Integrations/Components/IntegrationLinkCell/IntegrationLinkCell';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../../Integrations/Components/IntegrationTable/IntegrationTable';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { activityIntegrationTableRowSearch } from './Helpers/activityIntegrationTableRowSearch';
import {
	ActivityIntegrationTableRow,
	CostCodeLink,
	hasCostCode,
	hasTaskCode,
	TaskCodeLink,
} from './Models/activityTab';

const workbenchActivityMap: Record<
	keyof Pick<ActivityLink, 'costCode' | 'taskCode'>,
	string
> = {
	costCode: 'Cost Code',
	taskCode: 'Task Code',
};

const getLinkStatus = (
	link: ActivityLink | null,
	integrationTask: ProjectTrackingTaskCode | null,
	integrationCostCode: ProjectTrackingCostCode | null,
): IntegrationLinkStatus => {
	if (link === null) {
		return IntegrationLinkStatus.Unlinked;
	} else if (integrationTask === null || integrationCostCode === null) {
		return IntegrationLinkStatus.Missing;
	} else {
		return IntegrationLinkStatus.Linked;
	}
};

export type ActivitiesTabProps = {
	user: User;
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'siteSubscription'
		| 'activeSitesByCompanySubscription'
		| 'projectTrackingIntegrationSiteFetch'
		| 'projectTrackingIntegrationSiteLinksSubscription'
		| 'projectTrackingIntegrationSiteActivityLinksSubscription'
		| 'projectTrackingIntegrationAllSiteActivityLinksSubscription'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		| 'fetchProjectTrackingCostingIDs'
		| 'createProjectTrackingActivityLink'
		| 'deleteProjectTrackingActivityLink'
	>;
};

export const ActivitiesTab = ({
	user,
	userDetails,
	firebaseApi,
	cloudFunctionApi,
}: ActivitiesTabProps): JSX.Element => {
	const abortSignal = useAbortController();

	const [tableData, setTableData] = useState<ActivityIntegrationTableRow[]>(
		[],
	);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [integrationCostings, setIntegrationCostings] = useState<
		Record<string, ProjectTrackingCosting>
	>({});
	const [siteLinks, setSiteLinks] = useState<Record<string, SiteLink>>({});
	const [siteActivityLinks, setSiteActivityLinks] = useState<
		Record<string, SiteActivityLink>
	>({});
	const [newTaskCodeLinks, setNewTaskCodeLinks] = useState<
		Record<string, TaskCodeLink>
	>({});
	const [newCostCodeLinks, setNewCostCodeLinks] = useState<
		Record<string, CostCodeLink>
	>({});
	const [loading, setLoading] = useState<
		Record<
			| 'activities'
			| 'activityLinks'
			| 'siteLinks'
			| 'integrationActivities',
			boolean
		>
	>({
		activities: true,
		activityLinks: true,
		siteLinks: true,
		integrationActivities: true,
	});

	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const singleSite = userDetails?.accountType === 'management';

	const numCells = 6;
	const cellWidth = `${100 / numCells}%`;
	const cellWidthExtra = `${(100 / numCells) * 1.25}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	const fetchActivityIDs = useCallback(async (): Promise<void> => {
		setLoading((prev) => ({ ...prev, integrationActivities: true }));

		const costingRecord =
			await cloudFunctionApi.fetchProjectTrackingCostingIDs(
				abortSignal,
				user,
			);

		setIntegrationCostings(costingRecord.projects);

		setLoading((prev) => ({
			...prev,
			integrationActivities: false,
		}));
	}, [abortSignal, cloudFunctionApi, user]);

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}
		setLoading((prev) => ({ ...prev, activities: true }));

		if (singleSite) {
			return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
				setSites({ [site.id]: site });
				setLoading((prev) => ({ ...prev, activities: false }));
			});
		} else {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) => {
					setSites(sites);
					setLoading((prev) => ({ ...prev, activities: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}

		setLoading((prev) => ({ ...prev, siteLinks: true }));

		if (singleSite) {
			let mounted = true;
			if (userDetails.siteID === '') {
				return;
			}
			const fetchLink = async (): Promise<void> => {
				const siteLink =
					await firebaseApi.projectTrackingIntegrationSiteFetch(
						userDetails.companyID,
						userDetails.siteID,
					);

				if (siteLink && mounted) {
					setSiteLinks({ [siteLink.id]: siteLink });
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				}
			};

			fetchLink();
			return () => {
				mounted = false;
			};
		} else {
			return firebaseApi.projectTrackingIntegrationSiteLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteLinks(links);
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		fetchActivityIDs();
	}, [fetchActivityIDs]);

	useEffect(() => {
		if (
			!userDetails ||
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}
		setLoading((prev) => ({ ...prev, activityLinks: true }));

		if (singleSite) {
			return firebaseApi.projectTrackingIntegrationSiteActivityLinksSubscription(
				userDetails.companyID,
				userDetails.siteID,
				(links) => {
					setSiteActivityLinks(links);
					setLoading((prev) => ({ ...prev, activityLinks: false }));
				},
			);
		} else {
			return firebaseApi.projectTrackingIntegrationAllSiteActivityLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteActivityLinks(links);
					setLoading((prev) => ({ ...prev, activityLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, siteLinks, userDetails]);

	useEffect(() => {
		const tableData = Object.values(siteLinks).flatMap((siteLink) => {
			const site = sites[siteLink.id];

			return site !== undefined
				? Object.values(site.timesheetActivitiesV2).map((activity) =>
						mapTableData(
							site,
							activity,
							siteLinks,
							siteActivityLinks,
							newTaskCodeLinks,
							newCostCodeLinks,
							integrationCostings,
						),
				  )
				: [];
		});

		setTableData(tableData);
	}, [
		integrationCostings,
		newTaskCodeLinks,
		newCostCodeLinks,
		siteActivityLinks,
		siteLinks,
		sites,
	]);

	const mapTableData = (
		site: Site,
		activity: TimesheetActivity,
		siteLinks: Record<string, SiteLink>,
		links: Record<string, SiteActivityLink>,
		newTaskCodeLinks: Record<string, TaskCodeLink>,
		newCostCodeLinks: Record<string, CostCodeLink>,
		integrationCostings: Record<string, ProjectTrackingCosting>,
	): ActivityIntegrationTableRow => {
		const siteActivityID = createSiteActivityID(site.id, activity.id);
		const siteLink = siteLinks[site.id];
		const link = links[siteActivityID]?.activityLink ?? null;
		const integrationCosting = integrationCostings[siteLink.integrationID];

		const integrationActivity =
			integrationCosting?.tasks?.find(
				(item) =>
					link !== null && item.id === link?.taskCode?.integrationID,
			) ?? null;

		const integrationCostCode =
			integrationCosting?.costCodes?.find(
				(item) =>
					link !== null && item.id === link?.costCode?.integrationID,
			) ?? null;

		const status = getLinkStatus(
			link,
			integrationActivity,
			integrationCostCode,
		);

		const taskCode: TaskCodeLink = {
			siteID: site.id,
			activityID: activity.id,
			taskCode:
				link?.taskCode ?? newTaskCodeLinks[siteActivityID]?.taskCode,
		};

		const costCode: CostCodeLink = {
			siteID: site.id,
			activityID: activity.id,
			costCode:
				link?.costCode ?? newCostCodeLinks[siteActivityID]?.costCode,
		};

		return {
			id: createSiteActivityID(site.id, activity.id),
			site: site.name,
			name: activity.activityName,
			status: status,
			taskCode: taskCode,
			costCode: costCode,
		};
	};

	const createLink = async (
		siteID: string,
		link: ActivityLink,
	): Promise<void> => {
		if (user !== undefined && user !== null) {
			await cloudFunctionApi.createProjectTrackingActivityLink(
				abortSignal,
				user,
				siteID,
				link,
			);
		}
	};

	const deleteLink = async (
		siteID: string,
		link: ActivityLink,
	): Promise<void> => {
		if (user !== undefined && user !== null) {
			await cloudFunctionApi.deleteProjectTrackingActivityLink(
				abortSignal,
				user,
				siteID,
				link,
			);
		}
	};

	const triggerRefresh = async (): Promise<void> => await fetchActivityIDs();

	const onCostingLinkChange =
		(
			siteActivityID: string,
			link: ActivityIntegrationTableRow['taskCode' | 'costCode'],
			integrationCostingList: IntegrationElement[],
			setLink: Dispatch<
				SetStateAction<Record<string, CostCodeLink | TaskCodeLink>>
			>,
			type: keyof Pick<
				ActivityIntegrationTableRow,
				'costCode' | 'taskCode'
			>,
		) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setLink((prev) => {
					const { [siteActivityID]: _, ...remaining } = prev;
					return remaining;
				});

				return;
			}

			const integrationSiteActivity = integrationCostingList.find(
				(item) => item.id === integrationID,
			);

			if (integrationSiteActivity === undefined) {
				return;
			}

			setLink((prev) => ({
				...prev,
				[siteActivityID]: {
					...prev[siteActivityID],
					...link,
					[type]: {
						integrationID: integrationSiteActivity.id,
						integrationName: integrationSiteActivity.name,
					},
				},
			}));
		};

	const renderStatusChip = (status: IntegrationLinkStatus): JSX.Element => (
		<IntegrationStatusChip status={status} />
	);

	const renderCosting = (
		siteAndActivityLink: ActivityIntegrationTableRow[
			| 'taskCode'
			| 'costCode'],
		type: keyof Pick<ActivityIntegrationTableRow, 'costCode' | 'taskCode'>,
	): JSX.Element => {
		const { siteID, activityID } = siteAndActivityLink;
		const siteActivityID = createSiteActivityID(siteID, activityID);
		const siteLink = siteLinks[siteID];

		const costingList =
			integrationCostings[siteLink.integrationID][
				type === 'costCode' ? 'costCodes' : 'tasks'
			];

		const options = costingList.reduce<Record<string, OptionWithDisabled>>(
			(prev, current) => ({
				...prev,
				[current.id]: {
					id: current.id,
					name: `${current.name} - ${current.description}`,
					disabled: false,
				},
			}),
			{},
		);

		const onChange = onCostingLinkChange(
			siteActivityID,
			siteAndActivityLink,
			costingList,
			type === 'costCode' ? setNewCostCodeLinks : setNewTaskCodeLinks,
			type,
		);
		const linking = !!linkingRecords[siteActivityID];
		const trueLink = siteActivityLinks[siteActivityID]?.activityLink[type];

		const integrationID = hasTaskCode(siteAndActivityLink)
			? siteAndActivityLink.taskCode.integrationID
			: hasCostCode(siteAndActivityLink)
			? siteAndActivityLink.costCode?.integrationID
			: undefined;

		const selectText = `Select ${
			type === 'costCode' ? 'Cost Code' : 'Task Code'
		}`;

		return (
			<IntegrationLinkCell
				link={trueLink}
				linkIntegrationID={integrationID}
				options={options}
				onChange={onChange}
				missingLinkLabel={`Missing Workbench ${workbenchActivityMap[type]}`}
				missingLinkMessage="Linked item not found in Workbench"
				disabled={linking}
				selectOverride={selectText}
			/>
		);
	};

	const renderOptionButton = (siteActivityID: string): JSX.Element => {
		const link = siteActivityLinks[siteActivityID];
		const newTaskCodeLink = newTaskCodeLinks[siteActivityID];
		const newCostCodeLink = newCostCodeLinks[siteActivityID];

		const handleCreateLink = async (): Promise<void> => {
			const activity = sites[
				newTaskCodeLink.siteID
			].timesheetActivitiesV2.find(
				(item) => item.id === newTaskCodeLink.activityID,
			);

			// newTaskCodeLink created from the list of activities, so it will always exist but .find() doesn't care
			if (
				activity === undefined ||
				newTaskCodeLink?.taskCode === undefined ||
				newCostCodeLink?.costCode === undefined
			) {
				return;
			}

			const newLink: ActivityLink = {
				id: newTaskCodeLink.activityID,
				name: activity.activityName,
				taskCode: newTaskCodeLink.taskCode,
				costCode: newCostCodeLink.costCode,
				type: ProjectTrackingLinks.Activity,
			};

			setLinkingRecords((prev) => ({ ...prev, [siteActivityID]: true }));
			await createLink(newTaskCodeLink.siteID, newLink);
			setNewCostCodeLinks((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
			setNewTaskCodeLinks((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteActivityID]: true }));
			await deleteLink(link.siteID, link.activityLink);
			setLinkingRecords((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled =
			!link &&
			(newTaskCodeLink?.taskCode === undefined ||
				newCostCodeLink?.costCode === undefined);

		return (
			<IntegrationLinkButton
				link={link?.activityLink?.taskCode}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Activity',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidth,
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidth,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						{renderStatusChip(value)}
					</Box>
				),
			},
		},
		{
			name: 'taskCode',
			label: 'Task Code',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthExtra,
					},
				}),
				sortCompare: activityLinkTableSort('taskCode'),
				customBodyRender: (taskCodeLink: TaskCodeLink) => (
					<Box display="flex" justifyContent="center">
						{renderCosting(taskCodeLink, 'taskCode')}
					</Box>
				),
			},
		},
		{
			name: 'costCode',
			label: 'Cost Code',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthExtra,
					},
				}),
				sortCompare: activityLinkTableSort('costCode'),
				customBodyRender: (costCodeLink: CostCodeLink) => (
					<Box display="flex" justifyContent="center">
						{renderCosting(costCodeLink, 'costCode')}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	return (
		<Box flex="1">
			<Card>
				<IntegrationTable
					title="Link Activites"
					data={tableData}
					columns={columns}
					triggerRefresh={triggerRefresh}
					loading={
						loading.siteLinks ||
						loading.activityLinks ||
						loading.activities ||
						loading.integrationActivities
					}
					missingDataLabel="Sorry, no Site Links or Activities found"
					centeredColumns={[3, 4, 5, 6]}
					customSearch={activityIntegrationTableRowSearch}
				/>
			</Card>
			<CustomSnackBar
				snackBarText="hello"
				open={false}
				onClose={(): void => undefined}
			/>
		</Box>
	);
};
