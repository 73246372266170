import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type EditNoteBody = {
	leaveID: string;
	noteID: string;
	note: string;
};

export const editLeaveNote = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	leaveID: string,
	noteID: string,
	note: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<EditNoteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.EditLeaveNote,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			leaveID,
			noteID,
			note,
		},
	});
};
