import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { Template } from '../../../constants/Template';
import { ViewTemplate } from './ViewTemplate';
import { ViewTemplateHeader } from './ViewTemplateHeader';

type ViewTemplateDialogProps = {
	open: boolean;
	template: Template;
	handleOnClose: () => void;
};

export const ViewTemplateDialog = ({
	open,
	template,
	handleOnClose,
}: ViewTemplateDialogProps): JSX.Element => {
	return (
		<Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="xl">
			<DialogTitle>
				<Grid
					container
					direction={{
						xs: 'column-reverse',
						sm: 'column-reverse',
						md: 'row',
					}}>
					<Grid item sm={12} md={10}>
						<ViewTemplateHeader
							name={template.name}
							companyName={template.company.name}
						/>
					</Grid>
					<Grid item sm={12} md={2}>
						<Box display="flex" justifyContent="flex-end">
							<Tooltip title="Close">
								<IconButton onClick={handleOnClose}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent sx={{ pb: 0 }}>
				<Stack>
					<ViewTemplate template={template} showHeader={false} />
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
