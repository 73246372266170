import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { ManageLeaveIntegrations } from './ManageLeaveIntegrations';

export const ManageLeaveIntegrationsWrapper = (): JSX.Element => {
	return (
		<ManageLeaveIntegrations
			cloudFunctionApi={cloudFunctionApi}
			firebaseApi={firebaseApi}
		/>
	);
};
