import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { IntegrationLink } from '../../../../models/Integrations/IntegrationElements';

export type IntegrationLinkButtonProps = {
	link?: IntegrationLink;
	disabled: boolean;
	handleLinkClick: () => Promise<void>;
	handleUnlinkClick: () => Promise<void>;
};

export const IntegrationLinkButton = ({
	link,
	disabled,
	handleLinkClick,
	handleUnlinkClick,
}: IntegrationLinkButtonProps): JSX.Element => {
	const [sending, setSending] = useState(false);

	let buttonSettings: {
		onClick: () => void;
		disabled: boolean;
	} & (
		| {
				optionText: 'Link';
				variant: 'contained';
		  }
		| {
				optionText: 'Unlink';
				variant: 'outlined';
		  }
	);

	if (link === undefined) {
		buttonSettings = {
			optionText: 'Link',
			disabled: disabled,
			onClick: async (): Promise<void> => {
				setSending(true);
				await handleLinkClick();
				setSending(false);
			},
			variant: 'contained',
		};
	} else {
		buttonSettings = {
			optionText: 'Unlink',
			disabled: disabled,
			onClick: async (): Promise<void> => {
				setSending(true);
				await handleUnlinkClick();
				setSending(false);
			},
			variant: 'outlined',
		};
	}

	return (
		<LoadingButton
			fullWidth
			loading={sending}
			disabled={buttonSettings.disabled || sending}
			variant={buttonSettings.variant}
			onClick={buttonSettings.onClick}>
			{buttonSettings.optionText}
		</LoadingButton>
	);
};
