import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebaseApi from '../../firebase/firebaseApi';
import { InvoicingType } from '../../models/Integrations/InvoicingIntegration';
import { useUserDetailsContext } from '../../providers/UserProvider';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { IntegrationItem, IntegrationsSetup } from './IntegrationsSetup';

export type Integration = {
	title: string;
	url: string;
};

export const InvoicingIntegrationsPage = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const invoicingRedirects = useMemo(
		(): Record<InvoicingType, string | undefined> => ({
			Invoxy: '/connect-invoicing/invoxy',
			Xero: '/connect-invoicing/xero',
		}),
		[],
	);

	useEffect(() => {
		if (userDetails === null) return;

		setLoading(true);
		const sub = firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				if (company?.invoicingIntegrated) {
					const navigationLink =
						invoicingRedirects[company.invoicingIntegrated];
					if (navigationLink) {
						navigate(navigationLink);
					}
				}
				setLoading(false);
			},
		);

		return sub;
	}, [navigate, invoicingRedirects, userDetails]);

	const invoicingTypes: Partial<
		Record<InvoicingType, IntegrationItem<InvoicingType>>
	> = useMemo(() => {
		const types: Partial<
			Record<InvoicingType, IntegrationItem<InvoicingType>>
		> = {
			Invoxy: {
				name: 'Invoxy',
				id: 'Invoxy',
				link: 'invoxy',
			},
			Xero: {
				name: 'Xero',
				id: 'Xero',
				link: 'xero',
			},
		};

		return types;
	}, []);

	const renderDescription = (): JSX.Element => (
		<>
			<Typography>
				Looks like you don&apos;t have an invoicing system setup with
				us! We can handle your timesheets all the way through to
				invoicing, freeing up your schedule for other things.
			</Typography>
			<Typography>
				Select your invoicing provider below to integrate with Trade
				Legion
			</Typography>
		</>
	);

	return (
		<Box>
			{loading ? (
				<LoadingDots />
			) : (
				<IntegrationsSetup<InvoicingType>
					integrationTypes={invoicingTypes}
					description={renderDescription()}
				/>
			)}
		</Box>
	);
};
