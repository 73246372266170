import { createContext, useContext } from 'react';
import { ExplicitAny } from '../../constants/AnyTypes';
import { UserDetails } from '../../constants/Common';

export type Logger = {
	log: (message: string, ...props: ExplicitAny[]) => void;
	debug: (message: string, ...props: ExplicitAny[]) => void;
	error: (message: string, ...props: ExplicitAny[]) => void;
	info: (message: string, ...props: ExplicitAny[]) => void;
};

export type LoggingService = {
	setup: () => void;
	identify: (userDetails: UserDetails | null) => void;
	logger: Logger;
};

export const defaultLoggingContext: LoggingService = {
	setup: () => {
		throw new Error('Uninitialised');
	},
	identify: () => {
		throw new Error('Uninitialised');
	},
	logger: {
		log: () => {
			throw new Error('Uninitialised');
		},
		debug: () => {
			throw new Error('Uninitialised');
		},
		error: () => {
			throw new Error('Uninitialised');
		},
		info: () => {
			throw new Error('Uninitialised');
		},
	},
};

export const LoggingContext = createContext<LoggingService>(
	defaultLoggingContext,
);

export const useLoggingContext = (): LoggingService => {
	return useContext(LoggingContext);
};
