import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { Guests } from './Guests';

export const GuestsWrapper = ({ userDetails }: UserProps): JSX.Element => {
	return (
		<AccountsTableTheme centerColumnHeader={4}>
			<Guests userDetails={userDetails} firebaseApi={firebaseApi} />
		</AccountsTableTheme>
	);
};
