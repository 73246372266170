import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { Chip } from '@mui/material';
import { CSSProperties } from 'react';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';

export type TimesheetStatusChipProps = { status: TimesheetStatus };

export const TimesheetStatusChip = ({
	status,
}: TimesheetStatusChipProps): JSX.Element => {
	const chipStyle: CSSProperties = {
		fontWeight: 'bold',
		justifyContent: 'space-between',
		width: '102px',
	};

	switch (status) {
		case TimesheetStatus.Active:
			return (
				<Chip
					icon={<ErrorOutlineIcon color="error" />}
					label={status}
					color="error"
					size="small"
					style={chipStyle}
				/>
			);
		case TimesheetStatus.Submitted:
			return (
				<Chip
					icon={<OutboundOutlinedIcon color="secondary" />}
					label={status}
					color="secondary"
					size="small"
					style={chipStyle}
				/>
			);
		case TimesheetStatus.Approved:
			return (
				<Chip
					icon={<CheckCircleIcon color="success" />}
					label={status}
					color="success"
					size="small"
					style={chipStyle}
				/>
			);
		case TimesheetStatus.Archived:
			return (
				<Chip
					icon={<CheckCircleIcon color="success" />}
					label={status}
					color="success"
					size="small"
					style={chipStyle}
				/>
			);
	}
};
