import { Card, Typography } from '@mui/material';

export const Create = (): JSX.Element => {
	return (
		<Card>
			<Typography
				textAlign="center"
				width="100%"
				height="calc(100vh - 190px)">
				Under Construction 🚧
			</Typography>
		</Card>
	);
};
