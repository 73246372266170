import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { ManageInvoicingIntegrations } from './ManageInvoicingIntegrations';

export const ManageInvoicingIntegrationsWrapper = (): JSX.Element => {
	return (
		<ManageInvoicingIntegrations
			cloudFunctionApi={cloudFunctionApi}
			firebaseApi={firebaseApi}
		/>
	);
};
