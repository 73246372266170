import { Box, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { BASE_URL as cloudFunctionBaseUrl } from '../../../cloudfunctions/HTTPSCloudFunctionURLs';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { useIsComponentMounted } from '../../../hooks/useIsComponentMounted';
import { useUuid } from '../../../hooks/useUuid';
import {
	ProjectTrackingIntegration,
	projectTrackingType,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../providers/UserProvider';
import { IntegrationPublishDialog } from '../../Integrations/Components/IntegrationPublishDialog/IntegrationPublishDialog';
import { IntegrationSetupDialog } from '../../Integrations/Components/IntegrationSetupDialog/IntegrationSetupDialog';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { EnvironmentVariables } from './PageWrapper';
import { WorkbenchFieldItem } from './WorkbenchFieldItem';
import { WorkbenchUrlDialog } from './WorkbenchUrlDialog';

export type WorkbenchSetupProps = {
	firebaseApi: Pick<
		FirebaseApi,
		'projectTrackingIntegrationSubscription' | 'companySubscription'
	>;
	cloudFunctionApi: Pick<CloudFunctionApi, 'fetchProjectTrackingTokens'>;
	environmentVariables: Record<EnvironmentVariables, string | undefined>;
};

export const WorkbenchSetup = ({
	firebaseApi,
	cloudFunctionApi,
	environmentVariables,
}: WorkbenchSetupProps): JSX.Element => {
	const LINK_END = '#/Admin/ApplicationClient.aspx';
	const appendURL = (url: string): string => url + LINK_END;

	const URL = environmentVariables.WORKBENCH_URL;

	const navigate = useNavigate();
	const user = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const abortSignal = useAbortController();
	const isMounted = useIsComponentMounted();

	const uuid = useUuid();

	const [value, setValue] = useState<string>('');
	const [token, setToken] = useState<string | null>(null);
	const [url, setUrl] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	const [loading, setLoading] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);

	const [integration, setIntegration] =
		useState<ProjectTrackingIntegration | null>(null);

	const integrationType = projectTrackingType.Workbench;
	const urlDialogOpen = token === null && url === null && !authenticated;
	const setupOpen = token === null && url !== null && !authenticated;
	const publishOpen = token !== null && url !== null && !authenticated;

	const clearAll = (): void => {
		setUrl(null);
		setToken(null);
		setValue('');
	};

	const submitIntegrationKey = useCallback(
		async (code: string, url: string) => {
			if (user === null) return;
			setLoading(true);

			const cleanToken = code.startsWith('Bearer ')
				? code.substring(7)
				: code;

			const response = await cloudFunctionApi.fetchProjectTrackingTokens(
				abortSignal,
				user,
				integrationType,
				url,
				cleanToken,
			);

			if (isMounted) {
				if (!response) {
					handleError();
				} else {
					setAuthenticated(true);
				}
				clearAll();
				setLoading(false);
			}
		},
		[abortSignal, cloudFunctionApi, integrationType, isMounted, user],
	);

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/project-tracking', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	useEffect(() => {
		if (userDetails === null) return;

		setLoading(true);
		const sub = firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				setLoading(false);

				if (company.projectTrackingIntegrated === integrationType) {
					setAuthenticated(true);
				} else if (company.projectTrackingIntegrated) {
					// already integrated with another system
					handleCancel();
				} else {
					// no longer available
					setAuthenticated(false);
				}
			},
		);

		return sub;
	}, [firebaseApi, handleCancel, integrationType, userDetails]);

	useEffect(() => {
		if (userDetails === null || !authenticated) return;

		return firebaseApi.projectTrackingIntegrationSubscription(
			userDetails.companyID,
			(integration) => {
				setIntegration(integration);
			},
		);
	}, [authenticated, firebaseApi, userDetails]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (showError) setShowError(false);
		setValue(event.target.value);
	};

	const handleError = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleAddUrl = (value: string): void => {
		setUrl(URL ?? value);
	};

	const handleAuthenticate = (): void => {
		if (value === '') {
			setShowError(true);
		} else {
			setToken(value);
		}
	};

	const handlePublish = async (): Promise<void> => {
		if (!token || !url) return Promise.resolve(); // bad token
		await submitIntegrationKey(token, url);
	};

	const renderSetupContent = (): JSX.Element => {
		return (
			<Stack spacing={1}>
				<Typography>
					Input your companies Workbench Api Key below.
				</Typography>
				<TextField
					label="Workbench API Key"
					fullWidth
					value={value}
					onChange={handleChange}
					helperText={showError && 'API Key was invalid'}
					error={showError}
				/>
				{url && (
					<Typography variant="caption">
						{
							'A Workbench API key can be generated by copying the fields below into the '
						}
						<Link
							href={appendURL(url)}
							underline="hover"
							target="_blank"
							rel="noopener">
							Workbench Application Clients Table
						</Link>
					</Typography>
				)}
				<WorkbenchFieldItem field="ID" value="Trade Legion" />
				<WorkbenchFieldItem field="Name" value="Trade Legion" />
				<WorkbenchFieldItem field="Secret" value={uuid} />
				<WorkbenchFieldItem field="Life Time" value="2628000" />
				<WorkbenchFieldItem
					field="Allowed Origin"
					value={cloudFunctionBaseUrl}
				/>
			</Stack>
		);
	};

	const urlDialog = (): JSX.Element => (
		<WorkbenchUrlDialog
			open={urlDialogOpen}
			handleCancel={handleCancel}
			handleSubmitUrl={handleAddUrl}
			urlRegexp={environmentVariables.WORKBENCH_URL_REGEX}
		/>
	);

	const setupDialog = (): JSX.Element => (
		<IntegrationSetupDialog
			open={setupOpen}
			integrationType={integrationType}
			renderContent={renderSetupContent}
			handleCancel={handleCancel}
			handleAuthenticate={handleAuthenticate}
		/>
	);

	const publishDialog = (): JSX.Element => (
		<IntegrationPublishDialog
			open={publishOpen}
			integrationType={integrationType}
			loading={loading}
			handlePublish={handlePublish}
			handleCancel={handleCancel}
		/>
	);

	return (
		<Box>
			{loading || user === null || userDetails === null ? (
				<LoadingDots />
			) : (
				<>
					{urlDialog()}
					{setupDialog()}
					{publishDialog()}
					{authenticated && integration === null ? (
						<LoadingDots />
					) : null}
				</>
			)}
		</Box>
	);
};
