import { IntegrationLink } from '../../../../../models/Integrations/IntegrationElements';
import { IntegrationLinkStatus } from '../../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';

export type TaskCodeLink = {
	siteID: string;
	activityID: string;
	taskCode?: IntegrationLink;
};
export type CostCodeLink = {
	siteID: string;
	activityID: string;
	costCode?: IntegrationLink;
};

export const hasTaskCode = (
	value: TaskCodeLink | CostCodeLink,
): value is Required<TaskCodeLink> =>
	(value as TaskCodeLink)?.taskCode !== undefined;
export const hasCostCode = (
	value: TaskCodeLink | CostCodeLink,
): value is Required<CostCodeLink> =>
	(value as CostCodeLink)?.costCode !== undefined;

export type ActivityIntegrationTableRow = {
	id: string;
	name: string;
	site: string;
	status: IntegrationLinkStatus;
	taskCode: TaskCodeLink;
	costCode: CostCodeLink;
};
