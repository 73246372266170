import { addDays, addWeeks } from 'date-fns';
import {
	formatSlashedDate,
	formatTime,
} from '../../components/helpers/dateFormatters';
import { guardDate } from '../../components/helpers/dateUtilities';
import { SiteLog } from '../../constants/Common';
import {
	Firestore,
	FirestoreDataConverter,
	FirestoreError,
	Timestamp,
} from '../firebase';

const SITE_LOGS_COLLECTION = 'siteLogs';
const siteLogConverter: FirestoreDataConverter<SiteLog> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) =>
		({ ...snapshot.data(), id: snapshot.id } as SiteLog),
};

const formattedSiteLogConverter: FirestoreDataConverter<SiteLog> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => {
		const data = snapshot.data() as SiteLog;
		const date = data.datetime.toDate();
		const timeString = formatTime(date);
		const dateString = formatSlashedDate(date);
		return {
			...data,
			id: snapshot.id,
			timeString,
			dateString,
		};
	},
};

const userSitelogsForWeekSubscription = (
	workerID: string,
	siteID: string,
	week: Timestamp | Date,
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('workerID', '==', workerID)
		.where('siteID', '==', siteID)
		.where('datetime', '>=', week)
		.where(
			'datetime',
			'<',
			addWeeks(guardDate(week) ? week : week.toDate(), 1),
		)
		.withConverter(siteLogConverter)
		.onSnapshot(
			(snapshot) => onNext(snapshot.docs.map((doc) => doc.data())),
			onError,
		);
};

/** Returns the first signIn SiteLog found for workers current site or null if worker hasn't signed in within provided period */
const getFirstWorkerSiteSignInSiteLogForPeriod = async (
	workerID: string,
	siteID: string,
	startDate: Date,
	endDate: Date,
): Promise<SiteLog | null> => {
	const querySnapshot = await Firestore.collection(SITE_LOGS_COLLECTION)
		.where('workerID', '==', workerID)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.where('type', '==', 'In')
		.where('siteID', '==', siteID)
		.orderBy('datetime', 'asc')
		.withConverter(siteLogConverter)
		.limit(1)
		.get();
	return querySnapshot.size === 1 ? querySnapshot.docs[0].data() : null;
};

const getSiteLogsByCompanyDateRange = async (
	companyID: string,
	startDate: Date,
	endDate: Date,
): Promise<SiteLog[]> => {
	const snapshot = await Firestore.collection(SITE_LOGS_COLLECTION)
		.where('companyID', '==', companyID)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', 'asc')
		.withConverter(formattedSiteLogConverter)
		.get();
	return snapshot.docs.map((doc) => doc.data());
};

const siteLogsByCompanyAndTypeDateRangeSubscription = (
	companyID: SiteLog['companyID'],
	type: SiteLog['type'],
	startDate: Date,
	endDate: Date,
	order: 'asc' | 'desc',
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('companyID', '==', companyID)
		.where('type', '==', type)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', order)
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(
				querySnapshot.docs
					.filter((doc) => doc.exists)
					.map((doc) => doc.data()),
			);
		}, onError);
};

const getSiteLogsBySiteDateRange = async (
	siteID: string,
	startDate: Date,
	endDate: Date,
): Promise<SiteLog[]> => {
	const snapshot = await Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteID', '==', siteID)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', 'asc')
		.withConverter(formattedSiteLogConverter)
		.get();
	return snapshot.docs.map((doc) => doc.data());
};

const siteLogsBySiteDateRangeSubscription = (
	siteID: string,
	startDate: Date,
	endDate: Date,
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteID', '==', siteID)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', 'asc')
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.docs.map((doc) => doc.data()));
		}, onError);
};

const siteLogsBySiteAndTypeDateRangeSubscription = (
	siteID: SiteLog['siteID'],
	type: SiteLog['type'],
	startDate: Date,
	endDate: Date,
	order: 'asc' | 'desc',
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteID', '==', siteID)
		.where('type', '==', type)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', order)
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(
				querySnapshot.docs
					.filter((doc) => doc.exists)
					.map((doc) => doc.data()),
			);
		}, onError);
};

const siteLogsBySiteContractedToAndTypeDateRangeSubscription = (
	siteID: SiteLog['siteID'],
	contractedToID: NonNullable<SiteLog['contractedTo']>['id'],
	type: SiteLog['type'],
	startDate: Date,
	endDate: Date,
	order: 'asc' | 'desc',
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteID', '==', siteID)
		.where('contractedTo.id', '==', contractedToID)
		.where('type', '==', type)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', order)
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(
				querySnapshot.docs
					.filter((doc) => doc.exists)
					.map((doc) => doc.data()),
			);
		}, onError);
};

const getSiteLogsBySiteCompanyDateRange = async (
	siteCompanyID: string,
	startDate: Date,
	endDate: Date,
): Promise<SiteLog[]> => {
	const snapshot = await Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteCompanyID', '==', siteCompanyID)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', 'asc')
		.withConverter(formattedSiteLogConverter)
		.get();
	return snapshot.docs.map((doc) => doc.data());
};

const siteLogsBySiteCompanyAndTypeDateRangeSubscription = (
	siteCompanyID: SiteLog['siteCompanyID'],
	type: SiteLog['type'],
	startDate: Date,
	endDate: Date,
	order: 'asc' | 'desc',
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('siteCompanyID', '==', siteCompanyID)
		.where('type', '==', type)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', order)
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(
				querySnapshot.docs
					.filter((doc) => doc.exists)
					.map((doc) => doc.data()),
			);
		}, onError);
};

const siteLogsByContractedToAndTypeDateRangeSubscription = (
	contractedToID: NonNullable<SiteLog['contractedTo']>['id'],
	type: SiteLog['type'],
	startDate: Date,
	endDate: Date,
	order: 'asc' | 'desc',
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) => {
	return Firestore.collection(SITE_LOGS_COLLECTION)
		.where('contractedTo.id', '==', contractedToID)
		.where('type', '==', type)
		.where('datetime', '>=', startDate)
		.where('datetime', '<=', endDate)
		.orderBy('datetime', order)
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(
				querySnapshot.docs
					.filter((doc) => doc.exists)
					.map((doc) => doc.data()),
			);
		}, onError);
};

const signInsByCompanyDaySubscription = (
	companyID: string,
	date: Date,
	onNext: (siteLogs: SiteLog[]) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) =>
	Firestore.collection(SITE_LOGS_COLLECTION)
		.where('type', '==', 'In')
		.where('companyID', '==', companyID)
		.where('datetime', '>=', date)
		.where('datetime', '<=', addDays(date, 1))
		.withConverter(siteLogConverter)
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.docs.map((doc) => doc.data()));
		}, onError);

const signInsByCompanyDayCountSubscription = (
	companyID: string,
	date: Date,
	onNext: (count: number) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) =>
	Firestore.collection(SITE_LOGS_COLLECTION)
		.where('type', '==', 'In')
		.where('companyID', '==', companyID)
		.where('datetime', '>=', date)
		.where('datetime', '<=', addDays(date, 1))
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.size);
		}, onError);

const signInsBySiteDayCountSubscription = (
	siteID: string,
	date: Date,
	onNext: (count: number) => void,
	onError?: (error: FirestoreError) => void,
): (() => void) =>
	Firestore.collection(SITE_LOGS_COLLECTION)
		.where('type', '==', 'In')
		.where('siteID', '==', siteID)
		.where('datetime', '>=', date)
		.where('datetime', '<=', addDays(date, 1))
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.size);
		}, onError);

const siteLogsFirebaseApi = {
	userSitelogsForWeekSubscription,
	getFirstWorkerSiteSignInSiteLogForPeriod,
	getSiteLogsByCompanyDateRange,
	siteLogsByCompanyAndTypeDateRangeSubscription,
	getSiteLogsBySiteDateRange,
	siteLogsBySiteDateRangeSubscription,
	siteLogsBySiteAndTypeDateRangeSubscription,
	siteLogsBySiteContractedToAndTypeDateRangeSubscription,
	getSiteLogsBySiteCompanyDateRange,
	siteLogsBySiteCompanyAndTypeDateRangeSubscription,
	siteLogsByContractedToAndTypeDateRangeSubscription,
	signInsByCompanyDaySubscription,
	signInsByCompanyDayCountSubscription,
	signInsBySiteDayCountSubscription,
};

export default siteLogsFirebaseApi;
