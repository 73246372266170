import {
	Day,
	addDays,
	differenceInWeeks,
	endOfWeek,
	isDate,
	isValid,
	startOfWeek,
} from 'date-fns';
import firebase from 'firebase';
import { DayString, Month } from '../../constants/Common';
import { ReplaceWithUnionValue } from '../../constants/TypescriptUtilities';
import { formatSlashedDate } from './dateFormatters';

export const shortDays = [
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
	'Sun',
] as const;

export type ShortDay = (typeof shortDays)[number];

export const getDayString = (day: Day): DayString => {
	switch (day) {
		case 0: {
			return 'Sunday';
		}
		case 1: {
			return 'Monday';
		}
		case 2: {
			return 'Tuesday';
		}
		case 3: {
			return 'Wednesday';
		}
		case 4: {
			return 'Thursday';
		}
		case 5: {
			return 'Friday';
		}
		case 6: {
			return 'Saturday';
		}
	}
};

/** Converts a js style day (Sun=0,Mon=1,Sat=6) to (Sun=6,Mon=0,Sat=5) */
export const toZeroedMonday = (day: Day): number => {
	return day !== 0 ? day - 1 : 6;
};

/**
 * Week is expected to be a Monday.
 * Used to find the date of an Activity relative to a Timesheet week.
 * */
export const getDayOfWeekDate = (week: Date, day: DayString): Date =>
	addDays(week, toZeroedMonday(getDayNumber(day)));

export const getDayNumber = (day: DayString): Day => {
	switch (day) {
		case 'Sunday': {
			return 0;
		}
		case 'Monday': {
			return 1;
		}
		case 'Tuesday': {
			return 2;
		}
		case 'Wednesday': {
			return 3;
		}
		case 'Thursday': {
			return 4;
		}
		case 'Friday': {
			return 5;
		}
		case 'Saturday': {
			return 6;
		}
	}
};

export const sortByDayOfWeekCompareFn = (
	day1: DayString,
	day2: DayString,
	order: 'asc' | 'desc',
): number => {
	// start of week as Monday not Sunday
	let dayOfWeek1: number = getDayNumber(day1);
	if (dayOfWeek1 === 0) dayOfWeek1 = 7;
	let dayOfWeek2: number = getDayNumber(day2);
	if (dayOfWeek2 === 0) dayOfWeek2 = 7;
	const direction = order === 'asc' ? 1 : -1;
	return (dayOfWeek2 - dayOfWeek1) * direction;
};

export const getShortDayNumber = (day: ShortDay): Day => {
	return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(
		day,
	) as Day;
};

export const getMonthString = (month: number): Month => {
	switch (month) {
		case 0: {
			return 'jan';
		}
		case 1: {
			return 'feb';
		}
		case 2: {
			return 'mar';
		}
		case 3: {
			return 'apr';
		}
		case 4: {
			return 'may';
		}
		case 5: {
			return 'jun';
		}
		case 6: {
			return 'jul';
		}
		case 7: {
			return 'aug';
		}
		case 8: {
			return 'sep';
		}
		case 9: {
			return 'oct';
		}
		case 10: {
			return 'nov';
		}
		case 11: {
			return 'dec';
		}
		default: {
			// This should not be reached
			return 'jan';
		}
	}
};

export const secondsToTime = (timeInSeconds: number): string => {
	const hours = Math.floor(timeInSeconds / 3600)
		.toString()
		.padStart(2, '0');
	const minutes = Math.floor((timeInSeconds % 3600) / 60)
		.toString()
		.padStart(2, '0');
	const seconds = Math.floor(timeInSeconds % 60)
		.toString()
		.padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
};

export const sortShortDay = (
	dateA: ShortDay,
	dateB: ShortDay,
	order: 'asc' | 'desc' = 'asc',
): number =>
	(getShortDayNumber(dateA) - getShortDayNumber(dateB)) *
	(order === 'asc' ? 1 : -1);

/** Transform a number of hours worked into a string of the most-significant time unit rounded down
 * This assumes 40 hour weeks, 4 weeks a month, 50 weeks a year.
 * Returns 0 hours if the hours worked is less that 1.
 *
 * @example
 * hoursToMostSignificantUnit(50) // '2 days'
 * hoursToMostSignificantUnit(840) // '5 months'
 * hoursToMostSignificantUnit(3) // '3 hours'
 */
export const hoursToMostSignificantUnit = (hoursWorked: number): string => {
	const units = [
		{ unit: 'year', duration: 2000 },
		{ unit: 'month', duration: 160 },
		{ unit: 'week', duration: 40 },
		{ unit: 'day', duration: 8 },
		{ unit: 'hour', duration: 1 },
	];
	for (const unit of units) {
		if (hoursWorked >= unit.duration) {
			const value = Math.floor(hoursWorked / unit.duration);
			return `${value} ${unit.unit}${value !== 1 ? 's' : ''}`;
		}
	}
	return '0 hours';
};
/** Takes two optional timestamps and returns a human readable represntation of them
 * Uses locale date formatting
 *
 * @example
 * humanReadableSpan(start, end) === '1/1/1970 - 31/12/1970'
 * humanReadableSpan(start, undefined) === 'From 1/1/1970'
 * humanReadableSpan(undefined, end) === 'Until 31/12/1970'
 */
export const humanReadableSpan = (
	startTimestamp?: firebase.firestore.Timestamp,
	endTimestamp?: firebase.firestore.Timestamp,
): string => {
	const end = endTimestamp ? formatSlashedDate(endTimestamp.toDate()) : '';
	const start = startTimestamp
		? formatSlashedDate(startTimestamp.toDate())
		: '';

	if (end && start) {
		return `${start} - ${end}`;
	} else if (end) {
		return `Until ${end}`;
	} else if (start) {
		return `From ${start}`;
	} else {
		return '';
	}
};

/** Takes a date and returns the week difference from the current time */
export const getWeekString = (date: Date): string => {
	const currentWeekStartDate = startOfWeek(new Date());

	const weeks = differenceInWeeks(startOfWeek(date), currentWeekStartDate);

	if (weeks === 0) {
		return 'This Week';
	} else if (weeks === -1) {
		return 'Last Week';
	} else if (weeks === 1) {
		return 'Next Week';
	} else if (weeks > 1) {
		return `in ${weeks} weeks`;
	} else if (weeks < -1) {
		return `${Math.abs(weeks)} weeks ago`;
	} else {
		return '';
	}
};

export const guardDate = (maybeDate: unknown): maybeDate is Date =>
	isDate(maybeDate);

/** Anywhere a `Date` is used in an an object, also allow a Timestamp and vice-versa */
export type AllowDateOrTimestamp<T> = ReplaceWithUnionValue<
	T,
	firebase.firestore.Timestamp,
	Date
>;

export const validateDateString = (dateString: string | null): Date | null => {
	if (!dateString) {
		return null;
	}
	const date = new Date(dateString);
	return isValid(date) ? date : null;
};

export const calculateDateWeekRange = (
	date: Date,
): { startDate: Date; endDate: Date } => {
	return {
		startDate: startOfWeek(date),
		endDate: endOfWeek(date),
	};
};

// Uses current 5 weeks span based on week endings i.e 3, 2, 1 weeks ago, this week, next week
export const dateWithinCurrentWeeksRange = (date: Date): boolean => {
	// https://date-fns.org/v3.6.0/docs/differenceInWeeks#types/RoundingMethod/1834
	type RoundingMethod = 'ceil' | 'floor' | 'round' | 'trunc';
	const roundingMethod: RoundingMethod = 'ceil';

	const currentWeekEndDate = endOfWeek(new Date());
	const weeks = differenceInWeeks(endOfWeek(date), currentWeekEndDate, {
		roundingMethod,
	});

	return weeks >= -3 && weeks <= 1;
};
