import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Stack,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AccountType, UserDetails } from '../../constants/Common';
import type { RecaptchaVerifier, User } from '../../firebase/firebase';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import {
	RecaptchaVerifierConstructor,
	RecaptchaVerifierContainer,
} from '../LoginAndSignUp/RecaptchaVerifierContainer';
import {
	PhoneNumberInput,
	validatePhoneNumber,
} from '../PhoneNumberInput/PhoneNumberInput';
import { MultiFactorDialogWrapper } from './MultiFactorDialogWrapper';
import {
	EmailNotificationToggle,
	EmailNotificationType,
	EmailNotificationSetting,
} from './NotificationToggle/EmailNotificationToggle';

type EditAccountDisplayType = Partial<Record<keyof UserDetails, string>>;
type MultiFactorState =
	| 'closed'
	| 'info'
	| 'enterCode'
	| 'signOut'
	| 'finish'
	| 'error';

type AccountDetailsFirebaseCalls =
	| 'getVersion'
	| 'uploadUserProfilePicture'
	| 'updateUserDetailsPhotoURL'
	| 'updateUserDetailsAccountInfo'
	| 'updatedUserDetailsDisabledNotifications';

const notificationSettings: {
	key: EmailNotificationType;
	setting: EmailNotificationSetting;
	accountTypes: AccountType[];
}[] = [
	{
		key: 'timesheetEmailReminders',
		setting: {
			name: 'Timesheet Email Reminders',
			description:
				'Receive email reminders at 8am on Mondays and Tuesdays if there are any unreviewed timesheets on your site.',
		},
		accountTypes: ['management', 'seniorManagement'],
	},
	{
		key: 'complianceReport',
		setting: {
			name: 'Compliance Report Notifications',
			description:
				'Receive email notifications on the first of each month when a new induction report for your site is generated.',
		},
		accountTypes: ['management', 'seniorManagement'],
	},
	{
		key: 'pendingApprovals',
		setting: {
			name: 'Pending Approvals',
			description:
				'Receive email reminders at 1pm daily if there are any pending accounts for your company.',
		},
		accountTypes: ['management', 'seniorManagement', 'handler'],
	},
];

const notificationAccountTypes: AccountType[] = [
	'management',
	'seniorManagement',
	'handler',
];

export type AccountDetailsProps = {
	userDetails: UserDetails;
	RecaptchaVerifierConstructor: RecaptchaVerifierConstructor;
	user: User | null;
	firebaseApi: Pick<FirebaseApi, AccountDetailsFirebaseCalls>;
};

export const AccountDetails = ({
	userDetails,
	RecaptchaVerifierConstructor,
	user,
	firebaseApi,
}: AccountDetailsProps): JSX.Element => {
	const theme = useTheme();

	const [accountDetails, setAccountDetails] =
		useState<UserDetails>(userDetails);
	const [isMounted, setIsMounted] = useState<boolean>(true);
	const [webVersion, setWebVersion] = useState<string>('');
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [errorMap, setErrorMap] = useState<EditAccountDisplayType>({});
	const [imageUpload, setImageUpload] = useState<Blob | null>(null);
	const [previewURL, setPreviewURL] = useState<string>('');
	const [multiFactorState, setMultiFactorState] =
		useState<MultiFactorState>('closed');
	const [hasMultiFactor, setHasMultiFactor] = useState(false);
	const [recaptchaVerifier, setRecaptchaVerifier] =
		useState<RecaptchaVerifier>();
	const [toggleState, setToggleState] = useState(
		userDetails.disabledNotifications ?? {},
	);

	const enableNotifications = notificationAccountTypes.includes(
		userDetails.accountType,
	);

	useEffect(() => {
		user?.getIdTokenResult().then((idToken) => {
			if (idToken.signInSecondFactor) {
				setHasMultiFactor(true);
			}
		});
	});

	// To avoid other state updates after leaving the tab
	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	useEffect(() => {
		const getVersion = async (): Promise<void> => {
			const versions = await firebaseApi.getVersion();
			setWebVersion(versions.webVersion);
		};
		getVersion();
	}, [firebaseApi]);

	useEffect(() => {
		if (Object.keys(toggleState).length === 0) {
			firebaseApi.updatedUserDetailsDisabledNotifications(
				userDetails.userID,
				{ disabledNotifications: undefined },
			);
		} else {
			firebaseApi.updatedUserDetailsDisabledNotifications(
				userDetails.userID,
				{ disabledNotifications: toggleState },
			);
		}
	}, [firebaseApi, toggleState, userDetails.userID]);

	const safeStateUpdate = (callback: () => void): void => {
		if (isMounted) {
			callback();
		}
	};

	const chooseNewPhoto = (item: Blob): void => {
		setImageUpload(item);
		setPreviewURL(URL.createObjectURL(item));
	};

	const uploadProfilePicture = async (): Promise<void> => {
		if (imageUpload) {
			try {
				const downloadURL = await firebaseApi.uploadUserProfilePicture(
					userDetails.userID,
					imageUpload,
				);
				await Promise.all([
					firebaseApi.updateUserDetailsPhotoURL(userDetails.userID, {
						photoURL: downloadURL,
					}),
					firebaseApi.updateUserDetailsPhotoURL(userDetails.userID, {
						photoURL: downloadURL,
					}),
				]);
			} catch (error) {
				if (error) {
					alert('Something went wrong. Please try again');
				}
			}
		}
	};

	const accountDisplayFields: EditAccountDisplayType = {
		firstname: 'First Name',
		lastname: 'Last Name',
		displayName: 'Display Name',
		mobileNumber: 'Phone Number',
	};

	const handleDetailChange = (
		value: string,
		key: keyof UserDetails,
	): void => {
		setAccountDetails({
			...accountDetails,
			[key]: value,
		});
		switch (key) {
			case 'mobileNumber':
				if (validatePhoneNumber(value)) {
					setErrorMap((prevState) => ({
						...prevState,
						[key]: undefined,
					}));
				} else {
					setErrorMap((prevState) => ({
						...prevState,
						[key]: 'Invalid Phone Number',
					}));
				}
				break;
			default:
				if (value !== '')
					setErrorMap((prevState) => ({
						...prevState,
						[key]: undefined,
					}));
				else {
					setErrorMap((prevState) => ({
						...prevState,
						[key]: `Invalid ${accountDisplayFields[key]}`,
					}));
				}
		}
	};

	const handleClose = (): void => {
		setIsEditing(false);
		setAccountDetails(userDetails);
		setErrorMap({});
		setImageUpload(null);
		setPreviewURL('');
	};

	const saveAccountDetails = async (): Promise<void> => {
		setSaving(true);
		await uploadProfilePicture();
		safeStateUpdate(() => setImageUpload(null));

		const isValid = !Object.values(errorMap).some(
			(value) => value !== undefined,
		);

		if (isValid) {
			const update = {
				firstname: accountDetails.firstname.trim(),
				lastname: accountDetails.lastname.trim(),
				displayName: accountDetails.displayName.trim(),
				mobileNumber: accountDetails.mobileNumber.trim(),
			};
			try {
				await firebaseApi.updateUserDetailsAccountInfo(
					userDetails.userID,
					update,
				);
			} catch (error) {
				if (error) {
					alert('Something went wrong. Please try again');
					setAccountDetails(userDetails);
				}
			}
			safeStateUpdate(() => setIsEditing(false));
		}
		safeStateUpdate(() => setSaving(false));
	};

	const updateNotificationSettings = (
		notificationName: EmailNotificationType,
	): void => {
		const updatedToggles = { ...toggleState };
		if (notificationName in toggleState) {
			delete updatedToggles[notificationName];
		} else {
			updatedToggles[notificationName] = true;
		}

		setToggleState(updatedToggles);
	};

	const accountDetailsInputs = ([key, value]: [
		keyof UserDetails,
		string,
	]): JSX.Element => {
		if (key === 'mobileNumber') {
			return (
				<Grid item xs={6} key={key}>
					<Typography variant="h6">{value}</Typography>
					<PhoneNumberInput
						value={accountDetails[key]}
						onChange={(event): void => {
							if (typeof event === 'string') {
								handleDetailChange(event, key);
							}
						}}
						helperText={errorMap[key]}
						label=""
						disabled={!isEditing || saving}
						InputProps={{
							endAdornment: isEditing && (
								<InputAdornment position="end">
									<EditIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			);
		} else
			return (
				<Grid item xs={6} key={key}>
					<Typography variant="h6">{value}</Typography>
					<TextField
						id="filled-basic"
						fullWidth
						value={accountDetails[key]}
						onChange={(event): void =>
							handleDetailChange(event.target.value, key)
						}
						disabled={!isEditing || saving}
						InputLabelProps={{
							shrink: true,
						}}
						error={errorMap[key] !== undefined}
						helperText={errorMap[key]}
						InputProps={{
							endAdornment: isEditing && (
								<InputAdornment position="end">
									<EditIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			);
	};

	return (
		<>
			<Card variant="outlined">
				<CardContent>
					<Stack divider={<Divider />} spacing={2}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Grid
									container
									direction="column"
									alignItems="center">
									<CardHeader
										title="Profile Picture"
										action={
											isEditing && (
												<IconButton
													disableRipple
													sx={{
														pointerEvents: 'none',
													}}>
													<EditIcon />
												</IconButton>
											)
										}
									/>
									<CardMedia
										component="img"
										image={
											imageUpload
												? previewURL
												: userDetails.photoURL
										}
										sx={{
											width: '30vh',
											height: '30vh',
											borderRadius: '100%',
											border: `solid ${theme.palette.primary.main}`,
											[theme.breakpoints.down('lg')]: {
												width: '20vh',
												height: '20vh',
											},
											[theme.breakpoints.down('md')]: {
												width: '15vh',
												height: '15vh',
											},
										}}
									/>
									{isEditing && (
										<Grid
											container
											spacing={2}
											justifyContent="center"
											mt={2}>
											<Grid item>
												<Button
													variant="contained"
													component="label"
													disabled={saving}>
													Choose File
													<input
														type="file"
														hidden
														onChange={(
															event: React.ChangeEvent<HTMLInputElement>,
														): void => {
															if (
																event.target
																	.files
															) {
																chooseNewPhoto(
																	event.target
																		.files[0],
																);
															}
														}}
													/>
												</Button>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item xs={8}>
								<Grid container spacing={2} mt={0}>
									{Object.entries(accountDisplayFields).map(
										([key, value]) =>
											accountDetailsInputs([
												key as keyof UserDetails,
												value,
											]),
									)}
									<Grid item xs={6}>
										<Typography variant="h6">
											Email
										</Typography>
										<OutlinedInput
											fullWidth
											value={accountDetails.email}
											disabled
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h6">
											Web Version
										</Typography>
										<OutlinedInput
											fullWidth
											value={webVersion}
											disabled
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<CardActions
									sx={{
										justifyContent: 'flex-end',
										pr: 0,
										pb: 1,
									}}>
									{hasMultiFactor ? (
										<Chip
											icon={<CheckCircleIcon />}
											color="success"
											label="You are enrolled for Multi-Factor
						Authentication"
										/>
									) : (
										<Button
											variant="contained"
											color="primary"
											onClick={(): void =>
												setMultiFactorState('info')
											}>
											Enrol for Multi-Factor
											Authentication
										</Button>
									)}
									{!isEditing ? (
										<Button
											variant="contained"
											color="primary"
											onClick={(): void => {
												setIsEditing(true);
											}}>
											Edit
										</Button>
									) : (
										<>
											<Button
												variant="outlined"
												disabled={saving}
												onClick={handleClose}>
												Cancel
											</Button>
											<LoadingButton
												variant="contained"
												color="primary"
												loading={saving}
												disabled={saving}
												onClick={saveAccountDetails}>
												Confirm
											</LoadingButton>
										</>
									)}
								</CardActions>
							</Grid>
						</Grid>
						{enableNotifications && (
							<Stack>
								<Typography variant="h5" pb={2}>
									Notifications
								</Typography>
								<Grid container spacing={2}>
									{notificationSettings
										.filter((setting) =>
											setting.accountTypes.includes(
												userDetails.accountType,
											),
										)
										.map(({ key, setting }) => (
											<Grid item key={key} sm={12} md={6}>
												<EmailNotificationToggle
													disabled={saving}
													toggled={
														!(key in toggleState)
													}
													toggle={(): void =>
														updateNotificationSettings(
															key,
														)
													}
													notification={setting}
												/>
											</Grid>
										))}
								</Grid>
							</Stack>
						)}
					</Stack>
				</CardContent>
			</Card>
			{recaptchaVerifier && (
				<MultiFactorDialogWrapper
					multiFactorState={multiFactorState}
					setMultiFactorState={setMultiFactorState}
					recaptchaVerifier={recaptchaVerifier}
					accountDetails={accountDetails}
				/>
			)}
			<RecaptchaVerifierContainer
				setVerifier={setRecaptchaVerifier}
				RecaptchaVerifierConstructor={RecaptchaVerifierConstructor}
			/>
		</>
	);
};
