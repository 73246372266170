import {
	Alert,
	Box,
	Button,
	Divider,
	Grid,
	Paper,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import {
	AccountType,
	AccountTypeHumanName,
	UserDetails,
} from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { EditUserDetails } from './EditUserDetails';

export const ManageUsers = (): JSX.Element => {
	const [users, setUsers] = useState<UserDetails[]>([]);
	const [filterByUserName, setFilterByUserName] = useState<string>('');
	const [filterByCompanyName, setFilterByCompanyName] = useState<string>('');
	const [filterBySiteName, setFilterBySiteName] = useState<string>('');
	const [selected, setSelected] = useState(0);
	const [applyFilter, setApplyFilter] = useState(false);
	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (applyFilter) {
			const queryUsers = async (): Promise<void> => {
				const usersList = await firebaseApi.customerSupportQueryUsers(
					filterByUserName,
					filterByCompanyName,
					filterBySiteName,
				);
				setUsers(usersList);
			};
			queryUsers();
			setApplyFilter(false);
		}
	}, [applyFilter, filterByCompanyName, filterBySiteName, filterByUserName]);

	const columns = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'accountType',
			label: 'Account',
			options: {
				customBodyRender: (value: AccountType): string => {
					return AccountTypeHumanName[value] ?? '-';
				},
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'company',
			label: 'Company',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'single',
		tableBodyHeight: 'calc(100vh - 360px)',
		elevation: 2,
		viewColumns: false,
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		download: false,
		print: false,
	};

	const handleApplyFilters = (name: string | undefined = undefined): void => {
		if (name) {
			setFilterByUserName(name);
			setFilterBySiteName('');
			setFilterByCompanyName('');
		}
		if (
			filterByCompanyName !== '' ||
			filterByUserName !== '' ||
			filterBySiteName !== ''
		) {
			setApplyFilter(true);
		} else {
			setOpen(true);
		}
	};

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const filtersValidation = (): JSX.Element => (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<Alert
				onClose={handleClose}
				severity="error"
				sx={{ width: '100%' }}
				color="error">
				Please enter User Name, Company or Site
			</Alert>
		</Snackbar>
	);

	return (
		<>
			{filtersValidation()}
			<Paper id="filter" sx={{ mb: 1, padding: 1 }}>
				<Grid
					container
					spacing={1}
					alignItems="center"
					textAlign="center">
					<Grid item xs={12} sm={4} md={4}>
						<TextField
							fullWidth
							label="User Name (case sensitive)"
							id="UserName"
							value={filterByUserName}
							onChange={(event): void =>
								setFilterByUserName(event.target.value)
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<TextField
							fullWidth
							label="Company Name (case sensitive)"
							id="ComanyName"
							value={filterByCompanyName}
							onChange={(event): void =>
								setFilterByCompanyName(event.target.value)
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={4}>
						<TextField
							value={filterBySiteName}
							fullWidth
							label="Site Name (case sensitive)"
							onChange={(event): void => {
								setFilterBySiteName(event.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => handleApplyFilters()}>
							Apply filters
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => {
								setFilterByUserName('');
								setFilterByCompanyName('');
								setFilterBySiteName('');
								setUsers([]);
							}}>
							Clear filters
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
				}}
				divider={
					<Divider
						sx={{ ml: 1, mr: 1 }}
						orientation="vertical"
						flexItem
					/>
				}
				spacing={2}>
				<Box flex="1">
					<DataTable
						tableData={users}
						columns={columns}
						title="Users in the system"
						selection={[selected, setSelected]}
						customTableOptions={tableOptions}
					/>
				</Box>
				<Box flex="1">
					{users[selected] ? (
						<EditUserDetails
							user={users[selected]}
							onSaveUserCallback={handleApplyFilters}
						/>
					) : (
						<Paper
							sx={{ padding: 1, height: 'calc(100vh - 245px)' }}>
							<Grid
								container
								height="100%"
								justifyContent="center"
								alignItems="center">
								<Grid item>
									<Typography textAlign="center" variant="h6">
										Search for a User using the filters
										above and select one to show their
										details here.
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					)}
				</Box>
			</Stack>
		</>
	);
};
