import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import { BaseIntegrationLink } from '../../../models/Integrations/IntegrationElements';
import { MappingTypes } from '../IntegrationPages/ActivityAndLeaveMapping/MappingTypes';

type ConfirmLinkDialogProps = {
	type: MappingTypes;
	mappingRemoved: boolean;
	singleMapping: Pick<BaseIntegrationLink, 'name' | 'integrationName'>;
	open: boolean;
	handleCancel: () => void;
	handleConfirm: () => void;
};

export const ConfirmLinkDialog = ({
	type,
	mappingRemoved,
	singleMapping,
	open,
	handleCancel,
	handleConfirm,
}: ConfirmLinkDialogProps): JSX.Element => {
	const { name, integrationName } = singleMapping;
	return (
		<Dialog onClose={handleCancel} open={open}>
			<DialogTitle>{`${
				mappingRemoved ? 'Remove' : 'Save'
			} ${type} Connection:`}</DialogTitle>
			<DialogContent>
				<Stack
					direction="row"
					spacing={1}
					justifyContent="space-around">
					<Typography>{name}</Typography>
					<CompareArrowsIcon fontSize="large" />
					<Typography>{integrationName}</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>
					Cancel
				</Button>
				<Button variant="contained" onClick={handleConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
