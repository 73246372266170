import { Box, Card } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { ProjectTrackingEmployee } from '../../../../cloudfunctions/projectTracking';
import { UserDetails } from '../../../../constants/Common';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	EmployeeLink,
	ProjectTrackingLinks,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { linkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { employeeIntegrationTableRowSearch } from '../../../Integrations/Components/Helpers/employeeIntegrationTableRowSearch';
import { IntegrationLinkButton } from '../../../Integrations/Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkCell,
	OptionWithDisabled,
} from '../../../Integrations/Components/IntegrationLinkCell/IntegrationLinkCell';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../../Integrations/Components/IntegrationTable/IntegrationTable';
import {
	EmployeeIntegrationTableRow,
	TempEmployeeLink,
	isEmployeeLink,
} from '../../Common/Employees/Models/employeeTab';

const getLinkStatus = (
	link: EmployeeLink | null,
	integrationEmployee: ProjectTrackingEmployee | null,
): IntegrationLinkStatus => {
	if (link === null) {
		return IntegrationLinkStatus.Unlinked;
	} else if (integrationEmployee === null) {
		return IntegrationLinkStatus.Missing;
	} else {
		return IntegrationLinkStatus.Linked;
	}
};

export type EmployeesTabProps = {
	firebaseApi: Pick<
		FirebaseApi,
		| 'subscribeNonKioskUsersByCompany'
		| 'projectTrackingIntegrationEmployeeLinksSubscription'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		| 'fetchProjectTrackingEmployeeIDs'
		| 'createProjectTrackingEmployeeLink'
		| 'deleteProjectTrackingEmployeeLink'
	>;
	userDetails: UserDetails;
	user: User;
};

export const EmployeesTab = ({
	firebaseApi,
	cloudFunctionApi,
	user,
	userDetails,
}: EmployeesTabProps): JSX.Element => {
	const abortSignal = useAbortController();

	const [employees, setEmployees] = useState<Record<string, UserDetails>>({});
	const [integrationEmployees, setIntegrationEmployees] = useState<
		Record<string, ProjectTrackingEmployee>
	>({});
	const [employeeLinks, setEmployeeLinks] = useState<
		Record<string, EmployeeLink>
	>({});
	const [newLinks, setNewLinks] = useState<Record<string, EmployeeLink>>({});
	const [tableData, setTableData] = useState<EmployeeIntegrationTableRow[]>(
		[],
	);
	const [selected, setSelected] = useState<string[]>([]);
	const [loading, setLoading] = useState({
		employees: true,
		employeeLinks: true,
		integrationEmployees: true,
	});
	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const cellWidthExtra = `${(100 / numCells) * 1.25}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	const fetchEmployeeIDs = useCallback(async (): Promise<void> => {
		setLoading((prev) => ({ ...prev, integrationEmployees: true }));
		const employeeIDs =
			await cloudFunctionApi.fetchProjectTrackingEmployeeIDs(
				abortSignal,
				user,
			);

		const integrationEmployeeRecord = employeeIDs.employees.reduce<
			Record<string, ProjectTrackingEmployee>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: current,
			}),
			{},
		);

		setIntegrationEmployees(integrationEmployeeRecord);
		setLoading((prev) => ({ ...prev, integrationEmployees: false }));
	}, [abortSignal, cloudFunctionApi, user]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, employees: true }));
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			(users) => {
				const employeeRecord = users.reduce<
					Record<string, UserDetails>
				>(
					(prev, current) => ({ ...prev, [current.userID]: current }),
					{},
				);

				setEmployees(employeeRecord);
				setLoading((prev) => ({ ...prev, employees: false }));
			},
		);
	}, [firebaseApi, userDetails]);

	useEffect(() => {
		fetchEmployeeIDs();
	}, [fetchEmployeeIDs]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, employeeLinks: true }));
		return firebaseApi.projectTrackingIntegrationEmployeeLinksSubscription(
			userDetails.companyID,
			(links) => {
				setEmployeeLinks(links);
				setLoading((prev) => ({ ...prev, employeeLinks: false }));
			},
		);
	}, [firebaseApi, userDetails]);

	useEffect(() => {
		const tableData = Object.values(employees).map((employee) =>
			mapTableData(
				employee,
				employeeLinks,
				newLinks,
				integrationEmployees,
			),
		);

		const selectedData: string[] = [];
		tableData.forEach((row) => {
			if (isEmployeeLink(row.account)) {
				if (row.account.integrationID) {
					selectedData.push(row.account.integrationID);
				}
			}
		});

		setTableData(tableData);
		setSelected(selectedData);
	}, [employeeLinks, employees, integrationEmployees, newLinks]);

	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const mapTableData = (
		employee: UserDetails,
		links: Record<string, EmployeeLink>,
		newLinks: Record<string, EmployeeLink>,
		integrationEmployees: Record<string, ProjectTrackingEmployee>,
	): EmployeeIntegrationTableRow => {
		const link: EmployeeLink | null = links[employee.userID] ?? null;
		const integrationEmployee =
			integrationEmployees[link?.integrationID] ?? null;

		const status = getLinkStatus(link, integrationEmployee);

		return {
			id: employee.userID,
			name: employee.displayName,
			status: status,
			account: link ??
				newLinks[employee.userID] ?? { id: employee.userID },
		};
	};

	const createLink = async (link: EmployeeLink): Promise<void> => {
		await cloudFunctionApi.createProjectTrackingEmployeeLink(
			abortSignal,
			user,
			link,
		);
	};

	const deleteLink = async (link: EmployeeLink): Promise<void> => {
		await cloudFunctionApi.deleteProjectTrackingEmployeeLink(
			abortSignal,
			user,
			link,
		);
	};

	const triggerRefresh = async (): Promise<void> => await fetchEmployeeIDs();

	const onLinkCellChange =
		(
			employeeLink: TempEmployeeLink,
			employee: UserDetails,
			options: Record<string, OptionWithDisabled>,
		) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setNewLinks((prev) => {
					const { [employeeLink.id]: _, ...remaining } = prev;
					return remaining;
				});
				return;
			}

			const integrationEmployee = options[integrationID];

			if (integrationEmployee === undefined) {
				return;
			}

			setNewLinks((prev) => ({
				...prev,
				[employeeLink.id]: {
					id: employeeLink.id,
					name: employee.displayName,
					integrationID: integrationID,
					integrationName: integrationEmployee.name,
					type: ProjectTrackingLinks.Employee,
				},
			}));
		};

	const renderOptionButton = (employeeID: string): JSX.Element => {
		const link: EmployeeLink | undefined = employeeLinks[employeeID];
		const newLink: EmployeeLink | undefined = newLinks[employeeID];

		const handleCreateLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [employeeID]: true }));
			await createLink(newLink);
			setNewLinks((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
		};
		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [employeeID]: true }));
			await deleteLink(link);
			setLinkingRecords((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled = !link && newLink === undefined;

		return (
			<IntegrationLinkButton
				link={link}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const renderStatusChip = (status: IntegrationLinkStatus): JSX.Element => (
		<IntegrationStatusChip status={status} />
	);

	const renderAccount = (employeeLink: TempEmployeeLink): JSX.Element => {
		const trueLink = employeeLinks[employeeLink.id];
		const employee = employees[employeeLink.id];
		const options = Object.values(integrationEmployees).reduce<
			Record<string, OptionWithDisabled>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: {
					id: current.id,
					name: current.name,
					disabled: selected.some((id) => id === current.id),
				},
			}),
			{},
		);

		const onChange = onLinkCellChange(employeeLink, employee, options);
		const linking = !!linkingRecords[employeeLink.id];

		return (
			<IntegrationLinkCell
				link={trueLink}
				linkIntegrationID={
					isEmployeeLink(employeeLink)
						? employeeLink.integrationID
						: undefined
				}
				options={options}
				onChange={onChange}
				missingLinkLabel="Missing Workbench Account"
				missingLinkMessage="Linked item not found in Workbench"
				disabled={linking}
				selectOverride="Select Account"
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						{renderStatusChip(value)}
					</Box>
				),
			},
		},
		{
			name: 'account',
			label: 'Workbench Account',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
				sortCompare: linkTableSort,
				customBodyRender: (employeeLink: EmployeeLink) => (
					<Box display="flex" justifyContent="center">
						{renderAccount(employeeLink)}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	return (
		<Box flex="1">
			<Card>
				<IntegrationTable
					title="Link Employees"
					data={tableData}
					columns={columns}
					triggerRefresh={triggerRefresh}
					loading={
						loading.employeeLinks ||
						loading.employees ||
						loading.integrationEmployees
					}
					missingDataLabel="Sorry, no employees found"
					centeredColumns={[2, 3, 4]}
					customSearch={employeeIntegrationTableRowSearch}
				/>
			</Card>
		</Box>
	);
};
