import { isEqual } from 'lodash';
import { SetStateAction } from 'react';
import { ValueOf } from '../../../../constants/TypescriptUtilities';
import { TimesheetsActions, TimesheetsContext } from './models';

export const createReducer = (
	state: TimesheetsContext,
	action: TimesheetsActions,
): TimesheetsContext => {
	const setter = <T extends ValueOf<TimesheetsContext>>(
		value: SetStateAction<T>,
		prev: T,
	): T => (typeof value === 'function' ? value(prev) : value);

	if (isEqual(action.value, state[action.key])) return state;

	switch (action.key) {
		case 'timesheetID': {
			const selected = setter(action.value, state[action.key]);
			return {
				...state,
				timesheetID: selected,
			};
		}
		case 'loading': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'endDate': {
			const endDate = setter(action.value, state[action.key]);
			return {
				...state,
				[action.key]: endDate,
			};
		}
		case 'startDate': {
			const startDate = setter(action.value, state[action.key]);
			return {
				...state,
				[action.key]: startDate,
			};
		}
		case 'timesheetData': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'sites': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'companies': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'users': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
	}
};
