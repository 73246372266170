import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Chip, Tooltip } from '@mui/material';

export type ComplianceReportStatusChipProps = {
	rate: number;
};

export const ComplianceReportStatusChip = ({
	rate,
}: ComplianceReportStatusChipProps): JSX.Element => {
	const ratePercentage = Math.floor(rate * 100);
	const rateString = ratePercentage.toString() + '%';
	const chipStyle = {
		fontWeight: 'bold',
		justifyContent: 'flex-start',
		width: '102px',
		'& .MuiChip-labelSmall': {
			textAlign: 'center',
			width: '100%',
		},
	};

	if (rate == 1) {
		return (
			<ComplianceRateToolTip rateString={rateString}>
				<Chip
					icon={<CheckCircleIcon />}
					label={rateString}
					size="small"
					color="success"
					sx={chipStyle}
				/>
			</ComplianceRateToolTip>
		);
	} else if (rate < 1 && rate >= 0) {
		return (
			<ComplianceRateToolTip rateString={rateString}>
				<Chip
					icon={<CheckCircleIcon />}
					label={rateString}
					size="small"
					color="neutral"
					sx={chipStyle}
				/>
			</ComplianceRateToolTip>
		);
	} else if (rate === 0) {
		return (
			<ComplianceRateToolTip rateString={rateString}>
				<Chip
					icon={<ErrorIcon />}
					label={rateString}
					size="small"
					color="error"
					sx={chipStyle}
				/>
			</ComplianceRateToolTip>
		);
	} else {
		return (
			<ComplianceRateToolTip rateString="Invalid">
				<Chip
					icon={<ErrorIcon />}
					label="Invalid Rate"
					size="small"
					color="error"
					sx={chipStyle}
				/>
			</ComplianceRateToolTip>
		);
	}
};

export const ComplianceRateToolTip = ({
	rateString,
	children,
}: {
	rateString: string;
	children: JSX.Element;
}): JSX.Element => (
	<Tooltip title={`${rateString} compliant`}>{children}</Tooltip>
);
