import firebase from 'firebase';
import { AllInvoicingContractsResponse } from '../models/Integrations/IntegrationElements';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchInvoicingContractIDs = async (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<AllInvoicingContractsResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<AllInvoicingContractsResponse>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.InvoicingContractIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		},
	);
};
