import { Box, Typography } from '@mui/material';
import React from 'react';

type ViewTemplateHeaderProps = {
	name: string;
	companyName: string;
};

export const ViewTemplateHeader = ({
	name,
	companyName,
}: ViewTemplateHeaderProps): JSX.Element => {
	return (
		<Box>
			<Typography variant="h3Bold">{name}</Typography>
			<Typography variant="body1" color="neutral.main">
				{companyName}
			</Typography>
		</Box>
	);
};
