import { Firestore } from './firebase';

// Firestore only allows 10 items per in-query
const MAX_QUERY_CHUNK = 10;

/**
 * Get Firestore documents by their IDs chunked to batches of 10
 * @private Internal function for use within firebaseApi files
 *
 * @param ids Array of Firestore document IDs
 * @param path Firestore collection path
 * @param uniqueKey Unique key should match the document field of the DocumentID. Usually is just `'id'`.
 */
export const getFirestoreDocsByID = async <
	ID extends Extract<keyof Type, string>,
	Type extends { [key in ID]: string },
>(
	ids: string[],
	path: string,
	uniqueKey: ID,
): Promise<Record<string, Type>> => {
	// don't run if there aren't any ids or a path for the collection
	if (!ids || !ids.length || !path) return {};

	const chunkRequest = async (currentChunk: string[]): Promise<Type[]> => {
		const querySnapshot = await Firestore.collection(path)
			.where('__name__', 'in', currentChunk)
			.get();
		return querySnapshot.docs.map(
			(doc) =>
				({
					...doc.data(),
					[uniqueKey]: doc.id,
				} as Type),
		);
	};

	const chunks: Promise<Type[]>[] = [];
	while (ids.length) {
		const currentChunk = ids.splice(0, MAX_QUERY_CHUNK);
		// add the chunk request to the a queue
		chunks.push(chunkRequest(currentChunk));
	}

	const data = await Promise.all(chunks);
	const fetchedFirestoreData = data
		.flat()
		.reduce<Record<string, Type>>((allData, item) => {
			allData[item[uniqueKey]] = item;
			return allData;
		}, {});

	return fetchedFirestoreData;
};
