import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import cloudFunctionApi from '../../../cloudfunctions';
import { Company, UserProps } from '../../../constants/Common';
import firebaseApi from '../../../firebase/firebaseApi';
import {
	ProjectTrackingIntegration,
	projectTrackingType,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import {
	CATProjectsConfig,
	isCATProjectsAccountType,
} from './CATProjectsConstants';
import { CATProjectsSetup } from './CATProjectsSetup';
import { CATProjectsTabs } from './CATProjectsTabs';

const PATH = '/project-tracking/catprojects';

export const PageWrapper = ({
	user,
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const navigate = useNavigate();
	const pathMatch = useMatch(`${PATH}/:tab`);
	const location = useLocation();

	const [loading, setLoading] = useState<boolean>();
	const [companyIntegrated, setCompanyIntegrated] =
		useState<Company['projectTrackingIntegrated']>();
	const [integration, setIntegration] =
		useState<ProjectTrackingIntegration | null>(null);

	useEffect(() => {
		return firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				if (company !== undefined) {
					const integrated = company.projectTrackingIntegrated;
					setCompanyIntegrated(integrated);
					setLoading(false);
					if (!integrated && pathMatch?.params?.tab !== undefined) {
						// Not integrated should be in the project-tracking home url to get setup
						if (location.pathname !== PATH) {
							navigate(`${PATH}`);
						}
					} else if (
						integrated &&
						integrated === projectTrackingType.CATProjects &&
						pathMatch?.params?.tab === undefined
					) {
						const defaultTab = isCATProjectsAccountType(
							userDetails.accountType,
						)
							? CATProjectsConfig[userDetails.accountType]
									.titles[0]
							: 'sites';
						navigate(`${PATH}/${defaultTab}`);
					}
				} else {
					// Need an overall web catchall page so I can throw this and be taken to a 'sorry' page so instead enjoy dashboard
					navigate('/');
					throw new Error('Missing Company For Project Tracking');
				}
			},
		);
	}, [
		location.pathname,
		navigate,
		pathMatch?.params?.tab,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		if (!userDetails.companyID) return;

		return firebaseApi.projectTrackingIntegrationSubscription(
			userDetails.companyID,
			(integration) => {
				setIntegration(integration);
			},
		);
	}, [userDetails.companyID]);

	return (
		<Box>
			{!user || loading ? (
				<LoadingDots />
			) : companyIntegrated && integration && pathMatch?.params?.tab ? (
				<>
					<CATProjectsTabs
						user={user}
						userDetails={userDetails}
						integration={integration}
						currentTab={pathMatch.params.tab}
						cloudFunctionApi={cloudFunctionApi}
					/>
					<Outlet />
				</>
			) : (
				<CATProjectsSetup
					cloudFunctionApi={cloudFunctionApi}
					firebaseApi={firebaseApi}
				/>
			)}
		</Box>
	);
};
