import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { useIsComponentMounted } from '../../../hooks/useIsComponentMounted';
import {
	ProjectTrackingIntegration,
	projectTrackingType,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../providers/UserProvider';
import { IntegrationSetupDialog } from '../../Integrations/Components/IntegrationSetupDialog/IntegrationSetupDialog';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';

export type CATProjectsSetupProps = {
	firebaseApi: Pick<
		FirebaseApi,
		'projectTrackingIntegrationSubscription' | 'companySubscription'
	>;
	cloudFunctionApi: Pick<CloudFunctionApi, 'fetchProjectTrackingTokens'>;
};

export const CATProjectsSetup = ({
	firebaseApi,
	cloudFunctionApi,
}: CATProjectsSetupProps): JSX.Element => {
	const navigate = useNavigate();
	const user = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const abortSignal = useAbortController();
	const isMounted = useIsComponentMounted();

	const [loading, setLoading] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);

	const [integration, setIntegration] =
		useState<ProjectTrackingIntegration | null>(null);

	const integrationType = projectTrackingType.CATProjects;
	const setupOpen = !authenticated;

	const submitIntegrationKey = useCallback(async () => {
		if (user === null) return;
		setLoading(true);

		const response = await cloudFunctionApi.fetchProjectTrackingTokens(
			abortSignal,
			user,
			integrationType,
		);

		if (isMounted) {
			if (!response) {
				navigate('/project-tracking', {
					state: {
						snackbar: {
							severity: 'error',
							message: 'Could not create CATProjects integration',
						},
					},
				});
			} else {
				setAuthenticated(true);
			}
			setLoading(false);
		}
	}, [
		abortSignal,
		cloudFunctionApi,
		integrationType,
		isMounted,
		navigate,
		user,
	]);

	const handleCancel = useCallback(() => {
		navigate('/project-tracking', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	useEffect(() => {
		if (userDetails === null) return;

		setLoading(true);
		const sub = firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				setLoading(false);

				if (company.projectTrackingIntegrated === integrationType) {
					setAuthenticated(true);
				} else if (company.projectTrackingIntegrated) {
					// already integrated with another system
					handleCancel();
				} else {
					// no longer available
					setAuthenticated(false);
				}
			},
		);

		return sub;
	}, [firebaseApi, handleCancel, integrationType, userDetails]);

	useEffect(() => {
		if (userDetails === null || !authenticated) return;

		return firebaseApi.projectTrackingIntegrationSubscription(
			userDetails.companyID,
			(integration) => {
				setIntegration(integration);
			},
		);
	}, [authenticated, firebaseApi, userDetails]);

	const handleAuthenticate = async (): Promise<void> => {
		await submitIntegrationKey();
	};

	const setupDialog = (
		<IntegrationSetupDialog
			open={setupOpen}
			integrationType={integrationType}
			renderContent={(): JSX.Element => (
				<>
					Confirm that you give Trade Legion permission to use your
					{integrationType} connection.
				</>
			)}
			handleCancel={handleCancel}
			handleAuthenticate={handleAuthenticate}
		/>
	);

	return (
		<Box>
			{loading || user === null || userDetails === null ? (
				<LoadingDots />
			) : (
				<>
					{setupDialog}
					{authenticated && integration === null ? (
						<LoadingDots />
					) : null}
				</>
			)}
		</Box>
	);
};
