import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	MenuItem,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import {
	AccountsAccountType,
	ManageSignUpsAccount,
	accountApprovalsMap,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	Company,
	Site,
	UserDetails,
	WorkerType,
	accountTypes,
	assertAccountType,
	emptyFunction,
	workerTypes,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { CompanyAutoComplete } from '../Autocomplete/CompanyAutocomplete';
import { SitesAutocomplete } from '../Autocomplete/SitesAutocomplete';
import { sortStringArray } from '../helpers/sortHelpers';
import { accountApproveValidation } from './ApproveAccountsValidation';

export type ApproveAccountDialogProps = {
	userDetails: UserDetails;
	closeDialog: () => void;
	dialogOpen: boolean;
	userForApproval: ManageSignUpsAccount;
	companies: Record<string, Company>;
	sites: Record<string, Site>;
	approveUser: (
		newAccountInfo: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'contractedTo'
			| 'company'
			| 'companyID'
		>,
	) => void;
	firebaseApi: Pick<FirebaseApi, 'companiesSubscriptionByType'>;
};

export const ApproveAccountDialog = ({
	userDetails,
	closeDialog,
	dialogOpen,
	userForApproval,
	sites,
	companies,
	approveUser,
	firebaseApi,
}: ApproveAccountDialogProps): JSX.Element => {
	const [isSelectingAccountType, setIsSelectingAccountType] = useState(true);
	const [accountType, setAccountType] = useState<AccountType>('');
	const [workerType, setWorkerType] = useState<WorkerType>('Not Selected');
	const [accountError, setAccountError] = useState('');
	const [workerError, setWorkerError] = useState('');
	const [siteError, setSiteError] = useState('');
	const [siteID, setSiteID] = useState<string>(userForApproval.siteID ?? '');
	const [isWorker, setIsWorker] = useState(false);
	const [contractedToID, setContractedToID] = useState<string>(
		userForApproval.contractedTo?.id || '',
	);
	const [contractedToError, setContractedToError] = useState('');

	assertAccountType<AccountsAccountType>(
		userDetails.accountType,
		accountsAccountType,
	);
	const SelectableAccountTypes = accountApprovalsMap[userDetails.accountType];

	const confirmOneStepAccountTypes: AccountType[] = [
		accountTypes.handler,
		accountTypes.cx,
	];
	const confirmOneStep = confirmOneStepAccountTypes.includes(accountType);
	const userCompany = {
		name: userDetails.company,
		id: userDetails.companyID,
	};

	const handleNext = (): void => {
		if (accountType === '') {
			setAccountError('Please select an account type');
		} else {
			setIsSelectingAccountType(false);
			setAccountError('');
		}
	};

	const handleBack = (): void => {
		setIsSelectingAccountType(true);
		setSiteError('');
		setWorkerError('');
		setAccountError('');
		setSiteError('');
		setContractedToError('');
	};

	const selectAccountType = (newAccountType: AccountType): void => {
		setAccountType(newAccountType);
		setIsWorker(true);
		setAccountError('');
	};

	const selectWorkerType = (newWorkerType: WorkerType): void => {
		setWorkerType(newWorkerType);
		setWorkerError('');
	};

	const handleClose = (): void => {
		setIsSelectingAccountType(true);
		setAccountError('');
		setSiteError('');
		setWorkerError('');
		setAccountType('');
		setSiteID('');
		setContractedToError('');
		setContractedToID('');
		setWorkerType('Not Selected');
		closeDialog();
	};

	const setError = (
		errorField: 'workerTypeError' | 'siteError' | 'contractedToError',
		errorMessage: string,
	): void => {
		switch (errorField) {
			case 'workerTypeError':
				setWorkerError(errorMessage);
				break;
			case 'siteError':
				setSiteError(errorMessage);
				break;
			case 'contractedToError':
				setContractedToError(errorMessage);
				break;
		}
	};

	const handleApprove = (
		newAccountInfo: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'contractedTo'
			| 'company'
			| 'companyID'
		>,
	): void => {
		approveUser(newAccountInfo);
		handleClose();
	};

	const validateManagement = (): void => {
		accountApproveValidation(
			accountType,
			workerType,
			sites[siteID],
			userCompany,
			userCompany,
			setError,
			handleApprove,
		)
			.validateSite(siteID)
			.finalize();
	};

	const validateThirdPartyWorker = (): void => {
		const contractedTo = companies[contractedToID]
			? {
					name: companies[contractedToID].name,
					id: companies[contractedToID].id,
			  }
			: null;
		accountApproveValidation(
			accountType,
			workerType,
			sites[siteID],
			userCompany,
			contractedTo,
			setError,
			handleApprove,
		)
			.validateContractedTo(companies[contractedToID], sites[siteID])
			.finalize();
	};

	const validateWorker = (): void => {
		accountApproveValidation(
			accountType,
			workerType,
			sites[siteID],
			userCompany,
			userCompany,
			setError,
			handleApprove,
		).finalize();
	};

	const validateCX = (): void => {
		accountApproveValidation(
			accountType,
			workerType,
			{
				name: '',
				id: '',
				company: '',
				companyID: '',
			},
			{
				name: '',
				id: '',
			},
			null,
			setError,
			handleApprove,
		).finalize();
	};

	const handleNoRequiredFields = (): void => {
		accountApproveValidation(
			accountType,
			workerType,
			sites[siteID],
			userCompany,
			userCompany,
			setError,
			handleApprove,
		).finalize();
	};

	const accountTypeValidations: Record<AccountType, () => void> = {
		'': emptyFunction,
		handler: handleNoRequiredFields,
		management: validateManagement,
		thirdPartyWorker: validateThirdPartyWorker,
		cx: validateCX,
		seniorManagement: handleNoRequiredFields,
		worker: validateWorker,
	};

	const WorkerTypeInput = (
		<TextField
			disabled={!isWorker}
			value={workerType}
			fullWidth
			label="Worker Type"
			onChange={(event): void => {
				selectWorkerType(event.target.value as WorkerType);
				setWorkerError('');
			}}
			select
			error={workerError !== ''}
			helperText={workerError}>
			{[
				// Show 'Not selected' as the first choice
				'Not Selected',
				...sortStringArray(
					workerTypes.filter((type) => type !== 'Not Selected'),
				),
			].map((workerType) => (
				<MenuItem key={workerType} value={workerType}>
					{workerType}
				</MenuItem>
			))}
		</TextField>
	);

	const SiteInput = (
		<SitesAutocomplete
			label={`Site${
				accountType === accountTypes.management ? '' : ' (optional)'
			}`}
			value={sites[siteID] ? siteID : null}
			onChange={(siteID): void => {
				setSiteError('');
				setContractedToError('');
				setSiteID(siteID);
			}}
			sites={sites}
			error={siteError !== ''}
			helperText={siteError}
		/>
	);

	const ContractedToInput = (
		<CompanyAutoComplete
			value={companies[contractedToID] ? contractedToID : null}
			label="Contracted To (optional)"
			recentClientIDs={
				userForApproval.recentClients?.map((item) => item.id) ?? []
			}
			onChange={(contractedToCompany): void => {
				setContractedToError('');
				setSiteError('');
				setContractedToID(contractedToCompany?.id ?? '');
			}}
			error={contractedToError !== ''}
			helperText={contractedToError}
			disabled={false}
			firebaseApi={firebaseApi}
		/>
	);

	const renderAccountTypeInputs = (): JSX.Element => {
		switch (accountType) {
			case accountTypes.worker:
				return (
					<>
						<ListItem>{WorkerTypeInput}</ListItem>
						<ListItem>{SiteInput}</ListItem>
					</>
				);
			case accountTypes.thirdPartyWorker:
				return (
					<>
						<ListItem>{WorkerTypeInput}</ListItem>
						<ListItem>{ContractedToInput}</ListItem>
						<ListItem>{SiteInput}</ListItem>
					</>
				);
			case accountTypes.management:
			case accountTypes.seniorManagement:
				return <ListItem>{SiteInput}</ListItem>;
			default:
				return <></>;
		}
	};

	return (
		<Dialog onClose={handleClose} open={dialogOpen} fullWidth>
			<DialogTitle>
				Select Account Type{isWorker ? ' and Worker Type' : ''}:
			</DialogTitle>
			<DialogContent>
				<List>
					{isSelectingAccountType ? (
						<ListItem>
							<TextField
								value={accountType}
								fullWidth
								label="Account Type"
								onChange={(event): void => {
									selectAccountType(
										event.target.value as AccountType,
									);
								}}
								select
								error={accountError !== ''}
								helperText={accountError}>
								{SelectableAccountTypes.map((option) => (
									<MenuItem key={option} value={option}>
										{AccountTypeHumanName[option]}
									</MenuItem>
								))}
							</TextField>
						</ListItem>
					) : (
						renderAccountTypeInputs()
					)}
				</List>
			</DialogContent>
			<DialogActions>
				{isSelectingAccountType ? (
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
				) : (
					<Button variant="outlined" onClick={handleBack}>
						Back
					</Button>
				)}
				{isSelectingAccountType && !confirmOneStep ? (
					<Button variant="contained" onClick={handleNext}>
						Next
					</Button>
				) : (
					<Button
						variant="contained"
						onClick={accountTypeValidations[accountType]}>
						Confirm
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
