import { useEffect } from 'react';
import { AnalyticsContext, AnalyticsService } from './context';

type AnalyticsProviderProps = {
	children: React.ReactNode;
	analyticsService: AnalyticsService;
};

export const AnalyticsProvider = ({
	children,
	analyticsService,
}: AnalyticsProviderProps): JSX.Element => {
	useEffect(() => {
		analyticsService.setup();
	}, [analyticsService]);

	return (
		<AnalyticsContext.Provider value={analyticsService}>
			{children}
		</AnalyticsContext.Provider>
	);
};
