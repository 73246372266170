import ClearIcon from '@mui/icons-material/Clear';
import {
	Box,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
} from '@mui/material';
import { Note } from '../../../../../../constants/Note';
import { removeInputPadding } from '../../../../../helpers/textFieldHelpers';
import { NoteHeading } from './NoteHeading';

export type InputNoteProps = {
	note: Pick<Note, 'user' | 'note'>;
	onNoteChange: (note: string) => void;
	disabled: boolean;
};

export const InputNote = ({
	note,
	onNoteChange,
	disabled,
}: InputNoteProps): JSX.Element => {
	const handleMouseDown = (
		event: React.MouseEvent<HTMLButtonElement>,
	): void => {
		event.preventDefault();
	};
	return (
		<Stack>
			<NoteHeading name={note.user.name} />
			<TextField
				data-testid="add-note-input"
				size="small"
				fullWidth
				multiline
				maxRows={3}
				disabled={disabled}
				placeholder="Add a note"
				value={note.note}
				onChange={(event): void => {
					onNoteChange(event.target.value);
				}}
				InputProps={{
					inputProps: {
						sx: removeInputPadding,
					},
					sx: { padding: 0 },
					endAdornment:
						note.note.length > 0 ? (
							<InputAdornment
								position="end"
								sx={{
									flexDirection: 'column',
									alignSelf: 'baseline',
									paddingRight: 0.25,
								}}>
								<Tooltip title="Clear Note">
									<Box>
										<IconButton
											disabled={disabled}
											aria-label="clear note"
											onClick={(): void =>
												onNoteChange('')
											}
											onMouseDown={handleMouseDown}
											size="small"
											color="primary">
											<ClearIcon fontSize="small" />
										</IconButton>
									</Box>
								</Tooltip>
							</InputAdornment>
						) : (
							<></>
						),
				}}
			/>
		</Stack>
	);
};
