import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { Document } from '../../../constants/Document';
import { ViewDocumentBody } from './ViewDocumentBody';
import { ViewDocumentHeader } from './ViewDocumentHeader';

type ViewDocumentDialogProps = {
	open: boolean;
	document: Document;
	handleOnClose: () => void;
	onPDF: (id: string) => void;
};

export const ViewDocumentDialog = ({
	open,
	document,
	handleOnClose,
	onPDF,
}: ViewDocumentDialogProps): JSX.Element => {
	const handleOnPDF = (): void => {
		onPDF(document.id);
	};

	return (
		<Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="xl">
			<DialogTitle>
				<Grid
					container
					direction={{
						xs: 'column-reverse',
						sm: 'column-reverse',
						md: 'row',
					}}>
					<Grid item sm={12} md={10}>
						<ViewDocumentHeader
							name={document.name}
							companyName={document.company.name}
							employeeName={document.employee.name}
							signedDate={
								document.signedAt
									? document.signedAt.toDate()
									: undefined
							}
						/>
					</Grid>
					<Grid item sm={12} md={2}>
						<Stack
							spacing={1}
							direction="row"
							display="flex"
							justifyContent="flex-end">
							<Tooltip title="Export PDF">
								<IconButton
									color="primary"
									onClick={handleOnPDF}>
									<PictureAsPdfIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Close">
								<IconButton onClick={handleOnClose}>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Stack>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<ViewDocumentBody document={document} />
			</DialogContent>
		</Dialog>
	);
};
