import CloseIcon from '@mui/icons-material/Close';
import {
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { MinimalInterface } from '../../../constants/Common';
import { useDebounce } from '../../../hooks/useDebounce';

export type OptionWithDisabled = MinimalInterface & {
	disabled: boolean;
};

type IntegrationLink = {
	integrationID: string;
	integrationName: string;
};

export type IntegrationTextLinkCellProps = {
	link?: IntegrationLink;
	linkIntegrationID?: string;
	onChange: (integrationID?: string) => void;
	disabled?: boolean;
};

export const IntegrationTextLinkCell = ({
	link,
	linkIntegrationID,
	onChange,
	disabled,
}: IntegrationTextLinkCellProps): JSX.Element => {
	const debouncedOnChange = useDebounce({
		callback: (val: string): void => onChange(val),
	});

	const [tempLink, setTempLink] = useState<string>('');
	const handleOnChange = (
		event?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	): void => {
		const value = event ? event.target.value : '';

		setTempLink(value);
		debouncedOnChange(value);
	};

	const handleOnClear = (): void => {
		handleOnChange();
	};

	if (link !== undefined) {
		return <Typography>{link.integrationName}</Typography>;
	} else {
		return (
			<TextField
				value={tempLink ?? linkIntegrationID ?? ''}
				onChange={handleOnChange}
				size="small"
				fullWidth
				disabled={disabled}
				InputProps={{
					endAdornment: !!linkIntegrationID && (
						<InputAdornment position="end">
							<IconButton
								size="small"
								onClick={handleOnClear}
								disabled={disabled}>
								<Tooltip title="Clear Selection">
									<CloseIcon fontSize="small" />
								</Tooltip>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		);
	}
};
