import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
	Button,
	DialogActions,
	IconButton,
	Stack,
	SxProps,
	TextField,
	TextFieldProps,
	Theme,
	Typography,
} from '@mui/material';
import {
	addWeeks,
	endOfWeek,
	nextMonday,
	nextSunday,
	previousMonday,
	previousSunday,
	startOfWeek,
} from 'date-fns';
import { useContext } from 'react';
import {
	DatePicker,
	PickersActionBarProps,
	WrapperVariantContext,
	useLocaleText,
} from '../../providers/LocalizationProvider';
import {
	SLASH_DATE_FORMAT,
	formatSlashedDate,
} from '../helpers/dateFormatters';
import { getWeekString } from '../helpers/dateUtilities';

export type DateWeekSelectorProps = {
	date: Date;
	onChange: (date: Date | null) => void;
	textFieldProps?: Partial<TextFieldProps>;
	allowFuture?: boolean;
	disabled?: boolean;
	showWeekLabel?: boolean;
	showWeekRange?: boolean;
	showWeekChangeArrows?: boolean;
	dateLimits?: {
		minDate: Date;
		maxDate: Date;
	};
	wrapperStyles?: SxProps<Theme>;
	weekEnding?: boolean;
};

type CustomActionBarProps = PickersActionBarProps & {
	onSetLastWeek: () => void;
};

const CustomActionBar = (props: CustomActionBarProps): JSX.Element => {
	const { onAccept, onClear, onCancel, onSetToday, actions, onSetLastWeek } =
		props;
	const wrapperVariant = useContext(WrapperVariantContext);
	const localeText = useLocaleText();

	const actionsArray =
		typeof actions === 'function' ? actions(wrapperVariant) : actions;

	const buttons = actionsArray?.map((actionType) => {
		switch (actionType) {
			case 'clear':
				return (
					<Button
						data-mui-test="clear-action-button"
						onClick={onClear}
						key={actionType}>
						{localeText.clearButtonLabel}
					</Button>
				);
			case 'cancel':
				return (
					<Button onClick={onCancel} key={actionType}>
						{localeText.cancelButtonLabel}
					</Button>
				);
			case 'accept':
				return (
					<Button onClick={onAccept} key={actionType}>
						{localeText.okButtonLabel}
					</Button>
				);
			case 'today':
				return (
					<Button
						data-mui-test="today-action-button"
						onClick={onSetToday}
						key={actionType}>
						{localeText.todayButtonLabel}
					</Button>
				);
			default:
				return null;
		}
	});

	return (
		<DialogActions>
			<Button
				id="picker-actions-today"
				aria-controls="basic-menu"
				aria-haspopup={true}
				aria-expanded={true}
				onClick={onSetLastWeek}>
				Last Week
			</Button>
			{buttons?.filter((item) => item !== null)}
		</DialogActions>
	);
};

const DateWeekSelector = ({
	date,
	onChange,
	textFieldProps,
	allowFuture,
	disabled,
	showWeekLabel,
	showWeekRange,
	showWeekChangeArrows,
	dateLimits,
	wrapperStyles,
	weekEnding = false,
}: DateWeekSelectorProps): JSX.Element => {
	const formattedDate = formatSlashedDate(date);
	const nextWeek = weekEnding ? nextSunday(date) : nextMonday(date);
	const lastWeek = weekEnding ? previousSunday(date) : previousMonday(date);
	const lastWeekFromToday = weekEnding
		? endOfWeek(addWeeks(Date.now(), -1))
		: startOfWeek(addWeeks(Date.now(), -1));

	const shouldDisableDate = (date: Date): boolean =>
		weekEnding ? date?.getDay() !== 0 : date?.getDay() !== 1;

	const weekRange = weekEnding
		? `${formatSlashedDate(previousMonday(date))} - ${formattedDate}`
		: `${formattedDate} - ${formatSlashedDate(nextSunday(date))}`;

	return (
		<Stack direction="row" alignItems="center" sx={wrapperStyles}>
			{showWeekChangeArrows && (
				<IconButton
					aria-label="Last Week"
					disabled={disabled}
					onClick={(): void => {
						onChange(lastWeek);
					}}>
					<NavigateBeforeIcon fontSize="inherit" />
				</IconButton>
			)}
			<DatePicker
				label={
					showWeekRange
						? 'Week'
						: weekEnding
						? 'Week Ending'
						: 'Week Beginning'
				}
				value={date}
				onChange={onChange}
				shouldDisableDate={shouldDisableDate}
				minDate={dateLimits?.minDate}
				maxDate={dateLimits?.maxDate}
				showDaysOutsideCurrentMonth
				inputFormat={SLASH_DATE_FORMAT}
				disableFuture={!allowFuture}
				disabled={disabled}
				renderInput={(props: TextFieldProps): JSX.Element => (
					<TextField
						{...props}
						fullWidth
						inputProps={{
							...props.inputProps,
							value: showWeekRange ? weekRange : formattedDate,
							readOnly: true,
						}}
						{...textFieldProps}
						focused={false}
						sx={{
							'& .MuiInputBase-input': {
								caretColor: 'transparent',
								cursor: 'default',
							},
						}}
					/>
				)}
				components={{
					ActionBar: (props) => (
						<CustomActionBar
							{...props}
							onSetLastWeek={(): void => {
								onChange(lastWeekFromToday);
							}}
						/>
					),
				}}
				componentsProps={{
					actionBar: {
						actions: (variant) =>
							variant === 'desktop' ? [] : ['cancel', 'accept'],
					},
				}}
			/>
			{showWeekChangeArrows && (
				<IconButton
					aria-label="Next Week"
					disabled={
						disabled ||
						(allowFuture ? false : nextWeek > new Date())
					}
					onClick={(): void => {
						onChange(nextWeek);
					}}>
					<NavigateNextIcon fontSize="inherit" />
				</IconButton>
			)}
			{showWeekLabel && (
				<Typography
					variant="body1"
					sx={{
						pointerEvents: 'none',
						display: 'flex',
						position: 'absolute',
						right: '15%',
						top: '15%',
						bottom: '15%',
						alignItems: 'center',
					}}>
					{`(${showWeekLabel && getWeekString(date)})`}
				</Typography>
			)}
		</Stack>
	);
};

export default DateWeekSelector;
