import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	DialogActions,
	Button,
} from '@mui/material';

export type ArchiveSiteDialogProps = {
	open: boolean;
	setOpen: (value: boolean) => void;
	archiveSiteloading: boolean;
	archiveSite: () => void;
};

export const ArchiveSiteDialog = ({
	open,
	archiveSite,
	setOpen,
	archiveSiteloading,
}: ArchiveSiteDialogProps): JSX.Element => (
	<Dialog
		open={open}
		onClose={(): void => {
			if (!archiveSiteloading) setOpen(false);
		}}>
		<DialogTitle>Are you sure you want to archive this site?</DialogTitle>
		<DialogContent>
			<Typography>
				Archiving this site will prevent users from signing in to this
				site and cannot be undone.
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button
				variant="outlined"
				disabled={archiveSiteloading}
				onClick={(): void => setOpen(false)}>
				Cancel
			</Button>
			<LoadingButton
				loading={archiveSiteloading}
				variant="contained"
				onClick={archiveSite}
				color="error">
				Archive Site
			</LoadingButton>
		</DialogActions>
	</Dialog>
);
