import { Link, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import {
	DEFAULT_ACTIVITY_KEY,
	InvoicingType,
} from '../../../models/Integrations/InvoicingIntegration';
import { ActivityMappingTab } from '../IntegrationPages/ActivityAndLeaveMapping/ActivityMappingTab';
import { BaseInvoicingPage } from '../IntegrationPages/BaseIntegrationPage';
import { EmployeeMappingTab } from '../IntegrationPages/EmployeeMapping/EmployeeMappingTab';
import { IntegrationTab } from '../IntegrationPages/IntegrationTab';
import { ContractsMappingTab } from './ContractMapping/ContractsMappingTab';

const INVOXY_EMAIL_LINK = 'mailto:api@flexitime.works';

export const InvoxyPage = (): JSX.Element => {
	const navigate = useNavigate();

	const [value, setValue] = useState<string>('');
	const [token, setToken] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	const tabs: IntegrationTab<InvoicingType>[] = [
		{
			index: 0,
			name: 'Employees',
			content: (props) => <EmployeeMappingTab {...props} />,
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchInvoicingEmployeeIDs,
		},
		{
			index: 1,
			name: 'Activities',
			content: (props) => (
				<ActivityMappingTab firebaseApi={firebaseApi} {...props} />
			),
			optional: false,
			canMoveNext: (invoicingIntegration) =>
				DEFAULT_ACTIVITY_KEY in invoicingIntegration.activityTypes,
			fetchData: cloudFunctionApi.fetchInvoicingActivityIDs,
		},
		{
			index: 2,
			name: 'Contracts',
			content: (props) => <ContractsMappingTab {...props} />,
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchInvoicingContractIDs,
		},
	];

	const handleAuthenticate = (): void => {
		if (value === '') {
			setShowError(true);
		} else {
			setToken(value);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (showError) setShowError(false);
		setValue(event.target.value);
	};

	const renderApiTextField = (): JSX.Element => (
		<Stack spacing={1}>
			<Typography>Input your companies Invoxy Api Key below.</Typography>
			<TextField
				label="Invoxy API Key"
				fullWidth
				value={value}
				onChange={handleChange}
				helperText={
					showError &&
					'API Key was not accepted. Please check your input'
				}
				error={showError}
			/>
			<Typography variant="caption">
				{'This key can be found by '}
				<Link
					href={INVOXY_EMAIL_LINK}
					underline="hover"
					target="_blank"
					rel="noopener">
					emailing Invoxy
				</Link>
				{' for your company API key.'}
			</Typography>
		</Stack>
	);

	const clearAll = (): void => {
		setToken(null);
		setValue('');
	};

	const handleError = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/connect-invoicing', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	return (
		<BaseInvoicingPage
			handleAuthenticate={handleAuthenticate}
			integrationType="Invoxy"
			tabs={tabs}
			renderAuthenticationContent={renderApiTextField}
			token={token}
			handleSubmitError={handleError}
			handleSubmitSuccess={clearAll}
			handleCancelAuthenticate={handleCancel}
			fetchToken={cloudFunctionApi.fetchInvoicingTokens}
			firebaseApi={firebaseApi}
			postEndIntegrationStep={handleCancel}
		/>
	);
};
