import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { UserDetails } from '../../../constants/Common';
import {
	SafetyCourse,
	SafetyCourseStatus,
	SafetyCourseType,
	SafetyCourseValidationResponse,
	WorkerTypes,
	safetyCourseStatus,
	safetyCourseTypeNames,
} from '../../../constants/SafetyCourse';
import {
	getSafetyCourseStatus,
	safetyCourseToResponse,
} from '../../../constants/SafetyCourseUtilities';
import { User } from '../../../firebase/firebase';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { SafetyCourseTableTheme } from '../../Documents/TableWrappers/SafetyCourseTableWrapper';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { sortByField, sortObjectByField } from '../../helpers/sortHelpers';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { FilterChip } from '../../Timesheets/Timesheets/Overview/FilterChip';
import { SafetyCourseAddDialog } from './SafetyCourseAddDialog/SafetyCourseAddDialog';
import { SafetyCourseStatusChip } from './SafetyCourseStatusChip/SafetyCourseStatusChip';
import { SafetyCourseUpdateDialog } from './SafetyCourseUpdateDialog/SafetyCourseUpdateDialog';

const statusFilter = ['All', 'Expired', 'Valid'] as const;
type StatusFilter = (typeof statusFilter)[number];

const safetyCourseStatusFilters: Record<StatusFilter, SafetyCourseStatus[]> = {
	All: [safetyCourseStatus.Expired, safetyCourseStatus.Valid],
	Expired: [safetyCourseStatus.Expired],
	Valid: [safetyCourseStatus.Valid],
};

type SafetyCourseEntryTableData = {
	id: string;
	name: string;
	course: string;
	courseName: string;
	courseID: string;
	expiryDate: string;
	status: string;
};

export type SafetyCourseTableProps = {
	user: User;
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'safetyCoursesByCompanySubscription'
		| 'subscribeNonKioskUsersByCompany'
		| 'findDuplicateSafetyCourse'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'validateSafetyCourse' | 'createSafetyCourse' | 'updateSafetyCourse'
	>;
};

export const SafetyCourses = ({
	user,
	userDetails,
	firebaseApi,
	cloudFunctionApi,
}: SafetyCourseTableProps): JSX.Element => {
	const abortSignal = useAbortController();

	const [safetyCourses, setSafetyCourses] = useState<
		Record<string, SafetyCourse>
	>({});
	const [data, setData] = useState<SafetyCourseEntryTableData[]>([]);
	const [
		exisitingSafetyCourseEmployeeIDs,
		setExisitingSafetyCourseEmployeeIDs,
	] = useState<string[]>([]);
	const [filteredTableData, setFilteredTableData] = useState<
		SafetyCourseEntryTableData[]
	>([]);
	const [currentFilter, setCurrentFilter] = useState<StatusFilter>('All');
	const [safetyCourseStatusCount, setSafetyCourseStatusCount] = useState<
		Record<StatusFilter, number>
	>({
		All: 0,
		Expired: 0,
		Valid: 0,
	});
	const [loading, setLoading] = useState(true);
	const [creatingSafetyCourse, setCreatingSafetyCourse] = useState(false);

	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

	const [employees, setEmployees] = useState<Record<string, UserDetails>>({});
	const [currentSafetyCourse, setCurrentSafetyCourse] =
		useState<SafetyCourse | null>(null);
	const [loadingMap, setLoadingMap] = useState<
		Record<SafetyCourse['id'], boolean>
	>({});

	const title = 'Safety Courses';

	useEffect(() => {
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			(data) => {
				const filteredUsers: Record<string, UserDetails> =
					Object.fromEntries(
						data
							.filter(
								(user) =>
									!exisitingSafetyCourseEmployeeIDs.includes(
										user.userID,
									),
							)
							.map((user) => [user.userID, user]),
					);
				const sortedEmployees = sortObjectByField(
					filteredUsers,
					'displayName',
				);
				setEmployees(sortedEmployees);
			},
		);
	}, [exisitingSafetyCourseEmployeeIDs, firebaseApi, userDetails.companyID]);

	useEffect(() => {
		return firebaseApi.safetyCoursesByCompanySubscription(
			userDetails.companyID,
			(data) => {
				const safetyCourseRecords = Object.fromEntries(
					data.map((item) => [item.id, item]),
				);
				setSafetyCourses(safetyCourseRecords);
				setData(getTableData(data));
				const employeeIDs = data.map((entry) => entry.worker.id);
				setExisitingSafetyCourseEmployeeIDs(employeeIDs);
				setLoading(false);
			},
		);
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		const newStatusCount: Record<StatusFilter, number> = {
			All: 0,
			Expired: 0,
			Valid: 0,
		};
		statusFilter.forEach((filter) => {
			newStatusCount[filter] = data.filter((item) =>
				safetyCourseStatusFilters[filter].includes(
					item.status as SafetyCourseStatus,
				),
			).length;
		});
		setSafetyCourseStatusCount(newStatusCount);
	}, [data]);

	useEffect(() => {
		const filteredData = data.filter((item) => {
			return currentFilter === 'All'
				? true
				: currentFilter === item.status;
		});
		const sortedData = sortByField(filteredData, 'name');
		setFilteredTableData(sortedData);
	}, [currentFilter, data]);

	const validateSafetyCourse = async (
		safetyCourseType: SafetyCourseType,
		safetyCourseID: string,
	): Promise<{
		safetyCourseResponse: SafetyCourseValidationResponse | undefined;
		duplicateSafetyCourse: SafetyCourse | undefined;
	}> => {
		const safetyCourseResponse =
			await cloudFunctionApi.validateSafetyCourse(
				abortSignal,
				user,
				safetyCourseType,
				safetyCourseID,
			);

		const duplicateSafetyCourse =
			await firebaseApi.findDuplicateSafetyCourse(
				safetyCourseID,
				safetyCourseType,
			);

		return { safetyCourseResponse, duplicateSafetyCourse };
	};

	const saveSafetyCourse = async (
		userID: string,
		safetyCourse: SafetyCourseValidationResponse,
	): Promise<boolean> => {
		setCreatingSafetyCourse(true);
		const success = await cloudFunctionApi.createSafetyCourse(
			abortSignal,
			user,
			{
				workerType: WorkerTypes.Employee,
				workerID: userID,
				safetyCourseType: safetyCourse.type,
				safetyCourseID: safetyCourse.id,
			},
		);

		setCreatingSafetyCourse(false);
		return !!success;
	};

	const updateSafetyCourse = async (
		userID: string,
		safetyCourse: SafetyCourseValidationResponse,
	): Promise<boolean> => {
		setCreatingSafetyCourse(true);

		const success = await cloudFunctionApi.updateSafetyCourse(
			abortSignal,
			user,
			{
				workerID: userID,
				workerType: WorkerTypes.Employee,
				safetyCourseType: safetyCourse.type,
				safetyCourseID: safetyCourse.id,
			},
		);

		if (success) {
			setCurrentSafetyCourse(null);
		}

		setCreatingSafetyCourse(false);

		return !!success;
	};

	const refreshSafetyCourse = async (
		safetyCourseID: string,
	): Promise<void> => {
		const safetyCourse = safetyCourses[safetyCourseID];

		if (!safetyCourse) {
			return;
		}

		setLoadingMap((prev) => ({ ...prev, [safetyCourseID]: true }));

		const success = await cloudFunctionApi.updateSafetyCourse(
			abortSignal,
			user,
			{
				workerID: safetyCourse.worker.id,
				workerType: WorkerTypes.Employee,
				safetyCourseType: safetyCourse.course.type,
				safetyCourseID: safetyCourse.course.id,
			},
		);

		setLoadingMap((prev) => {
			const { [safetyCourseID]: _, ...rest } = prev;
			return rest;
		});

		if (success) {
			setCreatingSafetyCourse(false);
		}
	};

	const getTableData = (data: SafetyCourse[]): SafetyCourseEntryTableData[] =>
		data.map((entry) => {
			const expiryDate = entry.course.expiryDate;

			const stringDate =
				expiryDate === null
					? 'N/A'
					: formatSlashedDate(expiryDate.toDate());

			const status = getSafetyCourseStatus(
				safetyCourseToResponse(entry.course),
			);

			return {
				id: entry.id,
				name: entry.worker.name,
				course: safetyCourseTypeNames[entry.course.type],
				courseName: entry.course.name,
				courseID: entry.course.id,
				expiryDate: stringDate,
				status: status,
			};
		});

	const numCells = 7;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;

	const columns: MUIDataTableColumn[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.5),
					},
				}),
			},
		},
		{
			name: 'course',
			label: 'Course',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.5),
					},
				}),
			},
		},
		{
			name: 'courseName',
			label: 'Name on Course',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.5),
					},
				}),
			},
		},
		{
			name: 'courseID',
			label: 'Course ID',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'expiryDate',
			label: 'Expiry Date',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.75),
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				customBodyRender: (status: SafetyCourseStatus) => (
					<Box display="flex" justifyContent="center">
						<SafetyCourseStatusChip status={status} />
					</Box>
				),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.5),
					},
				}),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				searchable: false,
				filter: false,
				sort: false,
				customBodyRender: (id: SafetyCourseEntryTableData['id']) =>
					renderOptionButtons(id),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.25),
					},
				}),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'none',
		print: false,
		download: false,
		filter: false,
		viewColumns: false,
		search: true,
		sort: true,
		tableBodyHeight: 'calc(100vh - 330px)',
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching Safety Courses found'
				),
			},
		},
		customToolbar: () => renderAddButton(),
	};

	const renderAddButton = (): JSX.Element => (
		<LoadingButton
			loading={creatingSafetyCourse}
			variant="contained"
			endIcon={<AddIcon />}
			disableElevation
			sx={{ ml: 1, my: 1 }}
			onClick={(): void => setAddDialogOpen(true)}>
			New Safety Course
		</LoadingButton>
	);

	const renderOptionButtons = (
		id: SafetyCourseEntryTableData['id'],
	): JSX.Element => {
		const loading = loadingMap[id] || false;

		const handleEditOption = (): void => {
			setUpdateDialogOpen(true);
			setCurrentSafetyCourse(safetyCourses[id]);
		};

		const handleRefreshOption = (): Promise<void> =>
			refreshSafetyCourse(id);

		return (
			<Stack
				justifyContent="center"
				spacing={1}
				width="100%"
				direction={{ md: 'column', lg: 'row' }}>
				<LoadingButton
					variant="contained"
					fullWidth
					loading={loading}
					onClick={handleRefreshOption}>
					Refresh
				</LoadingButton>
				<Button variant="outlined" fullWidth onClick={handleEditOption}>
					Edit
				</Button>
			</Stack>
		);
	};

	const renderTableTitle = (): JSX.Element => (
		<Grid container spacing={1} pt={1}>
			<Grid xs={12} item>
				<Typography variant="h4">{title}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Stack spacing={1} direction="row">
					{statusFilter.map((titleKey) => (
						<FilterChip
							title={titleKey}
							key={titleKey}
							currentFilter={currentFilter}
							onClick={(): void => setCurrentFilter(titleKey)}
							count={safetyCourseStatusCount[titleKey]}
						/>
					))}
				</Stack>
			</Grid>
		</Grid>
	);

	const closeAddDialog = (): void => setAddDialogOpen(false);
	const closeUpdateDialog = (): void => {
		setCurrentSafetyCourse(null);
		setUpdateDialogOpen(false);
	};

	return (
		<>
			<SafetyCourseAddDialog
				open={addDialogOpen}
				onValidate={validateSafetyCourse}
				onClose={closeAddDialog}
				onSave={saveSafetyCourse}
				employees={employees}
				existingSafetyCourseEmployeeIDs={
					exisitingSafetyCourseEmployeeIDs
				}
			/>
			{currentSafetyCourse && (
				<SafetyCourseUpdateDialog
					open={updateDialogOpen}
					onValidate={validateSafetyCourse}
					onClose={closeUpdateDialog}
					onSave={updateSafetyCourse}
					user={employees[currentSafetyCourse.worker.id]}
					safetyCourse={safetyCourses[currentSafetyCourse.id]}
				/>
			)}
			<SafetyCourseTableTheme>
				<DateDataTableWithID
					title={renderTableTitle()}
					columns={columns}
					tableData={filteredTableData}
					customTableOptions={tableOptions}
				/>
			</SafetyCourseTableTheme>
		</>
	);
};
