import { Box } from '@mui/material';
import cloudFunctionApi from '../../../cloudfunctions';
import { useAbortController } from '../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../providers/UserProvider';
import { NewTemplate } from './NewTemplate';

export const NewTemplateWrapper = (): JSX.Element => {
	const abortSignal = useAbortController();
	const userAuth = useUserAuthContext();

	const createTemplateFunction = async (
		name: string,
		body: string,
		fields: Record<string, number>,
	): Promise<void> => {
		if (userAuth) {
			await cloudFunctionApi.createTemplate(
				abortSignal,
				userAuth,
				name,
				fields,
				body,
			);
		}
	};

	return (
		<Box>
			<NewTemplate createTemplate={createTemplateFunction} />
		</Box>
	);
};
