import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import {
	AccountType,
	UserDetails,
	UserProfile,
	WorkerType,
} from '../../constants/Common';
import { FieldValue } from '../../firebase/firebase';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type RemoveUserButtonProps = {
	accountType: AccountType;
	existingUserID: string;
	existingUserName: string;
	isGuest: boolean;
	firebaseApi: Pick<
		FirebaseApi,
		| 'updateUserDetailsSiteInfo'
		| 'updateUserDetailsCompanyInfo'
		| 'updateUserProfileCompany'
		| 'deleteGuest'
	>;
};

export const RemoveUserButton = ({
	accountType,
	existingUserID,
	existingUserName,
	isGuest,
	firebaseApi,
}: RemoveUserButtonProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const removeFromCompany = accountType === 'handler';

	const removeUserFromSite = async (): Promise<void> => {
		const updatedUser: Pick<
			UserDetails,
			'site' | 'siteID' | 'siteCompany' | 'siteCompanyID'
		> = {
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
		};

		if (isGuest) {
			await firebaseApi.deleteGuest(existingUserID);
		} else {
			await firebaseApi.updateUserDetailsSiteInfo(
				existingUserID,
				updatedUser,
			);
		}
	};

	// currently just for handlers
	// Pretty much puts the user back into company select phase
	const removeUserFromCompany = async (): Promise<void> => {
		const updatedUser: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'company'
			| 'companyID'
			| 'contractedTo'
		> = {
			accountType: '',
			workerType: FieldValue.delete() as unknown as WorkerType, // delete field
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
			company: '',
			companyID: '',
			contractedTo: null,
		};

		const updatedUserProfile: Pick<UserProfile, 'company'> = {
			company: {
				id: '',
				name: '',
			},
		};

		try {
			await firebaseApi.updateUserProfileCompany(
				existingUserID,
				updatedUserProfile,
			);
		} catch (error) {
			// IDK if every user has a profile? but if this errors the remove flow is stuffed
			console.error(error);
		}

		await firebaseApi.updateUserDetailsCompanyInfo(
			existingUserID,
			updatedUser,
		);
	};

	const deleteGuest = async (): Promise<void> => {
		setLoading(true);
		await firebaseApi.deleteGuest(existingUserID);
		setModalOpen(false);
		setLoading(false);
	};

	const onRemoveClick = async (): Promise<void> => {
		setLoading(true);
		if (removeFromCompany) {
			await removeUserFromCompany();
		} else {
			await removeUserFromSite();
		}
		setModalOpen(false);
		setLoading(false);
	};

	const confirmDialog = (
		<Dialog
			fullWidth
			open={modalOpen}
			onClose={(): void => setModalOpen(false)}>
			<DialogTitle>Are You Sure?</DialogTitle>
			{loading ? (
				<LoadingDots />
			) : (
				<DialogContent>
					{`This will remove ${existingUserName} from your ${
						removeFromCompany ? 'company' : 'site'
					}`}
				</DialogContent>
			)}
			<DialogActions>
				<Button
					disabled={loading}
					variant="outlined"
					onClick={(): void => setModalOpen(false)}
					color="primary">
					Cancel
				</Button>
				{isGuest ? (
					<Button
						disabled={loading}
						variant="contained"
						onClick={deleteGuest}
						color="error">
						Delete
					</Button>
				) : (
					<Button
						disabled={loading}
						variant="contained"
						onClick={onRemoveClick}
						color="error">
						Remove
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{confirmDialog}
			<Button
				fullWidth
				variant="contained"
				onClick={(): void => setModalOpen(true)}
				color="primary"
				data-testid="removeUserButton">
				{isGuest ? 'Delete' : 'Remove'}
			</Button>
		</>
	);
};
