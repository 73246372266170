import { LoadingButton } from '@mui/lab';
import { AlertColor, Box } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useState, useEffect } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import {
	InvoiceStatuses,
	Timesheet,
} from '../../../constants/Timesheet/Timesheet';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../providers/UserProvider';
import { DataTable } from '../../DataTable/DataTable';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import {
	contractedToCustomTableSort,
	timesheetHoursCustomTableSort,
	nameCustomTableSort,
} from '../../helpers/muiDataTableCustomSorts';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../../SnackBar/SnackBar';
import { TriggerStatuses } from '../FailedAutomations/FailedAutomations';
import { FailedIntegrationsTableTheme } from '../FailedAutomations/FailedIntegrationsTableTheme';

export type ManageInvoicingIntegrationsProps = {
	cloudFunctionApi: Pick<CloudFunctionApi, 'invoicingIntegrationsTrigger'>;
	firebaseApi: Pick<FirebaseApi, 'timesheetsByInvoiceStatus'>;
};

export const ManageInvoicingIntegrations = ({
	cloudFunctionApi,
	firebaseApi,
}: ManageInvoicingIntegrationsProps): JSX.Element => {
	const abortSignal = useAbortController();
	const user = useUserAuthContext();

	const [triggerStatus, setTriggerStatus] = useState<TriggerStatuses>('none');
	const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
	const [loading, setLoading] = useState(false);
	const COLUMN_COUNT = 9;
	const cellWidth = { width: `${100 / COLUMN_COUNT}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no documents found'
	);
	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'employee',
			label: 'User',
			options: {
				customBodyRender: (employee: Timesheet['employee']) =>
					employee.name,
				sortCompare: nameCustomTableSort,
				setCellHeaderProps,
			},
		},
		{
			name: 'employer',
			label: 'Company',
			options: {
				customBodyRender: (employer: Timesheet['employer']) =>
					employer.name,
				sortCompare: nameCustomTableSort,
				setCellHeaderProps,
			},
		},
		{
			name: 'contractedTo',
			label: 'Contracted To',
			options: {
				customBodyRender: (contractedTo: Timesheet['contractedTo']) =>
					contractedTo ? contractedTo.name : 'Not Contracted',
				setCellHeaderProps,
				sortCompare: contractedToCustomTableSort,
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				customBodyRender: (site: Timesheet['site']) => site.name,
				setCellHeaderProps,
				sortCompare: nameCustomTableSort,
			},
		},
		{
			name: 'week',
			label: 'Week',
			options: {
				customBodyRender: (date: Timesheet['week']) =>
					date ? formatSlashedDate(date.toDate()) : 'unsubmitted',
				setCellHeaderProps,
			},
		},
		{
			name: 'dateSubmitted',
			label: 'Date Submitted',
			options: {
				customBodyRender: (date: Timesheet['dateSubmitted']) =>
					date ? formatSlashedDate(date.toDate()) : 'unsubmitted',
				setCellHeaderProps,
			},
		},
		{
			name: 'reviewedAt',
			label: 'Reviewed At',
			options: {
				customBodyRender: (date: Timesheet['reviewedAt']) =>
					date ? formatSlashedDate(date.toDate()) : 'unreviewed',
				setCellHeaderProps,
			},
		},
		{
			name: 'hours',
			label: 'Total Hours',
			options: {
				customBodyRender: (hours: Timesheet['hours']) => (
					<Box display="flex" justifyContent="center">
						{hours.total.billable}
					</Box>
				),
				sortCompare: timesheetHoursCustomTableSort,
				setCellHeaderProps,
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				customBodyRender: (id): JSX.Element => (
					<LoadingButton
						fullWidth
						loading={triggerStatus === 'loading'}
						variant="outlined"
						onClick={(): Promise<void> =>
							handleTriggerTimesheet(id)
						}>
						trigger integration
					</LoadingButton>
				),
				setCellHeaderProps,
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 310px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		sortOrder: {
			name: 'reviewedAt',
			direction: 'desc',
		},
	};

	const snackBarContent: Record<
		TriggerStatuses,
		{ text: string; severity: AlertColor }
	> = {
		none: { text: '', severity: 'info' },
		error: {
			severity: 'error',
			text: 'Timesheet Integration Unsuccessful',
		},
		done: {
			severity: 'success',
			text: 'Timesheet Integration Success',
		},
		loading: {
			severity: 'info',
			text: 'Triggering Timesheet Integration',
		},
	};

	useEffect(() => {
		setLoading(true);
		return firebaseApi.timesheetsByInvoiceStatus(
			InvoiceStatuses.Failed,
			(timesheets) => {
				setTimesheets(timesheets);
				setLoading(false);
			},
		);
	}, [firebaseApi]);

	const handleTriggerTimesheet = async (
		timesheetID: string,
	): Promise<void> => {
		if (!user) return;
		setTriggerStatus('loading');
		const isSuccessful =
			await cloudFunctionApi.invoicingIntegrationsTrigger(
				abortSignal,
				user,
				timesheetID,
			);
		if (isSuccessful) {
			setTriggerStatus('done');
		} else {
			setTriggerStatus('error');
		}
	};

	return (
		<>
			<CustomSnackBar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={triggerStatus !== 'none'}
				onClose={(): void => {
					if (triggerStatus !== 'loading') setTriggerStatus('none');
				}}
				snackBarText={snackBarContent[triggerStatus].text}
				severity={snackBarContent[triggerStatus].severity}
				loading={triggerStatus === 'loading'}
			/>
			<FailedIntegrationsTableTheme
				centerStatusHeader={false}
				optionsColumn={9}>
				<DataTable
					tableData={timesheets}
					columns={columns}
					customTableOptions={tableOptions}
					title="Failed Invoicing Timesheets"
				/>
			</FailedIntegrationsTableTheme>
		</>
	);
};
