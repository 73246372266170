import { Box, Card } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import {
	Site,
	TimesheetActivity,
	UserDetails,
	accountTypes,
} from '../../../../constants/Common';
import { createSiteActivityID } from '../../../../constants/ProjectTrackingIntegrations';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	ActivityLink,
	ProjectTrackingLinks,
	SiteActivityLink,
	SiteLink,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { activityLinkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { IntegrationLinkButton } from '../../../Integrations/Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../../Integrations/Components/IntegrationTable/IntegrationTable';
import { IntegrationTextLinkCell } from '../../../Integrations/Components/IntegrationTextLinkCell';
import { activityIntegrationTableRowSearch } from '../../Common/Activities/Helpers/activityIntegrationTableRowSearch';
import {
	ActivityIntegrationTableRow,
	CostCodeLink,
	TaskCodeLink,
} from '../../Common/Activities/Models/activityTab';
import { getLinkStatus } from '../CATProjectsConstants';

const costCodeLink = { integrationID: 'T1', integrationName: 'T1' } as const;

export type ActivitiesTabProps = {
	user: User;
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'siteSubscription'
		| 'activeSitesByCompanySubscription'
		| 'projectTrackingIntegrationSingleSiteLinkSubscription'
		| 'projectTrackingIntegrationSiteLinksSubscription'
		| 'projectTrackingIntegrationSiteActivityLinksSubscription'
		| 'projectTrackingIntegrationAllSiteActivityLinksSubscription'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		| 'createProjectTrackingActivityLink'
		| 'deleteProjectTrackingActivityLink'
	>;
};

export const ActivitiesTab = ({
	user,
	userDetails,
	firebaseApi,
	cloudFunctionApi,
}: ActivitiesTabProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [searchParams] = useSearchParams();

	const [selected, setSelected] = useState<number | null>(null);

	const [tableData, setTableData] = useState<ActivityIntegrationTableRow[]>(
		[],
	);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [siteLinks, setSiteLinks] = useState<Record<string, SiteLink>>({});
	const [siteActivityLinks, setSiteActivityLinks] = useState<
		Record<string, SiteActivityLink>
	>({});
	const [newTaskCodeLinks, setNewTaskCodeLinks] = useState<
		Record<string, TaskCodeLink>
	>({});
	const [loading, setLoading] = useState<
		Record<'activities' | 'activityLinks' | 'siteLinks', boolean>
	>({
		activities: true,
		activityLinks: true,
		siteLinks: true,
	});
	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const singleSite = userDetails.accountType === accountTypes.management;

	const numCells = 5;
	const cellWidth = `${100 / numCells}%`;
	const cellWidthExtra = `${(100 / numCells) * 1.5}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}

		setLoading((prev) => ({ ...prev, activities: true }));

		if (singleSite) {
			return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
				setSites({ [userDetails.siteID]: site });
				setLoading((prev) => ({ ...prev, activities: false }));
			});
		} else {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) => {
					setSites(sites);
					setLoading((prev) => ({ ...prev, activities: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}

		setLoading((prev) => ({ ...prev, siteLinks: true }));

		if (singleSite) {
			return firebaseApi.projectTrackingIntegrationSingleSiteLinkSubscription(
				userDetails.companyID,
				userDetails.siteID,
				(link) => {
					if (link !== null) {
						setSiteLinks({ [userDetails.siteID]: link });
					}
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		} else {
			return firebaseApi.projectTrackingIntegrationSiteLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteLinks(links);
					setLoading((prev) => ({ ...prev, siteLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails]);

	useEffect(() => {
		if (
			!userDetails ||
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}

		setLoading((prev) => ({ ...prev, activityLinks: true }));

		if (singleSite) {
			firebaseApi.projectTrackingIntegrationSiteActivityLinksSubscription(
				userDetails.companyID,
				userDetails.siteID,
				(links) => {
					setSiteActivityLinks(links);
					setLoading((prev) => ({ ...prev, activityLinks: false }));
				},
			);
		} else {
			return firebaseApi.projectTrackingIntegrationAllSiteActivityLinksSubscription(
				userDetails.companyID,
				(links) => {
					setSiteActivityLinks(links);
					setLoading((prev) => ({ ...prev, activityLinks: false }));
				},
			);
		}
	}, [firebaseApi, singleSite, siteLinks, userDetails]);

	useEffect(() => {
		const tableData = Object.values(siteLinks).flatMap((siteLink) => {
			const site = sites[siteLink.id];

			return site !== undefined
				? Object.values(site.timesheetActivitiesV2).map((activity) => {
						return mapTableData(
							site,
							activity,
							siteActivityLinks,
							newTaskCodeLinks,
						);
				  })
				: [];
		});
		setTableData(tableData);
	}, [newTaskCodeLinks, siteActivityLinks, siteLinks, sites]);

	useEffect(() => {
		const name = searchParams.get('name');
		const index = tableData.findIndex((row) => row.name === name);
		setSelected(index === -1 ? null : index);
	}, [tableData, searchParams]);

	const mapTableData = (
		site: Site,
		activity: TimesheetActivity,
		links: Record<string, SiteActivityLink>,
		newTaskCodeLinks: Record<string, TaskCodeLink>,
	): ActivityIntegrationTableRow => {
		const siteActivityID = createSiteActivityID(site.id, activity.id);
		const link = links[siteActivityID]?.activityLink ?? null;

		const status = getLinkStatus(link);

		const taskCode: TaskCodeLink = {
			siteID: site.id,
			activityID: activity.id,
			taskCode:
				link?.taskCode ?? newTaskCodeLinks[siteActivityID]?.taskCode,
		};

		const costCode: CostCodeLink = {
			siteID: site.id,
			activityID: activity.id,
			costCode: costCodeLink,
		};

		return {
			id: createSiteActivityID(site.id, activity.id),
			site: site.name,
			name: activity.activityName,
			status: status,
			taskCode: taskCode,
			costCode: costCode,
		};
	};

	const createLink = async (
		siteID: string,
		link: ActivityLink,
	): Promise<void> => {
		if (user !== undefined && user !== null) {
			await cloudFunctionApi.createProjectTrackingActivityLink(
				abortSignal,
				user,
				siteID,
				link,
			);
		}
	};

	const deleteLink = async (
		siteID: string,
		link: ActivityLink,
	): Promise<void> => {
		if (user !== undefined && user !== null) {
			await cloudFunctionApi.deleteProjectTrackingActivityLink(
				abortSignal,
				user,
				siteID,
				link,
			);
		}
	};

	const onCostingLinkChange =
		(
			siteActivityID: string,
			link: ActivityIntegrationTableRow['taskCode'],
			setLink: Dispatch<SetStateAction<Record<string, TaskCodeLink>>>,
		) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setLink((prev) => {
					const { [siteActivityID]: _, ...remaining } = prev;
					return remaining;
				});

				return;
			}

			setLink((prev) => ({
				...prev,
				[siteActivityID]: {
					...prev[siteActivityID],
					...link,
					taskCode: {
						integrationID,
						integrationName: integrationID,
					},
				},
			}));
		};

	const renderCosting = (
		siteAndActivityLink: ActivityIntegrationTableRow['taskCode'],
	): JSX.Element => {
		const { siteID, activityID } = siteAndActivityLink;
		const siteActivityID = createSiteActivityID(siteID, activityID);

		const onChange = onCostingLinkChange(
			siteActivityID,
			siteAndActivityLink,
			setNewTaskCodeLinks,
		);
		const linking = !!linkingRecords[siteActivityID];
		const trueLink =
			siteActivityLinks[siteActivityID]?.activityLink['taskCode'];

		const integrationID = siteAndActivityLink.taskCode?.integrationID;

		return (
			<IntegrationTextLinkCell
				link={trueLink}
				linkIntegrationID={integrationID}
				onChange={onChange}
				disabled={linking}
			/>
		);
	};

	const renderOptionButton = (siteActivityID: string): JSX.Element => {
		const link = siteActivityLinks[siteActivityID];
		const newTaskCodeLink = newTaskCodeLinks[siteActivityID];

		const handleCreateLink = async (): Promise<void> => {
			const activity = sites[
				newTaskCodeLink.siteID
			].timesheetActivitiesV2.find(
				(item) => item.id === newTaskCodeLink.activityID,
			);

			// newTaskCodeLink created from the list of activities, so it will always exist but .find() doesn't care
			if (
				activity === undefined ||
				newTaskCodeLink?.taskCode === undefined
			) {
				return;
			}

			const newLink: ActivityLink = {
				id: newTaskCodeLink.activityID,
				name: activity.activityName,
				taskCode: newTaskCodeLink.taskCode,
				costCode: costCodeLink,
				type: ProjectTrackingLinks.Activity,
			};

			setLinkingRecords((prev) => ({ ...prev, [siteActivityID]: true }));
			await createLink(newTaskCodeLink.siteID, newLink);
			setNewTaskCodeLinks((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [siteActivityID]: true }));
			await deleteLink(link.siteID, link.activityLink);
			setLinkingRecords((prev) => {
				const { [siteActivityID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled = !link && newTaskCodeLink?.taskCode === undefined;

		return (
			<IntegrationLinkButton
				link={link?.activityLink?.taskCode}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Activity',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidth,
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidth,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						<IntegrationStatusChip status={value} />
					</Box>
				),
			},
		},
		{
			name: 'taskCode',
			label: 'Task Code',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthExtra,
					},
				}),
				sortCompare: activityLinkTableSort('taskCode'),
				customBodyRender: (taskCodeLink: TaskCodeLink) => (
					<Box display="flex" justifyContent="center">
						{renderCosting(taskCodeLink)}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	return (
		<Box flex="1">
			<Card>
				<IntegrationTable
					title="Link Activites"
					data={tableData}
					columns={columns}
					loading={
						loading.siteLinks ||
						loading.activityLinks ||
						loading.activities
					}
					missingDataLabel="Sorry, no Activities found"
					centeredColumns={[3, 4, 5]}
					customSearch={activityIntegrationTableRowSearch}
					selection={[selected, setSelected]}
				/>
			</Card>
		</Box>
	);
};
