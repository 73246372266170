import { Site } from '../../../../constants/Common';
import { validatePhoneNumber } from '../../../PhoneNumberInput/PhoneNumberInput';

/** The fields from within the Site doc that are editable */
export type EditableSiteFields = Pick<
	Site,
	| 'name'
	| 'jobNumber'
	| 'siteContact'
	| 'siteContactNumber'
	| 'address'
	| 'city'
	| 'region'
	| 'autoSignOutTime'
	| 'showCovidWarning'
	| 'allowAppSignIn'
	| 'startTime'
	| 'hasInductions'
	| 'safetyCourseRequiredForInduction'
>;

export type EditableSiteTextFields = Pick<
	EditableSiteFields,
	| 'name'
	| 'jobNumber'
	| 'siteContact'
	| 'siteContactNumber'
	| 'address'
	| 'city'
	| 'region'
	| 'autoSignOutTime'
	| 'startTime'
>;

export type ErrorMapType = Record<
	keyof EditableSiteFields,
	{ dirty: boolean; error: boolean }
>;

/** Default site values */
export const createBlankSite = (): EditableSiteFields => ({
	name: '',
	jobNumber: '',
	siteContact: '',
	siteContactNumber: '+64',
	address: '',
	city: '',
	region: '',
	autoSignOutTime: 21,
	showCovidWarning: false,
	allowAppSignIn: false,
	startTime: 27000000,
	hasInductions: false,
	safetyCourseRequiredForInduction: false,
});

export const siteDisplayTextFields: Record<
	keyof EditableSiteTextFields,
	string
> = {
	name: 'Name',
	jobNumber: 'Job Number',
	siteContact: 'Site Contact',
	siteContactNumber: 'Site Contact Number',
	address: 'Address',
	city: 'City',
	region: 'Region',
	autoSignOutTime: 'Auto Signout Time',
	startTime: 'Start Time',
};

export const siteDisplayFields: Record<keyof EditableSiteFields, string> = {
	...siteDisplayTextFields,
	showCovidWarning: 'Covid Warning',
	allowAppSignIn: 'Allow App Sign In',
	hasInductions: 'Require Inductions',
	safetyCourseRequiredForInduction: 'Require Safety Course in Inductions',
};

export const isAutoSignOutTime = (
	value: number,
): value is Site['autoSignOutTime'] => value >= -1 && value <= 24;

export const validateStartTime = (value: number): value is Site['startTime'] =>
	!isNaN(value) &&
	value >= 0 &&
	value <= 86400000 &&
	(value / 60000) % 5 === 0;

export const isFieldErrored = (
	value: string,
	key: keyof EditableSiteFields,
): boolean => {
	switch (key) {
		case 'autoSignOutTime':
			return !isAutoSignOutTime(Number.parseInt(value));
		case 'siteContactNumber':
			return !validatePhoneNumber(value);
		case 'startTime':
			return !validateStartTime(Number.parseInt(value));
		default:
			return value === '';
	}
};
/** Create an error object from scratch */
export const createError = (
	site: Partial<EditableSiteFields>,
	dirty = false,
): ErrorMapType => {
	return Object.fromEntries(
		Object.entries(site).map(([_key, value]) => {
			const key = _key as keyof EditableSiteFields;
			if (key === 'startTime') {
				return [
					key,
					{
						dirty,
						error: isFieldErrored(value?.toString(), key),
					},
				];
			}
			return [
				key,
				{
					dirty,
					error: isFieldErrored(value?.toString(), key),
				},
			];
		}),
	) as ErrorMapType;
};
