import { BaseIntegrationLink } from '../../models/Integrations/IntegrationElements';
import { IntegrationStatus } from '../../models/Integrations/IntegrationStatus';
import {
	PayrollIntegrationAtRest,
	PayrollIntegrationEmployeeLink,
} from '../../models/Integrations/PayrollIntegration';
import { FieldValue, Firestore } from '../firebase';

const getPayrollIntegration = async (
	companyID: string,
): Promise<PayrollIntegrationAtRest | null> => {
	const snapshot = await Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.get();
	return snapshot.exists
		? (snapshot.data() as PayrollIntegrationAtRest)
		: null;
};

const getIntegratedEmployees = async (
	companyID: string,
): Promise<Record<string, BaseIntegrationLink>> => {
	const querySnapshot = await Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.collection('employees')
		.get();
	const accountsList: Record<string, BaseIntegrationLink> = {};
	querySnapshot.forEach((doc) => {
		const employee = doc.data() as BaseIntegrationLink;
		accountsList[doc.id] = employee;
	});
	return accountsList;
};

const updatePayrollIntegrationStatus = async (
	companyID: string,
	status: IntegrationStatus,
): Promise<void> => {
	const updatedIntegration: Pick<PayrollIntegrationAtRest, 'status'> = {
		status: status,
	};
	await Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.update(updatedIntegration);
};

const updatePayrollOvertime = async (
	companyID: string,
	updatedIntegration: Pick<
		PayrollIntegrationAtRest,
		'overtimeThreshold' | 'activityTypes'
	>,
): Promise<void> => {
	if (updatedIntegration.overtimeThreshold === undefined) {
		const updatedActivityTypes = { ...updatedIntegration.activityTypes };
		delete updatedActivityTypes['Overtime'];
		const removedOvertime: typeof updatedIntegration = {
			overtimeThreshold: FieldValue.delete() as unknown as undefined,
			activityTypes: updatedActivityTypes,
		};

		await Firestore.collection('payrollIntegrations')
			.doc(companyID)
			.update(removedOvertime);
	} else {
		await Firestore.collection('payrollIntegrations')
			.doc(companyID)
			.update(updatedIntegration);
	}
};

const payrollIntegrationSubscription = (
	companyID: string,
	onNext: (integration: PayrollIntegrationAtRest | null) => void,
): (() => void) =>
	Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.onSnapshot((doc) => {
			onNext(
				doc.exists ? (doc.data() as PayrollIntegrationAtRest) : null,
			);
		});

const createPayrollEmployeeLink = async (
	companyID: string,
	employeeLink: PayrollIntegrationEmployeeLink,
): Promise<void> => {
	const employeeLinkRef = Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.collection('employees')
		.doc(employeeLink.id);

	await employeeLinkRef.set(employeeLink);
};

const deletePayrollEmployeeLink = async (
	companyID: string,
	employeeLinkID: PayrollIntegrationEmployeeLink['id'],
): Promise<void> => {
	const employeeLinkRef = Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.collection('employees')
		.doc(employeeLinkID);

	await employeeLinkRef.delete();
};

const payrollIntegrationEmployeeLinkSubscription = (
	companyID: string,
	callback: (
		employeeLinks: Record<string, PayrollIntegrationEmployeeLink>,
	) => void,
): (() => void) => {
	return Firestore.collection('payrollIntegrations')
		.doc(companyID)
		.collection('employees')
		.onSnapshot((querySnapshot) => {
			const employeeLinks = querySnapshot.docs.reduce<
				Record<string, PayrollIntegrationEmployeeLink>
			>((prev, current) => {
				const employeeLink =
					current.data() as PayrollIntegrationEmployeeLink;
				prev[employeeLink.id] = employeeLink;
				return prev;
			}, {});
			callback(employeeLinks);
		});
};

const payrollIntegrationsFirebaseApi = {
	getPayrollIntegration,
	getIntegratedEmployees,
	updatePayrollIntegrationStatus,
	updatePayrollOvertime,
	payrollIntegrationSubscription,
	createPayrollEmployeeLink,
	deletePayrollEmployeeLink,
	payrollIntegrationEmployeeLinkSubscription,
};

export default payrollIntegrationsFirebaseApi;
