import { Document } from '../../constants/Document';
import { Firestore } from '../firebase';

const COLLECTION = 'documents';

const documentsByCompanyIDSubscription = (
	companyID: string,
	callback: (documents: Document[]) => void,
): (() => void) => {
	return Firestore.collection(COLLECTION)
		.where('company.id', '==', companyID)
		.onSnapshot((snapshot) =>
			callback(
				snapshot.docs.map(
					(doc) => ({ ...doc.data(), id: doc.id } as Document),
				),
			),
		);
};

const documentsFirebaseApi = {
	documentsByCompanyIDSubscription,
};

export default documentsFirebaseApi;
