import {
	Card,
	CardHeader,
	CardMedia,
	CardActions,
	Button,
} from '@mui/material';
import Image from '../../../Image/Image';

export type IntegrationCardProps = {
	id: string;
	label: string;
	onStart: (key: string) => void;
	link?: string;
};

export const IntegrationCard = ({
	id,
	label,
	onStart,
	link,
}: IntegrationCardProps): JSX.Element => (
	<Card sx={{ width: '100%' }}>
		<CardHeader title={label} />
		<CardMedia title={label}>
			<Image
				src={link ?? ''}
				alt={label}
				style={{ height: '25vh', width: '100%' }}
				imageStyle={{ height: '25vh', objectFit: 'contain' }}
			/>
		</CardMedia>
		<CardActions sx={{ justifyContent: 'center' }}>
			<Button variant="contained" onClick={(): void => onStart(id)}>
				Connect
			</Button>
		</CardActions>
	</Card>
);
