import { Box, SxProps } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';

type LoadingDotsProps = {
	style?: SxProps;
	overrideHeight?: number | string;
};

export const LoadingDots = ({
	style,
	overrideHeight,
}: LoadingDotsProps): JSX.Element => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				...style,
			}}>
			<ThreeDots
				color="#d65900"
				height={overrideHeight ?? '100'}
				width="100"
			/>
		</Box>
	);
};
