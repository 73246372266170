import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, ListItem, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { PPEStoreQtyFormat } from './PPENumberFormats';
import { PPEItem } from './PPEStore';

type CartItemProps = {
	ppeItem: PPEItem;
	index: number;
	removeItem: (index: number | null) => void;
	updateItem: (
		update:
			| [number, 'quantity', number]
			| [number, 'size', string | null]
			| null,
	) => void;
	itemLimit: number;
};

export const CartItem = ({
	ppeItem,
	index,
	removeItem,
	updateItem,
	itemLimit,
}: CartItemProps): JSX.Element => {
	const [size, setSize] = useState(ppeItem.size ? ppeItem.size : '');
	const [quantity, setQuantity] = useState(ppeItem.quantity);
	const item = ppeItem;
	const id = `${item.id}-${item.size}`;
	const totalPrice = item.quantity * item.price;

	const changeQuantity: React.ChangeEventHandler<HTMLInputElement> = (
		event,
	) => {
		setQuantity(parseInt(event.target.value));
		updateItem([index, 'quantity', parseInt(event.target.value)]);
	};
	const changeSize: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setSize(event.target.value);
		updateItem([index, 'size', event.target.value]);
	};
	useEffect(() => {
		setQuantity(ppeItem.quantity);
	}, [ppeItem.quantity]);
	useEffect(() => {
		if (ppeItem.size) setSize(ppeItem.size);
	}, [ppeItem.size]);

	return (
		<ListItem
			style={{
				fontSize: '16px',
				border: '1px solid rgb(240,240,240)',
				color: 'rgb(96,96,96)',
			}}
			id={id}>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center">
				<Grid item xs={10}>
					<Grid
						item
						container
						xs={12}
						style={{
							display: 'flex',
							justifyContent: 'space-around',
						}}>
						<Grid item xs={6}>
							{item.name}
						</Grid>
						<Grid item xs={6}>
							Price: ${totalPrice}
						</Grid>
					</Grid>
					<Grid
						item
						container
						spacing={2}
						xs={12}
						style={{
							display: 'flex',
							justifyContent: 'space-around',
						}}>
						<Grid item xs={6}>
							<TextField
								size="small"
								label="Quantity"
								value={quantity}
								onChange={changeQuantity}
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								margin="dense"
								InputProps={{
									inputComponent: PPEStoreQtyFormat,
								}}
								inputProps={{
									style: { textAlign: 'center' },
									itemlimit: itemLimit,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							{item.size && item.sizes && (
								<TextField
									size="small"
									value={size}
									onChange={changeSize}
									label="Size"
									variant="outlined"
									fullWidth
									select
									margin="dense"
									InputProps={{
										style: { textAlign: 'center' },
									}}>
									{item.sizes.map((size, index) => (
										<MenuItem key={index} value={size}>
											{size}
										</MenuItem>
									))}
								</TextField>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={2}
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}>
					<IconButton
						onClick={(): void => removeItem(index)}
						size="small">
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Grid>
			</Grid>
		</ListItem>
	);
};
