import { Stack } from '@mui/material';
import {
	Activity,
	DayString,
	TimesheetActivity,
	UserDetails,
	dayStrings,
	lowercaseDayString,
} from '../../../../../constants/Common';
import { NoteDay } from '../../../../../constants/Note';
import {
	ActivityEditFields,
	Timesheet,
} from '../../../../../constants/Timesheet/Timesheet';
import { DaySection } from './DaySection';
import { HoursSection } from './Hours/HoursSection';
import { NotesSection } from './Notes/NotesSection';
import { EditState } from './reducer';

export type WeekContentsListProps = {
	disabled: boolean;
	siteActivities: TimesheetActivity[];
	weekActivities: Record<DayString, Pick<Activity, ActivityEditFields>[]>;
	activityErrors: Record<string, true>;
	weekSiteLogHours: Record<DayString, { formattedLogs: string | null }>;
	timesheetHours: Record<
		Lowercase<DayString>,
		Pick<Timesheet['hours']['total'], 'break' | 'billable'>
	>;
	timesheetId: string;
	addActivity: (day: DayString) => void;
	updateActivity: (
		id: Activity['id'],
		activity: Activity['activity'],
		hours: number,
	) => void;
	deleteActivity: (id: Activity['id']) => void;
	updateBreaks: (day: DayString, breakHours: number) => void;
	user: Pick<UserDetails, 'userID' | 'displayName'>;
	notes: EditState['updatedNotes'];
	updateNote: (day: NoteDay, id: string, note: string) => void;
};

export const WeekContentsList = ({
	disabled,
	siteActivities,
	weekActivities,
	activityErrors,
	weekSiteLogHours,
	timesheetHours,
	addActivity,
	updateActivity,
	deleteActivity,
	updateBreaks,
	user,
	notes,
	updateNote,
}: WeekContentsListProps): JSX.Element => {
	return (
		<Stack sx={{ paddingY: 0 }}>
			<NotesSection
				notes={Object.values(notes['All'])}
				editNotes={{
					user,
					onNoteChange: (id, note): void =>
						updateNote('All', id, note),
					disabled,
				}}
				isGeneralNotes
			/>
			{dayStrings.map((day) => (
				<DaySection
					key={day}
					day={day}
					sections={[
						<HoursSection
							key={`${day}-hours`}
							day={day}
							disabled={disabled}
							formattedLogs={weekSiteLogHours[day].formattedLogs}
							siteActivities={siteActivities}
							activities={weekActivities[day]}
							activityErrors={activityErrors}
							timesheetHours={
								timesheetHours[lowercaseDayString(day)]
							}
							addActivity={addActivity}
							updateActivity={updateActivity}
							deleteActivity={deleteActivity}
							updateBreaks={updateBreaks}
						/>,
						<NotesSection
							key={`${day}-notes`}
							notes={Object.values(notes[day])}
							editNotes={{
								user,
								onNoteChange: (id, note): void =>
									updateNote(day, id, note),
								disabled,
							}}
						/>,
					]}
				/>
			))}
		</Stack>
	);
};
