import { Grid, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	selectableAccountTypes,
	workerTypes,
	AccountTypeHumanName,
	Company,
	Site,
	UserDetails,
	CompanyTypes,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { sortObjectByField } from '../helpers/sortHelpers';
import {
	PhoneNumberInput,
	validatePhoneNumber,
} from '../PhoneNumberInput/PhoneNumberInput';

type UserPanelProps = {
	disabled: boolean;
	userTuple: [UserDetails, React.Dispatch<React.SetStateAction<UserDetails>>];
	errorMapTuple: [
		ErrorMapType,
		React.Dispatch<React.SetStateAction<ErrorMapType>>,
	];
	firebaseApi: Pick<
		FirebaseApi,
		| 'companiesSubscription'
		| 'activeSitesSubscription'
		| 'activeSitesByCompanySubscription'
	>;
};

export type EditableUserFields = Pick<
	UserDetails,
	| 'accountType'
	| 'companyID'
	| 'firstname'
	| 'lastname'
	| 'mobileNumber'
	| 'siteID'
	| 'workerType'
	| 'contractedTo'
>;

export type UserFields = Pick<UserDetails, 'email' | 'signedIn'>;

export type ErrorMapType = Record<
	keyof EditableUserFields,
	{ message: string; error: boolean }
>;

type SiteFields = Pick<Site, 'id' | 'name' | 'company' | 'companyID'>;
export const userDisplayEditableFields: Record<
	keyof EditableUserFields,
	string
> = {
	firstname: 'First name',
	lastname: 'Last Name',
	accountType: 'Account',
	mobileNumber: 'Mobile Number',
	companyID: 'Company Name',
	siteID: 'Site Name',
	workerType: 'Employee',
	contractedTo: 'Contracted To',
};

const userDisplayFields: Record<keyof UserFields, string> = {
	email: 'E-mail',
	signedIn: 'Sign In',
};

const isFieldErrored = (
	value: string,
	key: keyof EditableUserFields,
): boolean => {
	switch (key) {
		case 'mobileNumber':
			return !validatePhoneNumber(value);
		case 'siteID':
			return false;
		case 'accountType':
			return false;
		case 'contractedTo':
			return false;
		default:
			return value === '';
	}
};

export const createError = (user: EditableUserFields): ErrorMapType => {
	return Object.fromEntries(
		Object.entries(user).map(([key, value]) => {
			const keyField = key as keyof EditableUserFields;
			return [
				keyField,
				{
					message: `Invalid ${userDisplayEditableFields[keyField]}`,
					error: isFieldErrored(value?.toString() ?? '', keyField),
				},
			];
		}),
	) as ErrorMapType;
};

export const UserPanel = ({
	userTuple: [user, setUser],
	disabled,
	errorMapTuple: [errorMap, setErrorMap],
	firebaseApi,
}: UserPanelProps): JSX.Element => {
	const [sites, setSites] = useState<Record<string, SiteFields>>({});
	const [companies, setCompanies] = useState<Record<string, Company>>({});

	useEffect(() => {
		const companiesSubscription = firebaseApi.companiesSubscription(
			(querySnapshot) => {
				const unsortedCompanies: Record<string, Company> = {};
				querySnapshot.forEach((docSnapshot) => {
					const data = docSnapshot.data() as Company;
					unsortedCompanies[docSnapshot.id] = {
						...data,
						id: docSnapshot.id,
					};
				});
				const sortedCompanies = sortObjectByField(
					unsortedCompanies,
					'name',
				);
				setCompanies(sortedCompanies);
			},
		);
		return companiesSubscription;
	}, [firebaseApi]);

	useEffect(() => {
		const getUsersByCompany =
			companies[user.companyID]?.companyType ===
			CompanyTypes.construction;
		if (getUsersByCompany) {
			return firebaseApi.activeSitesByCompanySubscription(
				user.companyID,
				(siteMap) => {
					const sortedSites = sortObjectByField(siteMap, 'name');
					setSites({
						...sortedSites,
						notSelected: {
							id: 'notSelected',
							name: 'Not Selected',
							company: '',
							companyID: '',
						},
					});
				},
			);
		} else {
			return firebaseApi.activeSitesSubscription((siteMap) => {
				const sortedSites = sortObjectByField(siteMap, 'name');
				setSites({
					...sortedSites,
					notSelected: {
						id: 'notSelected',
						name: 'Not Selected',
						company: '',
						companyID: '',
					},
				});
			});
		}
	}, [user.companyID, companies, firebaseApi]);

	const handleContractedTo = (
		company: Company,
		siteID: string,
	): UserDetails['contractedTo'] => {
		let contractedTo: { id: string; name: string } | null = null;
		if (company.companyType === CompanyTypes.construction) {
			contractedTo = {
				id: company.id,
				name: company.name,
			};
		}
		if (company.companyType === CompanyTypes.recruitment) {
			if (siteID === '') {
				contractedTo = {
					id: '',
					name: '',
				};
			} else {
				const selectedSite = sites[siteID];
				contractedTo = {
					id: selectedSite.companyID,
					name: selectedSite.company,
				};
			}
		}
		return contractedTo;
	};

	const handleDetailChange = (
		value: string,
		key: keyof EditableUserFields,
	): void => {
		setErrorMap((prev) => ({
			...prev,
			[key]: {
				message: prev[key].message,
				error: isFieldErrored(value, key),
			},
		}));
		setUser((prev) => {
			if (key === 'contractedTo') {
				return {
					...prev,
					[key]:
						value !== 'notContracted'
							? {
									name: companies[value].name,
									id: companies[value].id,
							  }
							: null,
				};
			}
			return {
				...prev,
				[key]: value,
			};
		});
		if (key === 'companyID') {
			const selectedCompany = companies[value];
			const currentSiteID = '';
			setUser((prev) => ({
				...prev,
				company: selectedCompany.name,
				companyID: selectedCompany.id,
				site: '',
				siteID: '',
				siteCompany: '',
				siteCompanyID: '',
				contractedTo: handleContractedTo(
					selectedCompany,
					currentSiteID,
				),
			}));
		}
		if (key === 'siteID') {
			const selectedSite = sites[value];
			const currentCompany = companies[user.companyID];
			if (value !== 'notSelected') {
				setUser((prev) => ({
					...prev,
					site: selectedSite.name,
					siteID: selectedSite.id,
					siteCompany: selectedSite.company,
					siteCompanyID: selectedSite.companyID,
					contractedTo: handleContractedTo(
						currentCompany,
						selectedSite.id,
					),
				}));
			} else {
				setUser((prev) => ({
					...prev,
					site: '',
					siteID: '',
					siteCompany: '',
					siteCompanyID: '',
					contractedTo: handleContractedTo(
						currentCompany,
						selectedSite.id,
					),
				}));
			}
		}
	};

	return (
		<>
			<Grid container spacing={2}>
				{Object.entries(userDisplayEditableFields).map(
					([key, value]) => {
						const field = key as keyof EditableUserFields;
						const isError = errorMap[field].error;
						if (field === 'firstname' || field === 'lastname') {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										id="filled-basic"
										label={value}
										variant="outlined"
										fullWidth
										value={user[field]}
										onChange={(event): void =>
											handleDetailChange(
												event.target.value,
												field,
											)
										}
										disabled={disabled}
										InputLabelProps={{ shrink: true }}
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
									/>
								</Grid>
							);
						} else if (key === 'mobileNumber') {
							return (
								<Grid item xs={6} key={key}>
									<PhoneNumberInput
										value={user['mobileNumber']}
										onChange={(event): void => {
											if (typeof event === 'string') {
												handleDetailChange(event, key);
											}
										}}
										label="Mobile Number"
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={disabled}
									/>
								</Grid>
							);
						} else if (field === 'accountType') {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										value={user[field]}
										label={value}
										variant="outlined"
										fullWidth
										onChange={(event): void => {
											handleDetailChange(
												event.target.value,
												field,
											);
										}}
										select
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={disabled}>
										{Object.values(
											selectableAccountTypes,
										).map((type) => (
											<MenuItem key={type} value={type}>
												{AccountTypeHumanName[type]}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							);
						} else if (
							field === 'companyID' &&
							Object.values(companies).length > 0
						) {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										value={user[field]}
										label={value}
										variant="outlined"
										fullWidth
										onChange={(event): void =>
											handleDetailChange(
												event.target.value,
												field,
											)
										}
										select
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={disabled}>
										{Object.values(companies).map(
											(company) => (
												<MenuItem
													key={company.id}
													value={company.id}>
													{company.name}
												</MenuItem>
											),
										)}
									</TextField>
								</Grid>
							);
						} else if (
							field === 'siteID' &&
							Object.values(sites).length > 0
						) {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										value={
											user[field] === ''
												? 'notSelected'
												: user[field]
										}
										label={
											Object.keys(sites).length === 0
												? 'No Sites Available'
												: 'Site'
										}
										variant="outlined"
										fullWidth
										onChange={(event): void =>
											handleDetailChange(
												event.target.value,
												field,
											)
										}
										select
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={
											disabled ||
											Object.keys(sites).length === 0
										}>
										{Object.values(sites).map((site) => (
											<MenuItem
												key={site.id}
												value={site.id}>
												{site.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							);
						} else if (field === 'workerType') {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										value={user[field] ?? '-'}
										label={value}
										variant="outlined"
										fullWidth
										onChange={(event): void =>
											handleDetailChange(
												event.target.value,
												field,
											)
										}
										select
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={disabled}>
										{Object.values(workerTypes).map(
											(type) => (
												<MenuItem
													key={type}
													value={type}>
													{type}
												</MenuItem>
											),
										)}
									</TextField>
								</Grid>
							);
						} else if (key === 'contractedTo') {
							return (
								<Grid item xs={6} key={key}>
									<TextField
										value={
											Object.values(companies).length ===
											0
												? ''
												: user['contractedTo']?.id ??
												  'notContracted'
										}
										label={value}
										variant="outlined"
										fullWidth
										onChange={(event): void =>
											handleDetailChange(
												event.target.value,
												field,
											)
										}
										select
										error={isError}
										helperText={
											isError
												? errorMap[field].message
												: ''
										}
										disabled={disabled}>
										<MenuItem
											key="notContracted"
											value="notContracted">
											Not Contracted
										</MenuItem>
										{Object.values(companies).map(
											(company) => (
												<MenuItem
													key={company.id}
													value={company.id}>
													{company.name}
												</MenuItem>
											),
										)}
									</TextField>
								</Grid>
							);
						} else {
							// explicit return
							return undefined;
						}
					},
				)}
				{Object.entries(userDisplayFields).map(([key, value]) => {
					const field = key as keyof UserFields;
					return (
						<Grid item xs={6} key={key}>
							<TextField
								id="filled-basic"
								label={value}
								variant="outlined"
								fullWidth
								value={user[field]}
								disabled
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};
