import { useCallback, useState } from 'react';
import { UserDetails } from '../../../../constants/Common';
import {
	SafetyCourse,
	SafetyCourseType,
	SafetyCourseValidationResponse,
} from '../../../../constants/SafetyCourse';
import { safetyCourseToResponse } from '../../../../constants/SafetyCourseUtilities';
import { SafetyCourseDialog } from '../SafetyCourseDialog/SafetyCourseDialog';
import { SafetyCourseValidationCard } from '../SafetyCourseValidationCard/SafetyCourseValidationCard';

export type SafetyCourseUpdateDialogProps = {
	open: boolean;
	user: UserDetails;
	safetyCourse: SafetyCourse;
	onValidate: (
		courseType: SafetyCourseType,
		courseID: string,
	) => Promise<{
		safetyCourseResponse: SafetyCourseValidationResponse | undefined;
		duplicateSafetyCourse: SafetyCourse | undefined;
	}>;
	onClose: () => void;
	onSave: (
		userID: string,
		safetyCourse: SafetyCourseValidationResponse,
	) => Promise<boolean>;
};

export const SafetyCourseUpdateDialog = ({
	open,
	user,
	safetyCourse,
	onValidate,
	onClose,
	onSave,
}: SafetyCourseUpdateDialogProps): JSX.Element => {
	const [updatedSafetyCourseResponse, setUpdatedSafetyCourseResponse] =
		useState<SafetyCourseValidationResponse | null>(
			safetyCourseToResponse(safetyCourse.course),
		);
	const [loading, setLoading] = useState(false);
	const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
	const [duplicateSafetyCourse, setDuplicateSafetyCourse] = useState<
		SafetyCourse | undefined
	>();

	const errorSnackbarMessage = 'Failed to update the Safety Course.';

	const duplicateAlert =
		duplicateSafetyCourse && user?.displayName
			? `Another employee (${duplicateSafetyCourse.worker.name}) is already using this Safety Course ID. Please try again with ${user.displayName}'s ID.`
			: undefined;

	const disabled =
		updatedSafetyCourseResponse === null ||
		(updatedSafetyCourseResponse &&
			safetyCourse.course.id === updatedSafetyCourseResponse.id) ||
		duplicateSafetyCourse !== undefined;

	const handleValidate = useCallback(
		async (
			courseType: SafetyCourseType,
			courseID: string,
		): Promise<void> => {
			setDuplicateSafetyCourse(undefined);
			const { safetyCourseResponse, duplicateSafetyCourse } =
				await onValidate(courseType, courseID);

			setUpdatedSafetyCourseResponse(
				safetyCourseResponse !== undefined
					? safetyCourseResponse
					: null,
			);
			setDuplicateSafetyCourse(duplicateSafetyCourse);
		},
		[onValidate],
	);

	const handleConfirm = async (): Promise<void> => {
		if (updatedSafetyCourseResponse) {
			setLoading(true);

			const success = await onSave(
				user.userID,
				updatedSafetyCourseResponse,
			);
			if (success) {
				handleClose();
			} else {
				setErrorSnackbarOpen(true);
			}

			setLoading(false);
		}
	};

	const handleClose = (): void => {
		setUpdatedSafetyCourseResponse(
			safetyCourseToResponse(safetyCourse.course),
		);
		setDuplicateSafetyCourse(undefined);
		onClose();
	};

	const handleCloseSnackBar = (): void => setErrorSnackbarOpen(false);

	return (
		<SafetyCourseDialog
			open={open}
			loading={loading}
			disabled={disabled}
			errorSnackbarOpen={errorSnackbarOpen}
			errorSnackbarMessage={errorSnackbarMessage}
			handleCloseSnackBar={handleCloseSnackBar}
			handleClose={handleClose}
			handleConfirm={handleConfirm}>
			<SafetyCourseValidationCard
				validateSafetyCourse={handleValidate}
				safetyCourse={updatedSafetyCourseResponse}
				duplicateAlert={duplicateAlert}
			/>
		</SafetyCourseDialog>
	);
};
