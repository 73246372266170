import type firebase from 'firebase';
import { Logger } from '../providers/logging/context';

export class CloudFunctionError extends Error {
	constructor(readonly status: number, readonly message: string) {
		super(message);
		this.name = 'CloudFunctionError';
		this.status = status;
	}
}

export type CloudFunctionServices = {
	/**  Delayed fetch import so that it can assign from window.fetch in browsers */
	getFetch: () => typeof fetch;
	logger: Pick<Logger, 'error'>;
};

export type CloudFunctionAPI<T = undefined> = {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	user: firebase.User;
	abortSignal: AbortSignal;
	services: CloudFunctionServices;
	body?: T;
};

export const defaultCloudFunctionServices: CloudFunctionServices = {
	getFetch: () => fetch,
	logger: console,
};

const makeCloudFunctionRequestBase = async <BodyType = undefined>({
	abortSignal,
	user,
	url,
	method,
	services,
	body,
}: CloudFunctionAPI<BodyType>): Promise<Response> => {
	const apiRequestOptions: RequestInit = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${await user.getIdToken()}`,
		},
		body: body !== undefined ? JSON.stringify(body) : undefined,
		signal: abortSignal,
	};

	const cloudFunctionFetch = services.getFetch();
	const response = await cloudFunctionFetch(url, apiRequestOptions);
	if (response.ok) {
		return response;
	} else {
		const text = await response.text();
		let message: string;
		let logMessage: string;
		try {
			message = JSON.parse(text).message ?? text;
			logMessage = 'API Request failed with response,';
		} catch (_) {
			message = text;
			logMessage = 'API Request failed with non-api response,';
		}

		services.logger.error(
			logMessage,
			`${response.status}: ${response.statusText}`,
			message,
		);

		throw new CloudFunctionError(response.status, message);
	}
};

export const makeCloudFunctionRequest = async <
	ReturnType = undefined,
	BodyType = undefined,
>(
	props: CloudFunctionAPI<BodyType>,
): Promise<ReturnType> => {
	const response = await makeCloudFunctionRequestBase<BodyType>(props);

	return await response.json();
};

/**
 * @deprecated Returns `undefined` in case of error instead of throwing until TL-1607 implemented.
 *
 * Migrate to using `makeCloudFunctionRequest`
 */
export const makeCloudFunctionRequestDeprecated = async <
	ReturnType = undefined,
	BodyType = undefined,
>(
	props: CloudFunctionAPI<BodyType>,
): Promise<ReturnType | undefined> => {
	try {
		const response = await makeCloudFunctionRequestBase<BodyType>(props);
		return response.json();
	} catch (_) {
		return undefined;
	}
};

/**
 * Returns whether the function completed succesfully with a 2XX code
 */
export const makeCloudFunctionRequestReturnSuccessStatus = async <
	BodyType = undefined,
>(
	props: CloudFunctionAPI<BodyType>,
): Promise<boolean> => {
	try {
		const response = await makeCloudFunctionRequestBase<BodyType>(props);
		return response.ok;
	} catch (_) {
		return false;
	}
};

export const makeCloudFunctionRequestReturnBlob = async <BodyType = undefined>(
	props: CloudFunctionAPI<BodyType>,
): Promise<Blob> => {
	const response = await makeCloudFunctionRequestBase<BodyType>(props);

	return await response.blob();
};
