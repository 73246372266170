// Functions for different number input formats that are useful and shared across the app.
// These functions provide the inputComponent to the InputProps in textFields and input components.
import React from 'react';
import NumberFormat from 'react-number-format';
import { FixMeLater } from '../../constants/AnyTypes';

/*
  This number input format is used in the PPE Store and restricts the input number limit
  user can choose when purchasing items. 
*/
export const PPEStoreQtyFormat = React.forwardRef(function PPEStoreQtyFormat(
	props: FixMeLater,
	inputRef,
) {
	const { onChange, itemlimit, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			allowNegative={false}
			onValueChange={(values): void => {
				onChange({
					target: {
						name: props.name,
						value: Number(values.value),
					},
				});
			}}
			isAllowed={(values): boolean => {
				return (
					parseFloat(values.value) >= 0 && values.value <= itemlimit
				);
			}}
			isNumericString
		/>
	);
});
