import SyncIcon from '@mui/icons-material/Sync';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { useAbortController } from '../../../hooks/useAbortController';

type RefreshPayrollListBtnProps = {
	fetchList: (abortSignal: AbortSignal) => Promise<void>;
};

export const RefreshPayrollListBtn = ({
	fetchList,
}: RefreshPayrollListBtnProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [disableRefresh, setDisableRefresh] = useState(false);

	const handleRefreshPayrollAccounts = async (): Promise<void> => {
		setDisableRefresh(true);
		await fetchList(abortSignal);
		if (!abortSignal.aborted) {
			setDisableRefresh(false);
		}
	};

	return (
		<LoadingButton
			onClick={handleRefreshPayrollAccounts}
			disabled={disableRefresh}
			loading={disableRefresh}
			sx={{ minWidth: '0px' }}>
			<Tooltip title="Refresh Column">
				<SyncIcon />
			</Tooltip>
		</LoadingButton>
	);
};
