import { Typography, Button, Stack, Paper, Box, Tooltip } from '@mui/material';
import { InductionStep } from '../../../constants/InductionStep';
import Image from '../../Image/Image';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';

export type InductionStepCardProps = {
	step: InductionStep;
	position: number;
	totalSteps: number;
	onClick: () => void;
	imgUrl?: string;
};

export const InductionStepCard = ({
	step,
	position,
	totalSteps,
	onClick,
	imgUrl,
}: InductionStepCardProps): JSX.Element => {
	return (
		<Paper sx={{ maxWidth: '300px', width: '300px' }}>
			<Stack spacing={1} sx={{ height: '500px' }}>
				<Box height="320px">
					{!imgUrl ? (
						<LoadingDots />
					) : (
						<Image
							src={imgUrl}
							alt="Induction Step Image"
							imageStyle={{
								objectFit: 'cover',
								height: '320px',
							}}
						/>
					)}
				</Box>
				<Stack
					paddingX={2}
					direction="row"
					justifyContent="space-between">
					<Tooltip title={step.title}>
						<Typography
							variant="h6"
							fontWeight="bold"
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis">
							{step.title}
						</Typography>
					</Tooltip>
					<Typography marginLeft={2} variant="h6" fontWeight="bold">
						{position}/{totalSteps}
					</Typography>
				</Stack>
				<Typography
					sx={{ flex: 2 }}
					paddingX={2}
					variant="body2"
					color="textSecondary"
					overflow="auto"
					whiteSpace="break-spaces">
					{step.body}
				</Typography>
				<Box paddingX={1} paddingBottom={1} alignSelf="flex-end">
					<Button onClick={onClick} color="primary">
						Edit
					</Button>
				</Box>
			</Stack>
		</Paper>
	);
};
