import { useEffect, useState } from 'react';
import {
	Site,
	TimesheetActivity,
	UserProps,
} from '../../../../constants/Common';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { SiteActivities, SiteActivitiesFirebaseCalls } from './SiteActivities';

type AvailableFirebaseCalls = Extract<
	keyof FirebaseApi,
	SiteActivitiesFirebaseCalls
>;

export const SiteActivitiesWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	const {
		context: { firebaseApi },
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const [siteActivities, setSiteActivities] = useState<TimesheetActivity[]>(
		[],
	);

	useEffect(() => {
		if (!userDetails.siteID || userDetails.siteID === '') {
			setSiteActivities([]);
			return;
		}
		const siteCallback = (site: Site): void => {
			if (site.timesheetActivitiesV2)
				setSiteActivities(site.timesheetActivitiesV2);
		};

		return firebaseApi.siteSubscription(userDetails.siteID, siteCallback);
	}, [userDetails.siteID, firebaseApi]);

	return (
		<SiteActivities
			userDetails={userDetails}
			siteActivities={siteActivities}
			firebaseApi={firebaseApi}
		/>
	);
};
