import EditIcon from '@mui/icons-material/Edit';
import {
	Divider,
	Grid,
	IconButton,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';
import { PayrollIntegrationAtRest } from '../../../../models/Integrations/PayrollIntegration';
import { OvertimeDialog } from './OvertimeDialog';

export type OvertimeFooterProps = {
	loading: boolean;
	integration: PayrollIntegrationAtRest;
	integrationPayOptions: Record<string, IntegrationElement>;
	firebaseApi: Pick<FirebaseApi, 'updatePayrollOvertime'>;
};

export const OvertimeFooter = ({
	loading,
	integration,
	integrationPayOptions,
	firebaseApi,
}: OvertimeFooterProps): JSX.Element => {
	const [open, setOpen] = useState<boolean>(false);

	const enabled = integration.overtimeThreshold !== undefined;
	const currentOvertimePayOption =
		integrationPayOptions[
			integration.activityTypes['Overtime']?.integrationID
		] ?? null;

	const toggleOvertimeEnabled = async (): Promise<void> => {
		if (!enabled) {
			setOpen(true);
		} else {
			await firebaseApi.updatePayrollOvertime(integration.companyID, {
				overtimeThreshold: undefined,
				activityTypes: integration.activityTypes,
			});
		}
	};

	const saveOvertimeConfiguration = async (
		payOption: IntegrationElement,
		overtimeThreshold: number,
	): Promise<void> => {
		await firebaseApi.updatePayrollOvertime(integration.companyID, {
			overtimeThreshold,
			activityTypes: {
				...integration.activityTypes,
				Overtime: {
					id: 'Overtime',
					name: 'Overtime',
					integrationID: payOption.id,
					integrationName: payOption.name,
				},
			},
		});
	};

	return (
		<Stack>
			<Divider />
			<Grid
				container
				alignItems="center"
				spacing={1}
				paddingX={2}
				paddingY={1}>
				<Grid item xs={6} display="flex">
					<Typography>Overtime Pay Enabled</Typography>
				</Grid>
				<Grid item xs={6} display="flex" justifyContent="flex-end">
					<Switch
						checked={enabled}
						disabled={loading}
						onClick={toggleOvertimeEnabled}
					/>
				</Grid>
				{enabled && (
					<>
						<Grid item xs={6} display="flex">
							<Typography>Overtime Threshold</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							display="flex"
							justifyContent="flex-end">
							<TextField
								type="tel"
								size="small"
								value={`${
									integration.overtimeThreshold ?? 'Unknown'
								} hrs`}
								disabled={true}
								inputProps={{
									min: 0,
									sx: {
										textAlign: 'right',
										maxWidth: 60,
									},
								}}
							/>
							<IconButton
								onClick={(): void => setOpen(true)}
								color="primary"
								sx={{ marginX: 1 }}
								disabled={loading}>
								<EditIcon fontSize="small" />
							</IconButton>
						</Grid>
					</>
				)}
			</Grid>
			{open && (
				<OvertimeDialog
					open={open}
					initialOvertimeThreshold={
						integration.overtimeThreshold ?? null
					}
					initialOvertimePayOption={currentOvertimePayOption}
					integrationPayOptions={integrationPayOptions}
					handleClose={(): void => setOpen(false)}
					handleSave={saveOvertimeConfiguration}
				/>
			)}
		</Stack>
	);
};
