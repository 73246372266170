import { Grid, RegularBreakpoints } from '@mui/material';
import { ReactNode } from 'react';

export type TimesheetHeaderItemProps = {
	children: ReactNode;
	justifyContent?: Record<
		Extract<NonNullable<keyof RegularBreakpoints>, 'xs' | 'sm' | 'md'>,
		'start' | 'center' | 'end'
	>;
	breakpoints?: RegularBreakpoints;
};

export const TimesheetHeaderItem = ({
	children,
	justifyContent,
	breakpoints,
}: TimesheetHeaderItemProps): JSX.Element => {
	return (
		<Grid
			item
			display="flex"
			md={4}
			sm={4}
			xs={12}
			{...breakpoints}
			justifyContent={justifyContent}>
			{children}
		</Grid>
	);
};
