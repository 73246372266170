import { DefaultTheme } from '../../Theme';

export const rgbToHex = (red: number, green: number, blue: number): string =>
	'#' +
	[red, green, blue]
		.map((colourChannel) => {
			const hex = colourChannel.toString(16);
			return hex.length === 1 ? '0' + hex : hex;
		})
		.join('');

export const hexToRgb = (hex: string): [number, number, number] | null => {
	const rgb = (
		hex
			.replace(
				/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
				(_: string, red: string, green: string, blue: string) =>
					'#' + red + red + green + green + blue + blue,
			)
			.substring(1)
			.match(/.{2}/g) ?? []
	).map((colourChannel: string) => parseInt(colourChannel, 16));
	return rgb.length === 3 ? [rgb[0], rgb[1], rgb[2]] : null;
};

// https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
const luminance = (rgb: [r: number, g: number, b: number]): number => {
	const relLuminance = rgb.map((colorValue) => {
		colorValue /= 255;
		return colorValue <= 0.03928
			? colorValue / 12.92
			: Math.pow((colorValue + 0.055) / 1.055, 2.4);
	});
	return (
		relLuminance[0] * 0.2126 +
		relLuminance[1] * 0.7152 +
		relLuminance[2] * 0.0722
	);
};

const accessibleContrast = (color1Hex: string, color2Hex: string): boolean => {
	const rgb1 = hexToRgb(color1Hex);
	const rgb2 = hexToRgb(color2Hex);
	if (rgb1 === null || rgb2 === null) return false;
	const luminance1 = luminance(rgb1);
	const luminance2 = luminance(rgb2);
	const ratio =
		luminance1 > luminance2
			? (luminance2 + 0.05) / (luminance1 + 0.05)
			: (luminance1 + 0.05) / (luminance2 + 0.05);
	return ratio < 1 / 4; // WCAG test 1/3, ratio (3.0:1) for text in AA-level, we lower this a little more for our convenience
};

// This is voodoo magic to get a color hash out of a string
// best to ignore the bitwise happenings here
// We've added a accessibility contrast check
export const stringToColor = (name: string): string => {
	let hash = 0;

	for (let i = 0; i < name.length; i += 1) {
		hash = name.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (let i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	if (!accessibleContrast(color, DefaultTheme.palette.info.contrastText)) {
		// Go again with some predictable entropy
		return stringToColor(name + color);
	}

	return color;
};
