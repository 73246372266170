import { EmailTemplateRecipients } from '../../constants/EmailTemplateRecipients';
import { Version } from '../../models/Version';
import { Firestore } from '../firebase';

const WEBSITE_DATA_COLLECTION = 'websiteData';

const getEmails = async (): Promise<EmailTemplateRecipients | null> => {
	const emailsDoc = await Firestore.collection(WEBSITE_DATA_COLLECTION)
		.doc('emails')
		.get();
	if (emailsDoc.exists) {
		return emailsDoc.data() as EmailTemplateRecipients;
	} else {
		return null;
	}
};

const getVersion = async (): Promise<Version> => {
	const versionDoc = await Firestore.collection(WEBSITE_DATA_COLLECTION)
		.doc('version')
		.get();
	const version = versionDoc.data() as Version;
	return version;
};

const versionSubscription = (
	callback: (version: Version) => void,
): (() => void) =>
	Firestore.collection(WEBSITE_DATA_COLLECTION)
		.doc('version')
		.onSnapshot((snapshot) => {
			const version = snapshot.data() as Version;
			callback(version);
		});

const websiteDataFirebaseApi = {
	getEmails,
	getVersion,
	versionSubscription,
};

export default websiteDataFirebaseApi;
