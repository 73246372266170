import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Chip, Tooltip } from '@mui/material';
import {
	safetyCourseStatus,
	SafetyCourseStatus,
} from '../../../../constants/SafetyCourse';

export type SafetyCourseStatusChipProps = {
	status: SafetyCourseStatus;
};

export const SafetyCourseStatusChip = ({
	status,
}: SafetyCourseStatusChipProps): JSX.Element => {
	switch (status) {
		case safetyCourseStatus.Expired:
			return (
				<ExpiredTooltip>
					<Chip
						icon={<ErrorIcon />}
						label="Expired"
						size="small"
						color="error"
					/>
				</ExpiredTooltip>
			);
		case safetyCourseStatus.Valid:
			return (
				<ValidTooltip>
					<Chip
						icon={<CheckCircleIcon />}
						label="Valid"
						size="small"
						color="success"
					/>
				</ValidTooltip>
			);
		case safetyCourseStatus.NotFound:
			return (
				<NotFoundTooltip>
					<Chip
						icon={<ErrorIcon />}
						label="Not Found"
						size="small"
						color="neutral"
					/>
				</NotFoundTooltip>
			);
	}
};

export const ExpiredTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => (
	<Tooltip title="Safety Course is Expired">{children}</Tooltip>
);

export const ValidTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => <Tooltip title="Safety Course is Valid">{children}</Tooltip>;

export const NotFoundTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => (
	<Tooltip title="Safety Course Not Found">{children}</Tooltip>
);
