import { createTheme, Theme, ThemeProvider } from '@mui/material';

export const TimesheetsTableTheme = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => (
	<ThemeProvider
		theme={(baseTheme: Theme): Theme =>
			createTheme({
				...baseTheme,
				components: {
					...baseTheme.components,
					// MUIDataTable styles are not recognised by the MUI Theme
					// This is a hacky workaround, if their types were good we would not need this
					MUIDataTableHeadCell: {
						styleOverrides: {
							root: {
								...{
									// This is fragile styling as if columns change too much this breaks.
									// Currently, columns are okay.
									// We have to apply some custom styling to the table column headers here as
									// MUIDataTable doesn't let us do a custom render without losing the default styling
									'&:nth-of-type(n+7),&:nth-of-type(4)': {
										'& span': {
											display: 'flex',
											justifyContent: 'center',
											margin: 0,
										},
										'& button': {
											margin: 0,
										},
									},
									'&:nth-of-type(5)': {
										'& span': {
											display: 'flex',
											justifyContent: 'flex-end',
											margin: 0,
										},
										'& button': {
											margin: 0,
										},
									},
								},
							},
						},
					},
				},
			})
		}>
		{children}
	</ThemeProvider>
);
