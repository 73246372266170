import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';

export type IntegrationPublishDialogProps = {
	open: boolean;
	loading: boolean;
	integrationType: string;
	handlePublish: () => void;
	handleCancel: () => void;
};

export const IntegrationPublishDialog = ({
	open,
	loading,
	integrationType,
	handlePublish,
	handleCancel,
}: IntegrationPublishDialogProps): JSX.Element => (
	<Dialog open={open}>
		<DialogTitle>Confirm</DialogTitle>
		<DialogContent>
			{loading ? (
				<LoadingDots />
			) : (
				`Confirm that you give Trade Legion permission to use your ${integrationType} connection.`
			)}
		</DialogContent>
		<DialogActions>
			<Button
				variant="outlined"
				onClick={handleCancel}
				disabled={loading}>
				Cancel
			</Button>
			<Button
				variant="contained"
				onClick={handlePublish}
				disabled={loading}>
				Confirm
			</Button>
		</DialogActions>
	</Dialog>
);
