import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';

type DeleteTimesheetModalProps = {
	deleteOpen: boolean;
	onClose: () => void;
	deleteTimesheet: () => Promise<void>;
};

export const DeleteTimesheetModal = ({
	deleteOpen,
	deleteTimesheet,
	onClose,
}: DeleteTimesheetModalProps): JSX.Element => {
	const [deleting, setDeleting] = useState<boolean>(false);
	const [isMounted, setIsMounted] = useState<boolean>(true);

	// To avoid other state updates after deleting last timesheet
	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	return (
		<Dialog open={deleteOpen}>
			<DialogTitle>Delete Timesheet</DialogTitle>
			<DialogContent>
				<DialogContentText fontSize="small">
					Are you sure you want to permanently delete this timesheet?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					disabled={deleting}
					onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					color="error"
					disabled={deleting}
					loading={deleting}
					onClick={async (): Promise<void> => {
						setDeleting(true);
						await deleteTimesheet();
						if (isMounted) setDeleting(false);
					}}>
					Delete
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
