import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { Site, SiteStatus } from '../../../../constants/Common';
import { User } from '../../../../firebase/firebase';
import { useAbortController } from '../../../../hooks/useAbortController';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { SitePanel } from '../SitePanel/SitePanel';
import {
	EditableSiteFields,
	ErrorMapType,
	createError,
} from '../SitePanel/sitePanelHelpers';
import { ArchiveSiteDialog } from './ArchiveSiteDialog';

export type EditSiteDetailsProps = {
	user: User;
	site: Site;
	allowArchiveSite: boolean;
	allowEditSite: boolean;
	saveSiteCallback?: (name: string) => void;
	cloudFunctionApi: Pick<CloudFunctionApi, 'updateSite' | 'archiveSite'>;
};

export const EditSiteDetails = ({
	user,
	site,
	allowArchiveSite,
	allowEditSite,
	saveSiteCallback,
	cloudFunctionApi,
}: EditSiteDetailsProps): React.ReactElement => {
	const abortSignal = useAbortController();
	const [siteDetails, setSiteDetails] = useState<Partial<EditableSiteFields>>(
		{},
	);
	const [errorMap, setErrorMap] = useState<Partial<ErrorMapType>>({});
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [archiveSiteloading, setArchiveSiteLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [editSiteFailed, setEditSiteFailed] = useState(false);

	const saveSiteDetails = async (): Promise<void> => {
		const isValid = Object.values(errorMap).every((value) => !value.error);
		if (isValid && Object.keys(siteDetails).length > 0) {
			setLoading(true);
			await cloudFunctionApi.updateSite(
				abortSignal,
				user,
				site.id,
				siteDetails,
			);

			saveSiteCallback?.(
				siteDetails?.name ? siteDetails.name : site.name,
			);
			setSiteDetails({});
			setLoading(false);
		}
		setIsEditing(false);
	};

	const handleUpdateSite = (value: Partial<EditableSiteFields>): void => {
		setSiteDetails((prev) => ({ ...prev, ...value }));
	};

	const handleUpdateErrorMap = (value: Partial<ErrorMapType>): void => {
		setErrorMap((prev) => ({ ...prev, ...value }));
	};

	const archiveSite = async (): Promise<void> => {
		setArchiveSiteLoading(true);
		const success = await cloudFunctionApi.archiveSite(
			abortSignal,
			user,
			site.id,
		);
		if (!success) {
			setEditSiteFailed(true);
		}
		setOpen(false);
		setIsEditing(false);
		setArchiveSiteLoading(false);
	};

	return (
		<>
			<Card variant="outlined">
				<CardHeader
					title={`${site?.name} Details`}
					action={
						allowEditSite &&
						(!isEditing ? (
							<Tooltip title="Edit">
								<IconButton
									onClick={(): void => {
										setIsEditing(true);
									}}
									disabled={
										site.status === SiteStatus.Archived
									}
									color="primary">
									<EditIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Stack direction="row" spacing={1}>
								<Tooltip title="Save">
									<Box>
										<IconButton
											onClick={(): Promise<void> =>
												saveSiteDetails()
											}
											disabled={
												loading ||
												Object.values(errorMap).some(
													(value) => value.error,
												)
											}
											color="primary">
											{loading ? (
												<CircularProgress size={24} />
											) : (
												<SaveIcon />
											)}
										</IconButton>
									</Box>
								</Tooltip>
								<Tooltip title="Cancel">
									<IconButton
										disabled={loading}
										onClick={(): void => {
											setIsEditing(false);
											setSiteDetails(site as Site);
											setErrorMap(createError(site));
										}}
										color="primary">
										<CancelIcon />
									</IconButton>
								</Tooltip>
							</Stack>
						))
					}
				/>
				<CardContent sx={{ px: 3 }}>
					<SitePanel
						site={site}
						updatedSite={siteDetails}
						setUpdatedSite={handleUpdateSite}
						disabled={loading || !isEditing}
						errorMap={errorMap}
						setErrorMap={handleUpdateErrorMap}
						openArchiveSiteDialog={
							allowArchiveSite
								? (): void => setOpen(true)
								: undefined
						}
					/>
				</CardContent>
			</Card>
			{allowArchiveSite && (
				<>
					<ArchiveSiteDialog
						open={open}
						setOpen={setOpen}
						archiveSiteloading={archiveSiteloading}
						archiveSite={archiveSite}
					/>
					<CustomSnackBar
						open={editSiteFailed}
						onClose={(): void => setEditSiteFailed(false)}
						snackBarText="Archive Site Failed"
						severity="error"
					/>
				</>
			)}
		</>
	);
};
