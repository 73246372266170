import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import {
	IntegrationClass,
	IntegrationType,
} from '../../../models/Integrations/Integration';
import { InvoicingType } from '../../../models/Integrations/InvoicingIntegration';
import { PayrollType } from '../../../models/Integrations/PayrollIntegration';

type NavigateToIntegrationDialogProps = {
	openNavigateToIntegrationDialogState: [
		boolean,
		Dispatch<SetStateAction<boolean>>,
	];
	handleNavigateToIntegration: () => void;
	integrations: {
		integrationName: IntegrationType;
		integrationClass: IntegrationClass;
		otherIntegrationName: Partial<{
			payrollIntegration: PayrollType;
			invoicingIntegration: InvoicingType;
		}>;
	};
};

export const NavigateToIntegrationDialog = ({
	openNavigateToIntegrationDialogState,
	handleNavigateToIntegration,
	integrations,
}: NavigateToIntegrationDialogProps): JSX.Element => {
	const [
		openNavigateToIntegrationDialog,
		setOpenNavigateToIntegrationDialog,
	] = openNavigateToIntegrationDialogState;

	const { integrationName, integrationClass, otherIntegrationName } =
		integrations;
	const otherIntegrationType = otherIntegrationName.payrollIntegration
		? otherIntegrationName.payrollIntegration
		: otherIntegrationName.invoicingIntegration;
	const otherIntegrationClass =
		integrationClass === 'Payroll' ? 'Invoicing' : 'Payroll';

	return (
		<Dialog open={openNavigateToIntegrationDialog}>
			<DialogTitle>{`Go to ${otherIntegrationClass}`}</DialogTitle>
			<DialogContent>
				{`Unfortunately, ending your ${integrationName} ${integrationClass} integration requires reauthenticating your ${otherIntegrationType} ${otherIntegrationClass} integration once more`}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void =>
						setOpenNavigateToIntegrationDialog(false)
					}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={handleNavigateToIntegration}>
					{`Go to ${otherIntegrationClass}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
