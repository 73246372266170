import { LoadingButton } from '@mui/lab';
import {
	AlertColor,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';
import { addWeeks, endOfWeek } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { ExportErrors } from '../../../../cloudfunctions/projectTracking';
import { Site, UserDetails } from '../../../../constants/Common';
import {
	ActivityOption,
	activityOptions,
	isActivityOption,
	siteOption,
} from '../../../../constants/ProjectTrackingExport';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import DateWeekSelector from '../../../DateWeekSelector/DateWeekSelector';
import { formatSearchParamsDate } from '../../../helpers/dateFormatters';
import { ExportStatuses } from '../../../helpers/fileDownloads';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { ErrorDialog } from './ErrorDialog';

export type ExportProps = {
	user: User;
	userDetails: UserDetails;
	abortSignal: AbortSignal;
	exportTitle: string;
	firebaseApi: Pick<
		FirebaseApi,
		| 'siteSubscription'
		| 'activeSitesByCompanySubscription'
		| 'downloadStorageFileFromLink'
		| 'deleteStorageFile'
		| 'getTimesheetById'
	>;
	cloudFunctionApi: Pick<CloudFunctionApi, 'generateProjectTrackingExport'>;
};

export const Export = ({
	user,
	userDetails,
	abortSignal,
	exportTitle,
	firebaseApi,
	cloudFunctionApi,
}: ExportProps): JSX.Element => {
	const singleSite = userDetails.accountType === 'management';

	const [endDate, setEndDate] = useState<Date>(
		addWeeks(endOfWeek(new Date()), -1),
	);
	const [allOrOneSite, setAllOrOneSite] = useState<string>(
		singleSite ? userDetails.siteID : 'All',
	);
	const [activityFilter, setActivityFilter] = useState<ActivityOption>('All');
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [exportStatus, setExportStatus] = useState<ExportStatuses>('none');
	const [siteLoading, setSiteLoading] = useState<boolean>(true);
	const [errorDialogOpen, setErrorDialogOpen] = useState(false);
	const [errors, setErrors] = useState<ExportErrors[]>([]);

	const snackBarContent: Record<
		ExportStatuses,
		{ text: string; severity: AlertColor }
	> = {
		none: { severity: 'info', text: '' },
		zipEmpty: {
			severity: 'error',
			text: 'No data was found that matched these filters',
		},
		error: {
			severity: 'error',
			text: 'Could not download requested export csv',
		},
		done: {
			severity: 'success',
			text: 'CSV exported and downloaded',
		},
		loading: {
			severity: 'info',
			text: 'Downloading your CSV. This may take 10-60 seconds',
		},
		warning: {
			severity: 'warning',
			text: 'CSV exported and downloaded, with some issues',
		},
	};

	useEffect(() => {
		if (
			userDetails.companyID === '' ||
			(singleSite && userDetails.siteID === '')
		) {
			return;
		}

		if (singleSite) {
			return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
				setSites({ [site.id]: site });
				setSiteLoading(false);
			});
		} else {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(sites) => {
					setSites(sites);
					setSiteLoading(false);
				},
			);
		}
	}, [firebaseApi, singleSite, userDetails.companyID, userDetails.siteID]);

	const updateDate = (date: Date | null): void => {
		if (date) {
			setEndDate(date);
		}
	};

	const updateSiteOption = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	): void => {
		setAllOrOneSite(event.target.value);
	};

	const updateActivityOption = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	): void => {
		const value = event.target.value;
		if (isActivityOption(value)) {
			setActivityFilter(value);
		}
	};

	const handleExport = async (): Promise<void> => {
		setExportStatus('loading');

		const response = await cloudFunctionApi.generateProjectTrackingExport(
			abortSignal,
			user,
			{
				weekEnding: formatSearchParamsDate(endDate),
				activitiesFilter: activityFilter,
				siteOrAllSites: allOrOneSite,
			},
		);

		try {
			if (response) {
				await firebaseApi.downloadStorageFileFromLink(
					response.fileLocation,
				);
				await firebaseApi.deleteStorageFile(response.fileLocation);

				if (response.errors) {
					setExportStatus('warning');
					setErrors(response.errors);
					setErrorDialogOpen(true);
				} else {
					setExportStatus('done');
				}
			} else {
				setExportStatus('error');
			}
		} catch (error) {
			setExportStatus('error');
		}
	};

	const content = (
		<Stack spacing={1} paddingY={1}>
			<DateWeekSelector
				date={endDate}
				onChange={updateDate}
				showWeekChangeArrows
				showWeekRange
				weekEnding
			/>
			<TextField
				value={allOrOneSite}
				onChange={updateSiteOption}
				select
				fullWidth
				disabled={singleSite}
				sx={{ px: 5 }}>
				<MenuItem key={siteOption.id} value={siteOption.id}>
					{siteOption.label}
				</MenuItem>
				<Divider />
				{Object.values(sites).map((site) => (
					<MenuItem key={site.id} value={site.id}>
						{site.name}
					</MenuItem>
				))}
			</TextField>
			<TextField
				value={activityFilter}
				onChange={updateActivityOption}
				select
				fullWidth
				sx={{ px: 5 }}>
				{Object.values(activityOptions).map((item) => (
					<MenuItem key={item.id} value={item.id}>
						{item.label}
					</MenuItem>
				))}
			</TextField>
		</Stack>
	);

	return (
		<>
			<CustomSnackBar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={exportStatus !== 'none'}
				onClose={(_, reason): void => {
					if (
						!(reason === 'clickaway' && exportStatus === 'loading')
					) {
						setExportStatus('none');
					}
				}}
				snackBarText={snackBarContent[exportStatus].text}
				severity={snackBarContent[exportStatus].severity}
				loading={exportStatus === 'loading'}
			/>
			<ErrorDialog
				isOpen={errorDialogOpen}
				setIsOpen={setErrorDialogOpen}
				errors={errors}
				firebaseApi={firebaseApi}
			/>
			<Grid container justifyContent="center">
				<Grid item xs={12} sm={10} md={8}>
					<Card>
						<CardHeader sx={{ paddingX: 3 }} title={exportTitle} />
						<CardContent
							sx={{
								paddingTop: 0,
								paddingX: 3,
							}}>
							{siteLoading ? <LoadingDots /> : content}
						</CardContent>
						<CardActions
							sx={{
								justifyContent: 'flex-end',
							}}>
							<LoadingButton
								variant="contained"
								loading={
									exportStatus === 'loading' || siteLoading
								}
								disabled={
									siteLoading ||
									exportStatus === 'loading' ||
									(singleSite && !userDetails.siteID)
								}
								onClick={handleExport}>
								Export
							</LoadingButton>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
