import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type CreateCheckoutSessionResponse = {
	checkoutSessionUrl: string;
};

type CreateCheckoutSessionBody = {
	successUrl: string;
	cancelUrl: string;
	freeTrial: boolean;
};

export const fetchCheckoutPortal = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	successUrl: string,
	cancelUrl: string,
	freeTrial: boolean,
): Promise<string | undefined> => {
	const response = await makeCloudFunctionRequestDeprecated<
		CreateCheckoutSessionResponse,
		CreateCheckoutSessionBody
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.StripeCreateCheckoutSession,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: { successUrl, cancelUrl, freeTrial },
	});

	return response?.checkoutSessionUrl;
};
