import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

export const Payroll = (): JSX.Element => {
	const cellWidth = '20%';
	const cellWidth2 = '15%';
	const cellWidth3 = '25%';
	const data = [
		['Leo Lilley', 'Kate Sheppard Apartments', '$1206.50', 'paid', '162'],
		['Mitchel Ryan', 'Kate Sheppard Apartments', '$1668.12', 'paid', '162'],
		['Angus Schuler', 'Kate Sheppard Apartments', '$916.48', 'paid', '162'],
		['Cary Doe', 'Soho', '$1529.56', 'paid', '162'],
		['James Daweson', 'Endeavour', '$1796.08', 'paid', '162'],
		['Hamish Mulvin', 'Endeavour', '$2056.67', 'paid', '162'],
		['Shane Demmo', 'Endeavour', '$668.12', 'paid', '162'],
		['James Daweson', 'Endeavour', '$1796.08', 'paid', '162'],
		['Henry Griffin', 'Endeavour', '$251.61', 'paid', '162'],
		['Leon Lilleyput', 'Tiketike Way', '$1206.50', 'paid', '162'],
		['Mitchel Ryan', 'Kate Sheppard Apartments', '$1668.12', 'paid', '162'],
		['Angus Schuler', 'Kate Sheppard Apartments', '$916.48', 'paid', '162'],
		['Cary Doe', 'Soho', '$1529.56', 'paid', '162'],
		['James Daweson', 'Endeavour', '$1796.08', 'paid', '162'],
		['Hamish Mulvin', 'Endeavour', '$2056.67', 'paid', '162'],
		['Shane Demmo', 'Endeavour', '$668.12', 'paid', '162'],
		['James Daweson', 'Endeavour', '$1796.08', 'paid', '162'],
		['Henry Griffin', 'Endeavour', '$251.61', 'paid', '162'],
	];
	const options: MUIDataTableOptions = {
		filterType: 'textField',
		selectableRows: 'none',
		tableBodyHeight: 'calc(100vh - 225px)',
		elevation: 1,
		rowsPerPage: 10,
		expandableRowsHeader: false,
	};
	const fixedColumns = [
		{
			name: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Company',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth3 },
				}),
			},
		},

		{
			name: 'Gross',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth2 },
				}),
			},
		},
		{
			name: 'Status',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth2 },
				}),
			},
		},
		{
			name: 'Pay No.',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth2 },
				}),
			},
		},
	];

	return (
		<MUIDataTable
			title="MYOB Integration"
			data={data}
			columns={fixedColumns}
			options={options}
		/>
	);
};
