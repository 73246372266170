import { PropsOf } from '@emotion/react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { Dispatch } from 'react';

export type CustomActionDialogProps = {
	title: string;
	content: JSX.Element;
	isOpen: boolean;
	setIsOpen: Dispatch<boolean>;
	actionButton?: JSX.Element;
	disableBackdropClose?: boolean;
	sx?: PropsOf<typeof Dialog>['sx'];
};

export const CustomActionDialog = ({
	title,
	content,
	isOpen,
	setIsOpen,
	actionButton,
	disableBackdropClose,
	sx,
}: CustomActionDialogProps): JSX.Element => {
	const handleClose = (): void => setIsOpen(false);

	const onClose: PropsOf<typeof Dialog>['onClose'] = (_, reason) => {
		if (disableBackdropClose && reason && reason === 'backdropClick') {
			return;
		} else {
			return handleClose();
		}
	};

	return (
		<Dialog open={isOpen} onClose={onClose} sx={sx}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<Box>
					<Button variant="outlined" onClick={handleClose} fullWidth>
						cancel
					</Button>
				</Box>
				<Box>{actionButton}</Box>
			</DialogActions>
		</Dialog>
	);
};
