import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Company } from '../../../../constants/Common';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';
import {
	DueDate,
	GroupBy,
	InvoicingLink,
	InvoicingType,
	SendInvoice,
	dueDateLabels,
	groupByLabels,
	sendInvoiceLabels,
} from '../../../../models/Integrations/InvoicingIntegration';
import { CustomActionDialog } from '../../../Dialogs/CustomActionDialog';
import { sortObjectByField } from '../../../helpers/sortHelpers';

export type ClientMappingDialogProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	integrationType: InvoicingType;
	invoicingClients: IntegrationElement[];
	companies: Record<string, Company>;
	setLink: (link: InvoicingLink) => Promise<void>;
	existingLinks: InvoicingLink[];
	updateLink?: InvoicingLink;
};

const recordToMenuItems = <T extends string>(
	record: Record<T, string>,
): JSX.Element[] =>
	Object.entries(record).map(([key, label]) => (
		<MenuItem key={key} value={key}>
			{label}
		</MenuItem>
	));

const addOrEditableFields = [
	'id',
	'integrationID',
	'groupBy',
	'dueDate',
	'sendInvoice',
] as const;
type Fields = {
	[K in (typeof addOrEditableFields)[number]]: InvoicingLink[K] | '';
};
const defaultError = (): Record<keyof Fields, boolean> => ({
	id: false,
	integrationID: false,
	sendInvoice: false,
	dueDate: false,
	groupBy: false,
});
const defaultFields = (): Fields => ({
	id: '',
	integrationID: '',
	sendInvoice: '',
	dueDate: '',
	groupBy: '',
});

export const ClientMappingDialog = ({
	open,
	setOpen,
	integrationType,
	invoicingClients,
	companies,
	setLink,
	existingLinks,
	updateLink,
}: ClientMappingDialogProps): JSX.Element => {
	const addOrUpdate = updateLink ? 'Update' : 'Add';
	const existingCompanies = new Set(existingLinks.map((link) => link.id));
	const existingIntegrations = new Set(
		existingLinks.map((link) => link.integrationID),
	);

	const [fields, setFields] = useState<Fields>(updateLink ?? defaultFields());
	const [error, setError] = useState(defaultError());

	useEffect(() => {
		if (updateLink) {
			setFields(updateLink);
		} else {
			setFields(defaultFields());
		}
	}, [updateLink]);

	const setOpenWithCleanup = (open: boolean): void => {
		setError(defaultError());
		setFields(defaultFields());
		setOpen(open);
	};

	const validateAndSetError = (
		validationFields: Fields,
	): validationFields is Exclude<
		InvoicingLink,
		'name' | 'integrationName'
	> => {
		const error: [keyof Fields, boolean][] = addOrEditableFields.map(
			(key) => [
				key,
				!(key in validationFields) || !validationFields[key],
			],
		);
		setError(Object.fromEntries(error) as Record<keyof Fields, boolean>); // stupid ts erasing key types
		return !error.some(([_, hasErrored]) => hasErrored);
	};

	const submit = async (): Promise<void> => {
		if (!validateAndSetError(fields)) {
			return;
		}

		const name = companies[fields.id].name;
		const integrationName =
			invoicingClients.find(
				(Client) => Client.id === fields.integrationID,
			)?.name ?? '';

		const payload: InvoicingLink = {
			...fields,
			name,
			integrationName,
		};

		await setLink(payload);
		setError(defaultError());
		setFields(defaultFields());
		setOpen(false);
	};

	const content = (
		<Stack pt={1} spacing={2} minWidth="500px">
			<TextField
				data-testid="client-company-select"
				select
				fullWidth
				size="small"
				value={fields.id}
				disabled={addOrUpdate === 'Update'}
				error={error['id']}
				onChange={(event): void =>
					setFields((prev) => ({
						...prev,
						id: event.target.value,
					}))
				}
				required
				label="Client Company in Trade Legion (required)">
				{Object.entries(sortObjectByField(companies, 'name')).map(
					([id, company]) => (
						<MenuItem
							key={id}
							value={id}
							disabled={existingCompanies.has(id)}>
							{company.name}
						</MenuItem>
					),
				)}
			</TextField>
			<TextField
				select
				fullWidth
				size="small"
				value={fields.integrationID}
				error={error['integrationID']}
				onChange={(event): void =>
					setFields((prev) => ({
						...prev,
						integrationID: event.target.value,
					}))
				}
				required
				label={`Client Company in ${integrationType} (required)`}>
				{invoicingClients.map((element) => (
					<MenuItem
						key={element.id}
						value={element.id}
						disabled={existingIntegrations.has(element.id)}>
						{element.name}
					</MenuItem>
				))}
			</TextField>
			<TextField
				select
				fullWidth
				size="small"
				value={fields.groupBy}
				error={error['groupBy']}
				onChange={(event): void =>
					setFields((prev) => ({
						...prev,
						groupBy: event.target.value as GroupBy,
					}))
				}
				required
				label="Invoice Group By (required)">
				{Object.entries(groupByLabels).map(([key, value]) => (
					<MenuItem key={key} value={key}>
						{value}
					</MenuItem>
				))}
			</TextField>
			<TextField
				select
				size="small"
				fullWidth
				required
				label="Send Invoices (required)"
				error={error['sendInvoice']}
				value={fields.sendInvoice}
				onChange={(event): void =>
					setFields((prev) => ({
						...prev,
						sendInvoice: event.target.value as SendInvoice,
					}))
				}>
				{recordToMenuItems(sendInvoiceLabels)}
			</TextField>
			<TextField
				select
				size="small"
				fullWidth
				required
				label="Invoice Due Date (required)"
				error={error['dueDate']}
				value={fields.dueDate}
				onChange={(event): void =>
					setFields((prev) => ({
						...prev,
						dueDate: event.target.value as DueDate,
					}))
				}>
				{recordToMenuItems(dueDateLabels)}
			</TextField>
		</Stack>
	);

	return (
		<CustomActionDialog
			title={`${addOrUpdate} Client`}
			content={content}
			isOpen={open}
			setIsOpen={setOpenWithCleanup}
			actionButton={
				<Button variant="contained" onClick={submit}>
					{addOrUpdate}
				</Button>
			}
			disableBackdropClose
		/>
	);
};
