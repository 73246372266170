import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const SPACED_DATE_FORMAT = 'do MMMM yyyy';
/**
 * @example '6th March 2023'
 */
export const formatSpacedDate = (date: Date): string =>
	format(date, SPACED_DATE_FORMAT);

export const SPACED_DATE_TIME_FORMAT = 'do MMMM yyy hh:mmaaa';
/**
 * @example '23rd March 2023 03:29am'
 */
export const formatSpacedDateTime = (date: Date): string =>
	format(date, SPACED_DATE_TIME_FORMAT);

export const SHORT_SPACED_DATE_FORMAT = 'd MMM Y';
/**
 * @example '12 Feb 2021'
 * @example '4 Jan 2000'
 */
export const formatShortSpacedDate = (date: Date): string =>
	format(date, SHORT_SPACED_DATE_FORMAT);

export const SEARCH_PARAMS_DATE_FORMAT = 'yyyy-MM-dd';
/**
 * @example '2023-06-16'
 */
export const formatSearchParamsDate = (date: Date): string =>
	format(date, SEARCH_PARAMS_DATE_FORMAT);

export const SLASH_DATE_FORMAT = 'dd/MM/yyyy';
/**
 *
 * @example '24/06/2022'
 */
export const formatSlashedDate = (date: Date): string =>
	format(date, SLASH_DATE_FORMAT);

export const WEEKDAY_SLASH_DATE_FORMAT = 'EEEE dd/MM/yyyy';
/**
 *
 * @example 'Monday 13/03/2023'
 */
export const formatWeekdaySlashedDate = (date: Date): string =>
	format(date, WEEKDAY_SLASH_DATE_FORMAT);

const DAY_OF_MONTH_FORMAT = "EEEE do 'of' MMMM";
/**
 * @example 'Wednesday 12th of April'
 */
export const formatDayOfMonth = (date: Date): string =>
	format(date, DAY_OF_MONTH_FORMAT);

const ACTIVITY_DISPLAY_DAY_FORMAT = 'eeee d MMMM';
/**
 * @example 'Wednesday 17 January'
 */
export const formatActivityDisplayDay = (date: Date): string =>
	format(date, ACTIVITY_DISPLAY_DAY_FORMAT);

const DAY_OF_WEEK_FORMAT = 'eee';
/**
 * @example 'Mon'
 */
export const formatDayOfWeek = (date: Date): string =>
	format(date, DAY_OF_WEEK_FORMAT);

export const HOURS_MINUTES_FORMAT = 'HH:mm';
/**
 * @example '02:09'
 */
export const formatHoursMinutes = (date: Date): string =>
	format(date, HOURS_MINUTES_FORMAT);

export const TIME_FORMAT = 'p';
/**
 * @example '12:00 AM'
 */
export const formatTime = (date: Date): string => format(date, TIME_FORMAT);

export const LOWERCASE_TIME_FORMAT = 'hh:mmaaa';
/**
 * @example '09:23am'
 */
export const formatLowercaseTime = (date: Date): string =>
	format(date, LOWERCASE_TIME_FORMAT);

export const TIME_ZONE_FORMAT = 'hh:mmaa';
/**
 * Not sure what the issue was that we needed this one
 * @example '09:23AM'
 */
export const formatTimeZone = (
	date: Date,
	timeZone = 'Pacific/Auckland',
): string => formatInTimeZone(date, timeZone, TIME_ZONE_FORMAT);
