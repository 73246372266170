import {
	Box,
	Typography,
	TextField,
	Autocomplete,
	MenuItem,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FixMeLater } from '../../../../../constants/AnyTypes';
import { Guest } from '../../../../../constants/Common';
import { NewInductionValidationErrors } from '../../../../../constants/InductionEntry';

export type ExistingGuestInductionProps = {
	guests: Record<string, Guest>;
	selectedGuest: string;
	setSelectedGuest: Dispatch<SetStateAction<string>>;
	guestError: boolean;
	setValidationErrors: Dispatch<SetStateAction<NewInductionValidationErrors>>;
	disableGuestSelect: boolean;
};

export const ExistingGuestInduction = ({
	guests,
	selectedGuest,
	setSelectedGuest,
	guestError,
	setValidationErrors,
	disableGuestSelect,
}: ExistingGuestInductionProps): JSX.Element => (
	<Box>
		<Typography variant="subtitle1" pb={0.5}>
			Inductee
		</Typography>
		<Autocomplete
			options={Object.values(guests)}
			getOptionLabel={(option): string => option.displayName}
			fullWidth
			onChange={(_, value): void => {
				setSelectedGuest(value?.id ?? '');
				setValidationErrors((prevState) => ({
					...prevState,
					selectedUser: false,
				}));
			}}
			disabled={disableGuestSelect}
			value={guests[selectedGuest] ?? null}
			renderOption={(props: FixMeLater, option): JSX.Element => (
				<MenuItem
					{...props}
					label={option.displayName}
					variant="standard"
					key={option.id}>
					{option.displayName || 'No Guests'}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					placeholder="Select Guests Name"
					inputProps={{
						...params.inputProps,
					}}
					size="small"
					error={guestError}
					helperText={
						guestError
							? 'Guest is required'
							: disableGuestSelect
							? 'Site is required'
							: ''
					}
				/>
			)}
		/>
	</Box>
);
