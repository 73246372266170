import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import cloudFunctionApi from '../../../../cloudfunctions';
import { isRequiredLeaveMapped } from '../../../../constants/Leave';
import firebaseApi from '../../../../firebase/firebaseApi';
import { PayrollType } from '../../../../models/Integrations/PayrollIntegration';
import { LeaveMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/LeaveMappingTab';
import { BasePayrollPage } from '../../IntegrationPages/BaseIntegrationPage';
import { IntegrationTab } from '../../IntegrationPages/IntegrationTab';
import { SmartlyPayrollEmployeesTab } from './SmartlyPayrollEmployeesTab';

export const SmartlyPage = (): JSX.Element => {
	const navigate = useNavigate();

	const tabs: IntegrationTab<PayrollType>[] = [
		{
			index: 0,
			name: 'Employees',
			content: (props) => (
				<SmartlyPayrollEmployeesTab
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
					{...props}
				/>
			),
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchPayrollEmployeeIDs,
		},
		{
			index: 1,
			name: 'Leave',
			content: (props) => <LeaveMappingTab {...props} />,
			optional: false,
			canMoveNext: (payrollIntegration) =>
				isRequiredLeaveMapped(
					Object.keys(payrollIntegration.leaveTypes),
				),
			fetchData: cloudFunctionApi.fetchPayrollLeaveIDs,
		},
	];

	const renderApiTextField = (): JSX.Element => (
		<Stack spacing={1}>
			<Typography>
				Authenticate your company with Smartly below
			</Typography>
		</Stack>
	);

	const handleCancel = useCallback(() => {
		navigate('/connect', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	return (
		<BasePayrollPage
			handleAuthenticate={(): void => {
				// No Auth for required for Smartly
			}}
			integrationType="Smartly"
			tabs={tabs}
			renderAuthenticationContent={renderApiTextField}
			token="smartly"
			handleSubmitError={(): void => {
				return;
			}}
			handleSubmitSuccess={(): void => {
				return;
			}}
			handleCancelAuthenticate={handleCancel}
			fetchToken={cloudFunctionApi.fetchPayrollTokens}
			firebaseApi={firebaseApi}
			deleteIntegrationFunction={
				cloudFunctionApi.deletePayrollIntegration
			}
			postEndIntegrationStep={handleCancel}
		/>
	);
};
