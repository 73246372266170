import { Grid, Typography } from '@mui/material';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import {
	formatSpacedDate,
	formatSpacedDateTime,
} from '../../../helpers/dateFormatters';
import { LoadableImage } from '../../../Image/LoadableImage';
import { TimesheetStatusChip } from '../../TimesheetStatusChip';
import { ReviewerCard, ReviewStatus } from './ReviewerCard';
import { TimesheetContextCard } from './TimesheetContextCard';
import { TimesheetHeaderItem } from './TimesheetHeaderItem';

export type TimesheetHeaderProps = {
	timesheetHeaderFields: {
		employeeName: Timesheet['employee']['name'];
		employerName: Timesheet['employer']['name'];
		contractedToCompany:
			| NonNullable<Timesheet['contractedTo']>['name']
			| null;
		reviewer: Timesheet['reviewer'];
		reviewedAt: Timesheet['reviewedAt'];
		status: Timesheet['timesheetStatus'];
		siteName: Timesheet['site']['name'];
		week: Timesheet['week'];
	};
	companyLogo: string;
	companyLogoLoading: boolean;
};

export const TimesheetHeader = ({
	timesheetHeaderFields,
	companyLogo,
	companyLogoLoading,
}: TimesheetHeaderProps): JSX.Element => (
	<Grid container data-testid="timesheet-header">
		<Grid container paddingX={2} marginTop={1}>
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'start', md: 'start' }}
				breakpoints={{ sm: 4 }}>
				<Typography variant="h5Bold" marginRight={1}>
					{timesheetHeaderFields.employeeName}
				</Typography>
			</TimesheetHeaderItem>
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'center', md: 'center' }}
				breakpoints={{ sm: 4 }}>
				<Typography variant="h5Bold">
					{`WE ${formatSpacedDate(
						timesheetHeaderFields.week.toDate(),
					)}`}
				</Typography>
			</TimesheetHeaderItem>
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'end', md: 'end' }}
				breakpoints={{ sm: 4 }}>
				<TimesheetStatusChip status={timesheetHeaderFields.status} />
			</TimesheetHeaderItem>
		</Grid>
		<Grid container paddingX={2} alignItems="center" minHeight="100px">
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'start', md: 'start' }}
				breakpoints={{ sm: 6 }}>
				<TimesheetContextCard
					context={{
						siteName: timesheetHeaderFields.siteName,
						employerName: timesheetHeaderFields.employerName,
					}}
				/>
			</TimesheetHeaderItem>
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'end', md: 'center' }}
				breakpoints={{ sm: 6 }}>
				<ReviewerCard
					review={
						timesheetHeaderFields.reviewer &&
						timesheetHeaderFields.reviewedAt
							? {
									status: ReviewStatus.Reviewed,
									name: timesheetHeaderFields.reviewer.name,
									reviewedAt: formatSpacedDateTime(
										timesheetHeaderFields.reviewedAt.toDate(),
									),
							  }
							: {
									status: ReviewStatus.Pending,
									pendingReviewCompany:
										timesheetHeaderFields.contractedToCompany,
							  }
					}
				/>
			</TimesheetHeaderItem>
			<TimesheetHeaderItem
				justifyContent={{ xs: 'start', sm: 'center', md: 'end' }}
				breakpoints={{ sm: 12 }}>
				<LoadableImage
					src={companyLogo}
					srcLoading={companyLogoLoading}
					altText="Company Logo"
					imageStyle={{
						maxWidth: '102px',
						marginTop: 0.5,
					}}
				/>
			</TimesheetHeaderItem>
		</Grid>
	</Grid>
);
