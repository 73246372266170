import { createSiteActivityID } from '../../constants/ProjectTrackingIntegrations';
import {
	ActivityLink,
	EmployeeLink,
	ProjectTrackingIntegration,
	SiteActivityLink,
	SiteLink,
} from '../../models/Integrations/ProjectTrackingIntegration';
import { Firestore, filterCollectionGroupOnParent } from '../firebase';

const COLLECTION = 'projectTrackingIntegrations';

const projectTrackingIntegrationSubscription = (
	companyID: string,
	callback: (integration: ProjectTrackingIntegration | null) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.onSnapshot((doc) => {
			callback(
				doc.exists ? (doc.data() as ProjectTrackingIntegration) : null,
			);
		});

const getProjectTrackingIntegration = async (
	companyID: string,
): Promise<ProjectTrackingIntegration | null> => {
	const doc = await Firestore.collection(COLLECTION).doc(companyID).get();

	if (doc.exists) {
		return doc.data() as ProjectTrackingIntegration;
	} else {
		return null;
	}
};

const projectTrackingIntegrationEmployeeLinksSubscription = (
	companyID: string,
	callback: (links: Record<string, EmployeeLink>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('employees')
		.onSnapshot((snapshot) => {
			const employeeLinks = snapshot.docs.reduce(
				(previous: Record<string, EmployeeLink>, current) => {
					previous[current.id] = current.data() as EmployeeLink;
					return previous;
				},
				{},
			);

			callback(employeeLinks);
		});

const projectTrackingIntegrationActivityLinksSubscription = (
	companyID: string,
	siteID: string,
	callback: (links: Record<string, ActivityLink>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('projects')
		.doc(siteID)
		.collection('tasks')
		.onSnapshot((snapshot) => {
			const activityLinks = snapshot.docs.reduce(
				(previous: Record<string, ActivityLink>, current) => {
					previous[current.id] = current.data() as ActivityLink;
					return previous;
				},
				{},
			);

			callback(activityLinks);
		});

const projectTrackingIntegrationSingleSiteLinkSubscription = (
	companyID: string,
	siteID: string,
	callback: (links: SiteLink | null) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('projects')
		.doc(siteID)
		.onSnapshot((doc) => {
			callback(doc.exists ? (doc.data() as SiteLink) : null);
		});

const projectTrackingIntegrationSiteLinksSubscription = (
	companyID: string,
	callback: (links: Record<string, SiteLink>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('projects')
		.onSnapshot((snapshot) => {
			const siteLinks = snapshot.docs.reduce(
				(previous: Record<string, SiteLink>, current) => {
					previous[current.id] = current.data() as SiteLink;
					return previous;
				},
				{},
			);

			callback(siteLinks);
		});

const projectTrackingIntegrationSiteFetch = async (
	companyID: string,
	siteID: string,
): Promise<SiteLink | undefined> => {
	const doc = await Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('projects')
		.doc(siteID)
		.get();

	if (doc.exists) {
		return doc.data() as SiteLink;
	}
};

const projectTrackingIntegrationSiteActivityLinksSubscription = (
	companyID: string,
	siteID: string,
	callback: (links: Record<string, SiteActivityLink>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.doc(companyID)
		.collection('projects')
		.doc(siteID)
		.collection('tasks')
		.onSnapshot((snapshot) => {
			const activityLinks = Object.fromEntries(
				snapshot.docs.map((doc) => {
					const flatLinkID = createSiteActivityID(siteID, doc.id);

					return [
						flatLinkID,
						{
							siteID: siteID,
							activityLink: doc.data() as ActivityLink,
						},
					];
				}),
			);

			callback(activityLinks);
		});

const projectTrackingIntegrationAllSiteActivityLinksSubscription = (
	companyID: string,
	callback: (activityLink: Record<string, SiteActivityLink>) => void,
): (() => void) => {
	const companyRef = Firestore.collection(COLLECTION).doc(companyID);

	return filterCollectionGroupOnParent('tasks', companyRef.path).onSnapshot(
		(snapshot) => {
			callback(
				Object.fromEntries(
					snapshot.docs.map((doc) => {
						const parent = doc.ref.parent.parent;
						if (parent === null) {
							throw new Error('Task has no parent');
						}
						const flatLinkID = createSiteActivityID(
							parent.id,
							doc.id,
						);
						return [
							flatLinkID,
							{
								siteID: parent?.id || '',
								activityLink: doc.data() as ActivityLink,
							},
						];
					}),
				),
			);
		},
	);
};

const projectTrackingApi = {
	projectTrackingIntegrationSubscription,
	getProjectTrackingIntegration,
	projectTrackingIntegrationEmployeeLinksSubscription,
	projectTrackingIntegrationActivityLinksSubscription,
	projectTrackingIntegrationSiteLinksSubscription,
	projectTrackingIntegrationSiteFetch,
	projectTrackingIntegrationSiteActivityLinksSubscription,
	projectTrackingIntegrationAllSiteActivityLinksSubscription,
	projectTrackingIntegrationSingleSiteLinkSubscription,
};

export default projectTrackingApi;
