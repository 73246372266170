import { TabContext, TabPanel } from '@mui/lab';
import { Paper, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import cloudFunctionApi from '../../../../cloudfunctions';
import { UserDetails } from '../../../../constants/Common';
import firebaseApi from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	IntegrationType,
	isManualIntegrationType,
} from '../../../../models/Integrations/Integration';
import {
	BaseIntegrationLink,
	IntegrationEmployee,
} from '../../../../models/Integrations/IntegrationElements';
import { sortObjectByField } from '../../../helpers/sortHelpers';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { ErrorFooter } from '../ActivityAndLeaveMapping/ErrorFooter';
import { EmployeeIntegrationTabProps } from '../IntegrationTab';
import { LinkedUsers } from './LinkedUsersTable';
import { UnlinkedUsers } from './UnlinkedUsersTable';

export const EmployeeMappingTab = <T extends IntegrationType>({
	user,
	userDetails,
	integration,
	integrationCollection,
	fetchData,
	importHelperText,
}: EmployeeIntegrationTabProps<T>): JSX.Element => {
	const abortSignal = useAbortController();
	const [usersList, setUsersList] = useState<Record<string, UserDetails>>({});
	const [currentTab, setCurrentTab] = useState<string>('0');
	const handleTabChange = (
		_: React.ChangeEvent<object>,
		newValue: string,
	): void => {
		setCurrentTab(newValue);
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [importLoading, setImportLoading] = useState<boolean>(false);
	const [successSnackBarOpen, setSuccessSnackBarOpen] =
		useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [dropdownEmployees, setDropdownEmployees] = useState<
		IntegrationEmployee[]
	>([]);
	const [userIntegrationAccounts, setUserIntegrationAccounts] =
		useState<Record<string, BaseIntegrationLink> | null>(null);
	const [integrationAccounts, setIntegrationAccounts] = useState<
		IntegrationEmployee[] | null
	>(null);
	const [linkedUsersList, setLinkedUsersList] = useState<UserDetails[]>([]);
	const [unlinkedUsersList, setUnlinkedUsersList] = useState<UserDetails[]>(
		[],
	);
	const isManualIntegration = isManualIntegrationType(integration.type);

	const getAllIntegrationEmployees = useCallback(
		async (abortSignal: AbortSignal) => {
			const integrationEmployees = await fetchData(abortSignal, user);
			if (integrationEmployees && !isManualIntegration) {
				setIntegrationAccounts(integrationEmployees.employees);
				if (integrationEmployees.employees.length === 0) {
					setError(
						`No Accounts available from ${integration.type}. Please ensure you have the integrated system set up.`,
					);
				} else {
					setError(null);
				}
			} else if (
				integrationEmployees &&
				integrationEmployees.employees.length !== 0 &&
				isManualIntegration
			) {
				setIntegrationAccounts(integrationEmployees.employees);
			} else if (!abortSignal.aborted) {
				setError(
					`Unable to get Accounts from ${integration.type}. Please try again.`,
				);
				setLoading(false);
			}
		},
		[fetchData, integration.type, user, isManualIntegration],
	);

	useEffect(() => {
		if (userIntegrationAccounts === null || integrationAccounts === null) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [userIntegrationAccounts, integrationAccounts, isManualIntegration]);

	useEffect(() => {
		const integrationintegrationsSub =
			firebaseApi.integrationEmployeeLinksSubscription(
				integrationCollection,
				integration.companyID,
				(employeeLinks) => {
					setUserIntegrationAccounts(employeeLinks);
				},
			);
		const usersSub = firebaseApi.subscribeUsersByCompany(
			userDetails.companyID,
			(users) => {
				setUsersList(sortObjectByField(users, 'displayName'));
			},
		);
		getAllIntegrationEmployees(abortSignal);
		return () => {
			integrationintegrationsSub();
			usersSub();
		};
	}, [
		getAllIntegrationEmployees,
		integration.companyID,
		userDetails.companyID,
		abortSignal,
		integrationCollection,
	]);

	useEffect(() => {
		if (userIntegrationAccounts !== null && integrationAccounts !== null) {
			const accountsList = integrationAccounts.filter((employee) =>
				Object.values(userIntegrationAccounts).every(
					(acc) => acc.integrationID !== employee.id,
				),
			);
			setDropdownEmployees(accountsList);

			const linkedUsersList: UserDetails[] = [];
			const unlinkedUsersList: UserDetails[] = [];

			Object.values(usersList).forEach((user) => {
				const userLinked = Object.values(userIntegrationAccounts).some(
					(acc) => acc.id === user.userID,
				);
				if (userLinked) {
					linkedUsersList.push(user);
				} else {
					unlinkedUsersList.push(user);
				}
			});
			setLinkedUsersList(linkedUsersList);
			setUnlinkedUsersList(unlinkedUsersList);
			if (unlinkedUsersList.length === 0) {
				setCurrentTab('1');
			}
		}
	}, [integrationAccounts, userIntegrationAccounts, usersList]);

	const handleCSVImport = async (file: File | null): Promise<void> => {
		if (!file || !isManualIntegration) return;
		setImportLoading(true);
		// Save csv data to firebase storage
		const fileName = await firebaseApi.saveCSVFileToStorage(
			file,
			userDetails.companyID,
		);
		// Import that csv data into our firestore
		const response = await cloudFunctionApi.payrollEmployeeImport(
			abortSignal,
			user,
			fileName,
		);
		if (response) {
			// Fetch the new employee data
			await getAllIntegrationEmployees(abortSignal);
			setError(null);
			setSuccessSnackBarOpen(true);
		} else {
			setError(
				`${integration.type} CSV employee import failed. Please check the file is valid and try again.`,
			);
		}
		setImportLoading(false);
	};

	const successSnackBar = (): JSX.Element => (
		<CustomSnackBar
			open={successSnackBarOpen}
			onClose={handleSnackBarClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			snackBarText={`${integration.type} Accounts Linked`}
		/>
	);

	const handleSnackBarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessSnackBarOpen(false);
	};

	return (
		<Paper id="boxList" elevation={1}>
			<TabContext value={currentTab}>
				<Tabs
					value={currentTab}
					onChange={handleTabChange}
					sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tab label="Unlinked Users" value="0" />
					<Tab label="Linked Users" value="1" />
				</Tabs>
				<TabPanel value="0" sx={{ padding: 0 }}>
					<UnlinkedUsers
						integrationCompanyID={integration.companyID}
						usersList={usersList}
						dropdownEmployees={dropdownEmployees}
						loading={loading}
						unlinkedUsersList={unlinkedUsersList}
						integrationAccounts={integrationAccounts ?? []}
						fetchAllIntegrationEmployees={
							getAllIntegrationEmployees
						}
						integrationCollection={integrationCollection}
						handleCSVImport={handleCSVImport}
						importHelperText={importHelperText}
						importLoading={importLoading}
					/>
					{successSnackBar()}
				</TabPanel>
				<TabPanel value="1" sx={{ padding: 0 }}>
					<LinkedUsers
						integrationCompanyID={integration.companyID}
						usersList={usersList}
						dropdownEmployees={dropdownEmployees}
						loading={loading}
						userIntegrationAccounts={userIntegrationAccounts}
						integrationAccounts={integrationAccounts ?? []}
						linkedUsersList={linkedUsersList}
						fetchAllPayrollEmployees={getAllIntegrationEmployees}
						integrationCollection={integrationCollection}
					/>
				</TabPanel>
				{error && <ErrorFooter error={error} />}
			</TabContext>
		</Paper>
	);
};
