import { Card, CardContent, CardHeader } from '@mui/material';
import {
	Chart,
	ChartLegend,
	ChartSeries,
	ChartSeriesItem,
	ChartSeriesLabels,
} from '@progress/kendo-react-charts';
import { percentNumberFormatString } from '../helpers/numberFormats';

type DonutChartProps = {
	data: { kind: string; share: number }[];
	title: string;
};

export const DonutChart = ({ data, title }: DonutChartProps): JSX.Element => (
	<Card>
		<CardHeader title={title} />
		<CardContent>
			<Chart>
				<ChartSeries>
					<ChartSeriesItem
						type="donut"
						data={data}
						categoryField="kind"
						field="share"
						tooltip={{ visible: true }}>
						<ChartSeriesLabels
							content={(data): string =>
								data.category +
								'\n' +
								percentNumberFormatString(data.percentage)
							}
							position="outsideEnd"
						/>
					</ChartSeriesItem>
				</ChartSeries>
				<ChartLegend visible={false} />
			</Chart>
		</CardContent>
	</Card>
);
