import {
	Stack,
	TableCell,
	TableFooter,
	TableRow,
	Typography,
} from '@mui/material';
import { Activity } from '../../../../constants/Common';
import { Note } from '../../../../constants/Note';
import { getActivityHourTotals } from '../../../helpers/activityHelpers';
import { NotesSection } from './EditTimesheet/Notes/NotesSection';

type TimesheetFooterProps = {
	activities: Pick<Activity, 'id' | 'activity' | 'hours'>[];
	generalNotes: Pick<Note, 'note' | 'user' | 'id'>[];
};

export const TimesheetFooter = ({
	activities,
	generalNotes,
}: TimesheetFooterProps): JSX.Element => {
	const { totals, allHours } = getActivityHourTotals(activities);
	return (
		<TableFooter>
			<TableRow>
				{generalNotes.length > 0 && (
					<TableCell sx={{ padding: 0, borderBottom: 0 }}>
						<Stack marginX={1} alignItems="flex-start">
							<NotesSection notes={generalNotes} isGeneralNotes />
						</Stack>
					</TableCell>
				)}
				<TableCell sx={{ padding: 0, borderBottom: 0 }}>
					<Stack marginX={1} paddingY={1} alignItems="flex-end">
						{Object.entries(totals).map(([id, total]) => (
							<Stack data-testid={id} key={id} direction="row">
								<Typography>{total.name}</Typography>
								<Typography
									whiteSpace="nowrap"
									marginLeft={
										1
									}>{`${total.total} hrs`}</Typography>
							</Stack>
						))}
						<Stack data-testid="total" direction="row">
							<Typography fontWeight="bold">Total</Typography>
							<Typography
								fontWeight="bold"
								whiteSpace="nowrap"
								marginLeft={1}>{`${allHours} hrs`}</Typography>
						</Stack>
					</Stack>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};
