import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Chip, SvgIconProps, Typography } from '@mui/material';
import { useState } from 'react';

const FIVE_MB = 5242880;

type UploadFileProps = {
	onUpload: (blob: Blob | null) => void;
	storageUrl?: string | null;
	label?: string;
	icon?: React.ReactElement<SvgIconProps>;
};
/** Manage generic file uploads
 * @param props.onUpload - Callback triggered after a file is set.
 * @param props.storageUrl - The url of a file, if there is already one in the 'slot' this file would go into in firebase storage.
 * @param props.label - What we're calling the type of file (e.g. file, evidence, CV, whatever).
 */
export const UploadFile = ({
	onUpload,
	storageUrl,
	label = 'File',
	icon = <CloudUploadIcon />,
}: UploadFileProps): JSX.Element => {
	const [error, setError] = useState('');
	const [blob, setBlob] = useState<Blob | null>(null);

	/** Could do this using useEffect, but it's a hassle to prevent it from being inadvertantly called on first render; after hot-reload etc. */
	const afterChange = (blob: Blob | null): void => {
		setBlob(blob);
		onUpload(blob);
	};

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files) {
			const file = event.target.files[0];
			if (file.size > FIVE_MB) {
				setError(
					`Your ${label} is too large - cannot be more than 5MB`,
				);
				return;
			} else {
				setError('');
			}
			afterChange(file);
		}
	};

	return (
		<Box>
			{storageUrl ? (
				<Chip
					label={`Existing ${label}`}
					size="medium"
					icon={icon}
					onClick={(): '' | Window | null | undefined =>
						storageUrl && window.open(storageUrl)
					}
					onDelete={(): void => afterChange(null)}
					sx={{ pr: 2, pl: 2, fontWeight: 'bold' }}
				/>
			) : blob ? (
				<Chip
					label={`${label} added!`}
					color="success"
					size="medium"
					icon={icon}
					onClick={(): Window | null =>
						blob && window.open(URL.createObjectURL(blob))
					}
					onDelete={(): void => afterChange(null)}
					sx={{ pr: 2, pl: 2, fontWeight: 'bold' }}
				/>
			) : (
				<Button
					fullWidth
					variant="contained"
					component="label"
					startIcon={icon}>
					Select {label}
					<input
						type="file"
						hidden
						accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpg, image/jpeg"
						onChange={onChange}
					/>
				</Button>
			)}
			{error && (
				<Typography color="error.main" textAlign="center">
					{error}
				</Typography>
			)}
		</Box>
	);
};
