import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	TextField,
} from '@mui/material';
import { CompanyTypeName, companyTypes } from '../../constants/Common';

type CreateNewCompanyDialogProps = {
	openDialog: boolean;
	companyName: string;
	companyNameOnChange: (value: string) => void;
	nameError: string;
	companyType: string;
	companyTypeOnChange: (value: string) => void;
	typeError: string;
	handleDialogClose: () => void;
	handleCreateCompany: () => void;
};

export const CreateNewCompanyDialog = ({
	openDialog,
	companyName,
	companyNameOnChange,
	nameError,
	companyType,
	companyTypeOnChange,
	typeError,
	handleCreateCompany,
	handleDialogClose,
}: CreateNewCompanyDialogProps): JSX.Element => {
	const alphabetisedCompanyTypes = [...companyTypes].sort();
	return (
		<Dialog open={openDialog} fullWidth>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Company Name (required)"
							value={companyName}
							onChange={(event): void =>
								companyNameOnChange(event.target.value)
							}
							helperText={nameError}
							error={nameError !== ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={companyType}
							label="Company Type (required)"
							onChange={(event): void =>
								companyTypeOnChange(event.target.value)
							}
							select
							helperText={typeError}
							error={typeError !== ''}>
							{alphabetisedCompanyTypes.map((option) => (
								<MenuItem key={option} value={option}>
									{CompanyTypeName[option]}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleDialogClose}>
					Cancel
				</Button>
				<Button variant="contained" onClick={handleCreateCompany}>
					Create new company
				</Button>
			</DialogActions>
		</Dialog>
	);
};
