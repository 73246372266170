import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import cloudFunctionApi from '../cloudfunctions';
import { Company, SubscriptionStatuses } from '../constants/Common';
import { User } from '../firebase/firebase';
import firebaseApi from '../firebase/firebaseApi';
import { useAbortController } from '../hooks/useAbortController';
import { useFeatureFlagContext } from './featureFlags/Provider';
import { useUserAuthContext, useUserDetailsContext } from './UserProvider';

type CompanySubscriptionProviderProps = {
	children: React.ReactNode;
};

export type CompanySubscription = {
	subscriptionControlEnabled: boolean;
	isLoaded: boolean;
	isSubscribed: boolean;
	company: Company | null;
};

const defaultSubscription: CompanySubscription = {
	isLoaded: false,
	subscriptionControlEnabled: false,
	isSubscribed: false,
	company: null,
};

export const CompanySubscriptionContext =
	createContext<CompanySubscription>(defaultSubscription);

export const CompanySubscriptionProvider = ({
	children,
}: CompanySubscriptionProviderProps): JSX.Element => {
	const featureFlags = useFeatureFlagContext();
	const userDetails = useUserDetailsContext();
	const userAuth = useUserAuthContext();
	const abortSignal = useAbortController();

	const [isLoaded, setIsLoaded] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [controlEnabled, setControlEnabled] = useState<boolean | null>(null);
	const [company, setCompany] = useState<Company | null>(null);

	const checkTrialDates = useCallback(
		async (user: User): Promise<boolean> => {
			const subDates = await cloudFunctionApi.fetchSubscriptionTrialDates(
				abortSignal,
				user,
			);

			return (
				!!subDates?.trialEnd && new Date() < new Date(subDates.trialEnd)
			);
		},
		[abortSignal],
	);

	useEffect(() => {
		if (featureFlags.loaded) {
			setControlEnabled(featureFlags.get('subscriptionControl'));
		}
	}, [featureFlags]);

	useEffect(() => {
		if (!userDetails?.companyID) {
			setCompany(null);
			return;
		}

		return firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				setCompany(company);
			},
		);
	}, [userDetails?.companyID]);

	useEffect(() => {
		if (
			company === null ||
			!userAuth ||
			!featureFlags.loaded ||
			controlEnabled === null
		) {
			return;
		}
		if (!controlEnabled) {
			setIsSubscribed(true);
			setIsLoaded(true);
			return;
		}

		const checkSubscription = async (): Promise<void> => {
			if (company.subscriptionStatus === SubscriptionStatuses.Trialing) {
				const trialActive = await checkTrialDates(userAuth);
				setIsSubscribed(trialActive);
				setIsLoaded(true);
			} else if (
				company.subscriptionStatus === SubscriptionStatuses.Active ||
				(featureFlags.loaded && !controlEnabled)
			) {
				setIsSubscribed(true);
				setIsLoaded(true);
			} else {
				setIsSubscribed(false);
				setIsLoaded(true);
			}
		};

		checkSubscription();
	}, [
		company,
		userAuth,
		checkTrialDates,
		featureFlags.loaded,
		controlEnabled,
	]);

	const companySubscription: CompanySubscription = {
		subscriptionControlEnabled: !!controlEnabled,
		isLoaded,
		isSubscribed,
		company,
	};

	return (
		<CompanySubscriptionContext.Provider value={companySubscription}>
			{children}
		</CompanySubscriptionContext.Provider>
	);
};

export const useCompanySubscriptionContext = (): CompanySubscription => {
	return useContext(CompanySubscriptionContext);
};
