import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';

export type ReportConfigureHeaderProps = {
	loading: boolean;
	updateButtonText: string;
	updateOnClick: () => void;
	disabled?: boolean;
};

export const ReportConfigureHeader = ({
	loading,
	updateOnClick,
	updateButtonText,
	disabled = false,
}: ReportConfigureHeaderProps): JSX.Element => (
	<Grid container>
		<Grid item xs={6}>
			<Typography variant="h6">Configure Report</Typography>
		</Grid>
		<Grid item xs={6} display="flex" justifyContent="flex-end">
			<LoadingButton
				variant="contained"
				loading={loading}
				disabled={disabled}
				sx={{ width: '125px' }}
				onClick={updateOnClick}>
				{updateButtonText}
			</LoadingButton>
		</Grid>
	</Grid>
);
