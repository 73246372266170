import RefreshIcon from '@mui/icons-material/Refresh';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import { AuthState } from '../../constants/AuthState';
import {
	Auth,
	MultiFactorError,
	MultiFactorResolver,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
} from '../../firebase/firebase';
import { useUserAuthContext } from '../../providers/UserProvider';
import { RecaptchaVerifierContainer } from './RecaptchaVerifierContainer';
import { SignInUpWrapper } from './SignInUpWrapper';

type SignInUpProps = {
	authState: AuthState;
	onSignOut: () => void;
};

const auth = Auth();
export const SignInUp = ({
	authState,
	onSignOut,
}: SignInUpProps): JSX.Element => {
	const navigate = useNavigate();
	const userAuth = useUserAuthContext();
	const theme = useTheme();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [emailError, setEmailError] = useState<string | null>(null);
	const [passwordError, setPasswordError] = useState<string | null>(null);
	const [showPasswordResetText, setShowPasswordResetText] = useState(false);
	const [openResetPasswordDialog, setOpenResetPasswordDialog] =
		useState(false);
	const [verificationCode, setVerificationCode] = useState<string>('');
	const [verificationCodeError, setVerificationCodeError] =
		useState<string>('');
	const [verificationId, setVerificationId] = useState<string>('');
	const [multiFactorRequired, setMultiFactorRequired] = useState(false);
	const [resolver, setResolver] = useState<MultiFactorResolver>();
	const [recaptchaVerifier, setRecaptchaVerifier] =
		useState<RecaptchaVerifier>();

	const pinInputRef = useRef<PinInput>(null);

	useEffect(() => {
		// I believe we do this so flicking between sign-up and sign-in
		// clears previous users state i.e can't see a new sign-up's password
		// Makes for a strange experience when navigating direct to the sign-in page lol
		if (authState === 'signIn') {
			resetFields();
		}
		setEmailError(null);
		setPasswordError(null);
	}, [authState]);

	const resetFields = (): void => {
		setPassword('');
		setShowPassword(false);
		setEmail('');
		setEmailError(null);
		setPasswordError(null);
		setShowPasswordResetText(false);
		setOpenResetPasswordDialog(false);
	};

	const checkFields = (): boolean => {
		let isValid = true;
		if (email === undefined || email === null || email === '') {
			setEmailError('Please enter a valid Email');
			isValid = false;
		}
		if (
			password === undefined ||
			password === null ||
			password === '' ||
			password.length < 6
		) {
			setPasswordError('Please enter a valid Password');
			isValid = false;
		}
		return isValid;
	};

	const handleEmailInput = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setEmail(event.target.value);
		setEmailError(null);
	};

	const handlePasswordInput = (
		event: React.ChangeEvent<HTMLInputElement>,
	): void => {
		setPassword(event.target.value);
		setPasswordError(null);
	};

	const handleKeyDown = (event: React.KeyboardEvent): void => {
		if (event.code !== 'Enter') return;
		else if (authState === 'signIn') handleLogin();
		else if (authState === 'signUp') handleNewAccount();
	};

	const handleNewAccount = (): void => {
		if (checkFields() && userAuth === null) {
			auth.createUserWithEmailAndPassword(email.trim(), password).then(
				(authResult) => {
					if (!authResult.user) return;
					resetFields();
					authResult.user.sendEmailVerification().then(() => {
						navigate('/verify-email');
					});
				},
				(reason) => {
					// handle failure
					if (reason.code === 'auth/invalid-email') {
						setEmailError('Invalid Email');
					} else if (reason.code === 'auth/email-already-in-use') {
						setEmailError('Email already in use');
					}
				},
			);
		} else if (userAuth !== null) {
			navigate('/verify-email');
		}
	};

	const handleLogin = (): void => {
		if (checkFields()) {
			auth.signInWithEmailAndPassword(email.trim(), password).then(
				() => resetFields(),
				(reason) => {
					// handle failure
					if (reason.code === 'auth/wrong-password') {
						setPasswordError('Invalid Password');
						setShowPasswordResetText(true);
					} else if (reason.code === 'auth/user-not-found') {
						setEmailError('Email address not found');
					} else if (reason.code === 'auth/invalid-email') {
						setEmailError('Invalid Email');
					} else if (reason.code === 'auth/argument-error') {
						setEmailError('Incorrect Email');
					} else if (
						reason.code === 'auth/multi-factor-auth-required'
					) {
						signInWithMultiFactor(reason as MultiFactorError);
					}
				},
			);
		}
	};

	const signInWithMultiFactor = async (
		error: MultiFactorError,
	): Promise<void> => {
		setMultiFactorRequired(true);
		const resolver = error.resolver;
		setResolver(resolver);
		const phoneInfoOptions = {
			multiFactorHint: resolver.hints[0],
			session: resolver.session,
		};
		const phoneAuthProvider = new PhoneAuthProvider();
		// Send SMS verification code
		const verificationId = await phoneAuthProvider.verifyPhoneNumber(
			phoneInfoOptions,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			recaptchaVerifier!,
		);
		setVerificationId(verificationId);
	};

	const handleVerification = async (): Promise<void> => {
		if (!resolver) {
			setVerificationCodeError(
				'Unable to process MFA request. Please contact Trade Legion support.',
			);
			return;
		}
		if (!/^\d{6}$/.test(verificationCode)) {
			setVerificationCodeError(
				'Verification code should be a 6-digit number',
			);
			return;
		}
		try {
			// Ask user for the SMS verification code.
			const cred = PhoneAuthProvider.credential(
				verificationId,
				verificationCode,
			);

			const multiFactorAssertion =
				PhoneMultiFactorGenerator.assertion(cred);
			// Complete sign-in.
			await resolver.resolveSignIn(multiFactorAssertion);
		} catch (err) {
			const multiFactorError = err as MultiFactorError;
			if (multiFactorError.code === 'auth/invalid-verification-code') {
				// this is the most common case, so give it a plainer useful message
				setVerificationCodeError(
					'The code you entered was incorrect - please resend the verification code and try again.',
				);
			}
			setVerificationCodeError(multiFactorError.message);
		}
	};

	const handleShowPassword = (): void => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent): void => {
		event.stopPropagation();
	};

	const handleResetPassword = (event: React.MouseEvent): void => {
		event.preventDefault();
		setOpenResetPasswordDialog(true);
	};

	const resetPassword = (): void => {
		auth.sendPasswordResetEmail(email.trim());
		setOpenResetPasswordDialog(false);
	};

	const resendVerificationCode = (): void => {
		pinInputRef?.current?.clear();
		handleLogin();
	};

	const resetPasswordDialog = (): JSX.Element => (
		<Dialog open={openResetPasswordDialog} fullWidth>
			<DialogTitle title="Reset Password">Reset Passsword</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							Please enter the email of your account and we will
							send you a link to reset your password.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Email"
							onChange={handleEmailInput}
							helperText={emailError}
							error={emailError !== null}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void => setOpenResetPasswordDialog(false)}>
					Cancel
				</Button>
				<Button variant="contained" onClick={resetPassword}>
					Send Reset Email
				</Button>
			</DialogActions>
		</Dialog>
	);

	const handleTitle = (): string => {
		if (authState === 'signIn') {
			return 'Login to Trade Legion';
		} else {
			return 'Create Trade Legion Account';
		}
	};

	return (
		<>
			{resetPasswordDialog()}
			<SignInUpWrapper
				title={handleTitle()}
				authState={authState}
				back={{
					label:
						authState === 'signUp' && userAuth !== null
							? 'Log Out'
							: '',
					handle: onSignOut,
				}}
				next={{
					label:
						authState === 'signIn'
							? 'Login'
							: userAuth !== null
							? 'Next'
							: 'Create Account',
					handle: multiFactorRequired
						? handleVerification
						: authState === 'signIn'
						? handleLogin
						: handleNewAccount,
				}}>
				<Grid container spacing={2} onKeyDown={handleKeyDown}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Email"
							onChange={handleEmailInput}
							helperText={emailError}
							error={emailError !== null}
							value={userAuth === null ? email : userAuth.email}
							disabled={userAuth !== null}
							autoComplete="email"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={password}
							label="Password"
							type={showPassword ? 'text' : 'password'}
							autoComplete="current-password"
							onChange={handlePasswordInput}
							disabled={userAuth !== null}
							InputProps={{
								endAdornment: (
									<IconButton
										onClick={handleShowPassword}
										onMouseDown={handleMouseDownPassword}
										size="large">
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								),
							}}
							helperText={passwordError}
							error={passwordError !== null}
						/>
					</Grid>

					<Grid item xs={12}>
						<RecaptchaVerifierContainer
							setVerifier={setRecaptchaVerifier}
							RecaptchaVerifierConstructor={RecaptchaVerifier}
						/>
						{multiFactorRequired && (
							<>
								<Typography
									color={theme.palette.neutral.main}
									textAlign="center">
									Verify using the MFA code you just received:
								</Typography>
								<Box display="flex" justifyContent="center">
									<PinInput
										focus
										autoSelect
										ref={pinInputRef}
										length={6}
										onChange={(value): void =>
											setVerificationCode(value)
										}
										type="numeric"
										inputMode="number"
										style={{
											display: 'flex',
											justifyContent: 'center',
											paddingTop: '10px',
										}}
										inputStyle={{
											borderColor:
												theme.palette.neutral.main,
										}}
										inputFocusStyle={{
											borderColor:
												theme.palette.primary.main,
										}}
									/>
									{verificationCodeError !== '' && (
										<IconButton
											onClick={resendVerificationCode}>
											<RefreshIcon />
										</IconButton>
									)}
								</Box>
							</>
						)}
						{verificationCodeError && (
							<Typography
								color={theme.palette.error.main}
								textAlign="center">
								{verificationCodeError}
							</Typography>
						)}
					</Grid>

					<Grid
						item
						xs={12}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center">
						{showPasswordResetText === true && (
							<Button
								onClick={handleResetPassword}
								fullWidth
								color="primary">
								<Link href="#" onClick={handleResetPassword}>
									Reset Password
								</Link>
							</Button>
						)}
						{userAuth === null && (
							<Button
								onClick={(): void =>
									navigate(
										authState === 'signIn'
											? '/sign-up'
											: '/sign-in',
									)
								}
								fullWidth
								color="secondary">
								{authState === 'signIn'
									? "Don't have an account? Create Account"
									: 'Already Have An Account? Login'}
							</Button>
						)}
					</Grid>
				</Grid>
			</SignInUpWrapper>
		</>
	);
};
