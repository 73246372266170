import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserDetails } from '../../../constants/Common';
import { Document } from '../../../constants/Document';
import { Template } from '../../../constants/Template';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { sortByField } from '../../helpers/sortHelpers';
import { InitialNewDocumentView } from './InitialNewDocumentView';
import { NewDocumentForm } from './NewDocumentForm';
import { ViewDocumentBody } from './ViewDocumentBody';
import { ViewDocumentHeader } from './ViewDocumentHeader';

export type NewDocumentProps = {
	userDetails: UserDetails;
	createDocument: (
		name: Document['name'],
		employee: Document['employee']['id'],
		fields: Document['documentFields'],
		template: Document['template']['id'],
	) => Promise<void>;
	firebaseApi: Pick<
		FirebaseApi,
		'templatesByCompanyIDSubscription' | 'subscribeUsersByCompany'
	>;
};

export const NewDocument = ({
	userDetails,
	createDocument,
	firebaseApi,
}: NewDocumentProps): JSX.Element => {
	const [documentName, setDocumentName] = useState<string | null>(null);
	const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
		null,
	);
	const [templates, setTemplates] = useState<Template[]>([]);
	const [selectedEmployee, setSelectedEmployee] =
		useState<UserDetails | null>(null);
	const [employees, setEmployees] = useState<UserDetails[]>([]);
	const [customFields, setCustomFields] = useState<
		Document['documentFields']
	>({});

	useEffect(() => {
		const templateSubscription =
			firebaseApi.templatesByCompanyIDSubscription(
				userDetails.companyID,
				(companyTemplates) => {
					setTemplates(sortByField(companyTemplates, 'name'));
				},
			);
		const employeesSubscription = firebaseApi.subscribeUsersByCompany(
			userDetails.companyID,
			(companyEmployees) => {
				setEmployees(
					sortByField(Object.values(companyEmployees), 'displayName'),
				);
			},
		);
		return () => {
			templateSubscription();
			employeesSubscription();
		};
	}, [firebaseApi, userDetails.companyID]);

	const getDocumentName = (templateName: string): string => {
		if (documentName !== null) {
			return documentName;
		} else if (selectedEmployee !== null) {
			return `${templateName} - ${selectedEmployee.displayName}`;
		} else {
			return templateName;
		}
	};

	const sendDocument = async (
		templateID: string,
		templateName: string,
		employeeID: string,
	): Promise<void> => {
		await createDocument(
			getDocumentName(templateName),
			employeeID,
			customFields,
			templateID,
		);
		// clear fields
		setDocumentName(null);
		setSelectedEmployee(null);
		setSelectedTemplate(null);
		setCustomFields({});
	};

	return (
		<Grid container justifyContent="space-between" spacing={2}>
			<Grid item xs={12} sm={12} md={4}>
				<Card
					sx={{
						height: 'calc(100vh - 185px)',
						padding: 2,
					}}>
					<NewDocumentForm
						templates={templates}
						selectedTemplateState={[
							selectedTemplate,
							setSelectedTemplate,
						]}
						employees={employees}
						selectedEmployeeState={[
							selectedEmployee,
							setSelectedEmployee,
						]}
						customFieldsState={[customFields, setCustomFields]}
						documentNameState={[documentName, setDocumentName]}
						sendDocument={sendDocument}
					/>
				</Card>
			</Grid>

			<Grid item xs={12} sm={12} md={8}>
				<Card
					data-testid="document-preview"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: 'calc(100vh - 185px)',
						mb: 3,
					}}>
					{selectedTemplate === null ? (
						<InitialNewDocumentView />
					) : (
						<>
							<CardHeader
								title={
									<ViewDocumentHeader
										name={getDocumentName(
											selectedTemplate.name,
										)}
										companyName={
											selectedTemplate.company.name
										}
										employeeName={
											selectedEmployee?.displayName ?? ''
										}
									/>
								}
								sx={{ paddingBottom: 0 }}
							/>
							<CardContent
								sx={{
									overflowY: 'auto',
									'&:last-child': {
										pb: 2,
									},
								}}>
								<ViewDocumentBody
									document={{
										template: selectedTemplate,
										documentFields: customFields,
									}}
								/>
							</CardContent>
						</>
					)}
				</Card>
			</Grid>
		</Grid>
	);
};
