import {
	Autocomplete,
	AutocompleteRenderInputParams,
	createFilterOptions,
	FilterOptionsState,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { FixMeLater } from '../../../constants/AnyTypes';
import { IdObject } from './IdObject';

type KeysMatching<T, V> = {
	[K in Required<keyof T>]: T[K] extends V ? K : never;
}[keyof T];

type SearchTemplateProps<T extends IdObject> = {
	currentSelection: T | null;
	setCurrentSelection: (currentSelection: T | null) => void;
	templates: T[];
	label: KeysMatching<T, string>;
	templateName: string;
};

export const SearchTemplates = <T extends IdObject>({
	currentSelection,
	setCurrentSelection,
	templates,
	label,
	templateName,
}: SearchTemplateProps<T>): JSX.Element => {
	const renderInput = (
		params: AutocompleteRenderInputParams,
	): JSX.Element => (
		<TextField
			{...params}
			label={`Select ${templateName} or start typing to add a new one`}
			inputProps={{
				...params.inputProps,
			}}
		/>
	);
	const filterOptions = (
		options: T[],
		params: FilterOptionsState<T>,
	): T[] => {
		const filtered = createFilterOptions<T>()(options, params);

		if (params.inputValue !== '') {
			filtered.push({ id: '', [label]: params.inputValue } as T);
		}

		return filtered;
	};

	const renderOption = (props: FixMeLater, option: T): JSX.Element => (
		<MenuItem
			{...props}
			label={option[label]}
			variant="standard"
			key={option.id}>
			{option.id === '' ? (
				<Typography sx={{ fontStyle: 'italic' }}>
					Create New {templateName}: {option[label]}
				</Typography>
			) : (
				option[label]
			)}
		</MenuItem>
	);
	return (
		<Autocomplete
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
			forcePopupIcon
			value={currentSelection}
			// Aliging to the rubbish bin icons, which are 1 column wide in a 13 col grid
			sx={{ ml: 3, mt: 1, mr: '8%' }}
			filterOptions={filterOptions}
			options={templates}
			isOptionEqualToValue={(option, value): boolean =>
				option.id === value.id
			}
			getOptionLabel={(option): string => {
				/* the other fields take the type hint just fine - just not this one function?*/
				if (typeof option === 'string') {
					// is solo
					return option;
				} else {
					return typeof option[label] === 'string'
						? (option[label] as unknown as string)
						: '???';
				}
			}}
			onChange={(_, value): void => {
				if (typeof value !== 'string') setCurrentSelection(value);
			}}
			renderOption={renderOption}
			renderInput={renderInput}
		/>
	);
};
