import { LoadingButton } from '@mui/lab';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { NewTemplateFields } from './NewTemplateFields';

type NewTemplateProps = {
	createTemplate: (
		name: string,
		body: string,
		fields: Record<string, number>,
	) => Promise<void>;
	maxBodyLength?: number;
};

type ErrorMap = {
	name: boolean;
	body: boolean;
	fields: Record<number, string>;
};

const emptyErrorMap: ErrorMap = {
	name: false,
	body: false,
	fields: {},
};

// Might be a little loose, but figured this would suffice
// https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
const MAX_BODY_SIZE = 1000000;

export const NewTemplate = ({
	createTemplate,
	maxBodyLength = MAX_BODY_SIZE,
}: NewTemplateProps): JSX.Element => {
	const [templateName, setTemplateName] = useState<string>('');
	const [templateBody, setTemplateBody] = useState<string>('');
	const [templateFields, setTemplateFields] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<ErrorMap>(emptyErrorMap);
	const [maxLengthError, setMaxLengthError] = useState(false);

	const validate = (): boolean => {
		const errorMap: ErrorMap = cloneDeep(emptyErrorMap);
		const fieldNamesSet = new Set();

		if (templateName === '') {
			errorMap.name = true;
		}

		if (templateBody === '') {
			errorMap.body = true;
		} else if (
			new TextEncoder().encode(templateBody).length > maxBodyLength
		) {
			errorMap.body = true;
			setMaxLengthError(true);
		}

		for (let index = 0; index < templateFields.length; index++) {
			const value = templateFields[index];

			// Check if the field is empty
			if (value === '') {
				errorMap.fields[index] = 'Please enter a custom field name';
			}

			// Check for duplicate field names
			if (fieldNamesSet.has(value)) {
				errorMap.fields[index] = 'Please enter a unique field name';
			} else {
				fieldNamesSet.add(value);
			}
		}

		setErrors(errorMap);

		return (
			errorMap.name === true ||
			errorMap.body === true ||
			Object.values(errorMap.fields).length > 0
		);
	};

	const clearAll = (): void => {
		setTemplateName('');
		setTemplateBody('');
		setTemplateFields([]);
		setErrors(emptyErrorMap);
	};

	const saveTemplate = async (): Promise<void> => {
		if (!validate()) {
			setLoading(true);
			const fieldMap: Record<string, number> = {};
			const fields: Record<string, number> = templateFields.reduce(
				(acc, field, index) => {
					acc[field] = index; // Set the value to the index to maintain order
					return acc;
				},
				fieldMap,
			);
			await createTemplate(templateName, templateBody, fields);
			setLoading(false);
			clearAll();
		}
	};

	const addField = (): void => {
		setTemplateFields((prev) => [...prev, '']);
	};

	const removeField = (index: number): void => {
		const fields = cloneDeep(templateFields);
		fields.splice(index, 1);
		setTemplateFields(fields);
		setErrors((prev) => ({
			...prev,
			fields: {},
		}));
	};

	const handleUpdateField = (value: string, index: number): void => {
		setErrors((prev) => ({
			...prev,
			fields: {},
		}));
		const prevFields = [...templateFields];
		prevFields[index] = value;
		setTemplateFields(prevFields);
	};

	return (
		<Card
			sx={{
				height: 'calc(100vh - 185px)',
				mb: 3,
			}}>
			<CardHeader
				title="New Template"
				action={
					<LoadingButton
						loading={loading}
						sx={{ marginRight: 1 }}
						variant="contained"
						onClick={saveTemplate}>
						Save
					</LoadingButton>
				}
			/>
			<CardContent
				sx={{
					pt: 0,
					'&:last-child': {
						pb: 0,
					},
					height: 'calc(100% - 64px)',
					overflow: 'auto',
				}}>
				<Grid container height="100%">
					<Grid item xs={12} md={6}>
						<Box pr={{ xs: 0, md: 5 }}>
							<Typography variant="body1" pb={1}>
								Template Title
							</Typography>
							<TextField
								label="(required)"
								required
								InputLabelProps={{
									shrink: true,
								}}
								data-testid="template-title"
								value={templateName}
								fullWidth
								size="small"
								onChange={(event): void => {
									setErrors((prev) => ({
										...prev,
										name: false,
									}));
									setTemplateName(event.target.value);
								}}
								error={errors.name}
								disabled={loading}
							/>
						</Box>
						<NewTemplateFields
							addField={addField}
							loading={loading}
							templateFields={templateFields}
							removeField={removeField}
							handleUpdateField={handleUpdateField}
							errors={errors.fields}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						flexDirection="column"
						justifyContent="flex-end"
						pb={2}>
						<Typography variant="body1" pb={1}>
							Template Body
						</Typography>
						<TextField
							label="(required)"
							required
							InputLabelProps={{
								shrink: true,
							}}
							multiline
							fullWidth
							data-testid="template-body"
							size="small"
							minRows={9}
							value={templateBody}
							onChange={(event): void => {
								setErrors((prev) => ({
									...prev,
									body: false,
								}));
								setMaxLengthError(false);
								setTemplateBody(event.target.value);
							}}
							error={maxLengthError || errors.body}
							helperText={
								maxLengthError &&
								`Too many characters. ${maxBodyLength} limit`
							}
							disabled={loading}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
