import {
	EmployeeIntegrationTableRow,
	isEmployeeLink,
} from '../../../ProjectTracking/Common/Employees/Models/employeeTab';

export const employeeIntegrationTableRowSearch = (
	searchQuery: string,
	currentRow: (string | EmployeeIntegrationTableRow['account'])[],
): boolean => {
	const lowerCaseQuery = searchQuery.toLowerCase();
	return currentRow.some((cell): boolean => {
		if (typeof cell === 'string') {
			return cell.toLowerCase().includes(lowerCaseQuery);
		} else if (isEmployeeLink(cell)) {
			return (
				cell.integrationName.toLowerCase().includes(lowerCaseQuery) ||
				cell.integrationID.toLowerCase().includes(lowerCaseQuery)
			);
		}
		return false;
	});
};
