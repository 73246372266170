import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (app.apps.length === 0) {
	app.initializeApp(config);
}

// Auth is export like this for testing. If a test file pulls in firebase
// (importing Firestore say) it would try init the Auth module and fail due to no config
export const Auth = (): app.auth.Auth => app.auth();
export const Firestore = app.firestore();
export const Storage = app.storage();
export const Analytics = app.analytics;
export const App = app;

export type App = firebase.app.App;
export type Analytics = firebase.analytics.Analytics;
export type QuerySnapshot<T = firebase.firestore.DocumentData> =
	firebase.firestore.QuerySnapshot<T>;
export type Query = firebase.firestore.Query;
export type DocumentReference = firebase.firestore.DocumentReference;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type Timestamp = firebase.firestore.Timestamp;
export type WriteBatch = firebase.firestore.WriteBatch;
export type FirestoreError = firebase.firestore.FirestoreError;
export type User = firebase.User;
export type DocumentQuery =
	firebase.firestore.Query<firebase.firestore.DocumentData>;
export type FirestoreDataConverter<T> =
	firebase.firestore.FirestoreDataConverter<T>;

// auth types
export type Auth = firebase.auth.Auth;
export type RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
export type PhoneInfoOptions = firebase.auth.PhoneInfoOptions;
export type FirebaseAuthError = firebase.auth.Error;
export type MultiFactorError = firebase.auth.MultiFactorError;
export type MultiFactorResolver = firebase.auth.MultiFactorResolver;

export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider;
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
export const PhoneMultiFactorGenerator =
	firebase.auth.PhoneMultiFactorGenerator;

export type FieldValue = firebase.firestore.FieldValue;
export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;
export const FieldPath = firebase.firestore.FieldPath;

/** Filter a collectionGroup query based upon it's path
 *
 * the specified path can be as deeply nested as you like, but cannot skip levels
 */
export const filterCollectionGroupOnParent = (
	collectionGroup: string,
	parentPath: string,
): DocumentQuery =>
	Firestore.collectionGroup(collectionGroup)
		.orderBy(FieldPath.documentId())
		.startAt(parentPath)
		.endAt(parentPath + '\uf8ff');
