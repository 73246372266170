import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import {
	SafetyCourseValidationStatus,
	safetyCourseStatus,
} from '../../../../constants/SafetyCourse';
import { formatSlashedDate } from '../../../helpers/dateFormatters';
import { SafetyCourseStatusIcon } from '../SafetyCourseStatusIcon/SafetyCourseStatusIcon';

type SafetyCourseValidationInfoProps = {
	status: SafetyCourseValidationStatus;
	safetyCourseID: string;
	courseName: string;
	courseExpiry: Date | null;
};

const SafetyCourseValidationInfoImpl = ({
	status,
	safetyCourseID,
	courseName,
	courseExpiry,
}: SafetyCourseValidationInfoProps): JSX.Element => {
	switch (status) {
		case safetyCourseStatus.Expired:
			return (
				<>
					<SafetyCourseStatusIcon status={status} />
					<Box pl={2}>
						<Typography variant="body1Bold">
							Safety Course Has Expired
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							{safetyCourseID} - {courseName}
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							Expired on{' '}
							{courseExpiry
								? formatSlashedDate(courseExpiry)
								: 'unknown date'}
						</Typography>
					</Box>
				</>
			);
		case safetyCourseStatus.Valid:
			return (
				<>
					<SafetyCourseStatusIcon status={status} />
					<Box pl={2}>
						<Typography variant="body1Bold">
							Current Safety Course Found
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							{safetyCourseID} - {courseName}
						</Typography>
						{courseExpiry && (
							<Typography
								variant="subtitle2"
								color="text.secondary">
								Valid until {formatSlashedDate(courseExpiry)}
							</Typography>
						)}
					</Box>
				</>
			);
		case safetyCourseStatus.NotFound:
			return (
				<>
					<SafetyCourseStatusIcon status={status} />
					<Box pl={2}>
						<Typography variant="body1Bold">
							Failed to find employee safety course
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							{`Confirm that the employees' safety course ID is correct.`}
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							Check that their account is not private and is in
							the safety course system.
						</Typography>
					</Box>
				</>
			);
		case SafetyCourseValidationStatus.Pending:
		default:
			return (
				<>
					<SafetyCourseStatusIcon status="Pending" />
					<Box pl={2}>
						<Typography variant="body1Bold">
							Unknown Safety Course Status
						</Typography>
						<Typography variant="subtitle2" color="text.secondary">
							{`Enter a user's safety course ID above to check their status.`}
						</Typography>
					</Box>
				</>
			);
	}
};

export const SafetyCourseValidationInfo = memo(SafetyCourseValidationInfoImpl);
