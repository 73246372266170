import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { Chip, Tooltip } from '@mui/material';
import {
	InvoiceStatus,
	ProjectTrackingStatus,
	TimesheetPayrollStatus,
} from '../../constants/Timesheet/Timesheet';

export const StatusChipType = {
	Invoicing: 'Invoicing',
	Payroll: 'Payroll',
	ProjectTracking: 'Project Tracking',
} as const;

export type IntegrationStatusChipProps =
	| {
			type: (typeof StatusChipType)['Payroll'];
			status: TimesheetPayrollStatus;
	  }
	| {
			type: (typeof StatusChipType)['Invoicing'];
			status: InvoiceStatus;
	  }
	| {
			type: (typeof StatusChipType)['ProjectTracking'];
			status: ProjectTrackingStatus;
	  };

export const IntegrationStatusChip = ({
	type,
	status,
}: IntegrationStatusChipProps): JSX.Element => {
	switch (status) {
		case 'Failed':
			return (
				<FailedTooltip type={type}>
					<Chip
						icon={<ErrorIcon />}
						label="Failed"
						color="error"
						size="small"
						style={{ cursor: 'pointer' }}
					/>
				</FailedTooltip>
			);
		case 'Sent':
			return (
				<SuccessTooltip type={type}>
					<Chip
						icon={<CheckCircleIcon />}
						label="Sent"
						color="success"
						size="small"
						style={{ cursor: 'pointer' }}
					/>
				</SuccessTooltip>
			);
		case 'Unsent':
		default:
			return (
				<UnsentTooltip type={type}>
					<Chip
						icon={<InfoIcon />}
						label="Not Sent"
						color="info"
						size="small"
						style={{ cursor: 'pointer' }}
					/>
				</UnsentTooltip>
			);
	}
};

const FailedTooltip = ({
	type,
	children,
}: {
	type: (typeof StatusChipType)[keyof typeof StatusChipType];
	children: JSX.Element;
}): JSX.Element => (
	<Tooltip title={`Failed to send to ${type}`}>{children}</Tooltip>
);

const SuccessTooltip = ({
	type,
	children,
}: {
	type: (typeof StatusChipType)[keyof typeof StatusChipType];
	children: JSX.Element;
}): JSX.Element => <Tooltip title={`Sent to ${type}`}>{children}</Tooltip>;

const UnsentTooltip = ({
	type,
	children,
}: {
	type: (typeof StatusChipType)[keyof typeof StatusChipType];
	children: JSX.Element;
}): JSX.Element => <Tooltip title={`Not sent to ${type}`}>{children}</Tooltip>;
