import { Grid, Typography } from '@mui/material';
import { DayString } from '../../../../../constants/Common';

type DaySectionProps = {
	day: DayString;
	sections: JSX.Element[];
};

export const DaySection = ({ day, sections }: DaySectionProps): JSX.Element => {
	return (
		<Grid
			data-testid={`${day}-section`}
			container
			spacing={1}
			marginBottom={2}>
			<Grid item marginTop={1} md={12}>
				<Typography variant="h5Bold">{day}</Typography>
			</Grid>
			{sections.map((section, index) => (
				<Grid item key={`${day}-section-${index}`} md={6} xs={12}>
					{section}
				</Grid>
			))}
		</Grid>
	);
};
