import { Box, Tabs, Tab } from '@mui/material';
import { useEffect, useReducer } from 'react';
import { Outlet, useMatch, useOutletContext } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { createDocumentsReducer } from './StateManagement/documentsReducer';
import {
	DocumentsContextFirebaseCalls,
	DocumentsOutletContext,
} from './StateManagement/models';

export const DocumentsWrapper = ({
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const pathMatch = useMatch('/documents/:tab');
	const [searchParams] = useSearchParams();
	const documentID = searchParams.get('documentID');
	const templateID = searchParams.get('templateID');

	const [context, dispatch] = useReducer(createDocumentsReducer, {
		firebaseApi,
		documentsData: [],
		templateData: [],

		documentID: null,
		templateID: null,
		loading: true,
	});

	const titles = ['documents', 'templates', 'new document', 'new template'];

	useEffect(() => {
		dispatch({
			key: 'documentID',
			value: (prev) => (documentID ? documentID : prev),
		});
	}, [documentID]);

	useEffect(() => {
		dispatch({
			key: 'templateID',
			value: (prev) => (templateID ? templateID : prev),
		});
	}, [templateID]);

	useEffect(() => {
		return context.firebaseApi.documentsByCompanyIDSubscription(
			userDetails.companyID,
			(documents) => {
				dispatch({
					key: 'documentsData',
					value: documents,
				});
				dispatch({
					key: 'loading',
					value: false,
				});
			},
		);
	}, [context.firebaseApi, userDetails.companyID]);

	useEffect(() => {
		return context.firebaseApi.templatesByCompanyIDSubscription(
			userDetails.companyID,
			(templates) => {
				dispatch({
					key: 'templateData',
					value: () => templates,
				});
				dispatch({
					key: 'loading',
					value: false,
				});
			},
		);
	}, [context.firebaseApi, userDetails.companyID]);

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
						/>
					))}
				</Tabs>
			</Box>

			<Outlet context={{ context, dispatch }} />
		</>
	);
};

/** Gets the reducer state and dispatch function required by children of Documents
 *
 * The optional Type parameter T is used to narrow the required functions of firebaseApi inside context
 */
export const useDocumentsContext = <
	T extends keyof DocumentsContextFirebaseCalls,
>(): DocumentsOutletContext<T> => useOutletContext<DocumentsOutletContext<T>>();
