import { ErrorOutline, Link, LinkOff } from '@mui/icons-material';
import { Chip, SxProps, Theme, Tooltip } from '@mui/material';

export enum IntegrationLinkStatus {
	Linked = 'Linked',
	Unlinked = 'Unlinked',
	Missing = 'Missing',
}

export type IntegrationStatusChipProps = {
	status: IntegrationLinkStatus;
};

export const IntegrationStatusChip = ({
	status,
}: IntegrationStatusChipProps): JSX.Element => {
	const chipStyle: SxProps<Theme> = {
		fontWeight: 'bold',
		justifyContent: 'left',
		padding: '4px',
		width: '110px',
		'& .MuiChip-icon': {
			mx: '4px',
		},
	};

	switch (status) {
		case IntegrationLinkStatus.Linked:
			return (
				<Tooltip title={status}>
					<Chip
						icon={<Link />}
						label={status}
						color="success"
						size="small"
						sx={chipStyle}
					/>
				</Tooltip>
			);
		case IntegrationLinkStatus.Unlinked:
			return (
				<Tooltip title={status}>
					<Chip
						icon={<LinkOff />}
						label={status}
						color="neutral"
						size="small"
						sx={chipStyle}
					/>
				</Tooltip>
			);
		case IntegrationLinkStatus.Missing:
			return (
				<Tooltip title={status}>
					<Chip
						icon={<ErrorOutline />}
						label={status}
						color="error"
						size="small"
						sx={chipStyle}
					/>
				</Tooltip>
			);
	}
};
