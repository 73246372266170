import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	List,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import {
	stripeBillingStatus,
	StripeGetCustomerOutstandingPaymentsResponse,
} from '../../constants/Common';
import { DefaultTheme } from '../../Theme';
import { formatSpacedDate } from '../helpers/dateFormatters';

export type OutstandingPaymentsModalProps = {
	modalOpen: boolean;
	onClose: () => void;
	outstandingPayments: Extract<
		StripeGetCustomerOutstandingPaymentsResponse,
		{ status: typeof stripeBillingStatus.Outstanding }
	>;
};

export const OutstandingPaymentsModal = ({
	modalOpen,
	onClose,
	outstandingPayments,
}: OutstandingPaymentsModalProps): JSX.Element => {
	return (
		<Dialog
			color="default"
			scroll="paper"
			fullWidth
			maxWidth="sm"
			open={modalOpen}>
			<DialogTitle>Outstanding Payments On Your Subscription</DialogTitle>
			<DialogContent>
				<DialogContentText>
					The following invoices have unpaid amounts due before
					renewing your subscription:
				</DialogContentText>
				<List>
					{outstandingPayments.unpaidInvoices.map((invoice) => (
						<ListItemButton
							key={invoice.invoiceNumber}
							disabled={invoice.invoiceURL == null}
							href={invoice.invoiceURL ?? ''}
							target="_blank"
							onClick={onClose}>
							<ListItemText
								primaryTypographyProps={{
									style: {
										color: DefaultTheme.palette.primary
											.main,
										textDecorationLine: 'underline',
										display: 'list-item',
										listStylePosition: 'inside',
										listStyleType: 'disc',
									},
								}}>
								{`${
									invoice.invoiceURL == null
										? '(Unavailable) '
										: ''
								}Invoice ${
									invoice.invoiceNumber
								} Issued ${formatSpacedDate(
									new Date(invoice.dateIssued * 1000),
								)}`}
								<OpenInNewIcon
									style={{
										fontSize:
											DefaultTheme.typography.fontSize,
									}}
								/>
							</ListItemText>
						</ListItemButton>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};
