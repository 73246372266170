import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { EmployeeDetails } from './EmployeeDetails';

export const EmployeeDetailsWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	return (
		<EmployeeDetails userDetails={userDetails} firebaseApi={firebaseApi} />
	);
};
