import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

type DeleteTemplateModalProps = {
	open: boolean;
	loading: boolean;
	onClose: () => void;
	deleteTemplate: () => Promise<void>;
};

export const DeleteTemplateModal = ({
	open,
	loading,
	onClose,
	deleteTemplate,
}: DeleteTemplateModalProps): JSX.Element => {
	return (
		<Dialog open={open}>
			<DialogTitle>Delete Template</DialogTitle>
			<DialogContent>
				<DialogContentText fontSize="small">
					Are you sure you want to permanently delete this template?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={onClose}
					disabled={loading}
					color="primary">
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					onClick={async (): Promise<void> => {
						await deleteTemplate();
						onClose();
					}}
					data-testid="delete-template"
					disabled={loading}
					loading={loading}
					color="error">
					Delete
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
