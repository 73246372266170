import CloseIcon from '@mui/icons-material/Close';
import {
	ButtonBase,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	Theme,
} from '@mui/material';
import React, { useState } from 'react';

type ExpandDialogProps = {
	children: React.ReactNode;
	buttonStyles?: SxProps<Theme>;
};

export const ExpandDialog = ({
	children,
	buttonStyles,
}: ExpandDialogProps): JSX.Element => {
	const [open, setOpen] = useState(false);

	const handleOnClick = (): void => setOpen(true);

	const handleOnClose = (): void => setOpen(false);

	const expandDialog = (): JSX.Element => (
		<Dialog id="dialog" maxWidth="xl" open={open} onClose={handleOnClose}>
			<DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleOnClose}
					sx={{
						padding: '4px',
						position: 'absolute',
						right: 4,
						top: 4,
					}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					paddingTop: '4px !important',
					height: '80vh',
					width: '80vh',
				}}>
				{children}
			</DialogContent>
		</Dialog>
	);

	return (
		<>
			{expandDialog()}
			<ButtonBase sx={buttonStyles} onClick={handleOnClick}>
				{children}
			</ButtonBase>
		</>
	);
};
