import { isEqual } from 'lodash';
import { SetStateAction } from 'react';
import { ValueOf } from '../../../constants/TypescriptUtilities';
import { DocumentsContext, DocumentsActions } from './models';

export const createDocumentsReducer = (
	state: DocumentsContext,
	action: DocumentsActions,
): DocumentsContext => {
	const setter = <T extends ValueOf<DocumentsContext>>(
		value: SetStateAction<T>,
		prev: T,
	): T => (typeof value === 'function' ? value(prev) : value);

	if (isEqual(action.value, state[action.key])) return state;

	switch (action.key) {
		case 'documentID': {
			const selected = setter(action.value, state[action.key]);
			return {
				...state,
				documentID: selected,
			};
		}
		case 'templateID': {
			const selected = setter(action.value, state[action.key]);
			return {
				...state,
				templateID: selected,
			};
		}
		case 'loading': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'documentsData': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
		case 'templateData': {
			return {
				...state,
				[action.key]: setter(action.value, state[action.key]),
			};
		}
	}
};
