import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SignInUpWrapper } from './SignInUpWrapper';

const AUTH_STATE = 'approval';

type AwaitingApprovalProps = {
	onSignOut: () => void;
};

export const AwaitingApproval = ({
	onSignOut,
}: AwaitingApprovalProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<SignInUpWrapper
			title="Awaiting Approval"
			authState={AUTH_STATE}
			back={{
				label: 'Back',
				handle: () => navigate('/company-information'),
			}}
			next={{ label: 'Log Out', handle: onSignOut }}>
			<Typography textAlign="center" variant="h6">
				Contact your supervisor or Trade Legion
			</Typography>
		</SignInUpWrapper>
	);
};
