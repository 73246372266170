export const getTimeForHours = (hours: number): string => {
	const sign = hours < 0 ? '-' : '';
	const absHours = Math.abs(hours);
	const wholeHours = Math.trunc(absHours);
	const minutes = Math.round((absHours - wholeHours) * 60)
		.toString()
		.padStart(2, '0');
	return `${sign}${wholeHours}h ${minutes}m`;
};

export const replaceSpaceWithHyphen = (str: string): string =>
	str.replace(/\s+/g, '-');

export const replaceHyphenWithSpace = (str: string): string =>
	str.replace(/-/g, ' ');
