import ClearIcon from '@mui/icons-material/Clear';
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { Contract } from '../../../../constants/Contract';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	BaseIntegrationLink,
	InvoicingContractElement,
} from '../../../../models/Integrations/IntegrationElements';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { ConfirmLinkDialog } from '../../IntegrationUIComponents/ConfirmLinkDialog';
import {
	ColumnGrid,
	IntegrationUIHeader,
	RowGridContainer,
} from '../../IntegrationUIComponents/IntegrationUIComponents';
import { ActionSuccessfulSnackBar } from '../ActivityAndLeaveMapping/ActionSuccessfulSnackBar';

export type ContractToPlacement = {
	id: string;
	name: string;
	invoicingID: string;
	invoicingName: string;
	invoicingCompanyName: string;
};

type ContractMappingTableProps = {
	headerTitle: { left: string; right: string };
	dropdownLabel: string;
	dropdownHelperText: string;
	userInvoicingEntries: Record<string, BaseIntegrationLink>;
	invoicingContractElements: InvoicingContractElement[];
	mapping: Record<string, Contract>;
	invoicingIntegrationCompanyID: string;
	fetchInvoicingIDs: (abortSignal: AbortSignal) => Promise<void>;
	loading: boolean;
};

export const ContractMappingTable = ({
	headerTitle,
	dropdownLabel,
	dropdownHelperText,
	userInvoicingEntries,
	invoicingContractElements,
	mapping,
	invoicingIntegrationCompanyID,
	fetchInvoicingIDs,
	loading,
}: ContractMappingTableProps): JSX.Element => {
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [contractToPlacement, setContractToPlacement] =
		useState<ContractToPlacement | null>(null);

	const [actionSuccessfulSnackBarOpen, setActionSuccessfulSnackBarOpen] =
		useState<boolean>(false);
	const [invoicingConnectionDeleted, setInvoicingConnectionDeleted] =
		useState<boolean>(false);

	const invoicingPayElementsMap = invoicingContractElements
		? invoicingContractElements.reduce(
				(
					acc: Record<string, InvoicingContractElement>,
					invoicingPayElement,
				) => {
					acc[invoicingPayElement.id] = invoicingPayElement;
					return acc;
				},
				{},
		  )
		: {};

	const updateMapping = async (
		contractID: string,
		newInvoicingID: string,
	): Promise<void> => {
		if (newInvoicingID === '') {
			// delete the seleted item
			await firebaseApi
				.deleteInvoicingIntegrationPlacement(
					invoicingIntegrationCompanyID,
					contractID,
				)
				.catch((error) => {
					console.error('Failed to delete placement: ', error);
				});
			setInvoicingConnectionDeleted(newInvoicingID === '');

			return;
		}

		// This is what is being saved, so would be bad to be missing
		if (contractToPlacement === null) return;

		const newInvoicingTypeEntry: InvoicingContractElement | undefined =
			invoicingPayElementsMap[newInvoicingID];

		const currentEntry = userInvoicingEntries[contractID];

		if (
			newInvoicingTypeEntry === undefined ||
			currentEntry?.id === newInvoicingID
		)
			return; // same selected as previous userInvoicingContracts mapping no need to update

		const updatedEntry: BaseIntegrationLink = {
			id: contractID,
			name: contractToPlacement.name,
			integrationID: contractToPlacement.invoicingID,
			integrationName: contractToPlacement.invoicingName,
		};

		await firebaseApi.createInvoicingIntegrationPlacement(
			invoicingIntegrationCompanyID,
			updatedEntry,
		);

		setContractToPlacement(null);
		setInvoicingConnectionDeleted(false);
		setActionSuccessfulSnackBarOpen(true);
	};

	const updateMappingToSave = (id: string, invoicingID: string): void => {
		const payElement = invoicingPayElementsMap[invoicingID];
		const contract = mapping[id];

		const name = contract.site?.name
			? `${contract.employee.name} - ${contract.accepterCompany.name} - ${contract.site.name}`
			: `${contract.employee.name} - ${contract.accepterCompany.name}`;

		let newMapping: ContractToPlacement;
		if (payElement === undefined) {
			// Deleting
			const payEntry = userInvoicingEntries[id];
			const localPayElement =
				invoicingPayElementsMap[payEntry.integrationID];
			newMapping = {
				id,
				name,
				invoicingID: '',
				invoicingName: localPayElement.placementName,
				invoicingCompanyName: localPayElement.clientName,
			};
		} else {
			newMapping = {
				id,
				name,
				invoicingID: payElement.id,
				invoicingName: payElement.placementName,
				invoicingCompanyName: payElement.clientName,
			};
		}

		setContractToPlacement(newMapping);
		setConfirmDialogOpen(true);
	};

	const invoicingSelectDropDown = (id: string): JSX.Element => {
		const invoicingID = userInvoicingEntries[id]?.integrationID ?? '';
		const missingMapping =
			invoicingID !== '' &&
			!invoicingContractElements.some(
				(contract) => contract.id === invoicingID,
			);
		return (
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				textAlign="left">
				<TextField
					id={id}
					value={invoicingID}
					label={`${dropdownLabel}${
						id === 'Default' ? ' (required)' : ''
					}`}
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>,
					): void => updateMappingToSave(id, event.target.value)}
					error={missingMapping}
					required={id === 'Default'}
					helperText={missingMapping ? dropdownHelperText : ''}
					fullWidth
					select>
					{invoicingContractElements.map((payElement) => (
						<MenuItem
							key={`${id}-${payElement.id}`}
							id={payElement.id}
							value={payElement.id}>
							<Typography noWrap>
								{`${payElement.placementName} - ${payElement.clientName}`}
							</Typography>
						</MenuItem>
					))}
				</TextField>
				<IconButton
					size="small"
					color="primary"
					disabled={invoicingID === ''}
					onClick={(): void => updateMappingToSave(id, '')}>
					<Tooltip title="Remove">
						<ClearIcon fontSize="medium" />
					</Tooltip>
				</IconButton>
			</Stack>
		);
	};

	const allMappings = Object.entries(mapping);

	// Using this currently to deal with the difference between Activity/Leave items and Contract items
	const contractToPlacementConverter = (
		contractToPlacement: ContractToPlacement,
	): Pick<BaseIntegrationLink, 'name' | 'integrationName'> => {
		return {
			name: contractToPlacement.name,
			integrationName: `${contractToPlacement.invoicingName} - ${contractToPlacement.invoicingCompanyName}`,
		};
	};

	return (
		<Box px={3}>
			<IntegrationUIHeader
				leftTitle={headerTitle.left}
				rightTitle={headerTitle.right}
				fetchList={fetchInvoicingIDs}
			/>
			<Divider orientation="horizontal" />
			{loading ? (
				<LoadingDots />
			) : (
				<Stack py={1}>
					{allMappings.length > 0 ? (
						allMappings.map(
							([contractID, contract]: [string, Contract]) => (
								<RowGridContainer key={contractID}>
									<ColumnGrid md={4}>
										<Typography>
											{contract.employee.name}
										</Typography>
										<Typography variant="caption">
											{contract.accepterCompany.name}
										</Typography>
									</ColumnGrid>
									<Divider
										orientation="vertical"
										variant="middle"
										flexItem
									/>
									<ColumnGrid md={6}>
										{invoicingSelectDropDown(contractID)}
									</ColumnGrid>
								</RowGridContainer>
							),
						)
					) : (
						<Typography textAlign="center">
							No Contracts to manage
						</Typography>
					)}
				</Stack>
			)}
			{contractToPlacement && (
				<ConfirmLinkDialog
					type="Contract"
					mappingRemoved={contractToPlacement.invoicingID === ''}
					singleMapping={contractToPlacementConverter(
						contractToPlacement,
					)}
					open={confirmDialogOpen}
					handleCancel={(): void => setConfirmDialogOpen(false)}
					handleConfirm={(): void => {
						setConfirmDialogOpen(false);
						updateMapping(
							contractToPlacement.id,
							contractToPlacement.invoicingID,
						);
					}}
				/>
			)}
			<ActionSuccessfulSnackBar
				type="Contract"
				integrationConnectionDeleted={invoicingConnectionDeleted}
				actionSuccessfulSnackBarOpen={actionSuccessfulSnackBarOpen}
				setActionSuccessfulSnackBarOpen={
					setActionSuccessfulSnackBarOpen
				}
			/>
		</Box>
	);
};
