import { LoadingButton } from '@mui/lab';
import { ChangeEvent } from 'react';

type ImportCSVButtonProps = {
	title: string;
	isLoading: boolean;
	callback: (file: File | null) => Promise<void>;
};

export const ImportCSVButton = ({
	title,
	callback,
	isLoading,
}: ImportCSVButtonProps): JSX.Element => {
	const handleFileChange = async (
		event: ChangeEvent<HTMLInputElement>,
	): Promise<void> => {
		const file = event.target.files?.[0];
		if (file?.type === 'text/csv') {
			callback(file);
		} else {
			callback(null);
		}
	};

	return (
		<LoadingButton
			variant="contained"
			component="label"
			fullWidth
			loading={isLoading}
			sx={{ textAlign: 'center' }}>
			Import {title} CSV
			<input
				type="file"
				hidden
				value=""
				onChange={handleFileChange}
				accept=".csv"
				data-testid="inputTest"
			/>
		</LoadingButton>
	);
};
