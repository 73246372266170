import { Box, Button, Paper, Stack } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import { Company } from '../../../../constants/Common';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IntegrationElement } from '../../../../models/Integrations/IntegrationElements';
import {
	InvoicingLink,
	InvoicingType,
	dueDateLabels,
	groupByLabels,
	sendInvoiceLabels,
} from '../../../../models/Integrations/InvoicingIntegration';
import { DataTable } from '../../../DataTable/DataTable';
import { sortByField } from '../../../helpers/sortHelpers';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { ConfirmLinkDialog } from '../../IntegrationUIComponents/ConfirmLinkDialog';
import { ErrorFooter } from '../ActivityAndLeaveMapping/ErrorFooter';
import { ClientIntegrationTabProps } from '../IntegrationTab';
import { ClientMappingDialog } from './ClientMappingDialog';

export const ClientMappingTab = ({
	user,
	userDetails,
	integration,
	fetchData,
	firebaseApi,
}: ClientIntegrationTabProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
	const [dialogLink, setDialogLink] = useState<InvoicingLink>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const [companyMapping, setCompanyMapping] = useState<
		Record<string, Company>
	>({});
	const [userInvoicingClients, setUserInvoicingClients] = useState<
		InvoicingLink[] | null
	>(null);
	const [invoicingClients, setInvoicingClients] = useState<
		IntegrationElement[] | null
	>(null);

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no documents found'
	);

	const getInvoicingClientIDs = useCallback(
		async (abortSignal: AbortSignal) => {
			const invoicingClientIDs = await fetchData(abortSignal, user);

			if (invoicingClientIDs) {
				setInvoicingClients(invoicingClientIDs.companies);
				if (invoicingClientIDs.companies.length === 0) {
					setError(
						`No Clients available from ${integration.type}. Please ensure you have your invoicing system set up.`,
					);
				} else {
					setError(null);
				}
			} else {
				if (!abortSignal.aborted) {
					setError(
						`Unable to get Clients from ${integration.type}. Please try again.`,
					);
					setInvoicingClients([]);
				}
			}
		},
		[fetchData, integration.type, user],
	);

	useEffect(() => {
		const companySubscription = firebaseApi.companiesSubscriptionByType(
			'construction',
			(companies) => {
				const company: Record<string, Company> = Object.fromEntries(
					companies.map((company) => [company.id, company]),
				);
				setCompanyMapping(company);
			},
		);

		const invoicingintegrationsSub =
			firebaseApi.invoicingIntegrationClientSubscription(
				userDetails.companyID,
				(links) => {
					setUserInvoicingClients(sortByField(links, 'name'));
				},
			);

		getInvoicingClientIDs(abortSignal);

		return () => {
			companySubscription();
			invoicingintegrationsSub();
		};
	}, [
		getInvoicingClientIDs,
		integration.companyID,
		userDetails.companyID,
		abortSignal,
		firebaseApi,
	]);

	useEffect(() => {
		if (!companyMapping || !userInvoicingClients || !invoicingClients) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [userDetails, companyMapping, userInvoicingClients, invoicingClients]);

	const openDialog = (id?: string): void => {
		const link = userInvoicingClients?.find((Client) => Client.id === id);
		// { ...link } is to make sure that we re-render if the user reopens the same link
		setDialogLink(link ? { ...link } : undefined);
		setMappingDialogOpen(true);
	};

	const removeLink = (id: string): void => {
		const link = userInvoicingClients?.find((Client) => Client.id === id);
		// { ...link } is to make sure that we re-render if the user reopens the same link
		setDialogLink(link ? { ...link } : undefined);
		setRemoveDialogOpen(true);
	};

	const columns: MUIDataTableColumnDef[] = [
		{ label: 'Trade Legion Name', name: 'name' },
		{ label: `${integration.type} Client`, name: 'integrationName' },
		{
			label: 'Group By',
			name: 'groupBy',
			options: {
				customBodyRender: (value: InvoicingLink['groupBy']): string => {
					return groupByLabels[value];
				},
			},
		},
		{
			label: 'Send Invoice',
			name: 'sendInvoice',
			options: {
				customBodyRender: (
					value: InvoicingLink['sendInvoice'],
				): string => {
					return sendInvoiceLabels[value];
				},
			},
		},
		{
			label: 'Invoice Due Date',
			name: 'dueDate',
			options: {
				customBodyRender: (value: InvoicingLink['dueDate']): string => {
					return dueDateLabels[value];
				},
			},
		},
		{
			label: 'Options',
			name: 'id',
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: 'center',
				}),
				customBodyRender: (value: InvoicingLink['id']): JSX.Element => {
					return (
						<Stack direction="row" spacing={1} width="100%" pr={1}>
							<Box minWidth="50%">
								<Button
									fullWidth
									disabled={invoicingClients?.length === 0}
									variant="outlined"
									onClick={(): void => openDialog(value)}>
									Edit
								</Button>
							</Box>
							<Box minWidth="50%">
								<Button
									fullWidth
									variant="contained"
									onClick={(): void => removeLink(value)}>
									Remove
								</Button>
							</Box>
						</Stack>
					);
				},
			},
		},
	];

	const addButton = (): JSX.Element => (
		<Button
			variant="contained"
			disabled={loading}
			sx={{ ml: 1 }}
			onClick={(): void => openDialog(undefined)}>
			Add Client
		</Button>
	);

	return (
		<>
			<Box>
				<Paper id="boxList" elevation={1}>
					<DataTable
						tableData={userInvoicingClients ?? []}
						columns={columns}
						loading={loading}
						title="Link Clients"
						customToolbar={addButton}
						customTableOptions={{
							tableBodyHeight: 'calc(100vh - 304px)',
							textLabels: {
								body: {
									noMatch: noMatchTableText,
								},
							},
							print: false,
							download: false,
						}}
					/>
					{error !== null && <ErrorFooter error={error} />}
				</Paper>
			</Box>
			{dialogLink && (
				<ConfirmLinkDialog
					type="Client"
					mappingRemoved={true}
					singleMapping={dialogLink}
					open={removeDialogOpen}
					handleCancel={(): void => {
						setDialogLink(undefined);
						setRemoveDialogOpen(false);
					}}
					handleConfirm={async (): Promise<void> => {
						await firebaseApi.removeInvoicingIntegrationContactLink(
							userDetails.companyID,
							dialogLink,
						);
						setDialogLink(undefined);
						setRemoveDialogOpen(false);
					}}
				/>
			)}
			<ClientMappingDialog
				open={mappingDialogOpen}
				setOpen={setMappingDialogOpen}
				companies={companyMapping}
				existingLinks={userInvoicingClients ?? []}
				invoicingClients={invoicingClients ?? []}
				integrationType={integration.type as InvoicingType}
				updateLink={dialogLink}
				setLink={(link): Promise<void> =>
					firebaseApi.setInvoicingIntegrationClient(
						userDetails.companyID,
						link,
					)
				}
			/>
		</>
	);
};
