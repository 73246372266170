import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Tooltip } from '@mui/material';
import { Document } from '../../constants/Document';

type DocumentStatusIconProps = {
	documentStatus: Document['status'];
};
export const DocumentStatusIcon = ({
	documentStatus,
}: DocumentStatusIconProps): JSX.Element => icon[documentStatus];

const icon: Record<Document['status'], JSX.Element> = {
	Signed: (
		<Tooltip title="Signed">
			<CheckCircleOutlineIcon color="success" />
		</Tooltip>
	),
	Unsigned: (
		<Tooltip title="Unsigned">
			<HighlightOffOutlinedIcon color="error" />
		</Tooltip>
	),
};
