import { Company, emptyFunction } from '../../constants/Common';
import { SubscriptionControl } from '../SubscriptionControl/SubscriptionControl';
import { SignInUpWrapper } from './SignInUpWrapper';

const AUTH_STATE = 'subscribing';

type SubscriptionSetupProps = {
	company: Company | null;
};

export const SubscriptionSetup = ({
	company,
}: SubscriptionSetupProps): JSX.Element => {
	const originURL = window.location.origin;

	return (
		<SignInUpWrapper
			title=""
			authState={AUTH_STATE}
			/* 
				They can't navigate back if they have already created their Company, as we don't support that yet 
				If we eventually allow this, we will need to tweak routing as back doesn't work anyway.
			*/
			back={{
				label: '',
				handle: emptyFunction,
			}}
			next={{ label: '', handle: emptyFunction }}>
			<SubscriptionControl
				returnUrl={originURL + '/dashboard'}
				company={company}
				initialSetup
			/>
		</SignInUpWrapper>
	);
};
