import {
	InvoicingIntegrationAtRest,
	InvoicingType,
} from './InvoicingIntegration';
import {
	PayrollTypes,
	PayrollIntegrationAtRest,
	PayrollType,
} from './PayrollIntegration';

export type IntegrationClass = 'Payroll' | 'Invoicing';

export type IntegrationType = PayrollType | InvoicingType;

const ManualIntegrationTypes = ['Smartly'] as const;
export type ManualIntegrationType = (typeof ManualIntegrationTypes)[number];

export const isManualIntegrationType = (
	payrollType: string,
): payrollType is ManualIntegrationType =>
	(ManualIntegrationTypes as readonly string[]).includes(payrollType);

export type IntegrationAtRest<T extends IntegrationType> = T extends PayrollType
	? PayrollIntegrationAtRest
	: InvoicingIntegrationAtRest;

export const integrationCollection = [
	'payrollIntegrations',
	'invoicingIntegrations',
] as const;

export type IntegrationCollection = (typeof integrationCollection)[number];

export const isPayrollIntegrationType = (
	integration: IntegrationAtRest<IntegrationType>,
): integration is PayrollIntegrationAtRest =>
	(PayrollTypes as readonly string[]).includes(integration.type);
