import { Grid, Typography } from '@mui/material';
import { InducteeOption } from '../../../../../constants/InductionEntry';
import {
	SafetyCourseStatus,
	SafetyCourseType,
} from '../../../../../constants/SafetyCourse';
import { SafetyCourseValidationInfo } from '../../../SafetyCourses/SafetyCourseStatusInfo/SafetyCourseStatusInfo';

export type NewInductionReviewProps = {
	inducteeOption: InducteeOption;
	inducteeName: string;
	site: string;
	company: string;
	mobileNumber?: string;
	safetyCourseType?: SafetyCourseType;
	safetyCourseStatus?: SafetyCourseStatus;
	safetyCourseID?: string;
	courseName?: string;
	courseExpiry?: Date | null;
};

export const NewInductionReview = ({
	inducteeOption,
	inducteeName,
	site,
	company,
	mobileNumber,
	safetyCourseType,
	safetyCourseStatus,
	safetyCourseID,
	courseName,
	courseExpiry,
}: NewInductionReviewProps): JSX.Element => {
	return (
		<Grid container>
			<Grid xs={6} item>
				<Typography variant="h5Bold">{inducteeOption}</Typography>
				<Typography variant="subtitle2" color="neutral.main">
					{inducteeName}
				</Typography>
				{mobileNumber !== '' && (
					<Typography variant="subtitle2" color="neutral.main">
						{mobileNumber}
					</Typography>
				)}
				<Typography variant="subtitle2" color="neutral.main">
					{company}
				</Typography>
				<Typography variant="subtitle2" color="neutral.main">
					{site}
				</Typography>
			</Grid>
			{safetyCourseStatus && safetyCourseID && courseName && (
				<>
					<Grid xs={6} item>
						<Typography variant="h5Bold">Safety Course</Typography>
						<Typography variant="subtitle2" color="neutral.main">
							{safetyCourseType}
						</Typography>
						<Typography variant="subtitle2" color="neutral.main">
							{safetyCourseID}
						</Typography>
					</Grid>
					<Grid
						xs={12}
						display="flex"
						alignItems="center"
						pt={2}
						item>
						<SafetyCourseValidationInfo
							status={safetyCourseStatus}
							safetyCourseID={safetyCourseID}
							courseName={courseName}
							courseExpiry={courseExpiry ?? null}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};
