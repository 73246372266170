import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AccountTypeHumanName, UserDetails } from '../../constants/Common';
import firebaseApi, { FirebaseApi } from '../../firebase/firebaseApi';
import {
	GetEmployeeAttendance,
	GetEmployeesSignIn,
	GetGreetings,
	GetSignIn,
	GetSites,
	GetSitesBySiteLog,
	GetTimesheets,
} from '../Dashboard/DashboardFunc';

type DashboardDisplayProps = {
	userDetails: UserDetails;
};

/** Return the dashboard components for each accounttype.
 *
 * siteIds only used by seniorManagement.**/
const GetContentData = (
	userDetails: UserDetails,
	siteIDs: string[],
	firebaseApi: Pick<
		FirebaseApi,
		| 'activeSitesCountByCompanySubscription'
		| 'submittedTimesheetsByStatusSiteContractedToSubscription'
		| 'submittedTimesheetsByStatusContractedToSubscription'
		| 'userByAccountType'
		| 'signInsByCompanyDaySubscription'
		| 'signInsByCompanyDayCountSubscription'
		| 'subscribeUsersByCompany'
		| 'signInsBySiteDayCountSubscription'
	>,
): JSX.Element => {
	switch (userDetails.accountType) {
		case 'management':
			return (
				<>
					<GetTimesheets
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
					<GetSignIn
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
				</>
			);
		case 'seniorManagement':
			return (
				<>
					<GetSites
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
					<GetTimesheets
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
					<GetSignIn
						userDetails={userDetails}
						siteIDs={siteIDs}
						firebaseApi={firebaseApi}
					/>
				</>
			);

		case 'handler':
			return (
				<>
					<GetEmployeesSignIn
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
					<GetEmployeeAttendance
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
					<GetSitesBySiteLog
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
				</>
			);
		case 'thirdPartyWorker':
		case 'worker':
			return (
				<>
					<Typography variant="h5">
						Sorry, your account type is not available via the web
						yet.
					</Typography>
					<Typography variant="h5">
						{`Account Type: ${
							AccountTypeHumanName[userDetails.accountType]
						}`}
					</Typography>
				</>
			);

		default:
			return <Typography>Information not found</Typography>;
	}
};

const DashboardDisplay = ({
	userDetails,
}: DashboardDisplayProps): JSX.Element => {
	const [siteIDs, setSiteIDs] = useState<string[]>([]);
	const mountedRef = useRef(true);

	useEffect(() => {
		mountedRef.current = true;
		const fetchSites = async (): Promise<void> => {
			const ids = await firebaseApi.getActiveSitesByCompany(
				userDetails.companyID,
			);

			if (mountedRef.current) {
				setSiteIDs(ids.docs.map((doc) => doc.data().id));
			}
		};
		fetchSites();
		return () => {
			mountedRef.current = false;
		};
	}, [userDetails.companyID]);

	return (
		<Box id="content" sx={{ padding: 5 }}>
			<GetGreetings userDetails={userDetails} />
			<Grid id="data" pt={5} display="flex" direction="column" container>
				{GetContentData(userDetails, siteIDs, firebaseApi)}
			</Grid>
		</Box>
	);
};

export default DashboardDisplay;
