import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
	Box,
	Breakpoint,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SetStateAction } from 'react';
import { PDFViewer } from '../../PDFViewer/PDFViewer';

export type CompliancePDFViewDialogProps = {
	PDFUrl: string;
	open: boolean;
	setOpen: (value: SetStateAction<boolean>) => void;
	onLoaded: () => void;
	loading: boolean;
	downloadPDF: () => void;
};

export const CompliancePDFViewDialog = ({
	PDFUrl,
	open,
	setOpen,
	loading,
	downloadPDF,
	onLoaded,
}: CompliancePDFViewDialogProps): JSX.Element => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const isMd = useMediaQuery(theme.breakpoints.down('md'));
	const isLg = useMediaQuery(theme.breakpoints.down('lg'));

	let maxWidth: Breakpoint = 'xl';
	let scale = 1.8;
	if (isSm) {
		maxWidth = 'sm';
		scale = 0.7;
	} else if (isMd) {
		maxWidth = 'md';
		scale = 0.9;
	} else if (isLg) {
		maxWidth = 'lg';
		scale = 1.3;
	}

	return (
		<Dialog
			open={open}
			maxWidth={maxWidth}
			fullWidth
			onClose={(): void => {
				setOpen(false);
				onLoaded();
			}}>
			<DialogTitle sx={{ py: 1, px: 2 }}>
				<Stack justifyContent="flex-end" direction="row">
					<IconButton
						aria-label="pdf-download"
						onClick={downloadPDF}
						color="primary">
						<PictureAsPdfIcon />
					</IconButton>
					<IconButton
						aria-label="close"
						onClick={(): void => {
							setOpen(false);
						}}>
						<CloseIcon />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Box display="flex" justifyContent="center">
					<PDFViewer
						PDFUrl={PDFUrl}
						loading={loading}
						onDocLoadSuccess={onLoaded}
						onDocLoadError={onLoaded}
						scale={scale}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
