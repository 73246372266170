import { Box, Tab, Tabs } from '@mui/material';
import { Outlet, useMatch, useSearchParams, Link } from 'react-router-dom';
import {
	AccountsAccountType,
	AccountsConfig,
	accountsAccountType,
} from '../../constants/Accounts';
import { UserProps, assertAccountType } from '../../constants/Common';

export const Accounts = ({ userDetails }: UserProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);
	const titles = AccountsConfig[accountType].titles;
	const pathMatch = useMatch('/accounts/:tab');
	const [searchParams] = useSearchParams();

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
