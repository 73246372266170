import { LoadingButton } from '@mui/lab';
import {
	Dialog,
	DialogContent,
	Stepper,
	Step,
	StepLabel,
	DialogTitle,
	DialogActions,
	Stack,
	Box,
	Portal,
	Typography,
	Button,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Guest,
	SimplifiedSite,
	Site,
	UserDetails,
	accountTypes,
} from '../../../../../constants/Common';
import {
	InducteeOption,
	InducteeType,
	InductionEntry,
	NewInductionValidationErrors,
	inducteeOption,
	inducteeType,
} from '../../../../../constants/InductionEntry';
import {
	SafetyCourse,
	SafetyCourseSelectOption,
	SafetyCourseType,
	SafetyCourseValidationResponse,
	safetyCourseStatus,
	safetyCourseTypeNames,
} from '../../../../../constants/SafetyCourse';
import { safetyCourseToResponse } from '../../../../../constants/SafetyCourseUtilities';
import { FirebaseApi } from '../../../../../firebase/firebaseApi';
import {
	sortBySubField,
	sortObjectByField,
} from '../../../../helpers/sortHelpers';
import { validatePhoneNumber } from '../../../../PhoneNumberInput/PhoneNumberInput';
import { CustomSnackBar } from '../../../../SnackBar/SnackBar';
import { ExistingGuestInduction } from '../NewInductionChooseInductee/ExistingGuestInduction';
import { NewGuestInduction } from '../NewInductionChooseInductee/NewGuestInduction';
import { NewInductionTypeSiteSelect } from '../NewInductionChooseInductee/NewInductionTypeSiteSelect';
import { NewUserInduction } from '../NewInductionChooseInductee/NewUserInduction';
import { NewInductionReview } from '../NewInductionReview/NewInductionReview';
import { NewInductionSafetyCourse } from '../NewInductionSafetyCourse/NewInductionSafetyCourse';

export type NewInductionDialogFirebaseCalls =
	| 'activeSitesByCompanySubscription'
	| 'subscribeUsersBySite'
	| 'subscribeUsersByContractedTo'
	| 'subscribeGuestsBySite'
	| 'getSafetyCoursesByWorker'
	| 'getSafetyCoursesByWorkerCourseType';

export type NewInductionDialogProps = {
	userDetails: UserDetails;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	modalOpen: boolean;
	exisitingInductions: InductionEntry[];
	saveGuestInduction: (
		guestName: string,
		company: string,
		siteID: string,
		siteName: string,
		mobileNumber: string,
		safetyCourseValidationResponse?: SafetyCourseValidationResponse,
	) => Promise<boolean>;
	saveExistingInducteeInduction: (
		workerType: InducteeType,
		workerID: string,
		siteID: string,
		safetyCourseValidationResponse?: SafetyCourseValidationResponse,
	) => Promise<boolean>;
	validateSafetyCourse: (
		safetyCourseID: string,
		safetyCourseType: SafetyCourseType,
	) => Promise<SafetyCourseValidationResponse | undefined>;
	findDuplicateSafetyCourse: (
		safetyCourseID: string,
		safetyCourseType: SafetyCourseType,
	) => Promise<SafetyCourse | undefined>;
	validateExistingSafetyCourse: (
		safetyCourse: SafetyCourse,
		inducteeID: string,
	) => Promise<SafetyCourse | undefined>;
	firebaseApi: Pick<FirebaseApi, NewInductionDialogFirebaseCalls>;
};

export const NewInductionDialog = ({
	userDetails,
	setModalOpen,
	modalOpen,
	exisitingInductions,
	validateSafetyCourse,
	validateExistingSafetyCourse,
	saveExistingInducteeInduction,
	saveGuestInduction,
	findDuplicateSafetyCourse,
	firebaseApi,
}: NewInductionDialogProps): JSX.Element => {
	const [step, setStep] = useState<0 | 1 | 2>(0);
	const [sites, setSiteList] = useState<Record<string, Site>>({});
	const [users, setUsers] = useState<Record<string, UserDetails>>({});
	const [existingSafetyCourses, setExistingSafetyCourses] = useState<
		Record<string, SafetyCourse>
	>({});
	const [guests, setGuests] = useState<Record<string, Guest>>({});
	const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
	const [creatingInduction, setCreatingInduction] = useState(false);
	const [validatingSafetyCourse, setValidatingSafetyCourse] = useState(false);

	// shared induction for user or guest state
	const [siteID, setSiteID] = useState(userDetails.siteID);
	const [selectedInducteeType, setSelectedInducteeType] =
		useState<InducteeOption>(inducteeOption.ExistingUser);
	const [validationErrors, setValidationErrors] =
		useState<NewInductionValidationErrors>({
			site: false,
			selectedUser: false,
			inducteeName: false,
			guestMobileNumber: false,
			guestCompany: false,
		});

	// user induction state
	const [selectedUser, setSelectedUser] = useState('');

	// guest induction state
	const [guestName, setGuestName] = useState('');
	const [guestMobileNumber, setGuestMobileNumber] = useState('+64');
	const [guestCompany, setGuestCompany] = useState('');

	// safety course validation
	const [safetyCourseOption, setSafetyCourseOption] =
		useState<SafetyCourseSelectOption>(SafetyCourseSelectOption.Existing);
	const [safetyCourseResponse, setSafetyCourseResponse] = useState<
		SafetyCourseValidationResponse | undefined | null
	>();
	const [duplicateSafetyCourse, setDuplicateSafetyCourse] = useState<
		SafetyCourse | undefined
	>();

	const allowUseExisting = !!users[selectedUser];
	const disableSiteSelect =
		userDetails.accountType === accountTypes.management;
	const steps = ['Choose Inductee', 'Select Safety Course', 'Review Details'];
	const recentSites: Record<string, SimplifiedSite> = userDetails.recentSites
		? Object.fromEntries(
				userDetails.recentSites
					.filter((site) => sites[site.id] !== undefined)
					.map((site) => [
						site.id,
						{
							id: site.id,
							name: site.name,
							company: site.companyName,
							companyID: site.companyID,
						},
					]),
		  )
		: {};

	const duplicateAlert = duplicateSafetyCourse
		? duplicateSafetyCourse.worker.id === selectedUser
			? // warning alert when employee's safety course type already exists
			  `This employee (${
					users[selectedUser]?.displayName
			  }) already has an existing ${
					safetyCourseTypeNames[duplicateSafetyCourse.course.type]
			  } safety course`
			: // warning alert when safety course belongs to another employee
			  `Another employee (${duplicateSafetyCourse.worker.name}) is already using this Safety Course ID. Please try again with ${users[selectedUser]?.displayName}'s ID.`
		: undefined;

	const errorSnackbarMessage = 'Failed to create induction.';

	const emptyValidationErrors = useMemo(
		() => ({
			site: false,
			selectedUser: false,
			inducteeName: false,
			guestMobileNumber: false,
			guestCompany: false,
			safetyCourse: false,
		}),
		[],
	);

	// disable next step button logic
	const disableNextForGuestInductee =
		siteID === '' ||
		guestName === '' ||
		guestMobileNumber === '' ||
		guestCompany === '' ||
		!validatePhoneNumber(guestMobileNumber);

	const disableNextForExistingInductee = siteID === '' || selectedUser === '';

	const chooseInducteeNextDisabled =
		selectedInducteeType === inducteeOption.NewGuest
			? disableNextForGuestInductee
			: disableNextForExistingInductee;

	const safetyCourseRequired =
		sites[siteID]?.safetyCourseRequiredForInduction ?? true;

	const disableSafetyCourseNext =
		(safetyCourseRequired &&
			(safetyCourseResponse === undefined ||
				safetyCourseResponse === null ||
				duplicateSafetyCourse !== undefined)) ||
		validatingSafetyCourse;

	const disableNextStep: Record<0 | 1 | 2, boolean> = {
		0: chooseInducteeNextDisabled,
		1: disableSafetyCourseNext,
		2: false,
	};

	useEffect(() => {
		const usersWithInductionForChosenSite = new Set(
			exisitingInductions
				.filter((induction) => induction.site.id === siteID)
				.map((induction) => induction.inductee.id),
		);

		const setUserData = (users: Record<string, UserDetails>): void => {
			const filteredUsers = Object.fromEntries(
				Object.values(users)
					.filter(
						(user) =>
							!usersWithInductionForChosenSite.has(user.userID),
					)
					.map((user) => [user.userID, user]),
			);

			const sortedUsersList = sortObjectByField(
				filteredUsers,
				'displayName',
			);
			setUsers(sortedUsersList);
		};

		return disableSiteSelect
			? firebaseApi.subscribeUsersBySite(userDetails.siteID, setUserData)
			: firebaseApi.subscribeUsersByContractedTo(
					userDetails.companyID,
					setUserData,
			  );
	}, [
		disableSiteSelect,
		exisitingInductions,
		firebaseApi,
		siteID,
		userDetails,
	]);

	useEffect(() => {
		const getSafetyCourses = async (): Promise<void> => {
			const safetyCourses = await firebaseApi.getSafetyCoursesByWorker(
				selectedUser,
			);
			const sortedSafetyCourses = sortBySubField(
				safetyCourses,
				'course',
				'name',
			);
			const safetyCoursesObject = Object.fromEntries(
				sortedSafetyCourses.map((course) => [course.id, course]),
			);
			setExistingSafetyCourses(safetyCoursesObject);
		};
		getSafetyCourses();
	}, [firebaseApi, selectedUser]);

	useEffect(() => {
		return firebaseApi.activeSitesByCompanySubscription(
			userDetails.companyID,
			(sites) => {
				const sortedSiteList = sortObjectByField(sites, 'name');
				setSiteList(sortedSiteList);
			},
		);
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		const guestsWithInductionForChosenSite = new Set(
			exisitingInductions
				.filter((induction) => induction.site.id === siteID)
				.map((induction) => induction.inductee.id),
		);

		const setGuestData = (guests: Record<string, Guest>): void => {
			const filteredGuests = Object.fromEntries(
				Object.values(guests)
					.filter(
						(guest) =>
							!guestsWithInductionForChosenSite.has(guest.id),
					)
					.map((guest) => [guest.id, guest]),
			);

			const sortedGuestsList = sortObjectByField(
				filteredGuests,
				'displayName',
			);
			setGuests(sortedGuestsList);
		};

		return firebaseApi.subscribeGuestsBySite(siteID, setGuestData);
	}, [
		exisitingInductions,
		siteID,
		userDetails.companyID,
		firebaseApi,
		selectedInducteeType,
	]);

	const handleSafetyCourseValidation = async (
		courseType: SafetyCourseType,
		courseID: string,
	): Promise<void> => {
		setValidatingSafetyCourse(true);

		// construct safe IDs are saved with the format XXX-XXXX-XXX
		// we need to manually format the ID to match the saved format for validation if the input mask is not used
		// this happens when you copy paste the ID into the input field before selecting the course type
		if (
			courseType === SafetyCourseType.ConstructSafe &&
			courseID.length === 10
		) {
			courseID = `${courseID.slice(0, 3)}-${courseID.slice(
				3,
				7,
			)}-${courseID.slice(7)}`;
		}

		let safetyCourse: SafetyCourse | undefined;
		const existingUserSafetyCourses =
			await firebaseApi.getSafetyCoursesByWorkerCourseType(
				selectedUser,
				courseType,
			);
		safetyCourse = existingUserSafetyCourses[0];

		// if we did not find a safety course the user already has we check if this one exists for another user
		if (!safetyCourse) {
			const duplicateSafetyCourse = await findDuplicateSafetyCourse(
				courseID,
				courseType,
			);
			safetyCourse = duplicateSafetyCourse;
		}

		if (!safetyCourse) {
			// create safety course if none found
			const safetyCourseResponse = await validateSafetyCourse(
				courseID,
				courseType,
			);
			const courseResponse =
				safetyCourseResponse !== undefined
					? safetyCourseResponse
					: null;

			setSafetyCourseResponse(courseResponse);
			// We reset duplicate safety course state as there cannot be a duplicate if no safety course was found
			setDuplicateSafetyCourse(undefined);
		} else {
			// verify existing safety course
			const validSafetyCourse = await validateExistingSafetyCourse(
				safetyCourse,
				selectedUser,
			);

			if (!validSafetyCourse) {
				setDuplicateSafetyCourse(safetyCourse);
			} else {
				setDuplicateSafetyCourse(undefined);
			}
			setSafetyCourseResponse(
				safetyCourseToResponse(safetyCourse.course),
			);
		}
		setValidatingSafetyCourse(false);
	};

	const handleChangeStep = (direction: 'next' | 'back'): void => {
		let newStep = step;
		switch (step) {
			case 0:
				if (!chooseInducteeNextDisabled && direction === 'next') {
					newStep = 1;
				} else if (direction === 'back') {
					handleClose();
				}
				break;
			case 1:
				newStep = direction === 'next' ? 2 : 0;
				if (direction === 'back') {
					setSafetyCourseResponse(undefined);
					setDuplicateSafetyCourse(undefined);
				}
				break;
			case 2:
				if (direction === 'back') newStep = 1;
				break;
		}

		setStep(newStep);
	};

	const handleFinish = async (): Promise<void> => {
		setCreatingInduction(true);
		let success = false;
		const responseForInduction = duplicateAlert
			? undefined
			: safetyCourseResponse ?? undefined;
		if (
			selectedInducteeType === inducteeOption.ExistingUser ||
			selectedInducteeType === inducteeOption.ExistingGuest
		) {
			const workerType =
				selectedInducteeType === inducteeOption.ExistingUser
					? inducteeType.Employee
					: inducteeType.Guest;

			success = await saveExistingInducteeInduction(
				workerType,
				selectedUser,
				siteID,
				responseForInduction,
			);
		} else if (selectedInducteeType === inducteeOption.NewGuest) {
			success = await saveGuestInduction(
				guestName,
				guestCompany,
				siteID,
				sites[siteID]?.name,
				guestMobileNumber,
				responseForInduction,
			);
		}

		setCreatingInduction(false);
		if (success) {
			handleClose();
		} else {
			setErrorSnackbarOpen(true);
		}
	};

	const handleClose = (): void => {
		setModalOpen(false);
		setStep(0);
		setValidationErrors(emptyValidationErrors);
		setSelectedInducteeType(inducteeOption.ExistingUser);
		setSiteID(userDetails.siteID);
		setSelectedUser('');
		setGuestName('');
		setGuestCompany('');
		setGuestMobileNumber('+64');
		setSafetyCourseResponse(undefined);
		setDuplicateSafetyCourse(undefined);
	};

	const clearUser = (): void => {
		setSelectedUser('');
	};

	const clearGuest = (): void => {
		setGuestName('');
		setGuestCompany('');
		setGuestMobileNumber('+64');
	};

	const clearErrors = (): void => {
		setValidationErrors(emptyValidationErrors);
	};

	const handleInducteeOptionChange = (option: InducteeOption): void => {
		if (option !== selectedInducteeType) {
			clearUser();
			clearGuest();
			clearErrors();
			setSelectedInducteeType(option);
		}
	};

	const handleSiteChange = (newSiteID: string): void => {
		if (newSiteID !== siteID) {
			clearUser();
			clearErrors();
			setSiteID(newSiteID);
		}
	};

	const handleSafetyCourseOptionChange = (
		option: SafetyCourseSelectOption,
	): void => {
		setSafetyCourseOption(option);
		setSafetyCourseResponse(undefined);
		setDuplicateSafetyCourse(undefined);
	};

	const renderStep = (step: 0 | 1 | 2): JSX.Element => {
		const inducteeSelect: Record<InducteeOption, JSX.Element> = {
			[inducteeOption.ExistingUser]: (
				<NewUserInduction
					users={users}
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
					userError={validationErrors.selectedUser}
					setValidationErrors={setValidationErrors}
					disableUserSelect={siteID === ''}
				/>
			),
			[inducteeOption.ExistingGuest]: (
				<ExistingGuestInduction
					guests={guests}
					selectedGuest={selectedUser}
					setSelectedGuest={setSelectedUser}
					guestError={validationErrors.selectedUser}
					setValidationErrors={setValidationErrors}
					disableGuestSelect={siteID === ''}
				/>
			),
			[inducteeOption.NewGuest]: (
				<NewGuestInduction
					inducteeName={guestName}
					setInducteeName={setGuestName}
					inducteeNameError={validationErrors.inducteeName}
					guestMobileNumber={guestMobileNumber}
					setGuestMobileNumber={setGuestMobileNumber}
					guestMobileNumberError={validationErrors.guestMobileNumber}
					guestCompany={guestCompany}
					setGuestCompany={setGuestCompany}
					guestCompanyError={validationErrors.guestCompany}
					setValidationErrors={setValidationErrors}
				/>
			),
		};
		switch (step) {
			case 0:
				return (
					<Stack spacing={1}>
						<NewInductionTypeSiteSelect
							sites={sites}
							recentSites={recentSites}
							existingUserOrGuest={selectedInducteeType}
							setInducteeOption={handleInducteeOptionChange}
							site={siteID}
							setSite={handleSiteChange}
							siteError={validationErrors.site}
							setValidationErrors={setValidationErrors}
							disableSiteSelect={disableSiteSelect}
						/>
						{inducteeSelect[selectedInducteeType]}
					</Stack>
				);
			case 1:
				return (
					<NewInductionSafetyCourse
						safetyCourseOption={
							!allowUseExisting
								? SafetyCourseSelectOption.New
								: safetyCourseOption
						}
						handleSafetyCourseOptionChange={
							handleSafetyCourseOptionChange
						}
						existingSafetyCourses={existingSafetyCourses}
						handleSafetyCourseValidation={
							handleSafetyCourseValidation
						}
						allowUseExisting={allowUseExisting}
						safetyCourseResponse={safetyCourseResponse}
						handleSafetyCourseResponseChange={(newResponse): void =>
							setSafetyCourseResponse(newResponse)
						}
						safetyCourseDuplicateAlert={duplicateAlert}
					/>
				);
			case 2: {
				const newInductee = {
					[inducteeOption.ExistingUser]: {
						name: users[selectedUser]?.displayName,
						company: users[selectedUser]?.company,
						site: sites[siteID]?.name,
						mobileNumber: '',
					},
					[inducteeOption.ExistingGuest]: {
						name: guests[selectedUser]?.displayName,
						company: guests[selectedUser]?.company,
						site: sites[siteID]?.name,
						mobileNumber: guests[selectedUser]?.mobileNumber,
					},
					[inducteeOption.NewGuest]: {
						name: guestName,
						company: guestCompany,
						site: sites[siteID]?.name,
						mobileNumber: guestMobileNumber,
					},
				};
				// use the safety course if it is not a duplicate
				const safetyCourse = !duplicateAlert
					? {
							type: safetyCourseResponse?.type,
							id: safetyCourseResponse?.id,
							name: safetyCourseResponse?.name,
							expiryDate: safetyCourseResponse?.expiryDate,
					  }
					: undefined;
				return (
					<NewInductionReview
						inducteeOption={selectedInducteeType}
						inducteeName={newInductee[selectedInducteeType].name}
						site={newInductee[selectedInducteeType].site}
						company={newInductee[selectedInducteeType].company}
						mobileNumber={
							newInductee[selectedInducteeType].mobileNumber
						}
						safetyCourseType={safetyCourse?.type}
						safetyCourseStatus={safetyCourseStatus.Valid}
						safetyCourseID={safetyCourse?.id}
						courseName={safetyCourse?.name}
						courseExpiry={safetyCourse?.expiryDate}
					/>
				);
			}
		}
	};

	return (
		<>
			<Portal>
				<CustomSnackBar
					open={errorSnackbarOpen}
					autoHideDuration={6000}
					onClose={(): void => {
						setErrorSnackbarOpen(false);
					}}
					snackBarText={errorSnackbarMessage}
					severity="error"
				/>
			</Portal>
			<Dialog
				open={modalOpen}
				maxWidth="sm"
				fullWidth
				onClose={handleClose}>
				<DialogTitle>New Induction</DialogTitle>
				<DialogContent>
					<Box pb={2}>
						<Stepper activeStep={step} alternativeLabel>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel
										optional={
											!sites[siteID]
												?.safetyCourseRequiredForInduction &&
											label === 'Select Safety Course' ? (
												<Typography fontSize="small">
													(Optional)
												</Typography>
											) : undefined
										}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					{renderStep(step)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						onClick={(): void => handleChangeStep('back')}
						disabled={creatingInduction}
						sx={{ mr: 1 }}>
						Back
					</Button>
					<LoadingButton
						onClick={
							step === 2
								? handleFinish
								: (): void => handleChangeStep('next')
						}
						disabled={disableNextStep[step]}
						variant="contained"
						loading={creatingInduction}>
						{step === 2 ? 'Confirm' : 'Next'}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};
