import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Fade,
	Grid,
	Paper,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { ResidencyHumanName, UserProfile } from '../../constants/Common';
import { formatSpacedDate } from '../helpers/dateFormatters';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type HandlerEmployeeSummaryCardProps = {
	employee: UserProfile;
	setEmployeeDialogOpen: (open: boolean) => void;
	setCurrentEmployee: (userProfile: UserProfile) => void;
	selected?: boolean;
	elevation?: 0 | 1 | 2 | 3;
};

export const HandlerEmployeeSummaryCard = ({
	employee,
	selected,
	setEmployeeDialogOpen,
	setCurrentEmployee,
	elevation,
}: HandlerEmployeeSummaryCardProps): JSX.Element => {
	const [imageLoading, setImageLoading] = useState(true);

	const handleEmployeeClick = (): void => {
		setEmployeeDialogOpen(true);
		setCurrentEmployee(employee);
	};
	return (
		<Card elevation={elevation} sx={{ position: 'relative' }}>
			<CardActionArea
				sx={
					selected
						? {
								'&::before': {
									content: "''",
									display: 'block',
									position: 'absolute',
									top: 0,
									bottom: 0,
									left: 0,
									right: 0,
									backgroundColor: 'success.main',
									opacity: '0.5',
									transition: 'all 1s linear',
								},
						  }
						: {}
				}
				onClick={handleEmployeeClick}>
				{imageLoading && (
					<Paper
						variant="outlined"
						sx={{
							alignSelf: 'center',
							padding: 1,
							display: imageLoading ? 'block' : 'none',
						}}>
						<LoadingDots
							style={{
								width: '100%',
								height: 'auto',
							}}
						/>
					</Paper>
				)}
				<Fade in={selected}>
					<CheckCircleOutlineIcon
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							margin: 0,
						}}
					/>
				</Fade>
				<CardMedia
					component="img"
					src={employee.profileImg}
					sx={{
						width: '100%',
						height: '311px',
						display: !imageLoading ? 'block' : 'none',
					}}
					onLoad={(): void => setImageLoading(false)}
				/>

				<CardContent>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography
								variant="body1"
								component="div"
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis">
								{employee.displayName}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="body2">
								Location: {employee.location ?? 'Unknown'}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="body2">
								{`D.o.B: 
								${
									employee.dateOfBirth
										? formatSpacedDate(
												employee.dateOfBirth.toDate(),
										  )
										: 'Unknown'
								}`}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="body2">
								Residency:{' '}
								{employee.residency
									? ResidencyHumanName[employee.residency]
									: 'Unknown'}
							</Typography>
						</Grid>

						<Grid item xs={12} display="flex" alignContent="center">
							<Typography variant="body2">Transport:</Typography>
							{employee.transport ? (
								<CheckCircleIcon
									color="success"
									fontSize="small"
								/>
							) : (
								<CancelIcon color="error" fontSize="small" />
							)}
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
