import { useEffect, useState } from 'react';
import cloudFunctionApi from '../../../../cloudfunctions';
import { accountTypes, Company, Site } from '../../../../constants/Common';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../../providers/UserProvider';
import { LoadingDots } from '../../subcomponents/LoadingDots';
import { NoSiteWarning } from '../NoSiteWarning/NoSiteWarning';
import { EditSiteDetails } from './EditSiteDetails';

export const EditSiteDetailsWrapper = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const user = useUserAuthContext();

	const [site, setSite] = useState<Site | null>();
	const [company, setCompany] = useState<Company>();

	const allowArchiveSite = userDetails?.accountType === accountTypes.handler;
	const allowEditSite =
		userDetails?.accountType === accountTypes.seniorManagement ||
		userDetails?.accountType === accountTypes.management ||
		(userDetails?.accountType === accountTypes.handler &&
			!!company?.canCreateSites);

	useEffect(() => {
		if (!userDetails?.siteID || userDetails?.siteID === '') {
			setSite(null);
			return;
		}
		return firebaseApi.siteSubscription(userDetails.siteID, (site) => {
			setSite(site);
		});
	}, [userDetails?.siteID]);

	useEffect(() => {
		const getCompany = async (): Promise<void> => {
			if (userDetails?.companyID && userDetails?.companyID !== '') {
				const company = await firebaseApi.getCompany(
					userDetails.companyID,
				);
				setCompany(company);
			}
		};
		getCompany();
	}, [userDetails?.companyID]);

	return user === null || userDetails === null || site === undefined ? (
		<LoadingDots />
	) : site === null ? (
		<NoSiteWarning />
	) : (
		<EditSiteDetails
			allowArchiveSite={allowArchiveSite}
			allowEditSite={allowEditSite}
			user={user}
			site={site}
			cloudFunctionApi={cloudFunctionApi}
		/>
	);
};
