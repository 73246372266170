import { SiteLog } from '../../../constants/Common';
import { formatHoursMinutes } from '../../helpers/dateFormatters';
import { ShortDay, shortDays } from '../../helpers/dateUtilities';

export type DaySiteLogEntry = Partial<Record<ShortDay, TypeSiteLogEntry>>;
export type TypeSiteLogEntry = Record<SiteLog['type'], SiteLog[]>;

const buildLogsEntry = (day: ShortDay, logs: DaySiteLogEntry): string => {
	const dayLogs = logs[day];
	if (dayLogs === undefined) {
		return '';
	} else {
		return dayLogs.In.reduce((totalEntry, inLog, index) => {
			const outLog = dayLogs.Out[index];
			const outLogValue =
				outLog !== undefined
					? ` ${formatHoursMinutes(outLog.datetime.toDate())}`
					: '';
			const row =
				formatHoursMinutes(inLog.datetime.toDate()) + outLogValue;
			return totalEntry + row + '\r\n';
		}, '');
	}
};

type RowData = [
	{
		name: string;
		contactNumber: string;
	},
	string,
	string,
	string,
	DaySiteLogEntry,
	DaySiteLogEntry,
	DaySiteLogEntry,
	DaySiteLogEntry,
	DaySiteLogEntry,
	DaySiteLogEntry,
	DaySiteLogEntry,
];

/**
 * Because of how we build the table body renders this is an absolute bastard,
 * add a new column and this will break
 * @param data The data row
 * @returns modified csv row data as strings for csv export
 */
export const siteLogReportCSVBody = (
	data: {
		index: number;
		data: RowData;
	}[],
): {
	index: number;
	data: string[];
}[] => {
	return data.map((row) => {
		const [col1, col2, col3, col4, ...logs] = row.data;

		return {
			index: row.index,
			data: [
				`${col1.name} ${col1.contactNumber}`,
				col2,
				col3,
				col4,
				...shortDays.map((day) => buildLogsEntry(day, logs[0])),
			],
		};
	});
};

export const siteLogCustomSearch = (
	searchString: string,
	data: RowData,
): boolean => {
	const [col1, col2, col3, col4, ...logs] = data;
	const simpleRow = [
		`${col1.name} ${col1.contactNumber}`,
		col2,
		col3,
		col4,
		...shortDays.map((day) => buildLogsEntry(day, logs[0])),
	];
	return simpleRow.some((value) => value.includes(searchString));
};
