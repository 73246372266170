import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

type NewTemplateFieldsProps = {
	addField: () => void;
	loading: boolean;
	templateFields: string[];
	removeField: (index: number) => void;
	handleUpdateField: (value: string, index: number) => void;
	errors: Record<number, string>;
};

export const NewTemplateFields = ({
	addField,
	loading,
	templateFields,
	removeField,
	handleUpdateField,
	errors,
}: NewTemplateFieldsProps): JSX.Element => {
	const fieldRow = (value: string, index: number): JSX.Element => (
		<ListItem
			key={`field-${index}`}
			dense
			secondaryAction={
				!loading && (
					<Tooltip title="Delete Field">
						<IconButton
							data-testid={`template-field-${index}-delete`}
							edge="end"
							onClick={(): void => removeField(index)}>
							<DeleteOutlinedIcon
								fontSize="small"
								color="primary"
							/>
						</IconButton>
					</Tooltip>
				)
			}
			sx={{
				justifyContent: 'space-between',
				py: 0.5,
				pl: 0,
				pr: 5,
			}}>
			<TextField
				data-testid={`template-field-${index}`}
				fullWidth
				size="small"
				value={value}
				onChange={(event): void =>
					handleUpdateField(event.target.value, index)
				}
				error={!!errors[index]}
				helperText={errors[index]}
				disabled={loading}
			/>
		</ListItem>
	);

	return (
		<Card elevation={0}>
			<CardHeader
				titleTypographyProps={{
					variant: 'body1',
				}}
				sx={{ px: 0, pb: 0, pr: 5 }}
				title="Custom Fields"
			/>
			<CardContent sx={{ px: 0, py: '0 !important' }}>
				<List>
					{templateFields.map((value, index) => {
						return fieldRow(value, index);
					})}
					{templateFields.length === 0 && (
						<ListItem sx={{ justifyContent: 'center', pr: 5 }}>
							<Typography variant="subtitle1">
								No Template Fields
							</Typography>
						</ListItem>
					)}
					<ListItem sx={{ justifyContent: 'center', pr: 5 }}>
						<Button
							onClick={addField}
							disabled={loading}
							variant="outlined">
							Add Field
						</Button>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};
