import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export type PDFViewerProps = {
	PDFUrl: string;
	pageNumber?: number;
	onDocLoadSuccess?: () => void;
	onDocLoadError?: () => void;
	loading: boolean;
	scale?: number;
};

export const PDFViewer = ({
	PDFUrl,
	onDocLoadSuccess,
	onDocLoadError,
	loading,
	scale = 1,
}: PDFViewerProps): JSX.Element => {
	const [numPages, setNumPages] = useState<number>(1);

	const handleDocumentLoadSuccess = ({
		numPages,
	}: DocumentCallback): void => {
		setNumPages(numPages);
		if (onDocLoadSuccess) {
			onDocLoadSuccess();
		}
	};

	return (
		<Document
			file={PDFUrl}
			onLoadSuccess={handleDocumentLoadSuccess}
			onLoadError={onDocLoadError}
			noData={loading ? <LoadingDots /> : 'No PDF data found.'}
			loading={<LoadingDots />}>
			{Array.from(new Array(numPages), (_, index) => (
				<Page
					key={`page-${index + 1}`}
					pageNumber={index + 1}
					scale={scale}
				/>
			))}
		</Document>
	);
};
