import { SiteIntegrationTableRow, isSiteLink } from '../Models/sitesTab';

export const siteIntegrationTableRowSearch = (
	searchQuery: string,
	currentRow: (string | SiteIntegrationTableRow['job'])[],
): boolean => {
	const lowerCaseQuery = searchQuery.toLowerCase();
	return currentRow.some((cell): boolean => {
		if (typeof cell === 'string') {
			return cell.toLowerCase().includes(lowerCaseQuery);
		} else if (isSiteLink(cell)) {
			return (
				cell.integrationName.toLowerCase().includes(lowerCaseQuery) ||
				cell.integrationID.toLowerCase().includes(lowerCaseQuery)
			);
		}
		return false;
	});
};
