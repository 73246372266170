export const siteOption = { id: 'All', label: 'All Sites' } as const;

export const activityOptions = {
	All: { id: 'All', label: 'All Activities' },
	Unsent: { id: 'Unsent', label: 'Unsent Activities' },
} as const;
export type ActivityOption = keyof typeof activityOptions;

export const isActivityOption = (value: string): value is ActivityOption =>
	Object.keys(activityOptions).includes(value);
