import {
	Autocomplete,
	Box,
	Button,
	Divider,
	Grid,
	ListItem,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
	AccountTypeHumanName,
	UserDetails,
} from '../../../../constants/Common';
import firebaseApi from '../../../../firebase/firebaseApi';
import { IntegrationType } from '../../../../models/Integrations/Integration';
import {
	BaseIntegrationLink,
	IntegrationEmployee,
} from '../../../../models/Integrations/IntegrationElements';
import { PayrollType } from '../../../../models/Integrations/PayrollIntegration';
import { ImportCSVButton } from '../../../ImportCSVButton/ImportCSVButton';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import SearchInput from '../../../Search/Search';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { RefreshPayrollListBtn } from '../../IntegrationUIComponents/RefreshPayrollListBtn';
import { ConfirmationDialog } from './ConfirmationDialog';

export const header = (
	fetchAllPayrollEmployees: (abortSignal: AbortSignal) => Promise<void>,
	editable = true,
): JSX.Element => {
	return (
		<Grid
			container
			id="header"
			alignItems="center"
			textAlign="center"
			justifyContent="space-around"
			mb={2}
			width="100%">
			<Grid item xs={2}>
				<Typography>Name</Typography>
			</Grid>
			<Divider orientation="vertical" />
			<Grid item xs={2}>
				<Typography>Account Type</Typography>
			</Grid>
			<Divider orientation="vertical" />
			<Grid item xs={3}>
				<Typography justifyContent="space-between">
					Integration Account
					{editable && (
						<RefreshPayrollListBtn
							fetchList={fetchAllPayrollEmployees}
						/>
					)}
				</Typography>
			</Grid>
		</Grid>
	);
};

type UnlinkedUsersProps<T extends IntegrationType> = {
	usersList: Record<string, UserDetails>;
	dropdownEmployees: IntegrationEmployee[];
	unlinkedUsersList: UserDetails[];
	loading: boolean;
	integrationCompanyID: string;
	integrationAccounts: IntegrationEmployee[];
	fetchAllIntegrationEmployees: (abortSignal: AbortSignal) => Promise<void>;
	integrationCollection: T extends PayrollType
		? 'payrollIntegrations'
		: 'invoicingIntegrations';
	importHelperText?: JSX.Element;
	handleCSVImport: (file: File | null) => Promise<void>;
	importLoading: boolean;
};

export const UnlinkedUsers = <T extends IntegrationType>({
	integrationCompanyID,
	usersList,
	dropdownEmployees,
	loading,
	unlinkedUsersList,
	integrationAccounts,
	fetchAllIntegrationEmployees,
	integrationCollection,
	handleCSVImport,
	importHelperText,
	importLoading,
}: UnlinkedUsersProps<T>): JSX.Element => {
	const [successSnackBarOpen, setSuccessSnackBarOpen] =
		useState<boolean>(false);
	const [searchContentData, setSearchContentData] = useState<UserDetails[]>(
		[],
	);
	const [connectionsToSubmit, setConnectionsToSubmit] =
		useState<Record<string, BaseIntegrationLink>>();
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [updatedDropdownOptions, setUpdatedDropdownOptions] =
		useState<IntegrationEmployee[]>(dropdownEmployees);

	useEffect(() => {
		if (connectionsToSubmit) {
			const updatedDropdown = dropdownEmployees.filter((employee) =>
				Object.values(connectionsToSubmit).every(
					(acc) => acc.integrationID !== employee.id,
				),
			);
			setUpdatedDropdownOptions(updatedDropdown);
		}
	}, [connectionsToSubmit, dropdownEmployees]);

	useEffect(() => {
		setSearchContentData(unlinkedUsersList);
	}, [unlinkedUsersList]);

	const saveAccountInformation = (): void => {
		if (connectionsToSubmit) {
			Object.values(connectionsToSubmit).forEach(async (connection) => {
				const newConnection: BaseIntegrationLink = {
					id: connection.id,
					name: connection.name,
					integrationID: connection.integrationID,
					integrationName: connection.integrationName,
				};
				await firebaseApi.createIntegrationEmployeeLink(
					integrationCollection,
					integrationCompanyID,
					newConnection,
				);
				setSuccessSnackBarOpen(true);
				setShowConfirmationDialog(false);
				setConnectionsToSubmit(undefined);
			});
		}
	};

	const successSnackBar = (): JSX.Element => (
		<CustomSnackBar
			open={successSnackBarOpen}
			onClose={handleSnackBarClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			snackBarText="Integration Account Linked"
		/>
	);

	const handleSnackBarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessSnackBarOpen(false);
	};

	const handleSelectAccount = (
		integrationAccount: IntegrationEmployee | null,
		user: UserDetails,
	): void => {
		const updatedConnections = { ...connectionsToSubmit };
		if (integrationAccount) {
			updatedConnections[user.userID] = {
				id: user.userID,
				name: user.displayName,
				integrationID: integrationAccount.id,
				integrationName: integrationAccount.name,
			};
		} else {
			delete updatedConnections[user.userID];
		}
		if (Object.keys(updatedConnections).length === 0) {
			setConnectionsToSubmit(undefined);
		} else {
			setConnectionsToSubmit(updatedConnections);
		}
	};

	const handleDropdown = (userID: string): IntegrationEmployee[] => {
		const updatedDropdown = clone(updatedDropdownOptions);
		if (
			connectionsToSubmit &&
			integrationAccounts &&
			connectionsToSubmit[userID]
		) {
			const currentOption = integrationAccounts.find(
				(employee) =>
					employee.id === connectionsToSubmit[userID].integrationID,
			);
			if (currentOption) {
				updatedDropdown.push(currentOption);
			}
		}
		return Object.values(updatedDropdown);
	};

	return (
		<>
			{successSnackBar()}
			<ConfirmationDialog
				usersList={usersList}
				integrationAccounts={integrationAccounts}
				setShowConfirmationDialog={setShowConfirmationDialog}
				showConfirmationDialog={showConfirmationDialog}
				handleChanges={saveAccountInformation}
				connectionsToSubmit={connectionsToSubmit}
			/>
			<Paper id="boxList" elevation={1}>
				<Stack spacing={3} p={2}>
					<Stack direction="row" justifyContent="space-between" p={2}>
						<SearchInput
							onChange={(data): void =>
								setSearchContentData(data)
							}
							field="displayName"
							data={unlinkedUsersList}
							label="Search"
						/>
						<Box display="flex" flexDirection="row">
							{importHelperText && (
								<ImportCSVButton
									title="employees"
									callback={handleCSVImport}
									isLoading={importLoading}
								/>
							)}
							<Button
								disabled={connectionsToSubmit === undefined}
								variant="contained"
								onClick={(): void => {
									setShowConfirmationDialog(true);
								}}
								sx={{ mx: 2 }}>
								Link Users
							</Button>
						</Box>
					</Stack>
					{header(fetchAllIntegrationEmployees)}
					<Divider orientation="horizontal" />
					{loading ? (
						<LoadingDots />
					) : (
						<>
							{searchContentData.length > 0 &&
							unlinkedUsersList ? (
								searchContentData.map((user) => (
									<Grid
										key={user.userID}
										container
										id="list"
										alignItems="center"
										justifyContent="space-around"
										textAlign="center"
										width="100%">
										<Grid item xs={2}>
											<Typography>
												{user.displayName}
											</Typography>
										</Grid>
										<Divider
											orientation="vertical"
											flexItem
										/>

										<Grid item xs={2}>
											<Typography>
												{
													AccountTypeHumanName[
														user.accountType
													]
												}
											</Typography>
										</Grid>
										<Divider
											orientation="vertical"
											flexItem
										/>
										<Grid item xs={3}>
											<Autocomplete
												fullWidth
												options={
													connectionsToSubmit
														? handleDropdown(
																user.userID,
														  )
														: dropdownEmployees
												}
												getOptionLabel={(
													option,
												): string => option.name}
												isOptionEqualToValue={(
													option,
													value,
												): boolean => {
													return (
														value.name ===
														option.name
													);
												}}
												renderInput={(
													params,
												): JSX.Element => (
													<TextField
														{...params}
														label="Integration Account"
													/>
												)}
												renderOption={(
													props,
													option,
												): JSX.Element => (
													<ListItem
														{...props}
														key={option.id}>
														{option.name}
													</ListItem>
												)}
												onChange={(_, value): void => {
													handleSelectAccount(
														value,
														user,
													);
												}}
												filterSelectedOptions
											/>
										</Grid>
									</Grid>
								))
							) : importHelperText ? (
								importHelperText
							) : (
								<Typography textAlign="center">
									There are no accounts to link
								</Typography>
							)}
						</>
					)}
				</Stack>
			</Paper>
		</>
	);
};
