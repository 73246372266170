import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Activity, SiteLog } from '../../../../constants/Common';
import { NotesByDay } from '../../../../constants/Note';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { TimesheetHeader } from './TimesheetHeader';
import { TimesheetTable } from './TimesheetTable';

export type TimesheetDetailsFirebaseCalls =
	| 'getCompany'
	| 'getCompanyNoLogoUrl'
	| 'getDownloadUrl';

export type TimesheetDetailsProps = {
	timesheet: Timesheet;
	activities: Activity[];
	notes: NotesByDay;
	siteLogs: SiteLog[];
	defaultSiteLogURL: string | null;
	loading: boolean;
	firebaseApi: Pick<FirebaseApi, TimesheetDetailsFirebaseCalls>;
};

export const TimesheetDetails = ({
	timesheet,
	activities,
	notes,
	siteLogs,
	defaultSiteLogURL,
	loading,
	firebaseApi,
}: TimesheetDetailsProps): JSX.Element => {
	const companyID = timesheet.contractedTo?.id ?? '';
	const [companyLogos, setCompanyLogos] = useState<Record<string, string>>(
		{},
	);
	const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(true);

	const fetchCompanyLogo = useCallback(
		async (id: string, companyLogos: Record<string, string>) => {
			let companyLogoURL: string;
			if (id === '') {
				companyLogoURL = await firebaseApi.getCompanyNoLogoUrl();
			} else {
				const company = await firebaseApi.getCompany(id);
				if (company === undefined || !company.logoLocation) {
					companyLogoURL = await firebaseApi.getCompanyNoLogoUrl();
				} else {
					companyLogoURL = await firebaseApi.getDownloadUrl(
						company.logoLocation,
					);
				}
			}
			setCompanyLogos({ ...companyLogos, [id]: companyLogoURL });
			setCompanyLogoLoading(false);
		},
		[firebaseApi],
	);

	useEffect(() => {
		setCompanyLogoLoading(true);
		if (companyLogos[companyID] === undefined) {
			fetchCompanyLogo(companyID, companyLogos);
		} else {
			setCompanyLogoLoading(false);
		}
	}, [fetchCompanyLogo, companyID, companyLogos]);

	return (
		<Box
			sx={{
				height: 'calc(100vh - 250px)',
				overflow: 'auto',
			}}>
			<TimesheetHeader
				key={companyID}
				companyLogo={companyLogos[companyID]}
				companyLogoLoading={companyLogoLoading}
				timesheetHeaderFields={{
					employeeName: timesheet.employee.name,
					employerName: timesheet.employer.name,
					contractedToCompany: timesheet.contractedTo?.name ?? null,
					reviewer: timesheet.reviewer,
					reviewedAt: timesheet.reviewedAt,
					status: timesheet.timesheetStatus,
					siteName: timesheet.site.name,
					week: timesheet.weekEnding,
				}}
			/>
			<TimesheetTable
				activities={activities}
				siteLogs={siteLogs}
				breaks={timesheet.hours}
				defaultSiteLogURL={defaultSiteLogURL ?? ''}
				notes={notes}
				loading={loading}
			/>
		</Box>
	);
};
