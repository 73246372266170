import { Box, Paper, SxProps } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

export type ImageProps = {
	src: string;
	alt: string;
	style?: SxProps;
	imageStyle?: CSSProperties;
};

const Image = (props: ImageProps): JSX.Element => {
	const [loading, setLoading] = useState(true);

	const imageLoaded = (): void => {
		setLoading(false);
	};

	return (
		<Paper
			variant="outlined"
			sx={{
				background: 'none',
				border: 'none',
				alignSelf: 'center',
				...props.style,
			}}>
			<LoadingDots
				style={{
					width: '100%',
					height: 'auto',
					...props.imageStyle,
					display: loading ? 'flex' : 'none',
				}}
			/>
			<Box
				component="img"
				sx={{
					width: '100%',
					height: 'auto',
					borderRadius: '4px',
					...props.imageStyle,
					display: loading ? 'none' : 'block',
				}}
				alt={props.alt}
				src={props.src}
				onLoad={imageLoaded}
			/>
		</Paper>
	);
};

export default Image;
