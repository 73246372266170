import { createContext } from 'react';

export type FeatureFlag = {
	name: string;
	enabled: boolean;
	optedInCompanies: string[];
	optedInUsers: string[];
};

export type FeatureFlags = {
	get: (flagName: string) => boolean;
	loaded: boolean;
	getAll: () => Readonly<FeatureFlag>[];
};

const defaultFlags: FeatureFlags = {
	get: () => false,
	loaded: false,
	getAll: () => [],
};

export const FeatureFlagsContext = createContext<FeatureFlags>(defaultFlags);
