import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useState } from 'react';
import { UserProps } from '../../constants/Common';
import { RecaptchaVerifier } from '../../firebase/firebase';
import firebaseApi from '../../firebase/firebaseApi';
import { AccountDetails } from './AccountDetails';
import { MenuPreferences } from './MenuPreferences';

export const AccountSettings = ({
	userDetails,
	user,
}: Required<UserProps>): JSX.Element => {
	const titles = ['Account Details', 'Menu Preferences'] as const;

	const [tab, setTab] = useState<string>(titles[0]);

	const handleTabChange = (_: React.SyntheticEvent, value: string): void => {
		setTab(value);
	};

	return (
		<TabContext value={tab}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<TabList
					onChange={handleTabChange}
					variant="scrollable"
					scrollButtons="auto">
					{titles.map((title, index) => (
						<Tab label={title} value={title} key={index} />
					))}
				</TabList>
			</Box>
			<TabPanel value={titles[0]} sx={{ paddingX: 0 }} key="0">
				<Box flex={1}>
					<AccountDetails
						userDetails={userDetails}
						RecaptchaVerifierConstructor={RecaptchaVerifier}
						user={user}
						firebaseApi={firebaseApi}
					/>
				</Box>
			</TabPanel>
			<TabPanel value={titles[1]} sx={{ paddingX: 0 }} key="1">
				<Box flex={1}>
					<MenuPreferences
						userDetails={userDetails}
						firebaseApi={firebaseApi}
					/>
				</Box>
			</TabPanel>
		</TabContext>
	);
};
