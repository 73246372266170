import { isEqual } from 'lodash';
import { SetStateAction } from 'react';
import { ValueOf } from '../../../constants/TypescriptUtilities';
import { ReportsActions, ReportsContext } from './models';

export const createReportsReducer = (
	state: ReportsContext,
	action: ReportsActions,
): ReportsContext => {
	const setter = <T extends ValueOf<ReportsContext>>(
		value: SetStateAction<T>,
		prev: T,
	): T => (typeof value === 'function' ? value(prev) : value);

	if (isEqual(action.value, state[action.key])) return state;

	switch (action.key) {
		case 'startDate': {
			const startDate = setter(action.value, state[action.key]);
			return {
				...state,
				[action.key]: startDate,
			};
		}
		case 'endDate': {
			const endDate = setter(action.value, state[action.key]);
			return {
				...state,
				[action.key]: endDate,
			};
		}
	}
};
