import CreditCard from '@mui/icons-material/CreditCard';
import Info from '@mui/icons-material/Info';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { priceNumberFormat } from '../helpers/numberFormats';

type Invoice = {
	name: string;
	ref: string;
	amount: number;
	date: Date;
	invoiceDetails: InvoiceDetail[];
};

type InvoiceDetail = {
	name: string;
	ref: string;
	quantity: number;
	rate: number;
	amount: number;
};

export const Invoicing = (): JSX.Element => {
	const [open, setOpen] = useState<boolean>(false);
	const [selectedInvoice, setSelectedInvoice] = useState<string>(
		Object.keys(invoiceData)[0],
	);
	const [dataList, setDataList] = useState<(string | JSX.Element)[][]>([]);
	const columns = [
		{
			name: 'Company',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '15%',
					},
				}),
			},
		},
		{
			name: 'Ref',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '15%' },
				}),
			},
		},
		{
			name: 'Amount',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '15%' },
				}),
			},
		},
		{
			name: 'Date',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '15%' },
				}),
			},
		},
		{
			name: 'Details',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
				filter: false,
				sort: false,
			},
		},
		{
			name: 'Pay',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '10%',
					},
				}),
				filter: false,
				sort: false,
			},
		},
	];

	const options: MUIDataTableOptions = {
		filterType: 'textField',
		selectableRows: 'none',
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 225px)',
		rowsPerPage: 10,
		expandableRowsHeader: false,
	};

	useEffect(() => {
		const tableData = Object.values(invoiceData).map((entry) => {
			return [
				entry.name,
				entry.ref,
				priceNumberFormat(entry.amount),
				entry.date.toLocaleDateString(),
				<IconButton
					key={entry.name + 'info'}
					onClick={(): void => {
						setOpen(true);
						setSelectedInvoice(entry.ref);
					}}>
					<Info />
				</IconButton>,
				<IconButton key={entry.name + 'credit-card'}>
					<CreditCard />
				</IconButton>,
			];
		});
		setDataList(tableData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceData]);

	const handleClose = (): void => {
		setOpen(false);
	};

	const invoice = (): JSX.Element => {
		return (
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell style={{ fontWeight: 600 }}>
								Name
							</TableCell>
							<TableCell
								align="center"
								style={{ fontWeight: 600 }}>
								Ref
							</TableCell>
							<TableCell
								align="center"
								style={{ fontWeight: 600 }}>
								Quantity
							</TableCell>
							<TableCell
								style={{ fontWeight: 600 }}
								align="center">
								Rate
							</TableCell>
							<TableCell
								style={{ fontWeight: 600 }}
								align="center">
								Amount
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invoiceData[selectedInvoice] &&
							invoiceData[selectedInvoice]['invoiceDetails'].map(
								(row, index) => (
									<TableRow key={index}>
										<TableCell>{row.name}</TableCell>
										<TableCell align="center">
											{row.ref}
										</TableCell>
										<TableCell align="center">
											{row.quantity}
										</TableCell>
										<TableCell align="center">
											{priceNumberFormat(row.rate)}
										</TableCell>
										<TableCell align="center">
											{priceNumberFormat(row.amount)}
										</TableCell>
									</TableRow>
								),
							)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	const dialog = (): JSX.Element => {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				maxWidth="md"
				fullWidth={true}>
				<DialogTitle>
					<Box display="flex" justifyContent="flex-end">
						<Button
							variant="outlined"
							onClick={(): void => setOpen(false)}>
							Close
						</Button>
					</Box>
					<Grid container>
						<Grid item xs={8} display="flex" alignItems="center">
							<Typography variant="h5">Trade Legion</Typography>
						</Grid>
						<Grid item container xs={3}>
							<Grid item xs={12}>
								<Typography>
									{invoiceData[selectedInvoice].ref}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>January 2021</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>
									Kate Sheppard Apartments
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>23 Spyglass Lane</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>Whitby Porirua 5024</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography>Wellington NEW ZEALAND</Typography>
							</Grid>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>{invoice()}</DialogContent>
				<DialogActions>
					<Grid
						container
						justifyContent="flex-end"
						width="33%"
						paddingBottom={2}
						marginRight={4}>
						<Grid
							item
							display="flex"
							justifyContent="space-between"
							xs={12}>
							<Typography>Subtotal </Typography>
							<Typography>
								{priceNumberFormat(
									invoiceData[selectedInvoice].amount * 0.85,
								)}
							</Typography>
						</Grid>
						<Grid
							item
							display="flex"
							justifyContent="space-between"
							xs={12}>
							<Typography>GST </Typography>
							<Typography>
								{priceNumberFormat(
									invoiceData[selectedInvoice].amount * 0.15,
								)}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid
							item
							display="flex"
							justifyContent="space-between"
							xs={12}>
							<Typography style={{ fontWeight: 600 }}>
								Total
							</Typography>
							<Typography style={{ fontWeight: 600 }}>
								{priceNumberFormat(
									invoiceData[selectedInvoice].amount,
								)}
							</Typography>
						</Grid>
						<Grid item xs={12} paddingTop={2}>
							<Button variant="contained" fullWidth>
								Pay Now
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			<MUIDataTable
				title="Invoices"
				data={dataList}
				columns={columns}
				options={options}
			/>
			{dialog()}
		</>
	);
};

const invoiceData = {
	'INV-0131': {
		name: 'Trade Legion',
		ref: 'INV-0131',
		amount: 28717.04,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Nehru Gallegos',
				ref: '04/25/22',
				quantity: 43.6,
				rate: 31.9,
				amount: 1390.84,
			},
			{
				name: 'Hamish Alford',
				ref: '10/05/22',
				quantity: 45.6,
				rate: 32.2,
				amount: 1468.32,
			},
			{
				name: 'Raven Neal',
				ref: '19/33/21',
				quantity: 46.6,
				rate: 30.2,
				amount: 1407.32,
			},
			{
				name: 'Tamekah Fowler',
				ref: '31/44/21',
				quantity: 44.9,
				rate: 29.5,
				amount: 1324.55,
			},
			{
				name: 'George Medina',
				ref: '15/25/21',
				quantity: 46.8,
				rate: 31.8,
				amount: 1488.24,
			},
			{
				name: 'Hop Barber',
				ref: '27/08/22',
				quantity: 44.0,
				rate: 31.9,
				amount: 1403.6,
			},
			{
				name: 'Uta Goodwin',
				ref: '09/35/22',
				quantity: 43.4,
				rate: 33.2,
				amount: 1440.88,
			},
			{
				name: 'Robin Glover',
				ref: '09/08/21',
				quantity: 46.0,
				rate: 29.7,
				amount: 1366.2,
			},
			{
				name: 'Anika Miller',
				ref: '16/47/22',
				quantity: 46.5,
				rate: 30.7,
				amount: 1427.55,
			},
			{
				name: 'Carlos Melton',
				ref: '01/18/21',
				quantity: 45.4,
				rate: 30.2,
				amount: 1371.08,
			},
			{
				name: 'Erica Morin',
				ref: '16/38/21',
				quantity: 48.5,
				rate: 32.9,
				amount: 1595.64,
			},
			{
				name: 'Keith Trevino',
				ref: '18/27/22',
				quantity: 46.3,
				rate: 30.6,
				amount: 1416.78,
			},
			{
				name: 'Guinevere Head',
				ref: '18/31/21',
				quantity: 45.7,
				rate: 31.9,
				amount: 1457.83,
			},
			{
				name: 'Demetria Rush',
				ref: '27/40/22',
				quantity: 47.1,
				rate: 32.8,
				amount: 1544.88,
			},
			{
				name: 'Josephine Lane',
				ref: '03/50/21',
				quantity: 47.4,
				rate: 32.3,
				amount: 1531.02,
			},
			{
				name: 'Isaiah Mcfadden',
				ref: '23/47/21',
				quantity: 46.5,
				rate: 32.1,
				amount: 1492.65,
			},
			{
				name: 'Jackson Mason',
				ref: '12/37/21',
				quantity: 45.6,
				rate: 32.4,
				amount: 1477.44,
			},
			{
				name: 'Hayfa Mullen',
				ref: '29/01/21',
				quantity: 45.7,
				rate: 29.7,
				amount: 1357.29,
			},
			{
				name: 'David Copeland',
				ref: '05/14/22',
				quantity: 44.5,
				rate: 31.2,
				amount: 1388.4,
			},
			{
				name: 'Elizabeth Emerson',
				ref: '26/01/23',
				quantity: 45.1,
				rate: 30.3,
				amount: 1366.53,
			},
		],
	},
	'INV-0132': {
		name: 'Trade Legion',
		ref: 'INV-0132',
		amount: 29312.31,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Halla Mccarty',
				ref: '25/45/21',
				quantity: 45.7,
				rate: 31.4,
				amount: 1434.98,
			},
			{
				name: 'Tucker Vincent',
				ref: '06/13/21',
				quantity: 45.9,
				rate: 30.2,
				amount: 1386.17,
			},
			{
				name: 'Leonard Serrano',
				ref: '23/44/21',
				quantity: 44.7,
				rate: 31.2,
				amount: 1394.64,
			},
			{
				name: 'Cassandra Villarreal',
				ref: '14/09/22',
				quantity: 46.8,
				rate: 32.0,
				amount: 1497.6,
			},
			{
				name: 'Hilda Kim',
				ref: '23/41/22',
				quantity: 43.5,
				rate: 31.5,
				amount: 1370.25,
			},
			{
				name: 'Amber Craft',
				ref: '07/29/21',
				quantity: 44.9,
				rate: 32.2,
				amount: 1445.78,
			},
			{
				name: 'Mohammad Jackson',
				ref: '26/00/21',
				quantity: 45.5,
				rate: 31.9,
				amount: 1451.45,
			},
			{
				name: 'Cedric Weiss',
				ref: '28/19/21',
				quantity: 44.3,
				rate: 30.5,
				amount: 1351.15,
			},
			{
				name: 'Nomlanga Wood',
				ref: '15/11/21',
				quantity: 44.2,
				rate: 30.1,
				amount: 1330.42,
			},
			{
				name: 'MacKensie Sawyer',
				ref: '20/38/21',
				quantity: 45.2,
				rate: 30.7,
				amount: 1387.64,
			},
			{
				name: 'Alisa Case',
				ref: '17/56/21',
				quantity: 45.4,
				rate: 32.0,
				amount: 1452.8,
			},
			{
				name: 'Ramona Harrell',
				ref: '11/27/21',
				quantity: 44.0,
				rate: 30.0,
				amount: 1320,
			},
			{
				name: 'Armando Horn',
				ref: '27/41/22',
				quantity: 44.8,
				rate: 35.0,
				amount: 1568,
			},
			{
				name: 'Upton Bright',
				ref: '08/19/21',
				quantity: 42.6,
				rate: 32.4,
				amount: 1380.24,
			},
			{
				name: 'Indigo Vaughn',
				ref: '31/29/21',
				quantity: 43.7,
				rate: 30.1,
				amount: 1315.37,
			},
			{
				name: 'Rooney Yang',
				ref: '06/22/21',
				quantity: 44.5,
				rate: 31.4,
				amount: 1397.3,
			},
			{
				name: 'Maxine Mclean',
				ref: '24/41/22',
				quantity: 46.5,
				rate: 27.7,
				amount: 1288.05,
			},
			{
				name: 'Dara Wood',
				ref: '11/37/21',
				quantity: 46.7,
				rate: 30.8,
				amount: 1438.36,
			},
			{
				name: 'Cleo Osborn',
				ref: '24/27/21',
				quantity: 43.4,
				rate: 30.5,
				amount: 1323.7,
			},
			{
				name: 'Claire Klein',
				ref: '16/15/21',
				quantity: 47.8,
				rate: 30.7,
				amount: 1467.46,
			},
		],
	},
	'INV-0133': {
		name: 'Trade Legion',
		ref: 'INV-0133',
		amount: 41033.76,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Lana Kaufman',
				ref: '31/56/21',
				quantity: 43.8,
				rate: 29.4,
				amount: 1287.72,
			},
			{
				name: 'Rafael Nicholson',
				ref: '18/58/21',
				quantity: 44.1,
				rate: 29.9,
				amount: 1318.59,
			},
			{
				name: 'August Warner',
				ref: '08/37/21',
				quantity: 44.2,
				rate: 31.1,
				amount: 1374.62,
			},
			{
				name: 'Cassady Underwood',
				ref: '09/19/21',
				quantity: 44.1,
				rate: 31.1,
				amount: 1371.51,
			},
			{
				name: 'Patricia Vincent',
				ref: '18/22/22',
				quantity: 46.3,
				rate: 32.6,
				amount: 1509.38,
			},
			{
				name: 'Mara Stout',
				ref: '21/55/21',
				quantity: 47.5,
				rate: 31.6,
				amount: 1501,
			},
			{
				name: 'Mira Crawford',
				ref: '23/22/22',
				quantity: 44.1,
				rate: 31.6,
				amount: 1393.56,
			},
			{
				name: 'Solomon Davenport',
				ref: '17/49/22',
				quantity: 43.7,
				rate: 30.4,
				amount: 1328.48,
			},
			{
				name: 'Orlando Burns',
				ref: '11/48/22',
				quantity: 44.3,
				rate: 31.5,
				amount: 1395.45,
			},
			{
				name: 'Faith Oliver',
				ref: '11/12/22',
				quantity: 45.0,
				rate: 31.9,
				amount: 1435.5,
			},
			{
				name: 'Dante Spears',
				ref: '24/24/22',
				quantity: 45.8,
				rate: 33.6,
				amount: 1538.88,
			},
			{
				name: 'Ursula Battle',
				ref: '20/46/21',
				quantity: 43.5,
				rate: 30.8,
				amount: 1339.8,
			},
			{
				name: 'Fay Houston',
				ref: '21/47/22',
				quantity: 46.1,
				rate: 29.3,
				amount: 1350.73,
			},
			{
				name: 'Neville Lopez',
				ref: '15/15/23',
				quantity: 46.6,
				rate: 31.5,
				amount: 1467.9,
			},
			{
				name: 'Moana Kaufman',
				ref: '13/56/22',
				quantity: 43.7,
				rate: 33.5,
				amount: 1463.95,
			},
			{
				name: 'Aiko Patrick',
				ref: '11/17/22',
				quantity: 43.3,
				rate: 29.6,
				amount: 1281.68,
			},
			{
				name: 'Chadwick Lee',
				ref: '19/58/21',
				quantity: 45.8,
				rate: 31.0,
				amount: 1419.8,
			},
			{
				name: 'Shoshana Wong',
				ref: '04/58/21',
				quantity: 45.2,
				rate: 30.7,
				amount: 1387.64,
			},
			{
				name: 'Omar Bailey',
				ref: '02/29/21',
				quantity: 46.4,
				rate: 33.0,
				amount: 1531.2,
			},
			{
				name: 'Philip Yates',
				ref: '09/47/22',
				quantity: 45.3,
				rate: 28.8,
				amount: 1304.64,
			},
		],
	},
	'INV-0134': {
		name: 'Trade Legion',
		ref: 'INV-0134',
		amount: 34023.76,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Cole Rollins',
				ref: '12/50/22',
				quantity: 49.0,
				rate: 28.4,
				amount: 1391.6,
			},
			{
				name: 'Zelenia Warner',
				ref: '27/13/21',
				quantity: 43.5,
				rate: 30.8,
				amount: 1339.8,
			},
			{
				name: 'Katelyn Randolph',
				ref: '22/11/21',
				quantity: 49.2,
				rate: 28.8,
				amount: 1416.96,
			},
			{
				name: 'Scott Alexander',
				ref: '25/10/22',
				quantity: 44.0,
				rate: 32.5,
				amount: 1430,
			},
			{
				name: 'Kyle Greer',
				ref: '20/33/22',
				quantity: 41.8,
				rate: 33.0,
				amount: 1379.4,
			},
			{
				name: 'Denton Kaufman',
				ref: '19/01/22',
				quantity: 47.7,
				rate: 30.8,
				amount: 1469.16,
			},
			{
				name: 'Amena Humphrey',
				ref: '15/34/21',
				quantity: 44.4,
				rate: 29.8,
				amount: 1323.12,
			},
			{
				name: 'Bell Weiss',
				ref: '11/22/22',
				quantity: 45.7,
				rate: 28.8,
				amount: 1316.16,
			},
			{
				name: 'Luke Daugherty',
				ref: '05/15/22',
				quantity: 43.2,
				rate: 31.1,
				amount: 1343.52,
			},
			{
				name: 'Alden Myers',
				ref: '24/59/21',
				quantity: 44.8,
				rate: 28.6,
				amount: 1281.28,
			},
			{
				name: 'Daniel Salas',
				ref: '18/29/21',
				quantity: 44.8,
				rate: 31.6,
				amount: 1415.68,
			},
			{
				name: 'Kirestin Bowen',
				ref: '16/24/22',
				quantity: 46.6,
				rate: 30.6,
				amount: 1425.96,
			},
			{
				name: 'Denton Vazquez',
				ref: '18/46/21',
				quantity: 44.1,
				rate: 33.6,
				amount: 1481.76,
			},
			{
				name: 'Vaughan Wells',
				ref: '15/59/21',
				quantity: 43.4,
				rate: 34.2,
				amount: 1484.28,
			},
			{
				name: 'Nell Williamson',
				ref: '09/09/21',
				quantity: 44.0,
				rate: 28.8,
				amount: 1267.2,
			},
			{
				name: 'Jael Warner',
				ref: '29/05/22',
				quantity: 46.5,
				rate: 31.2,
				amount: 1450.8,
			},
			{
				name: 'Melinda Andrews',
				ref: '27/01/22',
				quantity: 45.8,
				rate: 29.1,
				amount: 1332.78,
			},
			{
				name: 'Ivana Roman',
				ref: '06/36/21',
				quantity: 44.8,
				rate: 31.3,
				amount: 1402.24,
			},
			{
				name: 'Nathaniel Blackwell',
				ref: '10/35/22',
				quantity: 44.3,
				rate: 32.8,
				amount: 1453.04,
			},
			{
				name: 'Judah Davis',
				ref: '26/59/22',
				quantity: 45.4,
				rate: 35.4,
				amount: 1607.16,
			},
		],
	},
	'INV-0135': {
		name: 'Trade Legion',
		ref: 'INV-0135',
		amount: 26492.12,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Kieran Mcfarland',
				ref: '19/39/21',
				quantity: 45.7,
				rate: 31.2,
				amount: 1425.84,
			},
			{
				name: 'Matthew Caldwell',
				ref: '17/08/22',
				quantity: 42.6,
				rate: 32.2,
				amount: 1371.72,
			},
			{
				name: 'Christian Duncan',
				ref: '13/31/21',
				quantity: 45.3,
				rate: 30.4,
				amount: 1377.12,
			},
			{
				name: 'Danielle Wilkins',
				ref: '29/12/22',
				quantity: 45.0,
				rate: 29.5,
				amount: 1327.5,
			},
			{
				name: 'Ainsley Cannon',
				ref: '09/22/21',
				quantity: 44.8,
				rate: 33.2,
				amount: 1487.36,
			},
			{
				name: 'Lillith Craft',
				ref: '26/58/22',
				quantity: 44.5,
				rate: 30.3,
				amount: 1348.35,
			},
			{
				name: 'Silas Thompson',
				ref: '20/01/21',
				quantity: 45.0,
				rate: 32.3,
				amount: 1453.5,
			},
			{
				name: 'Raya Burch',
				ref: '02/32/21',
				quantity: 46.3,
				rate: 31.4,
				amount: 1453.82,
			},
			{
				name: 'Hamish Todd',
				ref: '18/52/22',
				quantity: 42.9,
				rate: 30.5,
				amount: 1308.45,
			},
			{
				name: 'Leroy Kirkland',
				ref: '14/36/22',
				quantity: 46.5,
				rate: 30.8,
				amount: 1432.2,
			},
			{
				name: 'Nola Bonner',
				ref: '09/56/21',
				quantity: 46.6,
				rate: 29.0,
				amount: 1351.4,
			},
			{
				name: 'Connor Solomon',
				ref: '13/40/22',
				quantity: 44.4,
				rate: 33.0,
				amount: 1465.2,
			},
			{
				name: 'Hyacinth Leon',
				ref: '09/56/22',
				quantity: 45.3,
				rate: 31.0,
				amount: 1404.3,
			},
			{
				name: 'Bevis Kennedy',
				ref: '11/23/22',
				quantity: 44.4,
				rate: 30.1,
				amount: 1336.44,
			},
			{
				name: 'Ferdinand Snow',
				ref: '27/50/21',
				quantity: 45.4,
				rate: 31.0,
				amount: 1407.4,
			},
			{
				name: 'Grady Martinez',
				ref: '30/55/22',
				quantity: 45.4,
				rate: 29.8,
				amount: 1352.92,
			},
			{
				name: 'Carol Klein',
				ref: '26/27/22',
				quantity: 42.5,
				rate: 31.6,
				amount: 1343,
			},
			{
				name: 'Sebastian Summers',
				ref: '24/29/22',
				quantity: 43.3,
				rate: 32.6,
				amount: 1411.58,
			},
			{
				name: 'Sade Rose',
				ref: '05/25/22',
				quantity: 44.1,
				rate: 31.6,
				amount: 1393.56,
			},
			{
				name: 'Owen Frazier',
				ref: '12/23/21',
				quantity: 45.9,
				rate: 30.9,
				amount: 1418.31,
			},
		],
	},
	'INV-0136': {
		name: 'Trade Legion',
		ref: 'INV-0136',
		amount: 31028.31,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Edward Welch',
				ref: '10/05/23',
				quantity: 46.0,
				rate: 31.4,
				amount: 1444.4,
			},
			{
				name: 'Stuart Price',
				ref: '22/13/23',
				quantity: 42.3,
				rate: 31.4,
				amount: 1328.22,
			},
			{
				name: 'Fiona Walsh',
				ref: '30/00/21',
				quantity: 47.3,
				rate: 29.8,
				amount: 1409.54,
			},
			{
				name: 'Uriah Hurst',
				ref: '10/17/22',
				quantity: 47.2,
				rate: 30.8,
				amount: 1453.76,
			},
			{
				name: 'Gareth Summers',
				ref: '22/12/22',
				quantity: 43.7,
				rate: 31.3,
				amount: 1367.81,
			},
			{
				name: "Stephanie O'brien",
				ref: '02/59/21',
				quantity: 44.9,
				rate: 30.8,
				amount: 1382.92,
			},
			{
				name: 'Macy Simon',
				ref: '11/49/21',
				quantity: 44.5,
				rate: 29.6,
				amount: 1317.2,
			},
			{
				name: 'Ifeoma Kirk',
				ref: '13/46/22',
				quantity: 43.6,
				rate: 30.4,
				amount: 1325.44,
			},
			{
				name: 'Hanna Vaughn',
				ref: '22/38/21',
				quantity: 46.7,
				rate: 32.5,
				amount: 1517.75,
			},
			{
				name: 'Malik Benson',
				ref: '13/34/22',
				quantity: 42.7,
				rate: 30.4,
				amount: 1298.08,
			},
			{
				name: 'Wilma Stone',
				ref: '16/59/22',
				quantity: 45.1,
				rate: 32.7,
				amount: 1474.77,
			},
			{
				name: 'Hall Clements',
				ref: '05/33/23',
				quantity: 45.3,
				rate: 26.3,
				amount: 1191.39,
			},
			{
				name: 'Kylie Cooke',
				ref: '15/44/22',
				quantity: 45.0,
				rate: 32.8,
				amount: 1475.99,
			},
			{
				name: 'August Murphy',
				ref: '22/22/21',
				quantity: 43.7,
				rate: 28.6,
				amount: 1249.82,
			},
			{
				name: 'Rachel Stark',
				ref: '29/29/21',
				quantity: 44.0,
				rate: 31.0,
				amount: 1364,
			},
			{
				name: 'Nolan Justice',
				ref: '19/09/21',
				quantity: 45.0,
				rate: 31.3,
				amount: 1408.5,
			},
			{
				name: 'Fritz Barnes',
				ref: '21/16/21',
				quantity: 44.6,
				rate: 30.0,
				amount: 1338,
			},
			{
				name: 'Morgan Daniels',
				ref: '05/28/21',
				quantity: 46.3,
				rate: 28.9,
				amount: 1338.07,
			},
			{
				name: 'Stewart Mccullough',
				ref: '26/28/21',
				quantity: 47.9,
				rate: 31.9,
				amount: 1528.01,
			},
			{
				name: 'Riley Bradshaw',
				ref: '10/21/22',
				quantity: 46.2,
				rate: 30.1,
				amount: 1390.62,
			},
		],
	},
	'INV-0137': {
		name: 'Trade Legion',
		ref: 'INV-0137',
		amount: 29132.31,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Adam Sherman',
				ref: '22/19/22',
				quantity: 45.2,
				rate: 30.5,
				amount: 1378.6,
			},
			{
				name: 'Alexa Simon',
				ref: '29/11/21',
				quantity: 47.5,
				rate: 32.0,
				amount: 1520,
			},
			{
				name: 'Thane Travis',
				ref: '18/42/21',
				quantity: 44.6,
				rate: 28.5,
				amount: 1271.1,
			},
			{
				name: 'Fleur Frye',
				ref: '01/36/22',
				quantity: 45.3,
				rate: 32.5,
				amount: 1472.25,
			},
			{
				name: 'Alisa Dotson',
				ref: '04/54/21',
				quantity: 46.1,
				rate: 30.1,
				amount: 1387.61,
			},
			{
				name: 'Idola Sutton',
				ref: '20/24/22',
				quantity: 41.6,
				rate: 32.0,
				amount: 1331.2,
			},
			{
				name: 'Castor Atkins',
				ref: '09/09/21',
				quantity: 44.1,
				rate: 33.0,
				amount: 1455.3,
			},
			{
				name: 'Joel Molina',
				ref: '11/08/22',
				quantity: 45.1,
				rate: 29.7,
				amount: 1339.47,
			},
			{
				name: 'Signe Strong',
				ref: '21/53/21',
				quantity: 45.7,
				rate: 30.2,
				amount: 1380.14,
			},
			{
				name: 'Ezra Barton',
				ref: '02/50/21',
				quantity: 45.5,
				rate: 32.3,
				amount: 1469.64,
			},
			{
				name: 'Ursula Hensley',
				ref: '09/52/21',
				quantity: 42.5,
				rate: 34.3,
				amount: 1457.74,
			},
			{
				name: 'Berk Hansen',
				ref: '30/19/21',
				quantity: 44.7,
				rate: 28.4,
				amount: 1269.48,
			},
			{
				name: 'Adrienne Tran',
				ref: '25/18/22',
				quantity: 42.2,
				rate: 31.5,
				amount: 1329.3,
			},
			{
				name: 'Clinton Dickerson',
				ref: '08/46/22',
				quantity: 46.6,
				rate: 31.9,
				amount: 1486.54,
			},
			{
				name: 'Kelly Gamble',
				ref: '01/56/21',
				quantity: 45.7,
				rate: 31.1,
				amount: 1421.27,
			},
			{
				name: 'Barrett Russell',
				ref: '11/26/22',
				quantity: 44.1,
				rate: 29.9,
				amount: 1318.59,
			},
			{
				name: 'Maile Warren',
				ref: '11/26/22',
				quantity: 45.9,
				rate: 33.6,
				amount: 1542.24,
			},
			{
				name: 'Rafael Zamora',
				ref: '20/47/21',
				quantity: 46.7,
				rate: 30.1,
				amount: 1405.67,
			},
			{
				name: 'Paki Robinson',
				ref: '09/51/23',
				quantity: 48.7,
				rate: 30.8,
				amount: 1499.96,
			},
			{
				name: 'Sierra Colon',
				ref: '27/14/21',
				quantity: 45.7,
				rate: 31.3,
				amount: 1430.41,
			},
		],
	},
	'INV-0138': {
		name: 'Trade Legion',
		ref: 'INV-0138',
		amount: 33719.93,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Quail Odom',
				ref: '11/18/22',
				quantity: 43.9,
				rate: 31.0,
				amount: 1360.89,
			},
			{
				name: 'Basia Mcleod',
				ref: '26/56/21',
				quantity: 45.9,
				rate: 30.0,
				amount: 1377,
			},
			{
				name: 'Denton Jacobson',
				ref: '14/15/21',
				quantity: 42.6,
				rate: 33.6,
				amount: 1431.36,
			},
			{
				name: 'Zenaida Berg',
				ref: '12/42/21',
				quantity: 45.7,
				rate: 32.1,
				amount: 1466.97,
			},
			{
				name: 'Brooke Wilkinson',
				ref: '21/32/22',
				quantity: 44.6,
				rate: 29.7,
				amount: 1324.62,
			},
			{
				name: 'Marcia Garrison',
				ref: '08/40/23',
				quantity: 45.1,
				rate: 31.1,
				amount: 1402.61,
			},
			{
				name: 'Cynthia Wilkins',
				ref: '17/44/22',
				quantity: 43.9,
				rate: 28.5,
				amount: 1251.15,
			},
			{
				name: 'Jonas Mcpherson',
				ref: '20/57/22',
				quantity: 41.2,
				rate: 30.1,
				amount: 1240.12,
			},
			{
				name: 'Faith Mccoy',
				ref: '25/18/21',
				quantity: 44.5,
				rate: 30.0,
				amount: 1335,
			},
			{
				name: 'Gillian Carr',
				ref: '08/28/21',
				quantity: 47.2,
				rate: 31.3,
				amount: 1477.36,
			},
			{
				name: 'Duncan Jackson',
				ref: '29/56/21',
				quantity: 45.0,
				rate: 32.4,
				amount: 1458,
			},
			{
				name: 'Sacha Mckee',
				ref: '05/29/22',
				quantity: 44.5,
				rate: 30.1,
				amount: 1339.45,
			},
			{
				name: 'Nadine Carey',
				ref: '23/26/22',
				quantity: 47.6,
				rate: 31.8,
				amount: 1513.68,
			},
			{
				name: 'Sopoline Wilkerson',
				ref: '19/32/21',
				quantity: 44.9,
				rate: 30.2,
				amount: 1355.98,
			},
			{
				name: 'Kennan Noel',
				ref: '08/56/22',
				quantity: 46.3,
				rate: 33.5,
				amount: 1551.05,
			},
			{
				name: 'Branden Dorsey',
				ref: '27/23/22',
				quantity: 43.3,
				rate: 30.1,
				amount: 1303.33,
			},
			{
				name: 'Giselle Jennings',
				ref: '23/07/23',
				quantity: 44.1,
				rate: 28.5,
				amount: 1256.85,
			},
			{
				name: 'Velma Bright',
				ref: '21/02/22',
				quantity: 45.3,
				rate: 32.2,
				amount: 1458.66,
			},
			{
				name: 'Francis Ramirez',
				ref: '21/24/21',
				quantity: 43.5,
				rate: 30.1,
				amount: 1309.35,
			},
			{
				name: 'Emily Craig',
				ref: '30/43/22',
				quantity: 44.0,
				rate: 33.1,
				amount: 1456.4,
			},
		],
	},
	'INV-0139': {
		name: 'Trade Legion',
		ref: 'INV-0139',
		amount: 32128.91,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Yoshi Hull',
				ref: '14/52/23',
				quantity: 46.4,
				rate: 30.5,
				amount: 1415.2,
			},
			{
				name: 'Naomi Barber',
				ref: '03/38/22',
				quantity: 44.6,
				rate: 29.2,
				amount: 1302.32,
			},
			{
				name: 'Inez Hess',
				ref: '02/55/22',
				quantity: 44.5,
				rate: 30.1,
				amount: 1339.45,
			},
			{
				name: 'Germaine Pennington',
				ref: '02/08/21',
				quantity: 43.7,
				rate: 33.0,
				amount: 1442.1,
			},
			{
				name: 'Olga Moreno',
				ref: '29/44/22',
				quantity: 46.0,
				rate: 31.2,
				amount: 1435.2,
			},
			{
				name: 'George Sawyer',
				ref: '06/25/22',
				quantity: 44.8,
				rate: 31.9,
				amount: 1429.12,
			},
			{
				name: 'Odette Harrington',
				ref: '23/25/21',
				quantity: 45.8,
				rate: 31.6,
				amount: 1447.28,
			},
			{
				name: 'Mia Mcdonald',
				ref: '07/54/21',
				quantity: 44.2,
				rate: 33.7,
				amount: 1489.54,
			},
			{
				name: 'Basil Bennett',
				ref: '29/23/21',
				quantity: 43.5,
				rate: 28.6,
				amount: 1244.1,
			},
			{
				name: 'Regina Benton',
				ref: '07/44/21',
				quantity: 44.7,
				rate: 30.6,
				amount: 1367.82,
			},
			{
				name: 'Stella Blanchard',
				ref: '07/11/21',
				quantity: 43.9,
				rate: 28.8,
				amount: 1264.32,
			},
			{
				name: 'Bernard England',
				ref: '10/57/21',
				quantity: 44.0,
				rate: 32.0,
				amount: 1408,
			},
			{
				name: 'Burton Garrison',
				ref: '22/54/21',
				quantity: 43.8,
				rate: 30.0,
				amount: 1314,
			},
			{
				name: 'Jordan Greene',
				ref: '24/51/22',
				quantity: 45.3,
				rate: 32.1,
				amount: 1454.12,
			},
			{
				name: 'Katelyn Phillips',
				ref: '05/31/21',
				quantity: 46.8,
				rate: 32.4,
				amount: 1516.32,
			},
			{
				name: 'Stewart Russo',
				ref: '31/12/21',
				quantity: 48.4,
				rate: 32.3,
				amount: 1563.31,
			},
			{
				name: 'Lamar Leach',
				ref: '24/35/21',
				quantity: 44.2,
				rate: 30.2,
				amount: 1334.84,
			},
			{
				name: 'Noelani Page',
				ref: '22/34/22',
				quantity: 46.4,
				rate: 30.6,
				amount: 1419.84,
			},
			{
				name: 'Portia Blackburn',
				ref: '09/40/21',
				quantity: 45.9,
				rate: 33.6,
				amount: 1542.24,
			},
			{
				name: 'Yasir George',
				ref: '25/20/22',
				quantity: 42.2,
				rate: 32.7,
				amount: 1379.94,
			},
		],
	},
	'INV-0140': {
		name: 'Trade Legion',
		ref: 'INV-01310',
		amount: 38192.13,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Jacqueline Mckinney',
				ref: '09/25/21',
				quantity: 43.2,
				rate: 31.2,
				amount: 1347.84,
			},
			{
				name: 'Lane Savage',
				ref: '25/12/21',
				quantity: 44.5,
				rate: 29.2,
				amount: 1299.4,
			},
			{
				name: 'Martena Lindsay',
				ref: '03/50/21',
				quantity: 44.9,
				rate: 31.8,
				amount: 1427.82,
			},
			{
				name: 'Kibo Tyson',
				ref: '12/46/22',
				quantity: 46.6,
				rate: 30.2,
				amount: 1407.32,
			},
			{
				name: 'Timon Delgado',
				ref: '23/40/22',
				quantity: 45.2,
				rate: 30.3,
				amount: 1369.56,
			},
			{
				name: 'Shaeleigh Bishop',
				ref: '19/13/22',
				quantity: 44.8,
				rate: 31.3,
				amount: 1402.24,
			},
			{
				name: 'Christen Gomez',
				ref: '30/18/22',
				quantity: 46.3,
				rate: 30.1,
				amount: 1393.63,
			},
			{
				name: 'Demetrius Sawyer',
				ref: '12/46/22',
				quantity: 44.5,
				rate: 29.8,
				amount: 1326.1,
			},
			{
				name: 'Kamal Walters',
				ref: '07/25/22',
				quantity: 44.6,
				rate: 32.5,
				amount: 1449.5,
			},
			{
				name: 'Byron Fulton',
				ref: '12/01/23',
				quantity: 47.2,
				rate: 32.1,
				amount: 1515.12,
			},
			{
				name: 'Kasper Ashley',
				ref: '20/38/22',
				quantity: 43.9,
				rate: 27.8,
				amount: 1220.42,
			},
			{
				name: 'Salvador Dale',
				ref: '04/35/22',
				quantity: 46.0,
				rate: 29.4,
				amount: 1352.4,
			},
			{
				name: 'Clark Rocha',
				ref: '26/44/22',
				quantity: 44.6,
				rate: 30.7,
				amount: 1369.22,
			},
			{
				name: 'Moana Kline',
				ref: '31/25/22',
				quantity: 43.6,
				rate: 30.2,
				amount: 1316.72,
			},
			{
				name: 'Edward Cardenas',
				ref: '14/14/21',
				quantity: 45.0,
				rate: 32.0,
				amount: 1440,
			},
			{
				name: 'Patrick Brooks',
				ref: '04/28/22',
				quantity: 46.2,
				rate: 30.1,
				amount: 1390.62,
			},
			{
				name: 'Beverly Hale',
				ref: '07/09/22',
				quantity: 46.8,
				rate: 28.7,
				amount: 1343.16,
			},
			{
				name: 'Valentine Mueller',
				ref: '11/35/21',
				quantity: 46.9,
				rate: 31.4,
				amount: 1472.66,
			},
			{
				name: 'Gregory Clayton',
				ref: '27/25/21',
				quantity: 46.0,
				rate: 32.1,
				amount: 1476.6,
			},
			{
				name: 'Jolie Sanford',
				ref: '03/55/22',
				quantity: 45.3,
				rate: 26.7,
				amount: 1209.51,
			},
		],
	},
	'INV-0141': {
		name: 'Trade Legion',
		ref: 'INV-01311',
		amount: 39192.29,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Norman Tyler',
				ref: '09/05/21',
				quantity: 47.3,
				rate: 31.0,
				amount: 1466.3,
			},
			{
				name: 'Tyler Underwood',
				ref: '04/28/22',
				quantity: 45.9,
				rate: 33.7,
				amount: 1546.83,
			},
			{
				name: 'Timothy Anthony',
				ref: '27/18/22',
				quantity: 44.5,
				rate: 27.5,
				amount: 1223.75,
			},
			{
				name: 'Eleanor Ramsey',
				ref: '10/58/22',
				quantity: 45.5,
				rate: 31.4,
				amount: 1428.7,
			},
			{
				name: 'Irma Cherry',
				ref: '15/44/22',
				quantity: 45.8,
				rate: 31.6,
				amount: 1447.28,
			},
			{
				name: 'Malachi Combs',
				ref: '26/44/22',
				quantity: 45.7,
				rate: 32.5,
				amount: 1485.25,
			},
			{
				name: 'Adam Pennington',
				ref: '24/11/22',
				quantity: 43.6,
				rate: 29.4,
				amount: 1281.84,
			},
			{
				name: 'Rashad Martin',
				ref: '06/47/22',
				quantity: 45.7,
				rate: 35.0,
				amount: 1599.5,
			},
			{
				name: 'Kermit Garza',
				ref: '02/52/22',
				quantity: 44.9,
				rate: 29.8,
				amount: 1338.02,
			},
			{
				name: 'Herrod Black',
				ref: '02/27/22',
				quantity: 45.6,
				rate: 31.7,
				amount: 1445.52,
			},
			{
				name: 'Chastity Perkins',
				ref: '09/28/21',
				quantity: 44.5,
				rate: 30.9,
				amount: 1375.05,
			},
			{
				name: 'Jerome Harvey',
				ref: '01/53/22',
				quantity: 46.4,
				rate: 32.1,
				amount: 1489.44,
			},
			{
				name: 'Amery Rodriquez',
				ref: '11/56/22',
				quantity: 45.6,
				rate: 32.0,
				amount: 1459.2,
			},
			{
				name: 'Mia Richard',
				ref: '14/04/22',
				quantity: 45.7,
				rate: 33.5,
				amount: 1530.95,
			},
			{
				name: 'Hiram Powell',
				ref: '25/22/22',
				quantity: 45.0,
				rate: 33.6,
				amount: 1512,
			},
			{
				name: 'Quinn Patton',
				ref: '28/29/22',
				quantity: 46.6,
				rate: 29.7,
				amount: 1384.02,
			},
			{
				name: 'Hamish Maldonado',
				ref: '12/13/21',
				quantity: 45.7,
				rate: 29.8,
				amount: 1361.86,
			},
			{
				name: 'Nola Holder',
				ref: '26/56/22',
				quantity: 43.2,
				rate: 30.6,
				amount: 1321.92,
			},
			{
				name: 'Francis Black',
				ref: '03/02/21',
				quantity: 46.0,
				rate: 32.9,
				amount: 1513.4,
			},
			{
				name: 'Cameran Nicholson',
				ref: '27/09/22',
				quantity: 44.3,
				rate: 29.8,
				amount: 1320.14,
			},
		],
	},
	'INV-0142': {
		name: 'Trade Legion',
		ref: 'INV-01312',
		amount: 33792.83,
		date: new Date(),
		invoiceDetails: [
			{
				name: 'Aidan Delaney',
				ref: '31/19/22',
				quantity: 44.1,
				rate: 28.7,
				amount: 1265.67,
			},
			{
				name: 'Odette Lester',
				ref: '15/00/22',
				quantity: 46.1,
				rate: 29.3,
				amount: 1350.73,
			},
			{
				name: 'Graiden Kramer',
				ref: '28/24/22',
				quantity: 45.6,
				rate: 32.3,
				amount: 1472.88,
			},
			{
				name: 'Meghan Foreman',
				ref: '02/11/22',
				quantity: 45.9,
				rate: 30.0,
				amount: 1377,
			},
			{
				name: 'Zephr Cote',
				ref: '18/18/22',
				quantity: 45.2,
				rate: 33.6,
				amount: 1518.72,
			},
			{
				name: 'Reed Brewer',
				ref: '20/52/22',
				quantity: 44.4,
				rate: 28.7,
				amount: 1274.28,
			},
			{
				name: 'Kessie Leon',
				ref: '26/32/22',
				quantity: 43.9,
				rate: 31.2,
				amount: 1369.68,
			},
			{
				name: 'Lacy Chang',
				ref: '20/31/22',
				quantity: 45.8,
				rate: 33.5,
				amount: 1534.3,
			},
			{
				name: 'Vernon Peters',
				ref: '23/43/22',
				quantity: 45.5,
				rate: 33.6,
				amount: 1528.8,
			},
			{
				name: 'Judith Frost',
				ref: '16/12/21',
				quantity: 46.9,
				rate: 28.4,
				amount: 1331.96,
			},
			{
				name: 'Rudyard Jacobson',
				ref: '04/50/22',
				quantity: 45.0,
				rate: 32.1,
				amount: 1444.5,
			},
			{
				name: 'Nomlanga Lambert',
				ref: '30/00/22',
				quantity: 43.0,
				rate: 29.9,
				amount: 1285.7,
			},
			{
				name: 'Keiko Collier',
				ref: '14/50/21',
				quantity: 44.3,
				rate: 31.7,
				amount: 1404.31,
			},
			{
				name: 'Blake Bolton',
				ref: '13/52/22',
				quantity: 46.8,
				rate: 33.0,
				amount: 1544.4,
			},
			{
				name: 'Oren Whitehead',
				ref: '30/28/22',
				quantity: 45.3,
				rate: 33.1,
				amount: 1499.43,
			},
			{
				name: 'Macaulay Jimenez',
				ref: '09/29/21',
				quantity: 46.1,
				rate: 30.4,
				amount: 1401.44,
			},
			{
				name: 'Scott Lowe',
				ref: '09/04/23',
				quantity: 47.3,
				rate: 31.5,
				amount: 1489.95,
			},
			{
				name: 'Samuel Gonzalez',
				ref: '03/47/21',
				quantity: 42.2,
				rate: 30.4,
				amount: 1282.88,
			},
			{
				name: 'Tanya Lee',
				ref: '12/25/22',
				quantity: 46.6,
				rate: 31.1,
				amount: 1449.26,
			},
			{
				name: 'Quintessa Downs',
				ref: '01/16/22',
				quantity: 44.3,
				rate: 27.8,
				amount: 1231.54,
			},
		],
	},
} as Record<string, Invoice>;
