import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Badge, Box, Card, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Note } from '../../../../constants/Note';
import { BasicNote } from './EditTimesheet/Notes/BasicNote';

export const NotesTooltip = ({
	notes,
}: {
	notes: Pick<Note, 'id' | 'note' | 'user'>[];
}): JSX.Element => {
	const [open, setOpen] = useState(false);
	return (
		<Tooltip
			arrow
			open={open}
			title={
				<Stack spacing={1}>
					{notes.map((note) => (
						<Card sx={{ padding: 1 }} key={note.id}>
							<BasicNote note={note} noteSize="small" />
						</Card>
					))}
				</Stack>
			}>
			{notes.length > 0 ? (
				<Box
					justifyContent="center"
					alignItems="center"
					display="flex"
					width="100%"
					paddingTop={0.5}
					onMouseEnter={(): void => setOpen(true)}
					onMouseLeave={(): void => setOpen(false)}>
					<Badge
						badgeContent={notes.length}
						sx={{
							'& .MuiBadge-badge': {
								color: 'primary.contrastText',
								backgroundColor: 'primary.main',
								pointerEvents: 'none', // make hovering less jittery
								height: 12,
								minWidth: 12,
								fontSize: 8,
								padding: '3px',
							},
						}}>
						<CommentOutlinedIcon fontSize="small" />
					</Badge>
				</Box>
			) : (
				<></>
			)}
		</Tooltip>
	);
};
