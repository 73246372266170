import { Box, Tab, Tabs } from '@mui/material';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { UserProps, assertAccountType } from '../../constants/Common';
import {
	EmployeesAccountType,
	EmployeesConfig,
	employeesAccountType,
} from '../../constants/Employees';

export const Employees = ({
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<EmployeesAccountType>(accountType, employeesAccountType);
	const titles = EmployeesConfig[accountType].titles;
	const pathMatch = useMatch('/employees/:tab');

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={title}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
