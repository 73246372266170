import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { EditButton } from './EditButton';

type SectionHeaderProps = {
	title: string;
	setEditState?: (b: boolean) => void;
	save?: () => Promise<void>;
	editing: boolean;
	printing: boolean;
	children?: React.ReactNode;
};

export const SectionHeader = ({
	title,
	setEditState,
	save,
	printing,
	editing,
	children,
}: SectionHeaderProps): JSX.Element => {
	const [isSaving, setIsSaving] = useState(false);
	const showEditButton = !printing && !editing && setEditState;
	const showCancelSaveButtons = !printing && editing && setEditState && save;
	return (
		<>
			<Grid
				container
				alignItems="space-between"
				justifyContent="space-between"
				mt={4}>
				<Grid item>
					<Typography variant="h5">{title}</Typography>
				</Grid>
				{showEditButton && (
					<Grid item>
						<EditButton onClick={(): void => setEditState(true)} />
					</Grid>
				)}
				{showCancelSaveButtons && (
					<Grid item>
						<Tooltip title="Cancel">
							<IconButton
								color="primary"
								onClick={(): void => setEditState(false)}>
								<CloseIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Save">
							<IconButton
								color="primary"
								onClick={async (): Promise<void> => {
									setIsSaving(true);
									await save();
									setIsSaving(false);
								}}>
								<SaveIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
			</Grid>
			<Divider sx={{ mb: 1 }} />
			{isSaving ? (
				<Box width="100%" m={1} textAlign="center">
					<CircularProgress size="15%" />
				</Box>
			) : (
				children
			)}
		</>
	);
};
