import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { ManageTimesheetPayrollIntegrations } from './ManageTimesheetPayrollIntegrations';

export const ManageTimesheetPayrollIntegrationsWrapper = (): JSX.Element => {
	return (
		<ManageTimesheetPayrollIntegrations
			cloudFunctionApi={cloudFunctionApi}
			firebaseApi={firebaseApi}
		/>
	);
};
