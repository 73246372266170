import { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type InductionStepDeleteRequest = {
	id: string;
	siteID: string;
};

export const inductionStepDelete = async (
	abortSignal: AbortSignal,
	user: User,
	id: string,
	siteID: string,
): Promise<boolean | undefined> => {
	const bodyData: InductionStepDeleteRequest = {
		id,
		siteID,
	};

	return await makeCloudFunctionRequestReturnSuccessStatus<InductionStepDeleteRequest>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.DeleteInductionStep,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: bodyData,
		},
	);
};
