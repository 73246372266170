import CustomAlert from '../CustomAlert/CustomAlert';

type DuplicateTimesheetAlertProps = { handleGoToTimesheet: () => void };

export const DuplicateTimesheetAlert = ({
	handleGoToTimesheet,
}: DuplicateTimesheetAlertProps): JSX.Element => (
	<CustomAlert
		severity="info"
		variant="filled"
		actionOnClick={handleGoToTimesheet}
		actionText="Go to Timesheet"
		alertText="A timesheet already exists for this week"
	/>
);
