import { Typography } from '@mui/material';
import cloudFunctionApi from '../../../cloudfunctions';
import { useUserAuthContext } from '../../../providers/UserProvider';
import { useDocumentsContext } from '../DocumentsWrapper';
import { useDocumentSearchParams } from '../hooks/DocumentNavHooks';
import { Templates } from './Templates';

export const TemplatesWrapper = (): JSX.Element => {
	const user = useUserAuthContext();
	const {
		context: { templateData, loading, templateID },
	} = useDocumentsContext();

	const setDocumentSearchParams = useDocumentSearchParams();

	return user ? (
		<Templates
			templates={templateData}
			selected={templateID}
			setSelected={(value): void =>
				setDocumentSearchParams({ templateID: value })
			}
			loading={loading}
			cloudFunctionApi={cloudFunctionApi}
			user={user}
		/>
	) : (
		<Typography textAlign="center">
			No user info available. Templates can&apos;t be displayed
		</Typography>
	);
};
