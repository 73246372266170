import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Stack,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import { SeriesType } from '@progress/kendo-react-charts';
import { isValid, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { UserProps } from '../../constants/Common';
import { DatePicker } from '../../providers/LocalizationProvider';
import { getMonthString } from '../helpers/dateUtilities';
import {
	percentNumberFormatString,
	priceNumberFormat,
} from '../helpers/numberFormats';
import { DonutChart } from './DonutChart';
import { TimeSeriesChart } from './TimeSeriesChart';

type MonthData = Record<string, { kind: string; share: number }[]>;

export const Metrics = (_: UserProps): JSX.Element => {
	const [month, setMonth] = useState<Date>(startOfMonth(new Date()));
	const [index, setIndex] = useState<string>('');

	useEffect(() => {
		setIndex(`${getMonthString(month.getMonth())}`);
	}, [month]);

	const DateSelector = (): JSX.Element => {
		return (
			<DatePicker
				views={['year', 'month']}
				label="Month Of"
				openTo="month"
				minDate={new Date('2020-01-01')}
				value={month}
				onChange={(date): void => {
					if (isValid(date)) setMonth(date ?? new Date());
				}}
				renderInput={(params: TextFieldProps): JSX.Element => (
					<TextField {...params} helperText={null} fullWidth />
				)}
				inputFormat="MMMM yyyy"
			/>
		);
	};

	const summary = (): JSX.Element => {
		return (
			<Card sx={{ height: '100%' }}>
				<CardHeader title={<DateSelector />} />
				<CardContent>
					<Box padding={0}>
						<Typography variant="h5">Summary</Typography>
					</Box>
					<Grid container padding={2}>
						<Grid
							item
							xs={12}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Typography variant="body2">
								Number of Clients
							</Typography>
							<Typography variant="body2">
								{6 + ' (+2)'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Typography variant="body2">
								Number of Employees
							</Typography>
							<Typography variant="body2">
								{staffData[month.getMonth()].staffNum +
									' (+8%)'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Typography variant="body2">Manhours</Typography>
							<Typography variant="body2">
								{hoursData[month.getMonth()].hours + ' (+14%)'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Typography variant="body2">Revenue</Typography>
							<Typography variant="body2">
								{priceNumberFormat(
									revenuePerWeekData[month.getMonth()]
										.revenue,
								) + ' (+13%)'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							display="flex"
							flexDirection="row"
							justifyContent="space-between">
							<Typography variant="body2">Profit</Typography>
							<Typography variant="body2">
								{priceNumberFormat(
									profitPerWeekData[month.getMonth()].profit,
								) + ' (+4%)'}
							</Typography>
						</Grid>
					</Grid>
					<Box paddingTop={1}>
						<Typography variant="h6">Insights</Typography>
					</Box>
					<Grid container padding={2} spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body2">
								Hawkins are your largest client with 45 staff on
								hire. 32% of your work force. 25% of your profit
								came from them.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								You are most profitable with Maycroft, 24%
								margin, your average margin is{' '}
								{percentNumberFormatString(
									profitPerWeekData[month.getMonth()].profit /
										revenuePerWeekData[month.getMonth()]
											.revenue,
								)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	return (
		<>
			<Stack
				direction="row"
				spacing={2}
				divider={<Divider orientation="vertical" flexItem />}>
				<Box flex={1} pb={2}>
					{summary()}
				</Box>
				<Box overflow="auto" flex={3} pb={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<TimeSeriesChart
								series={EmployeesManHoursSeries}
								axisCrossingValue={AxisCrossingValue}
								axisValue={EmployeesManhoursValueAxis}
								title="Employees and Man Hours"
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<TimeSeriesChart
								series={RevenueProfitPerClientSeries}
								axisCrossingValue={AxisCrossingValue}
								axisValue={PerClientRevenueValueAxis}
								title="Revenue and Profit Per Client"
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<DonutChart
								data={EmployeeSharePerClientData[index]}
								title="Employee Share per Client"
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<DonutChart
								data={ProfitSharePerClientData[index]}
								title="Profit Share per Client"
							/>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</>
	);
};

const staffData = [
	{
		Date: new Date('2021-01-01T00:00:00.000'),
		staffNum: 48,
	},
	{
		Date: new Date('2021-02-01T00:00:00.000'),
		staffNum: 53,
	},
	{
		Date: new Date('2021-03-01T00:00:00.000'),
		staffNum: 58,
	},
	{
		Date: new Date('2021-04-01T00:00:00.000'),
		staffNum: 64,
	},
	{
		Date: new Date('2021-05-01T00:00:00.000'),
		staffNum: 69,
	},
	{
		Date: new Date('2021-06-01T00:00:00.000'),
		staffNum: 75,
	},
	{
		Date: new Date('2021-07-01T00:00:00.000'),
		staffNum: 82,
	},
	{
		Date: new Date('2021-08-01T00:00:00.000'),
		staffNum: 89,
	},
	{
		Date: new Date('2021-09-01T00:00:00.000'),
		staffNum: 97,
	},
	{
		Date: new Date('2021-10-01T00:00:00.000'),
		staffNum: 105,
	},
	{
		Date: new Date('2021-11-01T00:00:00.000'),
		staffNum: 114,
	},
	{
		Date: new Date('2021-12-01T00:00:00.000'),
		staffNum: 126,
	},
];

const hoursData = [
	{
		Date: new Date('2021-01-01T00:00:00.000'),
		hours: 48 * 37,
	},
	{
		Date: new Date('2021-02-01T00:00:00.000'),
		hours: 53 * 41,
	},
	{
		Date: new Date('2021-03-01T00:00:00.000'),
		hours: 58 * 36,
	},
	{
		Date: new Date('2021-04-01T00:00:00.000'),
		hours: 64 * 45,
	},
	{
		Date: new Date('2021-05-01T00:00:00.000'),
		hours: 69 * 43,
	},
	{
		Date: new Date('2021-06-01T00:00:00.000'),
		hours: 75 * 39,
	},
	{
		Date: new Date('2021-07-01T00:00:00.000'),
		hours: 82 * 44,
	},
	{
		Date: new Date('2021-08-01T00:00:00.000'),
		hours: 89 * 41,
	},
	{
		Date: new Date('2021-09-01T00:00:00.000'),
		hours: 97 * 42,
	},
	{
		Date: new Date('2021-10-01T00:00:00.000'),
		hours: 105 * 38,
	},
	{
		Date: new Date('2021-11-01T00:00:00.000'),
		hours: 114 * 35,
	},
	{
		Date: new Date('2021-12-01T00:00:00.000'),
		hours: 126 * 37,
	},
];

const revenuePerWeekData = [
	{
		Date: new Date('2020-01-01T00:00:00.000'),
		revenue: 48 * 37 * 32,
	},
	{
		Date: new Date('2020-02-01T00:00:00.000'),
		revenue: 53 * 41 * 30,
	},
	{
		Date: new Date('2020-03-01T00:00:00.000'),
		revenue: 58 * 36 * 38,
	},
	{
		Date: new Date('2020-04-01T00:00:00.000'),
		revenue: 64 * 45 * 33,
	},
	{
		Date: new Date('2020-05-01T00:00:00.000'),
		revenue: 69 * 43 * 32,
	},
	{
		Date: new Date('2020-06-01T00:00:00.000'),
		revenue: 75 * 39 * 39,
	},
	{
		Date: new Date('2020-07-01T00:00:00.000'),
		revenue: 82 * 44 * 37,
	},
	{
		Date: new Date('2020-08-01T00:00:00.000'),
		revenue: 89 * 41 * 31,
	},
	{
		Date: new Date('2020-09-01T00:00:00.000'),
		revenue: 97 * 42 * 35,
	},
	{
		Date: new Date('2020-10-01T00:00:00.000'),
		revenue: 105 * 38 * 34,
	},
	{
		Date: new Date('2020-11-01T00:00:00.000'),
		revenue: 114 * 35 * 31,
	},
	{
		Date: new Date('2020-12-01T00:00:00.000'),
		revenue: 126 * 37 * 38,
	},
];

const profitPerWeekData = [
	{
		Date: new Date('2020-01-01T00:00:00.000'),
		profit: 48 * 37 * 32 * 0.05,
	},
	{
		Date: new Date('2020-02-01T00:00:00.000'),
		profit: 53 * 41 * 30 * 0.1,
	},
	{
		Date: new Date('2020-03-01T00:00:00.000'),
		profit: 58 * 36 * 38 * 0.15,
	},
	{
		Date: new Date('2020-04-01T00:00:00.000'),
		profit: 64 * 45 * 33 * 0.12,
	},
	{
		Date: new Date('2020-05-01T00:00:00.000'),
		profit: 69 * 43 * 32 * 0.2,
	},
	{
		Date: new Date('2020-06-01T00:00:00.000'),
		profit: 75 * 39 * 39 * 0.18,
	},
	{
		Date: new Date('2020-07-01T00:00:00.000'),
		profit: 82 * 44 * 37 * 0.1,
	},
	{
		Date: new Date('2020-08-01T00:00:00.000'),
		profit: 89 * 41 * 31 * 0.13,
	},
	{
		Date: new Date('2020-09-01T00:00:00.000'),
		profit: 97 * 42 * 35 * 0.12,
	},
	{
		Date: new Date('2020-10-01T00:00:00.000'),
		profit: 105 * 38 * 34 * 0.09,
	},
	{
		Date: new Date('2020-11-01T00:00:00.000'),
		profit: 114 * 35 * 31 * 0.16,
	},
	{
		Date: new Date('2020-12-01T00:00:00.000'),
		profit: 126 * 37 * 38 * 0.12,
	},
];

const EmployeesManHoursSeries = [
	{
		type: 'column' as SeriesType,
		data: staffData,
		name: 'Number of Staff',
		field: 'staffNum',
	},
	{
		type: 'line' as SeriesType,
		data: hoursData,
		name: 'Total Work Hours',
		field: 'hours',
	},
];

const EmployeesManhoursValueAxis = [
	{
		name: 'staffNum',
		color: '#007eff',
	},
	{
		name: 'hours',
		color: '#73c100',
	},
];

const RevenueProfitPerClientSeries = [
	{
		type: 'column' as SeriesType,
		data: profitPerWeekData,
		name: 'Profit Per Week',
		field: 'profit',
	},
	{
		type: 'line' as SeriesType,
		data: revenuePerWeekData,
		name: 'Revenue Per Week',
		field: 'revenue',
	},
];

const PerClientRevenueValueAxis = [
	{
		name: 'profit',
		color: '#007eff',
	},
	{
		name: 'revenue',
		color: '#73c100',
	},
];

const AxisCrossingValue = [0, 12];

const EmployeeSharePerClientData = {
	jan: [
		{
			share: 4,
			kind: 'Client 1',
		},
		{
			share: 19,
			kind: 'Client 2',
		},
		{
			share: 10,
			kind: 'Client 3',
		},
		{
			share: 3,
			kind: 'Client 4',
		},
		{
			share: 8,
			kind: 'Client 5',
		},
		{
			share: 13,
			kind: 'Client 6',
		},
	],
	feb: [
		{
			share: 19,
			kind: 'Client 1',
		},
		{
			share: 15,
			kind: 'Client 2',
		},
		{
			share: 18,
			kind: 'Client 3',
		},
		{
			share: 2,
			kind: 'Client 4',
		},
		{
			share: 5,
			kind: 'Client 5',
		},
		{
			share: 11,
			kind: 'Client 6',
		},
	],
	mar: [
		{
			share: 11,
			kind: 'Client 1',
		},
		{
			share: 2,
			kind: 'Client 2',
		},
		{
			share: 13,
			kind: 'Client 3',
		},
		{
			share: 16,
			kind: 'Client 4',
		},
		{
			share: 4,
			kind: 'Client 5',
		},
		{
			share: 19,
			kind: 'Client 6',
		},
	],
	apr: [
		{
			share: 3,
			kind: 'Client 1',
		},
		{
			share: 16,
			kind: 'Client 2',
		},
		{
			share: 12,
			kind: 'Client 3',
		},
		{
			share: 12,
			kind: 'Client 4',
		},
		{
			share: 15,
			kind: 'Client 5',
		},
		{
			share: 6,
			kind: 'Client 6',
		},
	],
	may: [
		{
			share: 2,
			kind: 'Client 1',
		},
		{
			share: 8,
			kind: 'Client 2',
		},
		{
			share: 6,
			kind: 'Client 3',
		},
		{
			share: 14,
			kind: 'Client 4',
		},
		{
			share: 5,
			kind: 'Client 5',
		},
		{
			share: 6,
			kind: 'Client 6',
		},
	],
	jun: [
		{
			share: 6,
			kind: 'Client 1',
		},
		{
			share: 12,
			kind: 'Client 2',
		},
		{
			share: 18,
			kind: 'Client 3',
		},
		{
			share: 10,
			kind: 'Client 4',
		},
		{
			share: 5,
			kind: 'Client 5',
		},
		{
			share: 7,
			kind: 'Client 6',
		},
	],
	jul: [
		{
			share: 4,
			kind: 'Client 1',
		},
		{
			share: 12,
			kind: 'Client 2',
		},
		{
			share: 12,
			kind: 'Client 3',
		},
		{
			share: 12,
			kind: 'Client 4',
		},
		{
			share: 20,
			kind: 'Client 5',
		},
		{
			share: 9,
			kind: 'Client 6',
		},
	],
	aug: [
		{
			share: 8,
			kind: 'Client 1',
		},
		{
			share: 6,
			kind: 'Client 2',
		},
		{
			share: 9,
			kind: 'Client 3',
		},
		{
			share: 10,
			kind: 'Client 4',
		},
		{
			share: 15,
			kind: 'Client 5',
		},
		{
			share: 11,
			kind: 'Client 6',
		},
	],
	sep: [
		{
			share: 13,
			kind: 'Client 1',
		},
		{
			share: 13,
			kind: 'Client 2',
		},
		{
			share: 1,
			kind: 'Client 3',
		},
		{
			share: 11,
			kind: 'Client 4',
		},
		{
			share: 4,
			kind: 'Client 5',
		},
		{
			share: 17,
			kind: 'Client 6',
		},
	],
	oct: [
		{
			share: 4,
			kind: 'Client 1',
		},
		{
			share: 8,
			kind: 'Client 2',
		},
		{
			share: 11,
			kind: 'Client 3',
		},
		{
			share: 6,
			kind: 'Client 4',
		},
		{
			share: 7,
			kind: 'Client 5',
		},
		{
			share: 17,
			kind: 'Client 6',
		},
	],
	nov: [
		{
			share: 7,
			kind: 'Client 1',
		},
		{
			share: 15,
			kind: 'Client 2',
		},
		{
			share: 14,
			kind: 'Client 3',
		},
		{
			share: 20,
			kind: 'Client 4',
		},
		{
			share: 14,
			kind: 'Client 5',
		},
		{
			share: 14,
			kind: 'Client 6',
		},
	],
	dec: [
		{
			share: 11,
			kind: 'Client 1',
		},
		{
			share: 7,
			kind: 'Client 2',
		},
		{
			share: 20,
			kind: 'Client 3',
		},
		{
			share: 12,
			kind: 'Client 4',
		},
		{
			share: 5,
			kind: 'Client 5',
		},
		{
			share: 11,
			kind: 'Client 6',
		},
	],
} as MonthData;

const ProfitSharePerClientData = {
	jan: [
		{
			share: 12,
			kind: 'Client 1',
		},
		{
			share: 5,
			kind: 'Client 2',
		},
		{
			share: 13,
			kind: 'Client 3',
		},
		{
			share: 17,
			kind: 'Client 4',
		},
		{
			share: 8,
			kind: 'Client 5',
		},
		{
			share: 9,
			kind: 'Client 6',
		},
	],
	feb: [
		{
			share: 12,
			kind: 'Client 1',
		},
		{
			share: 19,
			kind: 'Client 2',
		},
		{
			share: 13,
			kind: 'Client 3',
		},
		{
			share: 9,
			kind: 'Client 4',
		},
		{
			share: 10,
			kind: 'Client 5',
		},
		{
			share: 14,
			kind: 'Client 6',
		},
	],
	mar: [
		{
			share: 3,
			kind: 'Client 1',
		},
		{
			share: 13,
			kind: 'Client 2',
		},
		{
			share: 10,
			kind: 'Client 3',
		},
		{
			share: 10,
			kind: 'Client 4',
		},
		{
			share: 10,
			kind: 'Client 5',
		},
		{
			share: 3,
			kind: 'Client 6',
		},
	],
	apr: [
		{
			share: 2,
			kind: 'Client 1',
		},
		{
			share: 6,
			kind: 'Client 2',
		},
		{
			share: 9,
			kind: 'Client 3',
		},
		{
			share: 1,
			kind: 'Client 4',
		},
		{
			share: 10,
			kind: 'Client 5',
		},
		{
			share: 7,
			kind: 'Client 6',
		},
	],
	may: [
		{
			share: 3,
			kind: 'Client 1',
		},
		{
			share: 13,
			kind: 'Client 2',
		},
		{
			share: 10,
			kind: 'Client 3',
		},
		{
			share: 10,
			kind: 'Client 4',
		},
		{
			share: 10,
			kind: 'Client 5',
		},
		{
			share: 3,
			kind: 'Client 6',
		},
	],
	jun: [
		{
			share: 12,
			kind: 'Client 1',
		},
		{
			share: 9,
			kind: 'Client 2',
		},
		{
			share: 8,
			kind: 'Client 3',
		},
		{
			share: 5,
			kind: 'Client 4',
		},
		{
			share: 15,
			kind: 'Client 5',
		},
		{
			share: 12,
			kind: 'Client 6',
		},
	],
	jul: [
		{
			share: 16,
			kind: 'Client 1',
		},
		{
			share: 9,
			kind: 'Client 2',
		},
		{
			share: 20,
			kind: 'Client 3',
		},
		{
			share: 9,
			kind: 'Client 4',
		},
		{
			share: 15,
			kind: 'Client 5',
		},
		{
			share: 15,
			kind: 'Client 6',
		},
	],
	aug: [
		{
			share: 5,
			kind: 'Client 1',
		},
		{
			share: 20,
			kind: 'Client 2',
		},
		{
			share: 1,
			kind: 'Client 3',
		},
		{
			share: 20,
			kind: 'Client 4',
		},
		{
			share: 8,
			kind: 'Client 5',
		},
		{
			share: 10,
			kind: 'Client 6',
		},
	],
	sep: [
		{
			share: 9,
			kind: 'Client 1',
		},
		{
			share: 16,
			kind: 'Client 2',
		},
		{
			share: 13,
			kind: 'Client 3',
		},
		{
			share: 5,
			kind: 'Client 4',
		},
		{
			share: 9,
			kind: 'Client 5',
		},
		{
			share: 12,
			kind: 'Client 6',
		},
	],
	oct: [
		{
			share: 13,
			kind: 'Client 1',
		},
		{
			share: 14,
			kind: 'Client 2',
		},
		{
			share: 11,
			kind: 'Client 3',
		},
		{
			share: 2,
			kind: 'Client 4',
		},
		{
			share: 20,
			kind: 'Client 5',
		},
		{
			share: 14,
			kind: 'Client 6',
		},
	],
	nov: [
		{
			share: 12,
			kind: 'Client 1',
		},
		{
			share: 13,
			kind: 'Client 2',
		},
		{
			share: 15,
			kind: 'Client 3',
		},
		{
			share: 19,
			kind: 'Client 4',
		},
		{
			share: 8,
			kind: 'Client 5',
		},
		{
			share: 8,
			kind: 'Client 6',
		},
	],
	dec: [
		{
			share: 4,
			kind: 'Client 1',
		},
		{
			share: 11,
			kind: 'Client 2',
		},
		{
			share: 4,
			kind: 'Client 3',
		},
		{
			share: 10,
			kind: 'Client 4',
		},
		{
			share: 8,
			kind: 'Client 5',
		},
		{
			share: 9,
			kind: 'Client 6',
		},
	],
} as MonthData;
