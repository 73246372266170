import DeleteIcon from '@mui/icons-material/Delete';
import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Persona } from '../../constants/Personas';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { sortByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

export type AdminPersonasProps = {
	userID: string;
	firebaseApi: Pick<FirebaseApi, 'personasByUserID' | 'deletePersona'>;
	handlePersonaOnClick: (persona: Persona) => void;
};

export const AdminPersonas = ({
	firebaseApi,
	userID,
	handlePersonaOnClick,
}: AdminPersonasProps): JSX.Element => {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const [personas, setPersonas] = useState<Persona[]>([]);

	useEffect(() => {
		setLoading(true);
		return firebaseApi.personasByUserID(userID, (personas) => {
			const sortedPersonas = sortByField(personas, 'name');
			setPersonas(sortedPersonas);
			setLoading(false);
		});
	}, [firebaseApi, userID]);

	const renderPersona = (persona: Persona): JSX.Element => {
		const company =
			persona.company.name === '' ? 'No Company' : persona.company.name;
		const site = persona.site.name === '' ? 'No Site' : persona.site.name;
		const contractedTo = persona.contractedTo?.name ?? 'Not Contracted';
		const helperText = `${persona.accountType} - ${company} - ${site} - ${contractedTo}`;
		return (
			<ListItem
				disablePadding
				sx={{
					border: `1px solid ${theme.palette.border.dark}`,
					borderRadius: '4px',
					mb: 2,
				}}
				key={persona.id}
				secondaryAction={
					<IconButton
						edge="end"
						color="primary"
						onClick={async (): Promise<void> =>
							await firebaseApi.deletePersona(userID, persona.id)
						}>
						<DeleteIcon />
					</IconButton>
				}>
				<ListItemButton
					onClick={(): void => handlePersonaOnClick(persona)}>
					<ListItemText
						primary={
							<Typography variant="subtitle1">
								{persona.name}
							</Typography>
						}
						secondary={
							<Typography variant="caption">
								{helperText}
							</Typography>
						}
					/>
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<List dense sx={{ width: '100%', padding: 2 }}>
			{loading ? (
				<LoadingDots />
			) : (
				personas.map((persona) => renderPersona(persona))
			)}
		</List>
	);
};
