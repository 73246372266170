import Close from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import type firebase from 'firebase';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	AccountType,
	Company,
	CompanyTypes,
	Minimal,
	Site,
	UserDetails,
	UserStatusType,
	isCompanyType,
} from '../../constants/Common';
import type { FirebaseApi } from '../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../providers/featureFlags/Provider';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../providers/UserProvider';
import { sortObjectByField } from '../helpers/sortHelpers';
import { capitaliseWords } from '../helpers/stringHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../SnackBar/SnackBar';
import { CreateNewCompanyDialog } from './CreateCompanyDialog';
import { NewUserInfo } from './NewUserInfo';
import { SignInUpWrapper } from './SignInUpWrapper';

type CompanySiteDetails = Pick<
	UserDetails,
	| 'company'
	| 'companyID'
	| 'siteCompany'
	| 'siteCompanyID'
	| 'site'
	| 'siteID'
>;

export type NewUserCompanyInfo = {
	companyID: string;
	siteID: string;
	companyType: string;
};

type CompanyInfoInputFields = Pick<NewUserCompanyInfo, 'companyID' | 'siteID'>;

export type CompanyInformationProps = {
	setNewUserCompanyInfo: (newUserCompanyInfo: NewUserCompanyInfo) => void;
	newUserAndCompanyInfo: NewUserInfo & NewUserCompanyInfo;
	accountInformationProvided: boolean;
	subscriptionEnabled: boolean;
	firebaseApi: Pick<
		FirebaseApi,
		| 'companiesSubscription'
		| 'getActiveSites'
		| 'getActiveSitesByCompany'
		| 'createCompany'
		| 'createUser'
		| 'getNoPictureUrl'
	>;
	onSignOut: () => void;
};

const AUTH_STATE = 'companyInfo';

export const CompanyInformation = ({
	newUserAndCompanyInfo,
	setNewUserCompanyInfo,
	accountInformationProvided,
	subscriptionEnabled,
	firebaseApi,
	onSignOut,
}: CompanyInformationProps): JSX.Element => {
	const navigate = useNavigate();
	const userAuth = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const featureFlags = useFeatureFlagContext();
	const allowCreateCompany = featureFlags.get('createCompanyWeb');
	const userReadableFieldNames: CompanyInfoInputFields = {
		companyID: 'Company',
		siteID: 'Site',
	} as const;
	const [loading, setLoading] = useState<boolean>(true);
	const [sites, setSites] = useState<Record<string, Site>>({});
	const [requestConfirmedDialog, setRequestConfirmedDialog] =
		useState<boolean>(false);
	const [companies, setCompanies] = useState<Record<string, Company>>({});
	const [newCompanyName, setNewCompanyName] = useState<string>('');
	const [newCompanyType, setNewCompanyType] = useState<string>('');
	const [showCreateNewCompany, setShowCreateNewCompany] =
		useState<boolean>(false);
	const [newCompanyCreated, setNewCompanyCreated] = useState<boolean>(false);
	const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
	const [nameError, setNameError] = useState<string>('');
	const [typeError, setTypeError] = useState<string>('');
	const [newUserCompany, setNewUserCompany] = useState<NewUserCompanyInfo>({
		companyID: userDetails?.companyID ?? newUserAndCompanyInfo.companyID,
		siteID: userDetails?.siteID ?? newUserAndCompanyInfo.siteID,
		companyType:
			companies[userDetails?.companyID ?? '']?.companyType ??
			newUserAndCompanyInfo.companyType,
	});
	const [inputErrors, setInputErrors] = useState<CompanyInfoInputFields>({
		companyID: '',
		siteID: '',
	});

	useEffect(() => {
		if (!userDetails) return;

		setNewUserCompany({
			companyID: userDetails.companyID,
			siteID: userDetails.siteID,
			companyType: companies[userDetails?.companyID ?? '']?.companyType,
		});
	}, [companies, userDetails]);

	useEffect(() => {
		setNewUserCompanyInfo(newUserCompany);
	}, [newUserCompany, setNewUserCompanyInfo]);

	useEffect(() => {
		let companiesSub = (): void => undefined;
		if (userAuth !== null) {
			companiesSub = firebaseApi.companiesSubscription(
				(querySnapshot) => {
					const unsortedCompanies: Record<string, Company> = {};
					querySnapshot.forEach((docSnapshot) => {
						const data = docSnapshot.data() as Company;
						unsortedCompanies[docSnapshot.id] = {
							...data,
							id: docSnapshot.id,
						};
					});
					const sortedCompanies = sortObjectByField(
						unsortedCompanies,
						'name',
					);
					setCompanies(sortedCompanies);
					setLoading(false);
				},
			);
		}
		return companiesSub;
	}, [userAuth, firebaseApi]);

	const getActiveSites = useCallback(async () => {
		const siteDocs = await firebaseApi.getActiveSites();
		setSitesFromDocs(siteDocs);
	}, [firebaseApi]);

	const getActiveSitesByCompanyID = useCallback(
		async (companyID: string) => {
			const siteDocs = await firebaseApi.getActiveSitesByCompany(
				companyID,
			);
			setSitesFromDocs(siteDocs);
		},
		[firebaseApi],
	);

	const setSitesFromDocs = (
		siteDocs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
	): void => {
		const siteList: Record<string, Site> = {};
		siteDocs.forEach((docSnapshot) => {
			const data = docSnapshot.data() as Site;
			siteList[docSnapshot.id] = {
				...data,
				id: docSnapshot.id,
			};
		});
		const sortedSites = sortObjectByField(siteList, 'name');
		setSites(sortedSites);
	};

	useEffect(() => {
		if (
			companies[newUserCompany.companyID]?.companyType ===
			CompanyTypes.recruitment
		) {
			getActiveSites();
		} else if (
			companies[newUserCompany.companyID]?.companyType ===
			CompanyTypes.construction
		) {
			getActiveSitesByCompanyID(newUserCompany.companyID);
		}
	}, [
		newUserCompany.companyID,
		companies,
		getActiveSitesByCompanyID,
		getActiveSites,
	]);

	useEffect(() => {
		if (userDetails === null || sites[userDetails.siteID] === undefined) {
			return;
		}
		setNewUserCompany((prevState) => ({
			...prevState,
			siteID: userDetails.siteID,
		}));
	}, [userDetails, sites]);

	const validateNewCompany = (): boolean => {
		const errorMap: Partial<NewUserCompanyInfo> = {};
		if (newCompanyName === '') {
			errorMap.companyID = 'Please enter a Company';
			setNameError(errorMap.companyID);
		}
		if (newCompanyType === '') {
			errorMap.companyType = 'Please select a Company Type';
			setTypeError(errorMap.companyType);
		}
		return Object.keys(errorMap).length === 0;
	};

	const createNewCompany = async (): Promise<void> => {
		if (validateNewCompany() && isCompanyType(newCompanyType)) {
			const newCompany: Omit<Company, 'id'> = {
				name: newCompanyName,
				companyType: newCompanyType,
			};
			const newCompanyID = await firebaseApi.createCompany(newCompany);
			setNewUserCompany({
				companyID: newCompanyID,
				siteID: '',
				companyType: '',
			});
			setShowCreateNewCompany(false);
			setNewCompanyCreated(true);
			setSnackBarOpen(true);
			setNameError('');
			setTypeError('');
		}
	};

	const handleAccountType = (
		company: string,
	): '' | 'handler' | 'seniorManagement' => {
		let accountType: AccountType;
		if (newCompanyCreated) {
			accountType =
				companies[company].companyType === CompanyTypes.construction
					? 'seniorManagement'
					: 'handler';
		} else {
			accountType = '';
		}
		return accountType;
	};

	const validateNewUserCompanyInput = (): boolean => {
		const newErrors = { ...inputErrors };
		Object.keys(inputErrors).forEach((item) => {
			const key = item as keyof typeof inputErrors;
			const value = newUserCompany[key];
			if (key !== 'siteID' && value === '') {
				newErrors[
					key
				] = `Please enter a ${userReadableFieldNames[key]}`;
			}
		});
		setInputErrors(newErrors);
		return Object.values(newErrors).every(
			(errMessage) => errMessage === '',
		);
	};

	const handleCreateUserDetailsRequest = async (): Promise<void> => {
		if (
			validateNewUserCompanyInput() &&
			userAuth !== null &&
			userAuth.email !== null &&
			userAuth.emailVerified &&
			accountInformationProvided
		) {
			const selectedSite = sites[newUserCompany.siteID];
			const selectedSiteExists = selectedSite !== undefined;
			const selectedCompany = companies[newUserCompany.companyID];
			const companySiteDetails: CompanySiteDetails = selectedSiteExists
				? {
						company: selectedCompany.name,
						companyID: selectedCompany.id,
						site: selectedSite.name,
						siteID: selectedSite.id,
						siteCompany: selectedSite.company,
						siteCompanyID: selectedSite.companyID,
				  }
				: {
						company: selectedCompany.name,
						companyID: selectedCompany.id,
						site: '',
						siteID: '',
						siteCompany: '',
						siteCompanyID: '',
				  };

			const accountType: AccountType = handleAccountType(
				newUserCompany.companyID,
			);

			let contractedTo: UserDetails['contractedTo'];
			// Construction companies employees are contracted to their own company
			// Or if creating a new company
			if (
				selectedCompany.companyType === CompanyTypes.construction ||
				newCompanyCreated
			) {
				contractedTo = {
					id: companySiteDetails.companyID,
					name: companySiteDetails.company,
				};
			} else if (
				// Recruitment Agency employees are contracted to their selected site's company
				selectedCompany.companyType === CompanyTypes.recruitment &&
				selectedSiteExists
			) {
				contractedTo = {
					id: selectedSite.companyID,
					name: selectedSite.company,
				};
			} else {
				contractedTo = null;
			}

			const recentSites: UserDetails['recentSites'] =
				companySiteDetails.siteID === ''
					? []
					: [
							{
								id: companySiteDetails.siteID,
								name: companySiteDetails.site,
								companyName: companySiteDetails.company,
								companyID: companySiteDetails.companyID,
							},
					  ];
			const recentClients: Minimal<Company>[] = contractedTo
				? [contractedTo]
				: [];
			const { firstName, lastName, mobileNumber } = newUserAndCompanyInfo;
			const displayName = `${firstName.trim()} ${lastName.trim()}`;
			const email = userAuth.email;
			const defaultPhotoURL = await firebaseApi.getNoPictureUrl();
			const newUserDetails: Omit<UserDetails, 'userID'> = {
				chargeOut: 0,
				photoURL: defaultPhotoURL,
				firstname: firstName.trim(),
				lastname: lastName.trim(),
				email: email,
				status: UserStatusType.Active,
				accountType: accountType,
				mobileNumber: mobileNumber.trim(),
				signedIn: false,
				workerType: 'Not Selected',
				displayName: capitaliseWords(displayName),
				signInPicNotRequired: false,
				contractedTo: contractedTo,
				recentSites: recentSites,
				recentClients: recentClients,
				disabledNotifications: {
					complianceReport: true,
				},
				...companySiteDetails,
			};

			await firebaseApi.createUser(userAuth.uid, newUserDetails);

			if (accountType === '') {
				setRequestConfirmedDialog(true);
				navigate('/awaiting-approval');
			} else if (subscriptionEnabled) {
				navigate('/subscription-setup');
			} else {
				navigate('/dashboard');
			}
		}
	};

	const handleNewUserUpdate =
		(fieldName: keyof CompanyInfoInputFields) =>
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			const updatedUserCompany = { ...newUserCompany };
			const updatedErrors = { ...inputErrors };

			updatedUserCompany[fieldName] = event.target.value;
			updatedErrors[fieldName] = '';

			if (fieldName === 'companyID') {
				updatedUserCompany['siteID'] = '';
				updatedErrors['siteID'] = '';
			}
			setNewUserCompany(updatedUserCompany);
			setInputErrors(updatedErrors);
		};

	const handleSnackBarClose = (
		_?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackBarOpen(false);
		setShowCreateNewCompany(false);
	};

	const handleDialogClose = (): void => {
		setNameError('');
		setTypeError('');
		setNewCompanyName('');
		setNewCompanyType('');
		setShowCreateNewCompany(false);
	};

	const submitSnackBar = (): JSX.Element => (
		<CustomSnackBar
			open={snackBarOpen}
			onClose={handleSnackBarClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			snackBarText="New Company Submitted"
		/>
	);

	const informationSubmittedDialog = (): JSX.Element => {
		return (
			<Dialog
				open={requestConfirmedDialog}
				onClose={(): void => setRequestConfirmedDialog(false)}>
				<DialogTitle>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-around">
						<Typography variant="h6" paddingRight={2}>
							Information Submitted
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					Your account has been created and is now awaiting approval.
				</DialogContent>
				<DialogActions>
					<IconButton
						onClick={(): void => setRequestConfirmedDialog(false)}>
						<Close color="primary" />
					</IconButton>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			<SignInUpWrapper
				title="Select Company"
				authState={AUTH_STATE}
				back={{
					label: 'Back',
					handle: (): void => {
						navigate('/account-information');
					},
				}}
				next={{
					label:
						userDetails === null
							? 'Submit Account Information'
							: 'Next',
					handle: handleCreateUserDetailsRequest,
				}}
				onSignOut={onSignOut}>
				{informationSubmittedDialog()}
				{submitSnackBar()}
				<CreateNewCompanyDialog
					openDialog={showCreateNewCompany}
					companyName={newCompanyName}
					companyNameOnChange={setNewCompanyName}
					nameError={nameError}
					companyType={newCompanyType}
					companyTypeOnChange={setNewCompanyType}
					typeError={typeError}
					handleDialogClose={handleDialogClose}
					handleCreateCompany={createNewCompany}
				/>
				<Grid container spacing={2}>
					{loading ? (
						<LoadingDots />
					) : (
						<>
							<Grid item xs={12}>
								<TextField
									value={newUserCompany.companyID}
									label="Company (required)"
									fullWidth
									onChange={handleNewUserUpdate('companyID')}
									select
									helperText={inputErrors.companyID}
									error={inputErrors.companyID !== ''}
									disabled={
										!userAuth?.emailVerified ||
										Object.keys(companies).length === 0
									}>
									{Object.values(companies).map((company) => (
										<MenuItem
											key={company.id}
											value={company.id}>
											{company.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={newUserCompany.siteID}
									fullWidth
									label={
										Object.keys(sites).length === 0
											? 'No Sites Available'
											: 'Site (optional)'
									}
									onChange={handleNewUserUpdate('siteID')}
									select
									disabled={
										!userAuth?.emailVerified ||
										Object.keys(sites).length === 0
									}>
									{Object.values(sites).map((site) => (
										<MenuItem key={site.id} value={site.id}>
											{site.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							{allowCreateCompany &&
								accountInformationProvided &&
								userDetails === null && (
									<>
										<Grid item xs={12}>
											<Typography
												variant="body1"
												textAlign="center">
												If you can&apos;t find your
												company above and are wanting to
												create a new company please
												click here
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Button
												fullWidth
												onClick={(): void => {
													setShowCreateNewCompany(
														true,
													);
												}}
												disabled={!allowCreateCompany}>
												Create new company
											</Button>
										</Grid>
									</>
								)}
							{userDetails && userDetails.company !== '' && (
								<Grid item xs={12}>
									<Typography textAlign="center" variant="h6">
										Currently awaiting approval
									</Typography>
									<Typography
										textAlign="center"
										variant="body2">
										You can continue to submit changes while
										awaiting approval
									</Typography>
								</Grid>
							)}
						</>
					)}
				</Grid>
			</SignInUpWrapper>
		</>
	);
};
