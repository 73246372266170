import { Stack, Box, Button } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useState } from 'react';
import { Document } from '../../../constants/Document';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { nameCustomTableSort } from '../../helpers/muiDataTableCustomSorts';
import { DocumentStatusIcon } from '../../LeaveReview/DocumentStatusIcon';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { DocumentsTableTheme } from '../TableWrappers/DocumentsTableWrapper';
import { ViewDocumentDialog } from './ViewDocumentDialog';

export type AllDocumentsProps = {
	selected: string | null;
	setSelected: (selected: string) => void;
	loading: boolean;
	documentsData: Document[];
	storageApi: Pick<FirebaseApi, 'downloadStorageFile'>;
};

export const AllDocuments = ({
	selected,
	setSelected,
	loading,
	documentsData,
	storageApi,
}: AllDocumentsProps): JSX.Element => {
	const [currentDocumentID, setCurrentDocumentID] = useState<string | null>(
		null,
	);

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no documents found'
	);
	const numCells = 6;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;

	const currentDocument = documentsData.find(
		(document) => document.id === currentDocumentID,
	);

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Document',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.7),
					},
				}),
			},
		},
		{
			name: 'employee',
			label: 'Recipient',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.7),
					},
				}),
				customBodyRender: (employee: Document['employee']) =>
					employee.name,
				sortCompare: nameCustomTableSort,
			},
		},
		{
			name: 'createdAt',
			label: 'Date Offered',
			options: {
				customBodyRender: (date: Document['createdAt']) =>
					formatSlashedDate(date.toDate()),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.6),
						whiteSpace: 'nowrap',
					},
				}),
			},
		},
		{
			name: 'signedAt',
			label: 'Date Signed',
			options: {
				customBodyRender: (date: Document['signedAt']) =>
					date ? formatSlashedDate(date.toDate()) : 'Not Signed',
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.6),
						whiteSpace: 'nowrap',
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(0.4),
					},
				}),
				customBodyRender: (status: Document['status']) => (
					<Box display="flex" justifyContent="center">
						<DocumentStatusIcon documentStatus={status} />
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
				customBodyRender: (id): JSX.Element => (
					<Stack direction="row" spacing={1} width="100%" pr={1}>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="outlined"
								onClick={(): Promise<void> => pdfOnClick(id)}>
								PDF
							</Button>
						</Box>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="contained"
								onClick={(): void => viewOnClick(id)}>
								open
							</Button>
						</Box>
					</Stack>
				),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const pdfOnClick = async (id: string): Promise<void> => {
		// less performance impact here than making the rendering of the table more complex
		const document = documentsData.find((doc) => doc.id === id);
		if (!document) return;

		await storageApi.downloadStorageFile(
			`${id}.pdf`,
			'documents',
			`${document.name} - ${document.employee.name}.pdf`,
		);
	};

	const viewOnClick = (id: string): void => {
		setCurrentDocumentID(id);
	};

	const onClose = (): void => {
		setCurrentDocumentID(null);
	};

	return (
		<>
			{currentDocument && (
				<ViewDocumentDialog
					open={!!currentDocumentID}
					document={currentDocument}
					handleOnClose={onClose}
					onPDF={pdfOnClick}
				/>
			)}
			<DocumentsTableTheme>
				<DateDataTableWithID
					tableData={documentsData}
					columns={columns}
					title="Documents"
					customTableOptions={tableOptions}
					selection={[selected, setSelected]}
				/>
			</DocumentsTableTheme>
		</>
	);
};
