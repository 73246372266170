import { Box, Card, Typography } from '@mui/material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { UserDetails } from '../../../../constants/Common';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import {
	EmployeeIntegrationTableRow,
	EmploymentType,
	getLinkStatus,
	isPayrollEmployeeLink,
	PayrollIntegrationEmployee,
	PayrollIntegrationEmployeeLink,
	TempEmployeeLink,
} from '../../../../models/Integrations/PayrollIntegration';
import { linkTableSort } from '../../../helpers/muiDataTableCustomSorts';
import { ImportCSVButton } from '../../../ImportCSVButton/ImportCSVButton';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { employeeIntegrationTableRowSearch } from '../../Components/Helpers/employeeIntegrationTableRowSearch';
import { IntegrationLinkButton } from '../../Components/IntegrationLinkButton/IntegrationLinkButton';
import {
	IntegrationLinkCell,
	OptionWithDisabled,
} from '../../Components/IntegrationLinkCell/IntegrationLinkCell';
import {
	IntegrationLinkStatus,
	IntegrationStatusChip,
} from '../../Components/IntegrationStatusChip/IntegrationStatusChip';
import { IntegrationTable } from '../../Components/IntegrationTable/IntegrationTable';

export type SmartlyPayrollEmployeesTabProps = {
	user: User;
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'subscribeNonKioskUsersByCompany'
		| 'payrollIntegrationEmployeeLinkSubscription'
		| 'createPayrollEmployeeLink'
		| 'deletePayrollEmployeeLink'
		| 'saveCSVFileToStorage'
	>;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'fetchPayrollEmployeeIDs' | 'payrollEmployeeImport'
	>;
};

type PayrollEmployeeOptions = OptionWithDisabled<{
	employmentType: EmploymentType;
}>;

const importHelperText = (
	<>
		<Typography textAlign="center">Upload CSV from Smartly</Typography>
		<Typography textAlign="center" variant="subtitle1">
			You can download the CSV through the Smartly reports generator
			choosing the employee category and employee card report.
		</Typography>
	</>
);

export const SmartlyPayrollEmployeesTab = ({
	user,
	userDetails,
	firebaseApi,
	cloudFunctionApi,
}: SmartlyPayrollEmployeesTabProps): JSX.Element => {
	const abortSignal = useAbortController();

	const [employees, setEmployees] = useState<Record<string, UserDetails>>({});
	const [integrationEmployees, setIntegrationEmployees] = useState<
		Record<string, PayrollIntegrationEmployee>
	>({});
	const [employeeLinks, setEmployeeLinks] = useState<
		Record<string, PayrollIntegrationEmployeeLink>
	>({});
	const [newLinks, setNewLinks] = useState<
		Record<string, PayrollIntegrationEmployeeLink>
	>({});
	const [tableData, setTableData] = useState<EmployeeIntegrationTableRow[]>(
		[],
	);
	const [selected, setSelected] = useState<string[]>([]);
	const [loading, setLoading] = useState({
		employees: true,
		employeeLinks: true,
		integrationEmployees: true,
	});
	const [linkingRecords, setLinkingRecords] = useState<
		Record<string, boolean>
	>({});

	const [importLoading, setImportLoading] = useState<boolean>(false);
	const [errorSnackBarOpen, setErrorSnackBarOpen] = useState<boolean>(false);

	const numCells = 4;
	const cellWidth = { width: `${100 / numCells}%` };
	const cellWidthExtra = `${(100 / numCells) * 1.25}%`;
	const cellWidthLess = `${(100 / numCells) * 0.75}%`;

	const fetchEmployeeIDs = useCallback(async (): Promise<void> => {
		setLoading((prev) => ({ ...prev, integrationEmployees: true }));
		const employeeIDs = await cloudFunctionApi.fetchPayrollEmployeeIDs(
			abortSignal,
			user,
		);

		const integrationEmployeeRecord = employeeIDs.employees.reduce<
			Record<string, PayrollIntegrationEmployee>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: current,
			}),
			{},
		);

		setIntegrationEmployees(integrationEmployeeRecord);
		setLoading((prev) => ({ ...prev, integrationEmployees: false }));
	}, [abortSignal, cloudFunctionApi, user]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, employees: true }));
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			(users) => {
				const employeeRecord = users.reduce<
					Record<string, UserDetails>
				>(
					(prev, current) => ({ ...prev, [current.userID]: current }),
					{},
				);

				setEmployees(employeeRecord);
				setLoading((prev) => ({ ...prev, employees: false }));
			},
		);
	}, [firebaseApi, userDetails]);

	useEffect(() => {
		fetchEmployeeIDs();
	}, [fetchEmployeeIDs]);

	useEffect(() => {
		if (userDetails.companyID === '') {
			return;
		}
		setLoading((prev) => ({ ...prev, employeeLinks: true }));
		return firebaseApi.payrollIntegrationEmployeeLinkSubscription(
			userDetails.companyID,
			(links) => {
				setEmployeeLinks(links);
				setLoading((prev) => ({ ...prev, employeeLinks: false }));
			},
		);
	}, [firebaseApi, userDetails]);

	useEffect(() => {
		const tableData = Object.values(employees).map((employee) =>
			mapTableData(
				employee,
				employeeLinks,
				newLinks,
				integrationEmployees,
			),
		);

		const selectedData: string[] = [];
		tableData.forEach((row) => {
			if (isPayrollEmployeeLink(row.account)) {
				if (row.account.integrationID) {
					selectedData.push(row.account.integrationID);
				}
			}
		});

		setTableData(tableData);
		setSelected(selectedData);
	}, [employeeLinks, employees, integrationEmployees, newLinks]);

	const handleCSVImport = async (file: File | null): Promise<void> => {
		if (!file) return;
		setImportLoading(true);
		// Save csv data to firebase storage
		const fileName = await firebaseApi.saveCSVFileToStorage(
			file,
			userDetails.companyID,
		);
		// Import that csv data into our firestore
		const response = await cloudFunctionApi.payrollEmployeeImport(
			abortSignal,
			user,
			fileName,
		);
		if (response) {
			// Fetch the new employee data
			await fetchEmployeeIDs();
			setErrorSnackBarOpen(false);
		} else {
			setErrorSnackBarOpen(true);
		}
		setImportLoading(false);
	};

	const handleErrorSnackBarClose = (): void => {
		setErrorSnackBarOpen(false);
	};

	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const mapTableData = (
		employee: UserDetails,
		links: Record<string, PayrollIntegrationEmployeeLink>,
		newLinks: Record<string, PayrollIntegrationEmployeeLink>,
		integrationEmployees: Record<string, PayrollIntegrationEmployee>,
	): EmployeeIntegrationTableRow => {
		const link: PayrollIntegrationEmployeeLink | null =
			links[employee.userID] ?? null;
		const integrationEmployee =
			integrationEmployees[link?.integrationID] ?? null;

		const status = getLinkStatus(link, integrationEmployee);

		return {
			id: employee.userID,
			name: employee.displayName,
			status: status,
			account: link ??
				newLinks[employee.userID] ?? { id: employee.userID },
		};
	};

	const createLink = async (
		link: PayrollIntegrationEmployeeLink,
	): Promise<void> => {
		await firebaseApi.createPayrollEmployeeLink(
			userDetails.companyID,
			link,
		);
	};

	const deleteLink = async (
		link: PayrollIntegrationEmployeeLink,
	): Promise<void> => {
		await firebaseApi.deletePayrollEmployeeLink(
			userDetails.companyID,
			link.id,
		);
	};

	const triggerRefresh = async (): Promise<void> => await fetchEmployeeIDs();

	const onLinkCellChange =
		(
			employeeLink: TempEmployeeLink,
			employee: UserDetails,
			options: Record<string, PayrollEmployeeOptions>,
		) =>
		(integrationID?: string): void => {
			if (integrationID === undefined) {
				setNewLinks((prev) => {
					const { [employeeLink.id]: _, ...remaining } = prev;
					return remaining;
				});
				return;
			}

			const integrationEmployee = options[integrationID];

			if (integrationEmployee === undefined) {
				return;
			}

			setNewLinks((prev) => ({
				...prev,
				[employeeLink.id]: {
					id: employee.userID,
					name: employee.displayName,
					integrationID: integrationID,
					integrationName: integrationEmployee.name,
					employmentType:
						integrationEmployee.metadata?.employmentType ??
						EmploymentType.Employee,
				},
			}));
		};

	const renderOptionButton = (employeeID: string): JSX.Element => {
		const link: PayrollIntegrationEmployeeLink | undefined =
			employeeLinks[employeeID];
		const newLink: PayrollIntegrationEmployeeLink | undefined =
			newLinks[employeeID];

		const handleCreateLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [employeeID]: true }));
			await createLink(newLink);
			setNewLinks((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
			setLinkingRecords((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
		};
		const handleDeleteLink = async (): Promise<void> => {
			setLinkingRecords((prev) => ({ ...prev, [employeeID]: true }));
			await deleteLink(link);
			setLinkingRecords((prev) => {
				const { [employeeID]: _, ...remaining } = prev;
				return remaining;
			});
		};

		const disabled = !link && newLink === undefined;

		return (
			<IntegrationLinkButton
				link={link}
				disabled={disabled}
				handleLinkClick={handleCreateLink}
				handleUnlinkClick={handleDeleteLink}
			/>
		);
	};

	const renderAccount = (employeeLink: TempEmployeeLink): JSX.Element => {
		const trueLink = employeeLinks[employeeLink.id];
		const employee = employees[employeeLink.id];
		const options = Object.values(integrationEmployees).reduce<
			Record<string, PayrollEmployeeOptions>
		>(
			(prev, current) => ({
				...prev,
				[current.id]: {
					id: current.id,
					name: current.name,
					metadata: { employmentType: current.employmentType },
					disabled: selected.some((id) => id === current.id),
				},
			}),
			{},
		);

		const onChange = onLinkCellChange(employeeLink, employee, options);
		const linking = !!linkingRecords[employeeLink.id];

		return (
			<IntegrationLinkCell
				link={trueLink}
				linkIntegrationID={
					isPayrollEmployeeLink(employeeLink)
						? employeeLink.integrationID
						: undefined
				}
				options={options}
				onChange={onChange}
				missingLinkLabel="Missing Smartly Account"
				missingLinkMessage="Linked item not found in Smartly"
				disabled={linking}
				selectOverride="Select Account"
			/>
		);
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'name',
			label: 'Name',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: (value: IntegrationLinkStatus) => (
					<Box display="flex" justifyContent="center">
						<IntegrationStatusChip status={value} />
					</Box>
				),
			},
		},
		{
			name: 'account',
			label: 'Smartly Account',
			options: {
				sort: true,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthExtra,
					},
				}),
				sortCompare: linkTableSort,
				customBodyRender: (
					employeeLink: PayrollIntegrationEmployeeLink,
				) => (
					<Box display="flex" justifyContent="center">
						{renderAccount(employeeLink)}
					</Box>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				setCellHeaderProps: () => ({
					style: {
						...setCellHeaderProps().style,
						width: cellWidthLess,
					},
				}),
				customBodyRender: renderOptionButton,
			},
		},
	];

	const importButton = importHelperText && (
		<Box display="inline-flex" flex="1">
			<ImportCSVButton
				title="employees"
				callback={handleCSVImport}
				isLoading={importLoading}
			/>
		</Box>
	);

	return (
		<Box flex="1">
			<CustomSnackBar
				open={errorSnackBarOpen}
				onClose={handleErrorSnackBarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				severity="error"
				snackBarText="Failed to Import Employees"
			/>
			<Card>
				<IntegrationTable
					title="Link Employees"
					data={tableData}
					columns={columns}
					triggerRefresh={triggerRefresh}
					loading={
						loading.employeeLinks ||
						loading.employees ||
						loading.integrationEmployees
					}
					missingDataLabel="Sorry, no employees found"
					centeredColumns={[2, 3, 4]}
					customSearch={employeeIntegrationTableRowSearch}
					customToolbarItems={importButton}
				/>
			</Card>
		</Box>
	);
};
