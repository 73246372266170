import { useLayoutEffect, useState } from 'react';

export const useWindowPosition = (
	id: string,
	scrollId: string,
	offsetExtra: number,
): boolean => {
	const [animation, setAnimation] = useState(false);

	useLayoutEffect(() => {
		const element = window.document.getElementById(scrollId);
		const updatePosition = (): void => {
			const offsetTop =
				window.document.getElementById(id)?.offsetTop ?? 0;

			if ((element?.scrollTop ?? 0) > offsetTop - offsetExtra) {
				setAnimation(true);
			} else {
				setAnimation(false);
			}
		};
		element?.addEventListener('scroll', updatePosition);

		return () => {
			element?.removeEventListener('scroll', updatePosition);
		};
	}, [id, offsetExtra, scrollId]);
	return animation;
};
