import { Stack, Typography } from '@mui/material';
import {
	SafetyCourse,
	SafetyCourseSelectOption,
	SafetyCourseType,
	SafetyCourseValidationResponse,
} from '../../../../../constants/SafetyCourse';
import { SafetyCourseValidationCard } from '../../../SafetyCourses/SafetyCourseValidationCard/SafetyCourseValidationCard';
import { NewInductionExistingSafetyCourseSelect } from './NewInductionExistingSafetyCourseSelect';
import { NewInductionSafetyCourseToggle } from './NewInductionSafetyCourseToggle';

export type NewInductionSafetyCourseProps = {
	safetyCourseOption: SafetyCourseSelectOption;
	handleSafetyCourseOptionChange: (option: SafetyCourseSelectOption) => void;
	existingSafetyCourses: Record<string, SafetyCourse>;
	safetyCourseResponse: SafetyCourseValidationResponse | undefined | null;
	handleSafetyCourseResponseChange: (
		option: SafetyCourseValidationResponse | undefined | null,
	) => void;
	handleSafetyCourseValidation: (
		courseType: SafetyCourseType,
		courseID: string,
	) => Promise<void>;
	allowUseExisting: boolean;
	safetyCourseDuplicateAlert?: string;
};

export const NewInductionSafetyCourse = ({
	safetyCourseOption,
	handleSafetyCourseOptionChange,
	existingSafetyCourses,
	safetyCourseResponse,
	handleSafetyCourseResponseChange,
	handleSafetyCourseValidation,
	allowUseExisting,
	safetyCourseDuplicateAlert,
}: NewInductionSafetyCourseProps): JSX.Element => {
	const selectedSafetyCourseID =
		Object.values(existingSafetyCourses).find(
			(safetyCourse) =>
				safetyCourse.course.id === safetyCourseResponse?.id,
		)?.id ?? null;

	const handleExistingCourseChange = (
		_: React.MouseEvent<HTMLElement>,
		newSelectionOption: SafetyCourseSelectOption,
	): void => {
		if (newSelectionOption) {
			handleSafetyCourseOptionChange(newSelectionOption);
		}
	};

	return (
		<Stack spacing={2}>
			<Typography variant="subtitle1">Safety Course Details</Typography>
			{allowUseExisting && (
				<NewInductionSafetyCourseToggle
					alignment={safetyCourseOption}
					handleChange={handleExistingCourseChange}
				/>
			)}
			{safetyCourseOption === SafetyCourseSelectOption.Existing && (
				<NewInductionExistingSafetyCourseSelect
					existingSafetyCourses={existingSafetyCourses}
					selectedSafetyCourseID={selectedSafetyCourseID}
					handleSafetyCourseResponseChange={
						handleSafetyCourseResponseChange
					}
					safetyCourseResponse={safetyCourseResponse}
				/>
			)}
			{safetyCourseOption === SafetyCourseSelectOption.New && (
				<SafetyCourseValidationCard
					validateSafetyCourse={handleSafetyCourseValidation}
					safetyCourse={safetyCourseResponse}
					duplicateAlert={safetyCourseDuplicateAlert}
					showHeader={false}
				/>
			)}
		</Stack>
	);
};
