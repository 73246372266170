import { useRef, useEffect } from 'react';

export const useIsComponentMounted = (): boolean => {
	const isComponentMounted = useRef(false);

	useEffect(() => {
		isComponentMounted.current = true;
		return () => {
			isComponentMounted.current = false;
		};
	}, []);

	return isComponentMounted.current;
};
