import cloudFunctionApi from '../../../../cloudfunctions';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../../providers/UserProvider';
import { LoadingDots } from '../../subcomponents/LoadingDots';
import { NewSite } from './NewSite';

export const NewSiteWrapper = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const user = useUserAuthContext();

	return userDetails === null || user === null ? (
		<LoadingDots />
	) : (
		<NewSite
			userDetails={userDetails}
			user={user}
			cloudFunctionApi={cloudFunctionApi}
			firebaseApi={firebaseApi}
		/>
	);
};
