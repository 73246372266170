import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
	IconButton,
	InputAdornment,
	FormControl,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { useEffect, useState } from 'react';

type SearchInputProps<T> = {
	onChange: (data: T[]) => void;
	field: keyof T;
	data: T[];
	label: string;
};

export default function SearchInput<T>({
	onChange,
	field,
	data,
	label,
}: SearchInputProps<T>): JSX.Element {
	const [search, setSearch] = useState('');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		event.preventDefault();
		setSearch(event.target.value);
	};

	const handleDeleteSearch = (): void => {
		setSearch('');
	};

	useEffect(() => {
		const updated = data.filter((dataEntry) => {
			const value = dataEntry[field];
			if (typeof value === 'string') {
				return value.toLowerCase().includes(search.toLowerCase());
			}
			return false;
		});
		onChange(updated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	return (
		<FormControl>
			<OutlinedInput
				label={label}
				id={label}
				value={search}
				onChange={handleChange}
				startAdornment={
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							color="primary"
							aria-label="delete search value"
							onClick={handleDeleteSearch}
							edge="end"
							disabled={!search}>
							<ClearIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
			<InputLabel htmlFor="filled-adornment-search">Search</InputLabel>
		</FormControl>
	);
}
