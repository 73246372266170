import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
	SafetyCourseSelectOption,
	safetyCourseSelectOptionValues,
} from '../../../../../constants/SafetyCourse';

export type NewInductionSafetyCourseToggleProps = {
	alignment: string;
	handleChange: (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: SafetyCourseSelectOption,
	) => void;
};

export const NewInductionSafetyCourseToggle = ({
	alignment,
	handleChange,
}: NewInductionSafetyCourseToggleProps): JSX.Element => {
	return (
		<ToggleButtonGroup
			color="primary"
			value={alignment}
			exclusive
			onChange={handleChange}
			sx={{
				// this styling is fragile as it only works for the two buttons
				['.MuiToggleButtonGroup-grouped:not(:first-of-type)']: {
					borderLeftColor: 'primary.main',
				},
			}}
			size="small">
			{Object.entries(safetyCourseSelectOptionValues).map(
				([key, value]) => (
					<ToggleButton
						value={key}
						key={key}
						sx={{
							borderColor:
								alignment === key ? 'primary.main' : undefined,
						}}>
						{value}
					</ToggleButton>
				),
			)}
		</ToggleButtonGroup>
	);
};
