import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	Paper,
	Typography,
	ClickAwayListener,
	Tooltip,
	IconButton,
	Grid,
} from '@mui/material';
import { useState } from 'react';

export const WorkbenchFieldItem = (props: {
	field: string;
	value: string;
}): JSX.Element => {
	const [copiedText, setCopiedText] = useState(false);
	return (
		<Paper key={props.field}>
			<Grid
				container
				alignItems="center"
				paddingX={2}
				height="60px"
				data-testid={`${props.field}-row`}>
				<Grid item xs={3}>
					<Typography variant="body1Bold">{props.field}</Typography>
				</Grid>
				<Grid item xs={8}>
					{props.value}
				</Grid>
				<Grid item xs={1}>
					<ClickAwayListener
						onClickAway={(): void => setCopiedText(false)}>
						<Tooltip
							placement="left"
							title="Copied!"
							open={copiedText}
							onClose={(): void => setCopiedText(false)}
							arrow
							disableFocusListener
							disableHoverListener
							disableTouchListener>
							<IconButton
								onClick={(): Promise<void> => {
									setCopiedText(true);
									return navigator.clipboard.writeText(
										props.value,
									);
								}}>
								<ContentCopyIcon color="primary" />
							</IconButton>
						</Tooltip>
					</ClickAwayListener>
				</Grid>
			</Grid>
		</Paper>
	);
};
