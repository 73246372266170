import { Box, Typography } from '@mui/material';
import TradeLegionLogo from '../../../../src/images/birdonlyblack-transparentbackground.png';

export const InitialNewDocumentView = (): JSX.Element => {
	return (
		<>
			<Typography variant="h5" textAlign="center" paddingTop={2}>
				Select Template To Begin
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				maxHeight="calc(100% - 32px)"
				minHeight="calc(100% - 32px)">
				<Box
					component="img"
					src={TradeLegionLogo}
					alt="Trade Legion"
					maxHeight="75%"
					maxWidth="75%"
					sx={{
						opacity: '25%',
					}}
				/>
			</Box>
		</>
	);
};
