import {
	startOfWeek,
	subWeeks,
	endOfWeek,
	endOfDay,
	startOfDay,
} from 'date-fns';
import { useEffect, useReducer, useState } from 'react';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';
import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { formatSearchParamsDate } from '../helpers/dateFormatters';
import { Reports } from './Reports';
import { ReportsTableTheme } from './ReportsTableTheme';
import {
	ReportsContextFirebaseCalls,
	ReportsOutletContext,
} from './StateManagement/models';
import { createReportsReducer } from './StateManagement/reportsReducer';

export const ReportsPageWrapper = ({
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [hasUpdatedSearchParams, setHasUpdatedSearchParams] = useState(true);
	const paramStartDate = searchParams.get('startDate');
	const paramEndDate = searchParams.get('endDate');

	const [context, dispatch] = useReducer(createReportsReducer, {
		firebaseApi,
		startDate: startOfWeek(subWeeks(new Date(), 1)),
		endDate: endOfWeek(subWeeks(new Date(), 1)),
		accountType: userDetails.accountType,
	});

	useEffect(() => {
		// Set the context date to be updated.
		setSearchParams((prev) => {
			prev.set('startDate', formatSearchParamsDate(context.startDate));
			return prev;
		});
		setHasUpdatedSearchParams(true);
	}, [context.startDate, setSearchParams]);

	useEffect(() => {
		// Here we only update the context date if the search params date has had an update.
		// This prevents an update loop cycle.
		if (hasUpdatedSearchParams) {
			if (paramStartDate) {
				dispatch({
					key: 'startDate',
					value: (prevStartDate) => {
						const startDate = startOfDay(new Date(paramStartDate));
						return startDate.getTime() !== prevStartDate.getTime()
							? startDate
							: prevStartDate;
					},
				});
			} else {
				const lastMonday = startOfWeek(subWeeks(new Date(), 1));
				dispatch({
					key: 'startDate',
					value: lastMonday,
				});
			}
			setHasUpdatedSearchParams(false);
		}
	}, [searchParams, hasUpdatedSearchParams, paramStartDate]);

	useEffect(() => {
		setSearchParams((prev) => {
			prev.set('endDate', formatSearchParamsDate(context.endDate));
			return prev;
		});
		setHasUpdatedSearchParams(true);
	}, [context.endDate, setSearchParams]);

	useEffect(() => {
		// Same as update check as startDate
		if (hasUpdatedSearchParams) {
			if (paramEndDate) {
				dispatch({
					key: 'endDate',
					value: (prevStartDate) => {
						const endDate = endOfDay(new Date(paramEndDate));
						return endDate.getTime() !== prevStartDate.getTime()
							? endDate
							: prevStartDate;
					},
				});
			} else {
				const lastSunday = endOfWeek(subWeeks(new Date(), 1));
				dispatch({
					key: 'endDate',
					value: lastSunday,
				});
			}
			setHasUpdatedSearchParams(false);
		}
	}, [searchParams, paramEndDate, hasUpdatedSearchParams]);

	return (
		<ReportsTableTheme>
			<>
				<Reports userDetails={userDetails} />
				<Outlet context={{ context, dispatch }} />
			</>
		</ReportsTableTheme>
	);
};

/** Gets the reducer state and dispatch function required by children of timesheetLog
 *
 * The optional Type parameter T is used to narrow the required functions of firebaseApi inside context*/
export const useReportsContext = <
	T extends keyof ReportsContextFirebaseCalls,
>(): ReportsOutletContext<T> => useOutletContext<ReportsOutletContext<T>>();
