import { Box } from '@mui/material';
import { CSSProperties, useState } from 'react';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

export type LoadableImageProps = {
	src: string;
	srcLoading: boolean;
	altText: string;
	imageStyle?: CSSProperties;
};

/**
 * Annoyingly this is a thing but didn't want the
 * extra container the other Image component makes
 */
export const LoadableImage = ({
	src,
	srcLoading,
	altText,
	imageStyle,
}: LoadableImageProps): JSX.Element => {
	const [loading, setLoading] = useState(true);

	const imageLoaded = (): void => {
		setLoading(false);
	};
	return srcLoading ? (
		<LoadingDots
			style={{
				height: 'auto',
				minHeight: '102px',
				...imageStyle,
			}}
		/>
	) : (
		<>
			<LoadingDots
				style={{
					height: 'auto',
					minHeight: '102px',
					...imageStyle,
					display: loading ? 'flex' : 'none',
				}}
			/>
			<Box
				component="img"
				alt={altText}
				src={src}
				sx={{
					width: '100%',
					borderRadius: '4px',
					...imageStyle,
					display: loading ? 'none' : 'flex',
				}}
				onLoad={imageLoaded}
			/>
		</>
	);
};
