import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Grid,
	Stack,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import firebaseApi from '../../firebase/firebaseApi';
import { IntegrationType } from '../../models/Integrations/Integration';
import Image from '../Image/Image';

export type IntegrationItem<T extends IntegrationType> = {
	name: string;
	id: T;
	link: string;
};

type IntegrationsSetupProps<T extends IntegrationType> = {
	integrationTypes: Partial<Record<T, IntegrationItem<T>>>;
	description: string | JSX.Element;
};

export const IntegrationsSetup = <T extends IntegrationType>({
	integrationTypes,
	description,
}: IntegrationsSetupProps<T>): JSX.Element => {
	const navigate = useNavigate();

	const [integrationLogos, setIntegrationLogos] = useState<
		Partial<Record<T, string>>
	>({});

	const handleFetchLogos = useCallback(async () => {
		const logos: Partial<Record<T, string>> = Object.fromEntries(
			await Promise.all(
				Object.values(integrationTypes).map(async (integration) => {
					const safeIntegration = integration as IntegrationItem<T>;
					return [
						safeIntegration.id,
						await firebaseApi.getDownloadUrl(
							'/integrationLogos/' + safeIntegration.id + '.png',
						),
					];
				}),
			),
		);

		return logos;
	}, [integrationTypes]);

	useEffect(() => {
		let isMounted = true;

		handleFetchLogos().then((logos) => {
			if (isMounted) setIntegrationLogos(logos);
		});

		return () => {
			isMounted = false;
		};
	}, [handleFetchLogos]);

	const onStart = (integrationSelected: T | ''): void => {
		if (integrationSelected !== '') {
			const selectedIntegration = integrationTypes[integrationSelected];
			if (selectedIntegration !== undefined) {
				const link = selectedIntegration.link;
				navigate(link, { replace: false });
			}
		}
	};

	const renderIntegrationCard = (
		key: T | '',
		label: string,
		link?: string,
	): JSX.Element => (
		<Card sx={{ width: '100%' }}>
			<CardHeader title={label} />
			<CardMedia title={label}>
				<Image
					src={link ?? ''}
					alt={label}
					style={{ height: '25vh', width: '100%' }}
					imageStyle={{ height: '25vh', objectFit: 'contain' }}
				/>
			</CardMedia>
			<CardActions sx={{ justifyContent: 'center' }}>
				<Button variant="contained" onClick={(): void => onStart(key)}>
					Connect
				</Button>
			</CardActions>
		</Card>
	);

	return (
		<Stack spacing={2}>
			<Card>
				<CardHeader title="Initial Setup" />
				<CardContent>{description}</CardContent>
			</Card>
			<Box>
				<Grid container spacing={2}>
					{Object.entries(integrationTypes).map(([key, value]) => (
						<Grid item key={key} xs={12} sm={4} md={3}>
							{renderIntegrationCard(
								key as T,
								// value is unkown for some reason, so forcing it back to what it should be based on integrationItem
								(value as IntegrationItem<T>).name,
								integrationLogos[key as T],
							)}
						</Grid>
					))}
				</Grid>
			</Box>
		</Stack>
	);
};
