import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { InvoicingType } from '../../../models/Integrations/InvoicingIntegration';
import { BaseInvoicingPage } from './BaseIntegrationPage';
import { ClientMappingTab } from './ClientMapping/ClientMappingTab';
import { IntegrationTab } from './IntegrationTab';

const REDIRECT_URL = process.env.REACT_APP_URL;
const XERO_LOGIN_BASE_URL = process.env.REACT_APP_XERO_LOGIN_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
const XERO_LOGIN_REDIRECT = `${REDIRECT_URL}/connect-invoicing/xero`;
const XERO_LOGIN_API = `${XERO_LOGIN_BASE_URL}/identity/connect/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${XERO_LOGIN_REDIRECT}&scope=openid offline_access accounting.transactions accounting.contacts.read`;

export const XeroInvoicingPage = (): JSX.Element => {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [token, setToken] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		const code = searchParams.get('code');
		setToken(code);
	}, [searchParams]);

	const tabs: IntegrationTab<InvoicingType>[] = [
		{
			index: 0,
			name: 'Clients',
			content: (props) => (
				<ClientMappingTab {...props} firebaseApi={firebaseApi} />
			),
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchInvoicingContactIDs,
		},
	];

	const handleAuthenticate = (): void => {
		window.open(XERO_LOGIN_API, '_self');
	};

	const renderXeroInvoicingAuthentication = (): JSX.Element => (
		<>
			<Typography>
				Click &quot;Authenticate&quot; below to connect your Xero
				Invoicing with Trade Legion.
			</Typography>
			{showError && (
				<>
					<Typography
						color="error"
						variant="subtitle2"
						paddingTop="1rem">
						Something went wrong and we failed to connect to Xero.
					</Typography>
					<Typography color="error" variant="subtitle2">
						Please contact Trade Legion for assistance.
					</Typography>
				</>
			)}
		</>
	);

	const handleSubmit = useCallback(() => {
		searchParams.delete('code');
		searchParams.delete('scope');
		searchParams.delete('session_state');
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const clearAll = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/connect-invoicing', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	if (!XERO_LOGIN_BASE_URL || !CLIENT_ID) {
		return (
			<Stack spacing={1} alignContent="center">
				<Typography textAlign="center">
					{`Xero Invoicing isn't correctly setup. ${(
						<Link to="/contact-us">Contact Trade Legion</Link>
					)}.`}
				</Typography>
				<Button
					variant="outlined"
					fullWidth
					onClick={(): void => navigate('/connect')}>
					Back
				</Button>
			</Stack>
		);
	}

	return (
		<BaseInvoicingPage
			handleAuthenticate={handleAuthenticate}
			integrationType="Xero"
			tabs={tabs}
			renderAuthenticationContent={renderXeroInvoicingAuthentication}
			token={token}
			handleSubmitError={clearAll}
			handleSubmitSuccess={handleSubmit}
			handleCancelAuthenticate={handleCancel}
			fetchToken={cloudFunctionApi.fetchInvoicingTokens}
			firebaseApi={firebaseApi}
			postEndIntegrationStep={handleCancel}
		/>
	);
};
