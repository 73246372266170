const MIN_TO_MILLISECONDS = 60000;

export const dateToMilliseconds = (date: Date | null): number => {
	if (!date) return 0;
	const userTimezoneOffset = date.getTimezoneOffset() * MIN_TO_MILLISECONDS;
	const newDate = new Date(date.getTime() - userTimezoneOffset);
	const time = newDate.getTime();

	return time;
};
export const millisecondsToDate = (milliseconds: number): Date => {
	const date = new Date(milliseconds);
	const userTimezoneOffset = date.getTimezoneOffset() * MIN_TO_MILLISECONDS;
	const newDate = new Date(milliseconds + userTimezoneOffset);

	return newDate;
};
