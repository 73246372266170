import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { ContractStatus } from '../../constants/Contract';

export type ContractStatusChipProps = { status: ContractStatus };

export const ContractStatusChip = ({
	status,
}: ContractStatusChipProps): JSX.Element => {
	const chipStyle: SxProps<Theme> = {
		textAlign: 'right',
	};

	switch (status) {
		case ContractStatus.Active:
			return (
				<Tooltip title="Pending">
					<Chip
						label="Pending"
						color="secondary"
						sx={chipStyle}
						variant="filled"
					/>
				</Tooltip>
			);
		case ContractStatus.Accepted:
			return (
				<Tooltip title={status}>
					<Chip
						label={status}
						color="success"
						sx={chipStyle}
						variant="filled"
					/>
				</Tooltip>
			);
		case ContractStatus.Archived:
			return (
				<Tooltip title={status}>
					<Chip
						label={status}
						color="default"
						sx={chipStyle}
						variant="filled"
					/>
				</Tooltip>
			);
	}
};
