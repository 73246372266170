import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { WorkerAttendance } from '../../constants/Attendance';
import { RemoveFrom } from './RemoveFrom';
import { WorkerCheckboxList } from './WorkerCheckboxList';

export type ClearAbsenteesModalProps = {
	absentWorkers: WorkerAttendance[];
	modalOpen: boolean;
	removedFrom: RemoveFrom.Company | RemoveFrom.Site;
	removeWorkers: (workers: WorkerAttendance[]) => Promise<void[]>;
	toggleOpen: () => void;
};

export const ClearAbsenteesModal = ({
	absentWorkers,
	modalOpen,
	removedFrom,
	removeWorkers,
	toggleOpen,
}: ClearAbsenteesModalProps): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [selectedWorkers, setSelectedWorkers] = useState<string[]>([]);

	const onClose = (): void => {
		if (!loading) {
			setSelectedWorkers([]);
			toggleOpen();
		}
	};

	const handleRemoveWorkers = async (): Promise<void> => {
		setLoading(true);
		await removeWorkers(
			absentWorkers.filter((worker) =>
				selectedWorkers.includes(worker.userID),
			),
		);
		setLoading(false);
		onClose();
	};

	return (
		<Dialog
			color="default"
			scroll="paper"
			fullWidth
			maxWidth="xs"
			open={modalOpen}
			onClose={onClose}>
			<DialogTitle>
				Remove Absent Workers
				<Tooltip
					title={`Removed workers will no longer have any access to your ${removedFrom}.`}
					componentsProps={{
						tooltip: { sx: { textAlign: 'center' } },
					}}
					placement="top">
					<IconButton
						disableFocusRipple
						disableRipple
						size="small"
						color="info">
						<InfoIcon fontSize="small" />
					</IconButton>
				</Tooltip>
			</DialogTitle>
			<DialogContent>
				<DialogContentText fontSize="small">
					Clear absent workers from your {removedFrom} that no longer
					work for you to keep your attendance numbers accurate.
				</DialogContentText>
				<WorkerCheckboxList
					title="Absent Workers"
					workers={absentWorkers}
					checked={selectedWorkers}
					setChecked={setSelectedWorkers}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					color="error"
					loading={loading}
					onClick={handleRemoveWorkers}>
					Remove
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
