import { Box, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { RelevantLeaveTypes, leaveLabels } from '../../../../constants/Leave';
import firebaseApi from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { IntegrationAtRest } from '../../../../models/Integrations/Integration';
import {
	BaseIntegrationLink,
	IntegrationElement,
} from '../../../../models/Integrations/IntegrationElements';
import {
	LeaveTypesMapping,
	PayrollType,
} from '../../../../models/Integrations/PayrollIntegration';
import { LeaveIntegrationTabProps } from '../IntegrationTab';
import { ErrorFooter } from './ErrorFooter';
import { MappingTable } from './MappingTable';

export const LeaveMappingTab = ({
	user,
	integration,
	integrationCollection,
	fetchData,
}: LeaveIntegrationTabProps): JSX.Element => {
	const abortSignal = useAbortController();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const [leaveMapping, setLeaveMapping] = useState<LeaveTypesMapping | null>(
		null,
	);
	const [integrationLeaveTypes, setIntegrationLeaveTypes] = useState<
		Record<string, IntegrationElement>
	>({});

	const getIntegrationLeaveIDs = useCallback(
		async (abortSignal: AbortSignal) => {
			const leaveList = await fetchData(abortSignal, user);

			if (leaveList) {
				const leaveMap = leaveList.leaveTypes.reduce(
					(acc: Record<string, IntegrationElement>, leaveElement) => {
						acc[leaveElement.id] = leaveElement;
						return acc;
					},
					{},
				);
				setIntegrationLeaveTypes(leaveMap);
				if (leaveList.leaveTypes.length === 0) {
					setError(
						`No Leave Options available from ${integration.type}. Please ensure you have your integrated system set up.`,
					);
				} else {
					setError(null);
				}
			} else {
				if (!abortSignal.aborted) {
					setError(
						`Unable to get Leave Options from ${integration.type}. Please try again.`,
					);
					setIntegrationLeaveTypes({});
				}
			}
		},
		[fetchData, integration.type, user],
	);

	useEffect(() => {
		const emptyRelevant = RelevantLeaveTypes.reduce(
			(emptyRelevant, key) => {
				emptyRelevant[key] = {
					id: key,
					name: leaveLabels[key],
					integrationID: '',
					integrationName: '',
				};
				return emptyRelevant;
			},
			{} as Record<
				(typeof RelevantLeaveTypes)[number],
				BaseIntegrationLink
			>, // is a truer cast than going straight to LeaveTypesMapping
		);

		setLeaveMapping({
			...emptyRelevant,
			...integration.leaveTypes,
		});

		getIntegrationLeaveIDs(abortSignal);
	}, [getIntegrationLeaveIDs, integration.leaveTypes, abortSignal]);

	useEffect(() => {
		if (
			integrationLeaveTypes === null ||
			Object.keys(integrationLeaveTypes).length === 0 ||
			leaveMapping === null
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [integrationLeaveTypes, leaveMapping]);

	const handleUpdateMapping = async (
		updatedMapping: LeaveTypesMapping,
	): Promise<void> => {
		// Remove blank entires
		const updatedOrAddedMappings = Object.values(updatedMapping).filter(
			(item) => item.integrationID !== '',
		);

		const updatedIntegration: Pick<
			IntegrationAtRest<PayrollType>,
			'leaveTypes'
		> = {
			// Couldn't get the MappingTable component to play nice with a ternary Type to guarentee the mapping used.
			// However, this should be relatively safe
			leaveTypes: Object.fromEntries(
				updatedOrAddedMappings.map((item) => [item.id, item]),
			) as LeaveTypesMapping,
		};

		// IDK what's up here but this is cooked shouldn't be attempting to update invoicing integrations here
		if (integrationCollection === 'payrollIntegrations') {
			await firebaseApi.updateIntegrationLeaveMappings(
				integrationCollection,
				integration.companyID,
				updatedIntegration,
			);
		}
	};

	if (leaveMapping === null) return <></>;

	return (
		<Box py={2}>
			<Paper id="boxList" elevation={1}>
				<MappingTable
					headerTitle={{
						left: 'Leave',
						right: 'Leave Option',
					}}
					dropdownLabel="Leave Option"
					dropdownHelperText="Missing mapping for Leave. Select a new Leave Option above"
					integrationMapping={leaveMapping}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					integrationElements={integrationLeaveTypes!}
					loading={loading}
					fetchIntegrationIDs={getIntegrationLeaveIDs}
					handleCommitMapping={handleUpdateMapping}
					mappingType="Leave"
					disableSelected={false}
				/>
				{error !== null && <ErrorFooter error={error} />}
			</Paper>
		</Box>
	);
};
