import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserDetails, accountTypes } from '../../../constants/Common';
import { ComplianceReport } from '../../../constants/Compliance';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { DateDataTableWithID } from '../../DataTable/DateDataTableWithID';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { ComplianceHistoryTableTheme } from './ComplianceHistoryTableWrapper';
import { CompliancePDFViewDialog } from './CompliancePDFViewDialog';
import { ComplianceReportStatusChip } from './ComplianceReportStatusChip/ComplianceReportStatusChip';

type ComplianceHistoryTableData = {
	site: string;
	period: string;
	dateGenerated: string;
	inductionRate: number;
	safetyCourseRate: number;
	id: string;
};

export type ComplianceHistoryProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<
		FirebaseApi,
		| 'fetchComplianceReportsByCompany'
		| 'fetchComplianceReportsBySite'
		| 'downloadStorageFileFromLink'
		| 'getDownloadUrl'
	>;
};

export const ComplianceHistory = ({
	userDetails,
	firebaseApi,
}: ComplianceHistoryProps): JSX.Element => {
	const [searchParams, setSearchParams] = useSearchParams();

	const initialMount = useRef(true);
	const [selected, setSelected] = useState('');
	const [downloadPDFUrl, setDownloadPDFUrl] = useState('');
	const [complianceReports, setComplianceReports] = useState<
		Record<string, ComplianceReport>
	>({});
	const [tableComplianceData, setTableComplianceData] = useState<
		ComplianceHistoryTableData[]
	>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingPDFDownLoad, setLoadingPDFDownLoad] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);

	const title = 'Compliance History';
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no complance reports found'
	);
	const numCells = 7;
	const cellWidthCalc = (ratio: number): string =>
		`${(100 / numCells) * ratio}%`;
	const showCompanyComplianceHistory =
		userDetails.accountType === accountTypes.seniorManagement;
	const showSiteComplianceHistory =
		userDetails.accountType === accountTypes.management;

	useEffect(() => {
		if (initialMount.current) {
			const reportID = searchParams.get('reportID') ?? '';
			if (reportID !== '') {
				setPdfLoading(true);
				setSelected(reportID);
				setModalOpen(true);
			}
			initialMount.current = false;
		}
	}, [searchParams]);

	useEffect(() => {
		if (selected !== '' && complianceReports[selected]) {
			const getDownloadUrl = async (
				reportLink: string,
			): Promise<void> => {
				const url = await firebaseApi.getDownloadUrl(reportLink);
				setDownloadPDFUrl(url);
			};

			setSearchParams({ reportID: selected });
			getDownloadUrl(complianceReports[selected].reportLink);
		}
	}, [complianceReports, firebaseApi, selected, setSearchParams]);

	const handlePDFOnLoad = (): void => {
		setPdfLoading(false);
	};

	useEffect(() => {
		const mapComplianceEntryToTableData = (
			entry: ComplianceReport,
		): ComplianceHistoryTableData => ({
			site: entry.site.name,
			period: entry.period,
			dateGenerated: formatSlashedDate(entry.createdDate.toDate()),
			inductionRate: entry.inductionRate,
			safetyCourseRate: entry.safetyCourseRate,
			id: entry.id,
		});

		if (showCompanyComplianceHistory) {
			firebaseApi.fetchComplianceReportsByCompany(
				userDetails.companyID,
				(data) => {
					setTableComplianceData(
						Object.values(data).map(mapComplianceEntryToTableData),
					);
					setComplianceReports(data);
					setLoading(false);
				},
			);
		} else if (showSiteComplianceHistory) {
			firebaseApi.fetchComplianceReportsBySite(
				userDetails.siteID,
				(data) => {
					setTableComplianceData(
						Object.values(data).map(mapComplianceEntryToTableData),
					);
					setComplianceReports(data);
					setLoading(false);
				},
			);
		}
	}, [
		firebaseApi,
		userDetails.companyID,
		userDetails.accountType,
		userDetails.siteID,
		showCompanyComplianceHistory,
		showSiteComplianceHistory,
	]);

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const handleSelect = (selectedID: string): void => {
		setSelected(selectedID);
	};

	const handlePDFOnClick = async (
		complianceReportID: string,
	): Promise<void> => {
		const report = complianceReports[complianceReportID];
		if (report) {
			setLoadingPDFDownLoad(true);
			await firebaseApi.downloadStorageFileFromLink(
				report.reportLink,
				`Compliance Report - ${report.site.company} - ${report.site.name} - ${report.period}.pdf`,
			);
			setLoadingPDFDownLoad(false);
		}
	};

	const complianceOptions = (complianceReportID: string): JSX.Element => (
		<Box display="flex" justifyContent="space-between">
			<LoadingButton
				variant="outlined"
				onClick={(): void => {
					handlePDFOnClick(complianceReportID);
				}}
				fullWidth
				loading={loadingPDFDownLoad}>
				PDF
			</LoadingButton>
			<LoadingButton
				variant="contained"
				onClick={(): void => {
					setPdfLoading(true);
					setModalOpen(true);
				}}
				fullWidth
				loading={pdfLoading}
				sx={{ ml: 0.5 }}>
				Open
			</LoadingButton>
		</Box>
	);

	const renderRatePercentageFromString = (rate: string): string =>
		Math.floor(Number(rate) * 100).toLocaleString() + '%';

	const columns: MUIDataTableColumn[] = [
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1.5),
					},
				}),
			},
		},
		{
			name: 'period',
			label: 'Period',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'dateGenerated',
			label: 'Date Generated',
			options: {
				customBodyRender: (
					value: ComplianceHistoryTableData['dateGenerated'],
				) => value,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
			},
		},
		{
			name: 'inductionRate',
			label: 'Induction Rate',
			options: {
				customBodyRender: (rate): JSX.Element => (
					<Box display="flex" justifyContent="center">
						<ComplianceReportStatusChip rate={rate} />
					</Box>
				),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
				filterOptions: {
					renderValue: renderRatePercentageFromString,
				},
				customFilterListOptions: {
					render: (rate) =>
						'Induction Rate: ' +
						renderRatePercentageFromString(rate),
				},
			},
		},
		{
			name: 'safetyCourseRate',
			label: 'Safety Course Rate',
			options: {
				customBodyRender: (rate): JSX.Element => (
					<Box display="flex" justifyContent="center">
						<ComplianceReportStatusChip rate={rate} />
					</Box>
				),
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(1),
					},
				}),
				filterOptions: {
					renderValue: renderRatePercentageFromString,
				},
				customFilterListOptions: {
					render: (rate) =>
						'Safety Course: ' +
						renderRatePercentageFromString(rate),
				},
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				filter: false,
				sort: false,
				searchable: false,
				customBodyRender: complianceOptions,
				setCellHeaderProps: () => ({
					style: {
						width: cellWidthCalc(2),
					},
				}),
			},
		},
	];

	return (
		<>
			<CompliancePDFViewDialog
				PDFUrl={downloadPDFUrl}
				open={modalOpen}
				setOpen={setModalOpen}
				onLoaded={handlePDFOnLoad}
				loading={pdfLoading}
				downloadPDF={(): void => {
					handlePDFOnClick(selected);
				}}
			/>
			<ComplianceHistoryTableTheme>
				<DateDataTableWithID
					title={<Typography variant="h4">{title}</Typography>}
					columns={columns}
					tableData={tableComplianceData}
					customTableOptions={tableOptions}
					selection={[selected, handleSelect]}
				/>
			</ComplianceHistoryTableTheme>
		</>
	);
};
