import firebase from 'firebase';
import { AllIntegrationEmployeesResponse } from '../models/Integrations/IntegrationElements';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchInvoicingEmployeeIDs = async (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<AllIntegrationEmployeesResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<AllIntegrationEmployeesResponse>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.InvoicingEmployeeIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		},
	);
};
