import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	Box,
	Button,
	Container,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import React from 'react';
import { AuthState } from '../../constants/AuthState';
import logo from '../../images/birdonlyblack-transparentbackground.png';
import { useUserLoadedContext } from '../../providers/UserProvider';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type BaseAuthState = AuthState | 'accountInfo' | 'verify' | 'companyInfo';
type AllAuthState = BaseAuthState | 'approval' | 'subscribing';
type StepperAuthState = Exclude<AllAuthState, 'signIn'>;

type StepperState = {
	index: number;
	label: string;
};

type SignInWrapperProps = {
	children: React.ReactNode;
	title: string;
	authState: AllAuthState;
	back: { label: string; handle: () => void };
	next: { label: string; handle: () => void };
	onSignOut?: () => void;
};

export const SignInUpWrapper = ({
	children,
	title,
	authState,
	next,
	back,
	onSignOut,
}: SignInWrapperProps): JSX.Element => {
	const { userAuthLoaded, userDetailsLoaded } = useUserLoadedContext();
	const HOME_URL = process.env.REACT_APP_HOME_URL || '';

	const steps: Partial<Record<StepperAuthState, StepperState>> = {
		signUp: { index: 0, label: 'Email Password' },
		verify: { index: 1, label: 'Verify Email' },
		accountInfo: { index: 2, label: 'Account Information' },
		companyInfo: { index: 3, label: 'Select Company' },
		subscribing: { index: 4, label: 'Subscription' },
		approval: { index: 4, label: 'Approval' },
	};

	if (authState === 'subscribing') {
		delete steps.approval;
	} else {
		delete steps.subscribing;
	}

	return (
		<Box
			height="100vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
			p={4}>
			<Container
				sx={{
					padding: 4,
					minWidth: '50%',
					border: 2,
					borderColor: 'textLandingPage.main',
				}}>
				<Button
					sx={{ mb: 2 }}
					target="_self"
					rel="noopener"
					href={HOME_URL}
					startIcon={<ArrowBackIcon />}>
					Back to Home
				</Button>
				{authState !== 'signIn' && (
					<Stepper
						activeStep={steps[authState]?.index}
						alternativeLabel>
						{Object.entries(steps)
							.filter(([key, _]) =>
								authState !== 'subscribing'
									? key !== 'subscribing'
									: key !== 'approval',
							)
							.map(([_, label]) => {
								const stepProps: { completed?: boolean } = {};
								const labelProps: {
									optional?: React.ReactNode;
								} = {};
								return (
									<Step
										key={label.index + label.label}
										{...stepProps}>
										<StepLabel {...labelProps}>
											{label.label}
										</StepLabel>
									</Step>
								);
							})}
					</Stepper>
				)}
				<Box display="flex" alignItems="center" flexDirection="column">
					<Box
						component="img"
						src={logo}
						alt="Trade Legion logo"
						width="150px"
						height="150px"
						paddingBottom={2}
					/>
					<Typography variant="h3" flex={1} textAlign="center">
						{title}
					</Typography>
				</Box>
				<Box sx={{ my: 1 }}>
					{userAuthLoaded && userDetailsLoaded ? (
						children
					) : (
						<LoadingDots />
					)}
				</Box>
				<Grid container spacing={1}>
					{onSignOut && (
						<Grid item xs={12}>
							<Button fullWidth onClick={onSignOut}>
								Log Out
							</Button>
						</Grid>
					)}
					{back.label !== '' && (
						<Grid item xs={6}>
							<Button onClick={back.handle} fullWidth>
								{back.label}
							</Button>
						</Grid>
					)}
					{authState !== 'subscribing' && (
						<Grid item xs={back.label !== '' ? 6 : 12}>
							<Button onClick={next.handle} fullWidth>
								{next.label}
							</Button>
						</Grid>
					)}
				</Grid>
			</Container>
		</Box>
	);
};
