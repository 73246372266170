import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { UserProps } from '../../constants/Common';

export const EmployeeFinancialOverview = (_: UserProps): JSX.Element => {
	const [dataList, setDataList] = useState<(string | number)[][]>([]);

	const cellWidth = '16.6%';
	const columns = [
		{
			name: 'Employee',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: '2019',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: '2020',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: '2021',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
		{
			name: 'Total',
			options: {
				setCellHeaderProps: () => ({
					style: { width: cellWidth },
				}),
			},
		},
	];

	const options: MUIDataTableOptions = {
		filterType: 'textField',
		selectableRows: 'none',
		tableBodyHeight: 'calc(100vh - 225px)',
		rowsPerPage: 100,
		expandableRowsHeader: false,
	};

	useEffect(() => {
		const tableData = users.map((user) => {
			return [
				user.label,
				`$${user.y2019.toLocaleString(undefined, {
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}`,
				`$${user.y2020.toLocaleString(undefined, {
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}`,
				`$${user.y2021.toLocaleString(undefined, {
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}`,
				`$${(user.y2021 + user.y2020 + user.y2019).toLocaleString(
					undefined,
					{
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					},
				)}`,
			];
		});
		setDataList(tableData);
	}, [users]);

	return (
		<MUIDataTable
			title="Employee Financial Overview"
			data={dataList}
			columns={columns}
			options={options}
		/>
	);
};

const users = [
	{
		id: 1,
		label: 'Mathew Webster',
		userID: '123',
		y2019: 8452.68,
		y2020: 14105.9,
		y2021: 10883.3,
	},
	{
		id: 2,
		label: 'Jennifer Lawerence',
		userID: 'ABC',
		y2019: 12296.68,
		y2020: 16609.84,
		y2021: 11003.03,
	},
	{
		id: 3,
		label: 'Robert Downey Jr',
		userID: 'URAQT',
		y2019: 9278.88,
		y2020: 17173.06,
		y2021: 13565.23,
	},
	{
		id: 3,
		label: 'Jacob Hays',
		userID: 'URAQT',
		y2019: 10441.5,
		y2020: 11743.06,
		y2021: 1650.34,
	},
	{
		id: 4,
		label: 'Michael Banks',
		userID: 'URAQT',
		y2019: 3478.88,
		y2020: 16673.34,
		y2021: 265.23,
	},
	{
		id: 5,
		label: 'Jarred Simpson',
		userID: 'URAQT',
		y2019: 11278.88,
		y2020: 17173.13,
		y2021: 13005.65,
	},
	{
		id: 6,
		label: 'Te Hui',
		userID: 'URAQT',
		y2019: 928.88,
		y2020: 11073.06,
		y2021: 12365.2,
	},
	{
		id: 7,
		label: 'Kapu Matiaha',
		userID: 'URAQT',
		y2019: 2718.81,
		y2020: 19073.05,
		y2021: 665.03,
	},
	{
		id: 8,
		label: 'Johnothan Davis',
		userID: 'URAQT',
		y2019: 9200.18,
		y2020: 4073.77,
		y2021: 16565.25,
	},
	{
		id: 9,
		label: 'Leon Fowler',
		userID: 'URAQT',
		y2019: 9204.11,
		y2020: 4993.06,
		y2021: 1565.53,
	},
];
