import { Button, Grid, Switch, Typography } from '@mui/material';
import { SitePanelFields } from './SitePanelFields';
import {
	EditableSiteFields,
	EditableSiteTextFields,
	ErrorMapType,
	isFieldErrored,
	siteDisplayTextFields,
} from './sitePanelHelpers';

export type SitePanelProps = {
	disabled: boolean;
	site: EditableSiteFields;
	updatedSite: Partial<EditableSiteFields>;
	setUpdatedSite: (value: Partial<EditableSiteFields>) => void;
	errorMap: Partial<ErrorMapType>;
	setErrorMap: (value: Partial<ErrorMapType>) => void;
	openArchiveSiteDialog?: () => void;
};

export const SitePanel = ({
	site,
	updatedSite,
	setUpdatedSite,
	disabled,
	errorMap,
	setErrorMap,
	openArchiveSiteDialog,
}: SitePanelProps): React.ReactElement => {
	const mergedSite = { ...site, ...updatedSite };

	const handleDetailChange = (
		value: string,
		key: keyof EditableSiteFields,
	): void => {
		setErrorMap({
			[key]: {
				dirty: true,
				error: isFieldErrored(value, key),
			},
		});

		setUpdatedSite({
			[key]:
				key === 'autoSignOutTime' || key === 'startTime'
					? Number.parseInt(value)
					: value,
		});
	};

	const handleAppSignInChange = (): void => {
		setUpdatedSite({
			allowAppSignIn: !mergedSite.allowAppSignIn,
		});
	};

	const handleShowCovidWarningChange = (): void => {
		setUpdatedSite({
			showCovidWarning: !mergedSite.showCovidWarning,
		});
	};

	const handleRequireInductionsChange = (): void => {
		let siteUpdate: Partial<
			Pick<
				EditableSiteFields,
				'safetyCourseRequiredForInduction' | 'hasInductions'
			>
		>;
		if (mergedSite.hasInductions) {
			siteUpdate = {
				hasInductions: false,
				safetyCourseRequiredForInduction: false,
			};
		} else {
			siteUpdate = {
				hasInductions: true,
			};
		}

		setUpdatedSite(siteUpdate);
	};

	const handleSafetyCourseOptionalForInductionsChange = (): void => {
		setUpdatedSite({
			safetyCourseRequiredForInduction:
				!mergedSite.safetyCourseRequiredForInduction,
		});
	};

	const renderFields = Object.entries(siteDisplayTextFields).map(
		([key, value]) => {
			const keyField = key as keyof EditableSiteTextFields;
			const errorField = errorMap[keyField];
			const isError = !errorField
				? false
				: errorField.error && errorField.dirty;

			return (
				<Grid item xs={6} key={key}>
					<SitePanelFields
						field={keyField}
						site={mergedSite}
						isError={isError}
						label={value}
						disabled={disabled}
						handleDetailChange={handleDetailChange}
					/>
				</Grid>
			);
		},
	);

	return (
		<>
			<Grid container spacing={3}>
				{renderFields}
			</Grid>
			<Grid container alignItems="flexStart" mt={2} direction="row">
				<Grid item display="flex">
					<Typography m={1}>Allow App SignIn</Typography>
					<Grid display="flex" alignItems="center">
						<Switch
							disabled={disabled}
							checked={mergedSite.allowAppSignIn}
							value={
								undefined /* turns this into an uncontrolled component */
							}
							onChange={handleAppSignInChange}
						/>
					</Grid>
				</Grid>
				<Grid item display="flex">
					<Typography variant="body1" m={1}>
						Show Covid Warning
					</Typography>
					<Grid display="flex" alignItems="center">
						<Switch
							disabled={disabled}
							checked={mergedSite.showCovidWarning}
							value={undefined}
							onChange={handleShowCovidWarningChange}
						/>
					</Grid>
				</Grid>
				<Grid item display="flex">
					<Typography variant="body1" m={1}>
						Require Inductions
					</Typography>
					<Grid display="flex" alignItems="center">
						<Switch
							data-testid="require-inductions-switch"
							disabled={disabled}
							checked={mergedSite.hasInductions}
							value={undefined}
							onChange={handleRequireInductionsChange}
						/>
					</Grid>
				</Grid>
				<Grid item display="flex">
					<Typography variant="body1" m={1}>
						Require Safety Courses in Inductions
					</Typography>
					<Grid display="flex" alignItems="center">
						<Switch
							disabled={
								disabled || mergedSite.hasInductions === false
							}
							checked={
								mergedSite.safetyCourseRequiredForInduction
							}
							value={undefined}
							onChange={
								handleSafetyCourseOptionalForInductionsChange
							}
						/>
					</Grid>
				</Grid>
				{openArchiveSiteDialog && (
					<Grid
						item
						display="flex"
						justifyContent="flex-end"
						flexGrow={1}>
						<Button
							variant="contained"
							onClick={openArchiveSiteDialog}>
							Archive Site
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};
