import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

export const useUuid = (): string => {
	const [uuid, setUuid] = useState(v4());

	useEffect(() => {
		setUuid(v4());
	}, []);

	return uuid;
};
