import { TextField } from '@mui/material';
import React from 'react';
import { TimePicker } from '../../providers/LocalizationProvider';
import {
	dateToMilliseconds,
	millisecondsToDate,
} from './CustomTimePickerHelpers';

export type CustomTimePickerProps = {
	onChange: (value: number | null) => void;
	value: number;
	label: string;
	disabled?: boolean;
	helperText?: string;
	error?: boolean;
	size?: 'small' | 'medium';
};

const CustomTimePicker = ({
	onChange,
	value,
	label,
	disabled,
	helperText,
	error,
	size,
}: CustomTimePickerProps): React.ReactElement => {
	return (
		<TimePicker
			label={label}
			onChange={(value): void => onChange(dateToMilliseconds(value))}
			value={millisecondsToDate(value)}
			renderInput={(params): React.ReactElement => (
				<TextField
					{...params}
					fullWidth
					size={size}
					helperText={helperText}
					error={error}
				/>
			)}
			disabled={disabled}
			minutesStep={5}
		/>
	);
};

export default CustomTimePicker;
