import firebase from 'firebase';
import { TimesheetStatus } from '../constants/Timesheet/TimesheetStatus';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnBlob,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

type AcceptableStatuses = (
	| TimesheetStatus.Submitted
	| TimesheetStatus.Approved
	| TimesheetStatus.Archived
)[];
export type SeniorManagementRequest = {
	week: number;
	accountType: 'seniorManagement';
	splitBy: 'employer';
	siteID: string | null;
	filterBy?: string[];
	statuses?: AcceptableStatuses;
	includeSiteLogs?: boolean;
};

export type ManagementRequest = {
	week: number;
	accountType: 'management';
	splitBy: 'employer';
	filterBy?: string[];
	statuses?: AcceptableStatuses;
	includeSiteLogs?: boolean;
};

export type HandlerRequest = {
	accountType: 'handler';
	week: number;
	splitBy: 'site';
	filterBy?: string[];
	statuses?: AcceptableStatuses;
	includeSiteLogs?: boolean;
};

export type MultiTimesheetRequestBody =
	| SeniorManagementRequest
	| ManagementRequest
	| HandlerRequest;

type MultiTimesheetResponse = {
	blobUrl: string;
	size: number;
};

export const fetchMultiTimesheet = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	requestBody: MultiTimesheetRequestBody,
): Promise<MultiTimesheetResponse | undefined> => {
	const blob =
		await makeCloudFunctionRequestReturnBlob<MultiTimesheetRequestBody>({
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.MultiTimesheet,
			method: 'POST',
			services: defaultCloudFunctionServices,
			body: requestBody,
		});

	if (!blob) return;

	return {
		blobUrl: URL.createObjectURL(blob),
		size: blob.size,
	};
};
