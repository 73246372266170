import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { EmployeesPayrollDetails } from './EmployeesPayrollDetails';

export const EmployeesPayrollDetailsWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	return (
		<EmployeesPayrollDetails
			userDetails={userDetails}
			firebaseApi={firebaseApi}
		/>
	);
};
