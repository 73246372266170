import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { IntegrationType } from '../../../models/Integrations/Integration';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';

type EndIntegrationDialogProps = {
	publishing: boolean;
	integrationType: IntegrationType;
	showDeleteError: boolean;
	showEndIntegrationModalState: [boolean, Dispatch<SetStateAction<boolean>>];
	handleDeleteIntegrationConfirm: () => void;
	endIntegrationHelperText: string;
};

export const EndIntegrationDialog = ({
	publishing,
	integrationType,
	endIntegrationHelperText,
	showDeleteError,
	showEndIntegrationModalState,
	handleDeleteIntegrationConfirm,
}: EndIntegrationDialogProps): JSX.Element => {
	const [showEndIntegrationModal, setShowEndIntegrationModal] =
		showEndIntegrationModalState;

	return (
		<Dialog open={showEndIntegrationModal}>
			<DialogTitle>Confirm</DialogTitle>
			{publishing ? (
				<DialogContent>
					<LoadingDots />
				</DialogContent>
			) : (
				<DialogContent>
					<Stack>
						<Typography>
							{`Confirm that you wish to end your ${integrationType} connection.`}
						</Typography>
						<Typography variant="caption">
							{endIntegrationHelperText}
						</Typography>
						{showDeleteError && (
							<>
								<Typography
									color="error"
									variant="subtitle2"
									paddingTop="1rem">
									{`Something went wrong and we failed to
									connect to ${integrationType}.`}
								</Typography>
								<Typography color="error" variant="subtitle2">
									Please contact Trade Legion for assistance.
								</Typography>
							</>
						)}
					</Stack>
				</DialogContent>
			)}
			<DialogActions>
				<Button
					variant="outlined"
					onClick={(): void => setShowEndIntegrationModal(false)}
					disabled={publishing}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={handleDeleteIntegrationConfirm}
					disabled={publishing}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
