import { startOfWeek } from 'date-fns';
import cloudFunctionApi from '../../../../cloudfunctions';
import type { UserProps } from '../../../../constants/Common';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { useTimesheetSearchParams } from '../../hooks/TimesheetNavHooks';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { Overview, OverviewFirebaseCalls } from './Overview';

type AvailableFirebaseCalls = Extract<keyof FirebaseApi, OverviewFirebaseCalls>;

export const OverviewWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const {
		context: {
			timesheetData,
			endDate,
			timesheetID,
			companies,
			loading,
			firebaseApi,
		},
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const setTimesheetSearchParams = useTimesheetSearchParams();

	return companies[userDetails.companyID] ? (
		<Overview
			userDetails={userDetails}
			timesheetData={timesheetData}
			weekEnding={endDate}
			setWeekEnding={(value): void =>
				setTimesheetSearchParams({
					endDate: value,
					startDate: startOfWeek(value),
				})
			}
			selected={timesheetID}
			setSelected={(value): void =>
				setTimesheetSearchParams({ timesheetID: value })
			}
			userCompany={companies[userDetails.companyID]}
			loading={loading}
			firebaseApi={firebaseApi}
			cloudFunctionApi={cloudFunctionApi}
		/>
	) : (
		<LoadingDots />
	);
};
