import { Contract, ContractStatus } from '../../constants/Contract';
import {
	DocumentReference,
	Firestore,
	FirestoreDataConverter,
} from '../firebase';

const contractConverter: FirestoreDataConverter<Contract> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) =>
		({ ...snapshot.data(), id: snapshot.id } as Contract),
};

const getLimitedContractsByEmployeeSiteSupplierAccepterStatus = async (
	employeeID: string,
	siteID: string | null,
	supplierCompanyID: string,
	accepterCompanyID: string,
	contractStatus: ContractStatus,
	limit?: number,
): Promise<Contract[]> => {
	let query = Firestore.collection('contracts')
		.where('employee.id', '==', employeeID)
		.where('site.id', '==', siteID)
		.where('supplierCompany.id', '==', supplierCompanyID)
		.where('accepterCompany.id', '==', accepterCompanyID)
		.where('status', '==', contractStatus);
	if (limit) {
		query = query.limit(limit);
	}
	const querySnapshot = await query.get();
	return querySnapshot.docs.map((doc) => doc.data() as Contract);
};

const getLimitedContractByEmployeeSupplierAccepterStatus = async (
	employeeID: string,
	supplierCompanyID: string,
	accepterCompanyID: string,
	contractStatus: ContractStatus,
	limit: number,
): Promise<Contract[]> => {
	const docs = await Firestore.collection('contracts')
		.where('employee.id', '==', employeeID)
		.where('supplierCompany.id', '==', supplierCompanyID)
		.where('accepterCompany.id', '==', accepterCompanyID)
		.where('status', '==', contractStatus)
		.limit(limit)
		.get();

	return docs.docs.map((doc) => doc.data() as Contract);
};

const contractsByStatusCompany = (
	statuses: ContractStatus[],
	companyID: string,
	companyFilter: `${keyof Contract}.id`,
	contractCallback: (contracts: Record<string, Contract>) => void,
): (() => void) =>
	Firestore.collection('contracts')
		.where('status', 'in', statuses)
		.where(companyFilter, '==', companyID)
		.withConverter(contractConverter)
		.onSnapshot((snapshot) => {
			const contractMap = Object.fromEntries(
				snapshot.docs.map((contract) => [contract.id, contract.data()]),
			);
			contractCallback(contractMap);
		});

const contractsBySupplierCompanySubscription = (
	supplierCompanyID: Contract['supplierCompany']['id'],
	contractCallback: (contracts: Record<string, Contract>) => void,
): (() => void) =>
	Firestore.collection('contracts')
		.where('supplierCompany.id', '==', supplierCompanyID)
		.withConverter(contractConverter)
		.onSnapshot((snapshot) => {
			const contractMap: Record<string, Contract> = {};
			snapshot.docs.forEach((contract) => {
				const contractData = contract.data();
				contractMap[contract.id] = contractData;
			});
			contractCallback(contractMap);
		});

const contractDocRef = (id: string): DocumentReference =>
	Firestore.collection('contracts').doc(id).withConverter(contractConverter);

const createContractDocRef = (): DocumentReference =>
	Firestore.collection('contracts').doc();

const acceptContract = (
	id: string,
	update: Contract['acceptedBy'],
): Promise<void> =>
	Firestore.collection('contracts')
		.doc(id)
		.update({
			status: ContractStatus.Accepted,
			...update,
		});

const archiveContract = (id: string): Promise<void> =>
	Firestore.collection('contracts').doc(id).update({
		status: ContractStatus.Archived,
	});

const findDuplicateContract = async (
	employeeID: string,
	employerID: string,
	clientID: string,
	siteID?: string,
): Promise<Contract[] | null> => {
	let query = Firestore.collection('contracts')
		.where('employee.id', '==', employeeID)
		.where('supplierCompany.id', '==', employerID)
		.where('accepterCompany.id', '==', clientID)
		// We allow new contracts with the same details after they have been archived
		.where('status', '!=', ContractStatus.Archived);

	// query for a null site when no site is selected
	if (siteID) {
		query = query.where('site.id', '==', siteID);
	} else {
		query = query.where('site', '==', null);
	}
	const result = await query.get();
	const contracts = result.docs.map(
		(contract) => contract.data() as Contract,
	);

	return contracts.length > 0 ? contracts : null;
};

const contractsFirebaseApi = {
	getLimitedContractsByEmployeeSiteSupplierAccepterStatus,
	getLimitedContractByEmployeeSupplierAccepterStatus,
	contractsByStatusCompany,
	contractsBySupplierCompanySubscription,
	contractDocRef,
	createContractDocRef,
	acceptContract,
	archiveContract,
	findDuplicateContract,
};

export default contractsFirebaseApi;
