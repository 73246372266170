import { Timestamp } from '../firebase/firebase';
import { SafetyCourse } from './SafetyCourse';

export const inducteeType = { Employee: 'Employee', Guest: 'Guest' } as const;
export type InducteeType = (typeof inducteeType)[keyof typeof inducteeType];

export type InductionEntry = {
	id: string;
	inductee:
		| {
				type: typeof inducteeType.Employee;
				id: string;
				name: string;
				companyID: string;
				company: string;
		  }
		| {
				type: typeof inducteeType.Guest;
				id: string;
				name: string;
				phoneNumber: string;
				company: string;
		  };
	createdBy: {
		id: string;
		name: string;
		company: string;
		companyID: string;
	};
	dateCreated: Timestamp;
	updatedBy: {
		id: string;
		name: string;
		company: string;
		companyID: string;
	};
	dateUpdated: Timestamp;
	site: {
		id: string;
		name: string;
		company: string;
		companyID: string;
	};
	safetyCourse?: SafetyCourse['course'];
};

export type NewInductionValidationErrors = {
	site: boolean;
	selectedUser: boolean;
	inducteeName: boolean;
	guestMobileNumber: boolean;
	guestCompany: boolean;
};

export const inducteeOption = {
	ExistingUser: 'Existing User',
	ExistingGuest: 'Existing Guest',
	NewGuest: 'New Guest',
} as const;

export type InducteeOption =
	(typeof inducteeOption)[keyof typeof inducteeOption];
