import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import type { CloudFunctionApi } from '../../../../cloudfunctions';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../../providers/UserProvider';

type NoTimesheetDialogProps = {
	employee: Timesheet['employee'];
	weekEnding: Date;
	endDate: Date;
	timesheetID: string | null;
	timesheetStatus: Timesheet['timesheetStatus'] | null;
	modalOpen: boolean;
	setModalOpen: (open: boolean) => void;
	canRemoveFromCompany: boolean;
	canRemoveFromSite: boolean;
	firebaseApi: Pick<
		FirebaseApi,
		| 'updateUserDetailsSiteInfo'
		| 'updateUserDetailsCompanyInfo'
		| 'deleteTimesheet'
	>;
	setNotificationSuccess: (success: boolean | undefined) => void;
	setRemoveSuccess: (success: boolean) => void;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		'sendTimesheetReminderNotification'
	>;
};

export const NoTimesheetDialog = ({
	employee,
	weekEnding,
	endDate,
	timesheetID,
	timesheetStatus,
	modalOpen,
	setModalOpen,
	canRemoveFromCompany,
	canRemoveFromSite,
	firebaseApi,
	setNotificationSuccess,
	setRemoveSuccess,
	cloudFunctionApi,
}: NoTimesheetDialogProps): JSX.Element => {
	const [disableSend, setDisableSend] = useState(false);

	const user = useUserAuthContext();
	const abortSignal = useAbortController();

	const removeWorkerFromSite = async (): Promise<void> => {
		if (!user) return;
		const updatedDetails: Parameters<
			typeof firebaseApi.updateUserDetailsSiteInfo
		>[1] = {
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
		};

		setModalOpen(false);
		try {
			await firebaseApi.updateUserDetailsSiteInfo(
				employee.id,
				updatedDetails,
			);
			if (timesheetID && timesheetStatus !== TimesheetStatus.Approved) {
				await firebaseApi.deleteTimesheet(
					abortSignal,
					user,
					timesheetID,
				);
			}
			setRemoveSuccess(true);
		} catch (err) {
			setRemoveSuccess(false);
		}
	};

	const removeWorkerFromCompany = async (): Promise<void> => {
		if (!user) return;
		const updatedDetails: Parameters<
			typeof firebaseApi.updateUserDetailsCompanyInfo
		>[1] = {
			site: '',
			siteID: '',
			siteCompany: '',
			siteCompanyID: '',
			company: '',
			companyID: '',
			contractedTo: null,
			workerType: 'Not Selected',
			accountType: '',
		};

		setModalOpen(false);
		try {
			await firebaseApi.updateUserDetailsCompanyInfo(
				employee.id,
				updatedDetails,
			);
			if (timesheetID) {
				await firebaseApi.deleteTimesheet(
					abortSignal,
					user,
					timesheetID,
				);
			}
			setRemoveSuccess(true);
		} catch (err) {
			setRemoveSuccess(false);
		}
	};

	const sendReminderNotification = async (): Promise<void> => {
		if (!user) return;
		setDisableSend(true);
		const notificationSentSuccess =
			await cloudFunctionApi.sendTimesheetReminderNotification(
				abortSignal,
				user,
				employee.id,
				weekEnding,
				endDate,
			);
		setModalOpen(false);
		setNotificationSuccess(notificationSentSuccess);
	};

	return (
		<>
			<Dialog
				color="default"
				fullWidth
				maxWidth="xs"
				open={modalOpen}
				onClose={(): void => setModalOpen(false)}>
				<DialogTitle>
					{employee.name}
					<IconButton
						onClick={(): void => {
							setModalOpen(!modalOpen);
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<>
						<Typography>
							{employee.name} has not submitted a timesheet.
						</Typography>

						<Typography>
							You can send them a notification to remind them.
						</Typography>

						{(canRemoveFromCompany || canRemoveFromSite) && (
							<Divider>
								<Typography>OR</Typography>
							</Divider>
						)}
						{(canRemoveFromCompany || canRemoveFromSite) && (
							<Typography>
								If they are no longer
								{canRemoveFromCompany
									? ' employed by your company '
									: ' on this site '}
								you can remove them.
							</Typography>
						)}
					</>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						onClick={sendReminderNotification}
						loading={disableSend}
						variant="text">
						Send Reminder
					</LoadingButton>
					{(canRemoveFromCompany || canRemoveFromSite) && (
						<Button
							onClick={
								canRemoveFromCompany
									? removeWorkerFromCompany
									: removeWorkerFromSite
							}
							disabled={disableSend}>
							Remove
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};
