import { Box, Typography, Switch, Stack } from '@mui/material';

const emailNotificationType = [
	'timesheetEmailReminders',
	'complianceReport',
	'pendingApprovals',
] as const;
export type EmailNotificationType = (typeof emailNotificationType)[number];
export type EmailNotificationSetting = {
	name: string;
	description: string;
};

export type EmailNotificationToggleProps = {
	disabled: boolean;
	toggle: () => void;
	toggled: boolean;
	notification: EmailNotificationSetting;
};

export const EmailNotificationToggle = ({
	disabled,
	toggle,
	toggled,
	notification,
}: EmailNotificationToggleProps): JSX.Element => (
	<Stack direction="row" justifyContent="space-between">
		<Box>
			<Typography pt={1} variant="body1">
				{notification.name}
			</Typography>
			<Typography variant="caption">
				{notification.description}
			</Typography>
		</Box>
		<Switch
			data-testid={notification.name}
			sx={{ mt: 0 }}
			disabled={disabled}
			checked={toggled}
			onClick={toggle}
		/>
	</Stack>
);
