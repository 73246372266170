import { Divider, Grid, SxProps, Theme, Typography } from '@mui/material';
import { RefreshPayrollListBtn } from './RefreshPayrollListBtn';

export const RowGridContainer = ({
	id,
	sx,
	children,
}: {
	id?: string;
	sx?: SxProps<Theme>;
	children: React.ReactNode;
}): JSX.Element => (
	<Grid
		container
		id={id}
		alignItems="center"
		justifyContent="space-around"
		textAlign="center"
		spacing={2}
		p={1}
		sx={sx}>
		{children}
	</Grid>
);

export const ColumnGrid = ({
	children,
	md,
}: {
	children: React.ReactNode;
	md: 4 | 6 | 12;
}): JSX.Element => (
	<Grid item sm={12} md={md}>
		{children}
	</Grid>
);

export const IntegrationUIHeader = ({
	leftTitle,
	rightTitle,
	fetchList,
}: {
	leftTitle: string;
	rightTitle: string;
	fetchList: (abortSignal: AbortSignal) => Promise<void>;
}): JSX.Element => (
	<RowGridContainer id="header">
		<ColumnGrid md={4}>
			<Typography>{leftTitle}</Typography>
		</ColumnGrid>
		<Divider orientation="vertical" />
		<ColumnGrid md={6}>
			<Typography>
				{rightTitle}
				<RefreshPayrollListBtn fetchList={fetchList} />
			</Typography>
		</ColumnGrid>
	</RowGridContainer>
);
