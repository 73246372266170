import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import cloudFunctionApi from '../../../../cloudfunctions';
import { isRequiredLeaveMapped } from '../../../../constants/Leave';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	DEFAULT_ACTIVITY_KEY,
	PayrollType,
} from '../../../../models/Integrations/PayrollIntegration';
import { ActivityMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/ActivityMappingTab';
import { LeaveMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/LeaveMappingTab';
import { BasePayrollPage } from '../../IntegrationPages/BaseIntegrationPage';
import { IntegrationTab } from '../../IntegrationPages/IntegrationTab';
import { PayrollEmployeesTab } from '../PayrollEmployeesTab';

const REDIRECT_URL = process.env.REACT_APP_URL;
const IPAYROLL_BASE_URL = process.env.REACT_APP_IPAYROLL_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_IPAYROLL_CLIENT_ID;
const IPAYROLL_REDIRECT = `${REDIRECT_URL}/connect/ipayroll`;
const IPAYROLL_API = `${IPAYROLL_BASE_URL}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&scope=employees timesheets payelements leaverequests&redirect_uri=${IPAYROLL_REDIRECT}&state=11111`;

export const IPayrollPage = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [token, setToken] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		const code = searchParams.get('code');
		setToken(code);
	}, [searchParams]);

	const tabs: IntegrationTab<PayrollType>[] = [
		{
			index: 0,
			name: 'Employees',
			content: (props) => (
				<PayrollEmployeesTab
					integrationName="IPayroll"
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
					{...props}
				/>
			),
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchPayrollEmployeeIDs,
		},
		{
			index: 1,
			name: 'Activities',
			content: (props) => (
				<ActivityMappingTab firebaseApi={firebaseApi} {...props} />
			),
			optional: false,
			canMoveNext: (payrollIntegration) =>
				(DEFAULT_ACTIVITY_KEY in payrollIntegration.activityTypes &&
					payrollIntegration.overtimeThreshold === undefined) ||
				(DEFAULT_ACTIVITY_KEY in payrollIntegration.activityTypes &&
					payrollIntegration.overtimeThreshold !== undefined &&
					'Overtime' in payrollIntegration.activityTypes),
			fetchData: cloudFunctionApi.fetchPayrollActivityIDs,
		},
		{
			index: 2,
			name: 'Leave',
			content: (props) => <LeaveMappingTab {...props} />,
			optional: false,
			canMoveNext: (payrollIntegration) =>
				isRequiredLeaveMapped(
					Object.keys(payrollIntegration.leaveTypes),
				),
			fetchData: cloudFunctionApi.fetchPayrollLeaveIDs,
		},
	];

	const handleAuthenticate = (): void => {
		window.open(IPAYROLL_API, '_self');
	};

	const renderIPayrollAuthentication = (): JSX.Element => (
		<>
			<Typography>
				{`Click "Authenticate" below to connect your iPayroll with Trade Legion.`}
			</Typography>
			{showError && (
				<>
					<Typography
						color="error"
						variant="subtitle2"
						paddingTop="1rem">
						Something went wrong and we failed to connect to
						iPayroll.
					</Typography>
					<Typography color="error" variant="subtitle2">
						Please contact Trade Legion for assistance.
					</Typography>
				</>
			)}
		</>
	);

	const handleSubmit = useCallback(() => {
		searchParams.delete('code');
		searchParams.delete('state');
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const clearAll = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/connect', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	if (!IPAYROLL_BASE_URL || !CLIENT_ID) {
		return (
			<Stack spacing={1} alignContent="center">
				<Typography textAlign="center">
					{"iPayroll isn't correctly setup. "}
					<Link to="/contact-us">Contact Trade Legion</Link>.
				</Typography>
				<Button
					variant="outlined"
					fullWidth
					onClick={(): void => navigate('/connect')}>
					Back
				</Button>
			</Stack>
		);
	}

	return (
		<BasePayrollPage
			handleAuthenticate={handleAuthenticate}
			integrationType="IPayroll"
			tabs={tabs}
			renderAuthenticationContent={renderIPayrollAuthentication}
			token={token}
			handleSubmitError={clearAll}
			handleSubmitSuccess={handleSubmit}
			handleCancelAuthenticate={handleCancel}
			fetchToken={cloudFunctionApi.fetchPayrollTokens}
			firebaseApi={firebaseApi}
			deleteIntegrationFunction={
				cloudFunctionApi.deletePayrollIntegration
			}
			postEndIntegrationStep={handleCancel}
		/>
	);
};
