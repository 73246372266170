import { ThemeProvider } from '@mui/material';
import '@progress/kendo-theme-default/dist/all.css';
import { setDefaultOptions } from 'date-fns';
import { BrowserRouter } from 'react-router-dom';
import Application from './application/Application';
import options from './constants/DatePickerConfig';
import { firebaseAnalyticsService } from './providers/analytics/firebaseAnalyticsService';
import { AnalyticsProvider } from './providers/analytics/Provider';
import { CompanySubscriptionProvider } from './providers/CompanySubscriptionProvider';
import { FeatureFlagsProvider } from './providers/featureFlags/Provider';
import { LocalizationProvider } from './providers/LocalizationProvider';
import { logRocketLoggingService } from './providers/logging/logRocketLoggingService';
import { LoggingProvider } from './providers/logging/Provider';
import { PageConfigProvider } from './providers/pageConfig/Provider';
import { UserProvider } from './providers/UserProvider';
import { DefaultTheme } from './Theme';

const App = (): JSX.Element => {
	setDefaultOptions(options);

	return (
		<BrowserRouter>
			<AnalyticsProvider analyticsService={firebaseAnalyticsService}>
				<LoggingProvider loggingService={logRocketLoggingService}>
					<LocalizationProvider>
						<ThemeProvider theme={DefaultTheme}>
							<UserProvider>
								<FeatureFlagsProvider>
									<CompanySubscriptionProvider>
										<PageConfigProvider>
											<Application />
										</PageConfigProvider>
									</CompanySubscriptionProvider>
								</FeatureFlagsProvider>
							</UserProvider>
						</ThemeProvider>
					</LocalizationProvider>
				</LoggingProvider>
			</AnalyticsProvider>
		</BrowserRouter>
	);
};

export default App;
