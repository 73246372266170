export type PayrollDetails = {
	id: string;
	payslipEmail: string;
	bankAccount: string;
	irdNumber: string;
	taxCode: TaxCodes;
	kiwiSaverPercentage: KiwiSaverPercentage;
};

export type PayrollDetail = keyof PayrollDetails;

type TaxCodes =
	| 'M'
	| 'ME'
	| 'M SL'
	| 'ME SL'
	| 'S'
	| 'SB'
	| 'SH'
	| 'ST'
	| 'S SL'
	| 'SB SL'
	| 'SH SL'
	| 'ST SL'
	| 'CAE'
	| 'EDW'
	| 'NSW'
	| 'STC';

export type KiwiSaverPercentage = 3 | 4 | 6 | 8 | 10 | null;

export const kiwiSaverPercentageString = (
	value: KiwiSaverPercentage,
): string => {
	switch (value) {
		case null:
			return 'Opt Out';
		case 3:
			return '3%';
		case 4:
			return '4%';
		case 6:
			return '6%';
		case 8:
			return '8%';
		case 10:
			return '10%';
	}
};
