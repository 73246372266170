import { Chip, Avatar } from '@mui/material';

type FilterChipProps = {
	title: string;
	currentFilter: string;
	onClick: () => void;
	count: number;
};

export const FilterChip = ({
	title,
	currentFilter,
	onClick,
	count: timesheetCount,
}: FilterChipProps): JSX.Element => (
	<Chip
		key={title}
		label={title}
		variant={title === currentFilter ? 'filled' : 'outlined'}
		onClick={onClick}
		avatar={<Avatar>{timesheetCount}</Avatar>}
		color={title === currentFilter ? 'secondary' : 'default'}
	/>
);
