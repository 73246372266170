import cloudFunctionApi from '../../../../cloudfunctions';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../../../providers/UserProvider';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { ActivitiesTab } from './ActivitiesTab';

export const ActivitiesWrapper = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const user = useUserAuthContext();

	return userDetails === null || user === null ? (
		<LoadingDots />
	) : (
		<ActivitiesTab
			firebaseApi={firebaseApi}
			cloudFunctionApi={cloudFunctionApi}
			userDetails={userDetails}
			user={user}
		/>
	);
};
