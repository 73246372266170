import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

type EditButtonProps = {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const EditButton = ({
	onClick,
}: EditButtonProps): React.ReactElement => {
	return (
		<Tooltip title="Edit">
			<IconButton color="primary" onClick={onClick} sx={{ ml: 3 }}>
				<EditIcon />
			</IconButton>
		</Tooltip>
	);
};
