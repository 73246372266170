import { Company } from '../../constants/Common';
import PageWrapperDisplay from '../DefaultPages/PageWrapper';
import { SubscriptionControl } from './SubscriptionControl';

type SubscriptionControlPageProps = {
	returnUrl: string;
	company: Company | null;
	renderHeader: (showDrawer: boolean) => JSX.Element;
};

export const SubscriptionControlPage = ({
	returnUrl,
	company,
	renderHeader,
}: SubscriptionControlPageProps): JSX.Element => {
	return (
		<PageWrapperDisplay renderHeader={renderHeader}>
			<SubscriptionControl returnUrl={returnUrl} company={company} />
		</PageWrapperDisplay>
	);
};
