import _ from 'lodash';
import { useMemo } from 'react';

type Debounce = Parameters<typeof _.debounce>;
type UseDebounceProps<T extends Debounce[0]> = {
	callback: T;
	wait?: Debounce[1];
	options?: _.DebounceSettings;
};

export const useDebounce = <T extends Debounce[0]>({
	callback,
	wait = 250,
	options = {
		leading: false,
		trailing: true,
	},
}: UseDebounceProps<T>): _.DebouncedFunc<T> => {
	const debouncedOnChange = useMemo(
		() => _.debounce(callback, wait, options),
		[callback, options, wait],
	);
	return debouncedOnChange;
};
