import firebase from 'firebase';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { emptyFunction, UserDetails } from '../constants/Common';
import { Auth } from '../firebase/firebase';
import firebaseApi from '../firebase/firebaseApi';
import { useAnalyticsContext } from './analytics/context';
import { useLoggingContext } from './logging/context';

export const UserAuthContext = createContext<firebase.User | null>(null);
export const UserDetailsContext = createContext<UserDetails | null>(null);
export const UserLoadedContext = createContext<UserLoaded>({
	userAuthLoaded: false,
	userDetailsLoaded: false,
	initialNavigateComplete: false,
	setInitialNavigateComplete: emptyFunction,
});

export type UserLoaded = {
	userAuthLoaded: boolean;
	userDetailsLoaded: boolean; // doesn't mean it's not null, just that it's had a chance to load
	initialNavigateComplete: boolean;
	setInitialNavigateComplete: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserProviderProps = {
	children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
	const logging = useLoggingContext();
	const analytics = useAnalyticsContext();

	const [userAuth, setUserAuth] = useState<firebase.User | null>(null);
	const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
	const [userAuthLoaded, setUserAuthLoaded] = useState<boolean>(false);
	const [userDetailsLoaded, setUserDetailsLoaded] = useState<boolean>(false);
	const [initialNavigateComplete, setInitialNavigateComplete] =
		useState<boolean>(false);
	const auth = Auth();

	useEffect(() => {
		return auth.onAuthStateChanged(async (authResult) => {
			setUserDetailsLoaded(false);
			if (authResult) {
				// userAuth logged in
				try {
					const user = await firebaseApi.getUser(authResult.uid);
					setUserAuth(authResult);
					setUserDetails(user);
					setUserDetailsLoaded(true);
				} catch {
					setUserAuth(authResult);
					setUserDetails((prevUser) => {
						if (prevUser !== null) {
							setInitialNavigateComplete(false);
						}
						return null;
					});
					setUserDetailsLoaded(true);
				}
			} else {
				// userAuth logged out
				setUserAuth(null);
				setUserDetails(null);
				// no user to load
				setUserDetailsLoaded(true);
			}
			setUserAuthLoaded(true);
			setInitialNavigateComplete(false);
		});
	}, [auth]);

	useEffect(() => {
		if (userAuth) {
			return firebaseApi.subscribeSingleUser(
				userAuth.uid,
				(user) => {
					if (user) {
						setUserDetails(user);
					} else {
						setUserDetails((prevUser) => {
							if (prevUser !== null) {
								setInitialNavigateComplete(false);
							}
							return null;
						});
					}
					setUserDetailsLoaded(true);
				},
				() => {
					setUserDetails((prevUser) => {
						if (prevUser !== null) {
							setInitialNavigateComplete(false);
						}
						return null;
					});
					setUserDetailsLoaded(true);
				},
			);
		}
	}, [userAuth]);

	useEffect(() => {
		logging.identify(userDetails);
		analytics.identify(userDetails);
	}, [analytics, logging, userDetails]);

	return (
		<UserAuthContext.Provider value={userAuth}>
			<UserDetailsContext.Provider value={userDetails}>
				<UserLoadedContext.Provider
					value={{
						userAuthLoaded,
						userDetailsLoaded,
						initialNavigateComplete,
						setInitialNavigateComplete,
					}}>
					{children}
				</UserLoadedContext.Provider>
			</UserDetailsContext.Provider>
		</UserAuthContext.Provider>
	);
};

export const useUserAuthContext = (): firebase.User | null => {
	return useContext(UserAuthContext);
};

export const useUserDetailsContext = (): UserDetails | null => {
	return useContext(UserDetailsContext);
};

export const useUserLoadedContext = (): UserLoaded => {
	return useContext(UserLoadedContext);
};
