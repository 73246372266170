import { Timestamp } from '../firebase/firebase';
import { AccountType } from './Common';

export const complianceAccountType = [
	'handler',
	'management',
	'seniorManagement',
] as const;

export type ComplianceAccountType = AccountType &
	(typeof complianceAccountType)[number];

export type ComplianceConfig = {
	titles: string[];
};

export const complianceConfig: Record<ComplianceAccountType, ComplianceConfig> =
	{
		management: {
			titles: ['inductions', 'history', 'configure inductions'],
		},
		handler: {
			titles: ['safety courses'],
		},
		seniorManagement: {
			titles: [
				'inductions',
				'safety courses',
				'history',
				'configure inductions',
			],
		},
	};

export type ComplianceReport = {
	id: string;
	site: {
		id: string;
		name: string;
		companyID: string;
		company: string;
	};
	period: string; // 'Jan 2024'
	createdDate: Timestamp;
	inductionRate: number; // percentage of compliance of the site as a decimal
	safetyCourseRate: number; // percentage of compliance of the site as a decimal
	reportLink: string; // link to report in storage
};
