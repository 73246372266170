import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';

export type WorkbenchUrlDialogProps = {
	open: boolean;
	handleCancel: () => void;
	handleSubmitUrl: (value: string) => void;
	urlRegexp: string | undefined;
};

export const WorkbenchUrlDialog = ({
	open,
	handleCancel,
	handleSubmitUrl,
	urlRegexp,
}: WorkbenchUrlDialogProps): JSX.Element => {
	const [value, setValue] = useState('');
	const [showError, setShowError] = useState(false);

	const LINK_START = 'https://';
	const LINK_END = '.wbi.cloud';
	const WORKBENCH_URL_REGEX = urlRegexp;

	const writeURL = (value: string): string => LINK_START + value + LINK_END;

	const handleChange = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		setShowError(false);
		const newValue = event.target.value;

		setValue(newValue);
	};

	const handleSubmit = (): void => {
		if (value !== '' && validateURL()) {
			const url = writeURL(value);
			handleSubmitUrl(url);
		} else {
			setShowError(true);
		}
	};

	const validateURL = (): boolean => {
		const regexp = WORKBENCH_URL_REGEX;
		if (regexp === undefined) {
			return false;
		}

		const urlRegExp = new RegExp(regexp, 'y');

		return urlRegExp.test(writeURL(value));
	};

	return (
		<Dialog open={open}>
			<DialogTitle>Workbench URL</DialogTitle>
			<DialogContent>
				<Stack spacing={3}>
					<Typography>
						To connect to Workbench you will need to be logged in to
						your company&apos;s Workbench app. You can find your
						company&apos;s unique Workbench code on your Workbench
						dashboard.
					</Typography>
					<Stack direction="row" alignItems="center">
						<Typography
							variant="h6"
							sx={{ textDecoration: 'underline' }}>
							https://www.
						</Typography>
						<TextField
							label="Unique Code"
							size="small"
							margin="none"
							value={value}
							onChange={handleChange}
							helperText={showError && 'Invalid URL'}
							error={showError}
							sx={{
								width: '144px',
							}}
						/>
						<Typography
							variant="h6"
							sx={{ textDecoration: 'underline' }}>
							.wbi.cloud/Workbench.aspx#
						</Typography>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>
					Cancel
				</Button>
				<Button variant="contained" onClick={handleSubmit}>
					Next
				</Button>
			</DialogActions>
		</Dialog>
	);
};
