import { Box, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import {
	BaseIntegrationLink,
	IntegrationElement,
} from '../../../../models/Integrations/IntegrationElements';
import { LoadingDots } from '../../../Management/subcomponents/LoadingDots';
import { ConfirmLinkDialog } from '../../IntegrationUIComponents/ConfirmLinkDialog';
import {
	ColumnGrid,
	IntegrationUIHeader,
	RowGridContainer,
} from '../../IntegrationUIComponents/IntegrationUIComponents';
import { ActionSuccessfulSnackBar } from './ActionSuccessfulSnackBar';
import { IntegrationSelectDropdown } from './IntegrationSelectDropdown';
import { MappingTypes } from './MappingTypes';

type MappingTableProps<T extends Record<string, BaseIntegrationLink>> = {
	headerTitle: { left: string; right: string };
	dropdownLabel: string;
	dropdownHelperText: string;
	integrationMapping: T;
	integrationElements: Record<string, IntegrationElement>;
	loading: boolean;
	fetchIntegrationIDs: (abortSignal: AbortSignal) => Promise<void>;
	handleCommitMapping: (updatedMapping: T) => Promise<void>;
	mappingType: MappingTypes;
	disableSelected?: boolean;
};

export const MappingTable = <T extends Record<string, BaseIntegrationLink>>({
	headerTitle,
	dropdownLabel,
	dropdownHelperText,
	integrationMapping,
	integrationElements,
	loading,
	fetchIntegrationIDs,
	handleCommitMapping,
	mappingType,
	disableSelected = true,
}: MappingTableProps<T>): JSX.Element => {
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [mappingToSave, setMappingToSave] =
		useState<BaseIntegrationLink | null>(null);

	const [actionSuccessfulSnackBarOpen, setActionSuccessfulSnackBarOpen] =
		useState<boolean>(false);
	const [integrationConnectionDeleted, setIntegrationConnectionDeleted] =
		useState<boolean>(false);

	const updateMapping = async (
		id: string,
		newIntegrationID: string,
	): Promise<void> => {
		let updatedMapping = { ...integrationMapping };

		if (integrationMapping[id]?.integrationID === newIntegrationID) return; // same selected as previous mapping no need to update

		// remove empty mappings
		Object.keys(updatedMapping).forEach((mappingKey) => {
			const key = mappingKey as keyof typeof integrationMapping;
			if (updatedMapping[key].integrationID === '') {
				delete updatedMapping[key];
			}
		});

		const newIntegrationTypeEntry: IntegrationElement | undefined =
			integrationElements[newIntegrationID];

		updatedMapping = {
			...updatedMapping,
			[id]: {
				id,
				name: integrationMapping[id] ? integrationMapping[id].name : id,
				integrationID: newIntegrationTypeEntry?.id ?? '',
				integrationName: newIntegrationTypeEntry?.name ?? '',
			},
		};

		await handleCommitMapping(updatedMapping);

		setMappingToSave(null);
		setIntegrationConnectionDeleted(newIntegrationID === '');
		setActionSuccessfulSnackBarOpen(true);
	};

	const updateMappingToSave = (singleMapping: BaseIntegrationLink): void => {
		setMappingToSave(singleMapping);
		setConfirmDialogOpen(true);
	};

	const onDropdownChange = (id: string, integrationID: string): void => {
		const integrationItem = integrationElements[integrationID];
		updateMappingToSave({
			id: id,
			name: integrationMapping[id] ? integrationMapping[id].name : id,
			integrationID: integrationID,
			integrationName: integrationItem?.name ?? '',
		});
	};

	const onDropdownClear = (id: string, integrationID: string): void => {
		const integrationItem = integrationElements[integrationID];

		updateMappingToSave({
			id: id,
			name: integrationMapping[id] ? integrationMapping[id].name : id,
			integrationID: '',
			integrationName: integrationItem?.name ?? '',
		});
	};

	const selectedIntegrationElementIDs = Object.values(integrationElements)
		.filter((integrationElement) =>
			Object.values(integrationMapping)
				.map((integrationLink) => integrationLink.integrationID)
				.includes(integrationElement.id),
		)
		.map((item) => item.id);

	return (
		<Box px={3}>
			<IntegrationUIHeader
				leftTitle={headerTitle.left}
				rightTitle={headerTitle.right}
				fetchList={fetchIntegrationIDs}
			/>
			<Divider orientation="horizontal" />
			{loading ? (
				<LoadingDots />
			) : (
				<Stack py={1}>
					{Object.entries(integrationMapping).map(
						([mappingKey, entry]) => {
							const id = mappingKey;
							return (
								<RowGridContainer id={`${id}-row`} key={id}>
									<ColumnGrid md={4}>
										<Typography>{entry.name}</Typography>
									</ColumnGrid>
									<Divider
										orientation="vertical"
										variant="middle"
										flexItem
									/>
									<ColumnGrid md={6}>
										<IntegrationSelectDropdown
											id={entry.id}
											integrationID={entry.integrationID}
											integrationPayElementTypes={Object.values(
												integrationElements,
											)}
											dropdownLabel={dropdownLabel}
											dropdownHelperText={
												dropdownHelperText
											}
											onChange={onDropdownChange}
											onClear={onDropdownClear}
											selected={
												selectedIntegrationElementIDs
											}
											disableSelected={disableSelected}
										/>
									</ColumnGrid>
								</RowGridContainer>
							);
						},
					)}
				</Stack>
			)}
			{mappingToSave && (
				<ConfirmLinkDialog
					type={mappingType}
					mappingRemoved={mappingToSave.integrationID === ''}
					singleMapping={mappingToSave}
					handleConfirm={(): void => {
						setConfirmDialogOpen(false);
						updateMapping(
							mappingToSave.id,
							mappingToSave.integrationID,
						);
					}}
					handleCancel={(): void => setConfirmDialogOpen(false)}
					open={confirmDialogOpen}
				/>
			)}
			<ActionSuccessfulSnackBar
				type={mappingType}
				integrationConnectionDeleted={integrationConnectionDeleted}
				actionSuccessfulSnackBarOpen={actionSuccessfulSnackBarOpen}
				setActionSuccessfulSnackBarOpen={
					setActionSuccessfulSnackBarOpen
				}
			/>
		</Box>
	);
};
