import firebase from 'firebase';
import { SubscriptionDates } from '../constants/Common';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchSubscriptionTrialDates = async (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<SubscriptionDates | undefined> => {
	return await makeCloudFunctionRequestDeprecated<SubscriptionDates>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.StripeGetSubscriptionTrialDates,
		method: 'GET',
		services: defaultCloudFunctionServices,
	});
};
