import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { Template } from '../../../constants/Template';
import { ViewTemplateHeader } from './ViewTemplateHeader';

type ViewTemplateProps = {
	template: Template;
	showHeader?: boolean;
};

export const ViewTemplate = ({
	template,
	showHeader = true,
}: ViewTemplateProps): JSX.Element => {
	return (
		<Card elevation={0}>
			<CardContent sx={{ padding: 0 }}>
				<Stack spacing={2}>
					{showHeader && (
						<ViewTemplateHeader
							name={template.name}
							companyName={template.company.name}
						/>
					)}

					<Box>
						<Typography variant="body1Bold" mb={1}>
							Custom Fields
						</Typography>
						<Grid container spacing={1}>
							{Object.keys(template.fields).map(
								(templateField) => (
									<Grid item xs={12} key={templateField}>
										<Typography>{templateField}</Typography>
									</Grid>
								),
							)}
						</Grid>
					</Box>
					<Box>
						<Typography variant="body1Bold" mb={1}>
							Body
						</Typography>
						<Typography whiteSpace="break-spaces">
							{template.body}
						</Typography>
					</Box>
				</Stack>
			</CardContent>
		</Card>
	);
};
