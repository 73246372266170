import { Stack, Typography } from '@mui/material';
import { Note } from '../../../../../../constants/Note';
import { NoteHeading } from './NoteHeading';

export type BasicNoteProps = {
	note: Pick<Note, 'note' | 'user'>;
	noteSize?: 'small' | 'medium';
};

export const BasicNote = ({
	note,
	noteSize = 'medium',
}: BasicNoteProps): JSX.Element => {
	return (
		<Stack>
			<NoteHeading name={note.user.name} />
			<Typography
				flex={1}
				overflow="anywhere"
				lineHeight={1}
				fontSize={noteSize}
				color="text.secondary">
				{note.note}
			</Typography>
		</Stack>
	);
};
