import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { NewAccounts } from './NewAccounts';

export const NewAccountsWrapper = ({
	userDetails,
	user,
}: UserProps): JSX.Element => {
	return (
		<AccountsTableTheme centerColumnHeader={3}>
			<NewAccounts
				userDetails={userDetails}
				firebaseApi={firebaseApi}
				user={user}
			/>
		</AccountsTableTheme>
	);
};
