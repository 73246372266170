import { Typography } from '@mui/material';
import { startOfWeek } from 'date-fns';
import type { UserProps } from '../../../../constants/Common';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { downloadBlob } from '../../../helpers/fileDownloads';
import { useTimesheetSearchParams } from '../../hooks/TimesheetNavHooks';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { Export, ExportFirebaseCalls } from './Export';

type AvailableFirebaseCalls = Extract<keyof FirebaseApi, ExportFirebaseCalls>;

export const ExportWrapper = ({
	user,
	userDetails,
}: UserProps): JSX.Element => {
	const {
		context: { sites, companies, endDate, timesheetData, firebaseApi },
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const setTimesheetSearchParams = useTimesheetSearchParams();

	return user ? (
		<Export
			user={user}
			sites={sites}
			companies={companies}
			weekEnding={endDate}
			setWeekEnding={(value): void =>
				setTimesheetSearchParams({
					endDate: value,
					startDate: startOfWeek(value),
				})
			}
			allTimesheets={timesheetData}
			userDetails={userDetails}
			firebaseApi={firebaseApi}
			downloadFile={downloadBlob}
		/>
	) : (
		<Typography textAlign="center">
			No user info available. Timesheet Export can&apos;t be displayed
		</Typography>
	);
};
