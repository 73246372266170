import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Stack,
	Step,
	StepLabel,
	StepLabelProps,
	Stepper,
	Typography,
} from '@mui/material';

export type StepperTab = {
	index: number;
	route: string;
	name: string;
	optional: boolean;
	canMoveNext: () => boolean;
};

export type StepperHeaderProps = {
	currentTab: string;
	tabs: Record<string, StepperTab>;
	loading: boolean;
	next: () => void;
	back: () => void;
	finalize: () => void;
};

export const StepperHeader = ({
	currentTab,
	tabs,
	loading,
	next,
	back,
	finalize,
}: StepperHeaderProps): JSX.Element => {
	const tabIndex = tabs[currentTab].index;
	const canMoveNext = tabs[currentTab].canMoveNext();

	const handleNext = (): void => {
		if (canMoveNext) {
			if (tabIndex === tabList.length - 1) {
				finalize();
			} else {
				next();
			}
		}
	};

	const handleBack = (): void => {
		back();
	};

	const tabList = Object.values(tabs);

	return (
		<Box width="100%" display="flex" flexDirection="row">
			<Stack justifyContent="center">
				<Button
					variant="contained"
					onClick={handleBack}
					disabled={loading || tabIndex === 0}
					startIcon={<ArrowBackIcon />}>
					Back
				</Button>
			</Stack>
			<Stepper
				activeStep={tabIndex}
				alternativeLabel
				sx={{ flex: '1 1 auto' }}>
				{tabList.map((tab) => {
					const labelProps: StepLabelProps = tab.optional
						? {
								optional: (
									<Typography variant="caption">
										(Optional)
									</Typography>
								),
						  }
						: {};

					return (
						<Step key={tab.route}>
							<StepLabel
								{...labelProps}
								sx={{
									textAlign: 'center',
								}}>
								Setup {tab.name}
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Stack justifyContent="center">
				<LoadingButton
					loading={tabIndex === tabList.length - 1 && loading}
					variant="contained"
					onClick={handleNext}
					disabled={!canMoveNext}
					endIcon={<ArrowForwardIcon />}>
					{tabIndex === tabList.length - 1 ? 'Finish' : 'Next'}
				</LoadingButton>
			</Stack>
		</Box>
	);
};
