import { Box, Typography, Button, Stack } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useCallback, useEffect, useState } from 'react';
import { ManageSignUpsAccount } from '../../constants/Accounts';
import {
	Company,
	CompanyTypes,
	Site,
	SubscriptionStatuses,
	UserDetails,
	accountTypes,
} from '../../constants/Common';
import { User } from '../../firebase/firebase';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DateDataTableWithID } from '../DataTable/DateDataTableWithID';
import { CustomActionDialog } from '../Dialogs/CustomActionDialog';
import { sortObjectByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { configureManageSubscriptionButton } from '../SubscriptionControl/ManageSubscriptionButton';
import { ApproveAccountDialog } from './ApproveAccountDialog';

export type NewAccountsFirebaseApi = Pick<
	FirebaseApi,
	| 'getCompany'
	| 'subscribeUnapprovedUsers'
	| 'subscribeUnapprovedUsersByCompany'
	| 'companiesSubscriptionByType'
	| 'activeSitesByCompanySubscription'
	| 'activeSitesSubscription'
	| 'updateSiteContractedToCompany'
	| 'approveUserAccount'
>;

export type NewAccountsProps = {
	userDetails: UserDetails;
	firebaseApi: NewAccountsFirebaseApi;
	user?: User | null;
};

export const NewAccounts = ({
	userDetails,
	firebaseApi,
	user,
}: NewAccountsProps): JSX.Element => {
	const [selectedUser, setSelectedUser] = useState<ManageSignUpsAccount>();
	const [userCompany, setUserCompany] = useState<Company>();
	const [userData, setUserData] = useState<ManageSignUpsAccount[]>([]);
	const [loading, setLoading] = useState({
		companies: true,
		sites: true,
		users: true,
	});
	const finishedLoading = !Object.values(loading).find(
		(isLoading) => isLoading === true,
	);
	const [sites, setSites] = useState<Record<string, Site>>({});

	const [companies, setCompanies] = useState<Record<string, Company>>({});
	const [approvedDialogOpen, setApprovedDialogOpen] = useState(false);
	const [warningDialogOpen, setWarningDialogOpen] = useState(false);

	const canApproveUsersWhileTrialing =
		userDetails.accountType === accountTypes.handler;

	const getSitesByCompany =
		userDetails.accountType === accountTypes.seniorManagement ||
		userDetails.accountType === accountTypes.management;

	const canManageSubscription =
		userDetails.accountType === accountTypes.seniorManagement ||
		userDetails.accountType === accountTypes.handler;

	const noMatchTableText = finishedLoading ? (
		'No accounts to approve'
	) : (
		<LoadingDots />
	);

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '50%',
					},
				}),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '30%',
					},
				}),
				customBodyRender: (site): JSX.Element => (
					<>{site ? site : 'No Site'}</>
				),
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				setCellHeaderProps: () => ({
					style: {
						width: '20%',
					},
				}),
				customBodyRender: (id): JSX.Element => (
					<Stack direction="row" spacing={1} width="100%" pr={1}>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="outlined"
								onClick={(): Promise<void> => denyUser(id)}>
								Deny
							</Button>
						</Box>
						<Box minWidth="50%">
							<Button
								fullWidth
								variant="contained"
								onClick={(): void => approveUser(id)}>
								Approve
							</Button>
						</Box>
					</Stack>
				),
			},
		},
	];

	const setUnapprovedUserDataCallback = useCallback(
		(users: UserDetails[]): void => {
			setUserData(users.map((user) => ({ ...user, id: user.userID })));
			setLoadingData({ users: false });
		},
		[],
	);

	useEffect(() => {
		const getCompany = async (): Promise<void> => {
			const company = await firebaseApi.getCompany(userDetails.companyID);
			setUserCompany(company);
		};
		getCompany();
	}, [firebaseApi, userDetails.accountType, userDetails.companyID]);

	useEffect(() => {
		return firebaseApi.subscribeUnapprovedUsersByCompany(
			userDetails.companyID,
			setUnapprovedUserDataCallback,
		);
	}, [
		firebaseApi,
		setUnapprovedUserDataCallback,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		let unsubSites: () => void;
		if (getSitesByCompany) {
			unsubSites = firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(siteMap) => {
					setSites(sortObjectByField(siteMap, 'name'));
					setLoadingData({ sites: false });
				},
			);
		} else {
			unsubSites = firebaseApi.activeSitesSubscription((siteMap) => {
				setSites(sortObjectByField(siteMap, 'name'));
				setLoadingData({ sites: false });
			});
		}

		const unsubCompanies = firebaseApi.companiesSubscriptionByType(
			CompanyTypes.construction,
			(companiesList) => {
				const companiesMap = companiesList.reduce(
					(companyRecords, company) => {
						companyRecords[company.id] = company;
						return companyRecords;
					},
					{} as Record<string, Company>,
				);
				setCompanies(sortObjectByField(companiesMap, 'name'));
				setLoadingData({ companies: false });
			},
		);
		return () => {
			unsubSites();
			unsubCompanies();
		};
	}, [firebaseApi, getSitesByCompany, userDetails.companyID]);

	const setLoadingData = (field: Record<string, boolean>): void =>
		setLoading((prevState) => ({
			...prevState,
			...field,
		}));

	const approveUser = (id: string): void => {
		const selectedUser = userData.find((user) => user.id === id);
		if (!selectedUser) {
			return;
		} else if (
			userCompany?.subscriptionStatus === SubscriptionStatuses.Trialing &&
			selectedUser.companyID === userDetails.companyID &&
			!canApproveUsersWhileTrialing
		) {
			// This stops construction companies from approving their users if they are on a trial
			setWarningDialogOpen(true);
		} else {
			setSelectedUser(selectedUser);
			setApprovedDialogOpen(true);
		}
	};

	const denyUser = async (id: string): Promise<void> => {
		const selectedUser = userData.find((user) => user.id === id);
		if (!selectedUser) {
			return;
		} else {
			await firebaseApi.updateSiteContractedToCompany(selectedUser.id, {
				site: '',
				siteID: '',
				siteCompany: '',
				siteCompanyID: '',
				company: '',
				companyID: '',
				contractedTo: null,
			});
		}
	};

	const approve = async (
		newAccountInfo: Pick<
			UserDetails,
			| 'accountType'
			| 'workerType'
			| 'site'
			| 'siteID'
			| 'siteCompany'
			| 'siteCompanyID'
			| 'contractedTo'
			| 'company'
			| 'companyID'
		>,
	): Promise<void> => {
		if (!selectedUser?.id) return;
		await firebaseApi.approveUserAccount(selectedUser.id, newAccountInfo);
	};

	const warningText = !canManageSubscription ? (
		<>
			<Typography>
				{userDetails.company} is on a free trial which does not allow
				for the inclusion of direct employees other than management. You
				do not have permission within {userDetails.company} company to
				upgrade your plan.
			</Typography>
			<Typography marginTop={2}>
				To begin onboarding staff, contact Trade Legion or your manager.
			</Typography>
		</>
	) : (
		<>
			<Typography>
				{userDetails.company} is on a free trial, which does not allow
				for the inclusion of direct employees other than management.
				Upgrade your plan to begin onboarding staff.
			</Typography>
			<Typography marginTop={2}>
				Contact Trade Legion if you would like support with this.
			</Typography>
		</>
	);

	return (
		<>
			<Box flex={1} mt={1}>
				<DateDataTableWithID
					tableData={userData}
					columns={columns}
					title="New Accounts"
					customTableOptions={tableOptions}
				/>
			</Box>
			{selectedUser && (
				<ApproveAccountDialog
					userDetails={userDetails}
					closeDialog={(): void => setApprovedDialogOpen(false)}
					dialogOpen={approvedDialogOpen}
					userForApproval={selectedUser}
					companies={companies}
					sites={sites}
					approveUser={approve}
					firebaseApi={firebaseApi}
				/>
			)}
			{user &&
				userCompany?.subscriptionStatus &&
				userCompany.subscriptionStatus !==
					SubscriptionStatuses.Incomplete && (
					<CustomActionDialog
						title="Trialing Subscription"
						content={warningText}
						isOpen={warningDialogOpen}
						setIsOpen={setWarningDialogOpen}
						actionButton={
							canManageSubscription ? (
								configureManageSubscriptionButton(
									userCompany,
									user,
									window.location.href,
								)
							) : (
								<></>
							)
						}
					/>
				)}
		</>
	);
};
