import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import cloudFunctionApi from '../../../cloudfunctions';
import { Company, UserProps } from '../../../constants/Common';
import {
	WorkbenchConfig,
	isWorkbenchAccountType,
} from '../../../constants/Workbench';
import firebaseApi from '../../../firebase/firebaseApi';
import {
	ProjectTrackingIntegration,
	projectTrackingType,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { WorkbenchSetup } from './WorkbenchSetup';
import { WorkbenchTabs } from './WorkbenchTabs';

const PATH = '/project-tracking/workbench';

export type EnvironmentVariables = 'WORKBENCH_URL' | 'WORKBENCH_URL_REGEX';

export const PageWrapper = ({
	user,
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const navigate = useNavigate();
	const pathMatch = useMatch(`${PATH}/:tab`);
	const location = useLocation();

	const environmentVariables: Record<
		EnvironmentVariables,
		string | undefined
	> = {
		WORKBENCH_URL: process.env.REACT_APP_WORKBENCH_URL,
		WORKBENCH_URL_REGEX: process.env.REACT_APP_WORKBENCH_URL_REGEX,
	};

	const [loading, setLoading] = useState<boolean>();
	const [companyIntegrated, setCompanyIntegrated] =
		useState<Company['projectTrackingIntegrated']>();
	const [integration, setIntegration] =
		useState<ProjectTrackingIntegration | null>(null);

	useEffect(() => {
		return firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				if (company !== undefined) {
					const integrated = company.projectTrackingIntegrated;
					setCompanyIntegrated(integrated);
					setLoading(false);
					if (!integrated && pathMatch?.params?.tab !== undefined) {
						// Not integrated should be in the project-tracking home url to get setup
						if (location.pathname !== PATH) {
							navigate(`${PATH}`);
						}
					} else if (
						integrated &&
						integrated === projectTrackingType.Workbench &&
						pathMatch?.params?.tab === undefined
					) {
						const defaultTab = isWorkbenchAccountType(
							userDetails.accountType,
						)
							? WorkbenchConfig[userDetails.accountType].titles[0]
							: 'sites';
						navigate(`${PATH}/${defaultTab}`);
					}
				} else {
					// Need an overall web catchall page so I can throw this and be taken to a 'sorry' page so instead enjoy dashboard
					navigate('/');
					throw new Error('Missing Company For Project Tracking');
				}
			},
		);
	}, [
		location.pathname,
		navigate,
		pathMatch?.params?.tab,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		if (!userDetails.companyID) return;

		return firebaseApi.projectTrackingIntegrationSubscription(
			userDetails.companyID,
			(integration) => {
				setIntegration(integration);
			},
		);
	}, [userDetails.companyID]);

	return (
		<Box>
			{!user || loading ? (
				<LoadingDots />
			) : companyIntegrated && integration && pathMatch?.params.tab ? (
				<>
					<WorkbenchTabs
						user={user}
						userDetails={userDetails}
						integration={integration}
						currentTab={pathMatch.params.tab}
						cloudFunctionApi={cloudFunctionApi}
					/>
					<Outlet />
				</>
			) : (
				<WorkbenchSetup
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
					environmentVariables={environmentVariables}
				/>
			)}
		</Box>
	);
};
