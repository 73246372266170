import firebase from 'firebase';
import { PayrollIntegrationEmployee } from '../models/Integrations/PayrollIntegration';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequest,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type PayrollIntegrationEmployeeResponse = {
	employees: PayrollIntegrationEmployee[];
};

export const fetchPayrollEmployeeIDs = async (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<PayrollIntegrationEmployeeResponse> => {
	return await makeCloudFunctionRequest<PayrollIntegrationEmployeeResponse>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.PayrollEmployeeIDs,
		method: 'GET',
		services: defaultCloudFunctionServices,
	});
};
