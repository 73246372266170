import { SetStateAction } from 'react';
import {
	RecaptchaVerifier,
	PhoneInfoOptions,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	User,
} from '../../firebase/firebase';

// extracted up to this level for easier testing
export const enrolMultiFactor = async (
	mobileNumber: string,
	recaptchaVerifier: RecaptchaVerifier,
	setVerificationId: (value: SetStateAction<string>) => void,
	user: User | null,
): Promise<void> => {
	if (!user) {
		console.error('No user!');
		return;
	}

	const session = await user.multiFactor.getSession();

	// Specify the phone number and pass the MFA session.
	const phoneInfoOptions: PhoneInfoOptions = {
		phoneNumber: mobileNumber,
		session,
	};

	const phoneAuthProvider = new PhoneAuthProvider();

	// Send SMS verification code.
	const id = await phoneAuthProvider.verifyPhoneNumber(
		phoneInfoOptions,
		recaptchaVerifier,
	);
	setVerificationId(id);
};

export const acceptVerificationCode = async (
	verificationId: string,
	verificationCode: string,
	user: User | null,
): Promise<void> => {
	if (!user) {
		console.error('No user!');
		return;
	}
	const cred = PhoneAuthProvider.credential(verificationId, verificationCode);

	const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

	// Complete enrollment. This will update the underlying tokens
	// and trigger ID token change listener.
	return user.multiFactor.enroll(multiFactorAssertion, 'Mobile phone number');
};
