/** I'm grouping these together as activities are logically children of Timesheets, even if this isn't enforced here*/
import firebase from 'firebase';
import cloudFunctionApi from '../../cloudfunctions';
import { AllowDateOrTimestamp } from '../../components/helpers/dateUtilities';
import { Activity } from '../../constants/Common';
import { TimesheetNote } from '../../constants/Note';
import { Timesheet } from '../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../constants/Timesheet/TimesheetStatus';
import { PartialWithID } from '../../constants/TypescriptUtilities';
import { Firestore, Timestamp } from '../firebase';
import {
	deleteActivitiesWithBatch,
	getActivityIDsByTimesheet,
	setActivitiesWithBatch,
	updateActivitiesWithBatch,
} from './activities';

// id and lastEditedBy both required. All dates can also be timestamps and vice-versa
type UpdateTimesheet = PartialWithID<AllowDateOrTimestamp<Timesheet>> & {
	lastEditedBy: Timesheet['lastEditedBy'];
};

const timesheetConverter: firebase.firestore.FirestoreDataConverter<Timesheet> =
	{
		toFirestore: (model) => model,
		fromFirestore: (snapshot, _) =>
			({ ...snapshot.data(), id: snapshot.id } as Timesheet),
	};

/** Saves Timesheet edits and changed activities */
const updateTimesheetAndActivities = async (
	updatedTimesheet?: {
		timesheet: UpdateTimesheet;
		lastEditor: Timesheet['lastEditedBy']; // must give new editor to be updated
	},
	newActivities?: Omit<AllowDateOrTimestamp<Activity>, 'id'>[],
	updatedActivities?: Activity[],
	deletedActivities?: string[],
): Promise<void> => {
	const batch = Firestore.batch();

	if (updatedTimesheet) {
		const timesheetToUpdate: UpdateTimesheet = {
			...updatedTimesheet.timesheet,
			lastEditedBy: updatedTimesheet.lastEditor,
		};
		batch.update(
			Firestore.collection('timesheets').doc(timesheetToUpdate.id),
			timesheetToUpdate,
		);
	}
	if (newActivities) {
		setActivitiesWithBatch(batch, newActivities);
	}
	if (updatedActivities) {
		updateActivitiesWithBatch(batch, updatedActivities);
	}
	if (deletedActivities) {
		deleteActivitiesWithBatch(batch, deletedActivities);
	}
	return batch.commit();
};

/** Checks for duplicate timesheet if found returns the timesheets ID */
const findDuplicateTimesheet = async (
	employeeID: string,
	contractedToCompanyID: string,
	siteID: string,
	week: Timestamp,
): Promise<Timesheet | null> => {
	const result = await Firestore.collection('timesheets')
		.where('employee.id', '==', employeeID)
		.where('contractedTo.id', '==', contractedToCompanyID)
		.where('site.id', '==', siteID)
		.where('week', '==', week)
		.get();
	return !result.empty
		? ({ ...result.docs[0].data(), id: result.docs[0].id } as Timesheet)
		: null;
};

/** PartialWithID on everything means that we redundantly 'update' the id to itself, but it's the most sensible typing to me*/
const updateTimesheetActivities = async (
	updatedTimesheet?: UpdateTimesheet,
	updatedActivities?: PartialWithID<AllowDateOrTimestamp<Activity>>[],
): Promise<void> => {
	const batch = Firestore.batch();

	if (updatedTimesheet) {
		batch.update(
			Firestore.collection('timesheets').doc(updatedTimesheet.id),
			updatedTimesheet,
		);
	}
	if (updatedActivities) {
		updateActivitiesWithBatch(batch, updatedActivities);
	}
	return batch.commit();
};

const deleteTimesheet = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	timesheetID: string,
): Promise<void> => {
	const batch = Firestore.batch();
	const activityIDs = await getActivityIDsByTimesheet(timesheetID);
	deleteActivitiesWithBatch(batch, activityIDs);
	const timesheetRef = Firestore.collection('timesheets').doc(timesheetID);

	await cloudFunctionApi.deleteAllTimesheetNotes(
		abortSignal,
		user,
		timesheetID,
	);
	batch.delete(timesheetRef);
	return batch.commit();
};

const setActivities = async (
	updatedActivities?: AllowDateOrTimestamp<Activity>[],
): Promise<void> => {
	const batch = Firestore.batch();

	if (updatedActivities) {
		setActivitiesWithBatch(batch, updatedActivities);
	}
	return batch.commit();
};

const handleTimesheetQuerySnapshot =
	(timesheetsCallback: (sheets: Timesheet[]) => void) =>
	(
		snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
	): void => {
		const sheets: Timesheet[] = snapshot.docs.map((docSnapshot) => {
			const timesheet = docSnapshot.data() as Timesheet;
			return {
				...timesheet,
				id: docSnapshot.id,
			};
		});
		timesheetsCallback(sheets);
	};

const timesheetsByWeekEmployerStatus = (
	startDate: Date,
	endDate: Date,
	employerID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('employer.id', '==', employerID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const getTimesheetsByWeekEmployerStatus = async (
	startDate: Date,
	endDate: Date,
	employerID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
): Promise<Timesheet[]> => {
	const timesheetsSnapshot = await Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('employer.id', '==', employerID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.get();
	return timesheetsSnapshot.docs.map(
		(doc) =>
			({
				id: doc.id,
				...doc.data(),
			} as Timesheet),
	);
};

const timesheetsByWeekUserStatus = (
	startDate: Date,
	endDate: Date,
	employeeID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('employee.id', '==', employeeID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.orderBy('week', 'desc')
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const timesheetsByPayrollStatus = (
	status: Timesheet['payrollStatus'],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('payrollStatus', '==', status)
		.orderBy('week', 'desc')
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const timesheetsByInvoiceStatus = (
	status: Timesheet['invoiceStatus'],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('invoiceStatus', '==', status)
		.orderBy('week', 'desc')
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const timesheetsByWorkHistoryStatus = (
	status: Timesheet['workHistoryStatus'],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('workHistoryStatus', '==', status)
		.orderBy('week', 'desc')
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const timesheetsByWeekSiteContractedStatus = (
	startDate: Date,
	endDate: Date,
	siteID: string,
	contractedToID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('site.id', '==', siteID)
		.where('contractedTo.id', '==', contractedToID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const getTimesheetsByWeekSiteContractedStatus = async (
	startDate: Date,
	endDate: Date,
	siteID: string,
	contractedToID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
): Promise<Timesheet[]> => {
	const timesheetsSnapshot = await Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('site.id', '==', siteID)
		.where('contractedTo.id', '==', contractedToID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.get();
	return timesheetsSnapshot.docs.map(
		(doc) =>
			({
				id: doc.id,
				...doc.data(),
			} as Timesheet),
	);
};

const getTimesheetsByWeekContractedStatus = async (
	startDate: Date,
	endDate: Date,
	contractedToID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
): Promise<Timesheet[]> => {
	const timesheetsSnapshot = await Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('contractedTo.id', '==', contractedToID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.get();
	return timesheetsSnapshot.docs.map(
		(doc) =>
			({
				id: doc.id,
				...doc.data(),
			} as Timesheet),
	);
};

const timesheetsByWeekContractedStatus = (
	startDate: Date,
	endDate: Date,
	contractedToID: string,
	timesheetStatuses: ('' | TimesheetStatus)[],
	timesheetsCallback: (timesheets: Timesheet[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.where('week', '>=', startDate)
		.where('week', '<=', endDate)
		.where('contractedTo.id', '==', contractedToID)
		.where('timesheetStatus', 'in', timesheetStatuses)
		.onSnapshot(handleTimesheetQuerySnapshot(timesheetsCallback));

const updateTimesheet = (
	timesheetID: string,
	updatedTimesheet: Pick<
		Timesheet,
		'timesheetStatus' | 'lastEditedBy' | 'reviewer' | 'reviewedAt'
	>,
): Promise<void> =>
	Firestore.collection('timesheets')
		.doc(timesheetID)
		.update(updatedTimesheet);

const createTimesheet = async (
	timesheet: Omit<Timesheet, 'id'>,
	activities: Omit<Activity, 'id' | 'timesheetID'>[],
): Promise<void> => {
	const batch = Firestore.batch();

	const docRef = Firestore.collection('timesheets').doc();
	const completeTimesheet: Timesheet = { ...timesheet, id: docRef.id };
	batch.set(docRef, completeTimesheet);

	setActivitiesWithBatch(batch, activities, completeTimesheet.id);

	return await batch.commit();
};

const submittedTimesheetsByStatusSiteContractedToSubscription = (
	siteID: string,
	companyID: string,
	onNext: (timesheets: Timesheet[]) => void,
	onError?: (error: firebase.firestore.FirestoreError) => void,
): (() => void) => {
	return Firestore.collection('timesheets')
		.where('timesheetStatus', '==', 'Submitted')
		.where('site.id', '==', siteID)
		.where('contractedTo.id', '==', companyID)
		.withConverter(timesheetConverter)
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.docs.map((doc) => doc.data()));
		}, onError);
};

const submittedTimesheetsByStatusContractedToSubscription = (
	companyID: string,
	onNext: (timesheets: Timesheet[]) => void,
	onError?: (error: firebase.firestore.FirestoreError) => void,
): (() => void) => {
	return Firestore.collection('timesheets')
		.where('timesheetStatus', '==', 'Submitted')
		.where('contractedTo.id', '==', companyID)
		.withConverter(timesheetConverter)
		.onSnapshot((querySnapshot) => {
			onNext(querySnapshot.docs.map((doc) => doc.data()));
		}, onError);
};

const getTimesheetById = async (timesheetID: string): Promise<Timesheet> => {
	const doc = await Firestore.collection('timesheets').doc(timesheetID).get();
	if (doc.exists) {
		return doc.data() as Timesheet;
	} else {
		throw new Error(`Timesheet ${timesheetID} does not exist`);
	}
};

const subscribeTimesheetNotesByTimesheetID = (
	timesheetID: string,
	callback: (timesheetNotes: TimesheetNote[]) => void,
): (() => void) =>
	Firestore.collection('timesheets')
		.doc(timesheetID)
		.collection('timesheetNotes')
		.orderBy('createdAt', 'desc')
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data() as TimesheetNote)),
		);

const timesheetsFirebaseApi = {
	createTimesheet,
	findDuplicateTimesheet,
	getTimesheetsByWeekContractedStatus,
	getTimesheetsByWeekEmployerStatus,
	getTimesheetsByWeekSiteContractedStatus,
	getTimesheetById,
	setActivities,
	submittedTimesheetsByStatusContractedToSubscription,
	submittedTimesheetsByStatusSiteContractedToSubscription,
	timesheetsByWeekContractedStatus,
	timesheetsByWeekEmployerStatus,
	timesheetsByWeekSiteContractedStatus,
	timesheetsByWeekUserStatus,
	updateTimesheet,
	updateTimesheetActivities,
	updateTimesheetAndActivities,
	subscribeTimesheetNotesByTimesheetID,
	deleteTimesheet,
	timesheetsByPayrollStatus,
	timesheetsByWorkHistoryStatus,
	timesheetsByInvoiceStatus,
};

export default timesheetsFirebaseApi;
