import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Tooltip } from '@mui/material';
import { TimesheetPayrollStatus } from '../../constants/Timesheet/Timesheet';

export const PayrollStatusIcon = ({
	status,
}: {
	status: TimesheetPayrollStatus;
}): JSX.Element => {
	switch (status) {
		case 'Failed':
			return (
				<FailedTooltip>
					<Box>
						<IconButton
							size="large"
							color="error"
							disableRipple={true}
							sx={{ pointerEvents: 'none' }}>
							<ErrorIcon fontSize="large" />
						</IconButton>
					</Box>
				</FailedTooltip>
			);
		case 'Sent':
			return (
				<SuccessTooltip>
					<Box>
						<IconButton
							size="large"
							color="success"
							disableRipple={true}
							sx={{ pointerEvents: 'none' }}>
							<CheckCircleIcon fontSize="large" />
						</IconButton>
					</Box>
				</SuccessTooltip>
			);
		case 'Unsent':
			return (
				<UnsentTooltip>
					<Box>
						<IconButton
							size="large"
							color="info"
							disableRipple={true}
							sx={{ pointerEvents: 'none' }}>
							<InfoIcon fontSize="large" />
						</IconButton>
					</Box>
				</UnsentTooltip>
			);
	}
};

const FailedTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => (
	<Tooltip title="Failed to send to Payroll">{children}</Tooltip>
);

const SuccessTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => <Tooltip title="Sent to Payroll">{children}</Tooltip>;

const UnsentTooltip = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => <Tooltip title="Not sent to Payroll">{children}</Tooltip>;
