import type { UserProfile, WorkHistory } from '../../constants/Common';
import { Competency, CompetencyUpdate } from '../../constants/Competencies';
import {
	Qualification,
	QualificationUpdate,
} from '../../constants/Qualifications';
import {
	FieldValue,
	Firestore,
	FirestoreDataConverter,
	Storage,
	filterCollectionGroupOnParent,
} from '../firebase';

const USER_PROFILES_COLLECTION = 'userProfiles';
const userProfileConverter: FirestoreDataConverter<UserProfile> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as UserProfile,
};

const userProfilesByCompanySubscription = (
	companyID: string,
	callback: (userProfiles: UserProfile[]) => void,
): (() => void) =>
	Firestore.collection(USER_PROFILES_COLLECTION)
		.where('company.id', '==', companyID)
		.withConverter(userProfileConverter)
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data())),
		);

const userProfileCompetenciesSubscription = (
	userID: string,
	callback: (competencies: Competency[]) => void,
): (() => void) =>
	Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.collection('competencies')
		.onSnapshot((snapshot) =>
			callback(
				snapshot.docs.map(
					(doc) => ({ ...doc.data(), id: doc.id } as Competency),
				),
			),
		);

/** full document path is `/userProfiles/<USER_ID>/sites/<SITE_ID>/workHistory/<COMPANY_ID>`,
 * where `COMPANY_ID` is for the company you are contracted to */
const userProfileWorkHistorySubscription = (
	userID: string,
	callback: (qualifications: WorkHistory[]) => void,
): (() => void) =>
	filterCollectionGroupOnParent(
		'workHistory',
		`userProfiles/${userID}`,
	).onSnapshot((snapshot) =>
		callback(snapshot.docs.map((doc) => doc.data() as WorkHistory)),
	);

const userProfileQualificationsSubscription = (
	userID: string,
	callback: (qualifications: Qualification[]) => void,
): (() => void) =>
	Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.collection('qualifications')
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data() as Qualification)),
		);

const saveCompetencies = async (
	userID: string,
	competenciesUpdates: Record<string, CompetencyUpdate>,
): Promise<void> => {
	const batch = Firestore.batch();
	const collection = Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.collection('competencies');
	for (const [id, competencyUpdate] of Object.entries(competenciesUpdates)) {
		const competencyDoc = collection.doc(id);
		if (competencyUpdate.type === 'delete') {
			batch.delete(competencyDoc);
		} else if (competencyUpdate.type === 'update') {
			const update: Pick<Competency, 'owned'> =
				competencyUpdate.competencyUpdate;
			batch.update(competencyDoc, update);
		} else if (competencyUpdate.type === 'new') {
			const newCompetency: Competency = competencyUpdate.competencyUpdate;
			batch.set(competencyDoc, newCompetency);
		}
	}
	await batch.commit();
};

const saveQualificationUpdates = async (
	userID: string,
	qualificationUpdates: Record<string, QualificationUpdate>,
): Promise<void> => {
	const batch = Firestore.batch();

	// promise.all and map to improve performance in cases where we have multiple file uploads/deletions
	await Promise.all(
		Object.entries(qualificationUpdates).map(
			async ([id, qualificationUpdate]) => {
				const qualificationDoc = Firestore.collection(
					USER_PROFILES_COLLECTION,
				)
					.doc(userID)
					.collection('qualifications')
					.doc(id);

				if (
					qualificationUpdate.type === 'delete' &&
					qualificationUpdate.hasEvidence
				) {
					await Storage.ref()
						.child(
							`qualifications/${userID}/${qualificationUpdate.id}`,
						)
						.delete();

					batch.delete(qualificationDoc);
				} else if (qualificationUpdate.type === 'delete') {
					batch.delete(qualificationDoc);
				} else if (
					qualificationUpdate.type === 'new' &&
					qualificationUpdate.newQualification.evidence
				) {
					const { evidence, ...qualification } =
						qualificationUpdate.newQualification;
					const storageRef = Storage.ref().child(
						`qualifications/${userID}/${qualification.id}`,
					);

					await storageRef.put(evidence);

					const evidenceUrl: string =
						await storageRef.getDownloadURL();
					qualification.evidenceUrl = evidenceUrl;
					batch.set(qualificationDoc, qualification);
				} else if (qualificationUpdate.type === 'new') {
					const newQualification: Qualification =
						qualificationUpdate.newQualification;
					batch.set(qualificationDoc, newQualification);
				}
			},
		),
	);

	await batch.commit();
};

const updateUserProfile = async (
	userID: string,
	updatedUserProfile: Partial<UserProfile>,
): Promise<void> => {
	await Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.update(updatedUserProfile);
};

const deleteUserProfileCV = async (userID: string): Promise<void> => {
	const storageRef = Storage.ref().child(`cvs/${userID}`);
	await storageRef.delete();
	await Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.update({ cv: FieldValue.delete() });
};

const updateUserProfileCompany = async (
	userID: string,
	updatedUserProfile: Pick<UserProfile, 'company'>,
): Promise<void> =>
	await Firestore.collection(USER_PROFILES_COLLECTION)
		.doc(userID)
		.update(updatedUserProfile);

const userProfilesFirebaseApi = {
	deleteUserProfileCV,
	saveCompetencies,
	saveQualificationUpdates,
	updateUserProfile,
	updateUserProfileCompany,
	userProfileCompetenciesSubscription,
	userProfileQualificationsSubscription,
	userProfilesByCompanySubscription,
	userProfileWorkHistorySubscription,
};

export default userProfilesFirebaseApi;
