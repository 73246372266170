import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Checkbox, Grid, Typography } from '@mui/material';
import { Competency } from '../../../constants/Competencies';
import { IndentedCardList } from '../Common/IndentedCardList';

type CompetencyListProps = {
	competencies: Competency[];
	onUpdate: (id: string, owned: boolean) => void;
	onDelete: (id: string) => void;
	editing: boolean;
};

export const CompetencyList = ({
	competencies,
	editing,
	onUpdate,
	onDelete,
}: CompetencyListProps): JSX.Element => {
	const row = (competency: Competency): JSX.Element => (
		<Grid container columns={12} alignItems="center">
			<Grid item xs={9}>
				<Typography variant="body1Bold">{competency.tool}</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant="body1">
					{competency.owned ? 'Owned' : 'Not Owned'}
				</Typography>
			</Grid>
			{editing ? (
				<Grid item xs={1}>
					<Checkbox
						value={competency.owned}
						checked={competency.owned}
						onChange={(event): void =>
							onUpdate(competency.id, event.currentTarget.checked)
						}
					/>
				</Grid>
			) : (
				<Grid item xs={1}>
					{competency.owned ? (
						<CheckCircleIcon color="success">Yes</CheckCircleIcon>
					) : (
						<CancelIcon color="error">No</CancelIcon>
					)}
				</Grid>
			)}
		</Grid>
	);

	return (
		<IndentedCardList
			list={competencies}
			editing={editing}
			card={{
				content: row,
				onDelete: (competency) => onDelete(competency.id),
			}}
		/>
	);
};
