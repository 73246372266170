import { Dialog, DialogContent, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserDetails, UserProfile } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { sortObjectByField } from '../helpers/sortHelpers';
import { HandlerEmployeeProfile } from './HandlerEmployeeProfile';
import { HandlerEmployeeSummaryCard } from './HandlerEmployeeSummaryCard';

type HandlerEmployeesProps = {
	userDetails: UserDetails;
};

export const HandlerEmployees = ({
	userDetails,
}: HandlerEmployeesProps): JSX.Element => {
	const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
	const [currentEmployee, setCurrentEmployee] = useState<UserProfile | null>(
		null,
	);
	const [employees, setEmployees] = useState<Record<string, UserProfile>>({});
	const [companyUserIDs, setCompanyUserIDs] = useState<string[]>([]);
	const [companyUserIDsLoaded, setCompanyUserIDsLoaded] = useState(false);
	const [shouldSetCurrentEmployee, setShouldSetCurrentEmployee] =
		useState(false);

	useEffect(() => {
		return firebaseApi.subscribeUserIDsByCompany(
			userDetails.companyID,
			(userIDs) => {
				setCompanyUserIDs(userIDs);
				setCompanyUserIDsLoaded(true);
			},
		);
	}, [userDetails.companyID]);

	useEffect(() => {
		if (!companyUserIDsLoaded) {
			return;
		}
		return firebaseApi.userProfilesByCompanySubscription(
			userDetails.companyID,
			(userProfiles) => {
				const fetchedEmployees = Object.fromEntries(
					userProfiles
						.filter((profile) =>
							// Only show employees that are in the companyUserIDs list
							// Current stopgap solution while we don't keep the profiles in sync
							companyUserIDs.includes(profile.id),
						)
						.map((userProfile) => [userProfile.id, userProfile]),
				);
				const sortedEmployees = sortObjectByField(
					fetchedEmployees,
					'displayName',
				);
				setEmployees(sortedEmployees);
				setShouldSetCurrentEmployee(true);
			},
		);
	}, [companyUserIDs, companyUserIDsLoaded, userDetails.companyID]);

	useEffect(() => {
		if (
			shouldSetCurrentEmployee &&
			currentEmployee?.id &&
			currentEmployee?.id in employees
		) {
			setCurrentEmployee(employees[currentEmployee?.id]);
			setShouldSetCurrentEmployee(false);
		}
	}, [currentEmployee?.id, employees, shouldSetCurrentEmployee]);

	const handleEmployeeCancelClick = (): void => {
		setEmployeeDialogOpen(false);
	};

	const renderContent = (): JSX.Element[] => {
		return Object.values(employees).map((item) => {
			return (
				<Grid item xs={3} key={item.id}>
					<HandlerEmployeeSummaryCard
						employee={item}
						setCurrentEmployee={setCurrentEmployee}
						setEmployeeDialogOpen={setEmployeeDialogOpen}
					/>
				</Grid>
			);
		});
	};

	const renderEmployeeDialog = (): JSX.Element => (
		<Dialog
			onClose={handleEmployeeCancelClick}
			open={employeeDialogOpen}
			maxWidth="md"
			fullWidth>
			<DialogContent>
				{currentEmployee && (
					<HandlerEmployeeProfile
						employee={currentEmployee}
						close={handleEmployeeCancelClick}
						isModal
						firebaseApi={firebaseApi}
					/>
				)}
			</DialogContent>
		</Dialog>
	);

	return (
		<>
			{renderEmployeeDialog()}
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</>
	);
};
