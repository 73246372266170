import { SiteLink } from '../../../../models/Integrations/ProjectTrackingIntegration';
import { IntegrationLinkStatus } from '../../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';

export type TempSiteLink = SiteLink | { id: string };

export type SiteIntegrationTableRow = {
	id: string;
	name: string;
	status: IntegrationLinkStatus;
	job: TempSiteLink;
};

export const isSiteLink = (value: TempSiteLink): value is SiteLink =>
	(value as SiteLink).name !== undefined;
