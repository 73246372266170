import { AllowDateOrTimestamp } from '../../components/helpers/dateUtilities';
import { UserDetails } from '../../constants/Common';
import { Leave, ReviewStatus } from '../../constants/Leave';
import { LeaveNote } from '../../constants/Note';
import { Firestore, QuerySnapshot, Timestamp, WriteBatch } from '../firebase';

const reviewLeave = async (
	id: string,
	reviewStatus: ReviewStatus,
	reviewer: UserDetails,
): Promise<void> => {
	const updateLeave: AllowDateOrTimestamp<
		Pick<Leave, 'reviewStatus' | 'reviewer' | 'reviewedAt'>
	> = {
		reviewedAt: new Date(),
		reviewStatus,
		reviewer: { name: reviewer.displayName, id: reviewer.userID },
	};

	return Firestore.collection('leave').doc(id).update(updateLeave);
};

const subLeaveByPayrollStatus = (
	payrollStatus: Leave['payrollStatus'],
	callback: (leaveItems: Leave[]) => void,
): (() => void) =>
	Firestore.collection('leave')
		.where('payrollStatus', '==', payrollStatus)
		.orderBy('dateRequested', 'desc')
		.onSnapshot((querySnapshot) => {
			const leaveItems: Leave[] = querySnapshot.docs.map(
				(snapshot) => snapshot.data() as Leave,
			);
			callback(leaveItems);
		});

const subLeaveByStatus = (
	reviewStatus: ReviewStatus[],
	companyID: string,
	dateRange: [Date, Date] | null,
	callback: (leaveItems: Leave[]) => void,
): (() => void) => {
	let query = Firestore.collection('leave')
		.where('employer.id', '==', companyID)
		.where('reviewStatus', 'in', reviewStatus);
	if (dateRange) {
		query = query
			.where('startDate', '>=', dateRange[0])
			.where('startDate', '<=', dateRange[1]);
	}

	return query.orderBy('startDate', 'desc').onSnapshot((querySnapshot) => {
		const leaveItems: Leave[] = querySnapshot.docs.map(
			(snapshot) => snapshot.data() as Leave,
		);
		callback(leaveItems);
	});
};

const subLeaveNotesByLeaveID = (
	leaveID: string,
	callback: (leaveNotes: LeaveNote[]) => void,
): (() => void) =>
	Firestore.collection('leave')
		.doc(leaveID)
		.collection('leaveNotes')
		.orderBy('createdAt', 'desc')
		.onSnapshot((snapshot) =>
			callback(snapshot.docs.map((doc) => doc.data() as LeaveNote)),
		);

export const deleteLeaveWithBatch = (
	batch: WriteBatch,
	leaveIDs: string[],
): void =>
	leaveIDs.forEach((id) =>
		batch.delete(Firestore.collection('leave').doc(id)),
	);

const getLeaveByUserTimesheetWeek = async (
	userID: string,
	timesheetID: string,
	week: Date | Timestamp,
): Promise<QuerySnapshot> =>
	await Firestore.collection('leave')
		.where('workerID', '==', userID)
		.where('timesheetID', '!=', timesheetID)
		.where('week', '==', week)
		.get();

const leaveFirebaseApi = {
	reviewLeave,
	subLeaveByStatus,
	subLeaveNotesByLeaveID,
	subLeaveByPayrollStatus,
	getLeaveByUserTimesheetWeek,
};

export default leaveFirebaseApi;
