import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton } from '@mui/material';

type AddNewProps = {
	onClick?: () => void | undefined;
	type: string | undefined;
	title?: string;
	disabled?: boolean;
};

export const AddNew = ({
	onClick,
	type,
	title,
	disabled,
}: AddNewProps): JSX.Element =>
	title === undefined ? (
		<IconButton
			onClick={onClick}
			disabled={disabled}
			hidden={type === 'current' ? false : true}>
			<AddIcon />
		</IconButton>
	) : (
		<Button
			fullWidth
			sx={{ minWidth: 'fit-content' }}
			variant="outlined"
			onClick={onClick}
			disabled={disabled}>
			{title}
		</Button>
	);
