// Function helpers for strings that are useful and shared across the app
export const capitaliseWords = (words: string): string => {
	if (words.split(' ')) {
		const splitWords = words.split(' ');
		for (let i = 0; i < splitWords.length; i++) {
			splitWords[i] =
				splitWords[i][0].toUpperCase() + splitWords[i].substring(1);
		}
		return splitWords.join(' ').trim();
	} else {
		return words.trim();
	}
};

export const toTitleCase = (name: string): string =>
	name
		.split(' ')
		.map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
		.join(' ');
