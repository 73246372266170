import MUIDataTable, {
	MUIDataTableColumnDef,
	MUIDataTableOptions,
} from 'mui-datatables';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type DataTableProps = {
	tableData: Array<object | number[] | string[]>;
	columns: MUIDataTableColumnDef[];
	title: React.ReactNode;
	customTableOptions?: MUIDataTableOptions;
	selection?: [number | null, ((selected: number) => void) | null];
	customToolbar?: () => React.ReactNode;
	loading?: boolean;
};

export const DataTable = ({
	tableData,
	columns,
	title,
	customTableOptions,
	selection,
	customToolbar,
	loading,
}: DataTableProps): JSX.Element => {
	const [selected, setSelected] = selection ? selection : [null, null];

	const tableOptions: MUIDataTableOptions = {
		tableBodyHeight: 'calc(100vh - 225px)',
		selectableRows: 'single',
		elevation: 1,
		viewColumns: false,
		rowsPerPage: 100,
		rowsPerPageOptions: [25, 50, 100],
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		rowsSelected: [selected],
		onTableChange: (action, tableState) => {
			const item = tableState.selectedRows.data[0] as unknown as {
				dataIndex: number;
				index: number;
			};
			if (item && action === 'rowSelectionChange' && setSelected) {
				setSelected(item.dataIndex);
			}
		},
		customToolbar: customToolbar ?? undefined,
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, there is no matching data to display'
				),
			},
		},
		...customTableOptions,
	};

	return (
		<MUIDataTable
			title={title}
			data={loading ? [] : tableData}
			columns={columns.map((column) =>
				typeof column === 'string'
					? column
					: {
							label: column.label,
							name: column.name,
							options: {
								sortThirdClickReset: true,
								...column.options,
							},
					  },
			)}
			options={{ ...tableOptions, rowsSelected: [selected] }}
		/>
	);
};
