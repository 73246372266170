import { Box, Grid, Typography } from '@mui/material';
import { startOfToday } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import {
	UserDetails,
	accountTypes,
	chunks,
	emptyFunction,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { setDifference } from '../helpers/setHelpers';

type BaseDashboardProps = {
	userDetails: UserDetails;
	siteIDs?: string[];
};

type DashboardFunctionsProps = BaseDashboardProps & {
	firebaseApi: Pick<
		FirebaseApi,
		| 'activeSitesCountByCompanySubscription'
		| 'submittedTimesheetsByStatusSiteContractedToSubscription'
		| 'submittedTimesheetsByStatusContractedToSubscription'
		| 'userByAccountType'
		| 'signInsByCompanyDaySubscription'
		| 'signInsByCompanyDayCountSubscription'
		| 'subscribeUsersByCompany'
		| 'signInsBySiteDayCountSubscription'
	>;
};

const startDate = startOfToday();

export const GetSites = ({
	userDetails,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [activeSites, setActiveSites] = useState<number>();

	useEffect(() => {
		firebaseApi.activeSitesCountByCompanySubscription(
			userDetails.companyID,
			(sites) => {
				setActiveSites(sites);
			},
		);
	}, [firebaseApi, userDetails.companyID]);
	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{activeSites}
			</Typography>
			<Typography variant="h5">Active sites</Typography>
		</Grid>
	);
};

export const GetSitesBySiteLog = ({
	userDetails,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [activeSites, setActiveSites] = useState<number>();

	useEffect(() => {
		const today = new Date();
		today.setHours(0);
		firebaseApi.signInsByCompanyDaySubscription(
			userDetails.companyID,
			startDate,
			(siteLogs) => {
				const siteIds = siteLogs.map((siteLog) => siteLog.siteID);
				setActiveSites([...new Set(siteIds)].length);
			},
		);
	}, [firebaseApi, userDetails.companyID]);
	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{activeSites}
			</Typography>
			<Typography variant="h5">Active sites today</Typography>
		</Grid>
	);
};

export const GetTimesheets = ({
	userDetails,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [forReview, setForReview] = useState<number>();

	const filterBySiteContractedTo = userDetails.accountType === 'management';
	const filterByContractedTo = userDetails.accountType === 'seniorManagement';

	useEffect(() => {
		if (filterBySiteContractedTo) {
			return firebaseApi.submittedTimesheetsByStatusSiteContractedToSubscription(
				userDetails.siteID,
				userDetails.companyID,
				(timesheets) => {
					setForReview(timesheets.length);
				},
			);
		} else if (filterByContractedTo) {
			return firebaseApi.submittedTimesheetsByStatusContractedToSubscription(
				userDetails.companyID,
				(timesheets) => {
					setForReview(timesheets.length);
				},
			);
		}
	}, [
		filterByContractedTo,
		filterBySiteContractedTo,
		firebaseApi,
		userDetails.companyID,
		userDetails.siteID,
	]);
	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{forReview}
			</Typography>
			<Typography variant="h5">Timesheets to be reviewed</Typography>
		</Grid>
	);
};

export const GetSignIn = ({
	userDetails,
	siteIDs,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [numberSignIn, setNumberSignIn] = useState<number>();
	useEffect(() => {
		const fetchSigninLogs = (): (() => void) => {
			if (siteIDs !== undefined && siteIDs?.length === 0) {
				// empty array so nothing to do
				setNumberSignIn(0);
				return emptyFunction;
			} else if (siteIDs !== undefined) {
				// We have a list of sites to do an "in" query for
				const subscriptions = [...chunks(siteIDs)].flatMap((chunk) => {
					return chunk.map((siteID) => {
						return firebaseApi.signInsBySiteDayCountSubscription(
							siteID,
							startDate,
							(count) => {
								setNumberSignIn((prev) => (prev || 0) + count);
							},
						);
					});
				});

				return () =>
					subscriptions.forEach((unsubscribe) => unsubscribe());
			} else {
				return firebaseApi.signInsBySiteDayCountSubscription(
					userDetails.siteID,
					startDate,
					(count) => {
						setNumberSignIn((prev) => (prev || 0) + count);
					},
				);
			}
		};

		return fetchSigninLogs();
	}, [firebaseApi, siteIDs, userDetails.siteID]);

	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{numberSignIn}
			</Typography>
			<Typography variant="h5">People signed in today</Typography>
		</Grid>
	);
};

export const getMonday = (): Date => {
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);
	const day = currentDate.getDay();
	const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
	return new Date(currentDate.setDate(diff));
};

export const GetEmployeesSignIn = ({
	userDetails,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [employeesSignIn, setEmployeesSignIn] = useState<number>();
	useEffect(() => {
		return firebaseApi.signInsByCompanyDayCountSubscription(
			userDetails.companyID,
			startDate,
			(count) => {
				setEmployeesSignIn(count);
			},
		);
	}, [firebaseApi, userDetails.companyID]);
	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{employeesSignIn}
			</Typography>
			<Typography variant="h5">People signed in today</Typography>
		</Grid>
	);
};

export const GetEmployeeAttendance = ({
	userDetails,
	firebaseApi,
}: DashboardFunctionsProps): JSX.Element => {
	const [numberEmployees, setNumberEmployees] = useState<number>(0);
	const [attendingEmployees, setAttendingEmployees] = useState<number>(0);
	const [companyhandlers, setCompanyHandlerCount] = useState<string[]>([]);
	const mountedRef = useRef(true);

	useEffect(() => {
		mountedRef.current = true;
		const fetchHandlers = async (): Promise<void> => {
			const handlers = await firebaseApi.userByAccountType(
				userDetails.companyID,
				accountTypes.handler,
			);

			if (mountedRef.current) {
				setCompanyHandlerCount(
					handlers.map((handler) => handler.userID),
				);
			}
		};

		fetchHandlers();
		return () => {
			mountedRef.current = false;
		};
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		firebaseApi.signInsByCompanyDaySubscription(
			userDetails.companyID,
			startDate,
			(siteLogs) => {
				const employeeIDs = siteLogs.map((siteLog) => siteLog.workerID);
				const excludingHandlers = setDifference(
					employeeIDs,
					companyhandlers,
				);
				setAttendingEmployees(excludingHandlers.length);
			},
		);
	}, [companyhandlers, firebaseApi, userDetails.companyID]);

	useEffect(() => {
		firebaseApi.subscribeUsersByCompany(userDetails.companyID, (users) => {
			setNumberEmployees(
				Object.values(users).length - companyhandlers.length,
			);
		});
	}, [companyhandlers.length, firebaseApi, userDetails.companyID]);

	return (
		<Grid container direction="column" alignItems="start">
			<Typography variant="h2" color="primary">
				{Math.round((attendingEmployees / numberEmployees) * 100) || 0}%
			</Typography>
			<Typography variant="h5">Attendance</Typography>
		</Grid>
	);
};

export const GetGreetings = (props: BaseDashboardProps): JSX.Element => {
	const [greeting, setGreeting] = useState('Hello');

	useEffect(() => {
		const today = new Date();
		const curHr = today.getHours();
		if (curHr < 12) {
			setGreeting('Good Morning');
		} else if (curHr < 18) {
			setGreeting('Good Afternoon');
		} else {
			setGreeting('Good Evening');
		}
		return;
	}, []);

	return (
		<Typography variant="h3">
			{greeting}{' '}
			<Box component="span" sx={{ color: 'primary.main' }}>
				{props.userDetails.firstname ?? props.userDetails.displayName}
			</Box>
		</Typography>
	);
};
