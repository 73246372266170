import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { DataTable } from './DataTable';
import { DateDataTable } from './DateDataTable';

type ObjectWithID = {
	id: string;
};

type DateDataTableWithIDProps<T extends ObjectWithID> = {
	tableData: T[];
	selection?: [string | null, (selected: string) => void];
	date?: [Date, (date: Date) => void];
	columns: MUIDataTableColumnDef[];
	title: React.ReactNode;
	customTableOptions?: MUIDataTableOptions;
	onClick?: () => void;
	type?: string;
	allowFuture?: boolean;
	weekEnding?: boolean;
	showWeekRange?: boolean;
};

export const DateDataTableWithID = <T extends ObjectWithID>({
	selection,
	date,
	...props
}: DateDataTableWithIDProps<T>): JSX.Element => {
	const getSelected = (): number => {
		if (!selection) return -1;

		const [selected] = selection;

		return props.tableData.findIndex((item) => item.id === selected);
	};

	const setSelected = (selected: number): void => {
		if (!selection) return;
		const [, setSelection] = selection;

		const selectedValue = props.tableData[selected];

		if (selectedValue) {
			setSelection(selectedValue.id);
		}
	};

	return date ? (
		<DateDataTable
			date={date}
			{...props}
			selection={[getSelected(), setSelected]}
			weekEnding={props.weekEnding}
			showWeekRange={props.showWeekRange}
		/>
	) : (
		<DataTable {...props} selection={[getSelected(), setSelected]} />
	);
};
