import { Autocomplete, TextField } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';

export const reportsCustomTableFilter: NonNullable<
	NonNullable<
		NonNullable<MUIDataTableColumn['options']>['filterOptions']
	>['display']
> = (filterList, onChange, index, column, filterData) => (
	<Autocomplete
		renderInput={(params): JSX.Element => (
			<TextField {...params} label={column.label} />
		)}
		value={filterList[index]}
		size="small"
		multiple
		onChange={(_, value): void => onChange(value, index, column)}
		options={filterData[index]}
		getOptionLabel={(option): string => option}
	/>
);
