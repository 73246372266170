import { UserProps } from '../../../constants/Common';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useReportsContext } from '../ReportsPageWrapper';
import { ProjectTrackingReportFirebaseCalls } from '../StateManagement/models';
import { ProjectTrackingReport } from './ProjectTrackingReport';

type AvailableFirebaseCalls = Extract<
	keyof FirebaseApi,
	ProjectTrackingReportFirebaseCalls
>;

export const ProjectTrackingReportWrapper = ({
	userDetails,
}: UserProps): JSX.Element => {
	const {
		context: { startDate, endDate, firebaseApi },
		dispatch,
	} = useReportsContext<AvailableFirebaseCalls>();

	return (
		<ProjectTrackingReport
			startDateContext={{
				date: startDate,
				setDate: (value): void => dispatch({ key: 'startDate', value }),
			}}
			endDateContext={{
				date: endDate,
				setDate: (value): void => dispatch({ key: 'endDate', value }),
			}}
			userDetails={userDetails}
			firebaseApi={firebaseApi}
		/>
	);
};
