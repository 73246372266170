import { TextField, MenuItem } from '@mui/material';
import { ChangeEventHandler, memo } from 'react';
import { AutoSignOutTimes } from '../../../../constants/Common';
import CustomTimePicker from '../../../CustomTimePicker/CustomTimePicker';
import { PhoneNumberInput } from '../../../PhoneNumberInput/PhoneNumberInput';
import { EditableSiteFields, siteDisplayFields } from './sitePanelHelpers';

export type SitePanelFieldsProps = {
	site: Omit<
		EditableSiteFields,
		| 'hasInductions'
		| 'safetyCourseRequiredForInduction'
		| 'allowAppSignIn'
		| 'showCovidWarning'
	>;
	field: keyof Omit<
		EditableSiteFields,
		| 'hasInductions'
		| 'safetyCourseRequiredForInduction'
		| 'allowAppSignIn'
		| 'showCovidWarning'
	>;
	label: string;
	isError: boolean;
	disabled: boolean;
	handleDetailChange: (value: string, key: keyof EditableSiteFields) => void;
};

const SitePanelFieldsImpl = ({
	field,
	site,
	label,
	isError,
	disabled,
	handleDetailChange,
}: SitePanelFieldsProps): JSX.Element => {
	switch (field) {
		case 'siteContactNumber': {
			const handleOnChange = (
				event:
					| string
					| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
			): void => {
				if (typeof event === 'string') {
					handleDetailChange(event, field);
				}
			};

			return (
				<PhoneNumberInput
					value={site[field]}
					required
					onChange={handleOnChange}
					label="Site Contact Number (required)"
					helperText={isError ? 'Invalid Site Contact Number' : ''}
					disabled={disabled}
					size="small"
				/>
			);
		}

		case 'autoSignOutTime': {
			const handleOnChange: ChangeEventHandler<
				HTMLInputElement | HTMLTextAreaElement
			> = (event) => handleDetailChange(event.target.value, field);
			const inputLabelProps = { shrink: true };

			const renderOptions = (): JSX.Element[] =>
				Object.entries(AutoSignOutTimes).map(([key, val]) => (
					<MenuItem key={key} value={key}>
						{val}
					</MenuItem>
				));

			return (
				<TextField
					select
					id="filled-basic"
					label={label}
					required
					variant="outlined"
					fullWidth
					size="small"
					value={site[field]}
					onChange={handleOnChange}
					disabled={disabled}
					InputLabelProps={inputLabelProps}
					error={isError}
					helperText={
						isError
							? 'Invalid Auto Sign Out Time (Time must be 12hr and in 5min increments)'
							: ''
					}>
					{renderOptions()}
				</TextField>
			);
		}
		case 'startTime': {
			const handleOnChange = (value: number | null): void => {
				handleDetailChange(String(value), field);
			};

			return (
				<CustomTimePicker
					label="Start Time *"
					value={site[field]}
					onChange={handleOnChange}
					disabled={disabled}
					helperText={
						isError ? `Invalid ${siteDisplayFields[field]}` : ''
					}
					error={isError}
					size="small"
				/>
			);
		}
		default: {
			const handleOnChange: ChangeEventHandler<
				HTMLInputElement | HTMLTextAreaElement
			> = (event) => handleDetailChange(event.target.value, field);
			const inputLabelProps = { shrink: true };

			return (
				<TextField
					label={`${label} (required)`}
					required
					variant="outlined"
					fullWidth
					value={site[field]}
					onChange={handleOnChange}
					disabled={disabled}
					InputLabelProps={inputLabelProps}
					error={isError}
					helperText={
						isError ? `Invalid ${siteDisplayFields[field]}` : ''
					}
					size="small"
				/>
			);
		}
	}
};

const propsAreEqual = (
	prevProps: Readonly<SitePanelFieldsProps>,
	nextProps: Readonly<SitePanelFieldsProps>,
): boolean =>
	prevProps.field === nextProps.field &&
	prevProps.site[prevProps.field] === nextProps.site[nextProps.field] &&
	prevProps.label === nextProps.label &&
	prevProps.isError === nextProps.isError &&
	prevProps.disabled === nextProps.disabled;

export const SitePanelFields = memo(SitePanelFieldsImpl, propsAreEqual);
