import {
	Box,
	Typography,
	TextField,
	Autocomplete,
	MenuItem,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FixMeLater } from '../../../../../constants/AnyTypes';
import { UserDetails } from '../../../../../constants/Common';
import { NewInductionValidationErrors } from '../../../../../constants/InductionEntry';

export type NewUserInductionProps = {
	users: Record<string, UserDetails>;
	selectedUser: string;
	setSelectedUser: Dispatch<SetStateAction<string>>;
	userError: boolean;
	setValidationErrors: Dispatch<SetStateAction<NewInductionValidationErrors>>;
	disableUserSelect: boolean;
};

export const NewUserInduction = ({
	users,
	selectedUser,
	setSelectedUser,
	userError,
	setValidationErrors,
	disableUserSelect,
}: NewUserInductionProps): JSX.Element => (
	<Box>
		<Typography variant="subtitle1" pb={0.5}>
			Inductee
		</Typography>
		<Autocomplete
			options={Object.values(users)}
			getOptionLabel={(option): string => option.displayName}
			fullWidth
			onChange={(_, value): void => {
				setSelectedUser(value?.userID ?? '');
				setValidationErrors((prevState) => ({
					...prevState,
					selectedUser: false,
				}));
			}}
			disabled={disableUserSelect}
			value={users[selectedUser] ?? null}
			renderOption={(props: FixMeLater, option): JSX.Element => (
				<MenuItem
					{...props}
					label={option.displayName}
					variant="standard"
					key={option.userID}>
					{option.displayName || 'No Users'}
				</MenuItem>
			)}
			renderInput={(params): JSX.Element => (
				<TextField
					{...params}
					placeholder="Select Users Name"
					inputProps={{
						...params.inputProps,
					}}
					size="small"
					error={userError}
					helperText={
						userError
							? 'User is required'
							: disableUserSelect
							? 'Site is required'
							: ''
					}
				/>
			)}
		/>
	</Box>
);
