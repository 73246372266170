import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { UserDetails } from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { usePageConfigContext } from '../../providers/pageConfig/context';
import { MenuPreferenceToggle } from './MenuPreferenceToggle';

type MenuPreferencesFirebaseCalls = 'updatedUserDetailsMenuPreferences';

export type MenuPreferencesProps = {
	userDetails: UserDetails;
	firebaseApi: Pick<FirebaseApi, MenuPreferencesFirebaseCalls>;
};

export const MenuPreferences = ({
	userDetails,
	firebaseApi,
}: MenuPreferencesProps): JSX.Element => {
	const pages = usePageConfigContext();
	const [toggleState, setToggleState] = useState(
		userDetails.hiddenMenuItemsPreferences ?? {},
	);

	const updateUsersHiddenMenuItemsPreferences = async (
		updatedToggleState: Record<string, true>,
	): Promise<void> => {
		if (Object.keys(updatedToggleState).length === 0) {
			await firebaseApi.updatedUserDetailsMenuPreferences(
				userDetails.userID,
				{
					hiddenMenuItemsPreferences: undefined,
				},
			);
		} else {
			await firebaseApi.updatedUserDetailsMenuPreferences(
				userDetails.userID,
				{
					hiddenMenuItemsPreferences: updatedToggleState,
				},
			);
		}
	};

	const toggleHideMenuItem = async (menuItemName: string): Promise<void> => {
		const updatedToggles = { ...toggleState };
		if (menuItemName in toggleState) {
			delete updatedToggles[menuItemName];
		} else {
			updatedToggles[menuItemName] = true;
		}
		setToggleState(updatedToggles);
		await updateUsersHiddenMenuItemsPreferences(updatedToggles);
	};

	return (
		<Paper>
			<Box padding={2} flexGrow={1}>
				<Typography>
					Customise which pages you want to show in the menu
				</Typography>
				<Divider sx={{ marginY: 2 }} />
				<Grid container spacing={2}>
					{pages
						.filter((page) => page.showInDrawer)
						.map((page) => (
							<Grid
								item
								key={page.name}
								lg={4}
								md={6}
								sm={12}
								xs={12}>
								<MenuPreferenceToggle
									name={page.name}
									icon={page.icon}
									toggled={!(page.name in toggleState)}
									toggle={async (): Promise<void> =>
										await toggleHideMenuItem(page.name)
									}
								/>
							</Grid>
						))}
				</Grid>
			</Box>
		</Paper>
	);
};
