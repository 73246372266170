import { Box, Tabs, Tab } from '@mui/material';
import { Outlet, useMatch } from 'react-router';
import { useSearchParams, Link } from 'react-router-dom';

export type TriggerStatuses = 'loading' | 'done' | 'error' | 'none';

export const FailedAutomations = (): JSX.Element => {
	const pathMatch = useMatch('/failed-automations/:tab');
	const [searchParams] = useSearchParams();

	const titles = ['leave', 'payroll timesheets', 'work history', 'invoices'];

	return (
		<>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
					{titles.map((title, index) => (
						<Tab
							label={title}
							value={title}
							key={index}
							component={Link}
							to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
						/>
					))}
				</Tabs>
			</Box>
			<Outlet />
		</>
	);
};
