import { SxProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { stringToColor } from '../helpers/colorHelpers';

type StringAvatarProps = {
	name: string;
};

type AvatarProps = {
	sx: SxProps;
	children: string;
};

export const StringAvatar = ({ name }: StringAvatarProps): JSX.Element => {
	const avatarProps = (name: string): AvatarProps => {
		const names = name.split(' ');
		const avatarColor = stringToColor(name);
		return {
			sx: {
				bgcolor: avatarColor,
				opacity: '50%',
				color: 'white',
			},
			// Get first and last initials found, only use first if one found, question mark if none present
			children: `${names[0] ? names[0][0].toLocaleUpperCase() : '?'}${
				names.length > 1
					? names[names.length - 1][0].toLocaleUpperCase()
					: ''
			}`,
		};
	};
	return <Avatar {...avatarProps(name)} />;
};
