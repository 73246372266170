import BusinessIcon from '@mui/icons-material/Business';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DefaultTheme } from '../../../../Theme';

export type TimesheetContextCardProps = {
	context: {
		employerName: string;
		siteName: string;
	};
};

export const TimesheetContextCard = ({
	context,
}: TimesheetContextCardProps): JSX.Element => (
	<Stack direction="row" spacing={2} alignItems="center" marginY={1}>
		<Avatar
			sx={{
				backgroundColor: DefaultTheme.palette.neutral.main,
				color: DefaultTheme.palette.info.contrastText,
			}}>
			<BusinessIcon color="inherit" />
		</Avatar>
		<Box>
			<Typography variant="h7Bold">Site</Typography>
			<Typography fontSize="small">{context.siteName}</Typography>
			<Typography variant="h7Bold">Employer</Typography>
			<Typography fontSize="small">{context.employerName}</Typography>
		</Box>
	</Stack>
);
