import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { endOfWeek, startOfToday, startOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import cloudFunctionApi from '../../cloudfunctions';
import { UserProps } from '../../constants/Common';
import { Leave, ReviewStatus } from '../../constants/Leave';
import { LeaveNote } from '../../constants/Note';
import firebaseApi, { FirebaseApi } from '../../firebase/firebaseApi';
import { LeaveReviewTable } from './LeaveReviewTable';

enum TabEnum {
	Unreviewed = '1',
	Reviewed = '2',
}

const LeaveReview = ({ userDetails, user }: UserProps): JSX.Element => {
	const leaveReviewFirebaseApi: Pick<
		FirebaseApi,
		'reviewLeave' | 'subLeaveByStatus' | 'subLeaveNotesByLeaveID'
	> = firebaseApi;
	const [leaveMapActive, setLeaveMapActive] = useState<Leave[]>([]);
	const [leaveMapReviewed, setLeaveMapReviewed] = useState<Leave[]>([]);
	const [tab, setTab] = useState(TabEnum.Unreviewed);
	const [startDate, setStartDate] = useState(startOfWeek(startOfToday()));

	const [leaveNotes, setLeaveNotes] = useState<Record<string, LeaveNote[]>>(
		{},
	);

	useEffect(() => {
		return leaveReviewFirebaseApi.subLeaveByStatus(
			[ReviewStatus.Submitted],
			userDetails.companyID,
			null,
			(leaveItems) => setLeaveMapActive(leaveItems),
		);
	}, [leaveReviewFirebaseApi, userDetails]);

	useEffect(() => {
		const endDate = endOfWeek(startDate);
		return leaveReviewFirebaseApi.subLeaveByStatus(
			[ReviewStatus.Approved, ReviewStatus.Disputed],
			userDetails.companyID,
			[startDate, endDate],
			(leaveItems) => setLeaveMapReviewed(leaveItems),
		);
	}, [leaveReviewFirebaseApi, startDate, userDetails]);

	useEffect(() => {
		const leaveIds = leaveMapActive
			.map((leave) => leave.id)
			.concat(leaveMapReviewed.map((leave) => leave.id));

		const unsubList = leaveIds.map((id) => {
			return leaveReviewFirebaseApi.subLeaveNotesByLeaveID(
				id,
				(incomingLeaveNotes) => {
					setLeaveNotes((prev) => ({
						...prev,
						[id]: incomingLeaveNotes,
					}));
				},
			);
		});
		return () => unsubList.forEach((unsub) => unsub());
	}, [leaveReviewFirebaseApi, leaveMapActive, leaveMapReviewed]);

	const handleChangeStartDate = (date: Date): void => {
		setStartDate(date);
	};

	const handleChange = (_: React.SyntheticEvent, newTab: TabEnum): void => {
		setTab(newTab);
	};

	return (
		<TabContext value={tab}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList onChange={handleChange}>
					<Tab label="Unreviewed Leave" value={TabEnum.Unreviewed} />
					<Tab label="Reviewed Leave" value={TabEnum.Reviewed} />
				</TabList>
			</Box>
			<TabPanel value={TabEnum.Unreviewed} sx={{ paddingX: '0' }}>
				<LeaveReviewTable
					title="Unreviewed"
					allLeaveEntries={leaveMapActive}
					changeStartDate={handleChangeStartDate}
					leaveNotes={leaveNotes}
					user={user}
					firebaseApi={leaveReviewFirebaseApi}
					cloudFunctionApi={cloudFunctionApi}
				/>
			</TabPanel>
			<TabPanel value={TabEnum.Reviewed} sx={{ paddingX: '0' }}>
				<LeaveReviewTable
					title="Reviewed"
					allLeaveEntries={leaveMapReviewed}
					startDate={[startDate, setStartDate]}
					changeStartDate={handleChangeStartDate}
					leaveNotes={leaveNotes}
					user={user}
					firebaseApi={leaveReviewFirebaseApi}
					cloudFunctionApi={cloudFunctionApi}
				/>
			</TabPanel>
		</TabContext>
	);
};

export default LeaveReview;
