import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined';
import { Tooltip } from '@mui/material';
import { Leave } from '../../constants/Leave';

type ReviewStatusIconProps = {
	reviewStatus: Leave['reviewStatus'];
};

export const ReviewStatusIcon = ({
	reviewStatus,
}: ReviewStatusIconProps): JSX.Element => icon[reviewStatus];

const icon: Record<Leave['reviewStatus'], JSX.Element> = {
	Approved: (
		<Tooltip title="Approved">
			<CheckCircleOutlineIcon color="success" />
		</Tooltip>
	),
	Submitted: (
		<Tooltip title="Submitted">
			<OutboundOutlinedIcon color="secondary" />
		</Tooltip>
	),
	Disputed: (
		<Tooltip title="Denied">
			<HighlightOffOutlinedIcon color="error" />
		</Tooltip>
	),
};
