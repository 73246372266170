import cloudFunctionApi from '../../../cloudfunctions';
import firebaseApi from '../../../firebase/firebaseApi';
import { ManageWorkHistoryAutomations } from './ManageWorkHistoryAutomations';

export const WorkHistoryAutomationsWrapper = (): JSX.Element => {
	return (
		<ManageWorkHistoryAutomations
			cloudFunctionApi={cloudFunctionApi}
			firebaseApi={firebaseApi}
		/>
	);
};
