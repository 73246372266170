import { Box } from '@mui/material';
import React from 'react';

type PageContainerProps = {
	header: string;
	children?: React.ReactNode;
};

const PageContainer = ({
	header,
	children,
}: PageContainerProps): JSX.Element => {
	return (
		<Box
			maxWidth="xl"
			marginLeft="auto"
			marginRight="auto"
			marginTop={3}
			aria-labelledby={`page-container-${header}`}>
			{children}
		</Box>
	);
};

export default PageContainer;
