import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Divider,
	GridProps,
} from '@mui/material';

type ReportsStyleWrapperProps = {
	title: JSX.Element;
	componentList: {
		gridSize: GridProps['xs'];
		component: JSX.Element;
		id: string;
	}[];
};

export const ReportsStyleWrapper = ({
	title,
	componentList,
}: ReportsStyleWrapperProps): JSX.Element => (
	<Card
		sx={{
			borderBottomRightRadius: 0,
			borderBottomLeftRadius: 0,
		}}>
		<CardHeader title={title} sx={{ px: 3, pb: 0 }} />
		<CardContent sx={{ pl: 3, py: '8px !important' }}>
			<Grid container spacing={1} width={{ md: '100%', lg: '75%' }}>
				{componentList.map((componentObject) => (
					<Grid
						key={componentObject.id}
						item
						xs={componentObject.gridSize}>
						{componentObject.component}
					</Grid>
				))}
			</Grid>
			<Divider orientation="horizontal" sx={{ mt: 1 }} />
		</CardContent>
	</Card>
);
