import DeleteIcon from '@mui/icons-material/Delete';
import {
	Card,
	CardActionArea,
	CardContent,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import { IdObject } from './IdObject';

type IndentedCardListProps<T extends IdObject> = {
	list: T[];
	card: {
		content: (content: T, editing: boolean) => JSX.Element;
		onDelete: (toDelete: T) => void;
		onClick?: (clicked: T) => void;
		isClickableField?: keyof T;
	};
	editing: boolean;
};

export const IndentedCardList = <T extends IdObject>({
	list,
	card,
	editing,
}: IndentedCardListProps<T>): JSX.Element => {
	const row = (rowContent: T): JSX.Element => {
		const isClickable =
			card.isClickableField && rowContent[card.isClickableField];

		const content = (
			<CardContent sx={{ padding: 1, '&:last-child': { pb: 1 } }}>
				{card.content(rowContent, editing)}
			</CardContent>
		);
		return (
			<Card
				variant="elevation"
				sx={{
					borderLeftColor: 'primary.main',
					borderLeftStyle: 'solid',
					borderLeftWidth: '3px',
				}}
				key={rowContent.id}>
				{isClickable ? (
					<CardActionArea
						onClick={(): void =>
							card.onClick && card.onClick(rowContent)
						}>
						{content}
					</CardActionArea>
				) : (
					content
				)}
			</Card>
		);
	};

	const editingRow = (rowContent: T): JSX.Element => (
		<Grid container columns={12} key={rowContent.id} alignItems="center">
			<Grid item xs={11}>
				{row(rowContent)}
			</Grid>
			<Grid item xs={1} textAlign="right">
				<IconButton onClick={(): void => card.onDelete(rowContent)}>
					<DeleteIcon />
				</IconButton>
			</Grid>
		</Grid>
	);

	return editing || list.length > 0 ? (
		<Stack
			direction="column"
			spacing={1}
			alignContent="center"
			sx={{
				marginLeft: 3,
				marginTop: 1,
			}}>
			{list.map((i) => (editing ? editingRow(i) : row(i)))}
		</Stack>
	) : (
		<Typography
			textAlign="center"
			marginTop={2}
			marginBottom={2}
			color="neutral.main">
			Empty
		</Typography>
	);
};
