// modified from https://stackoverflow.com/a/64245521
function* setDifferenceGenerator<T>(
	listA: T[],
	listB: T[],
): Generator<T, void, unknown> {
	const setA = new Set(listA);
	const setB = new Set(listB);

	for (const valueB of setB.values()) {
		setA.delete(valueB);
	}

	for (const valueA of setA.values()) {
		yield valueA;
	}
}

export const setDifference = <T>(listA: T[], listB: T[]): T[] =>
	Array.from(setDifferenceGenerator(listA, listB));
