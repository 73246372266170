import React from 'react';
import { NavigateFunction } from 'react-router';
import { ProjectTrackingType } from '../../../../models/Integrations/ProjectTrackingIntegration';
import CustomAlert from '../../../CustomAlert/CustomAlert';

export type SiteActivitiesWarningAlertProps = {
	navigate: NavigateFunction;
	integrationType: ProjectTrackingType | null;
};

const projectTrackingTypePaths: Record<ProjectTrackingType, string> = {
	Workbench: '/workbench',
	CATProjects: '/catprojects',
};

export const SiteActivitiesWarningAlert = ({
	navigate,
	integrationType,
}: SiteActivitiesWarningAlertProps): JSX.Element => {
	const handleOnClick = (): void => {
		if (integrationType !== null) {
			navigate(
				`/project-tracking${projectTrackingTypePaths[integrationType]}/activities`,
			);
		}
	};

	return (
		<CustomAlert
			severity="info"
			variant="filled"
			alertText="You have unlinked activities"
			actionOnClick={handleOnClick}
			actionText="Go to Project Tracking"
		/>
	);
};
