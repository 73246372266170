import { differenceInSeconds } from 'date-fns';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Evacuation, UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { formatSlashedDate, formatTime } from '../helpers/dateFormatters';
import { secondsToTime } from '../helpers/dateUtilities';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

type EvacuationTableData = Evacuation & {
	timeTaken: string;
	time: string;
	date: string;
	safeRatio: string;
};

export const EvacuationsHistory = ({ userDetails }: UserProps): JSX.Element => {
	const [tableData, setTableData] = useState<EvacuationTableData[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const columns = [
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '20%',
					},
				}),
			},
		},
		{
			name: 'note',
			label: 'Note',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '40%',
					},
				}),
			},
		},
		{
			name: 'date',
			label: 'Date',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
			},
		},
		{
			name: 'time',
			label: 'Time',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
			},
		},
		{
			name: 'timeTaken',
			label: 'Duration',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
			},
		},
		{
			name: 'safeRatio',
			label: 'Total Marked Safe',
			options: {
				setCellHeaderProps: () => ({
					style: { width: '10%' },
				}),
			},
		},
	];

	useEffect(() => {
		return firebaseApi.evacuationsBySiteSubscription(
			userDetails.siteID,
			(evacuations) => {
				const evacuationsData: EvacuationTableData[] = [];
				evacuations.forEach((evacuation) => {
					const timeTaken = secondsToTime(
						differenceInSeconds(
							evacuation.endTime.toDate().getTime(),
							evacuation.startTime.toDate().getTime(),
						),
					);
					evacuationsData.push({
						...evacuation,
						timeTaken: timeTaken,
						time: formatTime(evacuation.startTime.toDate()),
						date: formatSlashedDate(evacuation.startTime.toDate()),
						safeRatio: `${evacuation.totalPeopleSafe}/${evacuation.totalPeople}`,
					});
				});
				setTableData(evacuationsData);
				setLoading(false);
			},
		);
	}, [userDetails.siteID]);

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'single',
		tableBodyHeight: 'calc(100vh - 379px)',
		elevation: 1,
		viewColumns: false,
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching timesheets found'
				),
			},
		},
	};

	return (
		<MUIDataTable
			title="Evacuations History"
			data={tableData}
			columns={columns}
			options={tableOptions}
		/>
	);
};
