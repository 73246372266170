import { AccountType } from './Common';
import { TimesheetFirebaseFilterType } from './Timesheet/Timesheet';
import { TimesheetStatus } from './Timesheet/TimesheetStatus';

type ReportsConfig = {
	titles: string[];
	firebaseFilters: Partial<
		Record<TimesheetFirebaseFilterType, (TimesheetStatus | '')[]>
	>;
};

export const reportsAccountType = [
	'management',
	'seniorManagement',
	'handler',
] as const;

export type ReportsAccountType = AccountType &
	(typeof reportsAccountType)[number];

export const ReportsConfig: Record<ReportsAccountType, ReportsConfig> = {
	management: {
		titles: ['timesheets', 'site log', 'project tracking', 'activities'],
		firebaseFilters: {
			current: [
				TimesheetStatus.Submitted,
				TimesheetStatus.Active,
				TimesheetStatus.Approved,
			],
		},
	},
	seniorManagement: {
		titles: ['timesheets', 'site log', 'project tracking', 'activities'],
		firebaseFilters: {
			current: [
				TimesheetStatus.Submitted,
				TimesheetStatus.Active,
				TimesheetStatus.Approved,
			],
		},
	},
	handler: {
		titles: ['timesheets', 'site log'],
		firebaseFilters: {
			current: [
				TimesheetStatus.Submitted,
				TimesheetStatus.Active,
				TimesheetStatus.Approved,
			],
			archived: [TimesheetStatus.Archived],
		},
	},
};
