import { downloadBlob } from '../../components/helpers/fileDownloads';
import { Storage } from '../firebase';

const getNoPictureUrl = (): Promise<string> =>
	Storage.ref('profilepictures/no_picture.png').getDownloadURL();

const getCompanyNoLogoUrl = (): Promise<string> =>
	Storage.ref('companyLogos/no_logo.png').getDownloadURL();

const getDownloadUrl = (location: string): Promise<string> =>
	Storage.ref(location).getDownloadURL();

/**
 * @returns The download URL for the uploaded picture
 */
const uploadUserProfilePicture = async (
	userID: string,
	picture: Blob,
): Promise<string> => {
	const storageRef = Storage.ref().child('profilepictures/' + userID);
	await storageRef.put(picture);
	const downloadURL = await Storage.ref()
		.child('profilepictures/' + userID)
		.getDownloadURL();
	return downloadURL;
};
/** Downloads a file to the users computer, optionally with a different name
 *
 * @param fileName - the name of the file as it exists in Storage
 * @param location - the root folder of the file in Storage
 * @param downloadedName - The name that the file will be downloaded as. Should be useful to an end user.
 */
const downloadStorageFile = async (
	fileName: string,
	location: string,
	downloadedName?: string,
): Promise<void> => {
	try {
		const downloadURL = await Storage.ref(
			`${location}/${fileName}`,
		).getDownloadURL();
		const response = await fetch(downloadURL);
		const blob = await response.blob();

		downloadBlob(URL.createObjectURL(blob), downloadedName ?? fileName);
	} catch (err) {
		throw new Error('getStorageFile failed: ' + err);
	}
};

/** Downloads a file to the users computer, optionally with a different name
 *
 * @param link - the root folder and the name of the file as it exists in Storage
 * @param downloadedName - The name that the file will be downloaded as. Should be useful to an end user.
 */
const downloadStorageFileFromLink = async (
	link: string,
	downloadedName?: string,
): Promise<void> => {
	try {
		const downloadURL = await Storage.ref(link).getDownloadURL();
		const response = await fetch(downloadURL);
		const blob = await response.blob();

		downloadBlob(URL.createObjectURL(blob), downloadedName ?? link);
	} catch (err) {
		throw new Error('getStorageFile failed: ' + err);
	}
};

export const saveCSVFileToStorage = async (
	csvFile: File,
	companyID: string,
): Promise<string> => {
	const fileName = `payrollImportedEmployees/${companyID}`;
	const ref = Storage.ref().child(fileName);
	await ref.put(csvFile);
	return companyID;
};

export const deleteStorageFile = async (file: string): Promise<void> => {
	try {
		const ref = Storage.ref().child(file);
		await ref.delete();
	} catch (err) {
		throw new Error('deleteStorageFile failed: ' + err);
	}
};

const storageFirebaseApi = {
	getNoPictureUrl,
	getCompanyNoLogoUrl,
	downloadStorageFile,
	saveCSVFileToStorage,
	deleteStorageFile,
	getDownloadUrl,
	uploadUserProfilePicture,
	downloadStorageFileFromLink,
};

export default storageFirebaseApi;
