import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeIcon from '@mui/icons-material/Home';
import {
	AppBar,
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import TradeLegionLogo from '../../src/images/birdonlyblack-transparentbackground.png';
import { appHeaderHeight, UserDetails } from '../constants/Common';
import { defaultPageConfig, Page } from '../constants/PageConfig';

const HOME_URL = process.env.REACT_APP_HOME_URL;

type SidebarProps = {
	open: boolean;
	drawerWidth: number;
	handleDrawerClose: () => void;
	drawerPermanent: boolean;
	pageConfig: Page[];
	userDetails: UserDetails | null;
};

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	justifyContent: 'space-between',
	...theme.mixins.toolbar,
}));

type SidebarRowProps = {
	path: string;
	icon: React.ReactElement | undefined;
	name: string;
	isExternalLink?: boolean;
};

/** Recurse through the nestedPages, constructing a path of each of the first nested items */
const getNestedPath = (
	page: Pick<Page, 'path' | 'nestedPages'>,
	accountType?: UserDetails['accountType'],
): string => {
	if (!page.nestedPages || page.nestedPages.length === 0 || !accountType)
		return page.path;
	else {
		const firstPage = page.nestedPages.find((page) =>
			page.accountRequirements.includes(accountType),
		);
		if (!firstPage) return page.path;
		else return `${page.path}/${getNestedPath(firstPage)}`;
	}
};

const SidebarRow = ({
	path,
	icon,
	name,
	isExternalLink = false,
}: SidebarRowProps): JSX.Element => {
	const split = path.split('/');
	const locationMatchesPath =
		// some paths have a `/` prefix, others don't
		useMatch(`${split[0] || split[1]}/*`) &&
		// Make sure Home isn't highlighted on every page
		path !== '';
	return !isExternalLink ? (
		<ListItemButton
			selected={!!locationMatchesPath}
			component={Link}
			to={path}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={name} />
		</ListItemButton>
	) : (
		<ListItemButton
			selected={!!locationMatchesPath}
			component="a"
			target="_self"
			rel="noopener"
			href={path}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={name} />
		</ListItemButton>
	);
};

const Sidebar = ({
	userDetails,
	open,
	drawerWidth,
	drawerPermanent,
	pageConfig,
	handleDrawerClose,
}: SidebarProps): JSX.Element => {
	const theme = useTheme();

	const menuPreferences = userDetails?.hiddenMenuItemsPreferences ?? {};
	const pages = pageConfig.filter(
		(item) =>
			item.parent === 'dashboard' &&
			item.showInDrawer &&
			!(item.name in menuPreferences),
	);
	const renderDrawerContent = (): JSX.Element => (
		<List
			data-testid="sidebar"
			sx={{
				marginTop: `${appHeaderHeight}px`,
				height: `calc(100vh - ${appHeaderHeight}px)`,
				overflowY: 'auto',
			}}>
			{pages.map((page) => (
				<SidebarRow
					key={`sidebar-row-${page.name}`}
					path={getNestedPath(page, userDetails?.accountType)}
					icon={page.icon}
					name={page.name}
				/>
			))}
			{pages.length > 0 && (
				<Divider key="sidebar-divider" sx={{ mt: 1, mb: 1 }} />
			)}
			<SidebarRow
				key="sidebar-row-home"
				path={HOME_URL || ''}
				icon={<HomeIcon />}
				name="Home"
				isExternalLink
			/>
			{defaultPageConfig
				.filter((item) => item.parent === 'home' && item.showInDrawer)
				.map((page) => (
					<SidebarRow
						key={`sidebar-row-${page.name}`}
						path={page.path}
						icon={page.icon}
						name={page.name}
					/>
				))}
		</List>
	);

	return (
		<Box
			key="sidebar"
			component="nav"
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label="mailbox folders">
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						bgcolor: theme.palette.background.paper,
					},
				}}
				variant={drawerPermanent ? 'persistent' : 'temporary'}
				anchor="left"
				open={open}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}>
				<AppBar
					key="sidebar-appbar"
					position="fixed"
					color="default"
					sx={{ left: 0, width: '240px' }}>
					<DrawerHeader>
						<Box
							key="sidebar-header-trade-legion-logo"
							component="img"
							src={TradeLegionLogo}
							alt="Trade Legion"
							sx={{ height: 32, margin: 1 }}
						/>
						<Stack key="sidebar-header-stack">
							<Typography>Trade Legion</Typography>
							<Typography variant="caption">
								{process.env.REACT_APP_VERSION}
							</Typography>
						</Stack>
						<IconButton
							key="sidebar-header-button"
							onClick={handleDrawerClose}>
							{theme.direction === 'ltr' ? (
								<ChevronLeftIcon />
							) : (
								<ChevronRightIcon />
							)}
						</IconButton>
					</DrawerHeader>
				</AppBar>
				{/* Content */}
				{renderDrawerContent()}
			</Drawer>
		</Box>
	);
};

export default Sidebar;
