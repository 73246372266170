import { Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebaseApi from '../../firebase/firebaseApi';
import {
	useUserAuthContext,
	useUserDetailsContext,
} from '../../providers/UserProvider';
import { capitaliseWords } from '../helpers/stringHelpers';
import {
	PhoneNumberInput,
	validatePhoneNumber,
} from '../PhoneNumberInput/PhoneNumberInput';
import { NewUserInfo } from './NewUserInfo';
import { SignInUpWrapper } from './SignInUpWrapper';

type AccountInformationProps = {
	setNewUserInfo: (newUserInfo: NewUserInfo) => void;
	newUserInfo: NewUserInfo;
	setAccountInformationProvided: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	onSignOut?: () => void;
};

const AUTH_STATE = 'accountInfo';

export const AccountInformation = ({
	setNewUserInfo,
	newUserInfo,
	setAccountInformationProvided,
	onSignOut,
}: AccountInformationProps): JSX.Element => {
	const navigate = useNavigate();
	const userAuth = useUserAuthContext();
	const userDetails = useUserDetailsContext();
	const [newUser, setNewUser] = useState<NewUserInfo>({
		firstName: newUserInfo ? newUserInfo.firstName.trim() : '',
		lastName: newUserInfo ? newUserInfo.lastName.trim() : '',
		mobileNumber: newUserInfo.mobileNumber
			? newUserInfo.mobileNumber.trim()
			: '+64',
	});
	const [inputErrors, setInputErrors] = useState<NewUserInfo>({
		firstName: '',
		lastName: '',
		mobileNumber: '',
	});
	const userFields: NewUserInfo = {
		firstName: 'First Name',
		lastName: 'Last Name',
		mobileNumber: 'Mobile Number',
	};

	useEffect(() => {
		if (userDetails !== null) {
			setNewUser({
				firstName: userDetails.firstname.trim(),
				lastName: userDetails.lastname.trim(),
				mobileNumber: userDetails.mobileNumber,
			});
		}
	}, [userDetails]);

	useEffect(() => {
		setNewUserInfo(newUser);
	}, [newUser, setNewUserInfo]);

	const validate = (): boolean => {
		const newErrors: Partial<NewUserInfo> = {};
		Object.keys(inputErrors).forEach((item) => {
			const key = item as keyof NewUserInfo;
			const value = newUser[key];
			if (value === '') {
				newErrors[key] = `Please enter a ${userFields[key]}`;
			}
			if (key === 'mobileNumber' && !validatePhoneNumber(value)) {
				newErrors[key] = `Please enter a valid phone number`;
			}
		});
		setInputErrors({ ...inputErrors, ...newErrors });
		return Object.keys(newErrors).length < 1;
	};

	const handleNewUserUpdate =
		(parameterName: keyof NewUserInfo) =>
		(
			event:
				| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
				| string,
		) => {
			const user = { ...newUser };
			if (typeof event === 'string') {
				user[parameterName] = event;
			} else {
				user[parameterName] = event.target.value;
			}
			setNewUser({
				firstName: user.firstName.trim(),
				lastName: user.lastName.trim(),
				mobileNumber: user.mobileNumber.trim(),
			});
			const updatedErrors = { ...inputErrors };
			updatedErrors[parameterName] = '';
			setInputErrors(updatedErrors);
		};

	const handleRequest = async (): Promise<void> => {
		if (validate() && userAuth !== null) {
			if (userDetails !== null) {
				const displayName = `${newUserInfo.firstName} ${newUserInfo.lastName}`;
				await firebaseApi.updateUserDetailsAccountInfo(userAuth.uid, {
					firstname: newUserInfo.firstName,
					lastname: newUserInfo.lastName,
					mobileNumber: newUserInfo.mobileNumber,
					displayName: capitaliseWords(displayName),
				});
			}
			setAccountInformationProvided(true);
			navigate('/company-information');
		}
	};

	return (
		<>
			<SignInUpWrapper
				title="Account Information"
				authState={AUTH_STATE}
				back={{
					label: 'Back',
					handle: (): void => {
						navigate('/verify-email');
					},
				}}
				next={{
					label: 'Next',
					handle: handleRequest,
				}}
				onSignOut={onSignOut}>
				<Grid container spacing={2}>
					{!userAuth?.emailVerified && (
						<Grid item xs={12}>
							<Typography
								variant="h6"
								sx={{ textAlign: 'center' }}>
								Email Not Verified
							</Typography>
						</Grid>
					)}
					<>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="First Name (required)"
								value={newUser.firstName}
								onChange={handleNewUserUpdate('firstName')}
								helperText={inputErrors.firstName}
								error={inputErrors.firstName !== ''}
								disabled={!userAuth?.emailVerified}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								value={newUser.lastName}
								label="Last Name (required)"
								onChange={handleNewUserUpdate('lastName')}
								helperText={inputErrors.lastName}
								error={inputErrors.lastName !== ''}
								disabled={!userAuth?.emailVerified}
							/>
						</Grid>
						<Grid item xs={12}>
							<PhoneNumberInput
								value={newUser.mobileNumber}
								onChange={handleNewUserUpdate('mobileNumber')}
								helperText={inputErrors.mobileNumber}
							/>
						</Grid>
						{userDetails && userDetails.company !== '' && (
							<Grid item xs={12}>
								<Typography textAlign="center" variant="h6">
									Currently awaiting approval
								</Typography>
								<Typography textAlign="center" variant="body2">
									You can continue to submit changes while
									awaiting approval
								</Typography>
							</Grid>
						)}
					</>
				</Grid>
			</SignInUpWrapper>
		</>
	);
};
