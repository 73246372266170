import { Box, Tab, Tabs } from '@mui/material';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { UserProps } from '../../constants/Common';
import { ReportsAccountType, ReportsConfig } from '../../constants/Reports';

export const Reports = ({ userDetails }: UserProps): JSX.Element => {
	const accountType = userDetails.accountType as ReportsAccountType;
	const titles = ReportsConfig[accountType].titles;
	const pathMatch = useMatch('/reports/:tab');
	const [searchParams] = useSearchParams();

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};
