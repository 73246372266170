import { Typography, Grid, Divider } from '@mui/material';
import PageWrapperDisplay from './PageWrapper';

type ContactUsPage = {
	renderHeader: (showDrawer: boolean) => JSX.Element;
};

const ContactUsDisplay = (props: ContactUsPage): JSX.Element => {
	return (
		<PageWrapperDisplay renderHeader={props.renderHeader}>
			<Grid
				id="content"
				container
				spacing={2}
				alignItems="center"
				mt="10vh"
				sx={{ flexDirection: 'column' }}>
				<Grid item>
					<Typography variant="h4">Enquiries</Typography>
				</Grid>
				<Grid item>
					<Typography variant="subtitle1">
						<Divider variant="middle" />
						Please send any enquiries to mwebster@trade-legion.co.nz
					</Typography>
				</Grid>
			</Grid>
		</PageWrapperDisplay>
	);
};

export default ContactUsDisplay;
