import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { FixMeLater } from '../../constants/AnyTypes';
import type { App, RecaptchaVerifier } from '../../firebase/firebase';

// Define the type for the RecaptchaVerifier constructor
// constructor for firebase.auth.RecaptchaVerifier
export type RecaptchaVerifierConstructor = new (
	container: FixMeLater,
	parameters?: object | null,
	app?: App | null,
) => RecaptchaVerifier;

type RecaptchaVerifierProps = {
	setVerifier: Dispatch<SetStateAction<RecaptchaVerifier | undefined>>;
	RecaptchaVerifierConstructor: RecaptchaVerifierConstructor;
};

export const RecaptchaVerifierContainer = ({
	setVerifier,
	RecaptchaVerifierConstructor,
}: RecaptchaVerifierProps): JSX.Element => {
	const recaptchaContainer = useRef<typeof Box>(null);
	useEffect(() => {
		if (recaptchaContainer.current) {
			// constructor for firebase.auth.RecaptchaVerifier
			const recaptchaVerifier = new RecaptchaVerifierConstructor(
				recaptchaContainer.current,
				{
					size: 'invisible',
				},
			);
			recaptchaVerifier.render();
			setVerifier(recaptchaVerifier);

			return () => {
				// disable as we want to check if this value has changed
				// eslint-disable-next-line react-hooks/exhaustive-deps
				if (recaptchaContainer.current) {
					recaptchaVerifier.clear();
				}
			};
		}
	}, [RecaptchaVerifierConstructor, setVerifier]);

	return (
		<Box
			ref={recaptchaContainer}
			style={{
				transform: 'scale(0.5)',
				position: 'fixed',
				right: '0px',
				bottom: '16px',
			}}
		/>
	);
};
