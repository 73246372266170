import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import firebaseApi from '../../firebase/firebaseApi';
import { PayrollType } from '../../models/Integrations/PayrollIntegration';
import { useUserDetailsContext } from '../../providers/UserProvider';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { IntegrationItem, IntegrationsSetup } from './IntegrationsSetup';

export type Integration = {
	title: string;
	url: string;
};

export const PayrollIntegrationsPage = (): JSX.Element => {
	const userDetails = useUserDetailsContext();
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);

	const payrollRedirects = useMemo(
		(): Record<PayrollType, string | undefined> => ({
			IPayroll: '/connect/ipayroll',
			PayHero: '/connect/payhero',
			Xero: '/connect/xero',
			Smartly: '/connect/smartly',
		}),
		[],
	);

	useEffect(() => {
		if (userDetails === null) return;

		setLoading(true);
		const sub = firebaseApi.companySubscription(
			userDetails.companyID,
			(company) => {
				if (company) {
					if (
						company.payrollIntegrated &&
						!location.state?.skipNavigate
					) {
						const navigationLink =
							payrollRedirects[company.payrollIntegrated];
						if (navigationLink) {
							navigate(navigationLink);
						}
					} else {
						navigate('/connect', {
							replace: true,
							state: {},
						});
					}
				}
				setLoading(false);
			},
		);

		return sub;
	}, [location.state?.skipNavigate, navigate, payrollRedirects, userDetails]);

	const payrollTypes: Partial<
		Record<PayrollType, IntegrationItem<PayrollType>>
	> = useMemo(() => {
		const types: Partial<
			Record<PayrollType, IntegrationItem<PayrollType>>
		> = {
			PayHero: { name: 'PayHero', id: 'PayHero', link: 'payhero' },
			IPayroll: { name: 'iPayroll', id: 'IPayroll', link: 'ipayroll' },
			Smartly: { name: 'Smartly', id: 'Smartly', link: 'smartly' },
			Xero: { name: 'Xero', id: 'Xero', link: 'xero' },
		};

		return types;
	}, []);

	const renderDescription = (): JSX.Element => (
		<>
			<Typography>
				Looks like you don&apos;t have a payroll system setup with us!
				We can handle your timesheets all the way through to payroll,
				freeing up your schedule for other things.
			</Typography>
			<Typography>
				Select your payroll provider below to integrate with Trade
				Legion
			</Typography>
		</>
	);

	return (
		<Box>
			{loading ? (
				<LoadingDots />
			) : (
				<IntegrationsSetup
					integrationTypes={payrollTypes}
					description={renderDescription()}
				/>
			)}
		</Box>
	);
};
