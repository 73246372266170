import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
	Autocomplete,
	Box,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import {
	Activity,
	TimesheetActivity,
} from '../../../../../../constants/Common';
import { ActivityEditFields } from '../../../../../../constants/Timesheet/Timesheet';
import { removeInputPadding } from '../../../../../helpers/textFieldHelpers';
import { IncrementedTimeSelect } from '../IncrementedTimeSelect';

export type EditRowProps = {
	activity: Pick<Activity, ActivityEditFields>;
	siteActivities: TimesheetActivity[];
	updateActivity: (
		id: Activity['id'],
		activity: Activity['activity'],
		hours: number,
	) => void;
	deleteActivity: (id: Activity['id']) => void;
	disabled: boolean;
	error?: boolean;
};

export const EditRow = ({
	activity,
	siteActivities,
	updateActivity,
	deleteActivity,
	disabled,
	error,
}: EditRowProps): JSX.Element => {
	const selectedActivityID: string | null =
		activity.activity.id !== '' ? activity.activity.id : null;

	const handleUpdateActivity = (
		_: SyntheticEvent,
		newValue: TimesheetActivity | null,
	): void => {
		const activityID: string = newValue ? newValue.id : '';
		const siteActivity: TimesheetActivity | undefined = siteActivities.find(
			(siteActivity) => siteActivity.id === activityID,
		);

		if (siteActivity) {
			updateActivity(
				activity.id,
				{ id: siteActivity.id, name: siteActivity.activityName },
				activity.hours,
			);
		}
	};

	return (
		<Stack data-testid={`${activity.id}-edit`}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between">
				<Autocomplete
					options={siteActivities}
					getOptionLabel={(option: TimesheetActivity): string =>
						option.activityName
					}
					value={
						siteActivities.find(
							(siteActivity) =>
								siteActivity.id === selectedActivityID,
						) || null
					}
					onChange={handleUpdateActivity}
					disabled={disabled}
					fullWidth
					renderInput={(params): JSX.Element => (
						<TextField
							{...params}
							label={
								<Stack direction="row">
									<Typography>Activity Type</Typography>
									<Typography color="error.main">
										*
									</Typography>
								</Stack>
							}
							error={error}
							size="small"
							fullWidth
							inputProps={{
								...params.inputProps,
								sx: {
									...removeInputPadding,
									height: '14px', // autocomplete not recognizing size='small'?
								},
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				/>
				<IncrementedTimeSelect
					removeInputPadding
					maxHours={18}
					hours={activity.hours}
					setHours={(value: number): void => {
						updateActivity(activity.id, activity.activity, value);
					}}
					error={error}
					disabled={disabled}
				/>
				<Tooltip title="Delete Activity">
					<Box>
						<IconButton
							disabled={disabled}
							color="primary"
							onClick={(): void => {
								deleteActivity(activity.id);
							}}>
							<DeleteOutlinedIcon fontSize="small" />
						</IconButton>
					</Box>
				</Tooltip>
			</Stack>
			{error && (
				<Typography marginLeft={1} color="error" variant="caption">
					Please ensure an activity is selected and hours set
				</Typography>
			)}
		</Stack>
	);
};
