import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useCompanySubscriptionContext } from '../../providers/CompanySubscriptionProvider';
import { useFeatureFlagContext } from '../../providers/featureFlags/Provider';
import {
	useUserAuthContext,
	useUserLoadedContext,
} from '../../providers/UserProvider';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';

export const PageNotFound = (): JSX.Element => {
	const navigate = useNavigate();
	const { userDetailsLoaded, userAuthLoaded } = useUserLoadedContext();
	const userAuth = useUserAuthContext();
	const { loaded: featureFlagsLoaded } = useFeatureFlagContext();
	const { isLoaded: companySubscriptionLoaded } =
		useCompanySubscriptionContext();

	return (
		<Box
			height="50%"
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			mt="7vh">
			{(userDetailsLoaded &&
				userAuthLoaded &&
				featureFlagsLoaded &&
				companySubscriptionLoaded) ||
			(userAuthLoaded && userAuth === null) ? (
				<>
					<Typography variant="h1">Page Not Found</Typography>
					<Button
						onClick={(): void => navigate('/sign-in')}
						variant="contained"
						sx={{ margin: 4 }}>
						Return Home
					</Button>
				</>
			) : (
				<Typography variant="h1">
					Loading
					<LoadingDots />
				</Typography>
			)}
		</Box>
	);
};
