import firebase from 'firebase';
import {
	Minimal,
	Company,
	Site,
	AccountType,
	MinimalUserDetails,
	accountTypes,
} from './Common';

export type Contract = {
	id: string;
	employee: MinimalUserDetails;
	payRate: number;
	chargeOutRate: number;
	onCost: number;
	margin: number;
	notes: string;
	site: Minimal<Site> | null;
	createdBy: MinimalUserDetails;
	acceptedBy: MinimalUserDetails | null;
	supplierCompany: Minimal<Company>;
	accepterCompany: Minimal<Company>;
	dateCreated: firebase.firestore.Timestamp;
	status: ContractStatus;
	documentURL: string | null;
};

export enum ContractStatus {
	Active = 'Active',
	Accepted = 'Accepted',
	Archived = 'Archived',
}

export type UpdatableContract = Pick<
	Contract,
	| 'site'
	| 'employee'
	| 'payRate'
	| 'chargeOutRate'
	| 'notes'
	| 'accepterCompany'
	| 'margin'
	| 'onCost'
> & { document: Blob | string | null };

export const contractsAccountType = [accountTypes.handler] as const;

export type ContractsAccountType = AccountType &
	(typeof contractsAccountType)[number];

export const AccountInfo: Record<
	ContractsAccountType,
	{
		hiddenFields: Set<keyof Contract>;
		companyFilter: `${keyof Contract}.id`;
		allowedActions: Set<'accept' | 'archive' | 'edit'>;
	}
> = {
	handler: {
		hiddenFields: new Set(['supplierCompany']),
		companyFilter: 'supplierCompany.id',
		allowedActions: new Set(['edit', 'archive']),
	},
};

export type SimplifiedContract = Pick<
	Contract,
	'id' | 'employee' | 'site' | 'accepterCompany'
>;

export type ContractsConfig = {
	titles: string[];
};

export const ratesConfig: Record<ContractsAccountType, ContractsConfig> = {
	handler: {
		titles: ['rates', 'default rates'],
	},
};
