import { Box, Stack, Typography } from '@mui/material';
import { BrowserClient } from '../../constants/BrowerClient';
import { OperatingSystem } from '../../constants/OperatingSystem';
import PageWrapperDisplay from '../DefaultPages/PageWrapper';
import BirdImage from './../../images/birdonlyblack-transparentbackground.png';

type VersionControlProps = {
	currentVersion: string | null;
	liveVersion: string | null;
	operatingSystem: OperatingSystem;
	browserClient: BrowserClient;
};

const VersionControlPage = (props: VersionControlProps): JSX.Element => {
	const { liveVersion, currentVersion, operatingSystem } = props;

	const resetImage: Record<OperatingSystem, string> = {
		[OperatingSystem.Windows]: 'Ctrl + F5 OR Ctrl + Fn + F5',
		[OperatingSystem.Mac]:
			'Command + Shift + R OR Option + Command + E (Safari)',
		[OperatingSystem.Linux]: 'Ctrl + F5 OR Ctrl + Fn + F5',
		[OperatingSystem.Android]: 'Check your settings',
		[OperatingSystem.iOS]: 'Check your settings',
		[OperatingSystem.Unknown]: '',
	};

	return (
		<PageWrapperDisplay height="100%">
			<Stack
				sx={{ height: '100%' }}
				justifyContent="center"
				alignItems="center">
				<Typography variant="h3">Version Outdated</Typography>
				<Typography variant="h6">{`Expected Version: ${liveVersion}`}</Typography>{' '}
				<Typography variant="h6">{`Current Version: ${currentVersion}`}</Typography>
				<Box
					sx={{ width: '25%' }}
					component="img"
					alt="Icon"
					src={BirdImage}
				/>
				{operatingSystem !== OperatingSystem.Unknown && (
					<>
						<Typography>{`On ${OperatingSystem[operatingSystem]}, refresh by pressing:`}</Typography>
						<Typography variant="h5">
							{resetImage[operatingSystem]}
						</Typography>
					</>
				)}
			</Stack>
		</PageWrapperDisplay>
	);
};

export default VersionControlPage;
