import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { CloudFunctionApi } from '../../../../cloudfunctions';
import { CreateSiteRequestBody } from '../../../../cloudfunctions/sitesFirestore';
import { Company, SiteStatus, UserDetails } from '../../../../constants/Common';
import { User } from '../../../../firebase/firebase';
import { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../hooks/useAbortController';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';
import { SitePanel } from '../SitePanel/SitePanel';
import {
	EditableSiteFields,
	createBlankSite,
	ErrorMapType,
	createError,
} from '../SitePanel/sitePanelHelpers';

export type NewSiteProps = {
	user: User;
	userDetails: UserDetails;
	company?: Company;
	setShowCreateNewSite?: React.Dispatch<React.SetStateAction<boolean>>;
	setFilterBySiteName?: React.Dispatch<React.SetStateAction<string>>;
	saveSiteCallback?: () => void;
	cloudFunctionApi: Pick<CloudFunctionApi, 'createSite'>;
	firebaseApi: Pick<FirebaseApi, 'changeUserSite'>;
};

const newSiteFields: readonly (keyof EditableSiteFields)[] = [
	'name',
	'jobNumber',
	'siteContact',
	'siteContactNumber',
	'address',
	'city',
	'region',
	'autoSignOutTime',
	'showCovidWarning',
	'allowAppSignIn',
	'startTime',
	'hasInductions',
	'safetyCourseRequiredForInduction',
] as const;

const validateNewSite = (
	obj: Partial<EditableSiteFields>,
): obj is EditableSiteFields => {
	for (const key of newSiteFields) {
		const value = obj[key];

		if (value === undefined) {
			return false;
		}
	}
	return true;
};

export const NewSite = ({
	user,
	userDetails,
	company,
	setShowCreateNewSite,
	setFilterBySiteName,
	saveSiteCallback,
	cloudFunctionApi,
	firebaseApi,
}: NewSiteProps): React.ReactElement => {
	const abortSignal = useAbortController();

	const [newSiteDetails, setNewSiteDetails] = useState<
		Partial<EditableSiteFields>
	>(createBlankSite());
	const [errorMap, setErrorMap] = useState<Partial<ErrorMapType>>(
		createError(createBlankSite()),
	);
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const disableSave =
		loading || Object.values(errorMap).some((value) => value.error);

	const saveSiteDetails = async (): Promise<void> => {
		const newErrorMap = createError(newSiteDetails);
		const isValid = Object.values(newErrorMap).every(
			(value) => !value.error,
		);

		setErrorMap(newErrorMap);

		if (!validateNewSite(newSiteDetails)) {
			return;
		}

		if (isValid) {
			const newSite: CreateSiteRequestBody = {
				site: {
					autoSignOutTime: newSiteDetails.autoSignOutTime,
					name: newSiteDetails.name,
					jobNumber: newSiteDetails.jobNumber,
					address: newSiteDetails.address,
					region: newSiteDetails.region,
					city: newSiteDetails.city,
					siteContact: newSiteDetails.siteContact,
					siteContactNumber: newSiteDetails.siteContactNumber,
					showCovidWarning: newSiteDetails.showCovidWarning,
					allowAppSignIn: newSiteDetails.allowAppSignIn,
					startTime: newSiteDetails.startTime,
					hasInductions: newSiteDetails.hasInductions,
					safetyCourseRequiredForInduction:
						newSiteDetails.safetyCourseRequiredForInduction,
					company: company ? company.name : userDetails.company,
					companyID: company ? company.id : userDetails.companyID,
					createdBy: userDetails.displayName,
					createdByID: userDetails.userID,
					status: SiteStatus.Active,
				},
			};

			setLoading(true);
			const newSiteID = await cloudFunctionApi.createSite(
				abortSignal,
				user,
				newSite,
			);

			setShowCreateNewSite?.(false);
			setFilterBySiteName?.(newSiteDetails.name);
			setSnackBarOpen(true);
			setNewSiteDetails(createBlankSite());
			setErrorMap(createError(createBlankSite()));
			saveSiteCallback?.();
			setLoading(false);

			// if this is the first site created for a company, automatically switch to it after creation
			if (!userDetails.siteID && newSiteID) {
				await firebaseApi.changeUserSite(userDetails.userID, {
					site: newSiteDetails.name,
					siteID: newSiteID,
					siteCompany: userDetails.company,
					siteCompanyID: userDetails.companyID,
					contractedTo: {
						id: userDetails.companyID,
						name: userDetails.company,
					},
				});
			}
		}
	};

	const handleSnackBarClose = (_?: unknown, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarOpen(false);
	};

	const handleUpdateSite = (value: Partial<EditableSiteFields>): void => {
		setNewSiteDetails((prev) => ({ ...prev, ...value }));
	};

	const handleUpdateErrorMap = (value: Partial<ErrorMapType>): void => {
		setErrorMap((prev) => ({ ...prev, ...value }));
	};

	const handleCancel = (): void => {
		setNewSiteDetails(createBlankSite());
		setErrorMap(createError(createBlankSite()));
	};

	const handleSave = async (): Promise<void> => await saveSiteDetails();

	const saveIcon = loading ? <CircularProgress size={24} /> : <SaveIcon />;

	const cardHeaderActions = (
		<CardHeader
			title="Create New Site"
			action={
				<Stack direction="row" spacing={1}>
					<Tooltip title="Save">
						<Box>
							<IconButton
								onClick={handleSave}
								disabled={disableSave}
								color="primary">
								{saveIcon}
							</IconButton>
						</Box>
					</Tooltip>
					<Tooltip title="Clear Fields">
						<IconButton
							disabled={loading}
							onClick={handleCancel}
							color="primary">
							<CancelIcon />
						</IconButton>
					</Tooltip>
				</Stack>
			}
		/>
	);

	return (
		<Card variant="outlined">
			{cardHeaderActions}
			<CardContent sx={{ px: 3 }}>
				<Box>
					<SitePanel
						site={createBlankSite()}
						updatedSite={newSiteDetails}
						setUpdatedSite={handleUpdateSite}
						errorMap={errorMap}
						setErrorMap={handleUpdateErrorMap}
						disabled={loading}
					/>
				</Box>
			</CardContent>
			<CustomSnackBar
				open={snackBarOpen}
				onClose={handleSnackBarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				snackBarText="Site Added Successfully"
			/>
		</Card>
	);
};
