import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

type ConfirmationDialogProps = {
	title: string;
	question: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

export const ConfirmationDialog = ({
	title,
	question,
	isOpen,
	onClose,
	onConfirm,
}: ConfirmationDialogProps): JSX.Element => {
	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{question}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined">
					No
				</Button>
				<Button onClick={onConfirm} variant="contained">
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
