import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Grid,
	MenuItem,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import {
	Company,
	CompanyType,
	CompanyTypeName,
	companyTypes,
	isCompanyType,
} from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { CustomSnackBar } from '../SnackBar/SnackBar';
import { EditCompanyDetails } from './EditCompany';

type ErrorMapType = {
	name: string;
	companyType: string;
};

export const validateNewCompany = (
	newCompanyName: string,
	newCompanyType: string,
	setNameError: React.Dispatch<React.SetStateAction<string>>,
	setTypeError: React.Dispatch<React.SetStateAction<string>>,
): boolean => {
	const errorMap: Partial<ErrorMapType> = {};
	if (newCompanyName === '') {
		errorMap.name = 'Please enter a Company';
		setNameError(errorMap.name);
	}
	if (!isCompanyType(newCompanyType)) {
		errorMap.companyType = 'Please select a Company Type';
		setTypeError(errorMap.companyType);
	}
	return Object.keys(errorMap).length === 0;
};

export const ManageCompanies = (): JSX.Element => {
	const [filterByType, setFilterByType] = useState<string>('');
	const [filterByCompanyName, setFilterByCompanyName] = useState<string>('');
	const [companies, setCompanies] = useState<Company[]>([]);
	const [selected, setSelected] = useState(0);
	const [nameError, setNameError] = useState<string>('');
	const [typeError, setTypeError] = useState<string>('');
	const [newCompanyName, setNewCompanyName] = useState<string>('');
	const [newCompanyType, setNewCompanyType] = useState<string>('');
	const [showCreateNewCompany, setShowCreateNewCompany] =
		useState<boolean>(false);
	const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [applyFilter, setApplyFilter] = useState(false);

	useEffect(() => {
		if (applyFilter) {
			const fetchCompanies = async (): Promise<void> => {
				const csCompanies =
					await firebaseApi.customerSupportQueryCompanies(
						filterByCompanyName,
						filterByType,
					);
				setCompanies(csCompanies);
			};
			fetchCompanies();
			setApplyFilter(false);
		}
	}, [applyFilter, filterByCompanyName, filterByType]);

	const columns = [
		{
			name: 'name',
			label: 'Company Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'companyType',
			label: 'Company Type',
			options: {
				customBodyRender: (value: CompanyType): string => {
					return CompanyTypeName[value] ?? '-';
				},
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'payrollIntegrated',
			label: 'Payroll Integration',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'single',
		tableBodyHeight: 'calc(100vh - 360px)',
		elevation: 2,
		viewColumns: false,
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		download: false,
		print: false,
	};

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleApplyFilters = (name: string | undefined = undefined): void => {
		if (name) {
			setFilterByCompanyName(name);
			setFilterByType('');
		}
		if (filterByType !== '' || filterByCompanyName !== '') {
			setApplyFilter(true);
		} else {
			setOpen(true);
		}
	};

	const handleNameChange = (name: string): void => {
		setNewCompanyName(name);
		setNameError('');
		if (name === '') {
			setNameError('Please enter a Company');
		}
	};
	const handleTypeChange = (type: string): void => {
		setNewCompanyType(type);
		setTypeError('');
		if (type === '') {
			setTypeError('Please select a Company Type');
		}
	};

	const handleDialogClose = (): void => {
		setNameError('');
		setTypeError('');
		setNewCompanyName('');
		setNewCompanyType('');
		setShowCreateNewCompany(false);
	};

	const createNewCompany = (): void => {
		if (
			validateNewCompany(
				newCompanyName,
				newCompanyType,
				setNameError,
				setTypeError,
			) &&
			isCompanyType(newCompanyType)
		) {
			const newCompany: Omit<Company, 'id'> = {
				name: newCompanyName,
				companyType: newCompanyType,
			};
			firebaseApi.createCompany(newCompany);

			setFilterByType(newCompanyType);
			setFilterByCompanyName(newCompanyName);
			setShowCreateNewCompany(false);
			setSnackBarOpen(true);
			setNameError('');
			setTypeError('');
			handleApplyFilters();
		}
	};

	const handleSnackBarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackBarOpen(false);
		setShowCreateNewCompany(false);
	};

	const submitSnackBar = (
		<CustomSnackBar
			open={snackBarOpen}
			onClose={handleSnackBarClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			snackBarText="New Company Submitted"
		/>
	);

	const filtersValidation = (
		<CustomSnackBar
			open={open}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			snackBarText="Please enter Company Name or Company Type"
			severity="error"
		/>
	);

	const createNewCompanyDialog = (
		<Dialog open={showCreateNewCompany} fullWidth>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Company Name (required)"
							value={newCompanyName}
							onChange={(event): void =>
								handleNameChange(event?.target.value)
							}
							helperText={nameError}
							error={nameError !== ''}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							value={newCompanyType}
							label="Company Type (required)"
							onChange={(event): void =>
								handleTypeChange(event.target.value)
							}
							select
							helperText={typeError}
							error={typeError !== ''}>
							{companyTypes.map((option) => (
								<MenuItem key={option} value={option}>
									{CompanyTypeName[option]}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDialogClose} fullWidth>
					Cancel
				</Button>
				<Button onClick={createNewCompany} fullWidth>
					Create new company
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{filtersValidation}
			{submitSnackBar}
			{createNewCompanyDialog}
			<Paper id="filter" sx={{ mb: 1, padding: 1 }}>
				<Grid
					container
					spacing={1}
					alignItems="center"
					textAlign="center">
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Company Name (case sensitive)"
							id="ComanyName"
							value={filterByCompanyName}
							onChange={(event): void =>
								setFilterByCompanyName(event.target.value)
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							value={filterByType}
							fullWidth
							label="Company Type"
							onChange={(event): void => {
								setFilterByType(event.target.value);
							}}
							select>
							{companyTypes.map((type) => {
								return (
									<MenuItem key={type} value={type}>
										{CompanyTypeName[type]}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => handleApplyFilters()}>
							Apply filters
						</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => {
								setFilterByCompanyName('');
								setFilterByType('');
								setCompanies([]);
							}}>
							Clear filters
						</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="contained"
							size="large"
							onClick={(): void => {
								setShowCreateNewCompany(true);
							}}>
							Add company
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
				}}
				divider={
					<Divider
						sx={{ ml: 1, mr: 1 }}
						orientation="vertical"
						flexItem
					/>
				}
				spacing={2}>
				<Box flex="1">
					<DataTable
						tableData={companies}
						columns={columns}
						title="Companies in the system"
						selection={[selected, setSelected]}
						customTableOptions={tableOptions}
					/>
				</Box>
				<Box flex="1">
					{companies[selected] ? (
						<EditCompanyDetails
							company={companies[selected]}
							saveCompanyCallback={handleApplyFilters}
						/>
					) : (
						<Paper
							sx={{ padding: 1, height: 'calc(100vh - 245px)' }}>
							<Grid
								container
								height="100%"
								justifyContent="center"
								alignItems="center">
								<Grid item>
									<Typography textAlign="center" variant="h6">
										Search for a Company using the filters
										above and select one to show their
										details here.
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					)}
				</Box>
			</Stack>
		</>
	);
};
