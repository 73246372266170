import {
	Grid,
	InputAdornment,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material';
import { FixMeLater } from '../../constants/AnyTypes';
import {
	basicNumberFormatter,
	formatDecimalToPercent,
	formatPercentToDecimal,
	percentageNumberFormatter,
} from '../helpers/numberFormats';

const ratesTextFieldInputProps: TextFieldProps = {
	fullWidth: true,
	variant: 'outlined',
	InputProps: {
		startAdornment: <InputAdornment position="start">$</InputAdornment>,
		// These types are so incompatible, I have no idea how this works
		inputComponent: basicNumberFormatter as FixMeLater,
		inputProps: {
			allowNegative: false,
		},
	},
	InputLabelProps: { shrink: true },
};

const onCostFieldInputProps: TextFieldProps = {
	fullWidth: true,
	variant: 'outlined',
	InputProps: {
		inputComponent: percentageNumberFormatter as FixMeLater,
		inputProps: {
			allowNegative: false,
		},
	},
	InputLabelProps: { shrink: true },
};

type RatesTextFieldsProps = {
	newChargeRate: number;
	chargeRateError: boolean;
	newPayRate: number;
	payRateError: boolean;
	newOnCost: number;
	onCostError: boolean;
	newMargin: number;
	handleChargeRateChange: (rate: number) => void;
	handlePayRateChange: (rate: number) => void;
	handleOnCostChange: (rate: number) => void;
};

const errorString = 'Must be greater than 0';

const handleRateChange =
	(callback: (rate: number) => void) =>
	(event: React.ChangeEvent<HTMLInputElement>): void => {
		const rate = Number(event.target.value);
		callback(!isNaN(rate) ? rate : 0);
	};

export const RatesTextFields = ({
	newChargeRate,
	chargeRateError,
	newPayRate,
	payRateError,
	newOnCost,
	onCostError,
	newMargin,
	handleChargeRateChange,
	handlePayRateChange,
	handleOnCostChange,
}: RatesTextFieldsProps): JSX.Element => (
	<>
		<Grid container spacing={2} paddingY={2}>
			<Grid item xs={4}>
				<TextField
					label="Charge Rate"
					value={newChargeRate !== 0 ? newChargeRate : ''}
					placeholder="0"
					onChange={handleRateChange(handleChargeRateChange)}
					error={!!chargeRateError}
					helperText={chargeRateError && errorString}
					{...ratesTextFieldInputProps}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					label="Pay Rate"
					value={newPayRate !== 0 ? newPayRate : ''}
					placeholder="0"
					onChange={handleRateChange(handlePayRateChange)}
					error={!!payRateError}
					helperText={payRateError && errorString}
					{...ratesTextFieldInputProps}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					label="On Cost"
					value={
						newOnCost !== 0 ? formatDecimalToPercent(newOnCost) : ''
					}
					placeholder="0%"
					onChange={(
						event: React.ChangeEvent<HTMLInputElement>,
					): void => {
						const rate = Number(event.target.value);
						handleOnCostChange(
							!isNaN(rate) ? formatPercentToDecimal(rate) : 0,
						);
					}}
					error={!!onCostError}
					helperText={onCostError && errorString}
					{...onCostFieldInputProps}
				/>
			</Grid>
		</Grid>
		<Grid item xs={12} textAlign="center" minHeight="2rem">
			<Typography
				variant="body1Bold"
				color={newMargin < 0 ? 'error' : 'neutral'}>
				Estimated Margin: ${newMargin.toFixed(2)}
			</Typography>
			<Typography
				color="error"
				variant="caption"
				display="block"
				visibility={newMargin < 0 ? 'visible' : 'hidden'} // use visilibility to prevent reflow
			>
				Margin is negative
			</Typography>
		</Grid>
	</>
);
