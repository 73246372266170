import { UserProps } from '../../constants/Common';
import { Compliance } from './Compliance';

type ComplianceWrapperProps = UserProps;

export const ComplianceWrapper = ({
	userDetails,
}: ComplianceWrapperProps): JSX.Element => {
	return <Compliance userDetails={userDetails} />;
};
