import { ComplianceReport } from '../../constants/Compliance';
import { Firestore, FirestoreDataConverter } from '../firebase';

const COLLECTION = 'complianceReports';

const complianceReportsEntryConverter: FirestoreDataConverter<ComplianceReport> =
	{
		toFirestore: (model) => model,
		fromFirestore: (snapshot, _) => snapshot.data() as ComplianceReport,
	};

const fetchComplianceReportsByCompany = (
	companyID: string,
	callback: (complianceReports: Record<string, ComplianceReport>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.withConverter(complianceReportsEntryConverter)
		.where('site.companyID', '==', companyID)
		.onSnapshot((snapshot) => {
			const complianceReports = snapshot.docs.reduce<
				Record<string, ComplianceReport>
			>(
				(previous, current) => ({
					...previous,
					[current.id]: current.data(),
				}),
				{},
			);
			callback(complianceReports);
		});

const fetchComplianceReportsBySite = (
	siteID: string,
	callback: (complianceReports: Record<string, ComplianceReport>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.withConverter(complianceReportsEntryConverter)
		.where('site.id', '==', siteID)
		.onSnapshot((snapshot) => {
			const complianceReports = snapshot.docs.reduce<
				Record<string, ComplianceReport>
			>(
				(previous, current) => ({
					...previous,
					[current.id]: current.data(),
				}),
				{},
			);
			callback(complianceReports);
		});

const complianceReportsFirebaseApi = {
	fetchComplianceReportsByCompany,
	fetchComplianceReportsBySite,
};

export default complianceReportsFirebaseApi;
