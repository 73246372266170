import {
	SafetyCourseType,
	SafetyCourseValidationRequest,
	SafetyCourseValidationResponse,
} from '../constants/SafetyCourse';
import { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const validateSafetyCourse = async (
	abortSignal: AbortSignal,
	user: User,
	safetyCourseType: SafetyCourseType,
	safetyCourseID: string,
): Promise<SafetyCourseValidationResponse | undefined> => {
	const response = await makeCloudFunctionRequestDeprecated<
		SafetyCourseValidationResponse,
		SafetyCourseValidationRequest
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.ValidateSafetyCourse,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: { safetyCourseID, safetyCourseType },
	});

	if (response === undefined) {
		return response;
	}

	switch (response.type) {
		case SafetyCourseType.SiteSafe: {
			return {
				...response,
				expiryDate: new Date(response.expiryDate),
			};
		}
		case SafetyCourseType.ConstructSafe: {
			return {
				...response,
				expiryDate: null,
			};
		}
	}
};
