import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatShortSpacedDate } from '../../helpers/dateFormatters';

type ViewDocumentHeaderProps = {
	name: string;
	companyName: string;
	employeeName: string;
	signedDate?: Date;
};

export const ViewDocumentHeader = ({
	name,
	companyName,
	employeeName,
	signedDate,
}: ViewDocumentHeaderProps): JSX.Element => {
	const signedOn = signedDate
		? `Signed on ${formatShortSpacedDate(signedDate)}`
		: null;

	return (
		<Box>
			<Typography variant="h3Bold">{name}</Typography>
			<Typography variant="body1" color="neutral.main">
				{companyName}
			</Typography>
			<Typography variant="body1" color="neutral.main">
				{employeeName}
			</Typography>
			{signedOn && (
				<Typography variant="body1" color="neutral.main">
					{signedOn}
				</Typography>
			)}
		</Box>
	);
};
