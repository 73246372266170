import firebase from 'firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type DeleteNoteBody = {
	timesheetID: string;
	noteID: string;
};

export const deleteTimesheetNote = async (
	abortSignal: AbortSignal,
	user: firebase.User,
	timesheetID: string,
	noteID: string,
): Promise<void> => {
	await makeCloudFunctionRequestReturnSuccessStatus<DeleteNoteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.DeleteTimesheetNote,
		method: 'DELETE',
		services: defaultCloudFunctionServices,
		body: {
			timesheetID,
			noteID,
		},
	});
};
