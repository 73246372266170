import { StepperTab } from '../components/StepperHeader/StepperHeader';
import { AccountType } from './Common';

type WorkbenchConfig = {
	titles: string[];
	setupStepperTabs: () => Record<string, StepperTab>;
};

export const workbenchAccountTypes = [
	'management',
	'seniorManagement',
] as const;

export type WorkbenchAccountType = AccountType &
	(typeof workbenchAccountTypes)[number];

export const isWorkbenchAccountType = (
	accountType: string,
): accountType is WorkbenchAccountType => {
	return workbenchAccountTypes.includes(
		accountType as unknown as WorkbenchAccountType,
	);
};

export const WorkbenchConfig: Record<WorkbenchAccountType, WorkbenchConfig> = {
	management: {
		titles: ['sites', 'activities'],
		setupStepperTabs: () => ({
			sites: {
				index: 0,
				route: 'sites',
				name: 'Sites',
				optional: true,
				canMoveNext: () => true,
			},
			activities: {
				index: 1,
				route: 'activities',
				name: 'Activities',
				optional: true,
				canMoveNext: () => true,
			},
		}),
	},
	seniorManagement: {
		titles: ['employees', 'sites', 'activities'],
		setupStepperTabs: () => ({
			employees: {
				index: 0,
				route: 'employees',
				name: 'Employees',
				optional: true,
				canMoveNext: () => true,
			},
			sites: {
				index: 1,
				route: 'sites',
				name: 'Sites',
				optional: true,
				canMoveNext: () => true,
			},
			activities: {
				index: 2,
				route: 'activities',
				name: 'Activities',
				optional: true,
				canMoveNext: () => true,
			},
		}),
	},
};
