import firebase from 'firebase';
import { AllInvoicingCompaniesResponse } from '../models/Integrations/IntegrationElements';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export const fetchInvoicingContactIDs = async (
	abortSignal: AbortSignal,
	user: firebase.User,
): Promise<AllInvoicingCompaniesResponse | undefined> => {
	return await makeCloudFunctionRequestDeprecated<AllInvoicingCompaniesResponse>(
		{
			abortSignal,
			user,
			url: HTTPSCloudFunctionURLs.InvoicingCompanyIDs,
			method: 'GET',
			services: defaultCloudFunctionServices,
		},
	);
};
