import { useContext, useEffect, useState } from 'react';
import firebaseApi from '../../firebase/firebaseApi';
import { useUserAuthContext, useUserDetailsContext } from '../UserProvider';
import { FeatureFlag, FeatureFlags, FeatureFlagsContext } from './context';

type FeatureFlagsProviderProps = {
	children: React.ReactNode;
};

export const FeatureFlagsProvider = ({
	children,
}: FeatureFlagsProviderProps): JSX.Element => {
	const userAuth = useUserAuthContext();
	const userDetails = useUserDetailsContext();

	const [flags, setFlags] = useState<Record<string, FeatureFlag>>({});
	const [loadedFlags, setLoadedFlags] = useState(false);

	useEffect(() => {
		setLoadedFlags(false);
		return firebaseApi.featureFlagsSubscription((allFlags) => {
			const newFlags: Record<string, FeatureFlag> = {};
			allFlags.forEach((flag) => {
				const currentUserId = userAuth?.uid;
				const currentUserCompany = userDetails?.companyID;
				const checkOptInLists =
					flag.enabled &&
					currentUserId !== undefined &&
					(flag.optedInCompanies.length > 0 ||
						flag.optedInUsers.length > 0);

				if (checkOptInLists) {
					// enabled if the optedIn is empty or user is signed in and company included in the list
					const isCompanyEnabled =
						flag.optedInCompanies.length === 0 ||
						(currentUserCompany !== undefined &&
							flag.optedInCompanies.includes(currentUserCompany));

					// enabled if the optedIn is empty or user is signed in and included in the list
					const isUserEnabled =
						flag.optedInUsers.length === 0 ||
						flag.optedInUsers.includes(currentUserId);

					flag.enabled = isCompanyEnabled && isUserEnabled;
				}
				newFlags[flag.name] = flag;
			});

			setFlags(newFlags);
			setLoadedFlags(true);
		});
	}, [userAuth?.uid, userDetails?.companyID]);

	const featureflags = {
		get: (flagName: string) => flags[flagName]?.enabled ?? false, // default missing feature flag calls to off
		loaded: loadedFlags,
		getAll: (): Readonly<FeatureFlag>[] =>
			Object.values(flags).map((value) => Object.freeze(value)),
	};
	return (
		<FeatureFlagsContext.Provider value={featureflags}>
			{children}
		</FeatureFlagsContext.Provider>
	);
};

export const useFeatureFlagContext = (): FeatureFlags => {
	return useContext(FeatureFlagsContext);
};
