import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { DefaultTheme } from '../../../../Theme';

export enum ReviewStatus {
	Reviewed = 'Reviewed',
	Pending = 'Pending',
}

export type ReviewerCardProps = {
	review:
		| {
				status: ReviewStatus.Reviewed;
				name: string;
				reviewedAt: string;
		  }
		| { status: ReviewStatus.Pending; pendingReviewCompany: string | null };
};

export const ReviewerCard = ({ review }: ReviewerCardProps): JSX.Element => (
	<Stack direction="row" spacing={2} alignItems="center" marginY={1}>
		{review.status === ReviewStatus.Pending ? (
			<>
				<Avatar
					sx={{
						backgroundColor: DefaultTheme.palette.neutral.main,
						color: DefaultTheme.palette.info.contrastText,
					}}>
					<PendingActionsIcon color="inherit" />
				</Avatar>
				<Box>
					<Typography variant="h7Bold">Pending Review</Typography>
					<Typography fontSize="small">
						{review.pendingReviewCompany ?? 'Unknown'}
					</Typography>
				</Box>
			</>
		) : (
			<>
				<Avatar
					sx={{
						backgroundColor: DefaultTheme.palette.neutral.main,
						color: DefaultTheme.palette.info.contrastText,
					}}>
					<AssignmentTurnedInOutlinedIcon color="inherit" />
				</Avatar>
				<Box>
					<Typography variant="h7Bold">Reviewed</Typography>
					<Typography fontSize="small">{review.name}</Typography>
					<Typography fontSize="small">
						{review.reviewedAt}
					</Typography>
				</Box>
			</>
		)}
	</Stack>
);
