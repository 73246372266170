import { InductionEntry } from '../../constants/InductionEntry';
import { SafetyCourseType } from '../../constants/SafetyCourse';
import { Firestore, FirestoreDataConverter } from '../firebase';

const COLLECTION = 'inductions';

const inductionsEntryConverter: FirestoreDataConverter<InductionEntry> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as InductionEntry,
};

const fetchInductionsByCompany = (
	companyID: string,
	callback: (inductions: Record<string, InductionEntry>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.withConverter(inductionsEntryConverter)
		.where('site.companyID', '==', companyID)
		.onSnapshot((snapshot) => {
			const inductions = snapshot.docs.reduce<
				Record<string, InductionEntry>
			>(
				(previous, current) => ({
					...previous,
					[current.id]: current.data(),
				}),
				{},
			);
			callback(inductions);
		});

const fetchInductionsBySite = (
	siteID: string,
	callback: (inductions: Record<string, InductionEntry>) => void,
): (() => void) =>
	Firestore.collection(COLLECTION)
		.withConverter(inductionsEntryConverter)
		.where('site.id', '==', siteID)
		.onSnapshot((snapshot) => {
			const inductions = snapshot.docs.reduce<
				Record<string, InductionEntry>
			>(
				(previous, current) => ({
					...previous,
					[current.id]: current.data(),
				}),
				{},
			);
			callback(inductions);
		});

const getInductionsBySafetyCourseIDType = async (
	safetyCourseID: string,
	safetyCourseType: SafetyCourseType,
): Promise<InductionEntry[]> => {
	const snapshot = await Firestore.collection(COLLECTION)
		.withConverter(inductionsEntryConverter)
		.where('safetyCourse.id', '==', safetyCourseID)
		.where('safetyCourse.type', '==', safetyCourseType)
		.get();

	if (!snapshot.empty) {
		return snapshot.docs.map((doc) => doc.data() as InductionEntry);
	}

	return [];
};

const inductionsFirebaseApi = {
	fetchInductionsByCompany,
	fetchInductionsBySite,
	getInductionsBySafetyCourseIDType,
};

export default inductionsFirebaseApi;
