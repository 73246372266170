import { LoadingButton } from '@mui/lab';
import { AlertColor, Box } from '@mui/material';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { CloudFunctionApi } from '../../../cloudfunctions';
import { Leave, LeavePayrollStatus } from '../../../constants/Leave';
import { FirebaseApi } from '../../../firebase/firebaseApi';
import { useAbortController } from '../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../providers/UserProvider';
import { DataTable } from '../../DataTable/DataTable';
import { formatSlashedDate } from '../../helpers/dateFormatters';
import { nameCustomTableSort } from '../../helpers/muiDataTableCustomSorts';
import { ReviewStatusIcon } from '../../LeaveReview/ReviewStatusIcon';
import { LoadingDots } from '../../Management/subcomponents/LoadingDots';
import { CustomSnackBar } from '../../SnackBar/SnackBar';
import { TriggerStatuses } from '../FailedAutomations/FailedAutomations';
import { FailedIntegrationsTableTheme } from '../FailedAutomations/FailedIntegrationsTableTheme';

export type ManageLeaveIntegrationsProps = {
	cloudFunctionApi: Pick<CloudFunctionApi, 'payrollTriggerLeaveIntegration'>;
	firebaseApi: Pick<FirebaseApi, 'subLeaveByPayrollStatus'>;
};

export const ManageLeaveIntegrations = ({
	firebaseApi,
	cloudFunctionApi,
}: ManageLeaveIntegrationsProps): JSX.Element => {
	const abortSignal = useAbortController();
	const user = useUserAuthContext();

	const [triggerStatus, setTriggerStatus] = useState<TriggerStatuses>('none');
	const [leave, setLeave] = useState<Leave[]>([]);
	const [loading, setLoading] = useState(false);

	const COLUMN_COUNT = 7;
	const cellWidth = { width: `${100 / COLUMN_COUNT}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		'Sorry, no documents found'
	);
	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'employee',
			label: 'User',
			options: {
				customBodyRender: (employee: Leave['employee']) =>
					employee.name,
				sortCompare: nameCustomTableSort,
				setCellHeaderProps,
			},
		},
		{
			name: 'employer',
			label: 'Company',
			options: {
				customBodyRender: (employer: Leave['employer']) =>
					employer.name,
				sortCompare: nameCustomTableSort,
				setCellHeaderProps,
			},
		},
		{
			name: 'reviewStatus',
			label: 'Review Status',
			options: {
				customBodyRender: (status: Leave['reviewStatus']) => (
					<Box display="flex" justifyContent="center">
						<ReviewStatusIcon reviewStatus={status} />
					</Box>
				),
				setCellHeaderProps,
			},
		},
		{
			name: 'reviewedAt',
			label: 'Reviewed At',
			options: {
				customBodyRender: (date: Leave['reviewedAt']) =>
					date ? formatSlashedDate(date.toDate()) : 'unreviewed',
				setCellHeaderProps,
			},
		},
		{
			name: 'dateRequested',
			label: 'Date Requested',
			options: {
				customBodyRender: (date: Leave['dateRequested']) =>
					formatSlashedDate(date.toDate()),
				setCellHeaderProps,
			},
		},
		{
			name: 'leaveType',
			label: 'Leave Type',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'id',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				customBodyRender: (id): JSX.Element => (
					<LoadingButton
						fullWidth
						loading={triggerStatus === 'loading'}
						variant="outlined"
						onClick={(): Promise<void> => handleTriggerLeave(id)}>
						trigger integration
					</LoadingButton>
				),
				setCellHeaderProps,
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 310px)',
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
		sortOrder: {
			name: 'reviewedAt',
			direction: 'desc',
		},
	};

	const snackBarContent: Record<
		TriggerStatuses,
		{ text: string; severity: AlertColor }
	> = {
		none: { text: '', severity: 'info' },
		error: {
			severity: 'error',
			text: 'Leave Integration Unsuccessful',
		},
		done: {
			severity: 'success',
			text: 'Leave Integration Success',
		},
		loading: {
			severity: 'info',
			text: 'Triggering Leave Integration',
		},
	};

	useEffect(() => {
		setLoading(true);
		return firebaseApi.subLeaveByPayrollStatus(
			LeavePayrollStatus.Failed,
			(leave) => {
				setLeave(leave);
				setLoading(false);
			},
		);
	}, [firebaseApi]);

	const handleTriggerLeave = async (leaveID: string): Promise<void> => {
		if (!user) return;
		setTriggerStatus('loading');
		const isSuccessful =
			await cloudFunctionApi.payrollTriggerLeaveIntegration(
				abortSignal,
				user,
				leaveID,
			);
		setTriggerStatus('done');
		if (isSuccessful) {
			setTriggerStatus('done');
		} else {
			setTriggerStatus('error');
		}
	};

	return (
		<>
			<CustomSnackBar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={triggerStatus !== 'none'}
				onClose={(): void => {
					if (triggerStatus !== 'loading') setTriggerStatus('none');
				}}
				snackBarText={snackBarContent[triggerStatus].text}
				severity={snackBarContent[triggerStatus].severity}
				loading={triggerStatus === 'loading'}
			/>
			<FailedIntegrationsTableTheme centerStatusHeader optionsColumn={7}>
				<DataTable
					tableData={leave}
					columns={columns}
					customTableOptions={tableOptions}
					title="Failed Leave"
				/>
			</FailedIntegrationsTableTheme>
		</>
	);
};
