import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { UserDetails } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { capitaliseWords } from '../helpers/stringHelpers';
import {
	EditableUserFields,
	ErrorMapType,
	UserPanel,
	createError,
} from './UserPanel';

type EditUserDetailsProps = {
	user: UserDetails;
	onSaveUserCallback: (name: string) => void;
};

const toEditableUser = (user: UserDetails): EditableUserFields => ({
	firstname: user.firstname,
	lastname: user.lastname,
	accountType: user.accountType,
	mobileNumber: user.mobileNumber,
	companyID: user.companyID,
	siteID: user.siteID,
	workerType: user.workerType,
	contractedTo: user.contractedTo,
});

export const EditUserDetails = ({
	user,
	onSaveUserCallback,
}: EditUserDetailsProps): JSX.Element => {
	const [selectedUserDetails, setSelectedUserDetails] =
		useState<UserDetails>(user);
	const [errorMap, setErrorMap] = useState<ErrorMapType>(
		createError(toEditableUser(user)),
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	useEffect(() => {
		const editableUserDetails = toEditableUser(user);
		setSelectedUserDetails(user);
		setErrorMap(createError(editableUserDetails));
	}, [user]);

	useEffect(() => {
		setSelectedUserDetails(user);
	}, [user]);

	const saveUserDetails = async (): Promise<void> => {
		const isValid = Object.values(errorMap).every((value) => !value.error);
		if (isValid) {
			await firebaseApi.updateUserDetails(
				user.userID,
				selectedUserDetails,
			);

			setIsEditing(false);
			const name = capitaliseWords(
				`${selectedUserDetails.firstname.trim()} ${selectedUserDetails.lastname.trim()}`,
			);
			onSaveUserCallback(name);
		}
	};

	return (
		<Card variant="outlined">
			<CardHeader
				disableTypography
				avatar={
					user.photoURL !== '' && (
						<Box
							component="img"
							src={user?.photoURL}
							alt="Profile photo"
							sx={{
								height: 50,
							}}
						/>
					)
				}
				title={
					<Typography variant="h6">
						{user?.displayName} Details
					</Typography>
				}
				action={
					!isEditing ? (
						<IconButton
							onClick={(): void => {
								setIsEditing(true);
							}}>
							<EditIcon />
						</IconButton>
					) : (
						<>
							<IconButton
								onClick={async (): Promise<void> =>
									await saveUserDetails()
								}>
								<SaveIcon />
							</IconButton>
							<IconButton
								onClick={(): void => {
									setIsEditing(false);
									setSelectedUserDetails(user);
									setErrorMap(createError(user));
								}}>
								<CancelIcon />
							</IconButton>
						</>
					)
				}
			/>
			<CardContent>
				<UserPanel
					userTuple={[selectedUserDetails, setSelectedUserDetails]}
					disabled={!isEditing}
					errorMapTuple={[errorMap, setErrorMap]}
					firebaseApi={firebaseApi}
				/>
			</CardContent>
		</Card>
	);
};
