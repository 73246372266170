import { FeatureFlag } from '../../providers/featureFlags/context';
import { Firestore, FirestoreDataConverter } from '../firebase';

const FEATURE_FLAGS_COLLECTION = 'featureFlags';

const featureFlagConverter: FirestoreDataConverter<FeatureFlag> = {
	toFirestore: (model) => model,
	fromFirestore: (snapshot, _) => snapshot.data() as FeatureFlag,
};

const featureFlagsSubscription = (
	callback: (flags: FeatureFlag[]) => void,
): (() => void) => {
	return Firestore.collection(FEATURE_FLAGS_COLLECTION)
		.withConverter(featureFlagConverter)
		.onSnapshot((snapshot) => {
			const flags = snapshot.docs
				.filter((doc) => doc.exists)
				.map((doc) => doc.data());
			callback(flags);
		});
};

const featureFlagsFirebaseApi = {
	featureFlagsSubscription,
};

export default featureFlagsFirebaseApi;
