import { AccountType } from '../../../constants/Common';
import {
	ActivityLink,
	EmployeeLink,
	SiteLink,
} from '../../../models/Integrations/ProjectTrackingIntegration';
import { IntegrationLinkStatus } from '../../Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { StepperTab } from '../../StepperHeader/StepperHeader';

type CATProjectsConfig = {
	titles: string[];
	setupStepperTabs: () => Record<string, StepperTab>;
};

export const catprojectsAccountTypes = [
	'seniorManagement',
	'management',
] as const;

export type CATProjectsAccountType = AccountType &
	(typeof catprojectsAccountTypes)[number];

export const isCATProjectsAccountType = (
	accountType: string,
): accountType is CATProjectsAccountType => {
	return catprojectsAccountTypes.includes(
		accountType as unknown as CATProjectsAccountType,
	);
};

export const CATProjectsConfig: Record<
	CATProjectsAccountType,
	CATProjectsConfig
> = {
	seniorManagement: {
		titles: ['employees', 'sites', 'activities', 'export'],
		setupStepperTabs: () => ({
			employees: {
				index: 0,
				route: 'employees',
				name: 'Employees',
				optional: true,
				canMoveNext: () => true,
			},
			sites: {
				index: 1,
				route: 'sites',
				name: 'Sites',
				optional: true,
				canMoveNext: () => true,
			},
			activities: {
				index: 2,
				route: 'activities',
				name: 'Activities',
				optional: true,
				canMoveNext: () => true,
			},
		}),
	},
	management: {
		titles: ['sites', 'activities', 'export'],
		setupStepperTabs: () => ({
			sites: {
				index: 0,
				route: 'sites',
				name: 'Sites',
				optional: false,
				canMoveNext: () => true,
			},
			activities: {
				index: 1,
				route: 'activities',
				name: 'Activities',
				optional: false,
				canMoveNext: () => true,
			},
		}),
	},
};

export const getLinkStatus = (
	link: SiteLink | ActivityLink | EmployeeLink | null,
): IntegrationLinkStatus => {
	if (link === null) {
		return IntegrationLinkStatus.Unlinked;
	} else {
		return IntegrationLinkStatus.Linked;
	}
};
