import { createTheme, Theme, ThemeProvider } from '@mui/material';

export const FailedIntegrationsTableTheme = ({
	centerStatusHeader,
	optionsColumn,
	children,
}: {
	centerStatusHeader: boolean;
	optionsColumn: number;
	children: JSX.Element;
}): JSX.Element => (
	<ThemeProvider
		theme={(baseTheme: Theme): Theme =>
			createTheme({
				...baseTheme,
				components: {
					...baseTheme.components,
					MuiTableCell: {
						styleOverrides: {
							root: {
								padding: '8px 12px',
							},
						},
					},
					MUIDataTableHeadCell: {
						styleOverrides: {
							root: {
								...(centerStatusHeader && {
									// This is fragile styling as if columns change too much this breaks.
									// Currently, columns are okay.
									// We have to apply some custom styling to the table column headers here as
									// MUIDataTable doesn't let us do a custom render without losing the default styling
									'&:nth-of-type(3)': {
										'& span': {
											display: 'flex',
											justifyContent: 'center',
											margin: 0,
										},
										'& button': {
											margin: 0,
										},
										'& div': {
											display: 'flex',
											justifyContent: 'center',
										},
									},
								}),
								[`&:nth-of-type(${optionsColumn}), &:nth-of-type(8)`]:
									{
										'& span': {
											display: 'flex',
											justifyContent: 'center',
											margin: 0,
										},
										'& button': {
											margin: 0,
										},
										'& div': {
											display: 'flex',
											justifyContent: 'center',
										},
									},
							},
						},
					},
				},
			})
		}>
		{children}
	</ThemeProvider>
);
