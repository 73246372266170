import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';

const directions = { back: 'back', next: 'next' } as const;
type Direction = keyof typeof directions;

export type TimesheetActionsBarProps = {
	timesheet: Timesheet | null;
	timesheets: Timesheet[];
	setTimesheet: (timesheet: Timesheet) => void;
	action: { buttonText: string; onClick: () => Promise<void> } | null;
	handleEditClicked: () => void;
	handlePDFClicked: () => void;
};

export const TimesheetActionsBar = ({
	timesheet,
	timesheets,
	setTimesheet,
	action,
	handleEditClicked,
	handlePDFClicked,
}: TimesheetActionsBarProps): JSX.Element => {
	const theme = useTheme();
	const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const cycleTimesheets = (direction: Direction): void => {
		const currentIndex = timesheets.findIndex(
			(i) => i.id === timesheet?.id,
		);

		// lets the buttons cycle the list
		let timesheetIndex: number;
		if (direction === directions.next) {
			if (currentIndex + 1 >= timesheets.length) {
				timesheetIndex = 0;
			} else {
				timesheetIndex = currentIndex + 1;
			}
		} else {
			if (currentIndex - 1 < 0) {
				timesheetIndex = timesheets.length - 1;
			} else {
				timesheetIndex = currentIndex - 1;
			}
		}

		const timewithID = timesheets.at(timesheetIndex);
		if (timewithID) setTimesheet(timewithID);
	};

	// Keep buttons a constant width, just wider than the longest text
	const buttonStyle = { width: '100px' };

	const backButton = (): JSX.Element => (
		<Button
			color="primary"
			title="Back"
			onClick={(): void => cycleTimesheets(directions.back)}
			disabled={timesheets.length <= 1}
			startIcon={<NavigateBeforeIcon />}>
			Back
		</Button>
	);

	const nextButton = (): JSX.Element => (
		<Button
			color="primary"
			title="Next"
			onClick={(): void => cycleTimesheets(directions.next)}
			disabled={
				timesheets.length < 1 ||
				(timesheets.length === 1 && timesheet !== null)
			}
			endIcon={<NavigateNextIcon />}>
			Next
		</Button>
	);

	const editButton = (): JSX.Element => (
		<Button
			color="primary"
			variant="outlined"
			onClick={handleEditClicked}
			sx={buttonStyle}>
			Edit
		</Button>
	);

	const pdfButton = (): JSX.Element => (
		<Button
			color="primary"
			variant="outlined"
			onClick={handlePDFClicked}
			sx={buttonStyle}>
			PDF
		</Button>
	);

	const actionButton = ({
		buttonText,
		onClick,
	}: NonNullable<typeof action>): JSX.Element => (
		<Button
			variant="contained"
			onClick={(): Promise<void> => onClick()}
			sx={buttonStyle}>
			{buttonText}
		</Button>
	);

	// Needs to be two rows on small screens
	return isXSmallScreen ? (
		<>
			<Stack
				justifyContent="space-between"
				direction="row"
				m={1}
				spacing={1}>
				{backButton()}
				{nextButton()}
			</Stack>
			<Stack
				justifyContent="center"
				direction="row"
				m={1}
				mt={0}
				spacing={1}>
				{action && editButton()}
				{timesheet && pdfButton()}
				{action && actionButton(action)}
			</Stack>
		</>
	) : (
		<Stack justifyContent="space-between" direction="row" m={1} spacing={1}>
			{backButton()}
			<Stack direction="row" spacing={1}>
				{action && editButton()}
				{timesheet && pdfButton()}
				{action && actionButton(action)}
			</Stack>
			{nextButton()}
		</Stack>
	);
};
